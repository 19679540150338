import styled from "@emotion/styled";
import { Switch, SwitchProps } from "@mui/material";

export const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 23,
    padding: 0,
    fontFamily: "Plus Jakarta Sans",
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2.02,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#C9F2FF',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#C9F2FF',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: "#939396"
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.6,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18,
        height: 18,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#939396',
        opacity: 1,
        transition: "500ms"


    },
}));