import { useContext, useEffect, useState } from "react";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "../Evaluation/useSemesterYearNow";

export const useAbleInputSelectMetricas = () => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();

  //** Hooks

  //** States
  const [ableInputSelectMetrica, setAbleInputSelect] = useState(true);
  const [ableInputSelectMetricaEvaluador, setAbleInputSelectEvaluador] =
    useState(true);

  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;
  // const evaluationDate = `${Evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear} ${Evaluation?.evaluationPeriod?.evaluationPeriodYear}`;
  // const dateNow = `${User?.periodInfo?.semesterNumber} ${User?.periodInfo?.year}`;

  // console.log("Atributos", User);

  useEffect(() => {
    //**  +++++++++++++++++++++++++++   EVALUACIÓN +++++++++++++++++++++++
    if (User.role === "Auditor") {
      setAbleInputSelectEvaluador(true);
      setAbleInputSelect(true);
    } else {
      if (!urlPermisos) {
        if (
          Evaluation.catStatusEvaluationId > 0 &&
          Evaluation.catStatusEvaluationId < 6
        ) {
          setAbleInputSelectEvaluador(false);
          setAbleInputSelect(true);
        }

        if (
          Evaluation.catStatusEvaluationId === 7 ||
          Evaluation.catStatusEvaluationId === 6
        ) {
          if (isActualDate) {
            if (evaluationAttribute.edit_evaluation_complete === 1) {
              setAbleInputSelectEvaluador(false);
              setAbleInputSelect(true);
            }

            if (evaluationAttribute.edit_evaluation_complete === 0) {
              setAbleInputSelectEvaluador(true);
              setAbleInputSelect(true);
            }
          } else {
            setAbleInputSelectEvaluador(true);
            setAbleInputSelect(true);
          }
        }
      }

      //**  +++++++++++++++++++++++++++   AUTOEVALUACIÓN +++++++++++++++++++++++

      if (urlPermisos) {
        if (changePeriodEvaluation) {
          if (
            Evaluation.catStatusEvaluationId > 0 &&
            Evaluation.catStatusEvaluationId < 6
          ) {
            setAbleInputSelect(false);
            setAbleInputSelectEvaluador(true);
          }

          if (Evaluation.catStatusEvaluationId === 6) {
            setAbleInputSelect(true);
            setAbleInputSelectEvaluador(true);
          }

          //** Caso para los admins
          if (
            Evaluation.catStatusEvaluationId === 7 &&
            evaluationAttribute.edit_evaluation_complete === 1
          ) {
            setAbleInputSelect(false);
            setAbleInputSelectEvaluador(true);
          }

          //** Caso para los que no tienen permisos
          if (
            Evaluation.catStatusEvaluationId === 7 &&
            evaluationAttribute.edit_evaluation_complete === 0
          ) {
            setAbleInputSelect(true);
            setAbleInputSelectEvaluador(true);
          }
        } else {
          setAbleInputSelect(true);
          setAbleInputSelectEvaluador(true);
        }
      }
    }
  }, [
    Evaluation.catStatusEvaluationId,
    evaluationAttribute.edit_evaluation_complete,
    urlPermisos,
    changePeriodEvaluation,
    User.role,
    isActualDate,
  ]);

  return {
    ableInputSelectMetrica,
    ableInputSelectMetricaEvaluador,
  };
};

/*
evaluationAttribute.edit_evaluation_complete === 1 


      (User.role === "Admin TI" ||
      User.role === "Admin" ||
      User.role === "Admin RH")
*/

//? Si el usuario tiene habilitado el atributo
//? Si el usuario pertenece a alguno de estos roles
//? Si el usuario está en una evaluación
//? entonces esta sección se podrá editar
