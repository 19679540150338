import styled from "styled-components";

export const DownLoadReportBox = styled.div`
  padding: 0px;
  gap: 24px;
  background: #ffffff;
  /* border-radius: 16px; */
  font-family: "Plus Jakarta Sans";
  margin-top: 48px;
  margin-bottom: 48px;
  /* border: 1px solid red; */
  padding-bottom: 40px;
  border-bottom: 2px solid #F0F0F0;
`;

export const DownloadReportTitle = styled.h3`
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;

  /* H4 */
  font-family: Plus Jakarta Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  margin-bottom: 20px;
`;

export const SearchSection = styled.form`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #ffffff;
  width: 100%;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SearchLabel = styled.label`
  width: 51px;
  height: 36px;
  padding: 8px 12px;
  background: #f0f0f0;
  border-radius: 0px 4px 4px 0px;
`;
export const FixSearchLabel = {
  width: "auto",
  height: "38px",
  padding: " 8px 12px",
  background: "#f0f0f0",
};
export const SearchSelectOption = styled.option`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #939396;
`;

export const WrapperButtons = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: flex-end;
  gap: 8px;
  width: 100%;

  /* border: 1px solid red; */
  padding-top: 16px;
  align-items: flex-end;
  align-content: flex-end;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CleanButton = styled.button`
  /* width: 100px; */
  padding: 8px 24px;
  border: none;
  background: #c9f2ff;
  color: #3b63a2;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  line-height: 16px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
export const StyledButton = styled.button`
  /* width: 100px; */
  padding: 8px 24px;
  border: none;
  background: #3b63a2;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  line-height: 16px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const SelectDonwload = styled.select`
  display: flex;
  width: 100%;
  height: 36px;
  /* padding: 0px 12px; */
  /* align-items: center; */
  /* gap: 16px; */
  border: 1px solid #f0f0f0;
  border-radius: 0px 8px 8px 0px;
  background: #f0f0f0;
  /* margin-right: 2rem; */
`;

export const TextTypeReport = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
  border-radius: 8px 0px 0px 8px;
  background: #f0f0f0;
  height: 36px;
  background-color: #f0f0f0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const DownloadButton = ({ onClick, children, disabled }) => {
  return (
    <StyledButton type="button" onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};
