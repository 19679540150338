import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 24px;
  margin-bottom: 24px;
  /* padding:24px */
`;
export const TitleContainer = styled.div`
  display: flex;
  /* border: 1px solid red; */
  justify-content: space-between;
  height: 100%;
`;

export const Title = styled.h2`
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;
  /* H4 */
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; 
  margin: 0px;
`;

export const UpArrow = styled.img`
  width: 24px;
  cursor: pointer;
`;

export const ProgresBarContainer = styled.div`
  display: flex;
  gap: 1px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 24px;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 16px;
  background-color: #84d882;
  border-radius: 2px;
  /* margin-left: ; */
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: #f0f0f0;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const RowTable = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RowContain = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const PorcentaeContainer = styled.div`
  display: flex;
  gap: 32px;
  align-items: center;
`;

export const CircleTable = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f38382;
`;

export const TableText = styled.p`
  color: var(--neutrals-jet, #333335);
  font-feature-settings: "clig" off, "liga" off;
  /* Small Body */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  margin: 0;
`;

export const CardChartsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Tres columnas con el mismo ancho */
  gap: 16px;
  margin-top: 8px;

  justify-items: center;
`;

export const PedienteText = styled.p`
  color: var(--primary-true-blue, #3B63A2);
  margin:0;
  /* Small Text Link */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 19.6px */
  text-decoration-line: underline;
  align-self: flex-end;
  cursor: pointer;
`