import { SelectLabel, SelectPeriod } from "../styles/FiltroPeriod.styles";
const SelectCustom = ({
    valueSelect,
    nameSelect,
    nameLabel,
    children,
    hadleChange,
}: any) => {
    return (
        <SelectLabel>
            <div className="labelContainer">
                {nameLabel}
            </div>
            <SelectPeriod
                value={valueSelect}
                // placeholder="Elige una opción"
                onChange={hadleChange}
                name={nameSelect}
            >
                {/* <OptionPeriod value="">
                    Elige una opción
                </OptionPeriod> */}
                {children}
            </SelectPeriod>
        </SelectLabel>
    )
};

export default SelectCustom;
