export const detectMonthPeriod = (date: string, currentPeriod: number, defObj?: boolean) => {

    const partes = date.split("-");
    const mes = Number(partes[1]);

    const NewMonth = mes < 7 ? 1 : 2
    //? Si el mes es menor que 7 pertenecerá al prim er periodo
    // console.log("Estás es el mes -> ", mes, "por lo tanto es el periodo -> ", NewMonth, currentPeriod);

    if (defObj) {

        //* Mes: 1 - 6  ===  NewMonth -> 1
        //* Periodo: 1
        //? Permitido
        if (NewMonth === 1 && currentPeriod === 1) {
            return false
        }

        //* Mes: 7 - 12  ===  NewMonth -> 2
        //* Periodo: 2
        //? Permitido
        if (NewMonth === 2 && currentPeriod === 2) {
            return false
        }

        //* Mes: 7 - 12  ===  NewMonth -> 2
        //* Periodo: 1
        //! Denegado
        if (NewMonth === 2 && currentPeriod === 1) {
            return true
        }

        //* Mes: 1 - 6 ===  NewMonth -> 1
        //* Periodo: 2
        //! Denegado
        if (NewMonth === 1 && currentPeriod === 2) {
            return true
        }
    }

    else {

        //* Mes: 1 - 6  ===  NewMonth -> 1
        //* Periodo: 1
        //! Denegado
        if (NewMonth === 1 && currentPeriod === 1) {
            return true
        }

        //* Mes: 7 - 12  ===  NewMonth -> 2
        //* Periodo: 2
        //! Denegado
        if (NewMonth === 2 && currentPeriod === 2) {
            return true
        }

        //* Mes: 7 - 12  ===  NewMonth -> 2
        //* Periodo: 1
        //? Permitido
        if (NewMonth === 2 && currentPeriod === 1) {
            return false
        }

        //* Mes: 1 - 6 ===  NewMonth -> 1
        //* Periodo: 2
        //? Permitido
        if (NewMonth === 1 && currentPeriod === 2) {
            return false
        }
    }

}