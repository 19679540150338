import { useEffect, useState } from "react";
import styled from "styled-components";

import Close from "../../../assets/icons/CloseModal.svg";
import { ModalWrapperDos, SaveBtn } from "../ModalActions/ModalEditStyles";
import AlertModal from "../components/AlertModal";
import { useCollaboratorRedux } from "../../../redux/Collaborators";

const value = {
  label: "Selecciona una opcion",
  semester: 2,
  value: null,
};

const ModalObjetiveFuture = ({
  show,
  onHide,
  handleChangeDos,
  indexEdit,
  data,

}: any) => {
  const [alertModal, setAlertModal] = useState(false);
  const [alertModalAction, setAlertModalAction] = useState(1);
  const [inputModal, setInputModal] = useState<any>();
  const { User } = useCollaboratorRedux();

  const [duplicateAlert, setDuplicateAlert] = useState<boolean>(false);

  const onClose = () => {
    const desc = data[indexEdit].descripcion.label;
    setInputModal(desc||"");
    onHide(false);
    setDuplicateAlert(false);
  };
  const onChangeTextField = (e: any) => {
    setInputModal(e.target.value);

    setDuplicateAlert(false);

    // Validación de duplicados
    setDuplicateAlert(data.some(
      (item: any, index: number) => index !== indexEdit &&
        normalizeText(item.descripcion.label) === normalizeText(e.target.value)
    ))
  };

  // Función para normalizar texto
  const normalizeText = (text: string): string => {
    return text
      .toLowerCase()
      .normalize("NFD") // Normaliza el texto a Unicode NFD
      .replace(/[\u0300-\u036f]/g, "") // Remueve diacríticos
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()¿?¡"]/g, "") // Remueve caracteres especiales
      .replace(/\s+/g, " ") // Reemplaza múltiples espacios por uno
      .trim(); // Elimina espacios al inicio y al final
  };
  const cancelObjective = () => {
    handleChangeDos(indexEdit, "descripcion", value, true);
    onClose();
    onHide(false);
  };

  const onSubmit = () => {
    // console.log("Data ->", data);

    const normalizedInput = normalizeText(inputModal);

    // Validación de duplicados
    const isDuplicate = data.some(
      (item: { descripcion: { label: string; }; }, index: any) =>
        index !== indexEdit &&
        normalizeText(item.descripcion.label) === normalizedInput
    );

    if (isDuplicate) {
      setDuplicateAlert(true);
      return; // Salimos de la función para evitar que se guarde el duplicado
    }

    // Continuar con la actualización si no hay duplicados
    const newData = [...data];
    newData[indexEdit].semestre = data[indexEdit].semestre;
    newData[indexEdit].descripcion = {
      label: inputModal,
      semester: data[indexEdit].descripcion.semester,
      value: data[indexEdit].descripcion.value,
    };

    if (newData[indexEdit].descripcion.label.trim("") === "") {
      handleChangeDos(indexEdit, "descripcion", value, true);
      onClose();
    }
    onHide(false);
  };

  useEffect(() => {
    if (data.length > 0) {
      setInputModal(data[indexEdit]?.descripcion?.label);
    }
  }, [data, indexEdit]);

  return (
    <ModalWrapperDos display={show}  activeAlert={duplicateAlert}>
      <div className="modalWrapper">
        <div className="close">
          <div className="title">Edición de descripción de objetivos</div>
          <div className="closeModal" onClick={(e) => onClose()}>
            <img src={Close} alt="close" style={{ height: "16px" }} />
          </div>
        </div>
        <ContainerModalText>
          <div className="textContainer">
            A continuación, podrás editar {User.role !== "Evaluado" && "o borrar"} el mensaje escrito en la
            descripción de objetivos.
          </div>
          <div className="containerModal">
            <div className="txt">Oración</div>
            <div className="containerTxt">
              <InputObj onChange={onChangeTextField} value={inputModal} />
            </div>
          </div>
        </ContainerModalText>
        {duplicateAlert &&
          (
          <div className="active-alert-modal">
          No se permite guardar objetivos duplicados. Edita la descripción para continuar.
          </div>

          )
        }

        <FooterModal>
          <ButtonModalSave
          color={!duplicateAlert?"3b63a2":""}
            onClick={() => {
              if(!duplicateAlert){
                setAlertModalAction(1);
                setAlertModal(true);
              }

            }}
          >
            Guardar
          </ButtonModalSave>
          <ButtonModalErase
            onClick={(e) => {
              setAlertModalAction(2);
              setAlertModal(true);
              setDuplicateAlert(false);
            }}
          >
            Borrar
          </ButtonModalErase>
          <ButtonModalCancel
            onClick={() => {
              onClose();
            }}
          >
            Cancelar
          </ButtonModalCancel>
        </FooterModal>
      </div>

      <AlertModal
        show={alertModal}
        onHide={setAlertModal}
        actionModal={alertModalAction === 1 ? onSubmit : cancelObjective}
        message={alertModalAction === 1 ? "editar" : "borrar"}
      />
    </ModalWrapperDos>
  );
};

export default ModalObjetiveFuture;

export const InputObj = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  resize: none;
`;

export const ContainerModalText = styled.div`
  width: 100%;
  padding: 14px 21px;
  margin-top: 60px;

  .textContainer {
    font-size: 16px;
    color: #939396;
    font-weight: 500;
    padding-bottom: 5px;
  }

  .containerModal {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: end;
    font-size: 14px;

    .txt {
      margin-top: 8px;
      padding: 8px;
    }

    .containerTxt {
      background-color: white;
      border-radius: 8px;
      font-weight: 700;
      color: black;
      font-size: 14px;
      padding: 8px;
      margin: 8px;
      margin-right: 24px;
      width: 83%;
      border: 1px solid #f0f0f0;
    }
  }
`;

export const FooterModal = styled.div`
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: end;
  gap: 8px;
  padding: 14px 21px;
`;

export const ButtonModalSave = styled.div`
  background-color:${(props) => (props.color? "#3b63a2" : "#c1c1c3")};
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: ${(props) => (props.color ? "#fff" : "#fff")};
  padding: 8px 24px;
  cursor: pointer;
`;

export const ButtonModalErase = styled.div`
  background-color: #c9f2ff;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #3b63a2;
  padding: 8px 24px;
  cursor: pointer;
`;

export const ButtonModalCancel = styled.div`
  background-color: #3b63a2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #fff;
  padding: 8px 24px;
  cursor: pointer;
`;
