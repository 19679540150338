import { http } from '../api';

const getColaborators = async (query: string, IdPeriod: string) => {
  try {
    if (query !== "" && IdPeriod !== "") {
      const response = await http.get(`/Staff/collaborators/organigrama?nameToSearch=${query}&IdPeriod=${IdPeriod}`);
      return response;
    }

  } catch (error) {
    throw error;
  }
};

export { getColaborators };

