import { Select, TextFieldDos } from "../../ModalActions/InputsByTypeObjective";
import {
    ContainerModal
} from "../../ModalActions/ModalEditStyles";
import { PrimaryButton, SecondaryButton } from "../PoliticsStyles";

import FormModalNotify from "../../../../components/organisms/ConfirmationModals/FormModalNotify";

import Modal from "react-bootstrap/Modal";
const calificacion = [
  { text: "Selecciona opción", value: null },
  { text: "0", value: 0 },
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
];

const ModalPoliticas = ({
  show,
  handleClose,
  dataPolicies,
  handleChange,
  modalNotify,
  mensaje,
  onSubmit,
  disabledBtn,
}: any) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton />
        <Modal.Body >
          <strong>Insertar resultado de políticas</strong>
          <>
            <ContainerModal>
              <Select
                title="Calificación"
                id="calificacion"
                name="calificacion"
                value={dataPolicies}
                options={calificacion}
                onChange={(e) => handleChange(e)}
              />
              <TextFieldDos
                title="Total de preguntas"
                id="totalPreguntas"
                placeholder=""
                name="totalPreguntas"
                value={dataPolicies}
                type="number"
                maxL={3}
                onChange={(e) => handleChange(e)}
              />
              <TextFieldDos
                title="Total de aciertos"
                id="totalAciertos"
                placeholder=""
                name="totalAciertos"
                value={dataPolicies}
                type="number"
                maxL={3}
                onChange={handleChange}
              />
            </ContainerModal>
          </>
          {modalNotify ? <FormModalNotify mensaje={mensaje} /> : null}
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton onClick={handleClose}>Cancelar</SecondaryButton>
          <PrimaryButton onClick={onSubmit} disabled={disabledBtn}>
            Insertar resultado
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPoliticas;
