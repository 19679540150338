import { OrganizationChart } from "primereact/organizationchart";
import { useContext, useRef, useEffect } from "react";
import styled from "styled-components";
import arrowDown from "../../../../../assets/arrowDown.svg";
import arrowUp from "../../../../../assets/arrowUp.svg";
import logoOrg from "../../../../../assets/logoOrg.svg";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../../../context/EvaluationContext";
import { useCollaboratorRedux } from "../../../../../redux/Collaborators";
import { UpArrow } from "../../components/ProgresoEvaluaciones/ProgresoEvaluaciones.styles";
import { OrgChartContainer, Search } from "../../stylesDashboard/AdminStyles";


const EVALUATION_STATUS: any = {
  1: "Definir objetivos",
  2: "Evaluar",
  3: "Evaluar",
  4: "Ver evaluación",
  5: "Agendar entrevista",
  6: "Firmar",
  7: "Ver evaluación",
  8: "Ver evaluación",
};

const DashboardOrganigrama = ({
  setIdNodeStatus,
  handleClickNode,
  selection,
  collabData,
  setIdPeriodNode,
}: any) => {
  const filtrosAlmacenados = localStorage.getItem("principalFilters");
  const filtros = JSON.parse(filtrosAlmacenados ?? "{}");
  const { periodo } = filtros;

  const { setLoadingPagination } = useContext(
    EvaluationContext
  ) as EvaluationContextType;

  const { handleGetCollaborator, User } = useCollaboratorRedux();

  const organigramaRef = useRef<HTMLDivElement>(null);

  const centerOrganigrama = async () => {
    if (organigramaRef.current) {
      const firstNode = organigramaRef.current.querySelector('.p-organizationchart-lines div');
      if (firstNode) {
        firstNode.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'auto',
        });
      }
    }
  };

  useEffect(() => {
    if (organigramaRef.current) {
      requestAnimationFrame(() => {
        centerOrganigrama();
      });
    }
  }, [collabData]);

  const nodeTemplate = (node: any) => {
    const handleButtonClick = async (e: React.MouseEvent) => {
      //   console.log("se presiona el botón", node);
      setLoadingPagination(true);
      handleGetCollaborator(node.claTrab, periodo);
      setIdNodeStatus(node.idStatus);
      setIdPeriodNode(node.idEvaluacion);

      e.stopPropagation();
    };
    // console.log("Info collab", collabData, selection);

    // console.log(node, "node")
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {node.icon && <img alt="" src={node.icon} />}
        <div>
          <div style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px" }}>
            <strong>{node.puesto}</strong>
          </div>
          <div style={{ fontFamily: "Plus Jakarta Sans", fontSize: "14px" }}>
            {node.label}
          </div>
          {User.nombre !== node.label && (
            <button
              onClick={handleButtonClick}
              style={{
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "#C9F2FF",
                color: "#3B63A2",
                fontFamily: "Plus Jakarta Sans",
                marginTop: "8px",
                padding: "8px 8px",
                borderRadius: "8px",
                background: "#C9F2FF",
              }}
            >
              {EVALUATION_STATUS[node.idStatus]}
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <OrgChartContainer ref={organigramaRef}>
      <div>
        <OrganizationChart
          className="p-organizationchart-lines"
          style={{
            minWidth: "800px",
            width: "auto",
            overflowY: "auto",
            height: "400px",
          }}
          value={collabData}
          selectionMode="single"
          selection={selection}
          onSelectionChange={(e: any) => handleClickNode(e)}
          nodeTemplate={nodeTemplate}
          draggable="true"
        />
      </div>
    </OrgChartContainer>
  );
};

export default DashboardOrganigrama;

export const SearchBarOrganigrama = ({
  searchValue,
  handleSelectOrganigrama,
  handleClearSelection,
  setHide,
  hide,
}: any) => {
  //** Redux
  const { Collaborators } = useCollaboratorRedux();
  return (
    <>
      <WrapperHeaderOrganigrama>
        <h2 className="titleContainer">Organigrama</h2>
        <div className="containerToggle">
          <UpArrow
            alt=""
            src={hide ? arrowUp : arrowDown}
            onClick={() => setHide(!hide)}
          />
        </div>
      </WrapperHeaderOrganigrama>

      {hide && (
        <ContainerSearchBar>
          {Collaborators && Collaborators.length > 0 && (
            <SearchInput
              value={searchValue}
              onChange={(e) => handleSelectOrganigrama(e)}
            >
              {searchValue === "" && (
                <option value="">Seleccionar colaborador</option>
              )}
              {Collaborators.map((collaborator) => (
                <option key={collaborator.nombre} value={collaborator.nombre}>
                  {collaborator.nombre}
                </option>
              ))}
            </SearchInput>
          )}
          <SearchContainer>
            <Search onClick={handleClearSelection}>Limpiar selección</Search>
          </SearchContainer>
        </ContainerSearchBar>
      )}
    </>
  );
};

export const WrapperOrganigrama = ({ children, hContainer }: any) => {
  return (
    <WrapperContainer hContainer={hContainer}>{children}</WrapperContainer>
  );
};

export const WrapperHeaderOrganigrama = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 62px;

  .containerToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .titleContainer {
    color: var(--primary-black, #000);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Plus Jakarta Sans;
    font-size: 28px;
    font-style: normal;
    padding: 0px;
    margin: 0px;
    font-weight: 700;
    line-height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const ContainerSearchBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  margin-bottom: 40px;
  margin-top: 10px;
`;

export const WrapperContainer = styled.section.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  /* border : 1px solid red; */
  min-height: ${(props) => (props.hContainer ? "500px" : "100px")};
  background-color: #ffffff;
  padding: 24px;
`;

export const SearchContainer = styled.div`
  gap: 0;
  display: flex;
  background-color: #fff;
  height: 33px;
`;

export const SearchInput = styled.select`
  display: flex;
  width: 259px;
  height: 33px;
  align-items: center;
  gap: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px 0px 0px 8px;
  background: #fff;
  font-size: 14px;
  color: #939396;
  outline: none;
  padding-left: 8px;
`;

type PropTypeBgTres = {
  hContainer: any;
};

//! ++++++++++++++++++++++++++++Funciones++++++++++++++++++++++++++++

export function convertToTreeStructure(
  data: any[],
  isRoot: boolean = true
): TreeNode[] {
  // console.log("Collaborators", data);
  return data.map((item, index) => ({
    label: item?.nombre,
    selectable: true,
    idStatus: item?.idCatEstatus,
    icon: isRoot ? logoOrg : null,
    puesto: item?.puesto,
    idEvaluacion: item?.idEvaluacion,
    claTrab: item?.claTrab,
    descripcionEstatus: item?.descripcionEstatus,
    children:
      item?.hijos?.length > 0 ? convertToTreeStructure(item?.hijos, false) : [],
    style: {
      borderRadius: "12px",
      borderWidth: "3px",
      borderColor:
        item?.idCatEstatus === 1
          ? "#F38382"
          : item?.idCatEstatus === 2
          ? "#F4A886"
          : item?.idCatEstatus === 3
          ? "#F6C088"
          : item?.idCatEstatus === 4
          ? "#F6D98B"
          : item?.idCatEstatus === 5
          ? "#D6DA88"
          : item?.idCatEstatus === 6
          ? "#B1D885"
          : item?.idCatEstatus === 7
          ? "#84D882"
          : "#FFF",
    },
    isRoot: isRoot,
  }));
}

interface TreeNode {
  label: string;
  selectable: boolean;
  children: TreeNode[];
  idStatus: number;
  claTrab: number;
}
