/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { validateIdPeriod } from "../../../../context/validacionesFiltros";
import { FliterByTiemRow } from "../../../../pages/Admin/Dashboard/stylesDashboard/AdminStyles";
import {
  ContainModal,
  StyleInputs,
} from "../../../../pages/Admin/Objectives/ObjetivesStyles";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../pages/Admin/Politics/PoliticsStyles";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import { postInterviewServices } from "../../../../services/evaluation.service";
import { Date } from "../../../common/InputsByType";
import { TitleModal } from "../styleModals";

function BookingInterview({
  showBookInterview,
  filterSearch,
  onHide,
  evaluation,
  evaluation_period_id,
  setError,
  setSuccess,
  setMessage,
}: any) {
  const [inputValues, setInputValues] = useState({});
  const [interviewDate, setInterviewDate] = useState("");
  const { User, handleGetCollaborators, Meta } = useCollaboratorRedux();
  const onChangeTextField = (e: any) => {
    e.preventDefault();
    setInterviewDate(e.target.value);
  };

  // useEffect(() => {
  //   console.log("aquiiiiii ", Meta.currentPage);
  // }, [Meta]);

  const onSubmit = async (e: any) => {
    // console.log("filterSearch: ", filterSearch);
    await postInterviewServices(evaluation.employeeEvaluationId, interviewDate)
      .then((res) => {
        setSuccess(true);
        setMessage("Se ha agendado la entrevista correctamente.");
      })
      .catch((err) => {
        setError(true);
        setMessage("Se ha agendado la entrevista correctamente.");
      });
      // console.log("<Se hace petición>");
      const periodo = validateIdPeriod(evaluation_period_id)
    handleGetCollaborators(
      filterSearch === "" || null
        ? `?NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=${Meta.currentPage}`
        : filterSearch
    );
    //  `?IdPeriod=${evaluation.evaluationPeriod.evaluationPeriodId}&&IdEvaluator=${User.claTrab}&PageSize=10&PageNumber=${Meta?.currentPage}`
    setSuccess(true);
    onHide();
  };
  // console.log("Se setea el modal!!!");

  return (
    <Modal show={showBookInterview} size="lg" onHide={onHide} backdrop="static">
      <ContainModal>
        <Modal.Header closeButton>
          <TitleModal style={{ width: "250px" }}>
            Selecciona la fecha de entrevista
          </TitleModal>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <FliterByTiemRow>
              <StyleInputs>
                <Date
                  id="date"
                  title="Fecha"
                  name="date"
                  onChange={onChangeTextField}
                  value={inputValues}
                />
              </StyleInputs>
              <div
                style={{ marginBottom: "1rem", display: "flex", gap: "8px" }}
              >
                <PrimaryButton onClick={onSubmit}>Agendar cita</PrimaryButton>
                <SecondaryButton onClick={onHide}>Cancelar</SecondaryButton>
              </div>
            </FliterByTiemRow>
          </Container>
        </Modal.Body>
      </ContainModal>
      {/* {success ? (
          <ModalStatusSuccessful
            handleClick={setSuccess}
            modalStatus={success}
            messageShow
            message={message}
          />
        ) : null}
        {error ? (
          <ModalStatusTargetError
            handleClick={setError}
            modalStatus={error}
            messageShow
            message={message}
          />
        ) : null} */}
    </Modal>
  );
}

export default BookingInterview;
