import { ApiResponse, IPeriod, IGetIsCreatingEvaluationsResponse } from "../Interface/ApiInterface";
import { http } from "../api";

const getAllPeriodsServices = async (query: string) => {
  return new Promise<ApiResponse<IPeriod[]>>((resolve, reject) => {
    http
      .get(`Period${query}`)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createEvaluationPeriodsServices = async (
  idPeriod: number,
  cltrabEvaluator: number
) => {
  return new Promise<ApiResponse<IPeriod>>((resolve, reject) => {
    http
      .post(
        `Evaluations/create-evaluation-period?idPeriod=${idPeriod}&ClaTrab=${cltrabEvaluator}`
      )
      .then((response) => {
        const { data } = response;
        const res = data;
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


const postPeriodsServices = async (
  period: IPeriod,
  cltrabEvaluator: number
) => {
  return new Promise<ApiResponse<IPeriod>>((resolve, reject) => {
    http
      .post(`Period?ClaTrabEvaluator=${cltrabEvaluator}`, period)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const putPeriodsServices = async (id: number, period: IPeriod) => {
  return new Promise<ApiResponse<IPeriod>>((resolve, reject) => {
    http
      .put(`Period/${id}`, period)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deletePeriodsServices = async (id: number) => {
  return new Promise<ApiResponse<IPeriod>>((resolve, reject) => {
    http
      .delete(`Period/${id}`)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  getAllPeriodsServices,
  postPeriodsServices,
  putPeriodsServices,
  deletePeriodsServices,
  createEvaluationPeriodsServices
};

