import FileSaver from "file-saver";
import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import colladoLoader from '../../../../../assets/colladoLoader.gif';
import Caution from "../../../../../components/organisms/ConfirmationModals/Caution";
import ModalStatusSuccessful from "../../../../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import { MasivePatchUploadServices } from "../../../../../services/politics.services";
import { ContainButtonMobileDos, ContainerBar, ContainerCarga, ContainerDetaiCarga, ImgSize, RowBox, TextCarga } from "../../../Objectives/ObjetivesStyles";
import cloud from "../../../Objectives/images/cloud.svg";
import fileimg from "../../../Objectives/images/file.svg";
import { ButtonFile, ContainerUploadDos, ContainerUploadPrev, Paragraph, RowBoxTres, SubTitle, WrapperButton } from "../../../Politics/PoliticsStyles";
import { UpArrow } from "../ProgresoEvaluaciones/ProgresoEvaluaciones.styles";
import { ContainerCalificacionEmpresariales, MainContainer, SubtitleCard, SubtitlesContainer, Title } from "./AgregarArchivos.styles";

import arrowDown from "../../../../../assets/arrowDown.svg";
import arrowUp from "../../../../../assets/arrowUp.svg";

export const AgregarArchivos = () => {

  const [politicsFile, setPoliticsFile] = useState<File>();
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [messageModalDos, setMessageModalDos] = useState("");
  const [rowsInsertedResponse, setRowsInsertedResponse] = useState<number>();
  const [rowsNotInsertedResponse, setRowsNotInsertedResponse] = useState<number>();
  const [excelFile, setExcelFile] = useState<any>();
  const [excelFileName, setExcelFileName] = useState<any>();
  const [succesPost, setSuccesPost] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const [hide, setHide] = useState(false)

  const onInputClick = (event: any) => {
    event.target.value = "";
  };

  const onChangeUploadGeneral = (event: any) => {
    const receiveFile = event.target.files?.[0];
    if (receiveFile) {
      setPoliticsFile(receiveFile);
      setShowModalCaution(true);
    }
  };

  const uploadFileGeneral = async () => {
    if (politicsFile) {
      setIsLoading(true);
      await MasivePatchUploadServices(politicsFile)
        .then((response) => {
          const { message, rowsInserted, rowsNotInserted, excelContent } = response;
          setShowModalCaution(false);
          setMessageModal(message);
          setSuccesPost(true);
          setRowsInsertedResponse(rowsInserted);
          setRowsNotInsertedResponse(rowsNotInserted);
          if (excelContent) {
            const byteCharacters = atob(excelContent.fileContents);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: excelContent.contentType });
            setExcelFile(blob)
            setExcelFileName(excelContent.fileDownloadName);
          }
        })
        .catch((error) => {
          setShowModalCaution(false);
          setMessageModalDos("No pudimos cargar el archivo.");
          setIsLoading(true);
          //setErrorPost(true);
        })
        .finally(() => {
          setIsLoading(false); // Set isLoading to false regardless of success or error
        });
    }
  };


  const downloadExcelFile = () => {
    if (excelFile) {
      FileSaver.saveAs(excelFile, excelFileName);
    }
  };

  // console.log(excelFile)

  // console.log("respuesta de la solicutud",excelFile)
  return (
    <ContainerCalificacionEmpresariales>
      <Title>
        Calificación en lote de objetivos empresariales
        <div className="containerToggle">
          <UpArrow
            alt=""
            src={hide ? arrowUp : arrowDown}
            onClick={() => setHide(!hide)}
          />
        </div>
      </Title>
      {hide &&
        (<div style={{ marginTop: "24px" }}>

          <SubtitlesContainer>
            <SubtitleCard>Cargar calificaciones</SubtitleCard>
            <SubtitleCard style={{ paddingLeft: "20px" }}>Previsualización</SubtitleCard>
          </SubtitlesContainer>


          <MainContainer>
            <ContainerUploadDos>
              <RowBoxTres>
                <img src={cloud} alt="upload" />
                <div>
                  <Paragraph>Selecciona el archivo a cargar</Paragraph>
                  <SubTitle>
                    Formato de Excel peso de {politicsFile?.size.toString().slice(0, 2)} KB

                  </SubTitle>
                </div>
              </RowBoxTres>

              <ContainButtonMobileDos>
                <WrapperButton>
                  {isLoading ? 'Cargando' : "Seleccionar"}
                  <ButtonFile
                    type="file"
                    name="src-file1"
                    aria-label="Archivo"
                    onClick={onInputClick}
                    onChange={onChangeUploadGeneral}
                    accept=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </WrapperButton>
              </ContainButtonMobileDos>
              {isLoading && <img style={{ width: "42px" }} src={colladoLoader} alt="" />}
            </ContainerUploadDos>





            <ContainerUploadPrev>
              <RowBox>
                <ImgSize src={fileimg} alt="file" />
                <ContainerCarga>
                  <ContainerDetaiCarga>
                    <TextCarga>Excel</TextCarga>
                    <TextCarga> {politicsFile?.size.toString().slice(0, 2)} kB</TextCarga>
                  </ContainerDetaiCarga>
                  <ContainerBar>
                    <ProgressBar
                      variant="warning"
                      now={politicsFile?.size ? 100 : 0}
                    ></ProgressBar>
                  </ContainerBar>
                </ContainerCarga>
              </RowBox>
            </ContainerUploadPrev>


          </MainContainer>
        </div>
        )}
      <Caution
        textBody="Se realizará la carga masiva de datos desde Excel. Asegúrate de que el archivo esté correctamente estructurado. "
        //textBodyOk={messageModal}
        textBodyQuestion='¿Deseas continuar?'
        messageShow={true}
        title="Confirmar carga masiva"
        estado={showModalCaution}
        cambiarEstado={setShowModalCaution}
        textButtonAct="Continuar"
        textButtonClose="Cancelar"
        handleClick={uploadFileGeneral}
      />
      {succesPost ? (
        <ModalStatusSuccessful
          handleClick={setSuccesPost}
          modalStatus={succesPost}
          messageShow
          message={ rowsNotInsertedResponse ?  `Hemos cargado ${rowsInsertedResponse} registros con éxito.\r\nSin embargo se detectaron ${rowsNotInsertedResponse} errores en el proceso. Por favor, revisa el archivo y cárgalo nuevamente. ` : `Hemos cargado ${rowsInsertedResponse} registros con éxito. No se han detectado errores en el proceso. `}
          onClick={downloadExcelFile}
          excelFile={excelFile}
          rowsNotInsertedResponse={rowsNotInsertedResponse}
          titleModal={rowsNotInsertedResponse ? `Carga de registros parcial` : `Carga de registros completa`}
        />

      ) : null}
    </ContainerCalificacionEmpresariales>
  );
};

export default AgregarArchivos;
