import styled from "styled-components";

type PropTypeBg = {
  showData?: any;
  fontSz?: string;
  lineH?: string;
};

export const ContainerModal = styled.div.attrs(
  (props: PropTypeBg) => ({})
)<PropTypeBg>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.showData ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalBox = styled.div`
  background-color: #fff;
  /* height: 233px; */
  max-width: 699px;
  width: 100%;
  z-index: 2;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
  //gap:32px;
  padding: 32px;

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    /* height: 200px; */
  }
`;

export const Header = styled.div`
  display: flex;
  /* height: 50px; */
  width: 100%;
  /* border: 1px solid #e5e5e5; */
  justify-content: space-between;
  align-items: center;
  color: var(--primary-true-blue, #3B63A2);
font-feature-settings: 'clig' off, 'liga' off;
/* Title */
color: var(--primary-true-blue, #3B63A2);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Plus Jakarta Sans;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 110%; /* 22px */

  @media (max-width: 768px) {
    /* width: 90%; */
    font-size: 32px;
  }
`;

export const ImageIcon = styled.img`
  height: 20px
`;

export const CloseIcon = styled.img`
  height: 16px;
  cursor: pointer;
`;

export const TextModal = styled.div.attrs(
  (props: PropTypeBg) => ({})
)<PropTypeBg>`
  font-family: "Plus Jakarta Sans", sans-serif;
  /* font-weight: 400; */
  font-size: ${(props) => props.fontSz || "32.62px"};
  line-height: ${(props) => props.lineH || "45.67px"};
  color: var(--neutrals-jet, #333335);
font-feature-settings: 'clig' off, 'liga' off;
margin-top:32px;
/* Body */
color: var(--neutrals-jet, #333335);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Plus Jakarta Sans;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 22.4px */

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 30px;
    /* border: 1px solid; */
    line-height: 26px;
    font-size: 22px;
  }
`;

export const Loader = styled.div`
  /* width: 48px;
    height: 48px;
    border: 8px solid gray;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    background-color: white;
    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }  */

  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgba(255, 185, 230, 0.2) 5%, gray 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  ::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fff;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ContainerTxt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #3B63A2;
`;

export const ButtonContinuar = styled.button`
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #3B63A2;
  background: var(--primary-true-blue, #3B63A2);
  color: var(--neutrals-white, #FFF);
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;


export const ButtonContainer = styled.div`
  display: flex;
  width:100%;
  justify-content: center;
  margin-top: 32px;
`
