/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EvaluationInterface, EvaluationNew } from "../Interface/ApiInterface";
import { actionEvent } from "../common/utils";

import {
  deleteEvaluationServices,
  getEvaluationServices,
  postEvaluationFirmaServices,
  postEvaluationServices,
  postInterviewServices,
  putEvaluationServices
} from "../services/evaluation.service";
import { setIndividualObjetivesServices } from "../services/objetives.services";
import { RootReducerInterface } from "./store";
import { useLoader } from "../context/LoaderContext";
/**
 * Action Types
 */
export enum EvaluationActions {
  GET_EVALUATION_SUCCESS = "GET_EVALUATION_SUCCESS",
  GET_EVALUATION_ERROR = "GET_EVALUATION_ERROR",
  POST_EVALUATION_SUCCESS = "POST_EVALUATION_SUCCESS",
  POST_EVALUATION_ERROR = "POST_EVALUATION_ERROR",
  PUT_EVALUATION_SUCCESS = "PUT_EVALUATION_SUCCESS",
  PUT_EVALUATION_ERROR = "PUT_EVALUATION_ERROR",
  DELETE_EVALUATION_SUCCESS = "DELETE_EVALUATION_SUCCESS",
  DELETE_EVALUATION_ERROR = "DELETE_EVALUATION_ERROR",
  POST_INTERVIEW_SUCCESS = "POST_INTERVIEW_SUCCESS",
  POST_INTERVIEW_ERROR = "POST_INTERVIEW_ERROR",
  PUT_EVALUATION = "PUT_EVALUATION",
  POST_EVALUATION_FIRM = "POST_EVALUATION_FIRM",
}

/**
 * Interfaces
 */
interface EvaluationBaseInterface {
  Evaluation: EvaluationNew;
  Evaluations: EvaluationInterface[];
  loading: boolean;
  success: boolean;
  error: boolean;
  message: string;
  setSuccess: (succes: boolean) => void;
  setMessage: (message: string) => void;
  setError: (error: boolean) => void;
  errorMessages: string;
  dataChanged: any;
  successMessageSaveEvaluation: any;
  errorMessageSaveEvaluation: any;
  setErrorMessageSaveEvaluation: (succes: any) => void;
  setSuccessMessageSaveEvaluation: (succes: any) => void;
  // OpportunitiesEvaluationInterface: OpportunitiesEvaluationInterface[];
}

interface UseEvaluationInterface extends EvaluationBaseInterface {
  handleGetEvaluation: (
    ClaTrabEvaluator: number,
    ClaTrabEvaluated: number,
    idPeriod: number
  ) => Promise<void>;
  handlePostEvaluation: (body: EvaluationNew) => void;
  handleUpdateEvaluation: (id: number, body: EvaluationNew) => void;
  handleDeleteEvaluation: (id: number) => void;
  handleSetIndividualObjetives: (
    id: number,
    objetivos: any,
    idDos?: any,
    employeeEvaluation?: any
  ) => void;
  handleSetEvaluation: (Evaluation: EvaluationNew) => void;
  handleSetInterview: (id: number, fecha: String) => void;
  hanldeUpdateEvaluation: (Evaluation: any) => void;
  hanldeSaveEvaluation: (Evaluation: any) => void;
  handleSetEvaluationFirm: (idEvaluation: number, type: string) => void;
}

interface ReducerActionsInterface {
  type: EvaluationActions;
  payload: EvaluationBaseInterface;
}

/**
 * Hooks
 */
const useEvaluationRedux = (): UseEvaluationInterface => {
  const { incrementRequests, decrementRequests } = useLoader();
  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [errorMessages, setErrorMesagges] = useState("");
  const [message, setMessage] = useState("");

  //** Save evaluation
  const [errorMessageSaveEvaluation, setErrorMessageSaveEvaluation] =
    useState("");
  const [successMessageSaveEvaluation, setSuccessMessageSaveEvaluation] =
    useState("");

  //** Save evaluation
  const dispatch = useDispatch();

  //?? Mezclamos useContext y Redux
  // const { setFutureObjetives } = useContext(EvaluationContext) as EvaluationContextType;

  const { Evaluations, Evaluation, dataChanged } = useSelector(
    (state: RootReducerInterface) => state.Evaluations
  );

  const handleSetEvaluationFirm = async (
    idEvaluation: number,
    type: string
  ): Promise<void> => {
    // console.log("Esto se seteará", idEvaluation, type);

    setLoading(true);
    incrementRequests();
    const { data, meta } = await postEvaluationFirmaServices(
      idEvaluation,
      type
    );
    // console.log("data -> ", data);

    setLoading(false);
    try {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.POST_EVALUATION_FIRM,
          payload: {
            Evaluation: data,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.POST_EVALUATION_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleSetEvaluation = async (
    Evaluation: EvaluationNew
  ): Promise<void> => {
    
    try {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.GET_EVALUATION_SUCCESS,
          payload: {
            Evaluation: Evaluation,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.GET_EVALUATION_ERROR,
        })
      );
    }
  };

  const hanldeUpdateEvaluation = async (Evaluation: any): Promise<void> => {
    // console.log("Esto se manda", Evaluation);

    try {
      // await updateEvaluationServices(Evaluation);

      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.PUT_EVALUATION,
          payload: {
            Evaluation,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.PUT_EVALUATION_ERROR,
          payload: {
            Evaluation: Evaluation,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages: "",
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    }
  };

  const hanldeSaveEvaluation = async (Evaluation: any): Promise<void> => {
    // console.log("Esto se manda a guardar", Evaluation);

    try {
      // const { data } = await saveEvaluationServices(Evaluation);
      // console.log("error", 	)
      // setSuccess(true);
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.PUT_EVALUATION,
          payload: {
            Evaluation,
            Evaluations,
            loading,
            success: true,
            error,
            message: "Se ha guardado correctamente.",
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e: any) {
      // console.log("error", e?.response?.data?.title);
      // setError(true);
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.PUT_EVALUATION_ERROR,
          payload: {
            Evaluation: Evaluation,
            Evaluations,
            loading,
            success,
            error: true,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages: "Error al guardar",
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    }
  };

  const handleSetInterview = async (
    id: number,
    fecha: String
  ): Promise<void> => {
    try {
      setLoading(true);
      incrementRequests();

      // console.log("id", id, "fecha", fecha);
      setSuccess(true);
      await postInterviewServices(id, fecha);
      // setSuccess(true);
      // setMessage("Se ha agendado la entrevista correctamente.");
      // setLoading(false);
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.POST_INTERVIEW_SUCCESS,
          payload: {
            Evaluation,
            Evaluations,
            loading,
            success: true,
            error,
            message: "Se ha agendado la entrevista correctamente.",
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      setError(true);
      setMessage("No se agendó la entrevista correctamente.");
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.POST_INTERVIEW_ERROR,
          payload: {
            Evaluation,
            Evaluations,
            loading,
            success,
            error: true,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleGetEvaluation = async (
    ClaTrabEvaluator: number,
    ClaTrabEvaluated: number,
    idPeriod: number
  ): Promise<void> => {
    try {
      setLoading(true);
      incrementRequests();
      const { data, meta } = await getEvaluationServices(
        ClaTrabEvaluator,
        ClaTrabEvaluated,
        idPeriod
      );

      // console.log("aquí cambia esto", data, idPeriod);

      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.GET_EVALUATION_SUCCESS,
          payload: {
            Evaluation: data,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged: meta,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.GET_EVALUATION_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handlePostEvaluation = async (body: EvaluationNew): Promise<void> => {
    try {
      setLoading(true);
      incrementRequests();
      await postEvaluationServices(body);
      setLoading(false);

      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.POST_EVALUATION_SUCCESS,
          payload: {
            Evaluation,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.POST_EVALUATION_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleUpdateEvaluation = async (
    id: number,
    body: EvaluationNew
  ): Promise<void> => {
    try {
      // console.log('Esto se posteará: ',id, body);

      setLoading(true);
      incrementRequests();
      await putEvaluationServices(id, body);
      setLoading(false);

      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.PUT_EVALUATION_SUCCESS,
          payload: {
            Evaluation,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.PUT_EVALUATION_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleDeleteEvaluation = async (id: number): Promise<void> => {
    try {
      setLoading(true);
      incrementRequests();
      await deleteEvaluationServices(id);
      setLoading(false);

      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.DELETE_EVALUATION_SUCCESS,
          payload: {
            Evaluation,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.DELETE_EVALUATION_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleSetIndividualObjetives = async (
    id: number,
    objetivos: any,
    idDos?: any,
    employeeEvaluation?: any
  ) => {
    try {
      setLoading(true);
      incrementRequests();
      const { data, meta } = await setIndividualObjetivesServices(
        id,
        objetivos
      );

      setLoading(false);
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.GET_EVALUATION_SUCCESS,
          payload: {
            Evaluation: data,
            Evaluations,
            loading,
            success,
            error,
            message,
            setMessage,
            setSuccess,
            setError,
            errorMessages,
            dataChanged,
            successMessageSaveEvaluation,
            errorMessageSaveEvaluation,
            setErrorMessageSaveEvaluation,
            setSuccessMessageSaveEvaluation,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<EvaluationActions, EvaluationBaseInterface>({
          type: EvaluationActions.GET_EVALUATION_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  return {
    loading,
    Evaluations,
    Evaluation,
    success,
    error,
    message,
    setMessage,
    setError,
    setSuccess,
    handlePostEvaluation,
    handleUpdateEvaluation,
    handleDeleteEvaluation,
    handleGetEvaluation,
    handleSetIndividualObjetives,
    handleSetEvaluation,
    handleSetInterview,
    hanldeUpdateEvaluation,
    hanldeSaveEvaluation,
    handleSetEvaluationFirm,
    errorMessages,
    dataChanged,
    successMessageSaveEvaluation,
    errorMessageSaveEvaluation,
    setErrorMessageSaveEvaluation,
    setSuccessMessageSaveEvaluation,
  };
};

const useViewState = () => {
  const [view, setView] = useState("");

  return { view, setView };
};

/**
 * Reducers
 */
const initialState: EvaluationBaseInterface = {
  loading: false,
  success: false,
  error: false,
  message: "",
  dataChanged: "Hola",
  Evaluations: [] as EvaluationInterface[],
  Evaluation: {} as EvaluationNew,
  errorMessages: "",

  setMessage: function (message: string) {
    throw new Error("Function not implemented.");
  },
  setSuccess: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
  setError: function (error: boolean): void {
    throw new Error("Function not implemented.");
  },
  successMessageSaveEvaluation: false,
  errorMessageSaveEvaluation: false,
  setErrorMessageSaveEvaluation: function (error: boolean): void {
    throw new Error("Function not implemented.");
  },
  setSuccessMessageSaveEvaluation: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
};

const EvaluationReducer = (
  state = initialState,
  action: ReducerActionsInterface
): EvaluationBaseInterface => {
  switch (action.type) {
    case EvaluationActions.GET_EVALUATION_SUCCESS:
      return {
        ...state,
        Evaluation: action.payload.Evaluation,
        Evaluations: action.payload.Evaluations,
        loading: action.payload.loading,
        success: action.payload.success,
        error: action.payload.error,
        dataChanged: action.payload.dataChanged,
      };
    case EvaluationActions.PUT_EVALUATION_ERROR:
      return {
        ...state,
        errorMessages: action.payload.errorMessages,
      };
    default:
      return state;
  }
};

/**
 * Exports
 */
export { EvaluationReducer, useEvaluationRedux, useViewState };
export type { EvaluationBaseInterface, UseEvaluationInterface };

