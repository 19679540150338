import { FC } from "react";
import Loading from "../../../assets/icons/Loading.png";
import Close from "../../../assets/icons/CloseModal.svg";
// import { ContainerModal } from "../../../pages/Admin/Objectives/ObjetivesStyles";
import {
  CloseIcon,
  ContainerModal,
  Header,
  ImageIcon,
  ModalBox,
  TextModal,
} from "./ModalStatusStyles";

interface Labels {
  showData?: boolean | true;
}

export const ModalStatusTargetLoading: FC<Labels> = ({ showData }) => {
  return (
    <ContainerModal showData={showData ? "flex" : "none"}>
      <ModalBox>
        <Header style={{ color: "#3B63A2" }}>
          <div>
            <ImageIcon src={Loading} style={{ marginRight: "12px" }} />
            Espera
          </div>
          <CloseIcon src={Close} />
        </Header>
        <TextModal>
          Se esta agregando el archivo, esto puede tardar unos minutos
        </TextModal>
      </ModalBox>
    </ContainerModal>
  );
};

export default ModalStatusTargetLoading;
