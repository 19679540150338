import { useContext, useEffect, useState } from "react";
import { getPercentage } from "../../../../../services/percentage.service";
//styles
import {
  CircleTable,
  InnerContainer,
  MainContainer,
  PedienteText,
  PorcentaeContainer,
  ProgresBarContainer,
  ProgressBar,
  RowContain,
  RowTable,
  Separator,
  TableText,
  Title,
  TitleContainer,
  UpArrow,
} from "./ProgresoEvaluaciones.styles";

//assets
import arrowDown from "../../../../../assets/arrowDown.svg";
import arrowUp from "../../../../../assets/arrowUp.svg";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../../../context/EvaluationContext";
import { validateIdPeriod } from "../../../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../../../redux/Collaborators";
import { getEvaluationReminder } from "../../../../../services/evaluation.service";
import Caution from "./Caution";
import RecordatorioEvaluaciones from "./ModalRecordatorio/RecordatorioEvaluaciones";

export default function ProgresoEvaluaciones(props: ProgresoEvaluacionesProps) {
  const { setLoadingPagination } = useContext(
    EvaluationContext
  ) as EvaluationContextType;

  const [percentage, setPercentage] = useState<any| null>(null);
  const [error, setError] = useState<string | null>(null);
  const [hide, setHide] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const periodo = validateIdPeriod(props.idPeriod);

  const [openModal, setOpenModal] = useState(false);

  const [messageModal, setMessageModal] = useState("");
  const { evaluationAttribute } = useCollaboratorRedux();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getPercentage(periodo, props.name, props.role);

        if (response !== undefined) {
          const porcentaje = response.data; // Ajusta esto según la estructura real de tu respuesta.
          const repuesta = Object.entries(porcentaje.data).filter(([k, v]) =>
            k.includes("Status")
          );
          setPercentage(repuesta as []);
          setError(null); // Limpiar cualquier error previo si la solicitud es exitosa
        }
      } catch (error) {
        setError("Error al obtener el porcentaje"); // Configurar mensaje de error
      }
    };

    fetchData();
  }, [props.idPeriod]);

  const handleClick = async () => {
    try {
      setLoadingPagination(true);
      const idPeriod = periodo; // Reemplaza con el ID del período adecuado
      await getEvaluationReminder(idPeriod)
        .then((res) => {
          // setSuccess(res.success);
          // setMessageModal(res);
          // console.log(res);
        })
        .catch((err) => {
          setError(err.success);
          // console.log(err);
          // setMessageModal(err);
        });
      // Maneja la respuesta exitosa aquí
      // console.log("Respuesta exitosa:", result);

      setShowModal(!showModal);
      setLoadingPagination(false);
    } catch (error) {
      // Maneja el error aquí
      // console.error("Error al obtener el recordatorio de evaluación:", error);
    }
  };

  // console.log(percentage && percentage[0][0]);
  return (
    <MainContainer>
      {showModal ? (
        <RecordatorioEvaluaciones
          showModal={showModal}
          setShowModal={setShowModal}
        />
      ) : null}

      <TitleContainer>
        <Title>Progreso de evaluaciones</Title>
        <UpArrow
          alt=""
          src={hide ? arrowUp : arrowDown}
          onClick={() => setHide(!hide)}
        />
      </TitleContainer>

      <ProgresBarContainer>
        {percentage?.map((item: any, index: number) => (
          <ProgressBar
            key={item[0] && item[0]}
            style={{
              backgroundColor: colors[index],
              width: `${Math.round(item[1].percentage)}%`,
            }}
          />
        ))}
      </ProgresBarContainer>

      {hide ? (
        <>
          <RowTable>
            {percentage?.map((item: any, index: number) => (
              <>
                <InnerContainer>
                  <RowContain>
                    <CircleTable
                      style={{
                        backgroundColor: colors[index],
                      }}
                    />
                    <TableText>{titles[index]}</TableText>
                  </RowContain>
                  <PorcentaeContainer>
                    {titles[index] === "Pendiente de evaluar" &&
                      evaluationAttribute.btn_send_reminder === 1 && (
                        <PedienteText onClick={() => setOpenModal(true)}>
                          Enviar recordatorio
                        </PedienteText>
                      )}
                    <TableText>{Math.round(item[1].percentage)}%</TableText>
                    <TableText>{item[1].total} evaluaciones</TableText>
                  </PorcentaeContainer>
                </InnerContainer>
                <Separator />
              </>
            ))}
          </RowTable>
        </>
      ) : null}
      <Caution
        textBody="Esta acción implica enviar un recordatorio. Antes de continuar, tómate un momento para confirmar, ya que esta acción es irreversible."
        textBodyOk={messageModal}
        textBodyQuestion="¿Estás seguro de enviar un recordatorio?"
        messageShow={true}
        title="Atención"
        estado={openModal}
        cambiarEstado={setOpenModal}
        textButtonAct="Continuar"
        textButtonClose="Cancelar"
        handleClick={handleClick}
      />
    </MainContainer>
  );
}

interface ProgresoEvaluacionesProps {
  idPeriod: any;
  name: any;
  role: any;
}

interface Estatus {
  total: number;
  percentage: number;
}

const colors = [
  "#F38382",
  "#F4A886",
  "#F6C088",
  "#F6D98B",
  // '#D6DA88',
  "#B1D885",
  "#84D882",
];
const titles = [
  "Definición de objetivos",
  "Nuevo Ingreso",
  "Pendiente de evaluar",
  "Pendiente de liberar",
  // 'Pendiente de agendar',
  "Pendiente de firmar",
  "Completadas",
];
