/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import {
  IGeneralObjetives,
  IIndividualObjetives,
} from "../../Interface/ApiInterface";
import {
  StyleButon,
  StyleButonB,
} from "../../components/atoms/Modals/styleModals";
import {
  Date,
  Select,
  TextField,
  TextFieldLong,
} from "../../components/common/InputsByType";
import GetCurrentDate from "../../pages/Admin/Objectives/GetDate";
import {
  ContainModal,
  StyleInputs,
  TitleModal,
} from "../../pages/Admin/Objectives/ObjetivesStyles";
import { useObjetives } from "../../redux/Objetives";
import InfoModal from "../atoms/Modals/InfoModal";
import ModalStatusTargetError from "../organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../organisms/ConfirmationModals/ModalStatusSuccessful";
import "./GoalsModalStyle.css";

const onlyWords = [
  "generalObjetivesInfinitiveVerb",

  "generalObjetivesKpiConcept",
  "generalObjetivesAdverb",
  "locations",
  "departament",
  "area",
  "company",
  "department",
  "generalObjetviesMathExpression",
  "classifier",
  "generalObjetivesPreposition",
  "generalObjetivesQuantityIndicator",
  "generalObjetivesDate",
  "fGeneralObjetivesDate",
  "generalObjectivesSentence",
  "IngresarOracion",
];
const onlyNumbers = [
  "generalObjetivesGoal",
  "generalObjetivesValue",
  "generalObjetivesYear",
  "generalObjetivesSemester",
];
// const now = new Date();
interface ObjetivesModal {
  titleModal?: string;
  show?: any;
  setShow?: any;
  evaluation_period_id?: any;
  setShowOther?: any;
  setOpenModal?: any;
  openModal?: any;
  inputValues?: any;
  setInputValues?: any;
}

const fecha = GetCurrentDate().toISOString();

const GoalsModal: FC<ObjetivesModal> = ({
  titleModal,
  show,
  setShow,
  evaluation_period_id,
  setShowOther,
  setOpenModal,
  openModal,
  inputValues,
  setInputValues,
}) => {
  const {
    EditObjetive,
    handleUpdateGeneralObjetives,
    handleCreateGeneralObjetives,
    handleCreateIndividualObjetives,
    handleGetAllIndividualObjetives,
    handleGetAllGeneralObjetives,
    setSuccesPost,
    setErrorPost,
    yearsSemesters,
    succesPost,
    errorPost,
  } = useObjetives();

  const mathExpression = [
    { text: "Selecciona opción", value: "0" },
    { text: "igual", value: "igual" },
    { text: "menor que", value: "menor que" },
    { text: "mayor que", value: "mayor que" },
    { text: "menor igual que", value: "menor igual que" },
    { text: "mayor igual que", value: "mayor igual que" },
  ];
  const divisionData = [
    { text: "Selecciona opción", value: "0" },
    { text: "División", value: "División" },
    { text: "Global", value: "Global" },
  ];

  const semesterArray = [
    { text: "Selecciona opción", value: "0" },
    { text: "1", value: "1" },
    { text: "2", value: "2" },
  ];
  // const [inputValues, setInputValues] = useState({});
  const [newGeneralObjetive, setNewGeneralObjetive] =
    useState<IGeneralObjetives>({} as IGeneralObjetives);
  const [newIndividualObjetive, setNewIndividualObjetive] =
    useState<IIndividualObjetives>({} as IIndividualObjetives);

  const [inputLength, setInputLength] = useState(0);
  const [mensaje, setMensaje] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [disabledButtonGeneralSentence, setDisabledButtonGeneralSentence] =
    useState(false);
  const [disabledButtonNone, setDisabledButtonNone] = useState(false);

  const [period, setPeriod] = useState({
    generalObjetivesYear: 0,
    generalObjetivesSemester: 0,
  });

  const onChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // console.log("Entra un valor", name, value, titleModal);

    setInputLength(e.target.value.length);
    if (titleModal === "Ingresar objetivo individual") {
      if (onlyWords.includes(name)) {
        if (name === "fGeneralObjetivesDate") {
          //

          setInputValues({
            ...inputValues,
            generalObjetivesDate: value,
          });
          setInputValues({
            ...inputValues,
            [name]: value,
          });
        } else {
          //

          setInputValues({
            ...inputValues,
            [name]: value,
          });
        }
      } else if (onlyNumbers.includes(name)) {
        // console.log("Entra acááááááááááááá")
        let regex = new RegExp("^[0-9]*$");
        if (
          regex.test(value) &&
          name !== "generalObjetivesYear" &&
          name !== "generalObjetivesSemester"
        ) {
          if (value === "0") return
          if (Number(value) > 100) return
          setInputValues({
            ...inputValues,
            [name]: value,
          });
        } else if (name === "generalObjetivesYear") {
          setInputValues({
            ...inputValues,
            [name]: value,
          });
          setPeriod({
            ...period,
            generalObjetivesYear: parseInt(value),
          });
          //
        } else if (name === "generalObjetivesSemester") {
          setInputValues({
            ...inputValues,
            [name]: value,
          });
          setInputLength(inputLength + 1);
          setPeriod({
            ...period,
            generalObjetivesSemester: parseInt(value),
          });
          //
        } else if (regex.test(value) === false) {
          // console.log("Entra")
          setInputValues({ [name]: "" });
        }
      }

      setNewIndividualObjetive({
        ...newIndividualObjetive,
        [name]: value,
      });
    } else {

      if (name === "generalObjetivesGoal") {
        // console.log("Entra acá", name, value);
      }
      if (onlyWords.includes(name)) {
        if (name === "fGeneralObjetivesDate") {
          setInputValues({
            ...inputValues,
            generalObjetivesDate: value,
          });
          setInputValues({
            ...inputValues,
            [name]: value,
          });
        } else {
          setInputValues({
            ...inputValues,
            [name]: value,
          });
        }
      } else if (onlyNumbers.includes(name)) {

        let regex = new RegExp("^[0-9]*$");
        if (
          regex.test(value) &&
          name !== "generalObjetivesYear" &&
          name !== "generalObjetivesSemester"
        ) {
          // console.log("Entra acááááááááááááá")
          if (value === "0") return
          if (Number(value) > 100) return


          setInputValues({
            ...inputValues,
            [name]: value,
          });
        } else if (name === "generalObjetivesYear") {
          setInputValues({
            ...inputValues,
            [name]: value,
          });
          setPeriod({
            ...period,
            generalObjetivesYear: parseInt(value),
          });
        } else if (name === "generalObjetivesSemester") {
          setInputValues({
            ...inputValues,
            [name]: value,
          });
          setInputLength(inputLength + 1);
          setPeriod({
            ...period,
            generalObjetivesSemester: parseInt(value),
          });
        } else if (regex.test(value) === false) {
          setInputValues({ [name]: "" });
        }
      }

      setNewGeneralObjetive({
        ...newGeneralObjetive,
        [name]: value,
      });
    }
  };

  const handleCloseModal = () => {
    setInputValues({} as IGeneralObjetives);
    // console.log("se cierra");

    setButtonDisabled(true);
    setShow(false);
    setNewGeneralObjetive({} as IGeneralObjetives);
    setDisabledButtonNone(false);
    setDisabledButtonGeneralSentence(false);
    setInputValues({});
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (titleModal === "Ingresar objetivo individual") {
      try {
        await handleCreateIndividualObjetives(newIndividualObjetive);
        // console.log("acá se manda:", evaluation_period_id);
        const query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;
        await handleGetAllIndividualObjetives(query);
        handleCloseModal();
      } catch { }
    } else if (titleModal === "Ingresar objetivos generales") {
      try {
        await handleCreateGeneralObjetives(newGeneralObjetive);
        // console.log("acá se manda:", evaluation_period_id);
        const query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;

        await handleGetAllGeneralObjetives(query);
        handleCloseModal();
      } catch { }
    }
  };

  const handleSubmitUpdate = async (e: any) => {
    e.preventDefault();
    if (titleModal === "Ingresar objetivo individual") {
      try {
        await handleUpdateGeneralObjetives(
          newIndividualObjetive.generalObjetivesId !== undefined
            ? newIndividualObjetive.generalObjetivesId
            : 0,
          newIndividualObjetive,
          evaluation_period_id
        );
        let query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;
        setTimeout(() => {
          handleGetAllIndividualObjetives(query);
        }, 1000);
        handleCloseModal();
      } catch { }
    } else if (titleModal === "Ingresar objetivos generales") {
      try {
        await handleUpdateGeneralObjetives(
          newGeneralObjetive.generalObjetivesId !== undefined
            ? newGeneralObjetive.generalObjetivesId
            : 0,
          newGeneralObjetive,
          evaluation_period_id
        );
        let query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;
        setTimeout(() => {
          handleGetAllGeneralObjetives(query);
        }, 1000);
        handleCloseModal();
      } catch { }
    }
  };

  useEffect(() => {
    if (EditObjetive !== undefined) {
      // console.log("EditObjective", EditObjetive);

      if (titleModal === "Ingresar objetivo individual") {
        setNewIndividualObjetive(EditObjetive as IIndividualObjetives);

        setInputValues(EditObjetive);
      }

      if (titleModal === "Ingresar objetivos generales" && !openModal) {
        setNewGeneralObjetive(EditObjetive as IGeneralObjetives);
        setOpenModal(true);
        if (
          (EditObjetive.generalObjectivesSentence !== null &&
            EditObjetive.generalObjetivesInfinitiveVerb === " " &&
            EditObjetive) ||
          (EditObjetive &&
            EditObjetive.generalObjetivesAdverb !== " " &&
            EditObjetive.fGeneralObjetivesDate !== " " &&
            EditObjetive.fGeneralObjetivesDate)
        ) {
          if (
            EditObjetive.generalObjectivesSentence !== null &&
            EditObjetive.generalObjetivesInfinitiveVerb === " "
          ) {
            setDisabledButtonGeneralSentence(true);
            setInputValues(EditObjetive);
            setDisabledButtonNone(false);
          } else {
            // console.log("Entra acá");
            setInputValues(EditObjetive);
            setDisabledButtonNone(true);
            setDisabledButtonGeneralSentence(false);
          }
        } else {
          setDisabledButtonNone(false);
          setDisabledButtonGeneralSentence(false);
        }
      }
    }
  }, [EditObjetive, show]);

  useEffect(() => {
    if (titleModal === "Ingresar objetivos generales") {
      if (show === false) {
        setOpenModal(false);
        setInputValues({});
      }
    }
    if (titleModal === "Ingresar objetivo individual") {
      if (show === false) {
        setOpenModal(false);
        setInputValues({});
      }
    }
  }, [show]);

  useEffect(() => {
    disabledButton();
  }, [inputValues]);

  const disabledButton = () => {
    if (
      titleModal === "Ingresar objetivos generales" &&
      Object.keys(newGeneralObjetive)[9] !== undefined
    ) {
      if (
        ((newGeneralObjetive.generalObjetivesInfinitiveVerb !== undefined &&
          newGeneralObjetive.generalObjetivesInfinitiveVerb !== "" &&
          newGeneralObjetive.generalObjetivesKpiConcept !== undefined &&
          newGeneralObjetive.generalObjetivesKpiConcept !== "" &&
          newGeneralObjetive.generalObjetviesMathExpression !== undefined &&
          newGeneralObjetive.generalObjetviesMathExpression !== "0" &&
          newGeneralObjetive.generalObjetivesPreposition !== undefined &&
          newGeneralObjetive.generalObjetivesPreposition !== "" &&
          newGeneralObjetive.generalObjetivesQuantityIndicator !== undefined &&
          newGeneralObjetive.generalObjetivesQuantityIndicator !== "" &&
          newGeneralObjetive.generalObjetivesAdverb !== undefined &&
          newGeneralObjetive.generalObjetivesAdverb !== "" &&
          newGeneralObjetive.fGeneralObjetivesDate !== undefined &&
          newGeneralObjetive.fGeneralObjetivesDate !== "") ||
          (newGeneralObjetive.generalObjectivesSentence !== undefined &&
            newGeneralObjetive.generalObjectivesSentence !== "")) &&
        // newGeneralObjetive.generalObjetivesYear !== undefined &&
        // newGeneralObjetive.generalObjetivesYear !== 0 &&
        // newGeneralObjetive.generalObjetivesSemester !== undefined &&
        // newGeneralObjetive.generalObjetivesSemester !== 0 &&
        // newGeneralObjetive.company !== undefined &&
        // newGeneralObjetive.company !== "" &&
        // newGeneralObjetive.area !== undefined &&
        // newGeneralObjetive.area !== "" &&
        // newGeneralObjetive.department !== undefined &&
        // newGeneralObjetive.department !== "" &&
        newGeneralObjetive.generalObjetivesGoal !== undefined &&
        newGeneralObjetive.generalObjetivesGoal !== "" &&
        newGeneralObjetive.generalObjetivesValue !== undefined &&
        newGeneralObjetive.generalObjetivesValue !== "" &&
        ((newGeneralObjetive.locations !== undefined &&
          newGeneralObjetive.locations !== "") ||
          (newGeneralObjetive.classifier !== null &&
            newGeneralObjetive.classifier !== "0"))
      ) {
        if (
          newGeneralObjetive.generalObjectivesSentence &&
          !newGeneralObjetive.generalObjetivesInfinitiveVerb
        ) {
          setNewGeneralObjetive({
            ...newGeneralObjetive,
            generalObjetivesInfinitiveVerb: " ",
            generalObjetivesKpiConcept: " ",
            generalObjetviesMathExpression: "0",
            generalObjetivesPreposition: " ",
            generalObjetivesQuantityIndicator: " ",
            fGeneralObjetivesDate: fecha.toString(),
            generalObjetivesAdverb: " ",
          });

          setButtonDisabled(false);
        } else {
          setButtonDisabled(false);
        }
      } else {
        //
        setButtonDisabled(true);
      }
    } else {
      if (
        newIndividualObjetive.generalObjetivesInfinitiveVerb !== undefined &&
        newIndividualObjetive.generalObjetivesInfinitiveVerb !== "" &&
        newIndividualObjetive.generalObjetivesKpiConcept !== undefined &&
        newIndividualObjetive.generalObjetivesKpiConcept !== "" &&
        newIndividualObjetive.generalObjetviesMathExpression !== undefined &&
        newIndividualObjetive.generalObjetviesMathExpression !== "0" &&
        newIndividualObjetive.generalObjetivesPreposition !== undefined &&
        newIndividualObjetive.generalObjetivesPreposition !== "" &&
        newIndividualObjetive.generalObjetivesQuantityIndicator !== undefined &&
        newIndividualObjetive.generalObjetivesQuantityIndicator !== "" &&
        newIndividualObjetive.generalObjetivesAdverb !== undefined &&
        newIndividualObjetive.generalObjetivesAdverb !== "" &&
        newIndividualObjetive.fGeneralObjetivesDate !== undefined &&
        newIndividualObjetive.fGeneralObjetivesDate !== ""
      ) {
        //
        setButtonDisabled(false);
      } else {
        //
        setButtonDisabled(true);
      }
    }
  };

  return (
    <>
      <Modal show={show} size="xl" onHide={handleCloseModal} backdrop="static">
        <ContainModal>
          <Modal.Header closeButton>
            <TitleModal>
              {EditObjetive !== "undefined"
                ? `Actualizar ${titleModal?.slice(8, titleModal?.length)}`
                : titleModal}
            </TitleModal>
            <InfoModal />
          </Modal.Header>
          <Modal.Body>
            <Row className="mt-2">
              <StyleInputs>
                <TextField
                  title="Verbo infinitivo"
                  id="VerboInfinitivo"
                  maxLength={150}
                  name="generalObjetivesInfinitiveVerb"
                  placeholder="Entregar"
                  onChange={onChangeTextField}
                  value={inputValues}
                  disabled={disabledButtonGeneralSentence}
                />
                <TextField
                  title="Concepto KPI"
                  id="ConceptoKPI"
                  maxLength={150}
                  placeholder="Base de datos de proyecto regularización"
                  name="generalObjetivesKpiConcept"
                  onChange={onChangeTextField}
                  value={inputValues}
                  disabled={disabledButtonGeneralSentence}
                />
              </StyleInputs>
              <StyleInputs>
                <Select
                  title="Expresión matemática"
                  id="Expresion"
                  options={mathExpression}
                  name="generalObjetviesMathExpression"
                  onChange={onChangeTextField}
                  value={inputValues}
                  disabled={disabledButtonGeneralSentence}
                />
                <TextField
                  title="Preposición"
                  id="Preposición"
                  maxLength={150}
                  placeholder="El"
                  name="generalObjetivesPreposition"
                  onChange={onChangeTextField}
                  value={inputValues}
                  disabled={disabledButtonGeneralSentence}
                />
                <TextField
                  id="Indicador"
                  title="Indicador"
                  maxLength={150}
                  name="generalObjetivesQuantityIndicator"
                  onChange={onChangeTextField}
                  value={inputValues}
                  placeholder="100"
                  disabled={disabledButtonGeneralSentence}
                />
                <TextField
                  title="Adverbio"
                  id="Adverbio"
                  maxLength={150}
                  name="generalObjetivesAdverb"
                  onChange={onChangeTextField}
                  value={inputValues}
                  placeholder="Al"
                  disabled={disabledButtonGeneralSentence}
                />
              </StyleInputs>
              <StyleInputs>
                <Date
                  title="Fecha"
                  id="date"
                  name={"fGeneralObjetivesDate"}
                  onChange={onChangeTextField}
                  value={inputValues}
                  disabled={disabledButtonGeneralSentence}
                />

                <TextField
                  type="number"
                  title="Año"
                  id="Anio"
                  maxLength={4}
                  placeholder="Año"
                  name="generalObjetivesYear"
                  onChange={onChangeTextField}
                  value={inputValues}
                />
                <Select
                  title="Semestre"
                  id="Semestre"
                  options={semesterArray}
                  name="generalObjetivesSemester"
                  onChange={onChangeTextField}
                  value={inputValues}
                />

                {titleModal === "Ingresar objetivo individual" ? null : (
                  <>
                    <TextField
                      title="Localidad"
                      id="Localidad"
                      maxLength={150}
                      name="locations"
                      onChange={onChangeTextField}
                      value={inputValues}
                      placeholder="Corporativo"
                    />
                  </>
                )}
              </StyleInputs>
              {titleModal === "Ingresar objetivo individual" ? null : (
                <>
                  <StyleInputs>
                    <TextField
                      title="Departamento"
                      id="Departamento"
                      maxLength={150}
                      placeholder="Departamento"
                      name="department"
                      onChange={onChangeTextField}
                      value={inputValues}
                    />
                    <TextField
                      title="Área"
                      id="area"
                      maxLength={150}
                      placeholder="Área"
                      name="area"
                      onChange={onChangeTextField}
                      value={inputValues}
                    />
                    <TextField
                      title="Compañía"
                      id="Compania"
                      maxLength={280}
                      placeholder="Compañía"
                      name="company"
                      onChange={onChangeTextField}
                      value={inputValues}
                    />
                    <Select
                      id="divisision"
                      title="Clasificación"
                      options={divisionData}
                      name="classifier"
                      onChange={onChangeTextField}
                      value={inputValues}
                    />
                  </StyleInputs>
                  <StyleInputs style={{ width: "51%" }}>
                    <TextField
                      title="Meta"
                      id="Meta"
                      maxLength={3}
                      placeholder="Meta"
                      name="generalObjetivesGoal"
                      onChange={onChangeTextField}
                      value={inputValues}
                    // type={"number"}

                    />
                    <TextField
                      title="Valor"
                      id="Valor"
                      maxLength={2}
                      placeholder="Valor"
                      name="generalObjetivesValue"
                      onChange={onChangeTextField}
                      value={inputValues}
                    />
                  </StyleInputs>
                  <TextFieldLong
                    title="Ingresar oración"
                    id="generalObjectivesSentence"
                    maxLength={280}
                    placeholder="Entregar Base de datos de proyecto regularización El 100 al 31/12/2021"
                    name="generalObjectivesSentence"
                    onChange={onChangeTextField}
                    disabled={disabledButtonNone}
                    value={inputValues}
                  />
                </>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary "
              style={StyleButonB}
              onClick={(e) => {
                handleCloseModal();
              }}
            >
              Cancelar
            </Button>

            <Button
              className="btn btn-custom-class"
              style={StyleButon}
              disabled={buttonDisabled}
              onClick={(e) => {
                if (EditObjetive !== "undefined") {
                  handleSubmitUpdate(e);
                } else {
                  handleSubmit(e);
                }
              }}
            >
              {EditObjetive !== "undefined" ? "Actualizar" : "Guardar"} objetivo
            </Button>
          </Modal.Footer>
        </ContainModal>
      </Modal>
      {succesPost ? (
        <ModalStatusSuccessful
          handleClick={setSuccesPost}
          modalStatus={succesPost}
          titleModal="Objetivo agregado"
        />
      ) : null}
      {errorPost ? (
        <ModalStatusTargetError
          handleClick={setErrorPost}
          modalStatus={errorPost}
          titleModal="Error al agregar el objetivo"
        />
      ) : null}
    </>
  );
};

export default GoalsModal;
