import styled from "styled-components";

export const GlobalHeader = styled.div`
  background-color: #f0f0f0;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Plus Jakarta Sans", sans-serif;
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 99;
  .settingsIcon {
    cursor: pointer;
    @media screen and (min-width: 900px) {
      display: block;
    }
  }

  .burguerIcon {
    display: block;

    @media screen and (min-width: 900px) {
      display: none;
    }
  }
`;

export const MainLayoutContainer = styled.div`
  font-family: "Plus Jakarta Sans", sans-serif;
  @media screen and (min-width: 800px) {
    display: flex;
  }
`;

export const ChildrenLayout = styled.div`
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-top: 70px;
  /* border: 1px solid red; */

  @media screen and (min-width: 900px) {
    display: flex;
    margin-left: 220px;
  }
`;
