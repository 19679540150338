/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import { Link, useLocation } from "react-router-dom";
import { IViews } from "../../../Interface/ApiInterface";
import logout from "../../../assets/icons/cerrarsession.svg";
import CuestionarioIcon from "../../../assets/icons/cuestionario.svg";
import DashboardIcon from "../../../assets/icons/dashboard.svg";
import EvaluationIcon from "../../../assets/icons/evaluation.svg";
import PeriodoIcon from "../../../assets/icons/iconPeriodo.svg";
import ObjectiveIcon from "../../../assets/icons/objetive.svg";
import PoliticasIcon from "../../../assets/icons/politicas.svg";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { SocketContext } from "../../../context/SocketContext";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { getCollaboratorServices } from "../../../services/collaborator.service";
import { Divider } from "../../../styles/AppStyles";
import {
  EvaluationTitle,
  EvaluationTitleContainer,
  SideBarStyles,
} from "./SideBarStyles";

const SideBar = () => {
  const location = useLocation();
  const { socket, idRoom, setIdRoom } = useContext(SocketContext);

  const [view, setView] = useState("");
  //** Redux
  const {
    Meta,
    User,
    UserPrev,
    handleGetCollaborators,
    errorloading,
    setErrorLoading,
    Filters,
    evaluationAttribute,
    handleGetCollaborator,
    Collaborator,
  } = useCollaboratorRedux();
  //** Context
  const {
    setEvaluatedOrEvaluation,
    setIsReduxOrContext,
    setChangePage,
    changePage,
  } = useContext(EvaluationContext) as EvaluationContextType;

  const handleLogout = (e?: any) => {
    e.preventDefault();

    sessionStorage.removeItem("token");
    sessionStorage.removeItem("LDBCOLLADO");

    localStorage.removeItem("principalFilters");
    localStorage.removeItem("tableFilters");
    localStorage.removeItem("periodId");
    localStorage.removeItem("pageLocalStorage");
    if (idRoom) {
      socket?.emit("leave-room", idRoom);
      setIdRoom(undefined);
    }
    setTimeout(() => {
      window.location.href = "https://collado.com.mx/";
    }, 1000);
  };

  function scrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }

  // console.log(User);

  // console.log({evaluationAttribute});

  useEffect(() => {
    let inactivityTimer: any;

    const resetTimer = (event?: any) => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(() => {
        handleLogout(event);
        window.location.replace("https://collado.com.mx/");
      }, 600000); // 10 minutos de inactividad (ajusta el tiempo según tus necesidades)
    };

    const handleMouseMove = (event?: any) => {
      resetTimer(event);
    };

    document.addEventListener("mousemove", handleMouseMove);

    resetTimer();

    return () => {
      clearTimeout(inactivityTimer);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const handelView = (name: string) => {
    setView(name);
    switch (name) {
      case "Dashboard":
        window.scrollTo(0, 0);
        break;
      case "Evaluaciones":
        window.scrollTo(0, 0);
        break;
      case "Objetivos":
        window.scrollTo(0, 0);
        break;
      case "Periodo":
        window.scrollTo(0, 0);
        break;
      case "Politicas":
        window.scrollTo(0, 0);
        break;
      case "Cuestionario":
        window.scrollTo(0, 0);
        break;
      case "Mi evaluación":
        setIsReduxOrContext(false);
        setEvaluatedOrEvaluation(false);
        // getCollab();
        window.scrollTo(0, 100);
        setChangePage(!changePage);
        break;
      case "Mis objetivos":
        setIsReduxOrContext(false);
        setEvaluatedOrEvaluation(true);
        // getCollab();
        setChangePage(!changePage);
        scrollToBottom();

        break;
      default:
        break;
    }
  };

  // const getCollab = async () => {
  //   handleGetCollaborator(User?.claTrab);
  //   await getCollaboratorServices(User?.claTrab)
  //     .then((res) => {
  //       console.log("Este es el colaborador", res.data);
  //       sendInfo(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const sendInfo = (data: any) => {
  //   console.log("Este es el colaborador", data);

  //   handleGetEvaluation(
  //     data?.employeeEvaluation?.employeeEvaluationEvaluatorUserId,
  //     data?.claTrab,
  //     data?.employeeEvaluation?.evaluationPeriod?.evaluationPeriodId
  //   );

  // };

  useEffect(() => {
    if (User) {
      switch (User.role) {
        case "Evaluado":
          setView("Evaluacion");
          break;
        case "Evaluador":
          setView("Dashboard");
          break;
        case "Director":
          setView("Dashboard");
          break;
        case "Admin":
          setView("Dashboard");
          break;
        default:
          setView("Dashboard");
          break;
      }
    }
  }, []);

  useEffect(() => {
    const viewLocation = location.pathname.replace("/", "");
    setView(viewLocation);
  }, []);

  useEffect(() => {
    async function borrarVariosLocalStorageYRedireccionar(
      claves: any,
      urlRedireccion: any
    ) {
      try {
        // Itera sobre el arreglo de claves, borrando cada elemento de localStorage
        claves.forEach((clave: string) => {
          // console.log("Borrando elemento de localStorage:", clave); // Opcional: Muestra la clave que se está borrando.
          localStorage.removeItem(clave);
        });
        localStorage.clear();
        // console.log("Elementos borrados exitosamente."); // Opcional: Muestra un mensaje de éxito.
        if (Object.keys(window.localStorage).length === 0) {
          // console.log("LocalStorage vacio");
          // window.location.href = "https://collado.com.mx/";
        } else {
          // console.log("LocalStorage no vacio");
        }
        // window.location.href = urlRedireccion; // Redirecciona a la URL deseada.
      } catch (error) {
        // Maneja cualquier error que pueda ocurrir al intentar borrar los elementos de localStorage
        // console.error("Error al borrar los elementos de localStorage:", error);
      }
    }
    getCollaboratorServices(User?.claTrab)
      .then((res) => {
        // console.log("Envío de datos front  ->", res.data);
      })

      .catch((err) => {
        // console.log("Envío de datos front  ->", err, err.response.status);
        if (err.response.status === 401) {
          // Uso de la función con async/await
          borrarVariosLocalStorageYRedireccionar(
            [
              "token",
              "principalFilters",
              "tableFilters",
              "periodId",
              "pageLocalStorage",
            ],
            "https://www.ejemplo.com"
          );

          // console.log(window.localStorage);

        }
      });
  }, []);

  return (
    <SideBarStyles>
      {User?.views?.map((item: IViews, index: any) => (
        <Link
          to={`/${item.name === "Mi evaluación"
            ? "Evaluacion"
            : item.name === "Mis objetivos"
              ? "Evaluacion"
              : item.name
            }`}
          style={{ textDecoration: "none" }}
          onClick={() => handelView(item.name)}
          key={`${item.name}${index}`}
        >
          <EvaluationTitleContainer
            style={{ background: view === item.name ? "#FFFFFF" : "#F0F0F0" }}
          >
            <Image
              src={
                item.name === "Dashboard"
                  ? DashboardIcon
                  : item.name === "Evaluaciones"
                    ? EvaluationIcon
                    : item.name === "Objetivos"
                      ? ObjectiveIcon
                      : item.name === "Periodo"
                        ? PeriodoIcon
                        : item.name === "Politicas"
                          ? PoliticasIcon
                          : item.name === "Cuestionario"
                            ? CuestionarioIcon
                            : item.name === "Mi evaluación"
                              ? CuestionarioIcon
                              : item.name === "Mis objetivos"
                                ? ObjectiveIcon
                                : undefined
              }
              alt={item.name}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "8px",
              }}
            >
              <EvaluationTitle
                style={{
                  color: view === item.name ? "#3B63A2" : "#333335",
                  fontWeight: view === item.name ? "700" : "400",
                }}
              >
                {item.name === "Politicas" ? "Políticas" : item.name}
              </EvaluationTitle>
            </div>
          </EvaluationTitleContainer>
        </Link>
      ))}
      <Divider />
      <Link
        to="/Default.aspx"
        style={{ textDecoration: "none" }}
        onClick={(e) => handleLogout(e)}
        key={`logout`}
      >
        <EvaluationTitleContainer style={{ background: "#F0F0F0" }}>
          <Image src={logout} />
          <EvaluationTitle style={{ color: "#333335" }}>
            {`Cerrar sesión`}
          </EvaluationTitle>
        </EvaluationTitleContainer>
      </Link>
    </SideBarStyles>
  );
};

export default SideBar;
