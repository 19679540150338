/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { Button, Carousel, Modal } from "react-bootstrap";
import IconInfoModal from "../../../pages/Admin/Objectives/images/IconInfoModal.svg";
import info from "../../../pages/Admin/Objectives/images/info.svg";
import userModalInfo from "../../../pages/Admin/Objectives/images/userModalInfo.svg";
import "../../organisms/DashboardCardsBox/Change.css";
import {
  ButtonsCarrusel,
  ButtonsCarruselMobile,
  ContenTitle,
  ContentGrid,
  ContentSlice,
  ContentSmart,
  ContentTextSlice2,
  ContentTextSlice3,
  GlobalFontDos,
  HeaderSlice,
  HeaderSlice2,
  Image,
  Question,
  Smart,
  StyleButon,
  StyleButonCerrar,
  SubtitleSlice,
  SubtitleSlice3,
  Text,
  TextSlice2,
  TextSlice3,
  TitleModal,
  TitleTextSlice3,
  carrusel,
} from "./styleModals";

const InfoModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showWindow, setShowWindow] = useState(false);
  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);

  return (
    <GlobalFontDos>
      <img src={info} alt="info" onClick={handleShow} />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <ContenTitle>
            <img src={IconInfoModal} alt="infoModalIcon" />
            <TitleModal>Modal informativo para redactar objetivos</TitleModal>
          </ContenTitle>
        </Modal.Header>
        <Modal.Body>
          <Carousel style={carrusel} variant="dark">
            <Carousel.Item className="irBpjm">
              <ContentSlice>
                <div>
                  <HeaderSlice>Evaluación de Desempeño</HeaderSlice>
                  <br></br>
                  <SubtitleSlice>
                  La aplicación de la evaluación y establecimiento de objetivos 
                  se realizará en forma vertical, es decir:
                  </SubtitleSlice>
                  <br></br>
                </div>
                <div style={{ justifyContent: "flex-start" }}>
                  <Text>
                    a) De la Dirección General a Directores, Gerentes o personal directo que tenga a su cargo.
                  </Text>
                  <Text>
                    b) De Directores a Gerentes, Jefes de área o el personal directo que tengan a su cargo.
                  </Text>
                  <Text>
                    c) De los Gerentes o Jefes de área al personal directo que tenga a su cargo.
                  </Text>
                </div>
              </ContentSlice>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="irBpjm">
              <ContentSlice>
                <HeaderSlice2>
                Es importante recordar que para el establecimiento de objetivos contamos con la gráfica SMART.
                </HeaderSlice2>
                <br></br>
                <ContentTextSlice2>
                  <TextSlice2>
                    “Una encuesta de Human Resources Services Inc. a compañias de Fortune 1000 
                    encontró que la función de Talento Humano juega un papel muy importante 
                    en las grandes compañías. Más del 90% de las empresas que aparecen en esta lista 
                    han implementado alguna forma de evaluación de desempeño”
                  </TextSlice2>
                  <Image src={userModalInfo} alt="userSlice2" />
                </ContentTextSlice2>
              </ContentSlice>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="irBpjm">
              <ContentSlice>
                <HeaderSlice>
                  Es importante recordar que para el establecimiento de
                  objetivos contamos con la gráfica SMART
                </HeaderSlice>
                <br></br>
                <ContentSmart>
                  <ContentGrid>
                    <SubtitleSlice3>Specific</SubtitleSlice3>
                    <Smart>S</Smart>
                    <ContentTextSlice3>
                      <TitleTextSlice3>Específico</TitleTextSlice3>
                      <TextSlice3>
                      Siempre bien detallado, nunca abstracto. Inicia tu objetivo siempre con un verbo que invita a una acción.
                      Siempre en lenguaje positivo.
                      Mientras más concreta la acción, más claro el objetivo.
                      </TextSlice3>
                      <Question>¿Qué?</Question>
                    </ContentTextSlice3>
                  </ContentGrid>
                  <ContentGrid>
                    <SubtitleSlice3>Measurable</SubtitleSlice3>
                    <Smart>M</Smart>
                    <ContentTextSlice3>
                      <TitleTextSlice3>Medible</TitleTextSlice3>
                      <TextSlice3>
                      Lo que no se puede medir, no se puede mejorar.
                      Dar seguimiento al proceso e ir evaluando por etapas.
                      Debes establecer una cifra para saber cuándo has llegado a tu objetivo.
                      </TextSlice3>
                      <Question>¿Cuánto?</Question>
                    </ContentTextSlice3>
                  </ContentGrid>
                  <ContentGrid>
                    <SubtitleSlice3>Attainable</SubtitleSlice3>
                    <Smart>A</Smart>
                    <ContentTextSlice3>
                      <TitleTextSlice3>Alcanzable</TitleTextSlice3>
                      <TextSlice3>
                        Basa este atributo en los recursos que posees 
                        (Humanos, tecnológicos, tiempo, económicos), 
                        de manera que el objetivo vaya acorde a tus 
                        posibilidades reales. Los objetivos deben significar 
                        un reto posible, sacar lo mejor de cada uno de 
                        nosotros para mantenernos motivados.
                      </TextSlice3>
                      <Question>¿Cómo?</Question>
                    </ContentTextSlice3>
                  </ContentGrid>
                  <ContentGrid>
                    <SubtitleSlice3>Relevant</SubtitleSlice3>
                    <Smart>R</Smart>
                    <ContentTextSlice3>
                      <TitleTextSlice3>Relevante</TitleTextSlice3>
                      <TextSlice3>
                        La relevancia en un objetivo va de la mano con la filosofía y las necesidades del negocio.
                        Debes ordenarlas por jerarquía y comenzar por el más importante.
                      </TextSlice3>
                      <Question>¿Para qué?</Question>
                    </ContentTextSlice3>
                  </ContentGrid>
                  <ContentGrid>
                    <SubtitleSlice3>Time Bound</SubtitleSlice3>
                    <Smart>T</Smart>
                    <ContentTextSlice3>
                      <TitleTextSlice3>Temporal</TitleTextSlice3>
                      <TextSlice3>
                        Debes definir los objetivos a corto, mediano y largo plazo. 
                        Desarrollar un “Time-line” en donde esté muy claro el plazo 
                        de tiempo para el desarrollo de cada proceso.
                      </TextSlice3>
                      <Question>¿Cuándo?</Question>
                    </ContentTextSlice3>
                  </ContentGrid>
                </ContentSmart>
              </ContentSlice>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        {!showWindow ? (
          <Modal.Footer style={ButtonsCarruselMobile}>
            <Button
              variant="secondary"
              onClick={handleClose}
              style={StyleButonCerrar}
            >
              Cerrar
            </Button>
            <Button variant="primary" style={StyleButon}>
              <a
                style={{ all: "unset" }}
                href="/pdf/Ciclo_de_Desempeño.pdf"
                target="_blank"
              >
                Descargar guía completa
              </a>
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer style={ButtonsCarrusel}>
            <Button
              variant="secondary"
              onClick={handleClose}
              style={StyleButonCerrar}
            >
              Cerrar
            </Button>
            <Button variant="primary" style={StyleButon}>
              <a
                style={{ all: "unset" }}
                href="/pdf/Ciclo_de_Desempeño.pdf"
                target="_blank"
              >
                Descargar guía completa
              </a>
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </GlobalFontDos>
  );
};

export default InfoModal;
