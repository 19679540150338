/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEvaluationRedux } from "../../../../redux/Evaluations";
import { FooterTableKpisChangePeriod } from "../FooterTableKpis";
import {
  TableDosWrapper,
  TableHeadingDos
} from "../KpisTablesStyles";
import "./../kpisStyle.css";

//**  Context
import CardTableKpisChangePeriod from "./CardTableKpisChangePeriod";



export const TableOneChangePeriod = () => {

  // ** Redux
  const { Evaluation } = useEvaluationRedux();

 
  // console.log("Nuevo objetivos actuales: ",Evaluation?.employeeEvaluationObjetivesCurrents);
  
  return (
    <>
      {
      <TableDosWrapper>
        <>
          <TableHeadingDos>
            <div className="first">
              Sem{Evaluation?.period?.evaluationPeriodNumberOfYear}
              {`.`}
              {Evaluation?.period?.evaluationPeriodYear}
            </div>
            <div className="second">Valor</div>
            <div className="third">Descripción de objetivos</div>
            <div className="fourth">Meta</div>
            <div className="fifth">Calificación</div>
            <div className="sixth">% Alcance</div>
            <div className="seventh">Resultado</div>
          </TableHeadingDos>
          {Evaluation?.employeeEvaluationObjetivesCurrents &&
            Evaluation?.employeeEvaluationObjetivesCurrents?.map(
              (item: any, index: number) =>
                <CardTableKpisChangePeriod
                  item={item}
                />
            )}
          <FooterTableKpisChangePeriod evaluacion={Evaluation} />
        </>
      </TableDosWrapper>}
    </>
  );
};



