import { createBrowserRouter } from "react-router-dom";
import EmployeeReport from "../components/organisms/EvaluationReport/EmployeeReport";
import ManageFiltersDashboardProvider from "../context/ManageFiltersDashboard/FiltersDashboard";
import MainLayout from "../layouts/MainLayout/MainLayout";
import ErrorPage from "../layouts/error";
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import Objetives from "../pages/Admin/Objectives/Objectives";
import PeriodosEvaluaciones from "../pages/Admin/PeriodosEvaluaciones";
import Politics from "../pages/Admin/Politics/Politics";
import Questionnaire from "../pages/Cuestionario/Questionnaire";
import Evaluation from "../pages/Evaluation/Evaluation";
import Login from "./../pages/Auth/login";
import {
  NotEvaluatedRequireAuth,
  NotRequireAuth,
  ProtectedRoute,
} from "./ProtectedRoute";
import SocketProvider from "../context/SocketContext";

const router = createBrowserRouter([
  {
    path: "Default.aspx",
    element: (
      <NotRequireAuth>
        <Login />
      </NotRequireAuth>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <SocketProvider>
          <MainLayout />
        </SocketProvider>
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "Dashboard",
        element: (
          <ManageFiltersDashboardProvider>
            <NotEvaluatedRequireAuth>
              <Dashboard />
            </NotEvaluatedRequireAuth>
          </ManageFiltersDashboardProvider>
        ),
      },
      {
        path: "Evaluaciones",
        element: <Evaluation />,
      },
      {
        path: "evaluacion/:employeeNumber/:year/:semester",
        element: <Evaluation />,
      },
      {
        path: "Objetivos",
        // element: <Evaluation />,
        element: <Objetives />,
      },
      {
        path: "Colaboradores",
        element: <EmployeeReport />,
      },
      {
        path: "Cuestionario",
        element: (
          <NotEvaluatedRequireAuth>
            <Questionnaire />
          </NotEvaluatedRequireAuth>
        ),
      },
      {
        path: "Evaluacion",
        element: <Evaluation />,
      },
      {
        path: "Periodo",
        element: <PeriodosEvaluaciones />,
      },
      {
        path: "politicas",
        element: (
          <NotEvaluatedRequireAuth>
            <Politics />
          </NotEvaluatedRequireAuth>
        ),
      },
    ],
  },
]);

export default router;
