/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ApiResponse,
  BonusPaid,
  IResponse,
  ListCollaborators,
  SetAdminStaff,
  StaffByHierarchy,
} from "../Interface/ApiInterface";
import { http } from "../api";
import { validateIdPeriod } from "../context/validacionesFiltros";

const getListCollaborators = async (IdPeriod: number | undefined) => {
  return new Promise<ApiResponse<ListCollaborators[]>>((resolve, reject) => {
    http
      .get(`/Staff/collaborators/filters?IdPeriod=${IdPeriod}`)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const putBonusPaid = async (
  ClaTrab: number,
  IdPeriod: number,
  BonusPaid: boolean
) => {
  return new Promise<IResponse<BonusPaid>>((resolve, reject) => {
    const periodo = validateIdPeriod(IdPeriod);
    http
      .put(
        `/Evaluations/update-bonus-paid?ClaTrab=${ClaTrab}&IdPeriod=${periodo}&BonusPaid=${BonusPaid}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const dataError = error?.response?.data?.errors;
        reject(dataError);
      });
  });
};
const postSetAdminStaff = async (
  ClaTrab: number,
  Administrador?: boolean | undefined,
  Clasificacion?: boolean | undefined,
  EvaluationPeriodId?: number | undefined
) => {
  let url = `/Staff/SetAdminStaff?`;
  if (ClaTrab) url += `ClaTrab=${ClaTrab}`;
  if (Administrador !== undefined) url += `&Administrador=${Administrador}`;
  if (EvaluationPeriodId !== undefined)
    url += `&EvaluationPeriodId=${EvaluationPeriodId}`;
  if (typeof Clasificacion == "boolean")
    url += `&Clasificacion=${Clasificacion}`;
  return new Promise<IResponse<SetAdminStaff>>((resolve, reject) => {
    http
      .post(url)
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        const dataError = error?.response?.data?.errors;
        reject(error);
      });
  });
};

const postSetAdminPermisos = async (
  ClaTrab: number,
  userRole?: string | undefined
) => {
  let url = `/Staff/SetAdminStaff?`;
  if (ClaTrab) url += `ClaTrab=${ClaTrab}`;
  if (userRole) url += `&${userRole}=true`;

  return new Promise<IResponse<SetAdminStaff>>((resolve, reject) => {
    http
      .post(url)
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        const dataError = error?.response?.data?.errors;
        reject(error);
      });
  });
};
const GetStaffByHierarchy = async () => {
  return new Promise<ApiResponse<StaffByHierarchy>>((resolve, reject) => {
    http
      .get(`/Staff/GetStaffByHierarchy`)
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postDeactivateEval = async (ClaTrab: number, Demanda?: boolean) => {
  let url = `/Staff/SetAdminStaff?`;
  if (ClaTrab) url += `ClaTrab=${ClaTrab}`;
  // if (Demanda !== undefined) url += `&Administrador=${Demanda}`;
  if (typeof Demanda == "boolean") url += `&Demanda=${Demanda}`;
  return new Promise<IResponse<SetAdminStaff>>((resolve, reject) => {
    http
      .post(url)
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        const dataError = error?.response?.data?.errors;
        reject(error);
      });
  });
};

export {
  GetStaffByHierarchy,
  getListCollaborators,
  postDeactivateEval,
  postSetAdminPermisos,
  postSetAdminStaff,
  putBonusPaid
};

