import styled from "styled-components";

export const RowForm = {
  display: "flex",
  alignItems: "center",
};

export const ModalTitle = {
  display: "flex",
  alignItems: "center",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "22px",
  color: "#1F2A40",
  opacity: "0.6",
};

export const ModalBtnsStyles = {
  padding: "8px 16px",
  background: "#3B63A2",
  borderRadius: "8px",
  border: "none",
  color: "#fff",
  fontWeight: "600",
};

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
  /* margin-top: 40px; */
  padding: 0 28px;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const KpisContainerStyles = styled.div`
  padding: 0;
  margin: 1rem auto;
  /* max-width: 1440px; */
`;

export const KpisTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const TablesSection = styled.section`
  margin: 1rem 0;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 3.5rem;
  /* border: 1px solid red; */
`;

export const TitleBox = styled.div`
  margin: 0 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const TitleText = styled.h3`
  font-weight: 700;
  font-size: 20px;
  color: #3b63a2;
  margin: 0;
`;

export const PrevText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #939396;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;

export const CommentsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
`;

export const CommentCardTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #1f2a40;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f1f1f1;
`;

export const CommentCard = styled.div`
  padding: 24px;
  gap: 16px;
  width: 100;
  background: #f8f8f8;

  @media screen and (min-width: 800px) {
    width: 50%;
  }
`;
