import styled from "styled-components";

type PropTypeBgTres = {
  activeBorder?: any;
};

export const CardCurrentObj = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  background-color: #f8f8f8;
  /* color: #ffffff; */
  /* border-radius: 4px; */
  padding: 14px 8px;
  display: flex;

  margin-bottom: 2px;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.activeBorder ? "1px solid red" : "none")};

  .first {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .second {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .third {
    width: 37%;
    display: flex;
    align-items: center;
    margin-left: 12px;
    /* justify-content: center; */
    padding: 0px 10px;
    /* border: 1px solid white; */
  }

  .fourth {
    width: 11%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 60px;
    margin: 0px auto;
    /* border: 1px solid red; */
    .cmXlkM .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      width: 100%;
      /* outline: none; */
    }

    .css-1v1n4gf-MuiFormControl-root-MuiTextField-root {
      width: 100%;
      margin: 0px auto;
      /* border: 1px solid red; */
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
    }
  }

  .fifth {
    width: 11%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: 0px auto;
    /* padding: 0px 20px; */
    /* padding-left: 10px; */
    .cmXlkM .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      width: 100%;
      /* outline: none; */
    }

    .css-1v1n4gf-MuiFormControl-root-MuiTextField-root {
      width: 100%;
      margin: 0px auto;
      /* border: 1px solid red; */
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
    }
    /* border: 1px solid red; */
    /* border: 1px solid red; */
  }

  .sixth {
    width: 12.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .seventh {
    width: 12.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
