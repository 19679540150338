import styled from "styled-components";

export const ContainLogin = styled.div`
display:flex;
margin: 0;
padding: 0;
flex-direction:row;
width:100%;
height:100vh;
justify-content: center;
align-items: center;

/* text-Align:center; */
/* position:fixed; */
/* border: 2px solid red; */
@media (min-width: 320px) and (max-width: 768px) {
    flex-direction:column;
}
`;
export const ContainInfoLog = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin:0 auto;
width:50%;
gap: 12px;
/* height: 100%; */
/* border: 2px solid red; */
/* margin-top:13%; */
@media (min-width: 320px) and (max-width: 990px) {
    width:100%;
    margin-top: 10%;
}
`;
export const ContainImages = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin:0 auto;
width:48%;
@media (min-width: 320px) and (max-width: 990px) {
    width:100%;
}

`;
export const Mosaico = styled.img`
align-items: flex-start;
width: 100%;
padding: 0px;
@media (min-width: 320px) and (max-width: 1199px) {
    height:100%;
}
@media (min-width: 1200px)and (max-width: 1600px)  {
    height:72%;
    }
`;
export const Logos = styled.img`
align-items: flex-start;
padding: 0px;
width: 100%;
left: 0px;
margin-top:1%;
@media (min-width: 320px) and (max-width: 1199px) {
    height:100%;
}
@media (min-width: 1200px)and (max-width: 1600px)  {
    height:50%;
    margin-top:0%;

    }
`;
export const TextLogin = styled.p`
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 35px;
display: flex;
align-items: center;
color: #3B63A2;
@media (min-width: 320px) and (max-width: 768px) {
    font-size: 22px;
}
`;
export const SubTextLogin = styled.p`
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
display: flex;
align-items: center;
color: #3B63A2;
cursor:pointer;
`;
export const TextOtp = styled.p`
font-family: 'Plus Jakarta Sans', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
display: flex;
align-items: center;
color: #3B63A2;
`;
export const InputOtp = {
    background: '#F9FAFB',
    border: '0.418539px solid #D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '3.34831px',
    width: '33.48px',
    height: '33.48px',
    color: '#00000',
    lineHeight: '120%',
};