import "bootstrap/dist/css/bootstrap.min.css";
import { RouterProvider } from "react-router-dom";
import ChangePeriodProvider from "../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import EvaluationProvider from "../context/EvaluationContext";
import router from "./routes";


function App() {
  
  return (
    <EvaluationProvider>
      <ChangePeriodProvider>
      <RouterProvider router={router} />
      </ChangePeriodProvider>
    </EvaluationProvider>
  );
}

export default App;
