import styled from "styled-components";
import ModalInputDefault from "../../../components/atoms/KpisTables/inputMask/ModalInputDefault";

// export const ColumnsDataD = ({
//   dataMap,
//   semestre,
//   objetivesTypeG,
//   setObjetivesTypeG,
// }: any) => {
//   const { ableSelectDefaultObj } = useAbleFutureObjetivesDefaults();

//   const handleChangeValue = (value: number | string, index: number) => {
//     const changedValue = objetivesTypeG.map((item: any, i: number) => {
//       if (i === index) {
//         return {
//           ...item,
//           valor: value,
//         };
//       } else {
//         return item;
//       }
//     });

//     setObjetivesTypeG(changedValue);
//   };
//   return (
//     <>
//       {dataMap &&
//         dataMap?.map((item: any, index: number) => {
//           if (item.type === "G") {
//             return (
//               <ModalBodyTableDos key={item.id}>
//                 <div className="first">{semestre}</div>
//                 <div className="second">
//                   {/* {objetivesTypeG[index]?.valor} */}
//                   {/* <input
//                     style={{ outline: "none" }}
//                     className="inputSecond"
//                     disabled={ableSelectDefaultObj}
//                     type="number"
//                     value={objetivesTypeG[index]?.valor}
//                     onChange={(e) => handleChangeValue(e.target.value, index)}
//                   /> */}
//                   <ModalInputGeneral
//                     handleChange={handleChangeValue}
//                     value={objetivesTypeG[index]?.valor}
//                     disable={ableSelectDefaultObj}
//                     index={index}
//                     name="valor"
//                   />
//                 </div>
//                 <div className="third">{item.description}</div>
//                 <div className="fourth">{item.meta}%</div>
//               </ModalBodyTableDos>
//             );
//           }

//           return null;
//         })}
//     </>
//   );
// };

export const ColumnsDataG = ({
  dataMap,
  semestre,
  inputValue,
  setInputValue,
}: any) => {
  // console.log(dataMap);

  return (
    <>
      {dataMap &&
        dataMap?.map((item: any) => {
          if (
            item.type === "D" &&
            item.description !==
              "Desempeño laboral, humano, actitud y habilidades"
          ) {
            return (
              <ModalBodyTableDos key={item.id}>
                <div className="first">{semestre}</div>
                <div className="second">{item.valor}%</div>
                <div className="third">{item.description}</div>
                <div className="fourth">{item.meta}%</div>
              </ModalBodyTableDos>
            );
          }

          if (
            item.type === "D" &&
            item.description ===
              "Desempeño laboral, humano, actitud y habilidades"
          ) {
            return (
              <ModalBodyTableDos key={item.id}>
                <div className="first">{semestre}</div>
                <div className="second">
                  {/* <input
                    className="inputSecond"
                    type="number"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  /> */}
                  <ModalInputDefault
                    handleChange={setInputValue}
                    value={inputValue}
                    disable={false}
                    index={0}
                    name="valor"
                  />
                </div>
                <div className="third">{item.description}</div>
                <div className="fourth">{item.meta}%</div>
              </ModalBodyTableDos>
            );
          }
          return null;
        })}
    </>
  );
};

type PropTypeBgTres = {
  marginBtm?: any;
  disabled?: any;
  colorBtn?: any;
};

const ModalBodyTableDos = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: auto;
  color: #ffffff;
  width: 100%;
  padding: 8px;
  margin: 2px 0;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;

  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .second {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    .inputSecond {
      padding: 5px 10px;
      width: 40%;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
  .third {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .fourth {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
