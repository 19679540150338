import styled from "styled-components";
import arrowDown from "../../../../../assets/arrowDown.svg";
import arrowUp from "../../../../../assets/arrowUp.svg";
import { DownLoadReport } from "../../../../../components/atoms/DownLoadReport/DownLoadReport";
import { useCollaboratorRedux } from "../../../../../redux/Collaborators";
import FreeEvaluations from "../../../FreeEvaluations";
import { UpArrow } from "../../components/ProgresoEvaluaciones/ProgresoEvaluaciones.styles";
import {
  ColaboratorsReportsRow,
  ColaboratorsReportsTitle,
  ContainerButtons,
} from "./DownloadReportCollaboratorsStyles";

const DownloadReportCollaborators = ({
  evaluation_period_id,
  hide,
  setHide,
}: any) => {
  //** Redux
  const { evaluationAttribute } = useCollaboratorRedux();

  return (
    <>
      <ColaboratorsReportsRow>
        <ColaboratorsReportsTitle>
          Reporte de colaboradores
        </ColaboratorsReportsTitle>

        <ContainerButtons>
          {evaluationAttribute.btn_delivery_evaluation === 1 && (
            <FreeEvaluations evaluation_period_id={evaluation_period_id} />
          )}
          <div className="containerToggle">
            <UpArrow
              alt=""
              src={hide ? arrowUp : arrowDown}
              onClick={() => setHide(!hide)}
            />
          </div>
        </ContainerButtons>
      </ColaboratorsReportsRow>
      {hide && (
        <div style={{ marginTop: "24px" }}>
          {evaluationAttribute.dowload_collaborator_report === 1 && (
            <DownLoadReport />
          )}
        </div>
      )}
    </>
  );
};

export default DownloadReportCollaborators;

export const ContainerToggle = styled.div`
  width: 100%;
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;
