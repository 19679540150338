import styled from "styled-components";

export const EvaluationContainer = styled.div`
  padding: 40px;
  /* width: 105%; */
  /* background-color: #f4fcff; */
  background-color: #f0f0f0;
  /* border: 1px solid red; */

  @media screen and (max-width: 1600px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 12px solid black; */
    width: 99%;
  }
  @media screen and (max-width: 1509px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 12px solid black; */
    width: 97%;
  }
  @media screen and (max-width: 1479px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 12px solid black; */
    width: 93%;
  }

  @media screen and (max-width: 1470px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 90%;
  }
  @media screen and (max-width: 1370px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 88%;
  }
  @media screen and (max-width: 1350px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 86%;
  }
  @media screen and (max-width: 1330px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 84%;
  }
  @media screen and (max-width: 1300px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 80%;
  }
  @media screen and (max-width: 1280px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 78%;
  }
  @media screen and (max-width: 1180px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 74%;
  }
  @media screen and (max-width: 1150px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 70%;
  }
  @media screen and (max-width: 1100px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 64%;
  }
  @media screen and (max-width: 1050px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 62%;
  }

  @media screen and (max-width: 770px) {
    padding: 0 2rem 1rem 2rem;
    /* border: 1px solid green; */
    width: 100%;
  }


  @media screen and (min-width: 320px) and (max-width: 768px) {
    background-color: white;
  }
`;
