import { useEffect, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";

//* Con este hook veo si el donde estoy parado es mí evaluación o la de otro

export const useSemesterYearNow = () => {
  //** Redux
  const { User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  //** States
  const [isActualDate, setIsActualDate] = useState(false);

  const evaluationDate = `${Evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear} ${Evaluation?.evaluationPeriod?.evaluationPeriodYear}`;
  const dateNow = getCurrentYearAndSemester();
  // const dateNow = `${User?.periodInfo?.semesterNumber} ${User?.periodInfo?.year}`;

  // console.log(dateNow);

  useEffect(() => {
    const handleChangeEvaluator = () => {
      if (evaluationDate === dateNow) {
        setIsActualDate(true);
      } else {
        setIsActualDate(false);
      }
    };
    handleChangeEvaluator();
  }, [User, Evaluation, evaluationDate, dateNow]);

  return {
    isActualDate,
  };
};

// export function getCurrentYearAndSemester() {
//   const currentDate = new Date();
//   const currentYear = currentDate.getFullYear();
//   const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11

//   let semester;

//   if (currentMonth >= 1 && currentMonth <= 6) {
//     semester = 2; // Primer semestre
//   } else {
//     semester = 1; // Segundo semestre
//   }

//   return `${semester} ${currentYear - 1}`;
// }
// export function getCurrentYearAndSemester() {
//   const currentDate = new Date();
//   const currentYear = currentDate.getFullYear();
//   const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11

//   let semester;

//   if (currentMonth >= 1 && currentMonth <= 6) {
//     semester = 2; // Primer semestre
//   } else {
//     semester = 1; // Segundo semestre
//   }

//   // si el semestre es 1, entonces el año es el actual, si no, es el anterior
//   if (semester === 1) {
//     return `${semester} ${currentYear}`;
//   } else {
//     return `${semester} ${currentYear - 1}`;
//   }
//   // si el semestre es 2, entonces el año es el anterior
// }

export function getCurrentYearAndSemester() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11

  let previousYear;
  let previousSemester;

  if (currentMonth >= 1 && currentMonth <= 6) {
    previousYear = currentYear - 1;
    previousSemester = 2; // Segundo semestre del año anterior
  } else {
    previousYear = currentYear;
    previousSemester = 1; // Primer semestre del año actual
  }

  return `${previousYear} ${previousSemester}`;
}

// export function getCurrentYearAndSemesterObj() {
//   const currentDate = new Date();
//   const currentYear = currentDate.getFullYear();
//   const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11

//   let semester;

//   if (currentMonth >= 1 && currentMonth <= 6) {
//     semester = 2; // Primer semestre
//   } else {
//     semester = 1; // Segundo semestre
//   }

//   return { semestre: semester, year: currentYear - 1 };
// }
export function getCurrentYearAndSemesterObj() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11

  let previousYear;
  let previousSemester;

  if (currentMonth >= 1 && currentMonth <= 6) {
    previousYear = currentYear - 1;
    previousSemester = 2; // Segundo semestre del año anterior
  } else {
    previousYear = currentYear;
    previousSemester = 1; // Primer semestre del año actual
  }

  return { semestre: previousSemester, year: previousYear };
}

// Entraría -> 2024 año y 7 mes
// la respuesta sería 2024 1

// Entra: 2024 año y 03 mes
// 2023 2
