import React, { createContext, useContext, useState, FC, useCallback, ReactNode, useEffect } from 'react';

interface LoaderContextProps {
  activeRequests: number;
  isLoading: boolean;
  incrementRequests: () => void;
  decrementRequests: () => void;
}

interface LoaderProviderProps {
  children: ReactNode;
}

const LoaderContext = createContext<LoaderContextProps>({
  activeRequests: 0,
  isLoading: false,
  incrementRequests: () => {},
  decrementRequests: () => {},
});

export const LoaderProvider: FC<LoaderProviderProps> = ({ children }) => {
  const [activeRequests, setActiveRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const incrementRequests = useCallback(() => {
    setActiveRequests((prev) => prev + 1);
  }, []);

  const decrementRequests = useCallback(() => {
    setActiveRequests((prev) => {
      const newCount = prev - 1;
      return newCount;
    });
  }, []);

  useEffect(() => {
    if (activeRequests > 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [activeRequests]);


  return (
    <LoaderContext.Provider value={{ activeRequests, isLoading, incrementRequests, decrementRequests }}>
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => useContext(LoaderContext);