import { http } from "../api";

const getStatusList = async () => {
  try {
    const response = await http.get(`/Evaluations/get-evaluation-status-list`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getStatusChange = async ({
  catStatusActual,
  catStatusNuevo,
  idPeriod,
  selectedLocation,
  userId,
}: {
  catStatusActual: number | null;
  catStatusNuevo: number | null;
  idPeriod: number;
  selectedLocation: string;
  userId: string;
}) => {
  try {
    const response = await http.put(
      `/Evaluations/massive-status-change?IdEstatusActual=${catStatusActual}&IdEstatusActualizado=${catStatusNuevo}&IdPeriod=${idPeriod}&Localidad=${selectedLocation}&ListClaTrab=${userId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const aproveSolicitud = async (body: any) => {
  const response = await http.put(
    `/Solicitudes/update-status-mc-request`,
    body
  );

  return response.data;
};
const CambioDeEstatusMasivoSolicitud = async (body: any) => {
  const response = await http.post(
    `/Solicitudes/create-msc-request`,
    body
  );

  return response.data;
};
export { CambioDeEstatusMasivoSolicitud, getStatusChange, getStatusList };

