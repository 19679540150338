import { useContext, useEffect, useState } from "react";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "../Evaluation/useSemesterYearNow";

export const useAbleCommentEvaluated = () => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();

  //** Hooks

  //** States
  const [ableCommentEvaluated, setAbleCommentEvaluated] = useState(true);
  const [ableCommentEvaluator, setAbleCommentEvaluator] = useState(true);

  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  // const evaluationDate = `${Evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear} ${Evaluation?.evaluationPeriod?.evaluationPeriodYear}`;
  // const dateNow = `${User?.periodInfo?.semesterNumber} ${User?.periodInfo?.year}`;

  useEffect(() => {
    if (User.role === "Auditor") {
      setAbleCommentEvaluator(true); //Inhabilita
      setAbleCommentEvaluated(true); //Inhabilita
    } else {
      //todo Evaluador
      if (!urlPermisos) {
        if (Evaluation?.catStatusEvaluationId === 7) {
          if (
            evaluationAttribute.edit_evaluation_complete === 1 &&
            isActualDate
          ) {
            setAbleCommentEvaluator(false); // Habilita
            setAbleCommentEvaluated(true); //Inhabilita
          } else {
            setAbleCommentEvaluator(true); //Inhabilita
            setAbleCommentEvaluated(true); //Inhabilita
          }
        } else {
          setAbleCommentEvaluator(false); // Habilita
          setAbleCommentEvaluated(true); //Inhabilita
        }
      }

      //todo MI EVALUACIÓN
      if (urlPermisos) {
        if (changePeriodEvaluation) {
          if (
            Evaluation.catStatusEvaluationId > 0 &&
            Evaluation.catStatusEvaluationId <= 6
          ) {
            setAbleCommentEvaluated(false); // inhabilita
            setAbleCommentEvaluator(true); //Inhabilita
          }

          //** Caso para los admins
          if (
            Evaluation.catStatusEvaluationId === 7 &&
            evaluationAttribute.edit_evaluation_complete === 1
          ) {
            setAbleCommentEvaluated(false);
            setAbleCommentEvaluator(true); //Inhabilita
          }

          //** Caso para los que no tienen permisos
          if (
            Evaluation.catStatusEvaluationId === 7 &&
            evaluationAttribute.edit_evaluation_complete === 0
          ) {
            setAbleCommentEvaluated(true);
            setAbleCommentEvaluator(true); //Inhabilita
          }
        } else {
          setAbleCommentEvaluated(true);
          setAbleCommentEvaluator(true);
        }
      }
    }
  }, [
    Evaluation.catStatusEvaluationId,
    urlPermisos,
    evaluationAttribute.edit_column_score,
    evaluationAttribute.edit_evaluation_complete,
    changePeriodEvaluation,
    User.role,
    isActualDate,
  ]);

  return {
    ableCommentEvaluated,
    ableCommentEvaluator,
  };
};
