import { FormControlLabel } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import close from "../../../../assets/closeButton.svg";
import { ModalAlertWrapper } from "../../ModalActions/ModalEditStyles";
import ModalChangeClasification from "../../components/ModalChangeClasification";
import { TitleContainer } from "../../components/ModalChangeEstatus";
import { IOSSwitch } from "./IosSwitchDashboard";
// const ids: Record<number, string> = {
//   1: "Definición de objetivos",
//   2: "Nuevo ingreso",
//   3: "Pendiente evaluar",
//   4: "Pendiente de liberar",
//   6: "Pendiente de firmar",
//   7: "Completada",
// };

const SwitchClasificationUser = ({ item }: any) => {
  const [showModal, setShowModal] = useState(false);
  const [itemClasification, setItemClasification] = useState(
    item.clasificacion
  );

  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // function obtenerClavePorValor(id: number): string | undefined {
  //   return ids[id];
  // }

  // console.log(
  //   "item",
  //   item.employeeEvaluation.catStatusEvaluationId,
  //   obtenerClavePorValor(item.employeeEvaluation.catStatusEvaluationId)
  // );

  return (
    <>
      <FormControlLabel
        sx={{
          fontFamily: "Plus Jakarta Sans",
          fontSize: "14px",
        }}
        control={<IOSSwitch sx={{ m: 1 }} />}
        // label={item.clasificacion ? "Si" : "No"}
        label={itemClasification ? "Si" : "No"}
        // checked={item.clasificacion}
        checked={itemClasification}
        onChange={(e) => {
          // item.clasificacion = !item.clasificacion;
          // updateClasification(e, item);
          setShowModal(true);
        }}
      />
      {showModal && (
        <ModalChangeClasification
          itemClasification={itemClasification}
          setItemClasification={setItemClasification}
          item={item}
          isModalVisible={showModal}
          setIsModalVisible={setShowModal}
          setModal={setModal}
          setMessageModal={setModalMessage}
          // setModalError={setModalError}
          // updateClasification={updateClasification}
        />
      )}

      <ModalSuccess
        isModalVisible={modal}
        setIsModalVisible={setModal}
        message={modalMessage}
      />
    </>
  );
};

export default SwitchClasificationUser;

const ModalSuccess = ({ isModalVisible, setIsModalVisible, message }: any) => {
  return (
    <ModalAlertWrapper display={isModalVisible}>
      <div className="modalWrapper">
        <TitleContainer style={{ justifyContent: "flex-end" }}>
          <img
            src={close}
            className="image"
            alt="AlertModalIcon"
            onClick={() => setIsModalVisible(false)}
            style={{ cursor: "pointer" }}
          />
        </TitleContainer>

        <ContainerModalText>
          <div className="textContainer">
            <p>{message}</p>
          </div>
        </ContainerModalText>
      </div>
    </ModalAlertWrapper>
  );
};

export const ContainerModalText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  //padding: 14px 21px;

  .textContainer {
    font-size: 16px;
    color: #333335;
    font-weight: 400;
    //padding-bottom: 5px;
  }

  .containerModal {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: end;
    font-size: 14px;

    .txt {
      margin-top: 8px;
      padding: 8px;
    }

    .containerTxt {
      background-color: white;
      border-radius: 8px;
      font-weight: 700;
      color: black;
      font-size: 14px;
      //padding: 8px;
      //margin: 8px;
      //margin-right: 24px;
      width: 83%;
    }
  }
`;
