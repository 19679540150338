import { useContext } from "react";
import styled from "styled-components";
import Close from "../../../../assets/icons/CloseModal.svg";
import redBell from '../../../../assets/redBell.svg';
import { EvaluationContext, EvaluationContextType } from "../../../../context/EvaluationContext";
import { validateIdPeriod } from "../../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import { updateEvaluationStatusSemaforo } from "../../../../services/evaluation.service";
import { ModalAlertWrapper } from "../../ModalActions/ModalEditStyles";

const ModalDesactivarAutoEval = ({
  setIsModalVisible,
  isModalVisible,
  statusSemaforos,
  setStatusSemaforos,
  infoSemaforo,
  evaluation_period_id,
  evaluadoName
}: any) => {


  // console.log("Esta es la info de la evaluación", infoSemaforo, evaluadoName);
  //** Context
  const { setLoadingPagination } = useContext(EvaluationContext) as EvaluationContextType

  //** Redux
  const { handleGetCollaborators, Filters, User } = useCollaboratorRedux();

  // console.log("<Se hace petición>");
  const queryParts: string[] = [];

  for (const key in Filters) {
    if (Filters[key] !== undefined && Filters[key] !== "") {
      if (key === "Mas95Porcent" || key === "Menos50Porcent" || key === "TienePoliticPorcent" || key === "SinEvaluacionPorcent") {
        queryParts.push(`GraphicFilter.${key}=${true}`);
      } else {
        queryParts.push(`${key}=${Filters[key]}`);
      }
    }
  }
  let queryFilters = queryParts.join('&');
  const periodo = validateIdPeriod(evaluation_period_id)

  const handleSent = async () => {
    const { IdEvaluation, Status, index } = infoSemaforo
    let changeStatus = !Status
    setIsModalVisible(!isModalVisible);
    const newStatus = [...statusSemaforos]
    newStatus[index].status = false
    setStatusSemaforos(newStatus)


    await updateEvaluationStatusSemaforo({ IdEvaluation, Status })
    handleGetCollaborators(
      `?${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageNumber=1&PageSize=10`
    );
    setLoadingPagination(true)
  };



  return (
    <ModalAlertWrapper display={isModalVisible}>
      <div className="modalWrapper">
        <div className="close">
          <div className="title" style={{ color: '#F20505' }}>
            <img src={redBell} className="image" alt="AlertModalIcon" />
            Desactivar Autoevaluación
          </div>
          <div className="closeModal" onClick={() => setIsModalVisible(!isModalVisible)}>
            <img src={Close} alt="close" style={{ height: "16px" }} />
          </div>
        </div>
        <ContainerModalText>
          <div className="textContainer">
            Estás a punto de desactivar las autoevaluaciones para {evaluadoName}.
            <br />
            <br />
            ¿Deseas continuar con esta acción?
          </div>
        </ContainerModalText>
        <FooterModal>
          <ButtonModalSave
            onClick={handleSent}
          >
            Continuar
          </ButtonModalSave>
          <ButtonModalCancel
            onClick={() => setIsModalVisible(!isModalVisible)}
          >
            Cancelar
          </ButtonModalCancel>
        </FooterModal>
      </div>
    </ModalAlertWrapper>
  );
};

export default ModalDesactivarAutoEval;


export const InputObj = styled.textarea`
  width: 80%;
  height: 80%;
  border: none;
  outline: none;
  resize: none;
`;

export const ContainerModalText = styled.div`
  width: 90%;
  padding: 14px 21px;
  margin-top: 20px;

  .textContainer {
    font-size: 20px;
    color: #666666;
    font-weight: 400;
    padding-bottom: 5px;
  }

  .containerModal {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: end;
    font-size: 14px;
    padding: 2rem;

    .txt {
      margin-top: 8px;
      padding: 8px;
    }

    .containerTxt {
      background-color: white;
      border-radius: 8px;
      font-weight: 700;
      color: black;
      font-size: 14px;
      padding: 8px;
      margin: 8px;
      margin-right: 24px;
      width: 83%;
    }
  }
`;

export const FooterModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  padding: 14px 21px;
`;

export const ButtonModalSave = styled.div`
  background-color: #3b63a2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: white;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  align-content: center;
`;

export const ButtonModalCancel = styled.div`
  background-color: #c1c1c3;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #f0f0f0;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
