import { SelectLabelDashboard, SelectPeriodDashboard } from "../FilterDashboardStyles";

const SelectCustomdDashboard = ({
    valueSelect,
    nameSelect,
    nameLabel,
    children,
    hadleChange,
}: any) => {
    return (
        <SelectLabelDashboard>
            <div className="containerTxt">
                {nameLabel}
            </div>

            <SelectPeriodDashboard
                value={valueSelect}
                onChange={hadleChange}
                name={nameSelect}
            >
                {children}
            </SelectPeriodDashboard>
        </SelectLabelDashboard>
    )
};

export default SelectCustomdDashboard;
