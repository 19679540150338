import styled from "styled-components";

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
  justify-content: flex-end;

  @media (min-width: 320px) and (max-width: 1023px) {
    flex-direction: column;
    gap: 14px;
  }
`;

export const ButtonFirmar = {
  padding: "8px 32px",
  width: "114px",
  height: "40px",
  background: "#3B63A2",
  borderRadius: "8px",
  margin: "0 auto",
  borderColor: "transparent",
  marginTop: "1.3rem",
};

export const TableHeading = styled.thead`
  background: #3b63a2;
  color: #ffffff;
`;
export const TableHeadingDos = styled.div`
  background: #656569;
  color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  padding: 14px 8px;
  display: flex;

  .first {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .second {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .third {
    width: 37%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    /* border: 1px solid white; */
  }

  .fourth {
    width: 11%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .fifth {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .sixth {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .seventh {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const TableBodyDos = styled.div`
  background-color: #f8f8f8;
  /* color: #ffffff; */
  border-radius: 4px;
  padding: 14px 8px;
  display: flex;
  margin-bottom: 2px;
  align-items: center;
  justify-content: center;

  .first {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .second {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .third {
    width: 37%;
    display: flex;
    align-items: center;
    margin-left: 12px;
    /* justify-content: center; */
    padding: 0px 10px;
    /* border: 1px solid white; */
  }

  .fourth {
    width: 11%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .fifth {
    width: 11%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .sixth {
    width: 12.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .seventh {
    width: 12.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const TableDos = styled.div`
  overflow-x: scroll;
`;

export const EvaluationContainerDos = styled.div`
  /* width: 90vw; */
  display: flex;
  width: 100%;
`;

export const ButtonBlueFree = styled.button`
  color: white;
  background-color: #3b63a2;
  width: auto;
  font-weight: 700;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  border: none;
  padding: 8px 24px;
  border-radius: 8px;
  line-height: 16px;
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

export const TableDosWrapper = styled.div`
  min-width: 1240px;
  /* overflow: auto; */
  /* overflow-x: scroll; */
`;

export const TableSumDos = styled.div`
  background-color: #f8f8f8;
  /* color: #ffffff; */
  border-radius: 4px;
  padding: 14px 8px;
  display: flex;
  margin-bottom: 2px;
  width: 100%;
  /* border: 1px solid red; */

  .first {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .second {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .third {
    width: 37%;
    display: flex;
    align-items: center;
    margin-left: 12px;
    /* justify-content: center; */
    padding: 0px 10px;
    /* border: 1px solid white; */
  }

  .fourth {
    width: 11%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .fifth {
    width: 11%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid white; */
  }

  .sixth {
    width: 12.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .seventh {
    width: 12.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const FirmsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 3.5rem;
`;

export const EvaluatorFirmBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.2rem;
  p {
    font-weight: 700;
    font-size: 18px;
    /* color: #3b63a2; */
  }
`;

export const DirectorFirmBox = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 1.2rem;
  p {
    font-weight: 700;
    font-size: 18px;
    /* color: #3b63a2; */
  }
`;

export const ContainerDescription = styled.div`
  height: 40px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
`;

export const AdvisorText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #939396;
`;
export const ContainTd = {
  width: "21%",
};
export const BackTbale = {
  padding: "24px",
  gap: "16px",
  background: "#F8F8F8",
  borderRadius: "8px",
  marginBottom: "3.5rem",
};
export const AlignButtonFirm = {
  marginRight: "0.5rem",
  alignSelf: "flex-start",
};
