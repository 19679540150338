import styled from "styled-components";

export const AppContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Plus Jakarta Sans', sans-serif;
  background-color: #f0f0f0;
`;

export const Divider = styled.div `
  height: 1px;
  width:100%;
  display:block; /* for use on default inline elements like span */
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
`;
export const StyleSideBar = styled.div `
  display:flex;
  justify-content: flex-start;
  gap:1rem;
`;