import styled from "styled-components";
type SelectInterface = {
  widthSelect?: any;
};
type BtnInterface = {
  colorBg?: any;
  colorFont?: any;
};

export const FilterWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  .labelBoxTab {
    display: flex;
    width: 100px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px 8px 0px 0px;
    background: var(--secondary-blizzard-blue, #c9f2ff);
    color: var(--primary-true-blue, #3b63a2);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* Body Bold */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
  }
  .containerSelectsAndBtns {
    border: 1px solid var(--neutrals-platinum, #f0f0f0);
    background: var(--neutrals-white, #fff);
    width: 100%;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    .onlySelects {
      display: flex;
      width: 100%;
      /* max-width: 800px; */
      gap: 24px;
      /* border: 1px solid red; */
      align-items: center;
      align-content: center;
      justify-content: space-between;
    }

    .onlyBtns {
      display: flex;
      /* border: 1px solid red; */
      align-items: flex-end;
      align-content: flex-end;
      justify-content: flex-end;
      gap: 16px;
    }
  }
`;

export const SelectPeriodDashboard = styled.select.attrs(
  (props: SelectInterface) => ({})
) <SelectInterface>`
  width: 100%;
  border: 1px solid #f8f8f8;
  font-size: 13px;
  color: #939396;
  padding-left: 16px;
  border-radius: 0px 8px 8px 0px;
  margin: none !important;
  width: ${(props) => props.widthSelect || "56%"};
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;
  background-color: #f8f8f8;
  background-position: calc(100% - 0.6rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 100%;
  height: 40px;
  /* max-width: 180px; */
`;

export const OptionPeriod = styled.option`
  /* border: 1px solid red; */
`;

export const SelectLabelDashboard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  //styleName: Small Body Bold;
  font-family: Plus Jakarta Sans;
  /* color: #656569; */
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  justify-content: center;
  text-align: center;
  align-content: center;
  /* border: 1px solid red; */
  color: #333335;
  .containerTxt {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: var(--neutrals-jet, #333335);
    font-feature-settings: "clig" off, "liga" off;
    border: 1px solid var(--background-light-gray, #f8f8f8);
    background: var(--neutrals-white, #fff);
    /* Small Body */
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    color: #333335;
  }
`;

export const ButtonFilterDashboard = styled.button.attrs(
  (props: BtnInterface) => ({})
) <BtnInterface>`
  display: flex;
  /* width: 86px; */
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: ${(props) => props.colorBg || "#3b63a2"};
  color: ${(props) => props.colorFont || "#3b63a2"};
  line-height: 16px;
  /* height: 40px; */
  font-weight: 700;
  font-size: 14px;
`;


export const ContainerButtonsObjetives = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  /* border: 1px solid red; */
  margin-top: 16px;
  gap:8px;
`;
