import styled from "styled-components";

export const ButtonDashboardTable = styled.button`
  font-family: "Plus Jakarta Sans";
  /* padding: 8px 16px; */
  border: none;
  /* border-radius: 8px; */
  color: #656569;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
  text-decoration: underline;
`;

export const StatusDashboard = styled.p`
  line-height: 100%;
  color: #939396;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  margin: 0px;
  /* border: 1px solid red; */
`;

export const StatusDashboardSelect = styled.select`
  width: 100%;
  line-height: 100%;
  color: black;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  border-radius: 8px;
  padding: 5px;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;
  background-color: #f0f0f0;
  background-position: calc(100% - 0.6rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 100%;
  height: 35px;
`;

export const ContainerDashboard = styled.div`
  background-color: #ffffff;
  padding: 32px 24px;
  margin-bottom: 24px;
  gap: 24px;
`;

export const InputWrapperDashboard = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
`;

export const SelectDashboard = styled.select`
  width: 100%;
  line-height: 100%;
  color: black;
  font-family: "Plus Jakarta Sans";


  padding: 5px;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;
  background-color: #f0f0f0;
  background-position: calc(100% - 0.6rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 100%;
  height: 35px;
  font-size: 14px;
  border-radius: 0px 8px 8px 0px;
`;

export const LabelDashboard = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--background-light-gray, #f8f8f8);
  background: var(--neutrals-white, #fff);
  color: var(--neutrals-jet, #333335);
  font-feature-settings: "clig" off, "liga" off;
  /* Small Body */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;


export const InputDashboard = styled.input`
  width: 100%;
  line-height: 100%;
  color: black;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  border-radius: 0px 8px 8px 0px;
  padding: 5px;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  height: 35px;
`;