import { FC, useState } from "react";
import { Button, Container, Modal, Table } from "react-bootstrap";
import { Date } from "../../components/common/InputsByType";
import IconShowMore from "../../pages/Admin/Objectives/images/IconShowMore.svg";
import { ContainerTable, FliterByTiemRow } from "./Dashboard/stylesDashboard/AdminStyles";
import {
  ButtonActions,
  ColoBackground,
  Column,
  ContainModal,
  Row,
  StyleInputs,
  TitleModal,
} from "./Objectives/ObjetivesStyles.js";

const ColorHeadColumn = "#FFFFFF";
const ColorBack = "#FFFFF";
interface InfoTable {
  titleButton?: string;
}
const TableInfo: FC<InfoTable> = ({ titleButton }) => {
  const [inputValues, setInputValues] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onChangeTextField = () => {};
  return (
    <ContainerTable>
      <Table>
        <Row style={ColoBackground}>
          <Column color={ColorHeadColumn}>Nombre</Column>
          <Column color={ColorHeadColumn}>Estatus</Column>
          <Column color={ColorHeadColumn}>Acción</Column>
          <Column color={ColorHeadColumn}></Column>
        </Row>
        <Row>
          <Column>Base de datos del proyecto de regularización</Column>
          <Column color="#939396">Pendiente</Column>
          <Column>
            <ButtonActions onClick={handleShow}>{titleButton}</ButtonActions>
          </Column>
          <Column>
            <img src={IconShowMore} alt="showMore" />
          </Column>
        </Row>
        <Row>
          <Column>Base de datos del proyecto de regularización</Column>
          <Column color="#3B63A2">En progreso</Column>
          <Column>
            <ButtonActions onClick={handleShow}>{titleButton}</ButtonActions>
          </Column>
          <Column>
            <img src={IconShowMore} alt="showMore" />
          </Column>
        </Row>
        <Row>
          <Column>Base de datos del proyecto de regularización</Column>
          <Column color="#F27405">Aprobado</Column>
          <Column>
            <ButtonActions onClick={handleShow}>{titleButton}</ButtonActions>
          </Column>
          <Column>
            <img src={IconShowMore} alt="showMore" />
          </Column>
        </Row>
        <Row>
          <Column>Base de datos del proyecto de regularización</Column>
          <Column>Completado</Column>
          <Column>
            <ButtonActions onClick={handleShow}>{titleButton}</ButtonActions>
          </Column>
          <Column>
            <img src={IconShowMore} alt="showMore" />
          </Column>
        </Row>
        <Row>
          <Column>Base de datos del proyecto de regularización</Column>
          <Column color="#F20505">Rechazado</Column>
          <Column>
            <ButtonActions onClick={handleShow}>{titleButton}</ButtonActions>
          </Column>
          <Column>
            <img src={IconShowMore} alt="showMore" />
          </Column>
        </Row>
      </Table>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <ContainModal>
          <Modal.Header closeButton>
            <TitleModal style={{ width: "250px" }}>
              Selecciona la fecha de entrevista
            </TitleModal>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <FliterByTiemRow>
                <StyleInputs>
                  <Date
                    id="date"
                    title="Fecha"
                    name="date"
                    onChange={onChangeTextField}
                    value={inputValues}
                  />
                </StyleInputs>
                <>
                  <Button variant="primary" onClick={handleClose}>
                    Agendar cita
                  </Button>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                  </Button>
                </>
              </FliterByTiemRow>
            </Container>
          </Modal.Body>
        </ContainModal>
      </Modal>
    </ContainerTable>
  );
};

export default TableInfo;
