/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMeta, IQuestion, errorType } from "../Interface/ApiInterface";
import { actionEvent } from "../common/utils";
import { deleteQuestionsServices, getAllQuestions, postQuestionService, putQuestionsServices } from "../services/questions.service";
import { RootReducerInterface } from "./store";

enum QuestionnaireActions {
    GET_ALL_QUESTIONS = "GET_ALL_QUESTIONS",
    POST_QUESTION = "POST_QUESTION",
    PUT_QUESTION = "PUT_QUESTION",
    DELETE_QUESTION = "DELETE_QUESTION",
}

interface IQuestionsBaseInterface {
    loading: boolean;
    loadingPost: boolean;
    questions: IQuestion[];
    question: IQuestion;
    errorPeriod: errorType;
    Meta: IMeta;   
    messageQuestion: string;
    errorPost : boolean,
    setError: any;
}

interface UseQuestionsInterface extends IQuestionsBaseInterface {
    handleGetAllQuestions: (query: string) => void;
    handlePostQuestion: (question: IQuestion) => void;
    handlePutQuestion: (question: IQuestion) => void;
    handleDeleteQuestion: (id: number) => void;
}

interface ReducerActionsInterface {
    type: QuestionnaireActions;
    payload: IQuestionsBaseInterface;
}  
  

const useQuestions = (): UseQuestionsInterface => {
    const [ loading, setLoading ] = useState(false);
    const [ loadingPost, setLoadingPost ] = useState(false);
    const [errorPost, setError] = useState(false);
    const dispatch = useDispatch();

    const { questions, question, errorPeriod, Meta, messageQuestion } = useSelector(
        (state: RootReducerInterface) => state.Questions
    )

    const handleGetAllQuestions = async(query: string) => {        
        try {
            
            setLoading(true);
            const { data, meta } = await getAllQuestions(query);  
            // console.log("query: ", query);
                      
            setLoading(false);
            dispatch(
                actionEvent<QuestionnaireActions, IQuestionsBaseInterface>({
                    type: QuestionnaireActions.GET_ALL_QUESTIONS, 
                    payload: {
                        loading,
                        questions: data,
                        question,
                        errorPeriod,
                        messageQuestion,
                        Meta: meta,
                        loadingPost,
                        errorPost,
                        setError
                    }
                })
            );
        } catch (error) {
            dispatch(
                actionEvent<QuestionnaireActions, IQuestionsBaseInterface>({
                    type: QuestionnaireActions.GET_ALL_QUESTIONS,
                    payload: {
                        loading,
                        questions,
                        question,
                        errorPeriod,
                        messageQuestion,
                        Meta,
                        loadingPost,
                        errorPost,
                        setError
                    }
                })
            );
        }
    }

    const handlePostQuestion = async(question: IQuestion) => {
        try {
            setLoading(true);
            const { data } = await postQuestionService(question);
            dispatch(
                actionEvent<QuestionnaireActions, IQuestionsBaseInterface>({
                    type: QuestionnaireActions.POST_QUESTION,
                    payload: {
                        loading,
                        questions,
                        question: data,
                        errorPeriod,
                        messageQuestion,
                        Meta,
                        loadingPost,
                        errorPost,
                        setError
                    }
                })
            );
        } catch (error) {
            setError(true);
            dispatch(
                actionEvent<QuestionnaireActions, IQuestionsBaseInterface>({
                    type: QuestionnaireActions.POST_QUESTION,
                    payload: {
                        loading,
                        questions,
                        question,
                        errorPeriod,
                        messageQuestion,
                        Meta,
                        loadingPost,
                        errorPost,
                        setError
                    }
                })
            );
        }
    }

    const handlePutQuestion = async(question: IQuestion) => {
        try {
            setLoading(true);
            await putQuestionsServices(question);
            setLoading(false);
            dispatch(
                actionEvent<QuestionnaireActions, IQuestionsBaseInterface>({
                    type: QuestionnaireActions.PUT_QUESTION,
                    payload: {
                        loading,
                        questions,
                        question,
                        errorPeriod,
                        messageQuestion,
                        Meta,
                        loadingPost,
                        errorPost,
                        setError
                    }
                })
            );
        } catch (error) {
            dispatch(
                actionEvent<QuestionnaireActions, IQuestionsBaseInterface>({
                    type: QuestionnaireActions.PUT_QUESTION,
                    payload: {
                        loading,
                        questions,
                        question,
                        errorPeriod,
                        messageQuestion,
                        Meta,
                        loadingPost,
                        errorPost,
                        setError
                    }
                })
            );
        }
    }

    const handleDeleteQuestion = async(id: number) => {
        try {
            setLoading(true);
            const {data} = await deleteQuestionsServices(id);
            setLoading(false);
            dispatch(
                actionEvent<QuestionnaireActions, IQuestionsBaseInterface>({
                    type: QuestionnaireActions.DELETE_QUESTION,
                    payload: {
                        loading,
                        questions,
                        question,
                        errorPeriod,
                        messageQuestion: data,
                        Meta,
                        loadingPost,
                        errorPost,
                        setError
                    }
                })
            );
        } catch (error) {
            dispatch(
                actionEvent<QuestionnaireActions, IQuestionsBaseInterface>({
                    type: QuestionnaireActions.DELETE_QUESTION,
                    payload: {
                        loading,
                        questions,
                        question,
                        errorPeriod,
                        messageQuestion,
                        Meta,
                        errorPost,
                        loadingPost,
                        setError,

                    }
                })
            );
        }
    }
   

    return {
        loading,
        loadingPost,
        questions,
        question,
        Meta,
        errorPost,
        setError,
        errorPeriod,
        messageQuestion,
        handlePostQuestion,
        handleGetAllQuestions,
        handlePutQuestion,
        handleDeleteQuestion,
    }

}



const intailState: IQuestionsBaseInterface = {
    questions: [] as IQuestion[],
    question: {} as IQuestion,
    loading: false,
    loadingPost: false,
    errorPeriod: {} as errorType,
    Meta: {} as IMeta,
    messageQuestion: '',
    errorPost : false,
    setError: () => {},
}

const QuestionReducer = (state = intailState, action: ReducerActionsInterface) => {
    switch (action.type) {
      case QuestionnaireActions.GET_ALL_QUESTIONS:
        return {
          ...state,
          questions: action.payload.questions,
          Meta: action.payload.Meta,
        };
      case QuestionnaireActions.POST_QUESTION:
        return {
          ...state,
          question: action.payload.question,
          Meta: action.payload.Meta,
        };
      case QuestionnaireActions.PUT_QUESTION:
        return {
          ...state,
          question: action.payload.question,
          Meta: action.payload.Meta,
        };
      case QuestionnaireActions.DELETE_QUESTION:
        return {
          ...state,
          messageQuestion: action.payload.messageQuestion,
          Meta: action.payload.Meta,
        };
      default:
        return state;
    }
}



export {
    QuestionReducer, useQuestions
};

