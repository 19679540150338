import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  ModalBtnsStyles,
  ModalTitle,
} from "../../../components/organisms/KpisContainer/KpisContainerStyles";
import infoModalIcon from "./img/info-icon.svg";
// import { log } from "console";

const handleFilterData = (futureObj: any) => {
  const datosFuturosFiltradosDos = futureObj?.filter(
    (item: any) =>
      item.type === "D" &&
      item.description !== "Desempeño laboral, humano, actitud y habilidades"
  );
  // console.log("datosFuturosFiltradosDos", datosFuturosFiltradosDos);

  const allDataObjetives = [...datosFuturosFiltradosDos];

  const dataDos = allDataObjetives?.reduce(
    (a: any, b: any) => a + (b.valor || 0),
    0
  );

  return dataDos;
};
export const FooterTable = ({
  desempenioValue,
  valuesFuturesG,
  dataExtra,
  data,
}: any) => {
  const [validateValue, setValidateValue] = useState(true);

  const dataDefaultSum = handleFilterData(dataExtra);
  const valuesGSum = valuesFuturesG.reduce((a: any, b: any) => a + (b || 0), 0);
  const dataSum = data.reduce((a: any, b: any) => a + (b || 0), 0);

  const hasDesempenio = dataExtra.some((item: any) => item.description === "Desempeño laboral, humano, actitud y habilidades");


  useEffect(() => {
    const totalSum = dataSum + dataDefaultSum + valuesGSum + (hasDesempenio ? desempenioValue : 0);

    setValidateValue(totalSum !== 100);
  }, [desempenioValue, valuesFuturesG, dataDefaultSum, valuesGSum, dataSum, hasDesempenio]);

  const totalDisplayedSum = dataSum + dataDefaultSum + valuesGSum + (hasDesempenio ? desempenioValue : 0);

  return (
    <FooterWrapper colorBorber={validateValue}>
      <div className="first">
        {totalDisplayedSum}%
      </div>
      {validateValue && <div className="second">No suma 100%</div>}
    </FooterWrapper>
  );
};

type PropTypeBgTres = {
  colorBorber?: boolean;
};

const FooterWrapper = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 48px;
  font-weight: 700;
  width: 100%;
  margin: 2px 0;
  /* border-radius: 0px 0px 8px 8px; */
  border-radius: 2px;
  border: ${(props) => (props.colorBorber ? "1px solid red" : "black")};
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: ${(props) => (props.colorBorber ? "red" : "black")};
  letter-spacing: 1px;
  gap: 15px;

  .first {
    width: 27%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .second {
    width: 62%;
  }
`;

export const InfoModal = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div id="contained-modal-title-vcenter" style={ModalTitle}>
          <img src={infoModalIcon} alt="info" style={{ marginRight: "8px" }} />
          <p style={{ margin: "0" }}>Información comentarios del evaluador</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "#000", fontSize: "14px" }}>
          Aquí, el evaluador tiene la libertad y el derecho de escribir
          cualquier aspecto relacionado con la retroalimentación del evaluado.
          Es importante destacar que esta retroalimentación puede ser discutida
          y el evaluado tiene el derecho de expresar su acuerdo o desacuerdo.
          Valoramos la apertura y el diálogo constructivo para garantizar un
          ambiente de trabajo positivo y de mejora continua.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button style={ModalBtnsStyles} onClick={props.onHide}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const InfoModalEvaluado = (props: any) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div id="contained-modal-title-vcenter" style={ModalTitle}>
          <img src={infoModalIcon} alt="info" style={{ marginRight: "8px" }} />
          <p style={{ margin: "0" }}>Información comentarios del evaluado</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "#000", fontSize: "14px" }}>
          Aquí, el evaluado tiene la libertad y el derecho de escribir cualquier
          aspecto relacionado con la retroalimentación del evaluador. Es
          importante destacar que esta retroalimentación puede ser discutida y
          el evaluado tiene el derecho de expresar su acuerdo o desacuerdo.
          Valoramos la apertura y el diálogo constructivo para garantizar un
          ambiente de trabajo positivo y de mejora continua.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button style={ModalBtnsStyles} onClick={props.onHide}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const handleFilterDataFutureChanged = (futureObj: any) => {
  // const datosFuturosFiltradosDos = futureObj?.filter((item: any) =>
  //   item.type === "D" && item.description !== "Desempeño laboral, humano, actitud y habilidades")

  // const allDataObjetives = [...datosFuturosFiltradosDos]

  const dataDos = futureObj?.reduce((a: any, b: any) => a + (b.valor || 0), 0);

  return dataDos;
};

export const FooterTableChangedFuture = ({ dataExtra }: any) => {
  const dataDefaultSum = handleFilterDataFutureChanged(dataExtra);

  return (
    <FooterWrapper>
      <div className="first">{dataDefaultSum}%</div>
      {/* {validateValue && (
        <div className="second">
          No suma 100%
        </div>
      )} */}
    </FooterWrapper>
  );
};
