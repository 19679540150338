/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { ChangeEvent, useEffect, useState } from "react";
import UploadFile from "../../../components/common/UploadFile";

import FileSaver from "file-saver";
import InfoModal from "../../../components/atoms/Modals/InfoModal";
import PaginationTable from "../../../components/atoms/PaginationTable";
import GoalsModal from "../../../components/common/GoalsModal";
import Caution from "../../../components/organisms/ConfirmationModals/Caution";
import ModalStatusTargetError from "../../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import "../../../components/organisms/DashboardCardsBox/Change.css";
import FilterObjetivos from "../../../components/organisms/FilterObjetivos";
import {
  AdminContainerBlue,
  ContainTitleIconDos,
  FliterByTiemRow,
  RowBox,
  RowBoxDos,
  SubContainer,
  SubContainerDos,
  SubTitleDos,
  Title,
  TitleDos,
} from "../../../pages/Admin/Objectives/ObjetivesStyles";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useObjetives } from "../../../redux/Objetives";
import {
  BulkGeneralObjetivesUploadServices,
  BulkIndividualObjetivesUploadServices,
} from "../../../services/objetives.services";
import { TableObjetives } from "../../Admin/Objectives/Table";
import FilterGeneralObjetive from "../Dashboard/NewComponentsDashboardRefactor/FilterGeneralObjetive";
import FilterIndividualObjetives from "../Dashboard/NewComponentsDashboardRefactor/FilterIndividualObjetive";
import { ContainerTableFixed } from "../Dashboard/stylesDashboard/AdminStyles";
import GetCurrentDate from "./GetDate";
import MyVerticallyCenteredModal from "./LoaderModal";
import { CenterPaginate } from "./ObjetivesStyles";
import { validationGeneral, validationIndividual } from "./validacion";
import ModalLoader from "../../../components/organisms/ConfirmationModals/ModalLoader";
import { useLoader } from "../../../context/LoaderContext";
export interface IGeneralObjetivesFilter {
  verbFilterGeneral: String;
  adverbFilterGeneral: String;
  mathFilterGeneral: String;
  locationFilterGeneral: String;
  KPIFilterGeneral: String;
  prepositionFilterGeneral: String;
  indicatorFilterGeneral: any;
  dateFilterGeneral: any;
  yearFilterGeneral: any;
  departmentFilterGeneral: String;
  areaFilterGeneral: String;
  semesterFilterGeneral: any;
  companyFilterGeneral: String;
}

export interface IIndividualObjetivesFilter {
  verbFilterIndividual: String;
  adverbFilterIndividual: String;
  mathFilterIndividual: String;
  KPIFilterIndividual: String;
  prepositionFilterIndividual: String;
  indicatorFilterIndividual: any;
  dateFilterIndividual: any;
  yearFilterIndividual: any;
  semesterFilterIndividual: any;
}

const mathExpression = [
  { text: "Selecciona opción", value: "0" },
  { text: "igual", value: "igual" },
  { text: "menor que", value: "menor que" },
  { text: "mayor que", value: "mayor que" },
  { text: "menor igual que", value: "menor igual que" },
  { text: "mayor igual que", value: "mayor igual que" },
];

const Objectives = () => {
  //** Redux

  const { evaluationAttribute } = useCollaboratorRedux();
  const {
    MetaGeneral,
    MetaIndividual,
    generalObjetives,
    individualObjetives,
    yearsSemesters,
    handleGetAllGeneralObjetives,
    handleGetAllIndividualObjetives,
    handleGetPeriodYearsSemesters,
    handleSetGeneralFilter,
    handleSetIndividualFilter,
    setSuccesPost,
    setErrorPost,
    errorPost,
    succesPost,
  } = useObjetives();

  const [inputValuesGeneral, setInputValuesGeneral] = useState({
    verbFilterGeneral: "",
    KPIFilterGeneral: "",
    mathFilterGeneral: "0",
    prepositionFilterGeneral: "",
    indicatorFilterGeneral: "",
    adverbFilterGeneral: "",
    dateFilterGeneral: "",
    yearFilterGeneral: "",
    semesterFilterGeneral: "",
    locationFilterGeneral: "",
    departmentFilterGeneral: "",
    areaFilterGeneral: "",
    companyFilterGeneral: "",
  });
  const [inputValuesIndividual, setInputValuesIndividual] = useState({
    verbFilterIndividual: "",
    KPIFilterIndividual: "",
    mathFilterIndividual: "",
    prepositionFilterIndividual: "",
    indicatorFilterIndividual: "",
    adverbFilterIndividual: "",
    dateFilterIndividual: "",
    yearFilterIndividual: "",
    semesterFilterIndividual: "",
  });
  const [GeneralObjetivesFile, setGeneralObjetivesFile] = useState<File>();
  const [IndividualObjetivesFile, setIndividualObjetivesFile] =
    useState<File>();
  const [generalObjetivesFilter, setGeneralObjetivesFilter] =
    useState<IGeneralObjetivesFilter>({} as IGeneralObjetivesFilter);
  const [individualObjetivesFilter, setIndividualObjetivesFilter] =
    useState<IIndividualObjetivesFilter>({} as IIndividualObjetivesFilter);
  const [showIndividual, setShowIndividual] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const [showGeneral, setShowGeneral] = useState(false);
  const [inputValues, setInputValues] = useState({});

  const [modalShow, setModalShow] = useState(false);
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [messageModalDos, setMessageModalDos] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [textBodyModal, setTextBodyModal] = useState("");
  const [imageError, setImageError] = useState(false);

  const [rowsInsertedResponse, setRowsInsertedResponse] = useState<number>();
  const [rowsNotInsertedResponse, setRowsNotInsertedResponse] =
    useState<number>();
  const [excelFile, setExcelFile] = useState<any>();
  const [excelFileName, setExcelFileName] = useState<any>();
  //
  // const []

  const now = GetCurrentDate();
  const [years, setYears] = useState([
    { text: now.getFullYear().toString(), value: now.getFullYear().toString() },
  ]);
  const [yearPeriod, setYearPeriod] = useState([
    { text: "1", value: "1" },
    { text: "2", value: "2" },
  ]);

  const [evaluation_period_id, setEvaluation_period_id] = useState<string>("");
  useEffect(() => {
    loadYearsSemesters();
  }, [yearsSemesters]);
  const onChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name.includes("Individual")) {
      setIndividualObjetivesFilter({
        ...individualObjetivesFilter,
        [name]: value,
      });
    } else if (name.includes("General")) {
      setGeneralObjetivesFilter({
        ...generalObjetivesFilter,
        [name]: value,
      });
    }

    setInputValuesGeneral({
      ...inputValuesGeneral,
      [name]: value,
    });

    setInputValuesIndividual({
      ...inputValuesIndividual,
      [name]: value,
    });
  };

  const uploadFileGeneral = async () => {
    setIsIndividual(false);
    try {
      if (GeneralObjetivesFile) {
        await BulkGeneralObjetivesUploadServices(GeneralObjetivesFile)
          .then((response) => {
            const { message, rowsInserted, rowsNotInserted, excelContent } =
              response;
            setShowModalCaution(false);
            setMessageModal(message);
            setSuccesPost(true);
            setRowsInsertedResponse(rowsInserted);
            setRowsNotInsertedResponse(rowsNotInserted);
            if (excelContent) {
              const byteCharacters = atob(excelContent.fileContents);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], {
                type: excelContent.contentType,
              });
              setExcelFile(blob);
              setExcelFileName(excelContent.fileDownloadName);
            }

            let query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;
            handleGetAllGeneralObjetives(query);
          })
          .catch((error) => {
            const { detail, title } = error;
            setTitleModal(title);
            setShowModalCaution(false);
            setMessageModalDos(detail);
            setErrorPost(true);
          });
        return {
          data: undefined,
          success: true,
          message: textBodyModal,
          titleError: titleModal,
          imageError: imageError,
        };
      }
    } catch (error) {}
  };

  const downloadExcelFile = () => {
    if (excelFile) {
      FileSaver.saveAs(excelFile, excelFileName);
    }
  };

  const onChangeUploadGeneral = (event: any) => {
    setIsIndividual(false);
    const receiveFile = event.target.files?.[0];
    if (receiveFile) {
      setGeneralObjetivesFile(receiveFile);
      setShowModalCaution(true);
    }
  };

  const upLoadFileIndividuales = async () => {
    try {
      if (IndividualObjetivesFile) {
        await BulkIndividualObjetivesUploadServices(IndividualObjetivesFile)
          .then((response) => {
            const { message, rowsInserted, rowsNotInserted, excelContent } =
              response;
            setShowModalCaution(false);
            setMessageModal(message);
            setSuccesPost(true);
            setRowsInsertedResponse(rowsInserted);
            setRowsNotInsertedResponse(rowsNotInserted);
            if (excelContent) {
              const byteCharacters = atob(excelContent.fileContents);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], {
                type: excelContent.contentType,
              });
              setExcelFile(blob);
              setExcelFileName(excelContent.fileDownloadName);
            }
            let query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;

            handleGetAllIndividualObjetives(query);
          })
          .catch((error) => {
            const { detail } = error;
            setShowModalCaution(false);
            setMessageModal("");
            setMessageModalDos(detail);
            setErrorPost(true);
          });
        return {
          data: undefined,
          success: true,
          message: textBodyModal,
          titleError: titleModal,
          imageError: imageError,
        };
      }
    } catch (error) {}
  };

  // console.log("Respuesta objetivos individuales" , messageModal)

  const loadYearsSemesters = () => {
    setYearPeriod(
      yearsSemesters?.evaluationPeriodNumberOfYear?.map((r) => {
        return { text: r.toString(), value: r.toString() };
      })
    );

    setYears(
      yearsSemesters?.evaluationPeriodYear?.map((r) => {
        return { text: r.toString(), value: r.toString() };
      })
    );
  };

  const onChangeUploadIndividual = (event: ChangeEvent<HTMLInputElement>) => {
    setIsIndividual(true);
    const receiveFile = event.target.files?.[0];
    setIndividualObjetivesFile(receiveFile);
    setShowModalCaution(true);
  };

  const handleClearFilters = (e: any, name: string) => {
    let query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;

    if (name === "General") {
      setGeneralObjetivesFilter({} as IGeneralObjetivesFilter);
      // console.log("Se limpian los filtros")
      setInputValuesGeneral({
        verbFilterGeneral: "",
        KPIFilterGeneral: "",
        mathFilterGeneral: "0",
        prepositionFilterGeneral: "",
        indicatorFilterGeneral: "",
        adverbFilterGeneral: "",
        dateFilterGeneral: "",
        yearFilterGeneral: "",
        semesterFilterGeneral: "",
        locationFilterGeneral: "",
        departmentFilterGeneral: "",
        areaFilterGeneral: "",
        companyFilterGeneral: "",
      });
      // console.log("aquí se manda", query);

      handleGetAllGeneralObjetives(query);
    }
    if (name === "Individual") {
      setIndividualObjetivesFilter({} as IIndividualObjetivesFilter);
      setInputValuesIndividual({
        verbFilterIndividual: "",
        KPIFilterIndividual: "",
        mathFilterIndividual: "",
        prepositionFilterIndividual: "",
        indicatorFilterIndividual: "",
        adverbFilterIndividual: "",
        dateFilterIndividual: "",
        yearFilterIndividual: "",
        semesterFilterIndividual: "",
      });
      handleGetAllIndividualObjetives(query);
    }
  };

  const handleSearchFilters = (e?: any, name?: string) => {
    if (evaluation_period_id) {
      let query = `?`;
      if (name === "General") {
        const newQuery = validationGeneral(
          generalObjetivesFilter,
          evaluation_period_id
        );
        handleSetGeneralFilter(generalObjetivesFilter);
        // console.log("aquí se manda", newQuery);

        handleGetAllGeneralObjetives(newQuery);
      }

      if (name === "Individual") {
        const newQuery = validationIndividual(
          individualObjetivesFilter,
          evaluation_period_id
        );
        handleSetIndividualFilter(individualObjetivesFilter);
        handleGetAllIndividualObjetives(newQuery);
      }

      if (!name) {
        query += `IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;
        handleGetAllIndividualObjetives(query);
        handleGetAllGeneralObjetives(query);
      }
    }
  };

  const handleAction = (e: any) => {
    setEvaluation_period_id(e);
  };

  useEffect(() => {
    const newQuery = validationIndividual(
      individualObjetivesFilter,
      evaluation_period_id
    );
    handleSetIndividualFilter(individualObjetivesFilter);
    handleGetAllIndividualObjetives(newQuery);
  }, [individualObjetivesFilter]);

  useEffect(() => {
    if (evaluation_period_id) {
      const newQuery = validationGeneral(
        generalObjetivesFilter,
        evaluation_period_id
      );
      //

      handleSetGeneralFilter(generalObjetivesFilter);

      handleGetAllGeneralObjetives(newQuery);
    }
  }, [generalObjetivesFilter]);

  
  useEffect(() => {
    if (evaluation_period_id) {
      handleSearchFilters();
    }
  }, [evaluation_period_id]);

  // console.log("Hola", inputValuesGeneral);

  const { isLoading } = useLoader();

  return (
    <AdminContainerBlue>
      {isLoading && <ModalLoader />}
      <ContainTitleIconDos>
        <TitleDos>Objetivos</TitleDos>
        {evaluationAttribute.modal_tool_modal_writing_objectives === 1 && (
          <InfoModal />
        )}
      </ContainTitleIconDos>
      <FliterByTiemRow>
        <FilterObjetivos
          handleAction={handleAction}
          setEvaluation_period_id={setEvaluation_period_id}
        />
      </FliterByTiemRow>

      <RowBoxDos>
        <SubTitleDos>Objetivos generales para el siguiente periodo</SubTitleDos>
      </RowBoxDos>
      <SubContainerDos>
        {evaluationAttribute.objective_added_files === 1 && (
          <>
            <Title>Archivos agregados</Title>
            <UploadFile
              dataFirst={24}
              dataSecond={25}
              setShowModal={setShowGeneral}
              accept=".xlsx"
              fileSizeMB={10}
              txtFormat="excel"
              file={GeneralObjetivesFile}
              onChangeUpload={onChangeUploadGeneral}
            />
            <GoalsModal
              inputValues={inputValues}
              setInputValues={setInputValues}
              setOpenModal={setOpenModal}
              openModal={openModal}
              evaluation_period_id={evaluation_period_id}
              setShow={setShowGeneral}
              setShowOther={showIndividual}
              show={showGeneral}
              titleModal="Ingresar objetivos generales"
            />
          </>
        )}

        <FilterGeneralObjetive
          onChangeTextField={onChangeTextField}
          inputValuesGeneral={inputValuesGeneral}
          handleSearchFilters={handleSearchFilters}
          handleClearFilters={handleClearFilters}
        />

        <ContainerTableFixed>
          {evaluationAttribute.list_global_objetives === 1 && (
            <TableObjetives
              evaluation_period_id={evaluation_period_id}
              propValidationFirst={28}
              type="G"
              data={generalObjetives}
              setShow={setShowGeneral}
            ></TableObjetives>
          )}
          <CenterPaginate>
            <PaginationTable
              table="Generales"
              Meta={MetaGeneral}
              evaluation_period_id={evaluation_period_id}
            />
          </CenterPaginate>
        </ContainerTableFixed>
      </SubContainerDos>
      <RowBox>
        {/* <ImgSize src={Individuales} alt="individuales" /> */}
        <ContainTitleIconDos>
          <SubTitleDos>Objetivos individuales de la empresa</SubTitleDos>
        </ContainTitleIconDos>
      </RowBox>
      <SubContainer>
        {evaluationAttribute.objective_added_files === 1 && (
          <>
            <Title>Archivos agregados</Title>
            <UploadFile
              dataFirst={29}
              dataSecond={30}
              setShowModal={setShowIndividual}
              accept=".xlsx"
              fileSizeMB={10}
              txtFormat="excel"
              file={IndividualObjetivesFile}
              onChangeUpload={onChangeUploadIndividual}
            />
            <GoalsModal
              inputValues={inputValues}
              setInputValues={setInputValues}
              setOpenModal={setOpenModal}
              openModal={openModal}
              setShow={setShowIndividual}
              setShowOther={showGeneral}
              evaluation_period_id={evaluation_period_id}
              show={showIndividual}
              titleModal="Ingresar objetivo individual"
            />
          </>
        )}

        <FilterIndividualObjetives
          onChangeTextField={onChangeTextField}
          inputValuesIndividual={inputValuesIndividual}
          handleSearchFilters={handleSearchFilters}
          handleClearFilters={handleClearFilters}
        />
        <ContainerTableFixed>
          {evaluationAttribute.list_objetives_individual === 1 && (
            <TableObjetives
              evaluation_period_id={evaluation_period_id}
              propValidationFirst={33}
              type="I"
              data={individualObjetives}
              setShow={setShowIndividual}
            ></TableObjetives>
          )}
          <CenterPaginate>
            <PaginationTable
              table="Individuales"
              Meta={MetaIndividual}
              evaluation_period_id={evaluation_period_id}
            />
          </CenterPaginate>
        </ContainerTableFixed>
      </SubContainer>

      {succesPost ? (
        <ModalStatusSuccessful
          handleClick={setSuccesPost}
          modalStatus={succesPost}
          messageShow
          message={
            rowsNotInsertedResponse
              ? `Hemos cargado ${rowsInsertedResponse} registros con éxito.\r\nSin embargo se detectaron ${rowsNotInsertedResponse} errores en el proceso. Por favor, revisa el archivo y cárgalo nuevamente. `
              : `Hemos cargado ${rowsInsertedResponse} registros con éxito. No se han detectado errores en el proceso. `
          }
          onClick={downloadExcelFile}
          excelFile={excelFile}
          rowsNotInsertedResponse={rowsNotInsertedResponse}
          titleModal={
            rowsNotInsertedResponse
              ? `Carga de registros parcial`
              : `Carga de registros completa`
          }
        />
      ) : null}
      {errorPost ? (
        <ModalStatusTargetError
          handleClick={setErrorPost}
          modalStatus={errorPost}
          messageShow={true}
          titleModal={"Formato de documento no válido"}
          message={`Lamentamos informarte que solo se admiten documentos en formato .xlsx o .xls en este momento. \r\nPor favor, asegúrate de cargar un archivo con la extensión correcta para continuar.`}
        />
      ) : null}
      <Caution
        textBody={
          "Se realizará la carga masiva de datos desde Excel. Asegúrate de que el archivo esté correctamente estructurado."
        }
        textBodyQuestion={"¿Deseas continuar?"}
        textBodyOk={messageModal}
        messageShow={true}
        title="Confirmar carga masiva"
        estado={showModalCaution}
        cambiarEstado={setShowModalCaution}
        textButtonAct="Continuar"
        textButtonClose="Cancelar"
        handleClick={isIndividual ? upLoadFileIndividuales : uploadFileGeneral}
      />
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </AdminContainerBlue>
  );
};

export default Objectives;
