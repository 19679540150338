import { http } from '../api';

const getLogs = async (FechaInt: any, FechaFin: any, ClaTrab: any) => {
  // const periodo = validateIdPeriod(IdPeriod)
  try {


    //! PRIMER CASO
    //**  NO ClaTrab 
    //**  NO FechaFin 
    //**  SI FechaInt 
    if (
      (ClaTrab.trim("") === "" || ClaTrab === null) &&
      (FechaFin === "" || FechaFin === null || FechaFin === undefined) &&
      (FechaInt.trim("") !== "" || FechaInt !== null)
    ) {
      // console.log("Entra aquí!");

      const response = await http.get(`Logs/get-logs-report?FechaInt=${FechaInt}`, {
        method: 'GET',
        responseType: 'blob', // important
      });
      return response;

    }

    //! SEGUNDO CASO
    //**  NO ClaTrab 
    //**  SI FechaFin 
    //**  SI FechaInt 

    if (
      (FechaFin !== "" || FechaFin !== null || FechaFin !== undefined) &&
      (FechaInt.trim("") !== "" || FechaInt !== null) &&
      (ClaTrab.trim("") === "" || ClaTrab === null)
    ) {
      const response = await http.get(`Logs/get-logs-report?FechaInt=${FechaInt}&FechaFin=${FechaFin}`, {
        method: 'GET',
        responseType: 'blob', // important
      });
      return response;

    }

    //! TERCER CASO
    //**  SI ClaTrab 
    //**  SI FechaFin 
    //**  SI FechaInt 
    if (
      (FechaFin !== "" || FechaFin !== null || FechaFin !== undefined) &&
      (FechaInt.trim("") !== "" || FechaInt !== null) &&
      (ClaTrab.trim("") !== "" || ClaTrab !== null)
    ) {
      const response = await http.get(`Logs/get-logs-report?FechaInt=${FechaInt}&FechaFin=${FechaFin}&ClaTrab=${Number(ClaTrab)}`, {
        method: 'GET',
        responseType: 'blob', // important
      });
      return response;

    }


    else {
      const response = await http.get(`Logs/get-logs-report?FechaInt=${FechaInt}&FechaFin=${FechaFin}&ClaTrab=${Number(ClaTrab)}`, {
        method: 'GET',
        responseType: 'blob', // important
      });
      return response;
    }



  } catch (error) {
    throw error;
  }
};


const getLogsB = async (IdEvaluacion: number) => {
  // const periodo = validateIdPeriod(IdPeriod)
  try {
    const response = await http.get(`Logs/get-logs-report?IdEvaluacion=${IdEvaluacion}`, {
      method: 'GET',
      responseType: 'blob', // important
    });

    return response;
  } catch (error) {
    throw error;
  }
};
export { getLogs, getLogsB };

