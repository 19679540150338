/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IMeta,
    IPolitic,
    IPoliticsFilter,
    PolitcEvaluation,
} from "../Interface/ApiInterface";
import { actionEvent } from "../common/utils";
import {
    getAllPolitics,
    getAllPoliticsFilter,
    updatePolitic,
} from "../services/politics.services";
import { RootReducerInterface } from "./store";

enum PoliticsActions {
  GET_POLITICS = "GET_POLITICS",
  GET_POLITICS_FILTER = "GET_POLITICS_FILTER",
  GET_POLITICS_SUCCESS = "GET_POLITICS_SUCCESS",
  UPDATE_POLITICS = "UPDATE_POLITICS",
  SET_FILTER_POLITIC = "SET_FILTER_POLITIC",
}

interface errorType {
  error: any;
  type: string;
}

interface IPoliticInterface {
  politic: IPolitic[];
  MetaPolitic: IMeta;
  FilterPolitic: IPoliticsFilter;
  setSuccesPost: (succes: boolean) => void;
  succesPost: boolean;
  errorPost: boolean;
  setErrorPost: (error: boolean) => void;
}

interface IGetPolitics extends IPoliticInterface {
  handleGetAllPolitics: (query: any) => void;
  handleGetAllPoliticsFilter: (query: any) => void;
  handleUpdatePolicies: (body: PolitcEvaluation) => void;
  handleSetPoliticlFilter: (filter: IPoliticsFilter) => void;
}

interface ReducerActionsInterface {
  type: PoliticsActions;
  payload: IPoliticInterface;
}

const usePolitics = (): IGetPolitics => {
  const dispatch = useDispatch();
  const [succesPost, setSuccesPost] = useState(false);
  const [errorPost, setErrorPost] = useState(false);

  const { politic, MetaPolitic, FilterPolitic } = useSelector(
    (state: RootReducerInterface) => state.Politics
  );

  const handleSetPoliticlFilter = async (filter: IPoliticsFilter) => {
    dispatch(
      actionEvent<PoliticsActions, IPoliticInterface>({
        type: PoliticsActions.SET_FILTER_POLITIC,
        payload: {
          MetaPolitic,
          politic,
          FilterPolitic: filter,
          succesPost,
          errorPost,
          setSuccesPost,
          setErrorPost,
        },
      })
    );
  };

  const handleGetAllPoliticsFilter = async (query: any) => {
    try {
      const response = await getAllPoliticsFilter(query);
      const { data, meta } = response;
      setSuccesPost(true);
      dispatch(
        actionEvent<PoliticsActions, IPoliticInterface>({
          type: PoliticsActions.GET_POLITICS_FILTER,
          payload: {
            MetaPolitic: meta,
            politic: data,
            succesPost,
            errorPost,
            FilterPolitic,
            setSuccesPost,
            setErrorPost,
          },
        })
      );
    } catch (error) {
      //
      dispatch(
        actionEvent<PoliticsActions, IPoliticInterface>({
          type: PoliticsActions.GET_POLITICS_SUCCESS,
          payload: {
            MetaPolitic,
            politic,
            succesPost,
            errorPost,
            FilterPolitic,
            setSuccesPost,
            setErrorPost,
          },
        })
      );
      setErrorPost(true);
    }
  };
  const handleGetAllPolitics = async (query: any) => {
    try {
      const response = await getAllPolitics(query);
      const { data, meta } = response;
      setSuccesPost(true);
      dispatch(
        actionEvent<PoliticsActions, IPoliticInterface>({
          type: PoliticsActions.GET_POLITICS_SUCCESS,
          payload: {
            MetaPolitic: meta,
            politic: data,
            succesPost,
            errorPost,
            FilterPolitic,
            setSuccesPost,
            setErrorPost,
          },
        })
      );
    } catch (error) {
      //
      dispatch(
        actionEvent<PoliticsActions, IPoliticInterface>({
          type: PoliticsActions.GET_POLITICS_SUCCESS,
          payload: {
            MetaPolitic,
            politic,
            succesPost,
            errorPost,
            FilterPolitic,
            setSuccesPost,
            setErrorPost,
          },
        })
      );
      setErrorPost(true);
      //
    }
  };
  const handleUpdatePolicies = async (body: PolitcEvaluation) => {
    try {
      //

      await updatePolitic(body);
      // await handleGetAllPolitics();
      setSuccesPost(true);

      dispatch(
        actionEvent<PoliticsActions, IPoliticInterface>({
          type: PoliticsActions.UPDATE_POLITICS,
        })
      );
    } catch (error) {
      dispatch(
        actionEvent<PoliticsActions, IPoliticInterface>({
          type: PoliticsActions.GET_POLITICS_SUCCESS,
          payload: {
            MetaPolitic,
            politic,
            succesPost,
            errorPost,
            FilterPolitic,
            setSuccesPost,
            setErrorPost,
          },
        })
      );
      // setErrorPost(true);
    }
  };

  return {
    politic,
    succesPost,
    errorPost,
    MetaPolitic,
    FilterPolitic,
    handleGetAllPolitics,
    handleUpdatePolicies,
    setSuccesPost,
    setErrorPost,
    handleSetPoliticlFilter,
    handleGetAllPoliticsFilter,
  };
};

const intailState: IPoliticInterface = {
  politic: [],
  errorPost: false,
  succesPost: false,
  MetaPolitic: {} as IMeta,
  FilterPolitic: {} as IPoliticsFilter,
  setSuccesPost: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
  setErrorPost: function (error: boolean): void {
    throw new Error("Function not implemented.");
  },
};

const PoliticsReducer = (
  state = intailState,
  action: ReducerActionsInterface
) => {
  switch (action.type) {
    case PoliticsActions.GET_POLITICS_SUCCESS:
      return {
        ...state,
        politic: action.payload.politic,
        succesPost: action.payload.succesPost,
        errorPost: action.payload.errorPost,
        MetaPolitic: action.payload.MetaPolitic,
      };
    case PoliticsActions.SET_FILTER_POLITIC:
      return {
        ...state,
        FilterPolitic: action.payload.FilterPolitic,
      };
    case PoliticsActions.GET_POLITICS_FILTER:
      return {
        ...state,
        politic: action.payload.politic,
        succesPost: action.payload.succesPost,
        errorPost: action.payload.errorPost,
        MetaPolitic: action.payload.MetaPolitic,
      };
    default:
      return state;
  }
};
export { PoliticsReducer, usePolitics };

