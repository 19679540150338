import { useContext, useEffect, useState } from "react";
import { TableData, ValueType } from "../../Interface/ApiInterface";
import {
  GoalsHeadTable,
  WrapperGoalsTable,
} from "../../components/atoms/EvaluationForms/EvaluationFormsStyles";
import ModalStatusTargetError from "../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useObjetives } from "../../redux/Objetives";
import ModalEditObjective from "./ModalActions/ModalEditObjective";
import {
  FooterTable,
  FooterTableChangedFuture,
} from "./TableGoalValueComponents/FooterTable";

//**  Context
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  NewEvaluationContext,
  NewEvaluationContextType,
} from "../../context/EvaluacionContext/NewEvaluationContext";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../context/EvaluationContext";
import { useUrlPermisos } from "../../hooks/Evaluation";
import { getObjectivesPreviewListFuture } from "../../services/dataSelects.service";
import {
  ColumnsDataFutureChangedPeriod,
  ColumnsDataFutureD,
  ColumnsDataFutureDTwo,
  ColumnsDataFutureG,
  ColumnsDataFutureSelect,
} from "./TableGoalValueComponents/ColumnsDataFuture";
import { useAbleFutureObjetivesSelects } from "../../hooks/ObjetivosFuturos";
import { useLoader } from "../../context/LoaderContext";

const TableGoalValue = ({
  evaluation,
  desempenioValue,
  setDesempenioValue,
  data,
  setData,
  valuesFuturesG,
  setValuesFuturesG,
  reload
}: any) => {
  //* Redux
  const { individualObjetives, handleGetAllIndividualObjetives } =
    useObjetives();
  const { Evaluation: DatosAMapear } = useEvaluationRedux();

  //* Custom Hooks
  const { urlPermisos } = useUrlPermisos();
  const { ableSelectSelect } = useAbleFutureObjetivesSelects();
  const { incrementRequests, decrementRequests } = useLoader();

  //*  Hooks
  const [optionsData, setOptionsData] = useState<ValueType[]>();
  const [indexObjetive, setIndexObjetive] = useState(0);
  const [succesPost, setSuccesPost] = useState(false);
  const [errorPost, setErrorPost] = useState(false);

  //!Validación
  const [copyData, setCopyData] = useState<any>([]);
  //!Validación

  //? objetivos futuros
  const [loadSelects, setLoadSelects] = useState(false);

  //? objetivos futuros del cambio de periodo!!
  const [periodChangeFutures, setPeriodChangeFutures] = useState<any>([]);

  const [modalEditObjetive, setModalEditObjetive] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [optionsUsed, setOptionsUsed] = useState<any[]>([]);

  //* Context
  const { futureObjetives,setFutureObjetives } = useContext(
    EvaluationContext
  ) as EvaluationContextType;
  //** Context
  const { setValidateFutureObjs, setValidateFieldsFutureObjs } = useContext(
    NewEvaluationContext
  ) as NewEvaluationContextType;

  //** Context Cambio de periodo
  const { changePeriodEvaluation, realizedSearch, periodAutoEvaluation } =
    useContext(ChangePeriodContext) as ChangePeriodContextType;


  const [dataConocimientos, setDataConocimientos] = useState<any>([]);

  useEffect(() => {
    if (dataConocimientos.length === 0) {
      const conocimientos = futureObjetives.filter(
        (item: any) => item.type === "D" &&
          item.description !==
          "Desempeño laboral, humano, actitud y habilidades"
      );
      setDataConocimientos(conocimientos)
    }
  }, [futureObjetives]);

  useEffect(() => {
    const updatedData = data.map((currentObj: any) => {
      const hasChanged =
        currentObj.valor !== 0 &&
        currentObj.valor !== null &&
        currentObj.valor !== "" &&
        currentObj.valor !== "0" &&
        currentObj.meta !== 0 &&
        currentObj.meta !== null &&
        currentObj.meta !== "" &&
        currentObj.meta !== "0" &&
        currentObj.descripcion.label !== "Selecciona una opcion";

      const nothingChanged =
        (currentObj.valor === 0 ||
          currentObj.valor === null ||
          currentObj.valor === "" ||
          currentObj.valor === "0") &&
        (currentObj.meta === 0 ||
          currentObj.meta === null ||
          currentObj.meta === "" ||
          currentObj.meta === "0") &&
        currentObj.descripcion.label === "Selecciona una opcion";

      if (hasChanged) {
        return hasChanged;
      }
      if (nothingChanged) {
        return nothingChanged;
      }
      return hasChanged;
    });

    setCopyData(updatedData);

    const validateFutureObjetives = () => {
      if (updatedData.includes(false)) {
        setValidateFieldsFutureObjs(true);
      } else {
        setValidateFieldsFutureObjs(false);
      }
    };

    validateFutureObjetives();
  }, [data]);

  const validate = () => {
    const datoBusqueda = data?.map((item: any) => item?.descripcion);
    const datoSeteado = datoBusqueda?.find(
      (item: any) => item?.value !== "null"
    );

    if (datoSeteado !== undefined && !urlPermisos) {
      const valuesDataToNumber = data.map((item: any) => Number(item.valor));
      const desempenioValueToNumber = Number(desempenioValue);

      const dataUno = valuesDataToNumber?.reduce(
        (a: any, b: any) => a + (b || 0),
        0
      );

      const datosFuturosFiltradosDos = futureObjetives.filter(
        (item: any) =>
          item.type === "D" &&
          item.description !==
          "Desempeño laboral, humano, actitud y habilidades"
      );

      const allDataObjetives = [...datosFuturosFiltradosDos];

      const dataDos = allDataObjetives?.reduce(
        (a: any, b: any) => a + (b.valor || 0),
        0
      );

      const dataFuturesG = valuesFuturesG
        ?.map((item: any) => Number(item?.valor))
        .reduce((a: any, b: any) => a + (b || 0), 0);

      if (dataUno + dataDos + desempenioValueToNumber + dataFuturesG === 100) {
        setValidateFutureObjs(false);
      } else {
        setValidateFutureObjs(true);
      }
    } else if (urlPermisos) {
      setValidateFutureObjs(false);
    } else {
      setValidateFutureObjs(true);
    }
  };

  useEffect(() => {
    validate();
  }, [data, desempenioValue, valuesFuturesG]);

  const handleChange = (
    index: number,
    field: keyof TableData,
    value: any,
    modalEdit?: boolean
  ) => {
    const newData = [...data];

    if (field !== "descripcion") {
      newData[index][field] = value;
      setData(newData);
      setIndexObjetive(index);
    }

    if (newData[index].semestre === undefined) {
      newData[index].semestre = value.semester;
    }

    //! ------------- LÓGICA DE DESAPARECER DATOS YA SELECCIONADOS -------------

    const datosss = optionsUsed.filter(
      (item) => item.value !== newData[index].descripcion.value
    );

    //** Estos son los datos que ya hemos seleccionado
    const newStates = optionsUsed.filter((elemento1) => {
      return data.some((elemento2: any) => {
        return elemento2.descripcion.value === elemento1.value;
      });
    });

    const array1Distinct = datosss.filter((elemento) => {
      return !newStates.some((elem) => elem.value === elemento.value);
    });

    setOptionsData(array1Distinct);

    //! -------------  LÓGICA DE DESAPARECER DATOS YA SELECCIONADOS  -------------

    if (field === "descripcion") {
      newData[index][field] = value;
      setData(newData);
      setIndexObjetive(index);

      setModalInfo(value);

      if (!modalEdit) {
        setModalEditObjetive(true);
      }

      if (
        value.label === "Selecciona una opcion" ||
        value.label.trim("") === ""
      ) {
        newData[index].meta = 0;
        newData[index].valor = 0;
        setData(newData);
        setLoadInput(false);
        setTimeout(() => {
          setLoadInput(true);
        }, 1000);
      }
    }
  };

  useEffect(() => {
    const query = "?PageSize=10000&PageNumber=1";
    handleGetAllIndividualObjetives(query);
  }, []);

  //?? OPTIONS SELECT
  useEffect(() => {
    if (individualObjetives && futureObjetives) {
      const newData = individualObjetives.map((item: any, index: number) => {
        return {
          value: item?.generalObjetivesId ?? "",
          label: item?.objetiveName ?? "",
          semester: futureObjetives[0]?.semestre,
        };
      });

      setOptionsData(newData);
      setOptionsUsed(newData);
    }
  }, [individualObjetives, futureObjetives, DatosAMapear]);
  //?? OPTIONS SELECT

  useEffect(() => {
    if (futureObjetives) {
      if (futureObjetives.length >= 3) {
        const dato = futureObjetives[futureObjetives.length - 1].valor;

        setDesempenioValue(dato);
      }
    }
  }, [evaluation]);

  useEffect(() => {
    const getData = async () => {
      try {
        // Realiza la llamada a la función getObjectivesPreviewListFuture
        incrementRequests();
        const response = await getObjectivesPreviewListFuture(
          periodAutoEvaluation,
          DatosAMapear?.employeeEvaluationEvaluatedUserId,
          DatosAMapear?.employeeEvaluationId
        );

        // Verifica si la respuesta contiene data
        if (!response || !response.data) {
          console.error("Error: la respuesta no contiene data");
          return;
        }

        const { data: listFuture } = response;
        setFutureObjetives(listFuture);
        setPeriodChangeFutures(listFuture);

      } catch (error) {
        console.error("Error al obtener los datos:", error);
      } finally {
        decrementRequests();
      }
    };
    getData();
  }, [changePeriodEvaluation, realizedSearch, DatosAMapear]);

  //! Es necesario forzar un reload del componente
  //! porque al reutilizar el componente no limpia
  //! la vieja data que este poseía
  const [loadInput, setLoadInput] = useState(false);
  useEffect(() => {
    setLoadSelects(false);
    setTimeout(() => {
      setLoadSelects(true);
    }, 2000);
  }, [futureObjetives, changePeriodEvaluation]);

  useEffect(() => {
    setLoadSelects(false);
    setTimeout(() => {
      setLoadSelects(true);
    }, 2000);
  }, [reload]);


  return (
    <>
      {loadSelects && (
        <>
          {changePeriodEvaluation && (
            <WrapperGoalsTable>
              <GoalsHeadTable>
                <div className="first">Semestre</div>
                <div className="second">Valor</div>
                <div className="third">Descripción</div>
                <div className="fourth">Meta</div>
              </GoalsHeadTable>
              <ColumnsDataFutureG
                dataExtra={futureObjetives}
                valuesFuturesG={valuesFuturesG}
                setValuesFuturesG={setValuesFuturesG}
              />
              <>
                <ColumnsDataFutureSelect
                  loadInput={loadInput}
                  setLoadInput={setLoadInput}
                  boolData={copyData}
                  data={data}
                  handleChange={handleChange}
                  optionsData={optionsData}
                  semestre={futureObjetives[0]?.semestre}
                />
              </>
              <ColumnsDataFutureDTwo dataExtra={dataConocimientos} />
              <ColumnsDataFutureD
                dataExtra={futureObjetives}
                setDesempenioValue={setDesempenioValue}
                desempenioValue={desempenioValue}
              />
              <FooterTable
                desempenioValue={Number(desempenioValue)}
                valuesFuturesG={valuesFuturesG?.map((item: any) =>
                  Number(item?.valor)
                )}
                data={data.map((item: any) => Number(item.valor))}
                dataExtra={futureObjetives}
              />
            </WrapperGoalsTable>
          )}
        </>
      )}
      {loadSelects && (
        <>
          {!changePeriodEvaluation && (
            <WrapperGoalsTable>
              <GoalsHeadTable>
                <div className="first">Semestre</div>
                <div className="second">Valor</div>
                <div className="third">Descripción</div>
                <div className="fourth">Meta</div>
              </GoalsHeadTable>

              <ColumnsDataFutureChangedPeriod dataExtra={periodChangeFutures} />

              <FooterTableChangedFuture dataExtra={periodChangeFutures} />
            </WrapperGoalsTable>
          )}
        </>
      )}
      <>
        {modalEditObjetive && (
          <ModalEditObjective
            handleChange={handleChange}
            values={modalInfo}
            show={modalEditObjetive}
            setValue={setModalInfo}
            onHide={setModalEditObjetive}
            setData={setData}
            data={data}
            indexObjetive={indexObjetive}
          />
        )}

        {succesPost && (
          <ModalStatusSuccessful
            handleClick={setSuccesPost}
            modalStatus={succesPost}
            messageShow
            message="Evaluacion guardada con exito !"
          />
        )}
        {errorPost && (
          <ModalStatusTargetError
            handleClick={setErrorPost}
            modalStatus={errorPost}
            messageShow
            message="Debes seleccionar almenos un objetivo individual !"
          />
        )}
      </>
    </>
  );
};

export default TableGoalValue;

// !disableActionEvaluationBtn && DatosAMapear.catStatusEvaluationId !== 7 && evaluationAttribute.edit_evaluation_complete !== 1
