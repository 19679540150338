import styled from "styled-components";

type PropTypeBg = {
  display: boolean;
  activeAlert?: boolean;
};
type PropTypeBgDos = {
  disabledP: boolean;
};

type PropTypeBgTres = {
  widthSelect: any;
};

export const ModalWrapper = styled.div.attrs(
  (props: PropTypeBg) => ({})
)<PropTypeBg>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.display ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-family: "Plus Jakarta Sans";

  .modalWrapper {
    width: 80%;
    max-width: 1100px;
    height: ${(props) => (props.activeAlert ? "440px" : "354px")};
    background-color: #ffffff;
    position: absolute;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1067px) {
      height: 50%;
    }
  }

  .active-alert-modal {
    border: 1px solid rgba(255, 0, 0, 0.5);
    border-radius: 8px;
    height: 50px;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 30px;
    align-self: flex-start;
    margin: 20px auto 0px auto;
    font-size: 14px;
    background-color: rgba(255, 0, 0, 0.15);
  }

  .close {
    position: absolute;
    top: 0;
    padding: 20px 0px 16px 0px;
    width: 96%;
    border-bottom: 2px solid #e5e5e5;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-weight: 700;
    }
    .closeModal {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
`;

export const ModalWrapperDos = styled.div.attrs(
  (props: PropTypeBg) => ({})
)<PropTypeBg>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.display ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-family: "Plus Jakarta Sans";

  .modalWrapper {
    width: 80%;
    max-width: 1100px;
     height: ${(props) => (props.activeAlert ? "370px" : "300px")};
    background-color: #ffffff;
    position: absolute;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1067px) {
      height: 50%;
    }
  }
     .active-alert-modal {
    border: 1px solid rgba(255, 0, 0, 0.5);
    border-radius: 8px;
    height: 50px;
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 30px;
    align-self: flex-start;
    margin: 20px auto 0px auto;
    font-size: 14px;
    background-color: rgba(255, 0, 0, 0.15);
  }
  .close {
    position: absolute;
    top: 0;
    padding: 20px 0px 16px 0px;
    width: 96%;
    border-bottom: 2px solid #e5e5e5;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-weight: 700;
    }
    .closeModal {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
`;

export const ModalSelectWrapper = styled.div.attrs(
  (props: PropTypeBg) => ({})
)<PropTypeBg>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.display ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 999999;
  font-family: "Plus Jakarta Sans";

  .modalWrapper {
    width: 90%;
    max-width: 880px;
    background-color: #ffffff;
    position: absolute;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 360px;
    /* border: 1px solid red; */
    /* @media (min-width: 1067px) {
border: 1px solid red;
  } */
  }
  .close {
    padding: 20px 0px 16px 0px;
    width: 96%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #939396;
    margin-bottom: 30px;

    .title {
      font-size: 18px;
      font-weight: 700;
      color: #1f2a40;
      display: flex;
      justify-content: start;
      width: 100%;

      gap: 33px;
      .image {
        height: 48.38px;
        width: 38.64px;
      }
    }
    .closeModal {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 13px;
    }
  }
`;

export const ModalAlertWrapper = styled.div.attrs(
  (props: PropTypeBg) => ({})
)<PropTypeBg>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.display ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-family: "Plus Jakarta Sans";

  .modalWrapper {
    width: 100%;
    max-width: 641px;
    //height: 386px;
    background-color: #ffffff;
    position: absolute;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    gap: 32px;
    @media (max-width: 1067px) {
      //height: 50%;
    }
  }
  .close {
    padding: 20px 0px 16px 0px;
    width: 96%;
    display: flex;
    justify-content: space-between;
    border: none;

    .title {
      font-size: 20px;
      font-weight: 700;
      color: #3b63a2;

      display: flex;
      // justify-content: center;
      //align-items:center;
      //padding: 0 21px;
      /* border: 1px solid red; */
      /* justify-content: center; */
      width: 100%;
      gap: 10px;
      .image {
        width: 20px;
      }
    }
    .closeModal {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 5px;
    }
  }
`;

export const FormWrapper = styled.form`
  width: 96%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  height: 200px;
  margin-top: 20px;

  @media (max-width: 1067px) {
    height: auto;
    padding-bottom: 10px;
  }
  .rowFirst {
    display: flex;
    padding: 10px 10px 10px 10px;
    gap: 10px;

    @media (max-width: 1067px) {
      flex-direction: column;
    }
  }

  .rowSecond {
    display: flex;
    padding: 0px 10px;
    gap: 10px;
    height: 36px;
    justify-content: space-between;
    .selectWrapper {
      width: 96%;
      @media (max-width: 1067px) {
        width: 100%;
        height: 36px;
      }
    }
    @media (max-width: 1067px) {
      height: auto;
      flex-direction: column;
    }
  }

  .rowThird {
    display: flex;
    padding: 10px 10px;
    gap: 10px;
    justify-content: space-between;
    @media (max-width: 1067px) {
      flex-direction: column;
    }
  }

  .rowFourth {
    display: flex;
    padding: 0px 10px;
    gap: 10px;
    justify-content: space-between;
    @media (max-width: 1067px) {
      flex-direction: column;
    }
  }
`;

export const TitleInput = styled.div`
  /* height: 36px; */
  background-color: #f0f0f0;
  display: flex;
  font-size: 14px;
  padding: 0px 6px;
  align-items: center;
  justify-content: center;
  width: 48%;
`;
export const TitleInputDos = styled.div`
  /* height: 36px; */
  background-color: #f0f0f0;
  display: flex;
  font-size: 14px;
  /* padding: 0px 0px; */
  align-items: center;
  justify-content: center;
  width: 56%;
`;

export const InputTFieldDos = styled.input`
  width: 44%;
  outline: none;
  border: 1px solid #f0f0f0;
  border-left: none;
  font-size: 14px;
  padding: 0px 0px 0px 6px;
  border-radius: 0px 6px 6px 0px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
export const TitleInputSelect = styled.div`
  height: 36px;
  background-color: #f0f0f0;
  display: flex;
  font-size: 14px;
  /* padding: 0px 12px; */
  align-items: center;
  justify-content: center;
  width: 56%;
  @media (max-width: 1067px) {
    width: 34%;
    /* flex-direction: column; */
  }
`;

export const TitleInputSelectNew = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  height: 36px;
  background-color: #f0f0f0;
  display: flex;
  font-size: 14px;
  padding: 0px 12px;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.widthSelect || "56%"};
`;
export const TitleInputSelectDos = styled.div`
  height: 36px;
  background-color: #f0f0f0;
  display: flex;
  font-size: 14px;
  align-items: center;
  /* justify-content: center; */
  width: 45%;
  margin: none !important;
  @media (max-width: 1067px) {
    width: 34%;
    /* flex-direction: column; */
  }
`;

export const InputSelect = styled.select`
  width: 44%;
  border: 1px solid #f0f0f0;
  border-left: none;
  outline: none;
  font-size: 14px;
  height: 36px;
  border-radius: 0px 6px 6px 0px;
  @media (max-width: 1067px) {
    width: 66%;
  }
`;

export const InputGroupW = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const InputSelectDos = styled.select`
  width: 56%;
  border: 1px solid #f0f0f0;
  border-left: none;
  font-size: 14px;
  height: 36px;
  border-radius: 0px 6px 6px 0px;
  margin: none !important;
  /* margin-right: 4px; */
  @media (max-width: 1067px) {
    width: 66%;
  }
`;

export const InputSelectDosNew = styled.select.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  width: ${(props) => props.widthSelect || "56%"};
  border: 1px solid #f0f0f0;
  border-left: none;
  font-size: 14px;
  height: 36px;
  border-radius: 0px 6px 6px 0px;
  margin: none !important;
  width: ${(props) => props.widthSelect || "56%"};
`;
export const InputTField = styled.input`
  width: 73%;
  border: 1px solid #f0f0f0;
  border-left: none;
  font-size: 14px;
  padding: 0px 12px;
  border-radius: 0px 6px 6px 0px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 36px;

  .classDateName {
    outline: none;
    width: 55%;
    border: 1px solid #f0f0f0;
    border-left: none;
    font-size: 14px;
    padding: 0px 12px;
    border-radius: 0px 6px 6px 0px;
  }
`;

export const InputTDate = styled.input`
  width: 55%;
  border: 1px solid #f0f0f0;
  border-left: none;
  font-size: 14px;
  padding: 0px 12px;
  border-radius: 0px 6px 6px 0px;
`;

export const BuildObjetive = styled.div`
  width: 100%;
  display: flex;
  height: 36px;
  font-size: 14px;
  border: 1px solid #f0f0f0;
  border-radius: 0px 6px 6px 0px;

  .title {
    width: 10%;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .orationBuilded {
    width: 90%;
    display: flex;
    justify-content: start;
    padding-left: 10px;
    align-items: center;
  }
`;

export const ContainerModal = styled.form`
  display: flex;
  padding-top: 15px;
  gap: 8px;
  /* border : 1px solid #F0F0F0; */
`;

export const FilterBoxNew = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  align-content: center;
  padding: 16px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;


export const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  width: 95%;
  padding: 14px 0px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  /* border: 1px solid red; */

  .cancelBtn {
    border-radius: 4px;
    width: 174px;
    height: 34px;
    background-color: #3b63a2;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: 500;
    border: none;
  }

  .saveBtn {
    border-radius: 4px;
    width: 174px;
    height: 34px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: #3b63a2;
    font-weight: 500;
    border: none;
  }
`;

export const SaveBtn = styled.div.attrs(
  (props: PropTypeBgDos) => ({})
)<PropTypeBgDos>`
  border-radius: 4px;
  width: 174px;
  height: 34px;
  background-color: #3b63a2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  border: none;
  background-color: ${(props) => (props.disabledP ? "#c9f2ff" : "#C1C1C3")};
  cursor: pointer;
`;

export const SaveBtnModal = styled.div.attrs(
  (props) => ({}))<PropTypeBgDos>`
  border-radius: 4px;
  width: 174px;
  height: 34px;
  background-color: #3b63a2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.disabledP ? "#3b63a2" : "#fff")};
  font-weight: 500;
  border: none;
  background-color: ${(props) => (props.disabledP ? "#c9f2ff" : "#C1C1C3")};
  cursor: pointer;
   .sc-fopFza.cHzlcg.saveBtn {
    color: ${(props) => (props.disabledP ? "#3b63a2" : "red")};
}
`;

export const SaveBtnDos = styled.button.attrs(
  (props: PropTypeBgDos) => ({})
)<PropTypeBgDos>`
  border-radius: 4px;
  width: 174px;
  height: 34px;
  background-color: #3b63a2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  border: none;
  background-color: ${(props) => (props.disabledP ? "#E4F9FF" : "#C9F2FF")};
  color: ${(props) => (props.disabledP ? "#B3C7DF" : "#3B63A2")};
  /* cursor: pointer */
`;
