/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
import { ChangeEventHandler, FC } from "react";
import styled from "styled-components";
interface Options {
  value: string | undefined;
  text: string;
}
interface Values {
  [prop: string | number]: string | number;
}
interface PropsInputs {
  onChange?: ChangeEventHandler;
  title: string;
  options?: Array<Options>;
  placeholder?: string;
  id: string;
  name: string;
  value: any;
  onKeyDownCapture?: any;
  maxLength?: number;
  type?: string;
  disabled?: boolean;
}

// PORFAVOR, TRABAJAR FUNCIONALIDAD FUERA DE ESTE COMPONENTE../../pages/Admin/Objectives/ObjetivesStyles.
export const Date: FC<PropsInputs> = ({
  title,
  onChange,
  placeholder,
  id,
  name,
  value,
  disabled,
}) => {
  // 

  return (
    <InputWrapperDashboard>
      <LabelDashboard>
        {title}
      </LabelDashboard>
      <InputDashboard
        type="date"
        placeholder={placeholder}
        aria-label="Corporativo"
        aria-describedby={id}
        onChange={onChange}
        className="gapEelements"
        name={name}
        value={value[name]}
        disabled={disabled}
      />
    </InputWrapperDashboard>
  );
};

export const TextField: FC<PropsInputs> = ({
  title,
  onChange,
  placeholder,
  id,
  name,
  value,
  onKeyDownCapture,
  maxLength,
  type,
  disabled,
}) => {
  // 

  return (
    <InputWrapperDashboard>
      <LabelDashboard>
        {title}
      </LabelDashboard>
      <InputDashboard
        disabled={disabled}
        maxLength={maxLength}
        type={type}
        placeholder={placeholder}
        aria-label="Username"
        name={name}
        onKeyDownCapture={onKeyDownCapture}
        aria-describedby={id}
        onChange={onChange}
        className="gapEelements"
        value={value[name]}
     
      />
    </InputWrapperDashboard>
  );
};

export const TextFieldLong: FC<PropsInputs> = ({
  title,
  onChange,
  placeholder,
  id,
  name,
  value,
  onKeyDownCapture,
  maxLength,
  disabled,
}) => {
  // 

  return (
    <InputWrapperDashboard>
      <LabelDashboard>
        {title}
      </LabelDashboard>
      <InputDashboard
        maxLength={maxLength}
        placeholder={placeholder}
        aria-label="Username"
        name={name}
        onKeyDownCapture={onKeyDownCapture}
        aria-describedby={id}
        onChange={onChange}
        className="gapEelements"
        value={value[name]}
        disabled={disabled}
      />
    </InputWrapperDashboard>
  );
};

export const Select: FC<PropsInputs> = ({
  title,
  onChange,
  options,
  placeholder,
  id,
  name,
  value,
  disabled,
}) => {
  return (
    <InputWrapperDashboard>
      <LabelDashboard>
        {title}
      </LabelDashboard>
      <SelectDashboard
        placeholder={placeholder}
        aria-label="All"
        aria-describedby={id}
        className="gapEelements"
        name={name}
        onChange={onChange}
        value={value[name]}
        defaultValue={value[name]}
        disabled={disabled}
      >
        {options?.map(({ text, value }) => (
          <option value={value}>{text}</option>
        ))}
      </SelectDashboard>
    </InputWrapperDashboard>
  );
};
export const SelectDos: FC<PropsInputs> = ({
  title,
  onChange,
  options,
  placeholder,
  id,
  name,
  value,
}) => {
  return (
    <InputWrapperDashboard>
      <LabelDashboard>{title}</LabelDashboard>
      <SelectDashboard
        placeholder={placeholder}
        aria-label="All"
        aria-describedby={id}
        className="gapEelements"
        name={name}
        onChange={onChange}
        value={value[name]}
        defaultValue={value[name]}
      >
        {options?.map(({ text, value }) => (
          <option value={value}>{text}</option>
        ))}
      </SelectDashboard>
    </InputWrapperDashboard>
  );
};



export const InputWrapperDashboard = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
`;


export const LabelDashboard = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--background-light-gray, #f8f8f8);
  background: var(--neutrals-white, #fff);
  color: var(--neutrals-jet, #333335);
  font-feature-settings: "clig" off, "liga" off;
  /* Small Body */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 35%;
  max-width: 170px;
`;


export const InputDashboard = styled.input`
  width: 100%;
  line-height: 100%;
  color: black;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  border-radius: 0px 8px 8px 0px;
  padding: 5px;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  height: 35px;
`;


export const SelectDashboard = styled.select`
  width: 100%;
  line-height: 100%;
  color: black;
  font-family: "Plus Jakarta Sans";


  padding: 5px;
  border: none;
  outline: none;
  background-color: #f0f0f0;
  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;
  background-color: #f0f0f0;
  background-position: calc(100% - 0.6rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 100%;
  height: 35px;
  font-size: 14px;
  border-radius: 0px 8px 8px 0px;
`;


export const selectExpresionMath = styled.label`

display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--background-light-gray, #f8f8f8);
  background: var(--neutrals-white, #fff);
  color: var(--neutrals-jet, #333335);
  font-feature-settings: "clig" off, "liga" off;
  /* Small Body */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 35%;
`; 