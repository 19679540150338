import { useEffect, useState } from "react";
import { ICurrentsObjetives } from "../../../Interface/ApiInterface";
import { evaluationNullPolitics } from "../../../common/utils";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import { AlertContainerKpis, TableSumDosKpis } from "../Cards/CardEvaluatorStyles";
import { suma } from "./utils";

const FooterTableKpis = ({ politicsValidation, evaluacion, objetivesCurrents, sumTotal }: any) => {

    const [sumatoria, setSumTotal] = useState<number>(0)


    useEffect(() => {

        setSumTotal(() => {
            const result = (sumTotal && Array.isArray(sumTotal) && sumTotal.length > 0)
                ? Math.round(sumTotal.reduce((acc: number, item: any) => {
                    const valor = parseFloat(item.valor);
                    return acc + (isNaN(valor) ? 0 : valor);
                }, 0))
                : 0;

            return result
        })

    }, [sumTotal])

    return (
        <TableSumDosKpis showAlert={politicsValidation === evaluationNullPolitics}>
            <div className="first"></div>
            <div
                className="second"
                style={{
                    color:
                        suma(
                            evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                                (item: ICurrentsObjetives) =>
                                    +item.employeeEvaluationObjetivesWorth
                            )
                        ) !== 100
                            ? "red"
                            : "black",
                }}
            >
                {suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) =>
                            +item.employeeEvaluationObjetivesWorth
                    )
                )}
                %
            </div>
            <div
                className="third"
                style={{
                    color:
                        suma(
                            evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                                (item: ICurrentsObjetives) =>
                                    +item.employeeEvaluationObjetivesWorth
                            )
                        ) !== 100
                            ? "red"
                            : "black",
                }}
            >
                {suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) =>
                            +item.employeeEvaluationObjetivesWorth
                    )
                ) !== 100
                    ? `No suma 100%`
                    : `Suma`}
            </div>

            <AlertContainerKpis showAlert={politicsValidation === evaluationNullPolitics} >
                Alerta evaluación aún no tiene calificación de políticas
            </AlertContainerKpis>
            <div className="seventh">

                {politicsValidation !== evaluationNullPolitics ?
                    //  Math.round(suma(
                    //     evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                    //         (item: ICurrentsObjetives) => +item.employeeEvaluationObjetivesResult
                    //     ) || []
                    // )) 
                    sumatoria
                    : 0

                }
            </div>
        </TableSumDosKpis>);
};

export default FooterTableKpis;



export const FooterTableKpisChangePeriod = ({ evaluacion }: any) => {
    return (
        <TableSumDosKpis>
            <div className="first"></div>
            <div
                className="second"
                style={{
                    color:
                        suma(
                            evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                                (item: ICurrentsObjetives) =>
                                    +item.employeeEvaluationObjetivesWorth
                            )
                        ) !== 100
                            ? "red"
                            : "black",
                }}
            >
                {suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) =>
                            +item.employeeEvaluationObjetivesWorth
                    )
                )}
                %
            </div>
            <div
                className="third"
                style={{
                    color:
                        suma(
                            evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                                (item: ICurrentsObjetives) =>
                                    +item.employeeEvaluationObjetivesWorth
                            )
                        ) !== 100
                            ? "red"
                            : "black",
                }}
            >
                {suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) =>
                            +item.employeeEvaluationObjetivesWorth
                    )
                ) !== 100
                    ? `No suma 100%`
                    : `Suma`}
            </div>

            <AlertContainerKpis  >
                Alerta evaluación aún no tiene calificación de políticas
            </AlertContainerKpis>
            <div className="seventh">

                {Math.round(suma(
                    evaluacion?.employeeEvaluationObjetivesCurrents?.map(
                        (item: ICurrentsObjetives) => +item.employeeEvaluationObjetivesResult
                    ) || []
                ))

                }
            </div>
        </TableSumDosKpis>);
};

