/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
import { Navigate } from 'react-router-dom';
import { useCollaboratorRedux } from '../redux/Collaborators';

export function ProtectedRoute({ children }) {
  const { User } = useCollaboratorRedux();
  if (User.claTrab === undefined) {
    // user is not authenticated
    return <Navigate to="/Default.aspx" />;
  }
  return children;
}

export function NotEvaluatedRequireAuth({ children }) {
  const { User } = useCollaboratorRedux();
  if (User.role !== 'Evaluado') {
    return children;
  }
  return <Navigate to="/Default.aspx" />;
}


export function NotRequireAuth({ children }) {
  const { User } = useCollaboratorRedux();
  if (User.claTrab === undefined) {
    return children;
  }
  switch(User.role){
    case 'Evaluado':
      return <Navigate to="/Evaluacion" />;
    case 'Evaluador':
      return <Navigate to="/Dashboard" />;
    case 'Director':
      return <Navigate to="/Dashboard" />;
    case 'Admin':
      return <Navigate to="/Dashboard" />;
    default:
      return <Navigate to="/Dashboard" />;
  }
}

