import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useContext } from "react";
import { IMeta } from "../../Interface/ApiInterface";
import { EvaluationContext, EvaluationContextType } from "../../context/EvaluationContext";
import { validateIdPeriod } from "../../context/validacionesFiltros";
import { ContainPagination } from "../../pages/Admin/Dashboard/stylesDashboard/AdminStyles";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useObjetives } from "../../redux/Objetives";
import { usePeriods } from "../../redux/Periods";
import { usePolitics } from "../../redux/Politics";
import { useQuestions } from "../../redux/Questionnaires";
// import { styled } from '@mui/material/styles';

interface IPagination {
  table?: string;
  Meta?: IMeta;
  evaluation_period_id?: any;
  setCurrentPage?: any;
  setQueryPeticionWithFilters?: any;
  setQueryFilter?: any;
}
// const useStyles = makeStyles((theme: any) => ({
//   customPagination: {
//     '& .MuiPaginationItem-root': {
//       color: 'red', // Cambia el color de las flechas según tus preferencias
//     },
//   },
// }));
const PaginationTable = ({
  table,
  Meta,
  evaluation_period_id,
  setCurrentPage,
  setQueryPeticionWithFilters,
  setQueryFilter
}: IPagination) => {
  const { Filters, User, handleGetCollaborators } = useCollaboratorRedux();
  const {
    FilterGeneral,
    FilterIndividual,
    handleGetAllGeneralObjetives,
    handleGetAllIndividualObjetives,
  } = useObjetives();
  const { handleGetAllPeriods } = usePeriods();
  const { handleGetAllQuestions } = useQuestions();
  const { handleGetAllPoliticsFilter, FilterPolitic } = usePolitics();
  const { setLoadingPagination } = useContext(EvaluationContext) as EvaluationContextType;


  // console.log("Se cambian los filtros -> ", Filters);

  const handleChangePaginate = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    event.preventDefault();
    let query = `?`;
    let queryObjetivosIndividuales = `?`;
    let query2 = `?`;

    switch (table) {
      case "Collaboratos":
        if (Filters) {
          const queryParts: string[] = [];

          for (const key in Filters) {
            if (Filters[key] !== undefined && Filters[key] !== "") {
              if (key === "Mas95Porcent" || key === "Menos50Porcent" || key === "TienePoliticPorcent" || key === "SinEvaluacionPorcent") {
                queryParts.push(`GraphicFilter.${key}=${true}`);
              } else {
                queryParts.push(`${key}=${Filters[key]}`);
              }
            }
          }

          let queryFilters = queryParts.join('&');

          // console.log("Esto se enviará ->", queryFilters);
          const periodo = validateIdPeriod(evaluation_period_id);

          query += `${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=${page}`;

          // console.log("query petición", `${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=1`);

          setQueryPeticionWithFilters(`${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=1`);

          handleGetCollaborators(query);
          setLoadingPagination(true)

        }

        break;
      case "Periods":
        query += `PageSize=10&PageNumber=${page}`;
        handleGetAllPeriods(query);
        break;
      case "Cuestionario":
        query += `PageSize=10&PageNumber=${page}`;
        handleGetAllQuestions(query);
        break;
      case "Generales":
        const periodoG = validateIdPeriod(evaluation_period_id)
        if (FilterGeneral.verbFilterGeneral !== undefined) {
          query += `&GeneralObjetivesInfinitiveVerb=${FilterGeneral.verbFilterGeneral}`;
        }
        if (FilterGeneral.KPIFilterGeneral !== undefined) {
          query += `&GeneralObjetivesKPI=${FilterGeneral.KPIFilterGeneral}`;
        }
        if (FilterGeneral.mathFilterGeneral !== undefined) {
          query += `&GeneralObjetviesMathExpression=${FilterGeneral.mathFilterGeneral}`;
        }
        if (FilterGeneral.prepositionFilterGeneral !== undefined) {
          query += `&GeneralObjetivesPreposition=${FilterGeneral.prepositionFilterGeneral}`;
        }
        if (FilterGeneral.indicatorFilterGeneral !== undefined) {
          query += `&GeneralObjetivesQuantityIndicator=${FilterGeneral.indicatorFilterGeneral}`;
        }
        if (FilterGeneral.adverbFilterGeneral !== undefined) {
          query += `&GeneralObjetivesAdverb=${FilterGeneral.adverbFilterGeneral}`;
        }
        if (FilterGeneral.dateFilterGeneral !== undefined) {
          query += `&GeneralObjetivesDate=${FilterGeneral.dateFilterGeneral}`;
        }
        if (FilterGeneral.yearFilterGeneral !== undefined) {
          query += `&GeneralObjetivesYear=${FilterGeneral.yearFilterGeneral}`;
        }
        if (FilterGeneral.semesterFilterGeneral !== undefined) {
          query += `&GeneralObjetivesSemester=${FilterGeneral.semesterFilterGeneral}`;
        }
        if (FilterGeneral.locationFilterGeneral !== undefined) {
          query += `&Locations=${FilterGeneral.locationFilterGeneral}`;
        }
        if (FilterGeneral.departmentFilterGeneral !== undefined) {
          query += `&Department=${FilterGeneral.departmentFilterGeneral}`;
        }
        if (FilterGeneral.areaFilterGeneral !== undefined) {
          query += `&Area=${FilterGeneral.areaFilterGeneral}`;
        }
        if (FilterGeneral.companyFilterGeneral !== undefined) {
          query += `&Company=${FilterGeneral.companyFilterGeneral}`;
        }
        // if (evaluation_period_id !== undefined) {
        query += `&IdPeriod=${periodoG}&IdEvaluator=${User.claTrab}&PageSize=10&PageNumber=${page}`;
        // }
        handleGetAllGeneralObjetives(query);
        break;
      case "Individuales":
        const periodoI = validateIdPeriod(evaluation_period_id)
        if (FilterIndividual.verbFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesInfinitiveVerb=${FilterIndividual.verbFilterIndividual}`;
        }
        if (FilterIndividual.KPIFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesKPI=${FilterIndividual.KPIFilterIndividual}`;
        }
        if (FilterIndividual.mathFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetviesMathExpression=${FilterIndividual.mathFilterIndividual}`;
        }
        if (FilterIndividual.prepositionFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesPreposition=${FilterIndividual.prepositionFilterIndividual}`;
        }
        if (FilterIndividual.indicatorFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesQuantityIndicator=${FilterIndividual.indicatorFilterIndividual}`;
        }
        if (FilterIndividual.adverbFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesAdverb=${FilterIndividual.adverbFilterIndividual}`;
        }
        if (FilterIndividual.dateFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesDate=${FilterIndividual.dateFilterIndividual}`;
        }
        if (FilterIndividual.yearFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesYear=${FilterIndividual.yearFilterIndividual}`;
        }
        if (FilterIndividual.semesterFilterIndividual !== undefined) {
          queryObjetivosIndividuales += `&GeneralObjetivesSemester=${FilterIndividual.semesterFilterIndividual}`;
        }
        // if (evaluation_period_id !== undefined) {
        queryObjetivosIndividuales += `&IdPeriod=${periodoI}&IdEvaluator=${User.claTrab}&PageSize=10&PageNumber=${page}`;
        // }
        handleGetAllIndividualObjetives(queryObjetivosIndividuales);
        break;

      case "Politicas":

        const periodoP = validateIdPeriod(evaluation_period_id)
        if (FilterPolitic.nombre !== undefined && FilterPolitic.nombre) {
          if (query2 !== "") {
            query2 += `Nombre=${FilterPolitic.nombre}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Nombre=${FilterPolitic.nombre}`;
            setQueryFilter(query2);
          }
        }
        if (FilterPolitic.puesto !== undefined && FilterPolitic.puesto) {
          if (query2 !== "") {
            query2 += `Puesto=${FilterPolitic.puesto}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Puesto=${FilterPolitic.puesto}`;
            setQueryFilter(query2);
          }
        }
        if (FilterPolitic.area !== undefined && FilterPolitic.area) {
          if (query2 !== "") {
            query2 += `Area=${FilterPolitic.area}&`;
            setQueryFilter(query2);
          } else {
            query2 += `&Area=${FilterPolitic.area}`;
            setQueryFilter(query2);
          }
        }
        if (
          FilterPolitic.departamento !== undefined &&
          FilterPolitic.departamento
        ) {
          if (query2 !== "") {
            query2 += `Departamento=${FilterPolitic.departamento.trim()}&`;
             setQueryFilter(query2);
          } else {
            query2 += `&Departamento=${FilterPolitic.departamento.trim()}`;
             setQueryFilter(query2);
          }
        }
        if (FilterPolitic.fechaNac !== undefined && FilterPolitic.fechaNac) {
          if (query2 !== "") {
            query2 += `FechaNac=${FilterPolitic.fechaNac}&`;
             setQueryFilter(query2);
          } else {
            query2 += `&FechaNac=${FilterPolitic.fechaNac}`;
             setQueryFilter(query2);
          }
        }
        if (FilterPolitic.compania !== undefined && FilterPolitic.compania) {
          if (query2 !== "") {
            query2 += `Compania=${FilterPolitic.compania}&`;
             setQueryFilter(query2);
          } else {
            query2 += `&Compania=${FilterPolitic.compania}`;
             setQueryFilter(query2);
          }
        }
        if (
          FilterPolitic.clasificacion !== undefined &&
          FilterPolitic.clasificacion
        ) {
          if (query2 !== "") {
            query2 += `Clasificacion=${FilterPolitic.clasificacion}&`;
             setQueryFilter(query2);
          } else {
            query2 += `&Clasificacion=${FilterPolitic.clasificacion}`;
             setQueryFilter(query2);
          }
        }
        if (
          FilterPolitic.estatusPolicies !== undefined &&
          FilterPolitic.estatusPolicies
        ) {
          if (query2 !== "") {
            query2 += `EstatusPolicies=${FilterPolitic.estatusPolicies}&`;
             setQueryFilter(query2);
          } else {
            query2 += `&EstatusPolicies=${FilterPolitic.estatusPolicies}`;
             setQueryFilter(query2);
          }
        }

        query2 += `IdPeriod=${periodoP}&PageSize=10&PageNumber=${page}`;
        setQueryFilter(query2);
        handleGetAllPoliticsFilter(query2);
        break;
      default:
        return;
    }
  };

  return (
    <ContainPagination>

      <Stack spacing={2}>
        <Pagination
          onChange={(e, page) => {
            handleChangePaginate(e, page);
            if (table === "Collaboratos") {
              setLoadingPagination(true)
            }
          }}
          count={Meta?.totalPages}
          color="primary"
          page={Meta?.currentPage ?? 1}
        />
      </Stack>
    </ContainPagination>
  );
};

export default PaginationTable;



