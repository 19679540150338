import React, { useEffect, useState } from "react";
//styles
import {
  ButtonModal,
  ContainerButton,
  EstatusText,
  MainContainer,
  ModalBackground,
  SelectContainer,
  SelectModal,
  SelectsMainContainer,
  StepModal,
  Subtitle,
  TagContainer,
  TagsContainer,
  Title,
  TitleContainer,
} from "./ModalCambioStatusMasivo.styles";
//assets
import close from "../../../../../assets/closeButton.svg";
import CloseTag from "../../../../../assets/CloseTag.svg";
import info from "../../../../../assets/info.svg";
import { getStatusList } from "../../../../../services/massiveChange.service";
interface ModalCambioStatusMasivoProps {
  setShowModalCambioStatus: (show: boolean) => void;
  showModalCambioStatus: boolean;
  showModalEstatusActualizado: boolean;
  setShowModalEstatusActualizado: (show: boolean) => void;
  locations: string[];
  idPeriod: any;
  setSelectedStatus: any;
  setCatStatusNuevo: any;
  setSelectedLocation: any;
  setCatStatusActual: any;
  setUserId: any;
  selectedStatus: any;
  selectedLocation: any;
  userId: any;
  setShowModalCaution: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedStatusActual: React.Dispatch<React.SetStateAction<string>>;
  setListStringLocalidad: any;
}

interface Status {
  catStatusEvaluationId: number; // Ajusta según la estructura real de tus datos
  description: string;
  // Agrega otras propiedades según sea necesario
}

export default function ModalCambioStatusMasivo({

  locations,
  setShowModalCambioStatus,
  showModalCambioStatus,
  setSelectedStatus,
  setCatStatusNuevo,
  setSelectedLocation,
  setCatStatusActual,

  selectedStatus,
  selectedLocation,

  setShowModalCaution,
  setSelectedStatusActual,
  setListStringLocalidad,
}: ModalCambioStatusMasivoProps) {
  const [locationsArray, setLocationsArray] = useState<any>([]);
  const [statusList, setStatusList] = useState<Status[]>([]);

  const handleEstatusActualChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedStatusDescription = event.target.value;
    const selectedStatusObject = statusList.find(
      (status) => status.description === selectedStatusDescription
    );

    if (selectedStatusObject) {
      setSelectedStatus(selectedStatusDescription);
      setCatStatusActual(selectedStatusObject.catStatusEvaluationId);
    }
  };

  const handleEstatusNuevoChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedStatusDescription = event.target.value;
    const selectedStatusObject = statusList.find(
      (status) => status.description === selectedStatusDescription
    );

    if (selectedStatusObject) {
      setCatStatusNuevo(selectedStatusObject.catStatusEvaluationId);
      setSelectedStatusActual(selectedStatusDescription);
    }
  };

  const handleLocationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // console.log("Locations event", event.target.value);
    setSelectedLocation(event.target.value);
    setLocationsArray([...locationsArray, event.target.value]);
    setListStringLocalidad([...locationsArray, event.target.value].join(", "));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getStatusList();
        setStatusList(response.data);

        // Asegúrate de que haya al menos un elemento en statusList antes de intentar establecer el estado por defecto
        if (response.data.length > 0) {
          setSelectedStatus(response.data[0].description);
          setCatStatusActual(response.data[0].catStatusEvaluationId);
          setCatStatusNuevo(response.data[0].catStatusEvaluationId);
        }
      } catch (error) {
        // console.error('Error fetching status list:', error);
      }
    };

    fetchData();
  }, []);


  const handleCloseModal = () => {
    setListStringLocalidad("")
    setShowModalCambioStatus(!showModalCambioStatus);
  }
  const handleClickCambiar = () => {
    setShowModalCambioStatus(!showModalCambioStatus);
    setShowModalCaution(true);
  };

  // useEffect(() => {
  // console.log("Locations", locationsArray.join(", "), locationsArray.length);

  // }, [locationsArray]);

  const handleClose = (itemFiltered: any) => {
    const filteredLocations = locationsArray.filter(
      (item: any) => item !== itemFiltered
    );

    setSelectedLocation("");
    setLocationsArray(filteredLocations);

    setListStringLocalidad(filteredLocations.join(", "))
  };

  return (
    <ModalBackground>
      <MainContainer>
        <TitleContainer>
          <div className="containerTitleImg">
            <img src={info} alt="" />
            <Title>Cambio de estatus masivo</Title>
          </div>
          <img
            style={{ cursor: "pointer" }}
            src={close}
            alt=""
            onClick={handleCloseModal}
          />
        </TitleContainer>
        <Subtitle>
          Escoge el estatus actual y el nuevo estatus. Después, indica la
          localidad a la que se aplicará el cambio.
          <StepModal>Puedes seleccionar más de una localidad.</StepModal>
        </Subtitle>
        {/* <Subtitle>Selecciona el estatus que deseas cambiar</Subtitle> */}

        {/* <StepModal>Este paso es obligatorio.</StepModal> */}

        <SelectsMainContainer>
          <SelectContainer>
            <EstatusText>Estatus actual</EstatusText>
            <SelectModal
              onChange={handleEstatusActualChange}
              value={selectedStatus}
            >
              {statusList
                .filter((status) => status.description !== "Pendiente agendar")
                .map((status) => (
                  <option
                    key={status.catStatusEvaluationId}
                    value={status.description}
                  >
                    {status.description}
                  </option>
                ))}
            </SelectModal>
          </SelectContainer>
          <SelectContainer>
            <EstatusText>Estatus nuevo</EstatusText>
            <SelectModal onChange={handleEstatusNuevoChange}>
              {statusList
                .filter(
                  (status) =>
                    status.description !== "Pendiente agendar" &&
                    status.description !== selectedStatus
                )
                .map((status) => (
                  <option
                    key={status.catStatusEvaluationId}
                    value={status.description}
                  >
                    {status.description}
                  </option>
                ))}
            </SelectModal>
          </SelectContainer>
        </SelectsMainContainer>

        {/* <Subtitle>
          Selecciona a qué usuarios se les aplicará el cambio de estatus:{" "}
        </Subtitle>
        <StepModal>
          En caso de no seleccionar usuarios específicos, la modificación
          afectará a todos los usuarios que actualmente tengan el mismo estatus.
          Para ingresar múltiples IDs, asegúrate de separarlos con comas.{" "}
        </StepModal> */}

        <SelectsMainContainer style={{ marginTop: "16px" }}>
          {/* <SelectContainer>
            <EstatusText>ID</EstatusText>
            <InputModal
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </SelectContainer> */}
          <SelectContainer>
            <EstatusText>Localidad</EstatusText>
            <SelectModal
              onChange={handleLocationChange}
              value={selectedLocation}
            >
              <option value="">
                {locationsArray.length === 0
                  ? "Todos"
                  : "Selecciona una opción"}
              </option>
              {locations
                .filter((item) => !locationsArray.includes(item))
                .map((data: any) => {
                  return (
                    <option key={data} value={data}>
                      {data}
                    </option>
                  );
                })}
            </SelectModal>
          </SelectContainer>
        </SelectsMainContainer>
        <TagsContainer>
          {locationsArray.map((item: any) => (
            <TagContainer key={item} onClick={() => handleClose(item)}>
              {item}
              <div className="close">
                <img src={CloseTag} alt="" />
              </div>
            </TagContainer>
          ))}
        </TagsContainer>
        <ContainerButton style={{ marginTop: "36px" }}>
          <ButtonModal onClick={handleClickCambiar}>
            {/* Cambiar estatus */}
            Solicitar cambio de estatus
          </ButtonModal>
        </ContainerButton>
      </MainContainer>
    </ModalBackground>
  );
}
