import { FC } from "react";
import styled from "styled-components";
import gifImage from '../../../assets/colladoLoader.gif';
import './loaderModal.css';
interface Labels {
  showData?: boolean | true;
}

export const ModalLoader: FC<Labels> = ({ showData }) => {
  return (
    <ContainerModal >
      <ModalBox>
        <ImageIcon>
          <img src={gifImage} alt="imgIconLoader" />
          {/* <div className="loader"></div> */}

        </ImageIcon>
      </ModalBox>
    </ContainerModal>
  );
};

export default ModalLoader;

const ContainerModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
  display: flex;
`;

export const ImageIcon = styled.div`
 max-width: 200px;
  width: 100%;
  z-index: 2;
  /* border-radius: 19px; */
  justify-content: center;
  align-items: center;
  display: flex;
  /* background-color: white; */


  .imgIconLoader{
    height: 150px;
    width: 150px;
  }
`;

export const ModalBox = styled.div`
  /* background-color: #fff; */
  /* height: 233px; */
  max-width: 200px;
  width: 100%;
  z-index: 2;
  border-radius: 19px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* padding: 32px 30px; */

  /* @media (max-width: 768px) {
    width: 90%;
    height: auto; */
    /* height: 200px; */
  /* } */
`;
