import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionEvent } from "../common/utils";
import { RootReducerInterface } from "./store";
import { JobPerformanceInterface } from '../Interface/ApiInterface';
import { getJobPerformanceServices, postJobPerformanceServices, putJobPerformanceServices, deleteJobPerformanceServices } from "../services/jobPerformance.service";
/**
 * Action Types
 */
export enum JobPerformanceActions {
  GET_JOB_PERFORMANCE_SUCCESS = "GET_JOB_PERFORMANCE_SUCCESS",
  GET_JOB_PERFORMANCE_ERROR = "GET_JOB_PERFORMANCE_ERROR",
  POST_JOB_PERFORMANCE_SUCCESS = "POST_JOB_PERFORMANCE_SUCCESS",
  POST_JOB_PERFORMANCE_ERROR = "POST_JOB_PERFORMANCE_ERROR",
  PUT_JOB_PERFORMANCE_SUCCESS = "PUT_JOB_PERFORMANCE_SUCCESS",
  PUT_JOB_PERFORMANCE_ERROR = "PUT_JOB_PERFORMANCE_ERROR",
  DELETE_JOB_PERFORMANCE_SUCCESS = "DELETE_JOB_PERFORMANCE_SUCCESS",
  DELETE_JOB_PERFORMANCE_ERROR = "DELETE_JOB_PERFORMANCE_ERROR",
}

/**
 * Interfaces
 */
interface JobPerformanceBaseInterface {
  JobPerformances: JobPerformanceInterface[];
  loading: boolean;
}

interface UseJobPerformanceInterface extends JobPerformanceBaseInterface {
  handleGetJobPerformance: () => void;
  handlePostJobPerformance: (body: JobPerformanceInterface) => void;
  handleUpdateJobPerformance: (id: number, body: JobPerformanceInterface) => void;
  handleDeleteJobPerformance: (id: number) => void;
}

interface ReducerActionsInterface {
  type: JobPerformanceActions;
  payload: JobPerformanceBaseInterface;
}

/**
 * Hooks
 */
const useJobPerformanceRedux = (): UseJobPerformanceInterface => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const {
    JobPerformances,
  } = useSelector((state: RootReducerInterface) => state.JobPerformances);

  const handleGetJobPerformance = async (): Promise<void> => {
    try {
      setLoading(true);
      const { results } = await getJobPerformanceServices();
      setLoading(false);
      dispatch(
        actionEvent<JobPerformanceActions, JobPerformanceBaseInterface>({
          type: JobPerformanceActions.GET_JOB_PERFORMANCE_SUCCESS,
          payload: {
            JobPerformances: results,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<JobPerformanceActions, JobPerformanceBaseInterface>({
          type: JobPerformanceActions.GET_JOB_PERFORMANCE_ERROR,
        })
      );
    }
  };

  const handlePostJobPerformance = async (body: JobPerformanceInterface): Promise<void> => {
    try {
      setLoading(true);
      await postJobPerformanceServices(body);
      setLoading(false);

      dispatch(
        actionEvent<JobPerformanceActions, JobPerformanceBaseInterface>({
          type: JobPerformanceActions.POST_JOB_PERFORMANCE_SUCCESS,
          payload: {
            JobPerformances,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<JobPerformanceActions, JobPerformanceBaseInterface>({
          type: JobPerformanceActions.POST_JOB_PERFORMANCE_ERROR,
        })
      );
    }
  };

  const handleUpdateJobPerformance = async (id: number, body: JobPerformanceInterface): Promise<void> => {
    try {
      setLoading(true);
      await putJobPerformanceServices(id, body);
      setLoading(false);

      dispatch(
        actionEvent<JobPerformanceActions, JobPerformanceBaseInterface>({
          type: JobPerformanceActions.PUT_JOB_PERFORMANCE_SUCCESS,
          payload: {
            JobPerformances,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<JobPerformanceActions, JobPerformanceBaseInterface>({
          type: JobPerformanceActions.PUT_JOB_PERFORMANCE_ERROR,
        })
      );
    }
  };

  const handleDeleteJobPerformance = async (id: number): Promise<void> => {
    try {
      setLoading(true);
      await deleteJobPerformanceServices(id);
      setLoading(false);

      dispatch(
        actionEvent<JobPerformanceActions, JobPerformanceBaseInterface>({
          type: JobPerformanceActions.DELETE_JOB_PERFORMANCE_SUCCESS,
          payload: {
            JobPerformances,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<JobPerformanceActions, JobPerformanceBaseInterface>({
          type: JobPerformanceActions.DELETE_JOB_PERFORMANCE_ERROR,
        })
      );
    }
  };

  return {
    loading,
    JobPerformances,
    handleGetJobPerformance,
    handlePostJobPerformance,
    handleUpdateJobPerformance,
    handleDeleteJobPerformance
  };
};

/**
 * Reducers
 */
const initialState: JobPerformanceBaseInterface = {
  loading: false,
  JobPerformances: [],
};

const JobPerformanceReducer = (
  state = initialState,
  action: ReducerActionsInterface
): JobPerformanceBaseInterface => {
  switch (action.type) {
  case JobPerformanceActions.GET_JOB_PERFORMANCE_SUCCESS:
    return {
        ...state,
        JobPerformances: action.payload.JobPerformances,
        loading: action.payload.loading
      };
    default:
      return state;
  }
};

/**
 * Exports
 */
export { JobPerformanceReducer, useJobPerformanceRedux };
export type { UseJobPerformanceInterface, JobPerformanceBaseInterface };
