import { useContext, useEffect } from "react";
import { Form } from "react-bootstrap";
import { BiInfoCircle } from "react-icons/bi";
import iconThree from "../../../../assets/evaluaition-icons/icon-three.svg";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  NewEvaluationContext,
  NewEvaluationContextType,
} from "../../../../context/EvaluacionContext/NewEvaluationContext";
import { validateSemestre } from "../../../../context/validacionesFiltros";
import { useUrlPermisos } from "../../../../hooks/Evaluation";
import { useAbleCommentEvaluated } from "../../../../hooks/Kpis";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import {
  ComentariosTitleBox,
  CommentCardComentariosSection,
  CommentCardTitle,
  CommentsRowComentariosSection,
  KpisTextContainerComentariosSection,
  WrapperComentariosSection,
  WrapperTitleComentarios,
} from "./CommentStyles";

const CommentSection = ({
  showInfoModal,
  showInfoModalE,
  commentEvaluator,
  commentEvaluated,
  handleSetCommentEvaluator,
  handleSetCommentEvaluated,
  setCommentEvaluated,
  setCommentEvaluator,
  evaluation,
}: any) => {
  //** Context Validations
  const { setValidateComments } = useContext(
    NewEvaluationContext
  ) as NewEvaluationContextType;

  const { periodoDataInfo } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  //** Redux
  const { evaluationAttribute } = useCollaboratorRedux();
  // const { Evaluation } = useEvaluationRedux();

  //* Custom hooks
  const { urlPermisos } = useUrlPermisos();
  const { ableCommentEvaluated, ableCommentEvaluator } =
    useAbleCommentEvaluated();

  // ** Context
  useEffect(() => {
    if (!urlPermisos) {
      if (commentEvaluator === undefined || commentEvaluator === "") {
        setCommentEvaluator(evaluation?.employeeEvaluationEvaluatorCommet);
      }

      if (commentEvaluated === undefined || commentEvaluated === "") {
        setCommentEvaluated(evaluation?.employeeEvaluationCommetOfTheEvaluated);
      }
    } else {
      if (commentEvaluator !== undefined || commentEvaluator === undefined) {
        setCommentEvaluator(evaluation?.employeeEvaluationEvaluatorCommet);
      }

      if (commentEvaluated !== undefined || commentEvaluated === undefined) {
        setCommentEvaluated(evaluation?.employeeEvaluationCommetOfTheEvaluated);
      }
    }
  }, [evaluation]);

  useEffect(() => {
    if (!urlPermisos) {
      if (
        evaluation.employeeEvaluationEvaluatorCommet === null ||
        evaluation.employeeEvaluationEvaluatorCommet === ""
      ) {
        setValidateComments(true);
      } else {
        setValidateComments(false);
      }
    } else {
      if (
        evaluation.employeeEvaluationCommetOfTheEvaluated === null ||
        evaluation.employeeEvaluationCommetOfTheEvaluated === ""
      ) {
        setValidateComments(true);
      } else {
        setValidateComments(false);
      }
    }
  }, [evaluation]);

  const periodLocal = localStorage.getItem("periodId");
  const periodParsed = JSON.parse(periodLocal ?? "{}");
  const { valuePeriod } = periodParsed;
  const principalFilters = localStorage.getItem("principalFilters");
  const filtersParsed = JSON.parse(principalFilters ?? "{}");
  const { year } = filtersParsed;
  const semestre = validateSemestre(valuePeriod);

  return (
    <WrapperComentariosSection>
      <WrapperTitleComentarios>
        <img
          style={{ height: "32px", marginTop: "5px" }}
          src={iconThree}
          alt="comemntario"
        />
        <ComentariosTitleBox>
          <div className="titleComentarios">Comentarios</div>
          <div className="descriptionComentarios">
            Escribe algún comentario relacionado con la retroalimentación.
          </div>
        </ComentariosTitleBox>
        {urlPermisos && <div className="containerDate">{periodoDataInfo}</div>}
        {!urlPermisos && (
          <div className="containerDate">{`Semestre ${semestre} / ${year} `}</div>
        )}
      </WrapperTitleComentarios>
      <CommentsRowComentariosSection>
        <KpisTextContainerComentariosSection>
          <CommentCardComentariosSection>
            <CommentCardTitle>
              Evaluador
              {evaluationAttribute.show_modal_help_tooltips === 1 && (
                <BiInfoCircle
                  style={{
                    marginLeft: "6px",
                    color: "#3B63A2",
                  }}
                  onClick={showInfoModal}
                />
              )}
            </CommentCardTitle>
            <Form.Control
              as="textarea"
              className="textArea"
              placeholder="Ejemplo"
              style={{
                height: "120px",
                marginTop: "1rem",
                borderColor: "#f1f1f1",
              }}
              value={commentEvaluator}
              onChange={handleSetCommentEvaluator}
              disabled={ableCommentEvaluator}
            />
          </CommentCardComentariosSection>
          <CommentCardComentariosSection>
            <CommentCardTitle>
              Evaluado
              {evaluationAttribute.show_modal_help_tooltips === 1 && (
                <BiInfoCircle
                  style={{
                    marginLeft: "6px",
                    color: "#3B63A2",
                  }}
                  onClick={showInfoModalE}
                />
              )}
            </CommentCardTitle>
            <Form.Control
              as="textarea"
              className="textArea"
              placeholder="Ejemplo"
              style={{
                height: "120px",
                marginTop: "1rem",
                borderColor: "#f1f1f1",
              }}
              value={commentEvaluated}
              onChange={handleSetCommentEvaluated}
              disabled={ableCommentEvaluated}
            />
          </CommentCardComentariosSection>
        </KpisTextContainerComentariosSection>
      </CommentsRowComentariosSection>
    </WrapperComentariosSection>
  );
};

export default CommentSection;
