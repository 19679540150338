/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
import FileSaver from "file-saver";
import { ChangeEventHandler, FC, LegacyRef, useEffect, useState } from "react";
import PaginationTable from "../../../components/atoms/PaginationTable";
import { PrevisualizacionComponent } from "../../../components/common/UploadFile";
import Caution from "../../../components/organisms/ConfirmationModals/Caution";
import ModalStatusTargetError from "../../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import { DashboardCardBox } from "../../../components/organisms/DashboardCardsBox/DashboardCardsBox";
import FilterPolitics from "../../../components/organisms/FilterPolitics";
import { validateIdPeriod } from "../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { usePolitics } from "../../../redux/Politics";
import {
  getDataChartsPiePolitics,
  getStaffDirections,
  getStaffLocations,
} from "../../../services/dataSelects.service";
import { BulkPoliticsUploadServices } from "../../../services/politics.services";
import {
  InputGroupW,
  InputSelectDosNew,
  TitleInputSelectNew,
} from "../ModalActions/ModalEditStyles";
import colladoLoader from './../../../assets/colladoLoader.gif'
import {
  ContainButtonMobile,
  ContainButtonMobileDos,
  ContainButtonMobileTres,
} from "../Objectives/ObjetivesStyles";
import cloud from "../Objectives/images/cloud.svg";
import { PoliticsTable } from "./Components/PoliticsTable";
import { FiltersPolicies } from "./Filters";
import {
  ArchivosAgregadosContainer,
  BlueBox,
  BlueButtonDos,
  ButtonFile,
  ButtonsBox,
  CenterBox,
  ContainerUploadDos,
  DashboardsBox,
  GrayButton,
  InputsBox,
  KPIFilter,
  KPIFilterBoxSelect,
  KpiRow,
  Paragraph,
  RowBoxTres,
  RowChart,
  SubContainer,
  SubTitle,
  Title,
  TitleSectionDos,
  UploadBox,
  WrapperButton,
} from "./PoliticsStyles";
import ModalLoader from "../../../components/organisms/ConfirmationModals/ModalLoader";

const calificacion = [
  { text: "Selecciona opción", value: null },
  { text: "0", value: 0 },
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
];

interface Upload {
  accept: string;
  txtFormat: string;
  fileSizeMB: number;
  ref?: LegacyRef<HTMLInputElement>;
  multiple?: boolean;
  onChangeUpload?: ChangeEventHandler<HTMLInputElement>;
  setShowModal?: any;
  file?: File;
  period?: any;
}

interface ModalEdit {
  period: any;
}

const UploadFile: FC<Upload> = ({ txtFormat, file, period }) => {
  const [showWindow, setShowWindow] = useState(false);
  const [ isLoading,setIsLoading] = useState(false);
  const [politicsFile, setPoliticsFile] = useState<File>();
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [messageModal, setMessageModal] = useState("");
  const [messageModalDos, setMessageModalDos] = useState("");
  const [rowsInsertedResponse, setRowsInsertedResponse] = useState<number>();
  const [rowsNotInsertedResponse, setRowsNotInsertedResponse] =
    useState<number>();
  const [excelFile, setExcelFile] = useState<any>();
  const [excelFileName, setExcelFileName] = useState<any>();
  const {
    handleGetAllPolitics,
    succesPost,
    errorPost,
    setSuccesPost,
    setErrorPost,
  } = usePolitics();

  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);

  const onChangeUploadGeneral = (event: any) => {
    const receiveFile = event.target.files?.[0];

    // console.log("Peso del archivo", receiveFile);
    if (receiveFile) {
      setPoliticsFile(receiveFile);
      setShowModalCaution(true);
    }
  };

  const uploadFileGeneral = async () => {
    setIsLoading(true);
    if (politicsFile) {
      await BulkPoliticsUploadServices(politicsFile)
        .then((response) => {
          const { message, rowsInserted, rowsNotInserted, excelContent } =
            response;
          setShowModalCaution(false);
          setMessageModal(message);
          setSuccesPost(true);
          setRowsInsertedResponse(rowsInserted);
          setRowsNotInsertedResponse(rowsNotInserted);
          if (excelContent) {
            const byteCharacters = atob(excelContent.fileContents);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
              type: excelContent.contentType,
            });
            setExcelFile(blob);
            setExcelFileName(excelContent.fileDownloadName);
          }
          setIsLoading(false)
        })
        .catch((error) => {
          setShowModalCaution(false);
          setMessageModalDos("No pudimos cargar el archivo.");
          setErrorPost(true);
          setIsLoading(false);
        });
    }
    await handleGetAllPolitics(period);
  };

  const downloadExcelFile = () => {
    if (excelFile) {
      FileSaver.saveAs(excelFile, excelFileName);
    }
  };

  // console.log("Respuesta de la carga row", period);

  const onInputClick = (event: any) => {
    event.target.value = "";
  };

  // console.log(politicsFile);
  return (
    <>
      <UploadBox>
        <Caution
          textBody="Se realizará la carga masiva de datos desde Excel. Asegúrate de que el archivo esté correctamente estructurado. "
          textBodyQuestion={"¿Deseas continuar?"}
          textBodyOk={messageModal}
          messageShow={true}
          title="Confirmar carga masiva"
          estado={showModalCaution}
          cambiarEstado={setShowModalCaution}
          textButtonAct="Continuar"
          textButtonClose="Cancelar"
          handleClick={uploadFileGeneral}
        />
        <ContainerUploadDos>
          <RowBoxTres>
            <img src={cloud} alt="upload" style={{ marginRight: "18px" }} />
            <div>
              <Paragraph>Selecciona el archivo a cargar</Paragraph>
              <SubTitle>
                Formato de {txtFormat} peso de{" "}
                {politicsFile && politicsFile?.size / 1000} KB
              </SubTitle>
            </div>
          </RowBoxTres>
          {showWindow && (
            <ContainButtonMobileDos>
              <WrapperButton>
                Seleccionar
                <ButtonFile
                  type="file"
                  name="src-file1"
                  aria-label="Archivo"
                  onClick={onInputClick}
                  onChange={onChangeUploadGeneral}
                  accept=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </WrapperButton>
            </ContainButtonMobileDos>
          )}
        </ContainerUploadDos>
        {showWindow && (
          <ContainButtonMobileTres>
            <WrapperButton>
              Seleccionar
              <ButtonFile
                type="file"
                name="src-file1"
                aria-label="Archivo"
                onClick={onInputClick}
                onChange={onChangeUploadGeneral}
                accept=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </WrapperButton>
          </ContainButtonMobileTres>
        )}
      </UploadBox>
      {!showWindow && (
        <ContainButtonMobile>
          <WrapperButton>
            Seleccionar
            <ButtonFile
              type="file"
              name="src-file1"
              aria-label="Archivo"
              onClick={onInputClick}
              onChange={onChangeUploadGeneral}
              accept=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </WrapperButton>
        </ContainButtonMobile>
      )}
      {succesPost ? (
        <ModalStatusSuccessful
          handleClick={setSuccesPost}
          modalStatus={succesPost}
          messageShow
          message={
            rowsNotInsertedResponse
              ? `Hemos cargado ${rowsInsertedResponse} registros con éxito.\r\nSin embargo se detectaron ${rowsNotInsertedResponse} errores en el proceso. Por favor, revisa el archivo y cárgalo nuevamente. `
              : `Hemos cargado ${rowsInsertedResponse} registros con éxito. No se han detectado errores en el proceso. `
          }
          onClick={downloadExcelFile}
          excelFile={excelFile}
          rowsNotInsertedResponse={rowsNotInsertedResponse}
          titleModal={
            rowsNotInsertedResponse
              ? `Carga de registros parcial`
              : `Carga de registros completa`
          }
        />
      ) : null}
      {errorPost ? (
        <ModalStatusTargetError
          handleClick={setErrorPost}
          modalStatus={errorPost}
          messageShow
          messageDos={messageModalDos}
          titleModal="Lo sentimos"
        />
      ) : null}
      <>
        <PrevisualizacionComponent txtFormat={"excel"} file={politicsFile} />
      </>
      {isLoading && (
          <ModalLoader />
        )}
    </>
  );
};

// export const PoliticsTable: FC<ModalEdit> = ({ period }) => {
//   const [show, setShow] = useState(false);
//   const [dataPolicies, setDataPolicies] = useState<any>({
//     idEvaluacion: 0,
//     calificacion: 0,
//     totalPreguntas: 0,
//     totalAciertos: 0,
//   });

//   const {
//     handleUpdatePolicies,
//     politic,
//     handleGetAllPolitics,
//     succesPost,
//     errorPost,
//     setSuccesPost,
//     setErrorPost,
//   } = usePolitics();
//   const { User } = useCollaboratorRedux();
//   const [modalNotify, setModalNotify] = useState(false);
//   const [disabledBtn, setDisabledBtn] = useState(true);
//   const [mensaje, setMensaje] = useState("");

//   const handleClose = () => {
//     setShow(false);
//     setDataPolicies({
//       ...dataPolicies,
//       idEvaluacion: 0,
//       calificacion: 0,
//       totalPreguntas: 0,
//       totalAciertos: 0,
//     });
//   };
//   const handleShow = (e: any, idEvaluacion: any) => {
//     const politicData = politic.find(
//       (item) => item.idEvaluacion === idEvaluacion
//     );
//     setDataPolicies({
//       ...dataPolicies,
//       idEvaluacion: idEvaluacion,
//       calificacion: politicData?.calificacion,
//       totalAciertos: politicData?.totalAciertos,
//       totalPreguntas: politicData?.totalPreguntas,
//     });
//     setShow(true);
//   };

//   const validateBtn = () => {
//     if (
//       dataPolicies.calificacion >= 0 &&
//       dataPolicies.calificacion !== null &&
//       dataPolicies.totalPreguntas < 1000 &&
//       dataPolicies.totalPreguntas >= 0 &&
//       dataPolicies.totalAciertos < 1000 &&
//       dataPolicies.totalAciertos >= 0
//     ) {
//       setDisabledBtn(false);
//     } else {
//       setDisabledBtn(true);
//     }
//   };

//   useEffect(() => {
//     validateBtn();
//   }, [dataPolicies]);

//   const onSubmit = () => {
//     handleUpdatePolicies(dataPolicies);
//     handleClose();
//     setTimeout(() => {
//       handleGetAllPolitics(period);
//     }, 1500);
//   };

//   const handleChange = (e: any) => {
//     if (e.target.value.length < 4) {
//       setDataPolicies({
//         ...dataPolicies,
//         [e.target.name]: parseInt(e.target.value),
//       });
//       setModalNotify(false);
//     } else {
//       setDataPolicies({
//         ...dataPolicies,
//         [e.target.name]: parseInt(e.target.value),
//       });
//       setModalNotify(true);
//       setMensaje("No puede ser una cifra mayor a 999");
//     }
//   };
//   const { evaluationAttribute } = useCollaboratorRedux();
//   return (
//     <BodyTableWrapper>
//       <BodyTableWrapperWrap>
//         <GoalsHeadTablePoliticas>
//           <div className="first">Fortia</div>
//           <div className="second">Nombre</div>
//           <div className="third">Porcentaje</div>
//           <div className="fourth">Calificación</div>
//           <div className="fifth">Estatus</div>
//           {evaluationAttribute.upload_rate_politics_by_form === 1 && (
//             <div className="sixth">Acción</div>
//           )}
//         </GoalsHeadTablePoliticas>
//         {politic.map((data, index) => {
//           // console.log("dato ->", data, index);

//           return (
//             <GoalsBodyTablePoliticas key={data.claTrab}>
//               <div className="first">{data.claTrab}</div>
//               <div className="second">{data.nombre}</div>
//               <div className="third">{data.porcentaje}</div>
//               <div className="fourth">{data.calificacion}</div>
//               <div className="fifth">
//                 {data.estaCalificado
//                   ? "Resultados subidos"
//                   : "Pendiente subir resultado"}
//               </div>
//               {evaluationAttribute.edit_delete_results_politics_by_collaborator ===
//                 1 && (
//                 <div className="sixth">
//                   <SaveBtnDos
//                     disabled={data.estaCalificado}
//                     disabledP={data.estaCalificado}
//                     onClick={(e) => handleShow(e, data.idEvaluacion)}
//                   >
//                     Calificar
//                   </SaveBtnDos>
//                   <SaveBtnDos
//                     disabled={!data.estaCalificado}
//                     disabledP={!data.estaCalificado}
//                     onClick={(e) => handleShow(e, data.idEvaluacion)}
//                   >
//                     Ver / Editar
//                   </SaveBtnDos>
//                 </div>
//               )}
//             </GoalsBodyTablePoliticas>
//           );
//         })}
//       </BodyTableWrapperWrap>

//       <Modal show={show} onHide={handleClose} size="lg">
//         <Modal.Header closeButton />
//         <Modal.Body id="example-custom-modal-styling-title">
//           <strong>Insertar resultado de políticas</strong>
//           <FilterBoxNew>
//             <ContainerModal>
//               <Select
//                 title="Calificación"
//                 id="calificacion"
//                 name="calificacion"
//                 value={dataPolicies}
//                 options={calificacion}
//                 onChange={(e) => handleChange(e)}
//               />
//               <TextFieldDos
//                 title="Total de preguntas"
//                 id="totalPreguntas"
//                 placeholder=""
//                 name="totalPreguntas"
//                 value={dataPolicies}
//                 type="number"
//                 maxL={3}
//                 onChange={(e) => handleChange(e)}
//               />
//               <TextFieldDos
//                 title="Total de aciertos"
//                 id="totalAciertos"
//                 placeholder=""
//                 name="totalAciertos"
//                 value={dataPolicies}
//                 type="number"
//                 maxL={3}
//                 onChange={handleChange}
//               />
//             </ContainerModal>
//           </FilterBoxNew>
//           {modalNotify ? <FormModalNotify mensaje={mensaje} /> : null}
//         </Modal.Body>
//         <Modal.Footer>
//           <SecondaryButton onClick={handleClose}>Cancelar</SecondaryButton>
//           <PrimaryButton onClick={onSubmit} disabled={disabledBtn}>
//             Insertar resultado
//           </PrimaryButton>
//         </Modal.Footer>
//       </Modal>
//       {succesPost ? (
//         <ModalStatusSuccessful
//           handleClick={setSuccesPost}
//           modalStatus={succesPost}
//           messageShow
//           message="Los resultados de la política se han guardado correctamente. "
//           titleModal="Resultados guardados"
//         />
//       ) : null}
//       {errorPost ? (
//         <ModalStatusTargetError
//           handleClick={setErrorPost}
//           modalStatus={errorPost}
//           messageShow
//           message="No se pudo editar, por favor intenta nuevamente"
//           titleModal="Lo sentimos"
//         />
//       ) : null}
//     </BodyTableWrapper>
//   );
// };
const titleDonuts = [
  "% de colaboradores que tuvieron calificación 1 en su políticas",
  "% de colaboradores que tuvieron calificación 2 en su políticas",
  "% de colaboradores que tuvieron calificación 3 en su políticas",
  "% de colaboradores que tuvieron calificación 4 en su políticas",
  "% de colaboradores que tuvieron calificación 5 en su políticas",
];
interface DataDonuts {
  title: string;
  value: any;
}

const Politics = () => {
  const [queryFilter, setQueryFilter] = useState("");
  const [locations, setLocations] = useState<Array<string>>([]);
  const [directions, setDirections] = useState<Array<string>>([]);
  const [propLocation, setPropLocation] = useState<string>("");
  const [propDirector, setPropDirector] = useState<string>("");
  const [evaluation_period_id, setEvaluation_period_id] = useState<string>("");
  const [dataGraph, setDataGraph] = useState<Array<DataDonuts>>([
    {
      title: "",
      value: "",
    },
  ]);
  const [period, setPeriod] = useState("");
  const [isSelectLocalidadUpdated, setIsSelectLocalidadUpdated] =
    useState(false);
  const [dataRestante, setDataRestante] = useState<Array<DataDonuts>>([
    {
      title: "",
      value: "",
    },
  ]);

  //* Redux
  const { User, evaluationAttribute } = useCollaboratorRedux();
  const { handleGetAllPolitics, MetaPolitic } = usePolitics();
  const getDataDonut = async () => {
    // const IdPeriod = Number(evaluation_period_id.split(",")[0]);

    const periodo = validateIdPeriod(evaluation_period_id);
    // console.log("Este es el id", IdPeriod);

    const { data } = await getDataChartsPiePolitics(
      periodo,
      propLocation,
      propDirector
    );
    const dataDonutComplete: Array<number> = [];
    const dataDonutRestante: Array<number> = [];

    data?.map((data) => {
      return dataDonutComplete.push(data.compleado);
    });
    const result = dataDonutComplete.map((data, index) => {
      return {
        title: titleDonuts[index],
        value: data,
      };
    });

    setDataGraph(result);

    data?.map((data) => dataDonutRestante.push(data.restante));
    const rest = dataDonutRestante.map((data, index) => {
      return {
        title: titleDonuts[index],
        value: data,
      };
    });
    setDataRestante(rest);
  };

  const getOptionisLocations = async () => {
    const { data } = await getStaffLocations();
    const locationsList: Array<string> = [];
    data.map((data) => locationsList.push(data));
    setLocations(locationsList);
  };
  const getOptionsDirections = async () => {
    const { data } = await getStaffDirections();
    const directionsList: Array<string> = [];
    data.map((data) => directionsList.push(data));
    setDirections(directionsList);
  };

  useEffect(() => {
    getOptionisLocations();
    getOptionsDirections();
  }, []);

  const handleAction = (periodNew: any) => {
    // const evaluation = e.split(",");
    // console.log("Aquí -> ", periodNew);

    setEvaluation_period_id(periodNew);
    setQueryFilter(`?IdPeriod=${periodNew}`);
    handleGetAllPolitics(periodNew);
    setPeriod(periodNew);
  };

  const handleOptionLocation = (e: any) => {
    const { value } = e.target;
    setPropLocation(value);
  };
  const handleOptionDirector = (e: any) => {
    const { value } = e.target;
    setPropDirector(value);
  };
  const handlePaintData = (e: any) => {
    getDataDonut();
    setIsSelectLocalidadUpdated(true);
  };

  const handleLimpiar = () => {
    setPropLocation("");
    setPropDirector("");
    setDataGraph([{ title: "", value: "" }]);
    setIsSelectLocalidadUpdated(false);

  };

  console.log("Prop location", propLocation, propDirector);
  return (
    <BlueBox>
      <Title
        style={{
          paddingTop: "24px",
          marginBottom: "48px",
          fontSize: "28px",
          color: "#000",
        }}
      >
        Políticas
      </Title>
      <FilterPolitics
        handleAction={handleAction}
        setEvaluation_period_id={setEvaluation_period_id}
      />
      {evaluationAttribute.five_kpis_politics_ratings === 1 && (
        <KPIFilterBoxSelect>
          <div className="title">KPI</div>
          <KPIFilter>
            <KpiRow>
              <InputsBox>
                <InputGroupW>
                  <TitleInputSelectNew widthSelect="auto">
                    Localidad
                  </TitleInputSelectNew>
                  <InputSelectDosNew
                    widthSelect="100%"
                    onChange={handleOptionLocation}
                    value={propLocation}
                  >
                    <option value="">Elige una opción</option>
                    {locations.map((data) => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                  </InputSelectDosNew>
                </InputGroupW>
                <InputGroupW>
                  <TitleInputSelectNew widthSelect="auto">
                    Director
                  </TitleInputSelectNew>
                  <InputSelectDosNew
                    widthSelect="100%"
                    onChange={handleOptionDirector}
                    value={propDirector}
                  >
                    <option value="">Elige una opción</option>
                    {directions.map((data) => (
                      <option key={data} value={data}>
                        {data}
                      </option>
                    ))}
                  </InputSelectDosNew>
                </InputGroupW>
              </InputsBox>
              <ButtonsBox>
                <GrayButton onClick={handleLimpiar}>Limpiar</GrayButton>
                <BlueButtonDos onClick={handlePaintData}>Buscar</BlueButtonDos>
              </ButtonsBox>
            </KpiRow>
          </KPIFilter>
        </KPIFilterBoxSelect>
      )}
      {evaluationAttribute.five_kpis_politics_ratings === 1 &&
      isSelectLocalidadUpdated ? (
        <DashboardsBox>
          <RowChart>
            <DashboardCardBox
              evaluation_period_id={evaluation_period_id}
              firstValidation={38}
              dataDonutComplete={dataGraph}
              dataDonutRestante={dataRestante}
              inPolitics={true}
            />
          </RowChart>
        </DashboardsBox>
      ) : null}

      <SubContainer>
        <TitleSectionDos>Subida de políticas</TitleSectionDos>
        {evaluationAttribute.uplodad_ratings_by_excel === 1 && (
          <ArchivosAgregadosContainer>
            <Title>Archivos agregados</Title>
            <UploadFile
              accept={""}
              txtFormat={""}
              fileSizeMB={0}
              period={period}
            />
          </ArchivosAgregadosContainer>
        )}

        {evaluationAttribute.filters_search_collaborator_manager_director ===
          1 && (
          <FiltersPolicies
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
            period={period}
          />
        )}

        <PoliticsTable period={period} queryFilter={queryFilter} />
        <CenterBox>
          <PaginationTable
            table="Politicas"
            Meta={MetaPolitic}
            setQueryFilter={setQueryFilter}
            evaluation_period_id={period}
          />
        </CenterBox>
      </SubContainer>
    </BlueBox>
  );
};

export default Politics;
