import { FC } from "react";
import check from '../../../assets/checkGreen.svg';
import Close from "../../../assets/icons/CloseModal.svg";
import info from '../../../assets/infoYellow.svg';
import {
  ButtonContainer,
  ButtonContinuar,
  CloseIcon,
  ContainerModal,
  Header,
  ImageIcon,
  ModalBox,
  TextModal
} from "./ModalStatusStyles";

interface Labels {
  handleClick?: any;
  showData?: boolean | true;
  modalStatus?: boolean;
  message?: string;
  messageShow?: boolean;
  setFooter?: any;
  onClick?:any
  excelFile?:any
  rowsNotInsertedResponse?:number
  titleModal?:any
}

export const ModalStatusSuccessful: FC<Labels> = ({
  showData,
  handleClick,
  modalStatus,
  message,
  messageShow,
  setFooter,
  onClick,
  excelFile,
  rowsNotInsertedResponse,
  titleModal
}) => {
  return (
    <ContainerModal showData={showData ? "flex" : "none"}>
      <ModalBox>
        <Header>
          <div>
            <ImageIcon src={rowsNotInsertedResponse ? info :  check} style={{ marginRight: "12px" }} />
          {titleModal}   
          </div>
          <CloseIcon src={Close} onClick={() =>{ 
            if(setFooter){
              setFooter(false)
            }
            handleClick(!modalStatus)}} />
        </Header>
        <TextModal>
          {messageShow ? message : "El objetivo se ha agregado correctamente."}
        </TextModal>
      
        {excelFile  && titleModal === 'Carga de registros parcial' ?<ButtonContainer><ButtonContinuar onClick={onClick}>Descargar archivo</ButtonContinuar></ButtonContainer>  : null}
   
      </ModalBox>
    </ContainerModal>
  );
};

export default ModalStatusSuccessful;
