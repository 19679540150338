import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    width: 75px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background: var(--primary-true-blue, #3B63A2);
    box-shadow: -4px 4px 7px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
`

export const Imagen = styled.img`
    width: 32px
`

export const Text = styled.p`
    color: #FFF;
    text-align: center;
    font-family: Plus Jakarta Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 12px */
    margin:0
`

export const InnerContainer = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center
`