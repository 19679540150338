import styled from "styled-components";
type SelectInterface = {
  widthSelect?: any;
};
type BtnInterface = {
  widthSelect?: any;
};
export const ContainerFiltros = styled.section`
  /* width: 85vw; */
  /* margin:  20px 0px; */

  /* border: 1px solid red; */
`;

export const HeaderFiltrosBody = styled.div`
  .titlePeriod {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 25.5px;
    gap: 2px;
    border-radius: 8px 8px 0px 0px;

    //styleName: Body Bold;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    background-color: #c9f2ff;
    color: #3b63a2;
    max-width: 100px;
  }

  .bodyPeriod {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* height: 96px; */
    justify-content: space-between;
    padding: 16px;
    background-color: #ffffff;
    /* border: 1px solid red; */
  }
  .groupSelects {
    width: 100%;
    height: 100%;
    gap: 24px;
    /* border: 1px solid red; */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
  }

  .groupBtns {
    width: 100%;
    height: 100%;
    gap: 16px;
    margin-top: 16px;
    /* border: 1px solid red; */
    display: flex;
    justify-content: end;
    align-items: end;
  }
`;

export const SelectPeriod = styled.select.attrs(
  (props: SelectInterface) => ({})
)<SelectInterface>`
  /* width: ${(props) => props.widthSelect || "100%"}; */
  width: 100%;
  border: 1px solid #f8f8f8;
  font-size: 13px;

  padding-left: 16px;
  border-radius: 0px 8px 8px 0px;
  margin: none !important;
  width: ${(props) => props.widthSelect || "56%"};
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;
  background-color: #f8f8f8;
  background-position: calc(100% - 0.6rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 100%;
  /* height: 50px; */
`;

export const OptionPeriod = styled.option`
  /* border: 1px solid red; */
`;

export const SelectLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Plus Jakarta Sans;
  color: #656569;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  /* letter-spacing: 0px; */
  /* text-align: left; */
  border: 1px solid #f8f8f8;
  border-radius: 0px 8px 8px 0px;
  height: 40px;
  justify-content: center;
  /* align-items: center; */

  .labelContainer {
    /* width: 40%; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    gap: 8px;
  }
`;

export const BtnPeriodSearch = styled.button.attrs(
  (props: BtnInterface) => ({})
)<BtnInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width:  86px; */
  /* height:  40px; */
  padding: 8px 24px 8px 24px;
  border-radius: 8px;
  gap: 8px;
  background-color: #3b63a2;
  color: #fff;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`;
export const BtnPeriodClean = styled.button.attrs(
  (props: BtnInterface) => ({})
)<BtnInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width:  86px; */
  /* height:  40px; */
  padding: 8px 24px 8px 24px;
  border-radius: 8px;
  gap: 8px;
  background-color: #c9f2ff;
  color: #656569;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #3b63a2;
`;
