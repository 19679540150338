import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../context/EvaluationContext";
import { SocketContext } from "../../context/SocketContext";
import { validateIdPeriod } from "../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { getObjectivesPreviewListFuture } from "../../services/dataSelects.service";
import { getCollaboratorServices } from "../../services/collaborator.service";
import { getEvaluationServices } from "../../services/evaluation.service";

/**
 *  Control principal para la funcionamiento de los sockets en las evoluciones se controlar los
 *  siguientes conceptos:
 * 1. conectar al socket a la sala de la evaluación con el evento (join-room)
 * 2. verificar cuantos usuarios están conectados a la sala de la evaluación con el evento (someone_connected)
 * para controlar el modal que bloquea la actualización de los inputs
 * 3. dispara evento para cargar información nueva ingresada por otros sockets con el evento (load-information)
 * 4. controla el evento (next-socket) para cerrar el modal que bloquea la actualización de los inputs
 *  @returns  {
 *   showBlockEvalutionModal
 * }
 */
export const useEvaluationSocket = () => {
  const [showBlockEvalutionModal, setShowBlockEvalutionModal] = useState(false);
  const { online, socket, setIdRoom } = useContext(SocketContext);
  const { handleGetCollaborator, User } = useCollaboratorRedux();
  const { handleGetEvaluation } = useEvaluationRedux();
  const {
    setFutureObjetives,
    setEvaluationInfo,
    periodId,
    evaluationInfo,
    setIsReduxOrContext,
  } = useContext(EvaluationContext) as EvaluationContextType;
  const params = useParams();

  const parsePeriod = (periodId: string) => {
    const cache = JSON.parse(periodId)?.valuePeriod;
    const result = validateIdPeriod(cache);
    return result;
  };

  const sendInfo = (data: any) => {
    handleGetEvaluation(
      data?.employeeEvaluation?.employeeEvaluationEvaluatorUserId,
      data?.claTrab,
      data?.employeeEvaluation?.evaluationPeriod?.evaluationPeriodId
    );
  };

  const getFuturesInfo = async (data: any) => {
    const { data: listFuture } = await getObjectivesPreviewListFuture(
      data.employeeEvaluation?.evaluationPeriod?.evaluationPeriodId,
      data.employeeEvaluation?.employeeEvaluationEvaluatedUserId,
      data.employeeEvaluation?.employeeEvaluationId
    );
    setIsReduxOrContext(false);
    setFutureObjetives(listFuture);
    setEvaluationInfo(data);
  };

  const GetInformationEvaluation = () => {
    let periodCache =
      periodId || parsePeriod(localStorage.getItem("periodId") as string);
    handleGetCollaborator(User?.claTrab, periodCache);
    getCollaboratorServices(User?.claTrab, periodCache).then((res) => {
      sendInfo(res.data);
      getFuturesInfo(res.data);
      getEvaluationServices(
        res.data?.employeeEvaluation?.employeeEvaluationEvaluatorUserId,
        res.data?.claTrab,
        res.data?.employeeEvaluation.evaluationPeriod.evaluationPeriodId
      );
    });
  };

  const parseSearchEvaluation = () => {
    return {
      clabTrab: evaluationInfo?.claTrab,
      evaluationId: evaluationInfo?.employeeEvaluation?.employeeEvaluationId,
    };
  };

  const getFutureObjectives = async () => {
    let periodCache =
      periodId || parsePeriod(localStorage.getItem("periodId") as string);

    const data = parseSearchEvaluation();

    // console.log('id de la evolución ',evaluationInfo.claTrab);
    // console.log(params);

    let idClaveTra = data?.clabTrab;

    if (!idClaveTra) return;

    handleGetCollaborator(idClaveTra, periodCache);
    getCollaboratorServices(idClaveTra, periodCache).then((res) => {
      sendInfo(res.data);
      getFuturesInfo(res.data);
      getEvaluationServices(
        res.data?.employeeEvaluation?.employeeEvaluationEvaluatorUserId,
        res.data?.claTrab,
        res.data?.employeeEvaluation.evaluationPeriod.evaluationPeriodId
      );
    });
  };

  const memoizedGetInformation = useCallback(() => {
    if (User.role === "Evaluado") {
      GetInformationEvaluation();
    } else {
      getFutureObjectives();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [User.role]);

  useEffect(() => {
    const newRoom = evaluationInfo?.claTrab;

    if (socket && newRoom) {
      const idRoom = newRoom;
      socket.emit("join-room", idRoom);
    }

    if (online && newRoom && socket) {
      const idRoom = newRoom;
      setIdRoom(idRoom);

      const handleSomeoneConnected = () => {
        setShowBlockEvalutionModal(true);
      };

      const handleNextSocket = () => {
        setShowBlockEvalutionModal(false);
      };

      const handleLoadInformation = () => {
        memoizedGetInformation();
      };

      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        if (newRoom) {
          socket?.emit("leave-room", newRoom);
          setIdRoom(undefined);
        }
        event.returnValue = "";
        return "";
      };

      socket.on("someone_connected", handleSomeoneConnected);
      socket.on("next-socket", handleNextSocket);
      socket.on("load-information", handleLoadInformation);

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        socket.off("someone_connected", handleSomeoneConnected);
        socket.off("next-socket", handleNextSocket);
        socket.off("load-information", handleLoadInformation);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [
    online,
    socket,
    setIdRoom,
    memoizedGetInformation,
    evaluationInfo?.claTrab,
  ]);

  return {
    showBlockEvalutionModal,
  };
};
