import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { http } from "../../api";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import NotificationCard from "./components/NotificationCard";

const NotificationsTable = ({
  setOpen,
  open,
  firstCharge,
  setNotificationsList,
}: any) => {
  const { User } = useCollaboratorRedux();

  const [updateData, setUpdateData] = useState(false);
  const {
    isLoading,
    isFetching,
    data: lista,
    isError,
    error,
  } = useQuery({
    queryKey: ["issuesList", User?.claTrab, open, updateData, firstCharge],
    queryFn: async () => {
      console.log("User", User?.claTrab);

      if (User?.claTrab === undefined) return;
      const response = await http.get(
        `/Solicitudes/get-available-requests/?ClaTrab=${User?.claTrab}`
      );
      // console.log("largo de datos", response.data.data);

      setNotificationsList(response.data.data.length);
      // console.log("aquí", response.data);
      return response.data;
    },
  });

  const modalRef = useRef<HTMLDivElement>(null);
  // console.log("lista", lista?.data.length)
  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current?.contains(event.target as Node)) {
      setOpen(!open); // Función para cerrar el modal
    }
  };

  // console.log("Lista", lista.data);
  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
      // console.log("open");
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup al desmontar el componente
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, setOpen]); // Dependencias para actualizar el listener

  if (!open) return null;

  return (
    <>
      <Container $open={open} ref={modalRef}>
        {/* { open && <>Hola</>} */}
        {isError && "...error"}
        {isLoading || isFetching ? (
          "Loading..."
        ) : lista?.data.length === 0 ? (
          <div className="containerList" style={{ padding: "20px" }}>
            No tienes notificaciones
          </div>
        ) : (
          <div className="containerList">
            {lista?.data?.map((item: any, index: number) => {
              return (
                <NotificationCard
                  setUpdateData={setUpdateData}
                  updateData={updateData}
                  closeNotifications={setOpen}
                  key={item.listLocalidad}
                  idSolicitante={item.claTrabSolicitud}
                  nombreSolicitante={item.nombreUsuarioSolicitud}
                  index={index}
                  lengthData={lista.data.length}
                  idSolicitud={item.solicitudCambioMasivoId}
                />
              );
            })}
          </div>
        )}
      </Container>
    </>
  );
};

export default NotificationsTable;

const Container = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`
  border-radius: 16px;
  box-shadow: 0px 4px 8px 0px rgba(6, 17, 46, 0.18);
  width: 400px;
  position: absolute;
  right: 20px;
  top: 50px;
  background-color: #f8f8f8;
  display: ${(props) => (props.$open ? "block" : "none")};
  z-index: 1000;
  .containerList {
    display: flex;
    flex-direction: column;
  }
`;

type PropTypeBgTres = {
  $open?: any;
};
