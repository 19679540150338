/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
import { ChangeEventHandler, FC } from "react";
import { Form, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import { FixSearchLabel } from "../../../components/atoms/DownLoadReport/DownLoadReportStyles";
import {
  InputGroupW,
  InputSelect,
  InputSelectDos,
  InputTDate,
  InputTField,
  InputTFieldDos,
  InputWrapper,
  TitleInput,
  TitleInputDos,
  TitleInputSelect,
} from "./ModalEditStyles";

interface Options {
  value: any;
  text: any;
}

interface OptionsDos {
  value: any;
}
interface Values {
  [prop: string | number]: string | number;
}
interface PropsInputs {
  onChange?: ChangeEventHandler;
  title: string;
  options?: Array<Options>;
  placeholder?: string;
  id: string;
  name: string;
  value: any;
  onKeyDownCapture?: any;
  maxLength?: number;
  type?: string;
  maxL?: any;
}
interface PropsInputsDos {
  onChange?: ChangeEventHandler;
  title: string;
  options?: Array<Options>;
  optionsDos?: Array<OptionsDos>;
  placeholder?: string;
  id: string;
  name: string;
  value?: any;
  onKeyDownCapture?: any;
  maxLength?: number;
  type?: string;
  maxL?: any;
}

export const Date: FC<PropsInputs> = ({
  title,
  onChange,
  placeholder,
  id,
  name,
  value,
}) => {
  // 

  return (
    <InputWrapper>
      <TitleInput>{title}</TitleInput>
      <InputTDate
        type="date"
        placeholder={placeholder}
        aria-label="Corporativo"
        aria-describedby={id}
        onChange={onChange}
        // className="gapEelements"
        name={name}
        value={value[name]}
      />
    </InputWrapper>
  );
};

export const TextField: FC<PropsInputs> = ({
  title,
  onChange,
  placeholder,
  id,
  name,
  value,
  // onKeyDownCapture,
  maxLength,
  type,
}) => {
  // 

  return (
    <InputWrapper>
      <TitleInput>{title}</TitleInput>
      <InputTField
        maxLength={maxLength}
        type={type}
        placeholder={placeholder}
        // aria-label="Username"
        name={name}
        // onKeyDownCapture={onKeyDownCapture}
        aria-describedby={id}
        onChange={onChange}
        // className="gapEelements"
        value={value[name]}
      />
    </InputWrapper>
  );
};
export const TextFieldDos: FC<PropsInputs> = ({
  title,
  onChange,
  placeholder,
  id,
  name,
  value,
  onKeyDownCapture,
  maxL,
  type,
}) => {
  return (
    <InputWrapper>
      <TitleInputDos>{title}</TitleInputDos>
      <InputTFieldDos
        type={type}
        placeholder={placeholder}
        aria-label="Username"
        name={name}
        // onKeyDownCapture={onKeyDownCapture}
        aria-describedby={id}
        onChange={onChange}
        // className="gapEelements"
        value={value[name]}
        maxLength={maxL}
      />
    </InputWrapper>
  );
};

export const TextFieldLong: FC<PropsInputs> = ({
  title,
  onChange,
  placeholder,
  id,
  name,
  value,
  onKeyDownCapture,
  maxLength,
}) => {
  // 

  return (
    <InputGroup>
      <InputGroup.Text id={id} style={FixSearchLabel}>
        {title}
      </InputGroup.Text>
      <Form.Control
        maxLength={maxLength}
        placeholder={placeholder}
        aria-label="Username"
        name={name}
        onKeyDownCapture={onKeyDownCapture}
        aria-describedby={id}
        onChange={onChange}
        className="gapEelements"
        value={value[name]}
      />
    </InputGroup>
  );
};

const SelectWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
`;


export const Select: FC<PropsInputs> = ({
  title,
  onChange,
  options,
  placeholder,
  id,
  name,
  value,
}) => {
  return (
    <SelectWrapper>
      <TitleInputSelect>{title}</TitleInputSelect>
      <InputSelect
        placeholder={placeholder}
        aria-label="All"
        aria-describedby={id}
        className="gapEelements"
        name={name}
        onChange={onChange}
        value={value[name]}
        defaultValue={value[name]}
        style={{margin: '0px'}}
      >
        {options?.map(({ text, value }) => (
          <option value={value}>{text}</option>
        ))}
      </InputSelect>
    </SelectWrapper>
  );
};

export const SelectDos: FC<PropsInputs> = ({
  title,
  onChange,
  options,
  placeholder,
  id,
  name,
  value,
}) => {
  return (
    <InputGroupW>
      <TitleInputSelect>{title} </TitleInputSelect>
      <InputSelectDos
        placeholder={placeholder}
        aria-label="All"
        aria-describedby={id}
        className="gapEelements"
        name={name}
        onChange={onChange}
        value={value[name]}
        defaultValue={value[name]}
      >
        {options?.map(({ text, value }) => (
          <option value={value}>{text}</option>
        ))}
      </InputSelectDos>
    </InputGroupW>
  );
};
export const SelectTres: FC<PropsInputsDos> = ({
  title,
  onChange,
  options,
  placeholder,
  id,
  name,
  value,
}) => {
  return (
    <InputGroupW>
      <TitleInputSelect>{title} </TitleInputSelect>
      <InputSelectDos
        placeholder={placeholder}
        aria-label="All"
        aria-describedby={id}
        className="gapEelements"
        name={name}
        onChange={onChange}
        value={value[name]}
        defaultValue={value[name]}
      >
        {options?.map(({ text }) => (
          <option value={text}>{text}</option>
        ))}
      </InputSelectDos>
    </InputGroupW>
  );
};
