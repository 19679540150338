import styled from "styled-components";

export const EvaluationFormContainer = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  /* background-color: red; */
  /* flex-direction: column; */
  justify-content: center;
  gap: 3rem;

  @media screen and (min-width: 770px) {
    justify-content: flex-start;
  }
`;

export const QuestionListContainer = styled.div`
  padding: 24px;
  background: #ffffff;
  /* border-radius: 16px; */
  border: 1px solid red;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  /* justify-content: space-evenly; */
  font-family: "Plus Jakarta Sans";
  .separateDiv {
    border-right: 1px solid #d9d9d9;
    @media screen and (max-width: 1287px) {
      border: none;
    }
  }
  .two {
    @media screen and (max-width: 1287px) {
      display: none;
    }
  }

  .three {
    margin-top: 4rem;
  }
  @media screen and (max-width: 1287px) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
`;

export const EvaluationResultsContainer = styled.div`
  display: flex;
  /* border: 1px solid red; */
  width: 97%;
  justify-content: flex-end;
  background-color: #F4FCFF;
  margin: 20px auto;
  /* margin-top: 0px; */
  /* border-right: 1px solid #939396;
  justify-content: end; */
  /* display: none; */
  /* align-items: center; */

  /* @media screen and (max-width: 1287px) {
    display: block;
    border: none !important;
  } */
`;

export const HeaderEvaluationContainer = styled.div`
  margin-bottom: 2rem;
  /* border: 1px solid red; */
`;

export const TitleRow = styled.div`
  display: flex;
  margin-top: 40px;
  padding: 0 28px;

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const TitleBox = styled.div`
  margin-left: 8px;
`;

export const TitleTextBox = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    border: none !important;
    padding: 0;
  }
`;

export const TitleText = styled.h3`
  font-weight: 700;
  line-height: 110%;
  font-size: 16px;
  color: #3b63a2;
  margin: 0;
  width: 160px;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    width: fit-content;
  }
`;

export const PrevTextBox = styled.ul`
  padding: 0;

  li {
    list-style: none;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    gap: 6px;
  }
`;

export const PrevText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #939396;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;
