import { useContext } from "react";
import editObj from "../../../assets/icons/EditObj.svg";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { useEditFutureObjetive } from "../../../hooks/Evaluation";
import { useSemesterYearNow } from "../../../hooks/Evaluation/useSemesterYearNow";
import {
  EditContainer,
  EditObjectiveContainer,
} from "../Dashboard/stylesDashboard/AdminStyles";

const EditObjetive = ({ setEditObjective, index, data, setIndexEdit }: any) => {
  
  // ** Context
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;
  const { editeFutureObjetive } = useEditFutureObjetive();
  const { isActualDate } = useSemesterYearNow();
  const handleClick = () => {
    setEditObjective(true);
    setIndexEdit(index);
  };

  return (
    <EditContainer>
      <EditObjectiveContainer>
        {data.length > 0 && data[index]?.descripcion?.label}
      </EditObjectiveContainer>

      {/* {isActualDate && ( */}
        <div style={{ cursor: "pointer" }} onClick={() => handleClick()}>
          <img
            src={editObj}
            alt="editObjetive"
            style={{ height: "12px", width: "12px" }}
          />
        </div>
      {/* )} */}
    </EditContainer>
  );
};

export default EditObjetive;
