import styled from "styled-components";

export const TitleRowMetricasSection = styled.div`
  display: flex;
  margin-top: 40px;
  padding: 0 28px;
  /* border: 1px solid red; */
  align-items: center;
  margin-left: 24px;
  position: relative;
  .containerDate {
    position: absolute;
    right: 20px;
    font-size: 12px;
    font-family: "Plus Jakarta Sans";
    background-color: #F0F0F0;
    border-radius: 16px;
    padding:  7px 10px; 
  }

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;

export const TitleBoxMetricasSection = styled.div`
  margin-left: 24px;
  /* border: 1px solid red; */
  .titleMetricas {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 38.4px */
    margin: none;
    margin-right: 4px;
  }
`;

export const TitleTextBoxMetricasSection = styled.div`
  display: flex;
  /* align-items: flex-start; */
  position: relative;
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;

  /* @media screen and (min-width: 768px) {
    border: none !important;
    padding: 0;
  } */
`;

export const QuestionListContainerMetricasSection = styled.div`
  padding: 24px;
  background: #ffffff;
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-family: "Plus Jakarta Sans";

  .separateDiv {
    border-right: 1px solid #d9d9d9;
    @media screen and (max-width: 1287px) {
      border: none;
    }
  }
  .two {
    @media screen and (max-width: 1287px) {
      display: none;
    }
  }

  .three {
    margin-top: 4rem;
  }
  @media screen and (max-width: 1287px) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
`;

export const WrapperSectionMetricas = styled.div`
  background: #ffffff;

  width: 100%;
`;
