import {
  ApiResponse,
  EvaluationNew,
  IResponse,
} from "../Interface/ApiInterface";
import { http } from "../api";

const postEvaluationFirmaServices = async (
  idEvaluation: number,
  type: string
) => {
  return new Promise<ApiResponse<EvaluationNew>>((resolve, reject) => {
    http
      .post(
        `Evaluations/firma-evaluation?idEvaluation=${idEvaluation}&type=${type}`
      )
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getEvaluationServices = async (
  ClaTrabEvaluator: number,
  ClaTrabEvaluated: number,
  idPeriod: number
) => {
  return new Promise<ApiResponse<EvaluationNew>>((resolve, reject) => {
    // console.log("ESTE PERIODO ENVÍAS->", idPeriod);

    http
      .get(
        `Evaluations/Check-Evaluation/${ClaTrabEvaluator}/${ClaTrabEvaluated}/${idPeriod}`
      )
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getEvaluationReminder = async (idPeriod: number) => {
  return new Promise<ApiResponse<any>>((resolve, reject) => {
    http
      .get(`Evaluations/send-pending-evaluations?IdPeriod=${idPeriod}`)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postInterviewServices = async (id: number, fecha: String) => {
  return new Promise<ApiResponse<EvaluationNew>>((resolve, reject) => {
    http
      .post(`Evaluations/update-interview-evaluation/${id}?fecha=${fecha}`)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postEvaluationServices = async (body: EvaluationNew) => {
  return new Promise<IResponse<EvaluationNew>>((resolve, reject) => {
    http
      .post(`https://pokeapi.co/api/v2/pokemon?limit=100000&offset=0`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const putEvaluationServices = async (id: number, body: EvaluationNew) => {
  return new Promise<IResponse<EvaluationNew>>((resolve, reject) => {
    http
      .put(`https://pokeapi.co/api/v2/pokemon?limit=100000&offset=0`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteEvaluationServices = async (id: number) => {
  return new Promise<IResponse<EvaluationNew>>((resolve, reject) => {
    http
      .delete(`https://pokeapi.co/api/v2/pokemon?limit=100000&offset=0`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateEvaluationServices = async (body: any) => {
  return new Promise<any>((resolve, reject) => {
    http
      .put(`Evaluations/update-evaluation`, body)
      .then((response) => {
        // console.log("Respuesta back -> ", response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateEvaluationServicesPrueba = async (body: any) => {
  return new Promise<any>((resolve, reject) => {
    http
      .put(`Evaluations/update-evaluation`, body)
      .then((response) => {
        // console.log("Respuesta back -> ", response);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const saveEvaluationServices = async (body: any) => {
  return new Promise<any>((resolve, reject) => {
    http
      .put(`Evaluations/update-evaluation?ChangeStatus=false`, body)
      .then((response) => {

        // console.log("Respuesta back -> ", response)
        resolve(response);
      })
      .catch((error) => {
        // console.log("Respuesta back -> ", error)
        reject(error);
      });
  });
};

const updateEvaluationStatusSemaforo = async (body: any) => {
  return new Promise<IResponse<any>>((resolve, reject) => {
    http
      .put(
        `Evaluations/update-evaluation-status-semaforo?IdEvaluation=${body.IdEvaluation}&Status=${body.Status}`
      )
      .then((response) => {
        // console.log("Respuesta back -> ", response);

        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getEvaluationStatusSemaforo = async (IdEvaluation: number) => {
  return new Promise<ApiResponse<any>>((resolve, reject) => {
    http
      .get(`Evaluations/get-evaluation-status-semaforo/${IdEvaluation}`)
      .then((response) => {
        const { data, meta } = response.data;
        // console.log("Respuesta back ->",response);

        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export {
  deleteEvaluationServices,
  getEvaluationReminder,
  getEvaluationServices,
  getEvaluationStatusSemaforo,
  postEvaluationFirmaServices,
  postEvaluationServices,
  postInterviewServices,
  putEvaluationServices,
  saveEvaluationServices,
  updateEvaluationServices,
  updateEvaluationServicesPrueba,
  updateEvaluationStatusSemaforo
};

