import styled from "styled-components";

export const Title = styled.div`
  color: var(--primary-black, #000);
  font-feature-settings: "clig" off, "liga" off;

  /* H3 */
  font-family: Plus Jakarta Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
  /* margin-bottom: 40px; */
  /* border: 1px solid red; */
  display: flex;
  height: 100%;
  justify-content: space-between;
  /* align-self: center; */
  .containerToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

export const SubtitleCard = styled.p`
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;

  /* H4 */
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  width: 50%;
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  /* border: 1px solid #e5e5e5; */
  justify-content: center;
  align-items: center;
  //justify-content: space-between;
  /* gap: 24px; */
`;

export const SubtitlesContainer = styled.div`
  display: flex;
  //justify-content: space-between;
  gap: 24px;
  width: 100%;
`;

export const ContainerCalificacionEmpresariales = styled.div`
  background-color: #ffffff;
  padding: 32px 24px;
  margin-bottom: 24px;
  margin-top: 24px;
  gap: 24px;
`;
