import { TextField } from "@mui/material";
import * as React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import styled from "styled-components";
import { useAbleFutureObjetivesDefaults } from "../../../../hooks/ObjetivosFuturos";
import { ModalBodyTableDos } from "../../EvaluationForms/EvaluationFormsStyles";
import { regexValidateNumber } from "../utils";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        decimalScale={2}
        thousandSeparator
        valueIsNumericString
        prefix=""
        suffix={"%"}
        isAllowed={(values) => {
          const { floatValue } = values;
          //   const MAX_LIMIT = 120;
          if (floatValue) {
            return floatValue <= 100;
          }
          return true;
        }}
      />
    );
  }
);

export default function ModalInputGeneral({
  handleChange,
  value,
  disable,
  index,
  name,
}: any) {
  return (
    <ContainerInput>
      <TextField
        sx={{ maxWidth: "120px" }}
        value={value}
        onChange={(e) => {
          if (
            regexValidateNumber.test(e.target.value) ||
            e.target.value === ""
          ) {
            handleChange(e.target.value, index);
          }
        }}
        name={name}
        InputProps={{
          inputComponent: NumericFormatCustom as any,
        }}
        disabled={disable}
      />
    </ContainerInput>
  );
}

export const ColumnsDataD = ({
  dataMap,
  semestre,
  objetivesTypeG,
  setObjetivesTypeG,
}: any) => {
  const { ableSelectDefaultObj } = useAbleFutureObjetivesDefaults();

  const handleChangeValue = (value: number | string, index: number) => {
    const changedValue = objetivesTypeG.map((item: any, i: number) => {
      if (i === index) {
        return {
          ...item,
          valor: value,
        };
      } else {
        return item;
      }
    });

    setObjetivesTypeG(changedValue);
  };
  return (
    <>
      {dataMap &&
        dataMap?.map((item: any, index: number) => {
          if (item.type === "G") {
            return (
              <ModalBodyTableDos key={item.id}>
                <div className="first">{semestre}</div>
                <div className="second">
                  {/* <ModalInputGeneral
                    handleChange={handleChangeValue}
                    value={objetivesTypeG[index]?.valor}
                    disable={ableSelectDefaultObj}
                    index={index}
                    name="valor"
                  /> */}
                  <ContainerInput>
                    <TextField
                      sx={{ maxWidth: "120px" }}
                      value={objetivesTypeG[index]?.valor}
                      onChange={(e) => {
                        if (
                          regexValidateNumber.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          handleChangeValue(e.target.value, index);
                        }
                      }}
                      name="valor"
                      InputProps={{
                        inputComponent: NumericFormatCustom as any,
                      }}
                      disabled={ableSelectDefaultObj}
                    />
                  </ContainerInput>
                </div>
                <div className="third">{item.description}</div>
                <div className="fourth">{item.meta}%</div>
              </ModalBodyTableDos>
            );
          }

          return null;
        })}
    </>
  );
};

const ContainerInput = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: center;
    align-content: center; */
  /* border: 1px solid red; */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;
    margin: 0 auto;
    outline: none;
    font-family: Plus Jakarta Sans;
  }
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;
    font-family: Plus Jakarta Sans;
    align-items: center;
    justify-content: center;
    outline: none;
    align-content: center;
  }

  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;
  }

  .MuiInputBase-root {
    outline: none;
    font-family: Plus Jakarta Sans;
  }
`;
