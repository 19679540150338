import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CautionIcon from '../../../assets/infoYellow.svg';
import './loaderModal.css';
import { SocketContext } from '../../../context/SocketContext';
interface Labels {
  showData?: boolean | true;
  setIsEmptyData?: any;
  //   handleLogout: (e: any) => void;
}

export const ModalEvaluationNotCreated: FC<Labels> = ({ showData, setIsEmptyData }) => {
  const { socket, idRoom, setIdRoom } = useContext(SocketContext);

  const handleLogout = (e?: any) => {
    e.preventDefault();

    sessionStorage.removeItem('token');
    sessionStorage.removeItem('LDBCOLLADO');

    localStorage.removeItem('principalFilters');
    localStorage.removeItem('tableFilters');
    localStorage.removeItem('periodId');
    localStorage.removeItem('pageLocalStorage');
    if (idRoom) {
      socket?.emit('leave-room', idRoom);
      setIdRoom(undefined);
    }
    setTimeout(() => {
      window.location.href = 'https://collado.com.mx/';
    }, 1000);
  };
  return (
    <ContainerModal>
      <>
        <Modal>
          <div className='textModal'>
            <img className='image' src={CautionIcon} alt='AlertModalIcon' />
            Aviso
          </div>
          <TextModal>
            No existe una evaluación creada para el periodo actual. Por favor, solicita a tu 
            evaluador que la cree o haz clic en "Continuar" para seleccionar otro periodo.
          </TextModal>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: "100%", marginTop: '20px', }}>

            <Link
              to='/Default.aspx'
              onClick={(e) => handleLogout(e)}
              style={{
                textDecoration: 'none',
                background: 'rgba(59, 99, 162, 1)',
                fontSize: '14px',
                fontWeight: '700',
                color: 'white',
                padding: '10px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '8px',

                maxWidth: '250px',
              }}
            >
              Cerrar sesión
            </Link>
            <ButtonContinuar onClick={() => setIsEmptyData(false)}>
              Continuar
            </ButtonContinuar>
          </div>
        </Modal>
      </>
    </ContainerModal>
  );
};

export default ModalEvaluationNotCreated;

const ButtonContinuar = styled.button`
  border: none;
  padding: 8px 24px;
  border-radius: 8px;
  background-color: #C9F2FF;
  color: #3B63A2;
  //styleName: Small Body Bold;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  width: 50%;
  /* height: 16px; */
`;

const ContainerModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
  display: flex;
`;

export const Modal = styled.div`
  max-width: 600px;
  /* height: 400px; */
  border-radius: 8px;
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 32px;

  .imgModal {
    height: 20px;
    width: 20px;
    /* border: 1px solid red; */
  }
  .textModal {
    //styleName: Title;

    width: 100%;
    gap: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid red; */
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #3b63a2;
    margin: 0px;
    margin-bottom: 32px;
  }
`;

export const TextModal = styled.div`
  //styleName: Body Bold;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;

  //styleName: Body;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;
