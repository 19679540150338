import { useEffect, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "./useSemesterYearNow";

export const useEditFutureObjetive = () => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();
  //** Hooks

  //** States
  const [editeFutureObjetive, setEditeFutureObjetive] = useState(true);

  useEffect(() => {
    //**  +++++++++++++++++++++++++++ EVALUACIÓN +++++++++++++++++++++++
    if (User.role === "Auditor") {
      setEditeFutureObjetive(true); //! Inhabilita
    }

    if (User.role === "Evaluado" && (Evaluation.catStatusEvaluationId === 3 || Evaluation.catStatusEvaluationId === 4)) {
      setEditeFutureObjetive(false);  //! Habilita
    } else if (User.role === "Evaluado" ) setEditeFutureObjetive(true); //! Inhabilita

    if (User.role !== "Auditor" && User.role !== "Evaluado") {
      if (!urlPermisos) {
        if (
          Evaluation.catStatusEvaluationId > 0 &&
          Evaluation.catStatusEvaluationId < 6
        ) {
          setEditeFutureObjetive(false); //! Habilita
        }

        if (Evaluation.catStatusEvaluationId === 7) {
          if (isActualDate) {
            if (evaluationAttribute.edit_evaluation_complete === 1) {
              setEditeFutureObjetive(false); //! Habilita
            }

            if (evaluationAttribute.edit_evaluation_complete === 0) {
              setEditeFutureObjetive(true); //! Inhabilita
            }
          } else {
            setEditeFutureObjetive(true); //! Inhabilita
          }
        }
      }

      //**  +++++++++++++++++++++++++++   AUTOEVALUACIÓN +++++++++++++++++++++++
      if (urlPermisos) {
        if (
          Evaluation.catStatusEvaluationId > 0 &&
          Evaluation.catStatusEvaluationId < 6
        ) {
          setEditeFutureObjetive(false); //Se habilita
        }

        if (Evaluation.catStatusEvaluationId === 6) {
          setEditeFutureObjetive(true); //Se inhabilita
        }

        if (
          Evaluation.catStatusEvaluationId === 7 &&
          evaluationAttribute.edit_evaluation_complete === 1
        ) {
          setEditeFutureObjetive(false); //Se habilita
        }

        if (
          Evaluation.catStatusEvaluationId === 7 &&
          evaluationAttribute.edit_evaluation_complete === 0
        ) {
          setEditeFutureObjetive(true); //Se inhabilita
        }
      }
    }
  }, [
    Evaluation.catStatusEvaluationId,
    User.role,
    evaluationAttribute.edit_evaluation_complete,
    isActualDate,
    urlPermisos,
  ]);

  return {
    editeFutureObjetive,
  };
};

/*
evaluationAttribute.edit_evaluation_complete === 1 


      (User.role === "Admin TI" ||
      User.role === "Admin" ||
      User.role === "Admin RH")
*/

//? Si el usuario tiene habilitado el atributo
//? Si el usuario pertenece a alguno de estos roles
//? Si el usuario está en una evaluación
//? entonces esta sección se podrá editar
