import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import { TableHeadDos } from "../stylesDashboard/AdminStyles";

const HeaderTableUserDashboard = () => {
    //* REDUX
    const { evaluationAttribute, User } = useCollaboratorRedux();



    return (
        <>
            <TableHeadDos>
                <div className="first">Nombre</div>
                {evaluationAttribute.change_status_evaluation === 1 ? (
                    <div className="second">Estatus</div>
                ) : null}
                {evaluationAttribute.change_status_evaluation === 0 ? (
                    <div className="second">Estatus</div>
                ) : null}
                {evaluationAttribute.status_managed === 1 ? (
                    <div className="third">Acción</div>
                ) : null}

                {evaluationAttribute.action_global_calification === 1 && (
                    <div className="fourth">Clasificación</div>
                )}

                {/* {objStatusSemaforo?.length > 0 && (
                    <div className="fifth">Desactivar autoevaluación</div>
                )} */}

                {User.role !== "Auditor" &&
                    <div  className="fifth">

                        <div>
                            Estatus
                        </div>
                        <div>
                        autoevaluación
                        </div>
                    </div>
                }

                {evaluationAttribute.action_define_administrator === 1 && (
                    <div className="sixth">Permisos</div>
                )}
                {User.role !== "Auditor" &&
                    <div className="seventh">Opciones</div>
                }

            </TableHeadDos>
        </>
    );
};

export default HeaderTableUserDashboard;
