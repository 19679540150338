import styled from "styled-components";

type PropTypeBgTres = {
  marginBtm: any;
};

export const RowBox = { display: "flex", justifyContent: "space-between" };
export const BoxMobileForm = {
  display: "block",
  justifyContent: "space-between",
};
export const ColorBall = { background: "#3B63A2" };
export const CenterColumnTable = { padding: "auto" };

export const EditContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 10px;
`;

export const EditObjectiveContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  padding: 8px 16px;
  width: 85%;
`;

export const EditAreasContainer = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  padding: 8px 16px;
  width: 100%;
  min-height: 41.6px;
`;

export const ContainerDescription = styled.div`
  width: 340px;
  background: #ffffff;
  border-radius: 8px;
`;

export const ContainerDescriptionAreas = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
`;

export const TitleTable = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #1f2a40;
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #939396;
`;

export const SelectDireccion = {
  flexDirection: "row",
  alignItems: "center",
  padding: "6px 8px",
  gap: "4px",
  isolation: "isolate",
  width: "auto",
  height: "32px",
  background: "#3B63A2",
  borderRadius: "100px",
};
export const BackColor = {
  background: "#C9F2FF",
  borderRadius: "16px 16px 0px 0px",
  padding: "0px",
};
export const Th = {
  width: "45%",
};

export const ButtonTable = {
  justifyContent: "center",
  alignItems: "center",
  padding: "8px 24px",
  // gap: "8px",
  width: "auto",
  // height: "-webkit-fill-available",
  background: "#C9F2FF",
  borderRadius: "8px",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "16px",
  display: "flex",
  color: "#3B63A2",
  border: "none"

};

export const RowTab = styled.div`
  margin-left: 1%;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  box-sizing: border-box;
  display: block;
  --bs-gutter-x: -1.5rem;
  --bs-gutter-y: 0;
`;
export const ContainOrganigrama = styled.div`
  display: flex;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 0px;
  }
`;
export const Pendiente = styled.div`
  /* dot */

  width: 16px;
  height: 16px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  /* Vector */

  position: absolute;
  left: 31.25%;
  right: 31.25%;
  top: 31.25%;
  bottom: 31.25%;

  /* Neutrals/Taupe Gray */
  background: #939396;
`;

export const BoxMobile = styled.div`
  display: flex;
  gap: 14px;
  @media (min-width: 320px) and (max-width: 1023px) {
    display: block;
  }
`;

export const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto 12px;
`;

export const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
`;

export const ButtonsBox = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  @media (min-width: 320px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const AdminContainer = styled.div`
  padding: 0;
  background-color: #f0f0f0;
  margin: 0 auto;
  width: 83vw;
  padding: 24px 24px;
  /* height: 100vh; */
  margin-bottom: 120px;

  /* border: 1px solid red; */
  /* div::-webkit-scrollbar {
    display: none;
    background-color: transparent;
  }

  div:hover::-webkit-scrollbar {
    display: initial;
  }

  div::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  div:hover::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  div::-webkit-scrollbar {
    display: none;
  } */

  @media screen and (max-width: 1024px) {
    padding: 0px;
    background-color: #f0f0f0;
    /* background-color: #ffff; */
  }
`;

export const FilterSlectBox = styled.div`
  width: 100%;
  padding: 1rem;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 142px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  @media screen and (max-width: 1023px) {
    display: block;
    /* padding: 0rem; */
  }
`;

export const FliterByTiemRow = styled.div`
  display: flex;
  margin-left: 0.3%;
  align-items: center;
  gap: 0.5rem;
  //width: 65%;
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;
export const FliterWrapper = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`
  width: 100%;
  margin-bottom: ${(props) => props.marginBtm || "40px"};
  /* justify-content: center; */
  /* border: 1px solid red; */
  /* border: 1px solid #f0f0f0; */
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;
export const FliterWrapperDos = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`
  width: 100%;
  margin-bottom: ${(props) => props.marginBtm || "40px"};
  /* justify-content: center; */
  /* border: 1px solid red; */
  /* border: 1px solid #f0f0f0; */
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const FilterByTimeBox = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  @media screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const TabOptionsBox = styled.div`
  gap: 8px;
  margin-bottom: 40px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

export const FilterOptionsBox = styled.div`
  /* gap: 8px; */
  padding: 16px;
  background: #ffffff;
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08); */
  border: 1px solid var(--neutrals-platinum, #f0f0f0);
  border-top: none;
  background: var(--neutrals-white, #fff);
  width: 100%;
  @media screen and (max-width: 1023px) {
    padding: 12px;
  }
`;

export const ColaboratorsReportsTitleBox = styled.div`
  margin: 40px 0 16px;
  display: flex;
  align-items: center;
`;

export const ColaboratorsReportsTitle = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  color: #3b63a2;
  margin: 0;
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: 18px;
  }
`;

export const PreviewColaboratorsText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #939396;
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: 12px;
  }
`;

export const semesterInput = styled.div`
  /* Definir objetivos individuales */

  position: absolute;
  width: 289.57px;
  height: 22px;
  left: 1.65px;
  top: 0px;

  /* Small Title */
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  /* identical to box height, or 22px */

  /* Secondary/Space Blue */
  color: #1f2a40;
`;

export const ButtonColBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #3b63a2;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  font-family: "Plus Jakarta Sans";
  margin-bottom: 5px;

  .first {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  background-color: #656569;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 32px 24px;
  font-family: "Plus Jakarta Sans";
  margin-bottom: 5px;

  .first {
    width: 80%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  .second {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TableDos = styled.div`
  color: #ffffff;
  background-color: #3b63a2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  gap: 32px;
  width: 100%;
`;

export const BodyTableWrapper = styled.div`
  overflow-x: auto;
`;

export const BodyTableWrapperWrap = styled.div`
  min-width: 1200px;
`;

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 48px;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;

  .first {
    width: 80%;
    display: flex;
    align-items: center;
  }

  .second {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;
export const TableRowDos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f8f8f8;
  height: 60px;
  font-weight: 700;
  width: auto;
  padding: 16px 8px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  min-width: 2150px;

  .first {
    width: 44%;
    display: flex;
    align-items: flex-start;
    /* border: 1px solid red; */
    /* padding-right: 20px; */
  }

  .second {
    width: 12%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .third {
    width: 8%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    /* border: 1px solid red; */
    padding-left: 10px;
  }

  .fourth {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */

    .css-1gdrain-MuiFormControlLabel-root {
      margin: 0px;
    }

    .css-ahj2mt-MuiTypography-root {
      font-size: 14px;
      font-family: "Plus Jakarta Sans";
    }
  }

  .fifth {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .sixth {
    width: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .seventh {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TableHeadDos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #656569;
  height: 80px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: auto;
  padding: 16px 8px;
  font-family: "Plus Jakarta Sans";
  margin-bottom: 5px;
  min-width: 2150px;

  .first {
    width: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .second {
    width: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
  }

  .third {
    width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fifth {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .sixth {
    width: 9%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .seventh {
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

`;

export const ContainerSelects = styled.tr`
  display: flex;
  align-items: center;
  width: 60%;
  @media (min-width: 320px) and (max-width: 1023px) {
    display: block;
    width: 100%;
    gap: 14px;
  }
`;
export const ContainerSelectsMobile = styled.div`
  display: flex;
  width: 50%;
  @media (min-width: 320px) and (max-width: 1023px) {
    margin-bottom: 4%;
    display: flex;
    width: -webkit-fill-available;
  }
`;
export const ContainerTable = styled.div`
  background: #ffffff;
  overflow-x: auto;
  font-family: Plus Jakarta Sans;
  width: 100%;
  /* border: 1px solid red; */
`;

export const ContainerTablePerido = styled.tr`
  margin-top: 24px;
  height: auto;
  overflow: auto;
  background: #ffffff;
  border-radius: 16px;
  width: 200%;
`;

export const ContainerTableFixed = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /* flex-wrap: wrap; */
`;
export const ContainerTableFixedDos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

  /* flex-wrap: wrap; */
`;

export const ContainPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;
export const BackWhite = styled.div`
  padding: 24px;
  background: #ffffff;
  margin-top: 24px;

  /* border-radius: 16px; */

  /* @media screen and (max-width: 1024px) {
    padding: 0px;
    background-color: #ffff;
  } */
`;
export const BackWhiteQ = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  /* padding: 24px; */
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
`;
export const StyleButonsActions = {
  display: "flex",
  justifyContent: "space-around",
  gap: "5%",
  width: "100%",
};
export const AdminContainerFixes = styled.div`
  padding: 24px 86px 24px 24px;
  background-color: #f4fcff;
  margin: 0 auto;

  /* max-width: 1440px; */

  .gapEelements {
    margin-right: 8px;
  }
  @media (min-width: 320px) and (max-width: 1023px) {
    padding: 24px;
    background-color: white;
  }

  @media screen and (min-width: 770px) {
    padding: 24px 87px 24px 24px;
  }
  @media screen and (min-width: 990px) {
    padding: 24px 628px 24px 24px;
  }
`;
export const AdminContainerFixesP = styled.div`
  padding: 24px 196px 24px 24px;
  background-color: #f4fcff;
  margin: 0 auto;

  /* max-width: 1440px; */

  .gapEelements {
    margin-right: 8px;
  }
  @media (min-width: 320px) and (max-width: 1023px) {
    padding: 24px 24px 24px 24px;
    background-color: white;
  }

  @media screen and (min-width: 770px) {
    padding: 24px 87px 24px 24px;
  }
  @media screen and (min-width: 990px) {
    padding: 24px 862px 24px 24px;
  }
`;
export const BackWhitePeriodos = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  @media screen and (min-width: 990px) {
    width: 185%;
  }
`;
export const ContainPeriodoFix = styled.div`
  margin: 0rem 0 2rem 0;
  width: 150%;
  @media screen and (min-width: 320px) and (max-width: 990px) {
    width: -webkit-fill-available;
  }
  @media screen and (min-width: 991px) and (max-width: 1198px) {
    width: 150%;
  }
`;

export const ContainButonsHeadrEva = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-betwen;
  gap: 14px;
  /* width: auto; */
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColaboratorsReportsRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 20px;
    /* border: 1px solid red; */
  }
`;

export const StyleButonM = {
  background: "#3B63A2",
  borderRadius: "8px",
  fontWeight: " 700",
  fontSize: "16px",
  lineHeight: "120%",
  color: "#FFFFF",
  cursor: "pointer",
  maxWidth: "188px",
  margin: "0.5rem",
};

export const StyleButonBM = {
  padding: "8px 24px",
  gap: "8px",
  background: "#C1C1C3",
  borderRadius: "8px",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "120%",
  color: "#F0F0F0",
  borderStyle: "none",
  cursor: "pointer",
  maxWidth: "188px",
  margin: "0.5rem",
};

export const WrapperPeriodos = styled.div``;

export const WrapperPeriodosHeader = styled.div`
display: flex;
  align-items: center;
  background-color: #656569;
  /* height: 48px; */
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 32px 24px;
  font-family: "Plus Jakarta Sans";
  margin-bottom: 5px;
  //styleName: Small Body Bold;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;

  .first {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fourth {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fifth {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const WrapperPeriodosBody = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 48px;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;

  .first {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fourth {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fifth {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      background-color: #c9f2ff;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #3b63a2;
      border-radius: 8px;
      padding: 4px 26px;
      font-size: 14px;
      font-weight: 700;
      font-family: "Plus Jakarta Sans";
      cursor: pointer;
    }
  }
`;
export const WrapperCuestionario = styled.div``;

export const WrapperCuestionarioHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: #656569;
  /* height: 48px; */
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 32px 24px;
  font-family: "Plus Jakarta Sans";
  margin-bottom: 5px;
  //styleName: Small Body Bold;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;


  .first {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .third {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fourth {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const WrapperCuestionarioBody = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 48px;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  .first {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .third {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fourth {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SelectViewDisabledData = styled.div`
  height: auto;
  padding-left: 6px;
  padding: 6px;

  display: flex;
  font-weight: 400;
  /* border: 1px solid red; */
  justify-content: flex-start;
  align-items: flex-start;
  /* border: red; */

  /* overflow-y: scroll; */
`;

export const SearchInput = styled.select`
  display: flex;
  width: 259px;
  padding: 8px 12px;
  align-items: center;
  gap: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px 0px 0px 8px;
  background: #fff;

  color: #939396;
`;

export const Search = styled.div`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  border-radius: 0px 4px 4px 0px;
  background: #f0f0f0;
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  gap: 0;
  display: flex;
  background-color: #fff;
  margin-top: 20px;
`;
export const OrgChartContainer = styled.div`
  overflow-x: "auto";
  width: 100%;
  .p-organizationchart-lines {
    border-width: 5px !important; /* Cambia este valor al grosor deseado */
    border-color: #ff9900 !important; /* Cambia este valor al color deseado */
  }
`;

export const SemaforoColorGreen = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #10b102;
`;

export const SemaforoColorYellow = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #faba18;
`;
//   width:20px;
//   height:20px;
//   background-color:#FABA18
// `

export const ContainerButtonCambio = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ButtonCambiarStatus = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #c9f2ff;
  background: var(--secondary-blizzard-blue, #c9f2ff);
  color: var(--primary-true-blue, #3b63a2);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;



