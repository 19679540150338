/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { BsTrash } from "react-icons/bs";
import { GrEdit } from "react-icons/gr";
import {
  IGeneralObjetives,
  IIndividualObjetives,
} from "../../../Interface/ApiInterface";
import Caution from "../../../components/organisms/ConfirmationModals/Caution";
import ModalStatusTargetError from "../../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import {
  ButtonContainer,
  RowHead,
} from "../../../pages/Admin/Objectives/ObjetivesStyles";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useObjetives } from "../../../redux/Objetives";
import {
  BodyTableWrapperWrap,
  ContainerTable,
  TableHead,
  TableRow,
} from "../Dashboard/stylesDashboard/AdminStyles";

interface Table {
  data: IGeneralObjetives[] | IIndividualObjetives[];
  type: String;
  setShow: any;
  propValidationFirst?: any;
  evaluation_period_id?: any;
}

export const TableObjetives: React.FC<Table> = ({
  type,
  data,
  setShow,
  propValidationFirst,
  evaluation_period_id,
}) => {
  const {
    handleSetEditObjetive,
    handleDeleteGeneralObjetives,
    handleGetAllGeneralObjetives,
    handleGetAllIndividualObjetives,
    setErrorPost,
    errorPost,
    setSuccesPost,
    succesPost,
  } = useObjetives();
  const [dataToRender, setdataToRender] = useState<
    IGeneralObjetives[] | IIndividualObjetives[]
  >([]);
  const [messageModal, setMessageModal] = useState("");
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [imageError, setImageError] = useState(false);
  const [idPeriodoDelete, setIdPeriodoDelete] = useState(0);
  const { User } = useCollaboratorRedux();

  useEffect(() => {
    setdataToRender(data);
  }, [data]);
  // console.log("Este es el evaluationPeriod: ", evaluation_period_id)
  const deleteObjetiveIndividual = async () => {
    if (type === "I") {
      setIsIndividual(true);
      try {
        if (idPeriodoDelete !== undefined) {
          await handleDeleteGeneralObjetives(idPeriodoDelete);
          // console.log();
          const query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;
          await handleGetAllIndividualObjetives(query);
          setTitleModal("Objetivos Individuales");
          setMessageModal(
            "El objetivo individual fue eliminado correctamente."
          );
          setImageError(false);
        }
      } catch (error) {
        setTitleModal("Objetivos Individuales");
        setMessageModal("El objetivo individual no fue eliminado.");
        setImageError(true);
      }
    }
  };
  const deleteObjetiveGeneral = async () => {
    if (type === "G") {
      try {
        if (idPeriodoDelete !== undefined) {
          await handleDeleteGeneralObjetives(idPeriodoDelete);
          const query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;
          await handleGetAllGeneralObjetives(query);
          setTitleModal("Objetivo eliminado");
          setMessageModal(
            "El objetivo general se ha eliminado correctamente. ."
          );
          setImageError(false);
        }
      } catch (error) {
        setTitleModal("Objetivos generales");
        setMessageModal("El objetivo general no fue eliminado.");
        setImageError(true);
      }
    }
  };
  const handleDeleteObjetivesId = async (e: any, id: number | undefined) => {
    e.preventDefault();
    if (id !== undefined) {
      setIdPeriodoDelete(id);
    }
    setShowModalCaution(true);
  };

  const handleEditObjetives = (
    e: any,
    data: IGeneralObjetives | IIndividualObjetives
  ) => {
    // e.preventDefault();
    handleSetEditObjetive(data);
    const query = `?IdPeriod=${evaluation_period_id}&PageSize=10&PageNumber=1`;
    handleGetAllIndividualObjetives(query);
    handleGetAllGeneralObjetives(query);

    setShow(true);
  };

  // console.log(User.attributeSections);

  return (
    <ContainerTable>
      <Caution
        textBody={"¿Estás seguro de eliminar el objetivo?"}
        textBodyOk={messageModal}
        messageShow={true}
        title="¿Estás seguro?"
        estado={showModalCaution}
        cambiarEstado={setShowModalCaution}
        textButtonAct="Sí, adelante"
        textButtonClose="Cancelar"
        handleClick={
          type === "I" ? deleteObjetiveIndividual : deleteObjetiveGeneral
        }
      />
      <BodyTableWrapperWrap>
        <TableHead>
          <div className="first">Nombre</div>
          {User?.attributeSections[propValidationFirst]?.value === 1 && (
            <div className="second">Acción</div>
          )}
        </TableHead>

        {dataToRender.map((data: any, i) => {
          // console.log("Este es el data: ", data);

          return (
            <TableRow key={data.generalObjetivesId}>
              <div className="first">
                {type === "I"
                  ? data.objetiveName
                  : data.generalObjectivesSentence === null
                  ? data.generalObjetivesInfinitiveVerb +
                    " " +
                    data.generalObjetivesKpiConcept +
                    " " +
                    data.generalObjetviesMathExpression +
                    " " +
                    data.generalObjetivesPreposition +
                    "   " +
                    data.generalObjetivesQuantityIndicator +
                    "  " +
                    data.generalObjetivesAdverb +
                    "  " + 
                    data.fGeneralObjetivesDate +
                    " "
                  : data.generalObjectivesSentence}
              </div>
              {User?.attributeSections[propValidationFirst]?.value === 1 && (
                <div className="second">
                  <ButtonContainer
                    onClick={(e) => {
                      handleEditObjetives(e, data);
                    }}
                  >
                    <GrEdit />
                  </ButtonContainer>
                  <ButtonContainer
                    onClick={(e) => {
                      handleDeleteObjetivesId(e, data.generalObjetivesId);
                    }}
                  >
                    <BsTrash />
                  </ButtonContainer>
                </div>
              )}
            </TableRow>
          );
        })}
        {succesPost ? (
          <ModalStatusSuccessful
            handleClick={setSuccesPost}
            modalStatus={succesPost}
            messageShow
            message={messageModal}
            titleModal="Objetivo eliminado"
          />
        ) : null}
        {errorPost ? (
          <ModalStatusTargetError
            handleClick={setErrorPost}
            modalStatus={errorPost}
            messageShow
            messageDos={messageModal}
          />
        ) : null}
      </BodyTableWrapperWrap>
    </ContainerTable>
  );
};

export const IndividualsTable = () => {
  const dataObjTable = [
    {
      name: "Entregar  / base de datos del proyecto de regularización de planta Santa Bárbara / = / al / 100% / al / 31/12/2022",
      actionEdit: <GrEdit />,
      actionDelete: <BsTrash />,
    },
  ];

  const dataCentered = {
    display: "flex",
    gap: "8px",
    justifyContent: "center",
  };

  return (
    <Table borderless>
      <tbody>
        <RowHead>
          <th>Nombre</th>
          <th style={{ textAlign: "center" }}>Acción</th>
        </RowHead>
        {dataObjTable.map((data) => (
          <>
            <tr>
              <td>{data.name}</td>
              <td style={dataCentered}>
                {data.actionEdit}
                {data.actionDelete}
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </Table>
  );
};
