import { ICurrentsObjetives } from "../../../../Interface/ApiInterface";
import { suma } from "../../../../components/atoms/KpisTables/utils";

export const arrayObjFuture = (dataFuture: any) =>

    dataFuture.map((item: any) => {
        return {
            id: item.employeeObjetivesId,
            meta: item.employeeEvaluationObjetivesGoal,
            valor: item.employeeEvaluationObjetivesWorth,
            description: item.employeeEvaluationObjetivesDescription,
            semestre: item.employeeEvaluationObjetivesSemesterNumber,
            type: item.employeeObjetivesType
        }

    });




export const arrayObjEbita = (
    dataEbita: any,
    valuesFuturesG: any,
    evaluation: any,
    semesterFuture: any,
    idObjetivesG: any
) => {


    //! Arreglo de Objeto Collaborator.employeeEvaluation.employeeEvaluationObjetivesFutures
    //! Trae dos elementos en este array filtrado


    const dataEbitaFiltrada = dataEbita.filter((item: any) => item.employeeObjetivesType === "G")

    // console.log("dataEbitaFiltrada ->", dataEbita)

    const dataEbitaTransformada = valuesFuturesG.map((item: any, i: number) => {

        // console.log("aquí ->", valuesFuturesG[i], dataEbitaFiltrada[i]);

        return {
            employeeEvaluationObjetivesDescription: valuesFuturesG[i]?.description,
            employeeEvaluationObjetivesEvaluationId: evaluation?.employeeEvaluationId,
            employeeEvaluationObjetivesGoal: valuesFuturesG[i]?.meta ?? 0,
            employeeEvaluationObjetivesId: idObjetivesG[i]?.id ?? 0,
            employeeEvaluationObjetivesPercentageReach: "0",
            employeeEvaluationObjetivesReal: 0,
            employeeEvaluationObjetivesResult: 0,
            employeeEvaluationObjetivesSemesterNumber: semesterFuture,
            employeeEvaluationObjetivesWorth: Number(valuesFuturesG[i]?.valor) ?? 0,
            employeeObjetivesId: Number(valuesFuturesG[i]?.id) ?? 0,
            employeeObjetivesType: "G",

        }
    })


    return dataEbitaTransformada;

}


export const arrayObjCurrents = (
    currents: any,
    objetivesCurrents: any,
    evaluation: any,
    sumaTotal: any
) =>

    currents.map((item: any, index: number) => {

        // console.log("currents item ->", item, index, objetivesCurrents[index], sumaTotal[index]);

        if (item?.employeeEvaluationObjetivesDescription === "Desempeño laboral, humano, actitud y habilidades") {
            return {
                employeeEvaluationObjetivesDescription:
                    item?.employeeEvaluationObjetivesDescription,
                employeeEvaluationObjetivesEvaluationId:
                    evaluation.employeeEvaluationId,
                employeeEvaluationObjetivesGoal:
                    item?.employeeEvaluationObjetivesGoal,
                employeeEvaluationObjetivesId:
                    item?.employeeEvaluationObjetivesId,
                employeeEvaluationObjetivesPercentageReach: `${item?.employeeEvaluationObjetivesPercentageReach}` ?? "0",
                employeeEvaluationObjetivesReal: `${item?.employeeEvaluationObjetivesPercentageReach}` ?? "0",
                // employeeEvaluationObjetivesResult: Math.round(
                //     item?.employeeEvaluationObjetivesResult
                // ),
                employeeEvaluationObjetivesResult: sumaTotal[index].valor ?? 0,
                employeeEvaluationObjetivesSemesterNumber:
                    item?.employeeEvaluationObjetivesSemesterNumber,
                employeeEvaluationObjetivesWorth:
                    item?.employeeEvaluationObjetivesWorth,
                employeeObjetivesId: item?.employeeObjetivesId,
                employeeObjetivesType: item?.employeeObjetivesType,
            }
        } else if (item?.employeeEvaluationObjetivesDescription === "Conocimiento de las políticas de la empresa") {
            return {
                employeeEvaluationObjetivesDescription:
                    item?.employeeEvaluationObjetivesDescription,
                employeeEvaluationObjetivesEvaluationId:
                    evaluation.employeeEvaluationId,
                employeeEvaluationObjetivesGoal:
                    item?.employeeEvaluationObjetivesGoal,
                employeeEvaluationObjetivesId:
                    item?.employeeEvaluationObjetivesId,
                employeeEvaluationObjetivesPercentageReach: `${item?.employeeEvaluationObjetivesPercentageReach}` ?? "0",
                employeeEvaluationObjetivesReal: `${item?.employeeEvaluationObjetivesPercentageReach}` ?? "0",
                // employeeEvaluationObjetivesResult: Math.round(
                //     item?.employeeEvaluationObjetivesResult
                // ),
                employeeEvaluationObjetivesResult: sumaTotal[index].valor ?? 0,
                employeeEvaluationObjetivesSemesterNumber:
                    item?.employeeEvaluationObjetivesSemesterNumber,
                employeeEvaluationObjetivesWorth:
                    item?.employeeEvaluationObjetivesWorth,
                employeeObjetivesId: item?.employeeObjetivesId,
                employeeObjetivesType: item?.employeeObjetivesType,
            }
        } else {
            return {
                employeeEvaluationObjetivesDescription:
                    item?.employeeEvaluationObjetivesDescription,
                employeeEvaluationObjetivesEvaluationId:
                    evaluation.employeeEvaluationId,
                employeeEvaluationObjetivesGoal:
                    item?.employeeEvaluationObjetivesGoal,
                employeeEvaluationObjetivesId:
                    item?.employeeEvaluationObjetivesId,
                employeeEvaluationObjetivesPercentageReach: `${item?.employeeEvaluationObjetivesPercentageReach}` ?? "0",
                employeeEvaluationObjetivesReal: objetivesCurrents[index] === "" ? null : objetivesCurrents[index],
                // employeeEvaluationObjetivesResult: Math.round(
                //     item?.employeeEvaluationObjetivesResult
                // ),
                employeeEvaluationObjetivesResult: sumaTotal[index].valor ?? 0,
                employeeEvaluationObjetivesSemesterNumber:
                    item?.employeeEvaluationObjetivesSemesterNumber,
                employeeEvaluationObjetivesWorth:
                    item?.employeeEvaluationObjetivesWorth,
                employeeObjetivesId: item?.employeeObjetivesId,
                employeeObjetivesType: item?.employeeObjetivesType,
            }
        }




    })



export const arrayObjDataSaved = (
    futures: any,
    idObjetivesI: any[],
    evaluation: any,
    semesterFuture: any
) => {


    const objFutures = futures.map((item: any, index: number) => {
        if (item?.descripcion && item?.meta) {
            // console.log("Item enviado: ", item);

            return {
                employeeEvaluationObjetivesDescription: item?.descripcion.label,
                employeeEvaluationObjetivesEvaluationId:
                    evaluation?.employeeEvaluationId,
                employeeEvaluationObjetivesGoal: Number(item?.meta),
                employeeEvaluationObjetivesId: idObjetivesI[index].id ?? 0,
                employeeEvaluationObjetivesPercentageReach: item?.employeeEvaluationObjetivesPercentageReach ?? "0",
                employeeEvaluationObjetivesReal: 0,
                employeeEvaluationObjetivesResult: 0,
                employeeEvaluationObjetivesSemesterNumber: semesterFuture,
                employeeEvaluationObjetivesWorth: Number(item?.valor),
                employeeObjetivesId: item?.descripcion.value,
                employeeObjetivesType: "I",
            };
        }

        return {
            employeeEvaluationObjetivesDescription: item?.descripcion.label,
            employeeEvaluationObjetivesEvaluationId:
                evaluation?.employeeEvaluationId,
            employeeEvaluationObjetivesGoal: Number(item?.meta),
            employeeEvaluationObjetivesId: idObjetivesI[index].id ?? 0,
            employeeEvaluationObjetivesPercentageReach: item?.employeeEvaluationObjetivesPercentageReach ?? "0",
            employeeEvaluationObjetivesReal: 0,
            employeeEvaluationObjetivesResult: 0,
            employeeEvaluationObjetivesSemesterNumber: semesterFuture,
            employeeEvaluationObjetivesWorth: Number(item?.valor),
            employeeObjetivesId: item?.descripcion.value,
            employeeObjetivesType: "I",
        };
    });

    return objFutures;
}


export const arrayObjDefault = (
    datosFuturosFiltradosDos: any[],
    evaluation: any,
    allIds: any,
    semesterFuture: any,
    desempenioValue: any
) => {

    const objDefault = datosFuturosFiltradosDos.map((item: any, index: any) => {

        if (item?.description === "Desempeño laboral, humano, actitud y habilidades") {
            return {
                employeeEvaluationObjetivesDescription: item?.description,
                employeeEvaluationObjetivesEvaluationId:
                    evaluation?.employeeEvaluationId,
                employeeEvaluationObjetivesGoal: item?.meta,
                employeeEvaluationObjetivesId: allIds[allIds.length - 1].id ?? 0,
                employeeEvaluationObjetivesPercentageReach: item?.employeeEvaluationObjetivesPercentageReach ? `${item?.employeeEvaluationObjetivesPercentageReach}` : "0",
                // employeeEvaluationObjetivesReal: 0,
                employeeEvaluationObjetivesReal: item?.employeeEvaluationObjetivesPercentageReach ? `${item?.employeeEvaluationObjetivesPercentageReach}` : "0",
                employeeEvaluationObjetivesResult: 0,
                employeeEvaluationObjetivesSemesterNumber: semesterFuture,
                employeeEvaluationObjetivesWorth: Number(desempenioValue),
                employeeObjetivesId: Number(item?.idObjective),
                employeeObjetivesType: "D",
            }
        }

        return {
            employeeEvaluationObjetivesDescription: item?.description,
            employeeEvaluationObjetivesEvaluationId:
                evaluation?.employeeEvaluationId,
            employeeEvaluationObjetivesGoal: Number(item?.meta),
            employeeEvaluationObjetivesId: allIds[allIds.length - 2].id ?? 0,
            employeeEvaluationObjetivesPercentageReach: item?.employeeEvaluationObjetivesPercentageReach ? `${item?.employeeEvaluationObjetivesPercentageReach}` : "0",
            // employeeEvaluationObjetivesReal: 0,
            employeeEvaluationObjetivesReal: item?.employeeEvaluationObjetivesPercentageReach ? `${item?.employeeEvaluationObjetivesPercentageReach}` : "0",
            employeeEvaluationObjetivesResult: 0,
            employeeEvaluationObjetivesSemesterNumber: semesterFuture,
            employeeEvaluationObjetivesWorth: Number(item?.valor) ?? 0,
            employeeObjetivesId: Number(item?.idObjective),
            employeeObjetivesType: "D",
        }

    });

    return objDefault
}

export const EvaluationUpdated = (
    evaluation: any,
    currents: any,
    dataFuture: any,
    oportunidad: any,
    fortalezas: any,
    evaluationNullPolitics: any,
    urlPermisos: any,
    statusEvaluation: any
) => {

    return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationEvaluatedUserId:
            evaluation?.employeeEvaluationEvaluatedUserId,
        employeeEvaluationEvaluatorUserId:
            evaluation?.employeeEvaluationEvaluatorUserId,
        employeeEvaluationEvaluatorCommet:
            evaluation?.employeeEvaluationEvaluatorCommet,
        employeeEvaluationCommetOfTheEvaluated:
            evaluation?.employeeEvaluationCommetOfTheEvaluated,
        evaluationPeriodId: evaluation?.evaluationPeriodId,
        employeeEvaluationPolitics: evaluation?.employeeEvaluationPolitics,
        employeeEvaluationBonusPaid: evaluation?.employeeEvaluationBonusPaid,
        employeEvaluationInterview: evaluation?.employeEvaluationInterview,
        employeEvaluationQuestions: evaluation?.employeEvaluationQuestions,
        employeeEvaluationObjetivesCurrents: currents,
        employeeEvaluationObjetivesFutures: dataFuture,
        employeeEvaluationWorkersOpportunitieAreas: oportunidad,
        employeeEvaluationWorkersStrengths: fortalezas,
        catStatusEvaluationId: urlPermisos ? 0 : statusEvaluation,
        employeeEvaluationFinalScore: evaluation?.employeeEvaluationPolitics !== evaluationNullPolitics ? Math.round(
            suma(
                evaluation?.employeeEvaluationObjetivesCurrents?.map(
                    (item: ICurrentsObjetives) =>
                        +item.employeeEvaluationObjetivesResult
                )
            )
        ) : 0,
        doneAutoevaluation: urlPermisos ? Boolean(true) : evaluation?.doneAutoevaluation
    }
}