import { useEffect, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "../Evaluation/useSemesterYearNow";

export const useAbleFutureObjetivesDesempenio = () => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();
  //** Hooks

  //** States
  const [ableSelectObjetiveDesempenio, setAbleSelectObjetiveDesempenio] =
    useState(true);

  useEffect(() => {
    if (User.role === "Auditor") {
      setAbleSelectObjetiveDesempenio(true);
    }

    if (User.role === "Evaluado") {
      setAbleSelectObjetiveDesempenio(true);
    }

    if (User.role === "Admin TI") {
      //**  +++++++++++++++++++++++++++ EVALUACIÓN +++++++++++++++++++++++
      if (!urlPermisos) {
        if (
          Evaluation.catStatusEvaluationId > 0 &&
          Evaluation.catStatusEvaluationId < 6
        ) {
          setAbleSelectObjetiveDesempenio(false);
        }

        if (
          Evaluation.catStatusEvaluationId === 7 &&
          evaluationAttribute.edit_evaluation_complete === 1 &&
          isActualDate
        ) {
          setAbleSelectObjetiveDesempenio(false);
        }

        if (
          Evaluation.catStatusEvaluationId === 7 &&
          evaluationAttribute.edit_evaluation_complete === 0
        ) {
          setAbleSelectObjetiveDesempenio(true);
        }
      }

      //**  +++++++++++++++++++++++++++   AUTOEVALUACIÓN +++++++++++++++++++++++
      if (urlPermisos) {
        if (
          Evaluation.catStatusEvaluationId > 0 &&
          Evaluation.catStatusEvaluationId < 6
        ) {
          setAbleSelectObjetiveDesempenio(false);
        }

        if (Evaluation.catStatusEvaluationId === 6) {
          setAbleSelectObjetiveDesempenio(true);
        }

        if (
          Evaluation.catStatusEvaluationId === 7 &&
          evaluationAttribute.edit_evaluation_complete === 1
        ) {
          setAbleSelectObjetiveDesempenio(false);
        }

        if (
          Evaluation.catStatusEvaluationId === 7 &&
          evaluationAttribute.edit_evaluation_complete === 0
        ) {
          setAbleSelectObjetiveDesempenio(true);
        }
      }
    } else {
      setAbleSelectObjetiveDesempenio(true);
    }
  }, [
    Evaluation.catStatusEvaluationId,
    User.role,
    evaluationAttribute.edit_evaluation_complete,
    isActualDate,
    urlPermisos,
  ]);

  return {
    ableSelectObjetiveDesempenio,
  };
};

/*
evaluationAttribute.edit_evaluation_complete === 1 


      (User.role === "Admin TI" ||
      User.role === "Admin" ||
      User.role === "Admin RH")
*/

//? Si el usuario tiene habilitado el atributo
//? Si el usuario pertenece a alguno de estos roles
//? Si el usuario está en una evaluación
//? entonces esta sección se podrá editar
