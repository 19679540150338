import styled from "styled-components";

export const flexBox = {
  marginBottom: "8px",
};

export const CenterBox = styled.div`
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BlueBox = styled.div`
  background-color: #fff;
  margin: 0 auto;
  padding: 0 24px;
  width: 83vw;
  /* border: 1px solid #e5e5e5; */

  .gapEelements {
    /* margin-right: 8px; */
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    background-color: white;
    padding: 0 0px;
  }
`;

export const FliterByTiemRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin: 2.5rem 0;

  @media screen and (min-width: 770px) {
    flex-direction: row;
    width: 70%;
  }
`;

export const KpiRow = styled.div`
  display: flex;
  /* gap: 4rem; */
  padding: 16px;
  background: #ffffff;
  width: 100%;
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08); */
  /* border: 1px solid red; */
  flex-direction: column;

  @media screen and (max-width: 1040px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const InputsBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  /* border: 1px solid red; */
  @media screen and (max-width: 1040px) {
    flex-direction: column;
  }
`;

export const ButtonsBox = styled.div`
  /* width: 50%; */
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;

  @media screen and (max-width: 1040px) {
    /* border: 1px solid red; */
    gap: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
`;

export const Title = styled.h1`
//styleName: H4;
//styleName: H4;
font-family: Plus Jakarta Sans;
font-size: 28px;
font-weight: 700;
line-height: 34px;
letter-spacing: 0px;
text-align: left;
color: #3B63A2;


  @media screen and (max-width: 768px) {
    font-size: 20px;
    margin: 1rem 0;
  }
`;

export const RowChart = styled.div`
  justify-content: center;
  --bs-gutter-x: 17.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: scroll;
  /* margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x)); */
  border-radius: 16px;
  width: 100%;
  @media (min-width: 320px) and (max-width: 767px) {
    --bs-gutter-x: 5.5rem;
  }
`;

export const RowBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const RowBoxTres = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  /* border: 1px solid red; */
  /* margin: 0px 24px; */
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const RowBoxDos = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0px;

  /* height: auto; */

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    display: flex;
    align-items: end;
    gap: 8px;
    justify-content: center;
  }
`;
export const TitleSectionDos = styled.h1`
  //styleName: H4;
  font-family: Plus Jakarta Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;

  /* font-size: 16px; */
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const DashboardsBox = styled.div`
  background-color: #ffffff;
  display: flex;
  border-radius: 16px;
`;

export const ImgSize = styled.img`
  width: 40px;
  padding-top: 2rem;
  align-items: flex-start;
  margin-bottom: 3%;
`;

export const TitleSection = styled.h1`
  font-weight: 700;
  line-height: 120%;
  color: #3b63a2;
  margin-bottom: 0;

  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
`;

export const SubTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  color: #939396;

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

export const SubContainer = styled.div`
  /* padding: 24px; */
  font-family: "Plus Jakarta Sans", sans-serif;
  background: #ffffff;
  border-radius: 16px;
  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

export const Row = styled.tr`
  font-size: 14px;
  font-weight: 700;
  background: ${(props) => props.background || "#FFFFF"};
`;

export const RowNew = styled.div`
  /* border: 1px solid #e5e5e5; */
  font-size: 14px;
  /* font-weight: 700; */
  font-family: "Plus Jakarta Sans", sans-serif;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  .title {
    width: 100px;
    height: 35px;
    background-color: #c9f2ff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3b63a2;
    font-size: 16px;
    font-weight: 700;
    border-radius: 16px 16px 0px 0px;
  }
`;

export const ContainerUpload = styled.div`
  padding: 16px 24px;
  gap: 16px;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;

  @media screen and (min-width: 1200px) {
    height: 69px;
    width: 50%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
export const ContainerUploadDos = styled.div`
  /* gap: 16px; */
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  width: 50%;
  flex-direction: row;
  align-items: center;

  padding: 16.5px 24px;
  @media screen and (max-width: 1100px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ContainerUploadPrev = styled.div`
  gap: 16px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  padding: 16px 24px;
  width: 50%;
  flex-direction: row;
  @media screen and (max-width: 1100px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const ContainInputsTab = styled.div`
  border: 1px solid #f0f0f0;
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08); */
  display: flex;
  justify-content: space-between;
  padding: 12px;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
  /* flex-direction: column; */
`;
export const ContainInputs = styled.div`
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;
export const ContainButtons = styled.div`
  /* border: 1px solid red; */
  gap: 8px;
  /* width: auto; */
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 0px;
  }
`;
export const UploadBox = styled.div`
  flex-direction: row;
  margin: 1rem 0;

  @media screen and (min-width: 1024px) {
    width: 100%;
    flex-direction: row;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #3b63a2;
  margin-bottom: 4px;
`;

export const ButtonFile = styled.input`
  width: 157px;
  background: #3b63a2;
  border-radius: 8px;
  cursor: pointer;
  display: none;
  ::before {
    padding: 4px 32px;
    gap: 8px;
    width: 157px;
    height: 33px;
    background: #3b63a2;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border-radius: 8px;
    content: "Seleccionar";
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const ButtonFileDos = styled.input`
  width: 157px;
  background: #3b63a2;
  border-radius: 8px;
  cursor: pointer;
  display: none;
  height: 40px;
  padding: 8px 24px;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const WrapperButton = styled.label`
  background: #3b63a2;
  border-radius: 8px;
  cursor: pointer;
  color: #ffffff;
  padding: 8px 24px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const ButtonFileManual = styled.button`
  gap: 8px;
  width: 157px;
  height: 40px;
  background: #3b63a2;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  ::before {
    padding: 8px 32px;
    gap: 8px;
    width: 157px;
    height: 40px;
    background: #3b63a2;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border-radius: 8px;
    content: "Seleccionar";
    /* position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; */
  }

  input[type="file"] {
    opacity: 0;
    width: 200px;
    height: 32px;
    display: inline-block;
  }
`;

export const ButtonClean = styled.div`
  background-color: #c9f2ff;
  color: #3b63a2;
  /* width: 130px; */
  /* height: 33px; */

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  border-radius: 8px;
  padding: 8px 24px;
  /* @media screen and (min-width: 320px) and (max-width: 768px) {
    width: -webkit-fill-available;
  } */
`;

export const ButtonSelect = styled.div`
  background-color: #3b63a2;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  border-radius: 8px;
  padding: 8px 24px;
`;
export const StyleInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  /* width: fit-content; */

  .containerUnDiv {
    width: 32.33%;
  }
  @media screen and (max-width: 1024px) {
    /* flex-direction: row; */
    flex-direction: column;
    .containerUnDiv {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const GrayButton = styled.button`
  display: flex;

  /* height: 40px; */
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  background: var(--secondary-blizzard-blue, #c9f2ff);
  color: var(--primary-true-blue, #3b63a2);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 19.2px */
  @media screen and (max-width: 1040px) {
    /* border: 1px solid red; */
    /* width: -webkit-fill-available; */
    width: 100%;
  }
`;

export const BlueButton = styled.button`
  display: flex;
  /* height: 40px; */
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: none;
  background: var(--primary-true-blue, #3b63a2);
  color: var(--neutrals-white, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 19.2px */
  @media screen and (max-width: 1040px) {
    /* border: 1px solid red; */
    width: 100%;
  }
`;
export const BlueButtonDos = styled.button`
  /* height: 33px; */
  background: #3b63a2;
  border-radius: 8px;
  color: #ffffff;
  border: none;
  width: auto;
  padding: 0px 24px;
  font-weight: 700;

  @media screen and (max-width: 1040px) {
    /* border: 1px solid red; */
    width: 100%;
  }
`;

export const FilterBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

export const FilterBoxYearSemester = styled.div`
  display: flex;
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  width: 100%;
  /* margin-bottom: 10px; */
`;

export const TitleSelectYearSemester = styled.div`
  height: 36px;
  background-color: #f0f0f0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectYearSemester = styled.select`
  height: 36px;
  border: 1px solid #f0f0f0;
  border-radius: 0px 8px 8px 0px;
  width: 100%;
  padding: 0px 12px;
`;

export const KPIFilterBoxSelect = styled.div`
  display: flex;
  font-family: "Plus Jakarta Sans";
  flex-direction: column;
  margin-bottom: 40px;
  margin-top: 60px;
  width: 100%;
  .title {
    width: 58px;
    border-radius: 16px 16px 0px 0px;
    background: #c9f2ff;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    font-family: "Plus Jakarta Sans";
    color: #3b63a2;
  }
`;

export const KPIFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
  border: 1px solid #f0f0f0;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const PrimaryButton = styled.button`
  background: ${(props) => (props.disabled ? "#C9F2FF" : "#C9F2FF")};
  align-items: center;
  border-radius: 8px;
  max-width: fit-content;
  padding: 0 15px;
  min-height: 38px;
  font-weight: 700;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  color: #3b63a2;
  border: none;
  min-width: 130px;
`;

export const SecondaryButton = styled.button`
  background: ${(props) => (props.disabled ? "" : "#3b63a2")};
  align-items: center;
  border-radius: 8px;
  max-width: fit-content;
  padding: 0 15px;
  min-height: 38px;
  font-weight: 700;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  color: white;
  border: none;
`;

export const ContainerButtonsFiltros = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
`;

export const ArchivosAgregadosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  margin-bottom: 40px;
  border-bottom: 2px solid #f0f0f0;
`;
