import { FC } from "react";
import Close from "../../../assets/icons/CloseModal.svg";
import info from "../../../assets/infoYellow.svg";
import {
  CloseIcon,
  ContainerModal,
  Header,
  ImageIcon,
  ModalBox,
  TextModal,
} from "./ModalStatusStyles";

interface Labels {
  handleClick?: any;
  showData?: boolean | true;
  modalStatus?: boolean;
  message?: string;
  messageShow?: boolean;
  setFooter?: any;
  onClick?: any;
  excelFile?: any;
  rowsNotInsertedResponse?: number;
  titleModal?: any;
}

export const ModalSelectPeriod: FC<Labels> = ({
  // showData,
  handleClick,
  modalStatus,
}) => {
  // console.log(
  //   "handleClick",
  //   // handleClick,
  //   "modalStatus",
  //   modalStatus
  // );
  return (
    <ContainerModal showData={modalStatus}>
      <ModalBox>
        <Header>
          <div>
            <ImageIcon src={info} style={{ marginRight: "12px" }} />
          </div>
          <CloseIcon
            src={Close}
            onClick={() => {
              handleClick(false);
            }}
          />
        </Header>
        <TextModal>Favor de seleccionar un periodo.</TextModal>
      </ModalBox>
    </ContainerModal>
  );
};

export default ModalSelectPeriod;
