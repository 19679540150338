import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { StlyeTextBodyModal } from "../../atoms/EvaluationForms/EvaluationFormsStyles";
import infoIconModal from '../../../assets/icons/infoIconModal.svg'

export const InfoObjetivesModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showWindow, setShowWindow] = useState<boolean>(false);

  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };
  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);
  return (
    <>
      <AiOutlineInfoCircle onClick={handleShow} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Establecer objetivos SIGUIENTE SEMESTRE - OBJETIVOS FIJOS GENERALES
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "justify",
            }}
          >
            <StlyeTextBodyModal style={{ color: "black" }}>
              OBJETIVOS FIJOS GENERALES
              <br></br>Se tendrán que respetar los mismos objetivos fijos ya
              mencionados en el semestre previo.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              1. Desempeño laboral, humano, actitud y habilidades. Todo el
              personal al que se le pueden asignar objetivos medibles y
              cuantificables, deben tener 5% en el valor del objetivo. Solo
              aquellos puestos a los que no se les pueda asignar objetivos
              puntuales, podrán modificar el porcentaje del 5%.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              2. Conocimiento de políticas = 5% fijo para todos los
              colaboradores.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              3. Alcance (EBITDA / Costo de capital) Collado Consolidado de
              julio a diciembre de 2022
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{ color: "black" }}>
              Para colaboradores que pertenezcan a una o más divisiones de
              negocio/planta, adicionalmente tendrán el siguiente objetivo
              también pre establecido:
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              1. Alcance (EBITDA / Costo de capital) vs presupuesto de la
              división o planta a la que pertenece el colaborador, de enero a
              junio de 2023
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              Nota: El personal que contribuya en los resultados de dos o más
              divisiones o plantas calculará el promedio del alcance de estas
            </StlyeTextBodyModal>
          </div>
        </Modal.Body>
        {!showWindow ? (
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              variant="primary"
              onClick={handleClose}
              style={{ background: "#3B63A2" }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleClose}
              style={{ background: "#3B63A2" }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export const ModalFixedTargets = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showWindow, setShowWindow] = useState<boolean>(false);

  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };
  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);
  return (
    <>
      <AiOutlineInfoCircle onClick={handleShow} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            OBJETIVOS FIJOS O ESTANDARIZADOS
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "justify",
            }}
          >
            <StlyeTextBodyModal style={{ color: "black" }}>
            Todos los colaboradores tienen objetivos preestablecidos, sin importar su departamento y tramo de responsabilidad:
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
              1. Desempeño laboral, humano, actitud y habilidades: 
              Representa el 5% del valor del objetivo en la calificación total de los colaboradores 
              a los que se les puedan asignar objetivos medibles  y cuantificables. 
              Aquellos puestos a los que no se les puedan asignar objetivos 
              puntuales se les podrá modificar el valor.{" "}
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
              2. Conocimiento de políticas = 5% fijo para todos los colaboradores.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
              3. Alcance (EBITDA / Costo de capital) Collado Consolidado de julio - diciembre de 20XX
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{ color: "black" }}>
              Para colaboradores que pertenezcan	a una o más	divisiones de negocio/planta, 
              adicionalmente tienen el siguiente objetivo también pre establecido:
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
              1. Alcance (EBITDA / Costo de capital) de la división o planta a la que pertenece el colaborador, 
              de julio a diciembre 20XX{" "}
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
              Nota: El personal que contribuya en los resultados de dos o más divisiones o 
              plantas calculará el promedio del alcance de estas.{" "}
            </StlyeTextBodyModal>
            {/* <StlyeTextBodyModal style={{ color: "black" }}>
            OBJETIVOS ESPECÍFICOS (Semestre a Evaluar):
            </StlyeTextBodyModal> */}
            {/* <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
            En el Semestre (a evaluar ahora), 202X, escribe hasta 3 objetivos específicos 
            para el trabajador, establecidos en el semestre anterior, con sus resultados.
            </StlyeTextBodyModal> */}
            {/* <StlyeTextBodyModal style={{ color: "black" }}>
            Ejemplos de objetivos: 
            </StlyeTextBodyModal> */}
            {/* <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
            Incremento de productividad en un X%, reducir los gastos en $$$, recuperación 
            anticipada de cobranza en un X%, incrementar la rentabilidad en un X%, disminuir 
            de X número de posibles contingencias, disminuir los tiempos de entrega a clientes 
            en 2 horas, incrementar rotación de proveedores en un X%, disminuir las diferencias 
            de inventarios en $$$, etc. 
            </StlyeTextBodyModal> */}
            {/* <StlyeTextBodyModal style={{ color: "black" }}>
            Si el resultado disminuye, incluye en el campo del objetivo las palabras: 
            Disminuir, Reducir, Desperdicio.
            </StlyeTextBodyModal> */}
            <StlyeTextBodyModal style={{ color: "black" }}>
            OBJETIVOS VARIOS:
            </StlyeTextBodyModal>
            OBJETIVOS Semestre [X] [20XX]. 
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
            En esta sección el evaluador debe ingresar los objetivos del evaluado, 
            3 objetivos para divisón y 4 para global para el trabajador que fueron 
            establecidos para el primer semestre del año de 20XX con sus resultados.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{ color: "black" }}>
            Ejemplos de objetivos: :
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
            • Incremento de productividad en un X%, reducir los gastos  en  $$$<br/>
            • Recuperación  anticipada  de  cobranza  en  un  X%<br/>
            • Incrementar  la rentabilidad en un X%<br/>
            • Disminuir de X número de posibles contingencias<br/>
            • Disminuir los tiempos de entrega a clientes en 2 horas<br/>
            • Incrementar rotación de proveedores en un X%<br/>
            • Disminuir las diferencias de inventarios en $$$<br/>
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{ color: "black" }}>
            Para  resultados que disminuyan es necesario incluir en el campo del objetivo las palabras: disminuir, reducir, desperdicio.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{ color: "black" }}>
            Meta, calificación y porcentaje (%) de Alcance:
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
              En la columna “Meta” se deberá incluir el valor del objetivo que fue determinado. 
              Para la columna “Calificación” se tiene calcular el % del resultado alcanzado durante el 
              semestre con datos reales para que en la columna “% Alcance” se calcule de forma automática.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{ color: "black" }}>
            Cuadro de Calificaciones:
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
             Se llena automáticamente con el resultado, ya no es necesario colocar dato alguno.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{fontWeight:"400", color:"black"}}>
              Nota: En caso de revisión por auditoría interna, contraloría o administración 
              y se detecte que  no  hay  los  elementos  o  documentos  que  comprueben  los  
              resultados alcanzados;  el  evaluado  y  el  evaluador  serán  suspendidos  para  
              el  siguiente semestre y automáticamente tendrán cero (0). Al Evaluador se levantará 
              una acta administrativa y recibirá una sanción.
              Todas las calificaciones deben estar soportadas con documentos o elementos que prueben 
              el cumplimiento de los mismos.{" "}
            </StlyeTextBodyModal>

          </div>
        </Modal.Body>
        {!showWindow ? (
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              variant="primary"
              onClick={handleClose}
              style={{ background: "#3B63A2" }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleClose}
              style={{ background: "#3B63A2" }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
export const ModalVariedObjectives = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showWindow, setShowWindow] = useState<boolean>(false);

  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };
  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);
  return (
    <>
      <AiOutlineInfoCircle onClick={handleShow} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Información de Objetivos Variados Este Semestre
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "justify",
            }}
          >
            <StlyeTextBodyModal>
              <span style={{ color: "black" }}>OBJETIVOS: </span>Semestre (a
              evaluar ahora), 202X. Aquí deben escribir hasta 3 objetivos
              específicos para el trabajador que fueron establecidos para el
              semestre anterior con sus resultados. Ejemplos de objetivos:
              incremento de productividad en un X%, reducir los gastos en $$$,
              recuperación anticipada de cobranza en un X%, incrementar la
              rentabilidad en un X%, disminuir de X número de posibles
              contingencias, disminuir los tiempos de entrega a clientes en 2
              horas, incrementar rotación de proveedores en un X%, disminuir las
              diferencias de inventarios en $$$, etc.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal>
              Para resultados que disminuyan es necesario incluir en el campo
              del objetivo las palabras: Disminuir, disminuir, Reducir, reducir,
              Desperdicio, desperdicio.
            </StlyeTextBodyModal>
          </div>
        </Modal.Body>
        {!showWindow ? (
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              variant="primary"
              onClick={handleClose}
              style={{ background: "#3B63A2" }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleClose}
              style={{ background: "#3B63A2" }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
export const ModalPersonalObjectives = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showWindow, setShowWindow] = useState<boolean>(false);

  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };
  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);
  return (
    <>
      <AiOutlineInfoCircle onClick={handleShow} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Establecer objetivos SIGUIENTE SEMESTRE- OBJETIVOS VARIOS PERSONALES
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "justify",
            }}
          >
            <StlyeTextBodyModal style={{ color: "black" }}>
            OBJETIVOS PERSONALES VARIOS
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              En el próximo período, <strong>puedes establecer hasta 3 objetivos variables. 
              Es importante que la suma total del valor en porcentaje de todos los objetivos sea de 100%.</strong>
              <br></br>
              Estos 3 objetivos deben cumplir con las siguientes características: 
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              • Prácticos, realistas y claros.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              • Alcanzables con cierto grado de esfuerzo (retadores).
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              • Deben ser cuantificados o expresados en cifras.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              • Deben cumplirse en el período establecido.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              • Deben corresponder con las estrategias de Collado y sus áreas.
            </StlyeTextBodyModal>
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
              • NO deben corresponder a actividades rutinarias del puesto del
              trabajador o para lo cual fue contratado. Ejemplo: Llegar temprano
              todos los días; entregar a tiempo sus reportes, etc. 
              {/* (Ver lámina
              de Establecimiento Objetivos SMART).{" "} */}
            </StlyeTextBodyModal>
            {/* <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
            Además, para colaboradores que pertenezcan a una o más divisiones de 
            negocio/planta, tienen el siguiente objetivo también preestablecido.
            </StlyeTextBodyModal> */}
            {/* <StlyeTextBodyModal style={{ color: "black" }}>
            Recuerda anotar el lugar y la fecha que se encuentra al calce del documento.
            </StlyeTextBodyModal> */}
            <StlyeTextBodyModal style={{color:"black", fontWeight:'400'}}>
            ¡Estamos seguros de que con tu compromiso y enfoque, lograrás alcanzar tus objetivos 
            personales y contribuirás al éxito de Collado y su equipo!
            </StlyeTextBodyModal>
          </div>
        </Modal.Body>
        {!showWindow ? (
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              variant="primary"
              onClick={handleClose}
              style={{ background: "#3B63A2" }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleClose}
              style={{ background: "#3B63A2" }}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
