import styled from "styled-components";
type PropTypeBg = {
  isSelected: boolean;
};

export const ContainerTable = styled.div`
  display: flex;
  align-items: start;
  /* border: 1px solid red; */
  /* height: 400px; */
  width: 100%;
  gap: 0.5px;
  flex-direction: column;
  .tabsContainer {
    display: flex;
    align-items: start;
    border-bottom: 1px solid #f0f0f0;
    width: 100%;
    gap: 0.5px;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    margin-bottom: 20px;
    /*  */
  }
`;

export const TabsSelected = styled.div.attrs(
  (props: PropTypeBg) => ({})
)<PropTypeBg>`
  display: flex;
  /* cursor: pointer; */
  /* width: 100px; */
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px 8px 0px 0px;
  background: ${(props) => (props.isSelected ? "#c9f2ff" : "#F0F0F0")};
  color: ${(props) => (props.isSelected ? "#3b63a2" : "#656569")};
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Body Bold */
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

export const PreviewColaboratorsText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #939396;
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: 12px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  .containerToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

export const ColaboratorsReportsTitle = styled.h3`
  color: var(--primary-black, #000);

  /* H3 */
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: 18px;
  }
`;

export const TextTypeReportDownloadReport = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid #f0f0f0;
  font-size: 14px;
`;

export const SelectDonwloadReport = styled.select`
  display: flex;
  width: 100%;
  height: 36px;
  border: 1px solid #f0f0f0;
  border-radius: 0px 8px 8px 0px;
  background: #f0f0f0;
  font-size: 14px;
  outline: none;
  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;
  background-color: #f0f0f0;
  background-position: calc(100% - 0.4rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 100%;
  padding-left: 16px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const CleanButton = styled.button`
  /* width: 100px; */
  padding: 8px 24px;
  border: none;
  background: #c9f2ff;
  color: #656569;
  font-size: 14px;
  font-weight: 700;
  border-radius: 8px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const SelectContainerDownloadReport = styled.div`
  display: flex;
  width: 100%;
  max-width: 294px;
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const SearchSectionDownloadReport = styled.form`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #ffffff;
  width: 100%;
  justify-content: space-between;

  .selectContainer {
    display: flex;
    width: 80%;
    gap: 12.5px;
  }
  @media screen and (max-width: 1024px) {
    .selectContainer {
      display: flex;
      width: 100%;
      gap: 12.5px;
      flex-direction: column;
    }
    flex-direction: column;
  }
`;

export const SelectDonwloadReportDos = styled.select`
  display: flex;
  width: 100%;
  height: 36px;
  border: 1px solid #f0f0f0;
  border-radius: 0px 8px 8px 0px;
  background: #f0f0f0;
  font-size: 14px;
  outline: none;
  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;
  background-color: #f0f0f0;
  background-position: calc(100% - 0.4rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 50%;
  padding-left: 16px;

  @media screen and (max-width: 1024px) {
    width: 81.5%;
  }
`;

export const ContainerTableFixedDos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;

  /* flex-wrap: wrap; */
`;

export const ContainPagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const ContainOrganigrama = styled.div`
  display: flex;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    padding: 0px;
  }
`;

export const ContainerTxt = styled.div`
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;

  /* Title */
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 22px */
  margin-bottom: 24px;
`;
