import { TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import styled from "styled-components";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { useUrlPermisos } from "../../../hooks/Evaluation";
import { useSemesterYearNow } from "../../../hooks/Evaluation/useSemesterYearNow";
import {
  useAbleQualification,
  useAbleQualificationGlobal,
  useAbleQualificationGlobalPlanta,
} from "../../../hooks/Kpis";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import { CardCurrentObj } from "./NewStyles";
import {
  getClassNameBasedOnPercentage,
  porcentajeAlcanceDefault,
  porcentajeDeAlcanceGenerales,
  regexValidateNumber,
  reglasKPIs,
  reglasKPIsDefault,
  suma,
} from "./utils";


const CardTableKpis = ({
  item,
  index,
  handleChange,
  activeOrBlockFirm,
  preguntas,
  loader,
  setSumTotal,
  sumTotal,
  evaluacion,
  sumaDeMetricas,
  setSumaDeMetricas
}: any) => {
  // ** Redux
  const { Evaluation } = useEvaluationRedux();
  const { evaluationAttribute } = useCollaboratorRedux();
  const { isActualDate } = useSemesterYearNow();

  // ** CustomHooks
  const { urlPermisos } = useUrlPermisos();
  const { ableQualification } = useAbleQualification();
  const { ableQualificationGlobal } = useAbleQualificationGlobal();
  const { ableQualificationGlobalPlanta } = useAbleQualificationGlobalPlanta();

  // ** Context
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  //** Context Cambio de periodo
  const {
    totalConocimientoEmpresa,
    changePeriodEvaluation,
    totalSumaMetricas,
  } = useContext(ChangePeriodContext) as ChangePeriodContextType;


  const [loadInput, setLoadInput] = useState(true);
  const [loadInputRender, setLoadInputRender] = useState(true);

  useEffect(() => {
    setLoadInput(false);
    setTimeout(() => {
      setLoadInput(true);
    }, 1500);
  }, [item]);

  useEffect(() => {
    setLoadInputRender(false);
    setTimeout(() => {
      setLoadInputRender(true);
    }, 1500);
  }, []);



  const changeSumasTotales = (valor: any) => {
    let total: number = 0; // Inicializa total como 0



    if (valor.employeeObjetivesType === "D" &&
      valor.employeeEvaluationObjetivesDescription.includes("laboral, humano,")) {



      const porcentajeAlcance = porcentajeAlcanceDefault("Desempenio", sumaDeMetricas);
      total = reglasKPIsDefault(
        valor?.employeeEvaluationObjetivesWorth,
        porcentajeAlcance,
        valor?.employeeObjetivesType,
        valor?.employeeEvaluationObjetivesReal
      );

    } else if (valor.employeeObjetivesType === "D" &&
      valor.employeeEvaluationObjetivesDescription.includes("Conocimiento")) {
      if (totalConocimientoEmpresa) {
        const porcentajeAlcance = porcentajeAlcanceDefault("Empresa", totalConocimientoEmpresa);

        total = reglasKPIsDefault(
          valor?.employeeEvaluationObjetivesWorth,
          porcentajeAlcance,
          valor?.employeeObjetivesType,
          valor?.employeeEvaluationObjetivesReal
        );

      }
    } else if (valor?.employeeObjetivesType !== "D") {
      const porcentajeAlcance = porcentajeAlcanceDefault(
        "Generales",
        valor?.employeeEvaluationObjetivesReal,
        valor?.employeeEvaluationObjetivesGoal
      );

      total = reglasKPIs(
        valor?.employeeEvaluationObjetivesWorth,
        porcentajeAlcance,
        valor?.employeeObjetivesType,
        valor?.employeeEvaluationObjetivesReal
      );

    }


    return isNaN(total) ? 0 : total; // Asegura que nunca se devuelva NaN
  };


  const changeValues = () => {

    const total = changeSumasTotales(item);

    setSumTotal((prevState: any) => {
      // Crea una copia profunda del estado anterior
      const newEval = prevState.map((item: any) => ({ ...item }));

      // Verifica que el índice esté dentro del rango
      if (index >= 0 && index < newEval.length) {
        // Actualiza el valor del elemento en el índice especificado
        newEval[index].valor = total;
      } else {
        console.error('Índice fuera de rango');
      }

      return newEval;
    });
  }

  useEffect(() => {
    setTimeout(() => {
      setSumTotal(
        [...evaluacion?.employeeEvaluationObjetivesCurrents?.map((item: any) => ({ valor: changeSumasTotales(item) }))]
      );
    }, 2000);
  }, [evaluacion]);

  useEffect(() => {
    const sumarMetricas = () => {
      const sumaDeMet =
        (suma(
          preguntas?.map(
            (item: any) => item?.evaluatorEvaluationResponseQuestion
          )
        ) /
          (Evaluation.employeEvaluationQuestions.length * 5)) *
        100;
      return sumaDeMet;
    };
    setSumaDeMetricas(sumarMetricas());
  }, [item]);

  // const changeSumasTotales = (valor: any) => {
  //   let total: number = 0; // Inicializa total como 0



  //   if (valor.employeeObjetivesType === "D" &&
  //     valor.employeeEvaluationObjetivesDescription.includes("laboral, humano,")) {

  //     const porcentajeAlcance = porcentajeAlcanceDefault("Empresa", totalConocimientoEmpresa);


  //     console.log("Porcentaje de Alcance (Empresa):", porcentajeAlcance);

  //     total = reglasKPIsDefault(
  //       valor?.employeeEvaluationObjetivesWorth,
  //       porcentajeAlcance,
  //       valor?.employeeObjetivesType,
  //       valor?.employeeEvaluationObjetivesReal
  //     );

  //     if (isNaN(total)) {
  //       console.log("Salio Nan", total);
  //       console.log("Con estos valores",
  //         valor?.employeeEvaluationObjetivesWorth,
  //         porcentajeAlcance,
  //         valor?.employeeObjetivesType,
  //         valor?.employeeEvaluationObjetivesReal);
  //       console.log("total empresa en NAN", totalConocimientoEmpresa);

  //     }


  //     console.log("Total después de reglasKPIsDefault (laboral, humano):", total);

  //   } else if (valor.employeeObjetivesType === "D" &&
  //     valor.employeeEvaluationObjetivesDescription.includes("Conocimiento")) {
  //     if (totalConocimientoEmpresa) {
  //       const porcentajeAlcance = porcentajeAlcanceDefault("Desempenio", sumaDeMetricas);
  //       console.log("Porcentaje de Alcance (Desempenio):", porcentajeAlcance);

  //       total = reglasKPIsDefault(
  //         valor?.employeeEvaluationObjetivesWorth,
  //         porcentajeAlcance,
  //         valor?.employeeObjetivesType,
  //         valor?.employeeEvaluationObjetivesReal
  //       );

  //       console.log("Total después de reglasKPIsDefault (Conocimiento):", total);
  //     }
  //   } else if (valor?.employeeObjetivesType !== "D") {
  //     const porcentajeAlcance = porcentajeAlcanceDefault(
  //       "Generales",
  //       valor?.employeeEvaluationObjetivesReal,
  //       valor?.employeeEvaluationObjetivesGoal
  //     );
  //     console.log("Porcentaje de Alcance (Generales):", porcentajeAlcance);

  //     total = reglasKPIs(
  //       valor?.employeeEvaluationObjetivesWorth,
  //       porcentajeAlcance,
  //       valor?.employeeObjetivesType,
  //       valor?.employeeEvaluationObjetivesReal
  //     );

  //     console.log("Total después de reglasKPIs:", total);
  //   }



  //   if (isNaN(total)) {
  //     // Verificación final del total antes de devolverlo
  //     console.log("Total final antes de verificar NaN:", total);

  //     // Verificación final del total antes de devolverlo
  //     console.log("Valores NaN:",
  //       valor?.employeeEvaluationObjetivesWorth,
  //       valor?.employeeObjetivesType,
  //       valor?.employeeEvaluationObjetivesReal);
  //   }

  //   return isNaN(total) ? 0 : total; // Asegura que nunca se devuelva NaN
  // };


  // const changeValues = () => {

  //   const total = changeSumasTotales();
  //   console.log('Totaaaaaaaaaaaaal hacemos un cambio', total);

  //   setSumTotal((prevState: any) => {
  //     // Crea una copia profunda del estado anterior
  //     const newEval = prevState.map((item: any) => ({ ...item }));

  //     // Verifica que el índice esté dentro del rango
  //     if (index >= 0 && index < newEval.length) {
  //       // Actualiza el valor del elemento en el índice especificado
  //       newEval[index].valor = total;
  //       console.log('Totaaaaaaaaaaaaal hacemos un cambio', newEval);
  //     } else {
  //       console.error('Índice fuera de rango');
  //     }

  //     return newEval;
  //   });
  // }


  return (
    <CardCurrentObj activeBorder={activeOrBlockFirm}>
      <div className="first">
        {Evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear}
      </div>

      <div className="second">
        {item?.employeeObjetivesType === "G" &&
          `${item?.employeeEvaluationObjetivesWorth}%`}

        {item?.employeeObjetivesType === "I" && (
          <>{item?.employeeEvaluationObjetivesWorth}%</>
        )}

        {item?.employeeObjetivesType === "D" && (
          <>{item?.employeeEvaluationObjetivesWorth}%</>
        )}
      </div>

      <div className="third">
        {item?.employeeEvaluationObjetivesDescription !== null
          ? item?.employeeEvaluationObjetivesDescription
          : ""}
      </div>
      {/* COLUMNA DE META */}
      <div className="fourth">
        {item?.employeeObjetivesType === "G" &&
          `${item?.employeeEvaluationObjetivesGoal} %`}
        {item?.employeeObjetivesType === "I" &&
          `${item?.employeeEvaluationObjetivesGoal} %`}
        {item.employeeObjetivesType === "D" &&
          `${item?.employeeEvaluationObjetivesGoal} %`}
      </div>

      {/* +++++++++++++++++ COLUMNA DE CALIFICACIÓN +++++++++++++++++ */}
      <div className="fifth">
        {item.employeeObjetivesType !== "D" &&
          item.employeeObjetivesType !== "G" && (
            <>
              {loadInput && (
                <ContainerInput>
                  <TextField
                    defaultValue={item?.employeeEvaluationObjetivesReal}
                    onChange={(e) => {
                      if (
                        regexValidateNumber.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        handleChange(e, index, item, true);
                        changeValues();
                      }
                    }}
                    name="employeeEvaluationObjetivesReal"
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled={ableQualification}
                  />
                </ContainerInput>
              )}
            </>
          )}

        {item.employeeObjetivesType === "G" &&
          !item?.employeeEvaluationObjetivesDescription?.includes("planta") && (
            //! Planta
            <>
              {loadInput && (
                <ContainerInput>
                  <TextField
                    defaultValue={item?.employeeEvaluationObjetivesReal}
                    onChange={(e) => {
                      if (
                        regexValidateNumber.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        handleChange(e, index, item, true);
                        changeValues();
                      }
                    }}
                    name="employeeEvaluationObjetivesReal"
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled={ableQualificationGlobal}
                  />
                </ContainerInput>
              )}
            </>
          )}

        {item.employeeObjetivesType === "G" &&
          item?.employeeEvaluationObjetivesDescription?.includes("planta") && (
            //! Planta
            <>
              {loadInput && (
                <ContainerInput>
                  <TextField
                    defaultValue={item?.employeeEvaluationObjetivesReal}
                    onChange={(e) => {
                      if (
                        regexValidateNumber.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        handleChange(e, index, item, true);
                        changeValues();
                      }
                    }}
                    name="employeeEvaluationObjetivesReal"
                    InputProps={{
                      inputComponent: NumericFormatCustom as any,
                    }}
                    disabled={ableQualificationGlobalPlanta}
                  />
                </ContainerInput>
              )}
            </>
          )}
      </div>
      {/* +++++++++++++++++ COLUMNA DE CALIFICACIÓN +++++++++++++++++ */}
      {/* +++++++++++++++++ COLUMNA DE ALCANCE +++++++++++++++++ */}
      <div className="sixth">
        {item?.employeeObjetivesType === "G" && (
          <>
            {porcentajeDeAlcanceGenerales(
              item?.employeeEvaluationObjetivesGoal,
              item?.employeeEvaluationObjetivesReal
            )}
            %
          </>
        )}
        {item.employeeObjetivesType === "I" && (
          <>
            {porcentajeDeAlcanceGenerales(
              +item?.employeeEvaluationObjetivesGoal,
              +item?.employeeEvaluationObjetivesReal
            )}
            %
          </>
        )}
        {item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "Conocimiento"
          ) && (
            <>
              {totalConocimientoEmpresa ? (
                <>
                  {porcentajeAlcanceDefault(
                    "Empresa",
                    totalConocimientoEmpresa
                  )}
                  %{" "}
                </>
              ) : (
                <> 0%</>
              )}
            </>
          )}
        {item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "laboral, humano,"
          ) && <>{porcentajeAlcanceDefault("Desempenio", sumaDeMetricas)}%</>}
      </div>
      {/* +++++++++++++++++ COLUMNA DE ALCANCE +++++++++++++++++ */}

      {/* COLUMNA DE RESULTADO */}
      <div className="seventh">
        {/* <InputGroup className="InputTableResult"> */}
        {item?.employeeObjetivesType !== "D" && (
          <Form.Control
            name="employeeEvaluationObjetivesReal"
            className={`text-align-center ${getClassNameBasedOnPercentage(
              porcentajeAlcanceDefault(
                "Generales",
                item?.employeeEvaluationObjetivesReal,
                item?.employeeEvaluationObjetivesGoal
              )
            )}`}
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            value={reglasKPIs(
              item?.employeeEvaluationObjetivesWorth,
              porcentajeAlcanceDefault(
                "Generales",
                item?.employeeEvaluationObjetivesReal,
                item?.employeeEvaluationObjetivesGoal
              ),
              item?.employeeObjetivesType,
              item?.employeeEvaluationObjetivesReal
            )}
            disabled={true}
          />
        )}

        {item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "Conocimiento"
          ) && (
            <>
              {totalConocimientoEmpresa ? (
                <>
                  {loadInputRender && (
                    <Form.Control
                      name="employeeEvaluationObjetivesReal"
                      className={`text-align-center ${getClassNameBasedOnPercentage(
                        porcentajeAlcanceDefault(
                          "Empresa",
                          totalConocimientoEmpresa
                        )
                      )}`}
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      value={reglasKPIsDefault(
                        item?.employeeEvaluationObjetivesWorth,
                        porcentajeAlcanceDefault(
                          "Empresa",
                          totalConocimientoEmpresa
                        ),
                        item?.employeeObjetivesType,
                        item?.employeeEvaluationObjetivesReal
                      )}
                      disabled={true}
                    />
                  )}
                </>
              ) : (
                <Form.Control
                  name="employeeEvaluationObjetivesReal"
                  className={`text-align-center ${getClassNameBasedOnPercentage(
                    50
                  )}`}
                  aria-label="Default"
                  aria-describedby="inputGroup-sizing-default"
                  value={0}
                  disabled={true}
                />
              )}
            </>
          )}

        {item.employeeObjetivesType === "D" &&
          item.employeeEvaluationObjetivesDescription.includes(
            "laboral, humano,"
          ) && (
            <>
              <Form.Control
                name="employeeEvaluationObjetivesReal"
                className={`text-align-center ${getClassNameBasedOnPercentage(
                  porcentajeAlcanceDefault("Desempenio", sumaDeMetricas)
                )}`}
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                value={reglasKPIsDefault(
                  item?.employeeEvaluationObjetivesWorth,
                  porcentajeAlcanceDefault("Desempenio", sumaDeMetricas),
                  item?.employeeObjetivesType,
                  item?.employeeEvaluationObjetivesReal
                )}
                disabled={true}
              />
            </>
          )}
      </div>
      {/* COLUMNA DE RESULTADO */}
    </CardCurrentObj>
  );
};

export default CardTableKpis;

const ContainerInput = styled.div`
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;
    margin: 0 auto;
    outline: none;
    font-family: Plus Jakarta Sans;
  }
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;
    font-family: Plus Jakarta Sans;
    align-items: center;
    justify-content: center;
    outline: none;
    align-content: center;
  }

  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;
  }

  .MuiInputBase-root {
    outline: none;
    font-family: Plus Jakarta Sans;
  }
`;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        decimalScale={2}
        thousandSeparator
        valueIsNumericString
        prefix=""
        suffix={"%"}
        isAllowed={(values) => {
          const { floatValue } = values;
          //   const MAX_LIMIT = 120;
          if (floatValue) {
            return floatValue <= 120;
          }
          return true;
        }}
      />
    );
  }
);
