import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionEvent } from "../common/utils";
import { RootReducerInterface } from "./store";
import { SkillInterface } from '../Interface/ApiInterface';
import { getSkillServices, postSkillServices, putSkillServices, deleteSkillServices } from "../services/skills.service";
/**
 * Action Types
 */
export enum SkillActions {
  GET_SKILL_SUCCESS = "GET_SKILL_SUCCESS",
  GET_SKILL_ERROR = "GET_SKILL_ERROR",
  POST_SKILL_SUCCESS = "POST_SKILL_SUCCESS",
  POST_SKILL_ERROR = "POST_SKILL_ERROR",
  PUT_SKILL_SUCCESS = "PUT_SKILL_SUCCESS",
  PUT_SKILL_ERROR = "PUT_SKILL_ERROR",
  DELETE_SKILL_SUCCESS = "DELETE_SKILL_SUCCESS",
  DELETE_SKILL_ERROR = "DELETE_SKILL_ERROR",
}

/**
 * Interfaces
 */
interface SkillBaseInterface {
  Skills: SkillInterface[];
  loading: boolean;
}

interface UseSkillInterface extends SkillBaseInterface {
  handleGetSkill: () => void;
  handlePostSkill: (body: SkillInterface) => void;
  handleUpdateSkill: (id: number, body: SkillInterface) => void;
  handleDeleteSkill: (id: number) => void;
}

interface ReducerActionsInterface {
  type: SkillActions;
  payload: SkillBaseInterface;
}

/**
 * Hooks
 */
const useSkillRedux = (): UseSkillInterface => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const {
    Skills,
  } = useSelector((state: RootReducerInterface) => state.Skills);

  const handleGetSkill = async (): Promise<void> => {
    try {
      setLoading(true);
      const { results } = await getSkillServices();
      setLoading(false);
      dispatch(
        actionEvent<SkillActions, SkillBaseInterface>({
          type: SkillActions.GET_SKILL_SUCCESS,
          payload: {
            Skills: results,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<SkillActions, SkillBaseInterface>({
          type: SkillActions.GET_SKILL_ERROR,
        })
      );
    }
  };

  const handlePostSkill = async (body: SkillInterface): Promise<void> => {
    try {
      setLoading(true);
      await postSkillServices(body);
      setLoading(false);

      dispatch(
        actionEvent<SkillActions, SkillBaseInterface>({
          type: SkillActions.POST_SKILL_SUCCESS,
          payload: {
            Skills,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<SkillActions, SkillBaseInterface>({
          type: SkillActions.POST_SKILL_ERROR,
        })
      );
    }
  };

  const handleUpdateSkill = async (id: number, body: SkillInterface): Promise<void> => {
    try {
      setLoading(true);
      await putSkillServices(id, body);
      setLoading(false);

      dispatch(
        actionEvent<SkillActions, SkillBaseInterface>({
          type: SkillActions.PUT_SKILL_SUCCESS,
          payload: {
            Skills,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<SkillActions, SkillBaseInterface>({
          type: SkillActions.PUT_SKILL_ERROR,
        })
      );
    }
  };

  const handleDeleteSkill = async (id: number): Promise<void> => {
    try {
      setLoading(true);
      await deleteSkillServices(id);
      setLoading(false);

      dispatch(
        actionEvent<SkillActions, SkillBaseInterface>({
          type: SkillActions.DELETE_SKILL_SUCCESS,
          payload: {
            Skills,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<SkillActions, SkillBaseInterface>({
          type: SkillActions.DELETE_SKILL_ERROR,
        })
      );
    }
  };

  return {
    loading,
    Skills,
    handleGetSkill,
    handlePostSkill,
    handleUpdateSkill,
    handleDeleteSkill
  };
};

/**
 * Reducers
 */
const initialState: SkillBaseInterface = {
  loading: false,
  Skills: [],
};

const SkillReducer = (
  state = initialState,
  action: ReducerActionsInterface
): SkillBaseInterface => {
  switch (action.type) {
    case SkillActions.GET_SKILL_SUCCESS:
    return {
        ...state,
        Skills: action.payload.Skills,
        loading: action.payload.loading
      };
    default:
      return state;
  }
};

/**
 * Exports
 */
export { SkillReducer, useSkillRedux };
export type { UseSkillInterface, SkillBaseInterface };
