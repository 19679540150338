import { Button, Form, Modal } from "react-bootstrap";
import iconFour from "../../../assets/evaluaition-icons/icon-four.svg";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { ModalFixedTargets, ModalPersonalObjectives } from "./InfoObjetivesModal";
import {
    TitleBox,
    TitleRow
} from "./KpisContainerStyles";
import { PrevTextSectionContainer, TitleBoxSectionContainer, TitleRowKpisSectionContainer, TitleTextGoalValueSection } from "./KpisStylesNew";

export const ObjetivosIndicadoresModalFour = () => {

    //** Redux
    const { evaluationAttribute } = useCollaboratorRedux();

    return (
        <TitleRowKpisSectionContainer>
            <img src={iconFour} alt="indicador"  style={{ marginTop: "5px"}}/>
            <TitleBoxSectionContainer>
                <div className="wrapperTitleSection">
                    <h3 className="titleSection">
                    Objetivos y/o indicadores de desempeño(KPI's){" "}
                    </h3>
                    {evaluationAttribute.show_modal_help_tooltips === 1 && (
                        <span>
                            <ModalFixedTargets />
                        </span>
                    )}
                </div>
                
                <div style={{ display: "flex" }}>
                    <PrevTextSectionContainer>
                        En esta sección, debes establecer <b>3 objetivos para división</b> y <b>4 para global.</b>En la columna "Valor", asigna un porcentaje a cada objetivo, asegurando que la suma total sea 100% al final de la columna.
                    </PrevTextSectionContainer>
                </div>
            </TitleBoxSectionContainer>
        </TitleRowKpisSectionContainer>
    );
};


export const ModalPersonalObjectivesWrapperModal = () => {
    //** Redux
    const { evaluationAttribute } = useCollaboratorRedux();
    return (
        <>
            {evaluationAttribute.define_objectives_next_period === 1 && (
                <TitleRow>
                    <TitleBox
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "1rem",
                        }}
                    >
                        <TitleTextGoalValueSection>
                            Objetivos futuros{" "}
                        </TitleTextGoalValueSection>
                        {evaluationAttribute.show_modal_help_tooltips === 1 && (
                            <ModalPersonalObjectives />
                        )}
                    </TitleBox>
                </TitleRow>)
            }
        </>
    )
}

export const ModalAddObjetive = ({ show, handleClose }: any) => {
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Ingresar Objetivos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Label>Nombre de objetivo</Form.Label>
                    <Form.Control type="email" placeholder="Example" />
                </Form>
                <Form>
                    <Form.Label>Descripción</Form.Label>
                    <Form.Control type="email" placeholder="Example" as="textarea" />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Agregar objetivo
                </Button>
            </Modal.Footer>
        </Modal>
    )
}