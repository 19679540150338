import React from 'react';
import styled from 'styled-components';
//assets
import bell from '../../../../assets/alertaAmarila.svg';
import closeSign from '../../../../assets/closeButton.svg';
import { postSetAdminPermisos } from '../../../../services/dataTable.service';
type PropTypeBg = {
    display: any;
};

const ModalBackground = styled.div.attrs(
    (props: PropTypeBg) => ({})
) <PropTypeBg>`
 display: ${(props) => (props.display ? "flex" : "none")};
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const ModalContent = styled.div`
  display: flex;
  width: 641px;
  padding: 32px;
  flex-direction: column;
  gap: 24px;
  border-radius: 18px;
  background: #FFF;
`;

const ModalButtons = styled.div`

  display: flex;
  gap: 32px;
`;

const ButtonCancel = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #C9F2FF; /* Corrección del estilo de borde */
  background: var(--primary-true-blue, #3B63A2);
  color: var(--primary-true-blue, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const ButtonContinuar = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #C9F2FF; /* Corrección del estilo de borde */
  background: var(--secondary-blizzard-blue, #C9F2FF);
  color: var(--neutrals-white, #3B63A2);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const WarningText = styled.h2`
  color: #3B63A2;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
width: 100%;
  line-height: 120%;
  margin: 0px;
`;

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  
`;

const Campana = styled.img`
  width: 32px;
`;

const TextModal = styled.p`
  color: var(--neutrals-jet, #333335);
font-feature-settings: 'clig' off, 'liga' off;

/* Body */
font-family: Plus Jakarta Sans;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 22.4px */
`

const CloseContainer = styled.div`
width: 100%;
  display: flex;
  justify-content: flex-end; /* Corrección para alinear el icono de cierre a la derecha */
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

interface IbuttonDownload {
    //   onClick: () => void;
    showModalDesactivar: boolean;
    setShowModalDesactivar: React.Dispatch<React.SetStateAction<boolean>>;
    newType: any;
    changeTypeUser: any;
    item: any;

}

const ModalAdvertirPermisos = ({
    showModalDesactivar,
    setShowModalDesactivar,
    changeTypeUser,
    newType,
    item
}: IbuttonDownload) => {

    const upDateAdmin = async (collaborator: any, userRole: any) => {
        const { message } = await postSetAdminPermisos(
            collaborator.claTrab,
            userRole
        );


    };
    const handleCambiarStatus = () => {
        changeTypeUser(newType);
        setShowModalDesactivar(false);
        upDateAdmin(item, newType)
    };

    // console.log("item name", item);

    return (
        <ModalBackground display={showModalDesactivar}>
            <ModalContent>

                <WarningContainer>
                    <Campana src={bell} alt="" />
                    <WarningText>Cambio de permiso</WarningText>
                    <CloseContainer>
                        <CloseIcon onClick={() => setShowModalDesactivar(false)} src={closeSign} alt='' />
                    </CloseContainer>
                </WarningContainer>
                <TextModal>
                    Estás a punto de cambiar los permisos de {item.nombre}.
                </TextModal>
                <TextModal>¿Deseas continuar con esta acción?</TextModal>
                <ModalButtons>
                    <ButtonCancel onClick={() => setShowModalDesactivar(false)}>Cancelar</ButtonCancel>
                    <ButtonContinuar onClick={handleCambiarStatus}>
                        Continuar
                    </ButtonContinuar>
                </ModalButtons>
            </ModalContent>
        </ModalBackground>
    );
};

export default ModalAdvertirPermisos;
