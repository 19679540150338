import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import styled from "styled-components";
import { http } from "../../../api";
import CloseIconVector from "../../../assets/CloseIconVector.svg";
import infoIconVector from "../../../assets/infoIconVector.svg";
import { useCollaboratorRedux } from "../../../redux/Collaborators";

const StatusOb: { [key: number]: string } = {
  1: "Definición de objetivos",
  2: "Nuevo ingreso",
  3: "Pendiente evaluar",
  4: "Pendiente de liberar",
  6: "Pendiente de firmar",
  7: "Completado",
};

function returnDataObj(statusCode: number): string | undefined {
  return StatusOb[statusCode];
}

const ChangeStatusCard = ({
  id,
  open,
  close,
  idSolicitanteStatus,
  aproveSolicitudMutation,
  setDenyOrAprove,
  closeNotifications,
  setUpdateData,
  updateData,
}: {
  id: number | null;
  idSolicitanteStatus: number | null;
  open: boolean;
  close: any;
  aproveSolicitudMutation: any;
  setDenyOrAprove: any;
  closeNotifications: any;
  setUpdateData: any;
  updateData: any;
}) => {
  const queryClient = useQueryClient();
  const [aprove, setAprove] = useState<boolean | null>(null);
  const { User } = useCollaboratorRedux();

  const {
    isLoading,
    isFetching,
    data: infoSolicitud,
    isError,
    error,
  } = useQuery({
    queryFn: async () => {
      const response = await http.get(
        `/Solicitudes/get-request-by-id?SolicitudCambioMasivoId=${id}`
      );
      return response.data;
    },
    queryKey: ["issuesById", id],

    enabled: id !== null && open,
    staleTime: Infinity,
  });

  //   const aproveSolicitudMutation = () => {
  // "solicitudCambioMasivoId": 0,
  // "claTrabAprobado": 0,
  // "aprobado": true
  //   };

  //   const { mutateAsync: aproveSolicitudMutation } = useMutation({
  //     mutationFn: aproveSolicitud,
  //     onSuccess: () => {
  //       queryClient.invalidateQueries({ queryKey: ["issuesById"] });
  //     },
  //   });

  // console.log("infoSolicitud", infoSolicitud?.data);
  return (
    <ContainerModal $open={open}>
      <Container $open={open}>
        <div className="headerTable">
          <div className="containerTitle">
            <img src={infoIconVector} alt="info" />
            Aprobación de cambio de estatus masivo
          </div>

          <img
            className="containerCloseIcon"
            src={CloseIconVector}
            alt="close"
            onClick={() => close(false)}
          />
        </div>
        <div className="subtitle">
          Por favor, revisa la solicitud de{" "}
          <span className="spansub">
            {infoSolicitud?.data?.nombreUsuarioSolicitud
              ? `${infoSolicitud.data.nombreUsuarioSolicitud} ${idSolicitanteStatus}`
              : idSolicitanteStatus}
          </span>{" "}
          detalladamente:
        </div>
        {isError && "...error"}
        {isLoading || isFetching ? (
          "Loading..."
        ) : infoSolicitud?.data != undefined ? (
          <TableContainer>
            <div className="header">
              <div
                style={{
                  background: "rgba(240, 240, 240, 1)",
                  borderRight: "2px solid #3b63a2",
                  borderRadius: "8px 0px 0px 0px",
                }}
                className="dateRow"
              >
                Fecha
              </div>
              <div
                style={{
                  background: "rgba(240, 240, 240, 1)",
                  borderRight: "2px solid #3b63a2",
                }}
                className="idRow"
              >
                ID Solicitante
              </div>
              <div
                style={{
                  background: "rgba(240, 240, 240, 1)",
                  borderRight: "2px solid #3b63a2",
                }}
                className="estatusRow"
              >
                {" "}
                Estatus original
              </div>
              <div
                style={{
                  background: "rgba(240, 240, 240, 1)",
                  borderRight: "2px solid #3b63a2",
                }}
                className="newStatusRow"
              >
                {" "}
                Estatus nuevo
              </div>
              <div
                style={{
                  background: "rgba(240, 240, 240, 1)",
                  borderRight: "2px solid #3b63a2",
                }}
                className="localidadesRow"
              >
                {" "}
                Localidades
              </div>
              <div
                style={{
                  background: "rgba(240, 240, 240, 1)",
                  borderRadius: "0px 8px 0px 0px",
                }}
                className="periodoRow"
              >
                Periodo
              </div>
            </div>
            <div className="body">
              <div
                style={{
                  borderRight: "2px solid #3b63a2",
                }}
                className="dateRow"
              >
                {infoSolicitud.data ? infoSolicitud.data.date : ""}
              </div>
              <div
                style={{
                  borderRight: "2px solid #3b63a2",
                }}
                className="idRow"
              >
                {idSolicitanteStatus}
              </div>
              <div
                style={{
                  borderRight: "2px solid #3b63a2",
                }}
                className="estatusRow"
              >
                {infoSolicitud.data
                  ? returnDataObj(infoSolicitud.data.estatusActual)
                  : ""}
              </div>
              <div
                style={{
                  borderRight: "2px solid #3b63a2",
                }}
                className="newStatusRow"
              >
                {infoSolicitud.data
                  ? returnDataObj(infoSolicitud.data.estatusNuevo)
                  : ""}
              </div>
              <div
                style={{
                  borderRight: "2px solid #3b63a2",
                }}
                className="localidadesRow"
              >
                {" "}
                {infoSolicitud.data && infoSolicitud.data.listLocalidad !== "" ? infoSolicitud.data.listLocalidad : "Todas"}
              </div>
              <div className="periodoRow"
              >
                {infoSolicitud.data
                  ? `${infoSolicitud.data.periodo}`
                  : ""}
              </div>
            </div>
          </TableContainer>
        ) : (
          <TableContainer></TableContainer>
        )}
        <div className="subtitle">¿Deseas aprobar la solicitud?</div>
        <div className="buttonContainer">
          <BtnDenegar
            onClick={async () => {
              await aproveSolicitudMutation({
                solicitudCambioMasivoId:
                  infoSolicitud.data.solicitudCambioMasivoId,
                claTrabAprobado: User.claTrab,
                aprobado: false,
              });
              setDenyOrAprove("Denegar");
              close(false);
              // setTimeout(() => {
              //   closeNotifications(false);
              // }, 2500);
              setTimeout(() => {
                setUpdateData(!updateData);
              }, 1500);
            }}
          >
            Denegar
          </BtnDenegar>
          <BtnAprobar
            onClick={async () => {
              await aproveSolicitudMutation({
                // solicitudCambioMasivoId: infoSolicitud.data.estatusNuevo
                //   ? infoSolicitud.data.estatusNuevo
                //   : 0
                solicitudCambioMasivoId:
                  infoSolicitud.data.solicitudCambioMasivoId,
                claTrabAprobado: User.claTrab,
                aprobado: true,
              });
              setDenyOrAprove("Aprobar");
              close(false);
              // setTimeout(() => {
              //   closeNotifications(false);
              // }, 2500);
              setTimeout(() => {
                setUpdateData(!updateData);
              }, 1500);
            }}
          >
            Aprobar
          </BtnAprobar>
        </div>
      </Container>
    </ContainerModal>
  );
};

export default ChangeStatusCard;

const TableContainer = styled.div`
  margin: 20px auto;
  border: 2px solid #3b63a2;
  border-radius: 8px;
  width: 90%;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: #3b63a2;
  display: flex;
  flex-direction: column;

  .header, .body {
    display: flex;
    flex-direction: row;
  }

  .dateRow,
  .idRow,
  .estatusRow,
  .newStatusRow,
  .localidadesRow,
  .periodoRow {
    padding: 12px 8px;
    flex: 1;
    border-right: 2px solid #3b63a2;
  }

  .header > .dateRow,
  .body > .dateRow {
    border-radius: 8px 0 0 0;
  }

  .header > .periodoRow,
  .body > .periodoRow {
    border-radius: 0 8px 0 0;
    border-right: none;
  }
`;

const ContainerModal = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1;
  display: ${(props) => (props.$open ? "flex" : "none")};
  /* display: flex; */
`;

const Container = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  border-radius: 16px;
  background: var(--background-light-gray, #f8f8f8);
  box-shadow: 0px 4px 8px 0px rgba(6, 17, 46, 0.18);
  width: 640px;
  min-height: 400px;
  height: fit-content;
  display: ${(props) => (props.$open ? "flex" : "none")};

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: white;
  z-index: 2;
  flex-direction: column;
  .containerCloseIcon {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
  .containerList {
    display: flex;
    flex-direction: column;
  }

  .headerTable {
    display: flex;
    height: 50px;
    //styleName: Title;
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    margin: 32px;
    color: rgba(59, 99, 162, 1);
    .containerTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
    }
  }

  .subtitle {
    /* border: 1px solid red; */
    margin: 0px 32px;

    .spansub {
      font-weight: 700;
    }
  }

  .buttonContainer {
    margin: 0px 32px;
    display: flex;
    margin-bottom: 5%;
    justify-content: flex-end;
    gap: 32px;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
    margin-top: 30px;
    /* border: 1px solid red; */
    align-items: center;
    align-content: center;
  }
`;

type PropTypeBgTres = {
  $open?: any;
};

const BtnAprobar = styled.button`
  border: none;
  padding: 10px 24px;
  border-radius: 4px;
  background-color: rgba(59, 99, 162, 1);
  color: white;
  font-weight: 700;
  /* max-height: 40px; */
  /* display: flex;
  justify-content: center; */
`;
const BtnDenegar = styled.button`
  border: none;
  padding: 10px 24px;
  border-radius: 4px;
  color: rgba(59, 99, 162, 1);
  font-weight: 700;
  background-color: rgba(201, 242, 255, 1);
  /* max-height: 40px; */
  /* display: flex;
  justify-content: center; */
`;
