import { useContext, useEffect, useState } from "react";
import { useUrlPermisos } from ".";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../context/EvaluationContext";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useSemesterYearNow } from "./useSemesterYearNow";

export const useAbleFirmsBtnSecond = () => {
  //** Context
  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;
  //* Context
  const { disableActionEvaluationBtn } = useContext(
    EvaluationContext
  ) as EvaluationContextType;
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();

  //** Hooks

  //** States
  const [ableFirmsSecond, setAbleFirmsSecond] = useState(true);

  //** Esta es una evaluación (NO ES MI EVALUACIÓN)

  // console.log(
  //   "Estás en tu periodo actual!",
  //   Evaluation.catStatusEvaluationId,
  //   // !disableActionEvaluationBtn &&
  //   evaluationAttribute.edit_evaluation_complete !== 1,

  // );

  useEffect(() => {
    //**  +++++++++++++++++++++++++++   EVALUACIÓN +++++++++++++++++++++++

    if (User.role === "Auditor") {
      setAbleFirmsSecond(false); //Inhabilita
    }

    if (User.role !== "Auditor" && User.role !== "Evaluado") {
      if (!urlPermisos) {
        if (
          !disableActionEvaluationBtn &&
          evaluationAttribute.edit_evaluation_complete === 1
        ) {
          setAbleFirmsSecond(true); // Habilita
        } else {
          setAbleFirmsSecond(false); //Inhabilita
        }
      }

      if (urlPermisos) {
        if (changePeriodEvaluation) {
          if (
            !disableActionEvaluationBtn &&
            evaluationAttribute.edit_evaluation_complete === 1
          ) {
            setAbleFirmsSecond(true); // Habilita
          } else {
            setAbleFirmsSecond(false); //Inhabilita
          }
        } else {
          setAbleFirmsSecond(false); //Inhabilita
        }
      }
    }

    if (User.role === "Evaluado") {
      if (urlPermisos) {
        if (changePeriodEvaluation) {
          if (!disableActionEvaluationBtn) {
            if (
              Evaluation?.catStatusEvaluationId !== 7 &&
              evaluationAttribute?.edit_evaluation_complete !== 1
            ) {
              setAbleFirmsSecond(true); // Habilita
            }

            if (Evaluation?.catStatusEvaluationId === 7) {
              setAbleFirmsSecond(false); //Inhabilita
            }
          } else {
            setAbleFirmsSecond(false); //Inhabilita
          }
        } else {
          setAbleFirmsSecond(false); //Inhabilita
        }
      }
    }
  }, [
    Evaluation.catStatusEvaluationId,
    urlPermisos,
    evaluationAttribute.edit_column_score,
    evaluationAttribute.edit_evaluation_complete,
    changePeriodEvaluation,
    disableActionEvaluationBtn,
    User.role,
    isActualDate,
  ]);

  return {
    ableFirmsSecond,
  };
};
