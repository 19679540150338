import { useContext, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { instructions } from "../../../DataFake/EvaluationFormsContent";
import {
  HumanFactorResponseInterface,
  IEvaluationResults,
  IOportunities,
  IQuestionInterface,
  IStrenghs,
  JobPerformanceResponseInterface,
  SkillResponseInterface,
} from "../../../Interface/ApiInterface";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { useUrlPermisos } from "../../../hooks/Evaluation";
import { useSemesterYearNow } from "../../../hooks/Evaluation/useSemesterYearNow";
import { useAbleFortalezasDebilidades } from "../../../hooks/FortalezasDebilidades";
import { useAbleInputSelectMetricas } from "../../../hooks/Metricas";
import { ContainerDescriptionAreas } from "../../../pages/Admin/Dashboard/stylesDashboard/AdminStyles";
import EditStrengthsAndOpportunities from "../../../pages/Admin/components/EditStrengthsAndOpportunities";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import {
  AutoEvaluacionContainer,
  CommentContainer,
  ContainerSelect,
  EvaluatedCommentsContainer,
  EvaluationInstructionsHeading,
  EvaluationInstructionsList,
  FormContainer,
  FormTitle,
  NewTitleContainer,
  NumberItem,
  PlusResult,
  QuestionList,
  QuestionListItem,
  Results,
  ResultsContainer,
  SelectContainer,
  SelectOption,
  TextItem,
  TitleWrapper,
} from "./EvaluationFormsStyles";
import {
  EditStrongestModal,
  InfoLaboralDevelopmentModal,
  InfoLaboralDevelopmentModalFactorHumano,
  InfoLaboralDevelopmentModalHabilidades,
  OportunitiesModal,
  SaveEditModal,
  StrongestModal,
} from "./EvaluationModals/EvaluationModals";

const suma = (array: number[]) => {
  let suma = 0;
  for (let i = 0; i < array?.length; i++) {
    suma += array[i];
  }
  return suma;
};

export const EvaluationInstructions = () => {
  return (
    <Container>
      <EvaluationInstructionsHeading>
        <span></span>
        <strong>Evalue del 1 al 5 las siguientes métricas</strong>
        <Button variant="secondary">Guardar Evaluación</Button>
      </EvaluationInstructionsHeading>
      <EvaluationInstructionsList>
        {instructions.map((instruction) => (
          <li>
            <strong>{instruction}</strong>
          </li>
        ))}
      </EvaluationInstructionsList>
    </Container>
  );
};

export const JobPerformanceForm = ({
  results,
  index,
  add,
  setAdd,
  setEvaluation,
  evaluation,
  setAddJobPerformanceEvaluado,
  addJobPerformanceEvaluado,
  changePeriodEvaluation,
}: IQuestionInterface<JobPerformanceResponseInterface[]>) => {
  //* Redux
  const { evaluationAttribute } = useCollaboratorRedux();
  const { User } = useCollaboratorRedux();

  //* Context
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  //* Custom hooks
  const { urlPermisos } = useUrlPermisos();

  const handleChangeResult = (e: any, id: number, user: boolean) => {
    e.preventDefault();

    // console.log("<<HandleChange result ->", id, user);

    if (user) {
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && user) {
          return (item.employeEvaluationResponseQuestion = +e.target.value);
        }
      });

      setEvaluation({
        ...evaluation,
        employeeEvaluationJobPerformance: results,
      });

      setAddJobPerformanceEvaluado(
        suma(
          results?.map(
            (item: JobPerformanceResponseInterface) =>
              item?.employeEvaluationResponseQuestion
          )
        )
      );
    } else {
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && !user) {
          return (item.evaluatorEvaluationResponseQuestion = +e.target.value);
        }
      });

      setEvaluation({
        ...evaluation,
        employeeEvaluationJobPerformance: results,
      });

      setAdd(
        suma(
          results.map(
            (item: JobPerformanceResponseInterface) =>
              item?.evaluatorEvaluationResponseQuestion
          )
        )
      );
    }
  };

  useEffect(() => {
    setAdd(
      suma(
        results?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.evaluatorEvaluationResponseQuestion
        )
      )
    );

    setAddJobPerformanceEvaluado(
      suma(
        results?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.employeEvaluationResponseQuestion
        )
      )
    );
  }, [results, changePeriodEvaluation]);

  //** Custom hook para validar permisos de esta sección
  const { ableInputSelectMetrica, ableInputSelectMetricaEvaluador } =
    useAbleInputSelectMetricas();
  const { Evaluation } = useEvaluationRedux();
  const { isActualDate } = useSemesterYearNow();
  return (
    <FormContainer>
      <FormTitle>
        <TitleWrapper>
          <strong>1.- Desempeño laboral</strong>
          {evaluationAttribute.show_modal_help_tooltips === 1 ? (
            <InfoLaboralDevelopmentModal />
          ) : null}
        </TitleWrapper>
      </FormTitle>
      <AutoEvaluacionContainer>
        <strong className="data">Evaluado</strong>
        <strong className="data">Evaluador</strong>
      </AutoEvaluacionContainer>
      <QuestionList>
        {results?.map((item: JobPerformanceResponseInterface, aux: number) => {
          // console.log(item);
          return (
            <QuestionListItem key={item.question.idQuestion}>
              <TextItem>
                {index + (aux + 1)} .- {item?.question.textQuestion}
              </TextItem>
              <SelectContainer>
                {/* Columna Evaluado */}
                <ContainerSelect colorBtn={ableInputSelectMetrica}>
                  <SelectOption
                    style={{ fontSize: "14px" }}
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        true
                      );
                    }}
                    disabled={ableInputSelectMetrica}
                  >
                    {item?.employeEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
                {/* Columna Evaluador */}
                <ContainerSelect colorBtn={ableInputSelectMetricaEvaluador}>
                  <SelectOption
                    style={{ fontSize: "14px" }}
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        false
                      );
                    }}
                    colorBtn
                    disabled={ableInputSelectMetricaEvaluador}
                  >
                    {item?.evaluatorEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
              </SelectContainer>
            </QuestionListItem>
          );
        })}
      </QuestionList>
      <>
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          !urlPermisos && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{add}</span>
            </PlusResult>
          )}
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId === 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{add}</span>
            </PlusResult>
          )}

        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId !== 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{addJobPerformanceEvaluado}</span>
            </PlusResult>
          )}
      </>
    </FormContainer>
  );
};

export const HumanFactoAttitude = ({
  results,
  index,
  add,
  setAdd,
  setEvaluation,
  evaluation,
  setEvaluator,
  evaluator,
  addHumanFactorEvaluador,
  setAddHumanFactorEvaluador,
}: IQuestionInterface<HumanFactorResponseInterface[]>) => {
  const { User } = useCollaboratorRedux();
  const { urlPermisos } = useUrlPermisos();

  //** Context Cambio de periodo
  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  const handleChangeResult = (e: any, id: number, user: boolean) => {
    e.preventDefault();
    if (user) {
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && user) {
          return (item.employeEvaluationResponseQuestion = +e.target.value);
        }
      });

      setEvaluation({
        ...evaluation,
        employeeEvaluationHumanFactor: results,
      });
      setAddHumanFactorEvaluador(
        suma(
          results?.map(
            (item: JobPerformanceResponseInterface) =>
              item?.employeEvaluationResponseQuestion
          )
        )
      );
    } else {
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && !user) {
          return (item.evaluatorEvaluationResponseQuestion = +e.target.value);
        }
      });

      setEvaluation({
        ...evaluation,
        employeeEvaluationHumanFactor: results,
      });
      setAdd(
        suma(
          results.map(
            (item: HumanFactorResponseInterface) =>
              item?.evaluatorEvaluationResponseQuestion
          )
        )
      );
    }
  };

  useEffect(() => {
    setAdd(
      suma(
        results?.map(
          (item: HumanFactorResponseInterface) =>
            item?.evaluatorEvaluationResponseQuestion
        )
      )
    );
    setAddHumanFactorEvaluador(
      suma(
        results?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.employeEvaluationResponseQuestion
        )
      )
    );
  }, [results, changePeriodEvaluation]);

  const { evaluationAttribute } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  //** Custom hook para validar permisos de esta sección
  const { ableInputSelectMetrica, ableInputSelectMetricaEvaluador } =
    useAbleInputSelectMetricas();
  const { isActualDate } = useSemesterYearNow();
  return (
    <FormContainer className="flex-item">
      <FormTitle>
        <TitleWrapper>
          <strong>2.- Factor Humano / Actitud</strong>
          {evaluationAttribute.show_modal_help_tooltips === 1 ? (
            <InfoLaboralDevelopmentModalFactorHumano />
          ) : null}
        </TitleWrapper>
      </FormTitle>
      <AutoEvaluacionContainer>
        <strong className="data">Evaluado</strong>
        <strong className="data">Evaluador</strong>
      </AutoEvaluacionContainer>
      <QuestionList>
        {results?.map((item: HumanFactorResponseInterface, aux: number) => {
          // console.log(item);
          return (
            <QuestionListItem key={item.question.idQuestion}>
              <TextItem>
                {index + (aux + 1)} .- {item?.question.textQuestion}
              </TextItem>
              <SelectContainer>
                {/* Columna Evaluado */}
                <ContainerSelect colorBtn={ableInputSelectMetrica}>
                  <SelectOption
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        true
                      );
                    }}
                    disabled={ableInputSelectMetrica}
                  >
                    {item?.employeEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}

                    {item?.employeEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}

                    {item?.employeEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
                {/* Columna Evaluador */}
                <ContainerSelect colorBtn={ableInputSelectMetricaEvaluador}>
                  <SelectOption
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        false
                      );
                    }}
                    disabled={ableInputSelectMetricaEvaluador}
                  >
                    {item?.evaluatorEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
              </SelectContainer>
            </QuestionListItem>
          );
        })}
      </QuestionList>
      <>
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          !urlPermisos && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{add}</span>
            </PlusResult>
          )}
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId === 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">
                {/* {changePeriodEvaluation ? addHumanFactorEvaluador : "hola"} */}
                {add}
              </span>
            </PlusResult>
          )}

        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId !== 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{addHumanFactorEvaluador}</span>
            </PlusResult>
          )}
      </>
    </FormContainer>
  );
};

export const SkillsList = ({
  results,
  index,
  add,
  setAdd,
  setEvaluation,
  evaluation,
  setEvaluator,
  evaluator,
  addSkillsEvaluado,
  setAddSkillsEvaluador,
}: IQuestionInterface<SkillResponseInterface[]>) => {
  const { User } = useCollaboratorRedux();
  const { urlPermisos } = useUrlPermisos();
  const { Evaluation } = useEvaluationRedux();

  //** Custom hook para validar permisos de esta sección
  const { ableInputSelectMetrica, ableInputSelectMetricaEvaluador } =
    useAbleInputSelectMetricas();

  //** Context Cambio de periodo
  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  const handleChangeResult = (e: any, id: number, user: boolean) => {
    e.preventDefault();
    if (user) {
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && user) {
          return (item.employeEvaluationResponseQuestion = +e.target.value);
        }
      });

      setEvaluation({
        ...evaluation,
        employeeEvaluationSkills: results,
      });

      setAddSkillsEvaluador(
        suma(
          results?.map(
            (item: JobPerformanceResponseInterface) =>
              item?.employeEvaluationResponseQuestion
          )
        )
      );
    } else {
      results.filter((item) => {
        if (item?.employeeEvaluationQuestionsId === id && !user) {
          return (item.evaluatorEvaluationResponseQuestion = +e.target.value);
        }
      });

      setEvaluation({
        ...evaluation,
        employeeEvaluationSkills: results,
      });
      setAdd(
        suma(
          results.map(
            (item: SkillResponseInterface) =>
              item?.evaluatorEvaluationResponseQuestion
          )
        )
      );
    }
  };

  useEffect(() => {
    setAdd(
      suma(
        results?.map(
          (item: SkillResponseInterface) =>
            item?.evaluatorEvaluationResponseQuestion
        )
      )
    );

    setAddSkillsEvaluador(
      suma(
        results?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.employeEvaluationResponseQuestion
        )
      )
    );
  }, [results, changePeriodEvaluation]);

  const { evaluationAttribute } = useCollaboratorRedux();

  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;
  const { isActualDate } = useSemesterYearNow();
  return (
    <FormContainer className="flex-item">
      <FormTitle>
        <TitleWrapper>
          <strong>3.- Habilidades</strong>
          {evaluationAttribute.show_modal_help_tooltips === 1 ? (
            <InfoLaboralDevelopmentModalHabilidades />
          ) : null}
        </TitleWrapper>
      </FormTitle>
      <AutoEvaluacionContainer>
        <strong className="data">Evaluado</strong>
        <strong className="data">Evaluador</strong>
      </AutoEvaluacionContainer>
      <QuestionList>
        {results?.map((item: SkillResponseInterface, aux: number) => {

          return (
            <QuestionListItem key={item.question.idQuestion}>
              <TextItem>
                {index + (aux + 1)} .- {item?.question?.textQuestion}
              </TextItem>
              <SelectContainer>
                {/* Columna Evaluado */}
                <ContainerSelect colorBtn={ableInputSelectMetrica}>
                  <SelectOption
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        true
                      );
                    }}
                    disabled={ableInputSelectMetrica}
                  >
                    {item?.employeEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.employeEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
                {/* Columna Evaluador */}
                <ContainerSelect colorBtn={ableInputSelectMetricaEvaluador}>
                  <SelectOption
                    onChange={(e: any) => {
                      handleChangeResult(
                        e,
                        item?.employeeEvaluationQuestionsId,
                        false
                      );
                    }}
                    disabled={ableInputSelectMetricaEvaluador}
                  >
                    {item?.evaluatorEvaluationResponseQuestion === 1 ? (
                      <option value="1" selected>
                        1
                      </option>
                    ) : (
                      <option value="1">1</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 2 ? (
                      <option value="2" selected>
                        2
                      </option>
                    ) : (
                      <option value="2">2</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 3 ? (
                      <option value="3" selected>
                        3
                      </option>
                    ) : (
                      <option value="3">3</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 4 ? (
                      <option value="4" selected>
                        4
                      </option>
                    ) : (
                      <option value="4">4</option>
                    )}
                    {item?.evaluatorEvaluationResponseQuestion === 5 ? (
                      <option value="5" selected>
                        5
                      </option>
                    ) : (
                      <option value="5">5</option>
                    )}
                  </SelectOption>
                </ContainerSelect>
              </SelectContainer>
            </QuestionListItem>
          );
        })}
      </QuestionList>
      <>
        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          !urlPermisos && (
            <PlusResult>
              <span style={{ marginRight: "4.5rem" }}>Suma</span>
              <div style={{ marginRight: "1.5rem" }} className="sumaClass">
                {add}
              </div>
            </PlusResult>
          )}

        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId === 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{add}</span>
            </PlusResult>
          )}

        {(User.role === "Evaluador" ||
          User.role === "Evaluado" ||
          User.role === "Director" ||
          User.role === "Admin TI" ||
          User.role === "Admin" ||
          User.role === "Admin RH") &&
          urlPermisos &&
          Evaluation.catStatusEvaluationId !== 7 && (
            <PlusResult>
              <span>Suma</span>
              <span className="sumaClass">{addSkillsEvaluado}</span>
            </PlusResult>
          )}
      </>
    </FormContainer>
  );
};

const finalQualification = (sum: number, length: number): string => {
  return (sum / length).toFixed(2);
};

export const EvaluationResults = ({
  total,
  politicasDeEmpresa,
  addTotalEvalaudo,
  evaluation,
}: IEvaluationResults) => {
  const { urlPermisos } = useUrlPermisos();
  const { User } = useCollaboratorRedux();

  //** Context Cambio de periodo
  const {
    totalConocimientoEmpresa,
    setTotalConocimientoEmpresa,
    changePeriodEvaluation,
    setTotalSumaMetricas,
    totalSumaMetricas,
  } = useContext(ChangePeriodContext) as ChangePeriodContextType;

  useEffect(() => {

    if (evaluation.catStatusEvaluationId === 7) {
      // if (total) {
        let add = suma(
        evaluation.employeEvaluationQuestions?.map(
          (item: JobPerformanceResponseInterface) =>
            item?.evaluatorEvaluationResponseQuestion
        )
      );

      setTotalSumaMetricas(add);
       // }

      if (evaluation?.employeeEvaluationPolitics) {
        setTotalConocimientoEmpresa(evaluation?.employeeEvaluationPolitics);
      }
    } else {
      if (total) {
        setTotalSumaMetricas(total);
      }
      if (evaluation.employeeEvaluationPolitics) {
        setTotalConocimientoEmpresa(evaluation?.employeeEvaluationPolitics);
      }
    }
  }, [changePeriodEvaluation, evaluation?.employeeEvaluationPolitics, total]);

  useEffect(() => {
    setTotalConocimientoEmpresa(evaluation?.employeeEvaluationPolitics);
  }, []);



  return (
    <ResultsContainer>
      {(User.role === "Evaluador" ||
        User.role === "Evaluado" ||
        User.role === "Director" ||
        User.role === "Admin TI" ||
        User.role === "Admin" ||
        User.role === "Admin RH") &&
        !urlPermisos && (
          <Results>
            <span>Suma Total</span>
            <div className="totalRightSpacing">{total}</div>
          </Results>
        )}

      {(User.role === "Evaluador" ||
        User.role === "Evaluado" ||
        User.role === "Director" ||
        User.role === "Admin TI" ||
        User.role === "Admin" ||
        User.role === "Admin RH") &&
        urlPermisos && (
          <Results>
            <span>Suma Total</span>
            <div className="totalRightSpacing">
              {evaluation.catStatusEvaluationId === 7
                ? suma(
                  evaluation.employeEvaluationQuestions?.map(
                    (item: JobPerformanceResponseInterface) =>
                      item?.evaluatorEvaluationResponseQuestion
                  )
                )
                : suma(
                  evaluation.employeEvaluationQuestions?.map(
                    (item: JobPerformanceResponseInterface) =>
                      item?.employeEvaluationResponseQuestion
                  )
                )}
            </div>
          </Results>
        )}

      {(User.role === "Evaluador" ||
        User.role === "Evaluado" ||
        User.role === "Director" ||
        User.role === "Admin TI" ||
        User.role === "Admin" ||
        User.role === "Admin RH") &&
        urlPermisos && (
          <Results>
            <span>Calificación final</span>
            <div className="finalQualification">
              {evaluation.catStatusEvaluationId === 7
                ? finalQualification(
                  suma(
                    evaluation.employeEvaluationQuestions?.map(
                      (item: JobPerformanceResponseInterface) =>
                        item?.evaluatorEvaluationResponseQuestion
                    )
                  ), evaluation?.employeEvaluationQuestions?.length
                )
                : finalQualification(
                  suma(
                    evaluation.employeEvaluationQuestions?.map(
                      (item: JobPerformanceResponseInterface) =>
                        item?.employeEvaluationResponseQuestion
                    )
                  ), evaluation?.employeEvaluationQuestions?.length
                )}
            </div>
          </Results>
        )}
      {(User.role === "Evaluador" ||
        User.role === "Evaluado" ||
        User.role === "Director" ||
        User.role === "Admin TI" ||
        User.role === "Admin" ||
        User.role === "Admin RH") &&
        !urlPermisos && (
          <Results>
            <span>Calificación final</span>
            <div className="finalQualification">{finalQualification(total, evaluation?.employeEvaluationQuestions?.length)}</div>
          </Results>
        )}

      <Results>
        <span>Conocimiento de las políticas de la empresa</span>
        <div className="finalQualification">{politicasDeEmpresa}</div>
      </Results>
    </ResultsContainer>
  );
};

//** Oportunidades
export const EvaluatedComents = ({
  title,
  Opportunities,
  handleChange,
  oportunitySelect,
}: IOportunities) => {
  const { evaluationAttribute } = useCollaboratorRedux();
  const { urlPermisos } = useUrlPermisos();
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  const getId = (obj: any) => {
    const id = Opportunities?.find(
      (item) =>
        item?.employeeEvaluationWorkersOpportunitieAreaText ===
        obj?.employeeEvaluationWorkersOpportunitieAreaText
    )?.employeeEvaluationWorkersOpportunitieId;
    return id;
  };
  const [showEditObjetive, setShowEditObjetive] = useState(false);
  const [indexEdit, setIndexEdit] = useState(0);
  const [idSelect, setIdSelect] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isSaveDatas, setIsSaveDatas] = useState({});
  const [isErased, setIsErased] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [value, setValue] = useState(textModal ?? "");

  const handleUpdated = (e: any, type: any, index: number) => {
    const datas = {
      e: e,
      type: type,
      index: idSelect,
    };
    setIsSaveDatas(datas);
  };

  return (
    <EvaluatedCommentsContainer>
      <>
        <NewTitleContainer>
          <p className="titleStyle">{title}</p>
          <div className="iconContainer">
            {evaluationAttribute.show_modal_help_tooltips && (
              <OportunitiesModal />
            )}
          </div>
        </NewTitleContainer>
        <CommentContainer>
          <NumberItem>1.-</NumberItem>
          <ContainerDescriptionAreas>
            {oportunitySelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={oportunitySelect}
                index={0}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
        <CommentContainer>
          <NumberItem>2.-</NumberItem>
          <ContainerDescriptionAreas>
            {oportunitySelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={oportunitySelect}
                index={1}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
        <CommentContainer>
          <NumberItem>3.-</NumberItem>
          <ContainerDescriptionAreas>
            {oportunitySelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={oportunitySelect}
                index={2}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
      </>
      <EditStrongestModal
        show={showEditObjetive}
        onHide={setShowEditObjetive}
        handleChangeDos={handleUpdated}
        indexEdit={indexEdit}
        editText={"oportunidades"}
        StrenghsOrOpportunities={Opportunities}
        // isDisabled={urlPermisos || ableInput}
        formValues={oportunitySelect && getId(oportunitySelect[idSelect])}
        formData={oportunitySelect}
        idSelect={idSelect}
        isStrengh={false}
        setShowAlert={setShowAlert}
        isErased={isErased}
        setIsErased={setIsErased}
        textModal={textModal}
        setTextModal={setTextModal}
        value={value}
        setValue={setValue}
      />
      <SaveEditModal
        show={showAlert}
        onHide={setShowAlert}
        isSaveDatas={isSaveDatas}
        setIsSaveDatas={setIsSaveDatas}
        handleChange={handleChange}
        onHide2={setShowEditObjetive}
        isErased={isErased}
        setTextModal={setTextModal}
        isStrengh={false}
        setValue={setValue}
      />
    </EvaluatedCommentsContainer>
  );
};

//** Fortalezas
export const EvaluatorComents = ({
  title,
  Strenghs,
  handleChange,
  strenghsSelect,
}: IStrenghs) => {
  const { evaluationAttribute } = useCollaboratorRedux();

  //* Custom hooks
  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();

  // ** Context
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;
  const { enablePermissionStrAndOp } = useAbleFortalezasDebilidades();
  const getId: any = (obj: any) => {
    const id = Strenghs?.find(
      (item) =>
        item?.employeeEvaluationWorkersStrengthsQuestionText ===
        obj?.employeeEvaluationWorkersStrengthsQuestionText
    )?.employeeEvaluationWorkersStrengthsId;
    return id;
  };
  const [showEditObjetive, setShowEditObjetive] = useState(false);
  const [indexEdit, setIndexEdit] = useState(0);
  const [idSelect, setIdSelect] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [isSaveDatas, setIsSaveDatas] = useState({});
  const [isErased, setIsErased] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [value, setValue] = useState(textModal ?? "");

  const handleUpdated = (e: any, type: any, index: number) => {
    const datas = {
      e: e,
      type: type,
      index: idSelect,
    };
    setIsSaveDatas(datas);
  };

  return (
    <EvaluatedCommentsContainer>
      <>
        <NewTitleContainer>
          <p className="titleStyle">{title} </p>
          <div className="iconContainer">
            {evaluationAttribute.show_modal_help_tooltips === 1 && (
              <StrongestModal />
            )}
          </div>
        </NewTitleContainer>
        <CommentContainer>
          <NumberItem>1.-</NumberItem>
          <ContainerDescriptionAreas>
            {strenghsSelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={strenghsSelect}
                index={0}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                textModal={textModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
        <CommentContainer>
          <NumberItem>2.-</NumberItem>
          <ContainerDescriptionAreas>
            {strenghsSelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={strenghsSelect}
                index={1}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                textModal={textModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
        <CommentContainer>
          <NumberItem>3.-</NumberItem>
          <ContainerDescriptionAreas>
            {strenghsSelect && (
              <EditStrengthsAndOpportunities
                setEditObjective={setShowEditObjetive}
                data={strenghsSelect}
                index={2}
                setIndexEdit={setIndexEdit}
                setIdSelect={setIdSelect}
                setTextModal={setTextModal}
                textModal={textModal}
                setValue={setValue}
              />
            )}
          </ContainerDescriptionAreas>
        </CommentContainer>
      </>
      <EditStrongestModal
        show={showEditObjetive}
        onHide={setShowEditObjetive}
        handleChangeDos={handleUpdated}
        indexEdit={indexEdit}
        editText={"fortalezas"}
        StrenghsOrOpportunities={Strenghs}
        isDisabled={urlPermisos || ableInput}
        formValues={strenghsSelect && getId(strenghsSelect[idSelect])}
        formData={strenghsSelect}
        idSelect={idSelect}
        isStrengh={true}
        setShowAlert={setShowAlert}
        isErased={isErased}
        setIsErased={setIsErased}
        textModal={textModal}
        setTextModal={setTextModal}
        value={value}
        setValue={setValue}
      />
      <SaveEditModal
        show={showAlert}
        onHide={setShowAlert}
        isSaveDatas={isSaveDatas}
        setIsSaveDatas={setIsSaveDatas}
        handleChange={handleChange}
        onHide2={setShowEditObjetive}
        isErased={isErased}
        setTextModal={setTextModal}
        isStrengh={true}
        setValue={setValue}
      />
    </EvaluatedCommentsContainer>
  );
};
