import { saveAs } from 'file-saver';
import { useState } from 'react';
//styles
import ModalLoader from '../../../../../components/organisms/ConfirmationModals/ModalLoader';
import { getLogs } from '../../../../../services/logs.service';
import { UpArrow } from '../ProgresoEvaluaciones/ProgresoEvaluaciones.styles';
import {
  ButtonDescargar,
  ButtonLimpiar,
  FilterContainer,
  InputFilter,
  MainContainer,
  SelectContainer,
  Subtitle,
  Title,
  TitleSelect
} from './LogActividad.styles';



import styled from 'styled-components';
import arrowDown from "../../../../../assets/arrowDown.svg";
import arrowUp from "../../../../../assets/arrowUp.svg";

export default function LogActividad() {

  const [logs, setLogs] = useState();
  const [error, setError] = useState<string | null>(null);
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [idFortia, setIdFortia] = useState<any>("");
  const [loader, setLoader] = useState(false)

  const [hide, setHide] = useState(false)
  const fetchData = async () => {
    try {

      setLoader(true);



      let response = await getLogs(fechaInicio, fechaFin, idFortia);





      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      saveAs(blob, "Reporte historial");
      // console.log("data del archivo", response); // Limpiar cualquier error previo si la solicitud es exitosa

      if (response) {
        setLoader(false)
      }

    } catch (error) {
      setError('Error al obtener el porcentaje'); // Configurar mensaje de error

      if (error) {
        setLoader(false)
      }
    }
  };

  const limpiar = () => {
    setFechaFin("")
    setFechaInicio("")
    setIdFortia(null)
  }

  // console.log("DAta petición -> ", fechaInicio, fechaFin, idFortia);


  return (
    <MainContainer>
      {loader && <ModalLoader />}
      <Title>
        <div>
          Log de actividad
        </div>
        <div className="containerToggle">
          <UpArrow
            alt=""
            src={hide ? arrowUp : arrowDown}
            onClick={() => setHide(!hide)}
          />
        </div>

      </Title>
      {hide &&
        (<ContainerSelect>
          <Subtitle>Descarga</Subtitle>
          <FilterContainer>
            <SelectContainer>
              <TitleSelect>ID Fortia</TitleSelect>
              <InputFilter
                style={{ outline: "none" }}
                type='number'
                placeholder='Número ID'
                value={idFortia}
                onChange={(e) => setIdFortia(e.target.value)}
              />
            </SelectContainer>
            <SelectContainer>
              <TitleSelect>Fecha inicio</TitleSelect>
              <InputFilter
                style={{ outline: "none" }}
                type='date'
                value={fechaInicio}
                onChange={(e) => setFechaInicio(e.target.value)}
              />
            </SelectContainer>
            <SelectContainer>
              <TitleSelect>Fecha fin</TitleSelect>
              <InputFilter
                style={{ outline: "none" }}
                type='date'
                value={fechaFin}
                onChange={(e) => setFechaFin(e.target.value)}
              />
            </SelectContainer>



          </FilterContainer>
          <BtnWrapper>

            <ButtonLimpiar onClick={limpiar}>Limpiar</ButtonLimpiar>
            <ButtonDescargar onClick={fetchData}>Descargar</ButtonDescargar>
          </BtnWrapper>

          {/* <LineGray></LineGray> */}
        </ContainerSelect>)
      }
    </MainContainer>
  )
}


const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
`;

const ContainerSelect = styled.div`

  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  /* margin-bottom: 24px; */
  margin-top: 40px;

`;