import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//* Con este hook veo si el donde estoy parado es mí evaluación o la de otro

export const useUrlPermisos = () => {
  //** Hooks
  const paramsData = useParams();

  //** States
  const [urlPermisos, setUrlPermisos] = useState(false);

  const handleChangeEvaluator = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("/Evaluacion")) {
      setUrlPermisos(true);
    } else {
      setUrlPermisos(false);
    }
  };

  useEffect(() => {
    handleChangeEvaluator();
  }, [paramsData]);

  return {
    urlPermisos,
  };
};
