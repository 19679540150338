import PaginationTableCollabs from "../../../../../components/atoms/PaginationTableCollabs";
import { useCollaboratorRedux } from "../../../../../redux/Collaborators";
import FiltersDashboard from "../../FiltersDashboard";
import TableDashboard from "../../TableDashboard";
import {
    ContainOrganigrama,
    ContainPagination,
    ContainerTable,
    ContainerTableFixedDos,
    ContainerTxt,
    TabsSelected,
} from "./TableColaboradoresStyles";

const TableColaboradores = ({
  evaluation_period_id,
  nameEmployee,
  setFilterSearch,
  setErrorLoader,
  setQueryPeticionWithFilters,
  changeInfoTableGeneral,
  semestre,
  filterSearch,
  setError,
  setSuccess,
  setMessage,
  setSelectedIdEvaluation,
  updateTable,
  setUpdateTable,
  setuserShow,
  setuserClaTrab,
}: any) => {
  return (
    <ContainerTable>
      <ContainerTxt>Información de colaboradores</ContainerTxt>
      <div className="tabsContainer">
        <TabsSelected isSelected={true}>Filtros</TabsSelected>
      </div>

      <GeneralTable
        setuserClaTrab={setuserClaTrab}
        setuserShow={setuserShow}
        evaluation_period_id={evaluation_period_id}
        nameEmployee={nameEmployee}
        setFilterSearch={setFilterSearch}
        setErrorLoader={setErrorLoader}
        setQueryPeticionWithFilters={setQueryPeticionWithFilters}
        changeInfoTableGeneral={changeInfoTableGeneral}
        semestre={semestre}
        filterSearch={filterSearch}
        setError={setError}
        setSuccess={setSuccess}
        setMessage={setMessage}
        setSelectedIdEvaluation={setSelectedIdEvaluation}
        updateTable={updateTable}
        setUpdateTable={setUpdateTable}
      />
    </ContainerTable>
  );
};

export default TableColaboradores;

// export const OrganigramaTable = ({
//     evaluation_period_id,
//     nameEmployee,
//     setFilterSearch,
//     setErrorLoader,
//     setQueryPeticionWithFilters,
//     changeInfoTableGeneral,
//     semestre,
//     filterSearch,
//     setError,
//     setSuccess,
//     setMessage,
//     setSelectedIdEvaluation,
//     updateTable,
//     setUpdateTable,
//     setNameEmployee,
//     setuserShow,
//     setuserClaTrab
// }: any) => {

//     //** Redux
//     const {
//         evaluationAttribute,
//         Meta
//     } = useCollaboratorRedux();
//     return (
//         <>

//             <FiltersDashboard
//                 evaluation_period_id={evaluation_period_id}
//                 nameEmployee={nameEmployee}
//                 setErrorLoader={setErrorLoader}
//                 setFilterSearch={setFilterSearch}
//                 setQueryPeticionWithFilters={setQueryPeticionWithFilters}
//             />

//             {evaluationAttribute.table_collaborators === 1 && (
//                 <ContainerTableFixedDos>

//                     {evaluationAttribute.filter_jerarquia === 1 && (
//                         <ContainOrganigrama>
//                             {evaluationAttribute.dowload_collaborator_report ===
//                                 1 && (
//                                     <Organigrama
//                                         setNameEmployee={setNameEmployee}
//                                         evaluation_period_id={evaluation_period_id}
//                                     />
//                                 )}
//                             <TableDashboard
//                                 setuserClaTrab={setuserClaTrab}
//                                 setuserShow={setuserShow}
//                                 getAllInfo={changeInfoTableGeneral}
//                                 semestre={semestre}
//                                 filterSearch={filterSearch}
//                                 setError={setError}
//                                 setSuccess={setSuccess}
//                                 setMessage={setMessage}
//                                 evaluation_period_id={evaluation_period_id}
//                                 setSelectedIdEvaluation={setSelectedIdEvaluation}
//                                 updateTable={updateTable}
//                                 setUpdateTable={setUpdateTable}
//                             />
//                         </ContainOrganigrama>
//                     )}
//                     <ContainPagination style={{ marginTop: "40px" }}>
//                         <PaginationTable
//                             evaluation_period_id={evaluation_period_id}
//                             table="Collaboratos"
//                             Meta={Meta}
//                             setQueryPeticionWithFilters={setQueryPeticionWithFilters}
//                         />
//                     </ContainPagination>
//                 </ContainerTableFixedDos>
//             )}
//         </>
//     )
// }

export const GeneralTable = ({
  evaluation_period_id,
  nameEmployee,
  setFilterSearch,
  setErrorLoader,
  setQueryPeticionWithFilters,
  semestre,
  filterSearch,
  setError,
  setSuccess,
  setMessage,
  updateTable,
  setUpdateTable,
}: any) => {
  //** Redux
  const { evaluationAttribute, Meta } = useCollaboratorRedux();
  return (
    <>
      <FiltersDashboard
        evaluation_period_id={evaluation_period_id}
        nameEmployee={nameEmployee}
        setFilterSearch={setFilterSearch}
        setErrorLoader={setErrorLoader}
        setQueryPeticionWithFilters={setQueryPeticionWithFilters}
      />

      {evaluationAttribute.table_collaborators === 1 && (
        <ContainerTableFixedDos>
          <ContainOrganigrama>
            <TableDashboard
              semestre={semestre}
              filterSearch={filterSearch}
              setError={setError}
              setSuccess={setSuccess}
              setMessage={setMessage}
              evaluation_period_id={evaluation_period_id}
              updateTable={updateTable}
              setUpdateTable={setUpdateTable}
            />
          </ContainOrganigrama>
          <ContainPagination style={{ marginTop: "40px" }}>
            <PaginationTableCollabs
              evaluation_period_id={evaluation_period_id}
              table="Collaboratos"
              Meta={Meta}
              setQueryPeticionWithFilters={setQueryPeticionWithFilters}
            />
          </ContainPagination>
        </ContainerTableFixedDos>
      )}
    </>
  );
};
