import React from 'react'
//styles
import { ModalOverlay, MainCotainer, TextBody, TitleContainer, TitleImage, TitleText } from './RecordatorioEvaliaciones.styles'
//assets
import check from '../../../../../../assets/checkGreen.svg'
import close from '../../../../../../assets/closeButton.svg'
import { getEvaluationReminder } from '../../../../../../services/evaluation.service'


interface ModalRecordatoriProps {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function RecordatorioEvaluaciones({showModal, setShowModal} : ModalRecordatoriProps) {

 

  return (
    <ModalOverlay>
        <MainCotainer>
            <TitleContainer>
                <TitleImage>
                    <img src={check} alt=''/>
                    <TitleText>Recordatorio de evaluaciones pendientes</TitleText>
                </TitleImage>
                <img style={{cursor:'pointer'}} src={close} alt='' onClick={()=>setShowModal(!showModal)}/>
            </TitleContainer>
            <TextBody>¡Listo! Tu recordatorio ha sido enviado con éxito a tu dirección de correo electrónico. Por favor, revisa tu bandeja de entrada para acceder al reporte. </TextBody>
        </MainCotainer>
    </ModalOverlay>
  )
}
