import { FC, useState } from "react";
import {
  ButtonBlueFree,
  EvaluationContainerDos,
} from "../../components/atoms/KpisTables/KpisTablesStyles";
import Caution from "../../components/organisms/ConfirmationModals/Caution";
import ModalSelectPeriod from "../../components/organisms/ConfirmationModals/ModalSelectPeriod";
import ModalStatusTargetError from "../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import { validateIdPeriod } from "../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { getReleaseEvaluations } from "../../services/dataSelects.service";

interface PropsTable {
  evaluation_period_id: any;
  nameEmployee?: string;
  SetNameEmployee?: string;
}

const FreeEvaluations: FC<PropsTable> = ({ evaluation_period_id }) => {
  const { handleGetCollaborators, User, Filters } = useCollaboratorRedux();
  const [messageModal, setMessageModal] = useState("");
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [showModalSelectPeriod, setShowModalSelectPeriod] = useState(false);

  const handleChangeStatusEvaluation = async () => {
    // console.log("evaluation_period_id", evaluation_period_id);
    const periodo = validateIdPeriod(evaluation_period_id);

    // let query = `?&NombreColaborador=${User.nombre}&RolColaborador=${User.role}&IdPeriod=${periodo}&PageSize=10&PageNumber=1`;

    if (!evaluation_period_id) {
      // console.log("entro aca");
      setShowModalSelectPeriod(true);
    }

    if (evaluation_period_id.includes(",")) {
      const evaluationOnlyId = evaluation_period_id.split(",");

      await getReleaseEvaluations(evaluationOnlyId[0])
        .then((res) => {
          setSuccess(res.success);
          setMessageModal(res.message);
        })
        .catch((err) => {
          setError(err.success);
          setMessageModal(err.message);
        });
      // console.log("<Se hace petición>");
      const queryParts: string[] = [];

      for (const key in Filters) {
        if (Filters[key] !== undefined && Filters[key] !== "") {
          if (
            key === "Mas95Porcent" ||
            key === "Menos50Porcent" ||
            key === "TienePoliticPorcent" ||
            key === "SinEvaluacionPorcent"
          ) {
            queryParts.push(`GraphicFilter.${key}=${true}`);
          } else {
            queryParts.push(`${key}=${Filters[key]}`);
          }
        }
      }

      let queryFilters = queryParts.join("&");
      handleGetCollaborators(
        `?${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${
          User.role === "Auditor" ? "Admin" : User.role
        }&IdPeriod=${periodo}&PageSize=10&PageNumber=1`
      );
    } else {
      await getReleaseEvaluations(evaluation_period_id)
        .then((res) => {
          setSuccess(res.success);
          setMessageModal(res.message);
        })
        .catch((err) => {
          setError(err.success);
          setMessageModal(err.message);
        });
      // console.log("<Se hace petición>");
      const queryParts: string[] = [];

      for (const key in Filters) {
        if (Filters[key] !== undefined && Filters[key] !== "") {
          if (
            key === "Mas95Porcent" ||
            key === "Menos50Porcent" ||
            key === "TienePoliticPorcent" ||
            key === "SinEvaluacionPorcent"
          ) {
            queryParts.push(`GraphicFilter.${key}=${true}`);
          } else {
            queryParts.push(`${key}=${Filters[key]}`);
          }
        }
      }

      let queryFilters = queryParts.join("&");
      handleGetCollaborators(
        `?${queryFilters}&NombreColaborador=${User.nombre}&RolColaborador=${
          User.role === "Auditor" ? "Admin" : User.role
        }&IdPeriod=${periodo}&PageSize=10&PageNumber=1`
      );
    }
  };

  return (
    <EvaluationContainerDos>
      {/* <ContainButonsHeadrEva> */}
      <ButtonBlueFree onClick={() => setShowModalCaution(true)}>
        Liberar evaluaciones
      </ButtonBlueFree>{" "}
      {/* </ContainButonsHeadrEva> */}
      <Caution
        textBody="Esta acción implica liberar las evaluaciones. Antes de continuar, tómate un momento para confirmar, ya que esta acción es irreversible."
        textBodyOk={messageModal}
        textBodyQuestion="¿Estás seguro de liberar las evaluaciones?"
        messageShow={true}
        title="Atención"
        estado={showModalCaution}
        cambiarEstado={setShowModalCaution}
        textButtonAct="Continuar"
        textButtonClose="Cancelar"
        handleClick={() => handleChangeStatusEvaluation()}
      />
      {success ? (
        <ModalStatusSuccessful
          handleClick={setSuccess}
          modalStatus={success}
          messageShow
          message={messageModal}
        />
      ) : null}
      {error ? (
        <ModalStatusTargetError
          handleClick={setError}
          modalStatus={error}
          messageShow
          message={messageModal}
        />
      ) : null}
      {showModalSelectPeriod ? (
        <ModalSelectPeriod
          handleClick={setShowModalSelectPeriod}
          modalStatus={showModalSelectPeriod}
        />
      ) : null} 
    </EvaluationContainerDos>
  );
};

export default FreeEvaluations;
