import React, { ReactNode, useState } from "react";

export type NewEvaluationContextType = {
  setValidationFortalezasSection?: any;
  validationFortalezasSection?: boolean;
  validateFortalezasDebilidades?: any;
  setValidateFutureObjs?: any;
  validateFutureObjs?: any;
  setValidateFinishEv?: any;
  validateFinishEv?: any;
  setValidateFieldsFutureObjs?: any;
  validateFieldsFutureObjs?: any;
  setValidateEmptyInput?: any;
  validateEmptyInput?: any;
  setObjetivesCurrents?: any;
  objetivesCurrents?: any;
  userName?: any;
  setUserName?: any;
  validateComments?: any;
  setValidateComments?: any;
};

interface MyComponentProps {
  children: ReactNode;
}

export const NewEvaluationContext =
  React.createContext<NewEvaluationContextType | null>(null);

const MyEvaluationProvider: React.FC<MyComponentProps> = ({ children }) => {
  const [evaluation, setEvaluation] = useState<any | null>({});

  //todo H-073 Validación de terminar / firmar
  //? Fortalezas
  const [validationFortalezasSection, setValidationFortalezasSection] =
    useState<any | null>(false);
  //todo H-073 Validación de terminar / firmar
  //? Objetivos futuros
  const [validateFutureObjs, setValidateFutureObjs] = useState<any | null>(
    false
  );
  const [validateFieldsFutureObjs, setValidateFieldsFutureObjs] = useState<
    any | null
  >(true);
  //todo H-073 Validación de terminar / firmar
  //? Objetivos actuales
  const [validateFinishEv, setValidateFinishEv] = useState<boolean>(true);
  //? Objetivos actuales Identificar 0 en input
  const [validateEmptyInput, setValidateEmptyInput] = useState<boolean>(false);
  const [objetivesCurrents, setObjetivesCurrents] = useState([]);

  //** h16 Seleccionar periodo MiEvaluacion
  const [validateComments, setValidateComments] = useState(true);

  //?Identificar cambio de usuario
  const [userName, setUserName] = useState("");

  return (
    <NewEvaluationContext.Provider
      value={{
        setValidationFortalezasSection,
        setValidateFutureObjs,
        setValidateFinishEv,
        setValidateFieldsFutureObjs,
        setValidateEmptyInput,
        setObjetivesCurrents,
        setUserName,
        setValidateComments,
        validateComments,
        userName,
        objetivesCurrents,
        validateEmptyInput,
        validateFieldsFutureObjs,
        validationFortalezasSection,
        validateFutureObjs,
        validateFinishEv,
      }}
    >
      {children}
    </NewEvaluationContext.Provider>
  );
};

export default MyEvaluationProvider;
