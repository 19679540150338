/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { IGetIsCreatingEvaluationsResponse, IPeriod } from "../../Interface/ApiInterface";
import { HeaderTitleDos } from "../../components/atoms/EvaluationHeader/EvaluationHeaderStyles";
import PaginationTable from "../../components/atoms/PaginationTable";
import ModalPerioEvaluacion from "../../components/common/ModalPerioEvaluacion";
import Caution from "../../components/organisms/ConfirmationModals/Caution";
import ModalStatusTargetError from "../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusLoading from "../../components/organisms/ConfirmationModals/ModalStatusLoadingDos";
import ModalStatusSuccessful from "../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { usePeriods } from "../../redux/Periods";
import {
  BackWhiteQ,
  ContainPeriodoFix,
  ContainerTable,
  ContainerTableFixed,
  PaginationBox,
  WrapperPeriodos,
  WrapperPeriodosBody,
  WrapperPeriodosHeader,
} from "./Dashboard/stylesDashboard/AdminStyles";
import {
  CellHeadingText,
  ContainPageQuestionaire,
  RowBox,
  Title
} from "./Objectives/ObjetivesStyles.js";
import { SocketContext } from "../../context/SocketContext";
import ModalLoader from "../../components/organisms/ConfirmationModals/ModalLoader";
import { useLoader } from "../../context/LoaderContext";

const PeriodosEvaluaciones = () => {
  const { User } = useCollaboratorRedux();
  const ACTIVE = '1'
  const DESACTIVE = '0'
  const [period, setPeriod] = useState({} as IPeriod);
  const [submitModal, setSubmitModal] = useState(false);
  const [showModalCaution, setShowModalCaution] = useState(false);
  const [periodId, setPeriodId] = useState(0);
  const [isInProgress, setIsProgress] = useState(false)

  const {
    Meta,
    periods,
    handlePostPeriod,
    handleGetAllPeriods,
    setErrorPost,
    setSuccesPost,
    setLoader,
    errorPost,
    succesPost,
    message,
    loader,
    handleCreateEvaluationPeriods,
    setMessage
  } = usePeriods();
  const { socket } = useContext(SocketContext)

  useEffect(() => {
    if (submitModal) {
      handlePostPeriod(period, User.claTrab);
      handleGetAllPeriods(``);
      setPeriod({
        ...period,
        evaluationPeriodYear: 0,
        evaluationPeriodName: "",
      });
      setSubmitModal(false);
    }
  }, [submitModal]);

  useEffect(() => {
    handleGetAllPeriods(``);
  }, []);

  const activeCautionModal = (item: any) => {
    if (item.evaluationsObjectives === 0) {
      // try
    } else {
      setPeriodId(item.evaluationPeriodId);
      setShowModalCaution(true);
    }
  };

  const handleEmitFinishedEvaluation = () => {
    socket?.emit('finished-evaluations')
  }

  useEffect(() => {

    if (socket) {
      socket?.emit('join-periods');
    }

    return () => {
      if (socket) {
        socket.off('inprogress-evaluations');
        socket.off('finished-evaluations-process');
        socket.off('canceled-evaluations-process');
      }
    };
  }, [socket]);




  const handleCreateEvaluation = async () => {
    await handleCreateEvaluationPeriods(periodId, User.claTrab);
    handleEmitFinishedEvaluation()
    await handleGetAllPeriods(``);
  };

  const { evaluationAttribute } = useCollaboratorRedux();
  const { isLoading } = useLoader();
  return (
    <ContainPageQuestionaire>
      <HeaderTitleDos>Períodos y evaluaciones</HeaderTitleDos>
      <RowBox>

        <Title
          style={{
            fontSize: "20px",
            marginBottom: "1rem",
          }}
        >
          Periodo
        </Title>

      </RowBox>
      <ContainPeriodoFix>
        {evaluationAttribute.create_period === 1 && (
          <ModalPerioEvaluacion
            setPeriod={setPeriod}
            period={period}
            setSubmitModal={setSubmitModal}
          />
        )}
        {succesPost ? (
          <ModalStatusSuccessful
            handleClick={setSuccesPost}
            modalStatus={succesPost}
            messageShow
            message={message}
            titleModal={message === "El periodo ha sido creado correctamente." ? "Periodo creado" : "Evaluación creada"}
          />
        ) : null}
        {/* {loader || isInProgress ? (
          <ModalLoader />
        ) : null} */}
        {isLoading || isInProgress ? (
          <ModalLoader />
        ) : null}
        {errorPost ? (
          <ModalStatusTargetError
            handleClick={setErrorPost}
            modalStatus={errorPost}
            messageShow
            message={message}
            titleModal="Faltan objetivos generales para crear evaluaciones"
          />
        ) : null}
      </ContainPeriodoFix>
      <BackWhiteQ>
        <ContainerTableFixed>

          <ContainerTable>
            <WrapperPeriodos>
              <WrapperPeriodosHeader>
                <div className="first">
                  <CellHeadingText>Año</CellHeadingText>
                </div>
                <div className="second">
                  <CellHeadingText>Período</CellHeadingText>
                </div>
                <div className="third">
                  <CellHeadingText>Evaluaciones creadas</CellHeadingText>
                </div>
                <div className="fourth">
                  <CellHeadingText>Evaluaciones por crear</CellHeadingText>
                </div>
                {evaluationAttribute.create_evaluations_button === 1 &&
                  <div className="fifth">
                    <CellHeadingText>Acción</CellHeadingText>
                  </div>
                }
              </WrapperPeriodosHeader>

              {periods.map((item: IPeriod) => {
                return (
                  <WrapperPeriodosBody key={item.evaluationPeriodId}>
                    <div className="first">{item.evaluationPeriodYear}</div>
                    <div className="second">
                      {item.evaluationPeriodNumberOfYear}
                    </div>
                    <div className="third">{item.evaluationsCreates}</div>
                    <div className="fourth">{item.evaluationsForCreate}</div>

                    {evaluationAttribute.create_evaluations_button === 1 &&
                      (<div className="fifth">
                        <button
                          className="btn"
                          // disabled={loader || isInProgress || (item.evaluationsObjectives === 0 && item.evaluationPeriodYear <= new Date().getFullYear())}
                          disabled={isLoading || isInProgress || (item.evaluationsObjectives === 0 && item.evaluationPeriodYear <= new Date().getFullYear())}
                          onClick={() => {
                            activeCautionModal(item);
                          }}
                          style={
                            item.evaluationsObjectives === 0 && item.evaluationPeriodYear <= new Date().getFullYear()
                              ? {
                                background: "#c1c1c3",
                                color: "white",
                                cursor: "default",
                                border: 'none'
                              }
                              : {
                                border: 'none'
                              }
                          }
                        >
                          Crear evaluaciones
                        </button>
                      </div>)
                    }
                  </WrapperPeriodosBody>
                );
              })}
            </WrapperPeriodos>
          </ContainerTable>
          <PaginationBox style={{ marginTop: "40px" }}>
            <PaginationTable table="Periods" Meta={Meta} />
          </PaginationBox>
        </ContainerTableFixed>
      </BackWhiteQ>
      <Caution
        textBody="Al hacer clic en Continuar, estarás cerrando oficialmente el periodo anterior y comenzando uno nuevo para las evaluaciones. Ten en cuenta que esta acción no se puede deshacer."
        textBodyQuestion={'¿Deseas continuar?'}
        textBodyOk=""
        messageShow={true}
        title="Crear evaluaciones"
        estado={showModalCaution}
        cambiarEstado={setShowModalCaution}
        textButtonAct="Continuar"
        textButtonClose="Cancelar"
        handleClick={() => handleCreateEvaluation()}
      />
    </ContainPageQuestionaire>
  );
};

export default PeriodosEvaluaciones;
