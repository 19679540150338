import { TextField } from "@mui/material";
import * as React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import styled from "styled-components";
import { regexValidateNumber } from "../utils";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        thousandSeparator
        allowNegative={false}
        valueIsNumericString
        prefix=""
        suffix={"%"}
        isAllowed={(values) => {
          const { floatValue } = values;
          if (floatValue) {
            return floatValue <= 100;
          }
          return true;
        }}
      />
    );
  }
);

export default function FormattedInputsFutureDefault({
  handleChange,
  value,
  disable,
  index,
  name,
}: any) {
  return (
    <ContainerInput>
      <TextField
        value={value}
        onChange={(e) => {
          if (
            regexValidateNumber.test(e.target.value) ||
            e.target.value === ""
          ) {
            handleChange(e.target.value, index);
          }
        }}
        name={name}
        InputProps={{
          inputComponent: NumericFormatCustom as any,
        }}
        disabled={disable}
      />
    </ContainerInput>
  );
}

const ContainerInput = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: center;
    align-content: center; */
  /* border: 1px solid red; */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;
    margin: 0 auto;
    outline: none;
    font-family: Plus Jakarta Sans;
  }
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;
    font-family: Plus Jakarta Sans;
    align-items: center;
    justify-content: center;
    outline: none;
    align-content: center;
  }

  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;
  }

  .MuiInputBase-root {
    outline: none;
    font-family: Plus Jakarta Sans;
  }
`;
