import { http } from '../api';
import { validateIdPeriod } from '../context/validacionesFiltros';

const getFile = async (ClaTrabEvaluator: number, ClaTrabEvaluated: number, IdPeriod: number) => {
  const periodo = validateIdPeriod(IdPeriod)
  try {
    const response = await http.get(`/Evaluations/get-evaluation-to-download?ClaTrabEvaluated=${ClaTrabEvaluated}&IdPeriod=${periodo}` ,{
        method: 'GET',
        responseType: 'blob', // important
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export { getFile };

