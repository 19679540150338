import { useEffect, useState } from "react";
import styled from "styled-components";

const FooterModalMySelect = ({
    dataFront,
    dataBack,
    inputValue,
    validationSuma,
    objetivesTypeG,
    validationMeta,
}: any) => {


    const valuesDataToNumber = dataFront.map((item: any) => Number(item.valor)).reduce((a: any, b: any) => a + (b || 0), 0);
    const desempenioValueToNumber = Number(inputValue);
    const objetivesTypeGToNumber = objetivesTypeG.map((item: any) => Number(item.valor)).reduce((a: any, b: any) => a + (b || 0), 0);


    const dataBackOnlyNumber = dataBack.filter((obj: any) => obj.type === "D").map((item: any) => Number(item.valor)).reduce((a: any, b: any) => a + (b || 0), 0);


    return (
        <FooterWrapper colorBorber={validationSuma}>
            <div className="second">

                {valuesDataToNumber +
                    dataBackOnlyNumber +
                    desempenioValueToNumber + objetivesTypeGToNumber}%

            </div>
            <div style={{ display: "flex", flexDirection: "column", width: " 58%", justifyContent: "center", alignItems: "center", alignContent:"center", gap: "8px"}}>
                {validationSuma && <div className="third">La suma debe ser igual a 100%</div>}
                {!validationMeta && <div className="third">La meta debe ser mayor a 0%</div>}
            </div>

        </FooterWrapper>
    );
};

export default FooterModalMySelect;



//* Este componente recibe 3 prop:
//* PRIMERA: Data que nosotros seteamos en el front
//* SEGUNDA: Data que recibimos en el back
//* TERCERA: Data única, es un input


type PropTypeBgTres = {
    colorBorber?: boolean;
};

const FooterWrapper = styled.div.attrs(
    (props: PropTypeBgTres) => ({})
) <PropTypeBgTres>`
  
    display: flex;
    align-items: center;
    height: 68px;
    font-weight: 700;
    width: 100%;
    margin: 3px 0;
    border-radius: 2px;
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    color: ${(props) => (props.colorBorber ? "red" : "black")};
    letter-spacing: 0.75px;
    border: ${(props) => (props.colorBorber ? "1px solid red" : "none")};
  
    .second {
      width: 30%;
      display: flex;
      justify-content: end;
    }
    .third {
     width: 100%;
       display: flex;
      justify-content: center;
    }
  
  `;