import React from 'react';
import styled from 'styled-components';
//assets
import closeSign from '../../../../../assets/closeButton.svg';
import bell from '../../../../../assets/infoYellow.svg';

const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const ModalContent = styled.div`
  display: flex;
  width: 641px;
  padding: 32px;
  flex-direction: column;
  gap: 10px;
  border-radius: 18px;
  background: #FFF;
`;

const ModalButtons = styled.div`

  display: flex;
  gap: 32px;
`;

const ButtonCancel = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #3B63A2; /* Corrección del estilo de borde */
  background: var(--primary-true-blue, #3B63A2);
  color: var(--primary-true-blue, #FFF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const ButtonContinuar = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #C9F2FF; /* Corrección del estilo de borde */
  background: var(--secondary-blizzard-blue, #C9F2FF);
  color: var(--primary-true-blue, #3B63A2);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
`;

const WarningText = styled.h2`
  color: var(--feedback-red, #3B63A2);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin:0
`;

const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Campana = styled.img`
  width: 20px;
`;

const TextModal = styled.p`
  color: var(--neutrals-jet, #333335);
font-feature-settings: 'clig' off, 'liga' off;

/* Body */
font-family: Plus Jakarta Sans;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 22.4px */
`

const CloseContainer = styled.div`
width: 100%;
  display: flex;
  justify-content: flex-end; /* Corrección para alinear el icono de cierre a la derecha */
`;

const CloseIcon = styled.img`
  cursor: pointer;
`;

interface IbuttonDownload {
  onClick: () => void;
  showModalDesactivar: boolean;
  setShowModalDesactivar: React.Dispatch<React.SetStateAction<boolean>>;
  demanda: boolean;
}

const ModalAdvertirDesactivar = ({
  showModalDesactivar,
  setShowModalDesactivar,
  onClick,
  demanda,
}: IbuttonDownload) => {
  const handleCambiarStatus = () => {
    onClick();
    setShowModalDesactivar(!showModalDesactivar);
  };

  return (
    <ModalBackground>
      <ModalContent>

        <WarningContainer>
          <Campana src={bell} alt="" />
          <WarningText>Atención</WarningText>
          <CloseContainer>
            <CloseIcon onClick={() => setShowModalDesactivar(!showModalDesactivar)} src={closeSign} alt='' />
          </CloseContainer>
        </WarningContainer>
        <TextModal>
          Estás a punto de {demanda ? 'activar' : 'desactivar'} esta evaluación.
          {/*  ¿Estás seguro de que deseas {demanda ? 'activar' : 'desactivar'} esta evaluación? */}
        </TextModal>
        <TextModal>¿Deseas continuar?</TextModal>
        <ModalButtons>
          <ButtonCancel onClick={() => setShowModalDesactivar(!showModalDesactivar)}>Cancelar</ButtonCancel>
          <ButtonContinuar onClick={handleCambiarStatus}>
            Continuar
          </ButtonContinuar>
        </ModalButtons>
      </ModalContent>
    </ModalBackground>
  );
};

export default ModalAdvertirDesactivar;
