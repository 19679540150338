import editObj from "../../../assets/icons/EditObj.svg";
import { useAbleFortalezasDebilidades } from "../../../hooks/FortalezasDebilidades";
import {
  EditAreasContainer,
  EditContainer,
} from "../Dashboard/stylesDashboard/AdminStyles";

const EditStrengthsAndOpportunities = ({
  setEditObjective,
  index,
  data,
  setIndexEdit,
  setIdSelect,
  inputModal,
  textModal,
  setTextModal,
  setValue,
}: any) => {
  const { enablePermissionStrAndOp } = useAbleFortalezasDebilidades();

  const handleClick = () => {
    setTextModal(
      (data[index] &&
        data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
        data[index]?.employeeEvaluationWorkersOpportunitieAreaText
    );

    setValue(
      (data[index] &&
        data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
        data[index]?.employeeEvaluationWorkersOpportunitieAreaText
    );
    setEditObjective(true);
    setIndexEdit(index);
    setIdSelect(index);
  };

  return (
    <>
      <EditContainer>
        <EditAreasContainer>
          {(data[index] &&
            data[index]?.employeeEvaluationWorkersStrengthsQuestionText) ||
            data[index]?.employeeEvaluationWorkersOpportunitieAreaText}
        </EditAreasContainer>
        {!enablePermissionStrAndOp && (
          <div style={{ cursor: "pointer" }} onClick={() => handleClick()}>
            <img
              src={editObj}
              alt="EditStrengthsAndOpportunities"
              style={{ height: "12px", width: "12px" }}
            />
          </div>
        )}
      </EditContainer>
    </>
  );
};

export default EditStrengthsAndOpportunities;
