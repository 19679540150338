import { useContext, useEffect, useState } from "react";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "../Evaluation/useSemesterYearNow";

export const useAbleQualification = () => {
  //** Context
  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();
  //** Hooks

  //** States
  const [ableQualification, setAbleQualification] = useState(true);
  // const evaluationDate = `${Evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear} ${Evaluation?.evaluationPeriod?.evaluationPeriodYear}`;
  // const dateNow = `${User?.periodInfo?.semesterNumber} ${User?.periodInfo?.year}`;
  //** Esta es una evaluación (NO ES MI EVALUACIÓN)

  const evaluation = !urlPermisos;

  useEffect(() => {
    if (User.role === "Auditor") {
      setAbleQualification(true);
    }

    if (User.role === "Evaluado") {
      if (
        Evaluation.catStatusEvaluationId > 0 &&
        Evaluation.catStatusEvaluationId < 4
      ) {
        setAbleQualification(false);
      } else {
        setAbleQualification(true);
      }
    }

    if (User.role !== "Auditor" && User.role !== "Evaluado") {
      //**  +++++++++++++++++++++++++++   EVALUACIÓN +++++++++++++++++++++++
      if (evaluation) {
        if (Evaluation.catStatusEvaluationId === 7) {
          if (
            evaluationAttribute.edit_evaluation_complete === 1 &&
            isActualDate
          ) {
            setAbleQualification(false); // Habilita
          } else {
            setAbleQualification(true); //Inhabilita
          }
        } else if (Evaluation.catStatusEvaluationId === 6) {
          setAbleQualification(true); // Habilita
        } else {
          setAbleQualification(false); //Inhabilita
        }
      }

      //**  +++++++++++++++++++++++++++   AUTOEVALUACIÓN +++++++++++++++++++++++
      if (!evaluation) {
        if (changePeriodEvaluation) {
          if (
            Evaluation.catStatusEvaluationId === 7 ||
            Evaluation.catStatusEvaluationId === 6
          ) {
            setAbleQualification(true);
          }

          if (Evaluation.catStatusEvaluationId < 6) {
            if (evaluationAttribute.edit_column_score === 1) {
              setAbleQualification(false); // Habilita
            } else {
              setAbleQualification(true); //Inhabilita
            }
          }
        } else {
          setAbleQualification(true); //Inhabilita
        }
      }
    }
  }, [
    Evaluation.catStatusEvaluationId,
    urlPermisos,
    evaluation,
    evaluationAttribute.edit_column_score,
    evaluationAttribute.edit_evaluation_complete,
    changePeriodEvaluation,
    User.role,
    isActualDate,
  ]);

  return {
    ableQualification,
  };
};

/*
evaluationAttribute.edit_evaluation_complete === 1 


      (User.role === "Admin TI" ||
      User.role === "Admin" ||
      User.role === "Admin RH")
*/

//? Si el usuario tiene habilitado el atributo
//? Si el usuario pertenece a alguno de estos roles
//? Si el usuario está en una evaluación
//? entonces esta sección se podrá editar
