import InfoModal from "../Modals/InfoModal";
import {
  EvaluatedHeaderContainer,
  HeaderTitle,
} from "./EvaluationHeaderStyles";

const EvaluationHeader = () => {
  return (
    <div>
      
      <EvaluatedHeaderContainer>
        <HeaderTitle>Evaluación de colaboradores</HeaderTitle>
        <InfoModal/>
      </EvaluatedHeaderContainer>
      {/* <EvaluatedHeaderContainer> */}
        {/* <Breadcrumb>
          <Breadcrumb.Item href="#">Colaborador</Breadcrumb.Item>
          <Breadcrumb.Item active>Revisar evaluación</Breadcrumb.Item>
        </Breadcrumb> */}
      {/* </EvaluatedHeaderContainer> */}
    </div>
  );
};

export default EvaluationHeader;
