import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { EvaluationNew } from "../../Interface/ApiInterface";
import { CardEvaluatorNewDesing } from "../../components/atoms/Cards/CardEvaluatorNewDesing";
import EvaluationHeader from "../../components/atoms/EvaluationHeader/EvaluationHeader";
import ModalEvaluationInactive from "../../components/organisms/ConfirmationModals/ModalEvaluationInactive";
import ModalLoader from "../../components/organisms/ConfirmationModals/ModalLoader";
import ModalStatusTargetError from "../../components/organisms/ConfirmationModals/ModalStatusError";
import EvaluationForm from "../../components/organisms/EvaluationForm/EvaluationForm";
import { HeaderEvaluationContainer } from "../../components/organisms/EvaluationForm/EvaluationFormStyles";
import FiltroPeriod from "../../components/organisms/EvaluationForm/components/FiltroPeriod";
import KpisContainer from "../../components/organisms/KpisContainer/KpisContainer";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import MyEvaluationProvider from "../../context/EvaluacionContext/NewEvaluationContext";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../context/EvaluationContext";
// import { SocketContext } from "../../context/SocketContext";
import { useUrlPermisos } from "../../hooks/Evaluation";
import { useEvaluationSocket } from "../../hooks/Socket/useEvalutionSocket";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { getCollaboratorServices } from "../../services/collaborator.service";
import { getObjectivesPreviewListFuture } from "../../services/dataSelects.service";
import {
  getEvaluationServices,
  getEvaluationStatusSemaforo,
} from "../../services/evaluation.service";
import BlockEvalutionModal from "../Admin/components/ModalEvaluaciones/BlockEvalutionModal";
import PermisoNoActivo from "../Admin/components/ModalEvaluaciones/PermisoNoActivo";
import { EvaluationContainer } from "./EvaluationStyles";
import ModalEvaluationNotCreated from '../../components/organisms/ConfirmationModals/ModalEvaluationNotCreated';
// import { useEvaluationSocket } from "../../hooks/Socket/useEvalutionSocket";
import { useLoader } from "../../context/LoaderContext";

const Evaluation = () => {
  const { isLoading, incrementRequests, decrementRequests } = useLoader();
  const location = useLocation();
  //* Context
  const {
    setFutureObjetives,
    setEvaluationInfo,
    setIsReduxOrContext,
    changePage,
    evaluationInfo,
    setDisableActionEvaluationBtn,
  } = useContext(EvaluationContext) as EvaluationContextType;

  //** Context Cambio de periodo
  const { setChangePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;



  //** Custom Hooks
  const { urlPermisos } = useUrlPermisos();
  const { showBlockEvalutionModal } = useEvaluationSocket()

  //* Redux
  const { User, handleGetCollaborator, Collaborator } = useCollaboratorRedux();
  const { handleGetEvaluation, Evaluation } = useEvaluationRedux();

  //* States
  const [addTotal, setAddTotal] = useState(0);
  const [addTotalEvalaudo, setAddTotalEvaluado] = useState(0);
  const [evaluation, setEvaluation] = useState<EvaluationNew>(
    {} as EvaluationNew
  );
  const [InitialStateEvaluation, setInitialStateEvaluation] =
    useState<EvaluationNew>({} as EvaluationNew);
  const [oportunidad, setOportunidad] = useState([] as any);
  const [fortalezas, setFortalezas] = useState([] as any);
  const [errorPost, setErrorPost] = useState(false);
  const [mesaggeError, setMesaggeError] = useState("");
  const [messageDos, setMessageDos] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [isEmptyData, setIsEmptyData] = useState(false);


  //* stateLoader

  // const [loaderEvaluation, setLoaderEvaluation] = useState(true);

  //? Seteo de modales semáforos
  const [trueCaseModal, setTrueCaseModal] = useState<boolean>(false);
  const [falseCaseModal, setFalseCaseModal] = useState<boolean>(false);



  const getFuturesInfo = async (data: any) => {
    if (!data.employeeEvaluation) return;
  
    try {
      incrementRequests();
      const { data: listFuture } = await getObjectivesPreviewListFuture(
        data.employeeEvaluation?.evaluationPeriod?.evaluationPeriodId,
        data.employeeEvaluation?.employeeEvaluationEvaluatedUserId,
        data.employeeEvaluation?.employeeEvaluationId
      );
      setIsReduxOrContext(false);
      setFutureObjetives(listFuture);
      setEvaluationInfo(data);
    } catch (error) {
      console.error('Error fetching future objectives:', error);
    } finally {
      decrementRequests();
    }
  };

  const sendInfo = (data: any) => {
    // console.log("<AutoEvaluacion> Data de petición", data);
    handleGetEvaluation(
      data?.employeeEvaluation?.employeeEvaluationEvaluatorUserId,
      data?.claTrab,
      data?.employeeEvaluation?.evaluationPeriod?.evaluationPeriodId
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (location.pathname === "/Evaluacion") {
        try {
          incrementRequests();
          handleGetCollaborator(User?.claTrab);
  
          const res = await getCollaboratorServices(User?.claTrab);
          if (res.data.employeeEvaluation === null) {
            setIsEmptyData(true);
          } else {
            setIsEmptyData(false);
          }
          sendInfo(res.data);
          await getFuturesInfo(res.data);
  
          const response = await getEvaluationServices(
            res.data?.employeeEvaluation?.employeeEvaluationEvaluatorUserId,
            res.data?.claTrab,
            res.data?.employeeEvaluation.evaluationPeriod.evaluationPeriodId
          );
  
          if (Object.entries(response.data).length === 0) {
            setErrorPost(true);
            setMesaggeError("La evaluación no ha sido creada para este período");
          } else if (
            response.data.employeeEvaluationObjetivesCurrents !== undefined &&
            response.data.employeeEvaluationObjetivesCurrents.length === 0
          ) {
            console.log("Response data", response.data);
            if (!response.data.esNuevoIngreso) {
              setErrorPost(true);
              setMesaggeError(
                "Actualmente tu evaluador no ha definido tus objetivos."
              );
              setMessageDos(
                "Estamos en espera de esta información para avanzar en el proceso, por favor intenta más tarde. "
              );
              setTitleModal("Definición de objetivos pendiente");
            } else {
              setErrorPost(false);
            }
          } else {
            setErrorPost(false);
          }
  
          if (Evaluation.catStatusEvaluationId !== 7) {
            const statusResponse = await getEvaluationStatusSemaforo(
              res.data?.employeeEvaluation?.employeeEvaluationId
            );
  
            if (statusResponse.data.status) {
              setFalseCaseModal(true);
              setDisableActionEvaluationBtn(true);
            } else {
              setTrueCaseModal(true);
              setDisableActionEvaluationBtn(false);
            }
          }
        } catch (err) {
          console.log(err);
        } finally {
          decrementRequests();
        }
        setChangePeriodEvaluation(true);
      } else {
        setChangePeriodEvaluation(true);
        // getFutureObjectives()
      }
    };
  
    fetchData();
  }, [changePage]);

  // useEffect(() => {

  //   if (evaluationInfo.demanda) {
  //     setLoaderEvaluation(false);
  //   }

  //   if (!evaluationInfo.demanda) {
  //     setTimeout(() => {
  //       setLoaderEvaluation(false);
  //     }, 10000);
  //   }
  // }, [changePage, Collaborator, Evaluation]);

  const [reload, setReload] = useState(false);
  // console.log("evaluationInfo", Evaluation);

  return (
    <MyEvaluationProvider>
      {evaluationInfo.demanda && <ModalEvaluationInactive />}
      {/* {loaderEvaluation && <ModalLoader />} */}
      {/* {isLoading && <ModalLoader />} */}
      {isEmptyData &&
        <ModalEvaluationNotCreated setIsEmptyData={setIsEmptyData} />
      }
      <EvaluationContainer>
        <HeaderEvaluationContainer>
          <EvaluationHeader />
        </HeaderEvaluationContainer>

        <div style={{ marginBottom: '40px' }}>
          <CardEvaluatorNewDesing
            politicsAlert={evaluation?.employeeEvaluationPolitics}
          />
        </div>

        {urlPermisos && (
          // <FiltroPeriod setLoaderEvaluation={setLoaderEvaluation} />
          <FiltroPeriod />
        )}

        <div style={{ marginTop: '40px' }}>
          <EvaluationForm
            setAddTotal={setAddTotal}
            addTotal={addTotal}
            setEvaluation={setEvaluation}
            evaluation={evaluation}
            oportunidad={oportunidad}
            setOportunidad={setOportunidad}
            fortalezas={fortalezas}
            setFortalezas={setFortalezas}
            addTotalEvalaudo={addTotalEvalaudo}
            setAddTotalEvaluado={setAddTotalEvaluado}
          />
        </div>

        {/* <button onClick={() =>  navigate(
        `/evaluacion/20844/2024/1`
        )}>
         reaload
      </button> */}

        <KpisContainer
          addTotal={addTotal}
          setEvaluation={setEvaluation}
          evaluation={evaluation}
          oportunidad={oportunidad}
          fortalezas={fortalezas}
          setReload={setReload}
          reload={reload}
        />

        {/* <BlockEvalutionModal isModalVisible={showBlockEvalutionModal} /> */}

        {errorPost ? (
          <ModalStatusTargetError
            handleClick={setErrorPost}
            modalStatus={errorPost}
            messageShow={true}
            message={mesaggeError}
            messageDos={messageDos}
            titleModal={titleModal}
          />
        ) : null}
        <PermisoNoActivo
          isModalVisible={falseCaseModal}
          setIsModalVisible={setFalseCaseModal}
        />
      </EvaluationContainer>
    </MyEvaluationProvider>
  );
};

export default Evaluation;

// handleGetCollaborator(User?.claTrab);
//     sendInfo(Collaborator)
//     getFuturesInfo(Collaborator)
//     getEvaluationServices(
//       Collaborator?.employeeEvaluation?.employeeEvaluationEvaluatorUserId,
//       Collaborator?.claTrab,
//       Collaborator?.employeeEvaluation.evaluationPeriod.evaluationPeriodId
//     ).then((response) => {
//       if (Object.entries(response.data).length === 0) {
//         setErrorPost(true);
//         setMesaggeError(
//           "La evaluación no ha sido creada para este período"
//         );
//       } else if (
//         response.data.employeeEvaluationObjetivesCurrents !== undefined &&
//         response.data.employeeEvaluationObjetivesCurrents.length === 0
//       ) {
//         setErrorPost(true);
//         setMesaggeError("Tu evaluador aún no te define objetivos");
//       } else {
//         setErrorPost(false);
//       }
//     });

//     if (Evaluation.catStatusEvaluationId !== 7) {

//       getEvaluationStatusSemaforo(Collaborator?.employeeEvaluation?.employeeEvaluationId)
//         .then((response) => {
//           if (response.data.status) {
//             setFalseCaseModal(true)
//             setDisableActionEvaluationBtn(true)

//           } else {
//             setTrueCaseModal(true)
//             setDisableActionEvaluationBtn(false)
//           }
//         }).catch((err) => {
//           setTrueCaseModal(true)
//           setDisableActionEvaluationBtn(false)
//         })
//     }
