import { ApiResponse, IQuestion } from '../Interface/ApiInterface';
import { http } from '../api';

const getAllQuestions = async (query: string) => {
  return new Promise<ApiResponse<IQuestion[]>>((resolve, reject) => {
    http
      .get(`Questions${query}`)
      .then((response) => {
        const { data, meta } = response.data;
        

        const res = {
          data,
          meta
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const postQuestionService = async (question: IQuestion) => {
  return new Promise<ApiResponse<IQuestion>>((resolve, reject) => {
    http
      .post(`Questions`, question)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const putQuestionsServices = async (question: IQuestion) => {
  return new Promise<ApiResponse<IQuestion>>((resolve, reject) => {
    http
      .put(`Questions`, question)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteQuestionsServices = async (id: number) => {
  return new Promise<ApiResponse<string>>((resolve, reject) => {
    http
      .delete(`Questions?idQuestion=${id}`)
      .then((response) => {
        const { data, meta } = response.data;
        
        
        const res = {
          data,
          meta
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getAllQuestions, postQuestionService, putQuestionsServices, deleteQuestionsServices };
