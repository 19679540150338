import styled from "styled-components";

export const ComentariosTitleBox = styled.div`
  margin: 0 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
  font-family: Plus Jakarta Sans;

  .descriptionComentarios {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .titleComentarios {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 38.4px */
    margin: none;
  }

  @media screen and (max-width: 768px) {
    .titleComentarios {
      font-size: 20px;
    }
  }
`;

export const TableKpisContainer = styled.div`
  /* border: 1px solid red; */
`;

export const TitleRowKpisSectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0;
  gap: 8px;

  .wrapperTitleSection {
    display: flex;
    gap: 8px;
    /* margin-bottom: 18px; */
  }
  .titleSection {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 38.4px */
    margin: 0px;
  }

  @media screen and (max-width: 768px) {
    /* padding: 0 28px; */
    .titleSection {
      font-size: 20px;
    }
  }
`;

export const TitleBoxSectionContainer = styled.div`
  /* margin: 0 16px; */
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const PrevTextSectionContainer = styled.p`
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 80%;
`;

export const TablesSection = styled.section`
  margin: 1rem 0;
  padding: 24px;
  background: #ffffff;
  /* border-radius: 16px; */
  margin-bottom: 3.5rem;
  position: relative;
  .containerDate {
    position: absolute;
    right: 20px;
    font-size: 12px;
    font-family: "Plus Jakarta Sans";
    background-color: #F0F0F0;
    border-radius: 16px;
    padding:  7px 10px; 

  }
`;

export const TitleTextGoalValueSection = styled.h3`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 38.4px */
  margin: none;
`;
