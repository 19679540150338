import styled from "styled-components";

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const MainContainer = styled.div`
    display: flex;
    width: 641px;
    padding: 32px;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;
    gap: 32px;
    border-radius: 18px;
    background: #FFF;
`

export const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:8px
`

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:8px
`


export const ButtonCerrar = styled.button`
display: flex;
width: 50%;
height: 40px;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 8px;
flex: 1 0 0;
border-radius: 8px;
border-color: #3B63A2;
border-style: solid;
background: var(--primary-true-blue, #3B63A2);
color: var(--neutrals-white, #FFF);
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Plus Jakarta Sans;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 19.2px */
justify-self: center;
align-self: center;
`

export const Title = styled.h2`
    color: var(--feedback-green, #10B102);
    text-align: center;
    margin:0;

    /* H4 */
    font-family: Plus Jakarta Sans;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 33.6px */
`

export const Subtitle = styled.p`
    color: var(--neutrals-jet, #333335);
    font-feature-settings: 'clig' off, 'liga' off;

    /* Body */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
`

export const ButtonContainer = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content:center ;
`

export const ButtonCancelar = styled.button`
    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border-color: #3B63A2;
    border-width: 1px;
    border-style: solid;
    background: var(--primary-true-blue, #3B63A2);
    color: var(--neutrals-white, #FFF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
`

export const ButtonContinuar = styled.button`
    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border-color: #C9F2FF;
    border-width: 1px;
    border-style: solid;
    background: var(--secondary-blizzard-blue, #C9F2FF);
    color: var(--primary-true-blue, #3B63A2);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
`