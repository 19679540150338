/* eslint-disable react-hooks/exhaustive-deps */
// how create example component for login in reacjs ?
import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';

import ModalStatusError from '../../components/organisms/ConfirmationModals/ModalStatusError';
import ModalStatusSuccessful from '../../components/organisms/ConfirmationModals/ModalStatusSuccessful';
import { useCollaboratorRedux } from '../../redux/Collaborators';
import { getCredentials } from '../../services/collaborator.service';
import { ContainButtonMobile } from '../Admin/Objectives/ObjetivesStyles';
import { BlueButton } from '../Admin/Politics/PoliticsStyles';
import logosGroup from '../Auth/images/logosGroup.svg';
import mosaicoImage from '../Auth/images/mosaico.svg';

import { setAuthorizationHeader } from '../../api';
import ModalLoader from '../../components/organisms/ConfirmationModals/ModalLoader';
import ModalStatusLoader from '../../components/organisms/ConfirmationModals/ModalStatusLoader';
import {
  ContainImages,
  ContainInfoLog,
  ContainLogin,
  InputOtp,
  Logos,
  Mosaico,
  SubTextLogin,
  TextLogin,
  TextOtp,
} from './loguinStyles';
const Login = () => {
  const navigate = useNavigate();
  const valores = window.location.search;
  const {
    User,
    UserPrev,
    Credentials,
    handleGetCollaboratorUserPrev,
    handleGetCollaboratorUser,
    handleGetCredentials,
    setSuccesPost,
    succesPost,
    otp,
    otpLoader,
    setOtpLoader,
  } = useCollaboratorRedux();

  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [sendCode, setSendCode] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [counter, setCouter] = useState(0);

  const handleSubmitVerifyOtp = async (e: any) => {
    e.preventDefault();

    if (value.length === 6) {
      if (+value === +UserPrev.otp) {
        handleGetCollaboratorUser(UserPrev);

        setAuthorizationHeader(UserPrev.token);

        sessionStorage.setItem('token', UserPrev.token);
      } else {
        setShowErrorModal(true);
        setLoading(false);
      }
    }
  };

  const handleResendCodeConfirmation = (e?: any) => {
    if (Object.keys(Credentials).length === 4) {
      handleGetCollaboratorUserPrev(Credentials, true);
      setSendCode(sendCode + 1);
    }
  };

  function recorrerString(string: string, caracter: string) {
    let arregloResultado = [];
    let encontrado = false;

    for (let i = 0; i < string.length; i++) {
      let caracterActual = string[i];

      if (!encontrado) {
        if (caracterActual === caracter) {
          encontrado = true;
        }
      }

      if (encontrado) {
        arregloResultado.push(caracterActual);
      }
    }

    return arregloResultado;
  }

  useEffect(() => {
    const currentURL = window.location.href;

    const arreglo = recorrerString(currentURL, '?');
    arreglo.shift();
    const stringUnida = arreglo.join('');

    if (stringUnida) {
    } else {
      window.location.href = 'https://collado.com.mx/';
    }
  }, []);

  const handelAuthLoding = async () => {
    setOtpLoader(true);
    if (User.claTrab === undefined) {
      const urlLogin = valores.slice(1, valores.length);
      await handleGetCredentials(urlLogin);
      const response = await getCredentials(urlLogin);

      if (Object.keys(response.data).length === 4) {
        await handleGetCollaboratorUserPrev(response.data, false);
        setSendCode(sendCode + 1);
      }
      setOtpLoader(false);
    }
  };

  useEffect(() => {
    // console.log('UserPrev', UserPrev.token);
    localStorage.setItem('tokenUser', JSON.stringify(UserPrev.token));
  }, [UserPrev]);

  useEffect(() => {
    handelAuthLoding();
  }, []);

  useEffect(() => {
    setCouter(counter + 1);
    if (otp !== undefined && counter === 1) {
    }
  }, [otp]);

  useEffect(() => {
    if (User.claTrab !== undefined) {
      switch (User.role) {
        case 'Evaluado':
          setTimeout(() => {
            setLoading(false);
            navigate('/evaluacion');
          }, 3000);
          break;
        case 'Evaluador':
          setTimeout(() => {
            setLoading(false);
            navigate('/Dashboard');
          }, 3000);
          break;
        case 'Director':
          setTimeout(() => {
            setLoading(false);
            navigate('/Dashboard');
          }, 3000);
          break;
        case 'Admin':
          setTimeout(() => {
            setLoading(false);
            navigate('/Dashboard');
          }, 3000);
          break;
        default:
          navigate('/Default.aspx');
          break;
      }
    }
  }, [User]);

  const handleChange = (otp: any) => setValue(otp);

  // console.log("valor de value", otpLoader);

  const renderInput = (props: any, index: number) => {
    return <input {...props} data-testid={`otp-input-${index}`} />;
  };

  return (
    <ContainLogin>
      {otpLoader && <ModalLoader />}
      <ContainImages>
        <Mosaico src={mosaicoImage} alt='MosaicoLogin' />
        <Logos src={logosGroup} alt='grupoLogos' />
      </ContainImages>
      <ContainInfoLog>
        <TextLogin>Inicio de sesión</TextLogin>
        <TextLogin>Confirma tu correo electrónico</TextLogin>
        <SubTextLogin>Ingresa el código que recibiste</SubTextLogin>
        <TextOtp>Código de verificación</TextOtp>
        <OtpInput
          value={value}
          onChange={handleChange}
          numInputs={6}
          separator={<div>&nbsp; &nbsp;</div>}
          placeholder='000000'
          inputStyle={InputOtp}
          data-testid='otp-input'
        />
        <br></br>
        <ContainButtonMobile>
          {loading === false ? (
            <BlueButton
              disabled={value.length === 6 ? false : true}
              onClick={async (e) => {
                handleSubmitVerifyOtp(e);
              }}
              style={{ width: '171px' }}
              data-testid='button-login'
            >
              Confirmar
            </BlueButton>
          ) : (
            <Spinner animation='border' variant='primary' />
          )}
        </ContainButtonMobile>
        <SubTextLogin
          style={{
            textDecorationLine: 'underline',
            cursor: 'pointer',
            marginTop: '40px',
          }}
          onClick={(e) => handleResendCodeConfirmation(e)}
        >
          Reenviar código de verificación
        </SubTextLogin>
      </ContainInfoLog>
      {succesPost ? (
        <ModalStatusSuccessful
          handleClick={setSuccesPost}
          modalStatus={succesPost}
          messageShow
          message={'Código de verificación reenviado'}
        />
      ) : null}
      {otpLoader ? (
        <ModalStatusLoader
          handleClick={setOtpLoader}
          modalStatus={otpLoader}
          messageShow
          message={
            'Espera un momento mientras obtenemos la información de tu usuario'
          }
        />
      ) : null}
      {showErrorModal && (
        <ModalStatusError
          handleClick={setShowErrorModal}
          modalStatus={showErrorModal}
          messageShow
          message={
            'Parece que el código OTP que ingresaste no es correcto. Por favor, intenta ingresarlo nuevamente para completar la verificación.'
          }
          titleModal={'Código incorrecto'}
        />
      )}
    </ContainLogin>
  );
};

export default Login;
