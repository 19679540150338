import { useEffect, useState } from "react";
import { StatusDashboardSelect } from "../stylesDashboard/NewStylesDashboard";
import ModalAdvertirPermisos from "./AlertChangePermisos";

const PermisosTableUserDashboard = ({
    item
}: any) => {

    const [showModal, setShowModal] = useState(false);


    // const upDateAdmin = async (collaborator: any, userRole: any) => {
    //     const { message } = await postSetAdminPermisos(
    //         collaborator.claTrab,
    //         userRole
    //     );


    // };

    const [permisosUser, setPermisosUser] = useState<any>();
    const [newRol, setNewRol] = useState<any>()

    const validateRole = (role: any) => {
        if (role.administrador === true) {
            setPermisosUser("Administrador");
        }

        if (role.auditor === true) {
            setPermisosUser("Auditor");
        }

        if (
            role.default === true
        ) {
            setPermisosUser("Default");
        }
    };

    useEffect(() => {
        validateRole(item);
    }, [item]);




    return (
        <>
            <StatusDashboardSelect
                value={permisosUser}
                onChange={(e) => {
                    setShowModal(true)
                    setNewRol(e.target.value);
                }}
            >
                <option value="Administrador">
                    Administrador TI
                </option>
                <option value="Auditor">
                    Auditor
                </option>
                <option value="Default">
                    Default
                </option>
            </StatusDashboardSelect >
            <ModalAdvertirPermisos
                showModalDesactivar={showModal}
                setShowModalDesactivar={setShowModal}
                changeTypeUser={setPermisosUser}
                newType={newRol}
                item={item}
            />
        </>
    );
};

export default PermisosTableUserDashboard;