import { useEffect, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "../Evaluation/useSemesterYearNow";

export const useAbleFutureObjetivesDefaults = () => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();
  //** Hooks

  //** States
  const [ableSelectDefaultObj, setAbleSelectDefaultObj] = useState(true);

  // console.log("Evaluationnnnnn", Evaluation.catStatusEvaluationId);

  useEffect(() => {
    if (User.role === "Auditor") {
      setAbleSelectDefaultObj(true);
    }
    if (User.role === "Admin TI") {
      //**  +++++++++++++++++++++++++++ EVALUACIÓN +++++++++++++++++++++++
      if (!urlPermisos) {
        if (Evaluation.catStatusEvaluationId === 7) {
          if (
            evaluationAttribute.edit_evaluation_complete === 1 &&
            evaluationAttribute.edit_values_global_objetives === 1 &&
            isActualDate
          ) {
            setAbleSelectDefaultObj(false);
          } else {
            setAbleSelectDefaultObj(true);
          }
        } else {
          if (evaluationAttribute.edit_values_global_objetives === 1) {
            if (Evaluation.catStatusEvaluationId < 6) {
              setAbleSelectDefaultObj(false);
            } else {
              setAbleSelectDefaultObj(true);
            }
          } else {
            setAbleSelectDefaultObj(true);
          }
        }
      }

      //**  +++++++++++++++++++++++++++   AUTOEVALUACIÓN +++++++++++++++++++++++
      if (urlPermisos) {
        setAbleSelectDefaultObj(true);
      }
    } else {
      setAbleSelectDefaultObj(true);
    }
  }, [
    Evaluation.catStatusEvaluationId,
    User.role,
    evaluationAttribute.edit_evaluation_complete,
    evaluationAttribute.edit_values_global_objetives,
    isActualDate,
    urlPermisos,
  ]);

  // console.log("Se habilita", dateNow != evaluationDate);
  return {
    ableSelectDefaultObj,
  };
};

/*
evaluationAttribute.edit_evaluation_complete === 1 


      (User.role === "Admin TI" ||
      User.role === "Admin" ||
      User.role === "Admin RH")
*/

//? Si el usuario tiene habilitado el atributo
//? Si el usuario pertenece a alguno de estos roles
//? Si el usuario está en una evaluación
//? entonces esta sección se podrá editar
