import styled from "styled-components";

export const boxCard = {
  // width: 'fit-content',
  // padding: '8px 40px',
  // borderRight: '0.548503px solid #E5E5EF',
};

export const cardBody = {
  padding: "0",
  display: "flex",
  alignItems: "center",
  // gap: "1%",
};

export const underlineTitle = {
  // borderBottom: "0.548503px solid #E5E5EF",
  display: "flex",
  justifyContent: "center",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: "700"

};

export const DashboarCardItem = styled.div`
  /* width: 28rem; */
  display: flex;
  width: 362px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 16px;
  background: var(--background-light-gray, #f8f8f8);
  box-shadow: 0px 4px 8px 0px rgba(6, 17, 46, 0.18);
`;

export const DataBox = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  gap: 1rem;
`;

export const ItemBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 4.39px;
`;

export const Completed = styled.p`
  color: #F27405;

  /* Label */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0px;
`;

export const Missing = styled.p`
  margin: 0;
  color: #939396;
`;

export const GoalTitleCard = styled.p`
  font-weight: 700;
  font-size: 18px;
  /* line-height: 120%; */
  color: #3b63a2;
`;
