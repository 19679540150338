/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from "react";
import styled from "styled-components";
import caution from "../../../../../assets/infoYellow.svg";

type PropTypeBg = {
  display: boolean;
};
interface Labels {
  textBody?: string;
  textBodyOk?: string;
  textButtonAct?: string;
  textButtonClose?: string;
  estado?: boolean;
  cambiarEstado?: any;
  performAction?: any;
  title?: string;
  messageShow?: boolean;
  handleClick?: any;
  textBodyQuestion?:any
}

export const Caution: FC<Labels> = ({
  textBody,
  textButtonAct,
  textButtonClose,
  estado,
  cambiarEstado,
  title,
  handleClick,
  textBodyQuestion
}) => {
  return (
    <>
      {estado && (
        <>
          <ModalAlertWrapper
            display={estado}
          >
            <div className="modalWrapper">
              <div className="close">
                <div className="title">
                  <img src={caution} className="image" alt="AlertModalIcon" />
                  {title}
                </div>
              </div>
              <ContainerModalText>
                <div className="textContainer">
                  {textBody}
                </div>
              </ContainerModalText>
              <ContainerModalText>
                <div className="textContainer">
                  {textBodyQuestion}
                </div>
              </ContainerModalText>
              <FooterModal>
                <ButtonModalCancel
                  onClick={() => cambiarEstado(false)}
                >
                  {textButtonClose}
                </ButtonModalCancel>
                <ButtonModalSave
                  onClick={() => {
                    handleClick();
                    cambiarEstado(false);
                  }}
                >
                  {textButtonAct}
                </ButtonModalSave>
              </FooterModal>
            </div>
          </ModalAlertWrapper>
        </>
      )}
    </>
  );
};

export default Caution;


export const ContainerModalText = styled.div`
  width: 100%;
  //padding: 14px 21px;

  .textContainer {
    font-size: 16px;
    color: #333335;
    font-weight: 500;
    //padding-bottom: 5px;
  }

  .containerModal {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: end;
    font-size: 14px;

    .txt {
      margin-top: 8px;
      padding: 8px;
    }

    .containerTxt {
      background-color: white;
      border-radius: 8px;
      font-weight: 700;
      color: black;
      font-size: 14px;
      padding: 8px;
      margin: 8px;
     margin-right: 24px;
      width: 83%;
    }
  }
`;


export const FooterModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
 // padding: 14px 21px;
`;

export const ButtonModalSave = styled.div`
  background-color: #C9F2FF;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #3B63A2;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const ButtonModalCancel = styled.div`
  background-color: #3b63a2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: white;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  align-content: center;
`;


export const ModalAlertWrapper = styled.div.attrs(
  (props: PropTypeBg) => ({})
) <PropTypeBg>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.display ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-family: "Plus Jakarta Sans";

  .modalWrapper {
    width: 80%;
    max-width: 641px;
   // height: 296px;
    background-color: #ffffff;
    position: absolute;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    gap:32px;
    @media (max-width: 1067px) {
      height: 50%;
    }
  }
  .close {
    //padding: 20px 0px 16px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;


    .title {
      font-size: 20px;
      font-weight: 700;
      color: var(--feedback-red, #3B63A2);
      display: flex;
      //justify-content: center;
      align-items: center;
      width: 100%;
      margin:0;
      gap: 8px;
      .image {
        /* height: 48.38px; */
        /* width: 38.64px; */
      }
    }
    .closeModal {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      position: absolute;
      top: 6px;
      right: 10px;
    }
  }
`;