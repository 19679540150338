import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../../context/EvaluationContext";
import { validateSemestre } from "../../../../context/validacionesFiltros";
import { getCurrentYearAndSemesterObj } from "../../../../hooks/Evaluation/useSemesterYearNow";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../../redux/Evaluations";
import {
  BtnPeriodClean,
  BtnPeriodSearch,
  ContainerFiltros,
  HeaderFiltrosBody,
} from "../styles/FiltroPeriod.styles";
import SelectCustom from "./SelectCustom";
import { useLoader } from "../../../../context/LoaderContext";

interface YearOption {
  year: number;
}

interface PeriodName {
  evaluationPeriodId: number;
  evaluationPeriodNumberOfYear: number;
}

const { semestre, year } = getCurrentYearAndSemesterObj();

// const FiltroPeriod = ({ setLoaderEvaluation }: any) => {
const FiltroPeriod = () => {
  //* Context
  const { changePage } = useContext(EvaluationContext) as EvaluationContextType;

  const {
    onChangeOptionYear,
    onChangeOptionSemestre,
    selectedOptionPeriod,
    selectedOptionYear,
    years,
    evaluationPeriodName,
    getYearsOptions,
    setSelectedOptionYear,
    periodAutoEvaluation,
    handleValidatePeriod,
    getPeriods,
    setRealizedSearch,
    realizedSearch,
    setPeriodoDataInfo,
    idActualPeriod,
  } = useContext(ChangePeriodContext) as ChangePeriodContextType;

  //**  Redux
  const { User } = useCollaboratorRedux();
  const { handleGetEvaluation, Evaluation } = useEvaluationRedux();

  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { incrementRequests, decrementRequests } = useLoader();

  const handleSearchPeriod = async () => {
    if (!selectedOptionPeriod || !selectedOptionYear) {
      return;
    }

    incrementRequests();

    try {
      // setLoaderEvaluation(true);
      const Anio = `Semestre ${String(
        validateSemestre(selectedOptionPeriod)
      )} / ${selectedOptionYear}`;
  
      setPeriodoDataInfo(Anio);
      await handleGetEvaluation(
        Evaluation?.employeeEvaluationEvaluatorUser?.claTrab,
        User?.claTrab,
        Number(periodAutoEvaluation)
      );
  
      handleValidatePeriod(periodAutoEvaluation);
      setRealizedSearch(realizedSearch + 1);
  
      // setTimeout(() => {
      //   setLoaderEvaluation(false);
      // }, 10000);
    } catch (error) {
      console.log(error);
    } finally {
      decrementRequests();
    }
  };

  const handleCleanPeriod = async () => {
    // setLoaderEvaluation(true);

    incrementRequests();

    try {
      const Anio = `Semestre ${String(
        validateSemestre(selectedOptionPeriod)
      )} / ${selectedOptionYear}`;
  
      setPeriodoDataInfo(Anio);
  
      await handleGetEvaluation(
        Evaluation?.employeeEvaluationEvaluatorUser?.claTrab,
        User?.claTrab,
        idActualPeriod
      );
  
      setSelectedOptionYear(year);
      handleValidatePeriod(`${idActualPeriod},${semestre}`);
      getPeriods(year, true);
      setRealizedSearch(realizedSearch + 1);
  
      // setTimeout(() => {
      //   setLoaderEvaluation(false);
      // }, 10000);
    } catch (error) {
      console.log(error);
    } finally {
      decrementRequests();
    }
  };

  useEffect(() => {
    getYearsOptions();
    setSelectedOptionYear(year);

    if (semestre && year) {
      const Anio = `Semestre ${String(semestre)} / ${year}`;
      setPeriodoDataInfo(Anio);
    }
  }, [changePage]);

  useEffect(() => {
    const periodId = { valuePeriod: selectedOptionPeriod || '' };
    localStorage.setItem("periodId", JSON.stringify(periodId));
  }, [selectedOptionPeriod]);

  useEffect(() => {
    if (years.length > 0 && evaluationPeriodName.length > 0 && isInitialLoad) {
      // Encontrar el año más reciente disponible
      const latestYear = Math.max(...years.map((y: YearOption) => y.year));
      setSelectedOptionYear(latestYear);
      onChangeOptionYear({ target: { value: latestYear } });

      // Encontrar el semestre más reciente disponible en el año más reciente
      const latestPeriod = evaluationPeriodName.reduce((latest: PeriodName, current: PeriodName) => {
        return current.evaluationPeriodNumberOfYear > latest.evaluationPeriodNumberOfYear
          ? current
          : latest;
      }, evaluationPeriodName[0]);

      if (latestPeriod?.evaluationPeriodId) {
        onChangeOptionSemestre({ target: { value: `${latestPeriod.evaluationPeriodId},${latestPeriod.evaluationPeriodNumberOfYear}` } });
        setIsInitialLoad(false);
      }
    } else if (years.length > 0 && isInitialLoad) {
      const mostRecentYear = Math.max(...years.map((y: YearOption) => y.year));
      setSelectedOptionYear(mostRecentYear);
      onChangeOptionYear({ target: { value: mostRecentYear } });
      setIsInitialLoad(false);
    }
  }, [years, evaluationPeriodName, isInitialLoad]);

  useEffect(() => {
    if (Evaluation?.evaluationPeriod) {
      const { evaluationPeriodId, evaluationPeriodNumberOfYear, evaluationPeriodYear } = Evaluation.evaluationPeriod;

      setSelectedOptionYear(evaluationPeriodYear);
      onChangeOptionSemestre({
        target: {
          value: `${evaluationPeriodId},${evaluationPeriodNumberOfYear}`
        }
      });
    }
  }, [Evaluation]);

  useEffect(() => {
    if (!isInitialLoad && selectedOptionPeriod && selectedOptionYear) {
      searchButtonRef.current?.click();
    }
  }, [selectedOptionPeriod]);

  return (
    <ContainerFiltros>
      <HeaderFiltrosBody>
        <div className="titlePeriod">Filtros</div>
        <div className="bodyPeriod">
          <div className="groupSelects">
            <SelectCustom
              valueSelect={selectedOptionYear}
              nameSelect="year"
              nameLabel="Año"
              hadleChange={onChangeOptionYear}
            >
              {years.map(({ year }: YearOption) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </SelectCustom>
            <SelectCustom
              valueSelect={selectedOptionPeriod}
              nameSelect="semester"
              nameLabel="Semestre"
              hadleChange={onChangeOptionSemestre}
              disabled={evaluationPeriodName.length === 0}
            >
              {evaluationPeriodName.map((periodName: PeriodName) => (
                <option
                  key={periodName?.evaluationPeriodId}
                  value={`${periodName?.evaluationPeriodId},${periodName?.evaluationPeriodNumberOfYear}`}
                >
                  {periodName?.evaluationPeriodNumberOfYear}
                </option>
              ))}
            </SelectCustom>
          </div>

          <div className="groupBtns">
            <BtnPeriodClean onClick={handleCleanPeriod}>Limpiar</BtnPeriodClean>
            <BtnPeriodSearch ref={searchButtonRef} onClick={handleSearchPeriod}>Buscar</BtnPeriodSearch>
          </div>
        </div>
      </HeaderFiltrosBody>
    </ContainerFiltros>
  );
};

export default FiltroPeriod;
