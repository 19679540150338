import { ChangeEventHandler, FC, LegacyRef, useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import {
  ButtonFileManual,
  ContainButtonMobileDos,
  ContainButtonMobileTres,
  ContainerBar,
  ContainerCarga,
  ContainerDetaiCarga,
  ContainerUpload,
  Div,
  ImgSize,
  Paragraph,
  RowBox,
  SubTitle,
  TextCarga,
  Title,
  UploadBox,
} from "../../pages/Admin/Objectives/ObjetivesStyles";
import cloud from "../../pages/Admin/Objectives/images/cloud.svg";
import filed from "../../pages/Admin/Objectives/images/file.svg";
import pencil from "../../pages/Admin/Objectives/images/pencil.svg";
import {
  ButtonFileDos,
  WrapperButton,
} from "../../pages/Admin/Politics/PoliticsStyles";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useObjetives } from "../../redux/Objetives";

interface Upload {
  accept: string;
  txtFormat: string;
  fileSizeMB: number;
  ref?: LegacyRef<HTMLInputElement>;
  multiple?: boolean;
  onChangeUpload?: ChangeEventHandler<HTMLInputElement>;
  setShowModal?: any;
  file?: File;
  dataFirst?: any;
  dataSecond?: any;
}
const UploadFile: FC<Upload> = ({
  accept,
  fileSizeMB,
  txtFormat,
  ref,
  multiple,
  onChangeUpload,
  setShowModal,
  file,
  dataFirst,
  dataSecond,
}) => {
  const [showWindow, setShowWindow] = useState(false);
  const { handleSetEditObjetive } = useObjetives();
  const { User } = useCollaboratorRedux();
  const handleShowModal = async (e: any) => {
    e.preventDefault();
    await handleSetEditObjetive("undefined");
    setShowModal(true);
  };

  const onInputClick = (event: any) => {
    event.target.value = "";
  };

  useEffect(() => {
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);

  return (
    <>
      <UploadBox>
        {User?.attributeSections[dataFirst]?.value === 1 && (
          <ContainerUpload>
            <RowBox>
              <img src={cloud} alt="upload" style={{ marginRight: "18px" }} />
              <div>
                <Paragraph>Selecciona el archivo a cargar</Paragraph>
                <SubTitle>
                  Formato de {txtFormat} peso de{" "}
                  {file && file?.size / 1000} KB
                </SubTitle>
              </div>
            </RowBox>

            <ContainButtonMobileDos>
              <WrapperButton>
                Seleccionar
                <ButtonFileDos
                  onChange={onChangeUpload}
                  type="file"
                  name="src-file1"
                  aria-label="Archivo"
                  onClick={onInputClick}
                  accept=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
              </WrapperButton>
            </ContainButtonMobileDos>
          </ContainerUpload>
        )}

        {User?.attributeSections[dataFirst]?.value === 1 && (
          <ContainButtonMobileTres>
            <WrapperButton>
              Seleccionar
              <ButtonFileDos
                onChange={onChangeUpload}
                type="file"
                name="src-file1"
                aria-label="Archivo"
                onClick={onInputClick}
                accept=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </WrapperButton>
          </ContainButtonMobileTres>
        )}

        {User?.attributeSections[dataSecond]?.value === 1 && (
          <ContainerUpload>
            <Div>
              <img src={pencil} alt="edit" style={{ marginRight: "18px" }} />
              <Div>
                <Paragraph>Agregar manualmente</Paragraph>
              </Div>
            </Div>
            <ContainButtonMobileDos>
              <ButtonFileManual
                onClick={(e) => {
                  handleShowModal(e);
                }}
              >
                Agregar
              </ButtonFileManual>
            </ContainButtonMobileDos>
          </ContainerUpload>
        )}

        {User?.attributeSections[dataSecond]?.value === 1 && (
          <ContainButtonMobileTres>
            <ButtonFileManual
              onClick={(e) => {
                handleShowModal(e);
              }}
            />
          </ContainButtonMobileTres>
        )}
      </UploadBox>

      <div>
        <Title>Previsualización</Title>
        <RowBox>
          <ImgSize src={filed} alt="file" />
          <ContainerCarga>
            <ContainerDetaiCarga>
              <TextCarga>{txtFormat}</TextCarga>
              <TextCarga>{file && file?.size / 1000} kB</TextCarga>
            </ContainerDetaiCarga>
            <ContainerBar>
              <ProgressBar
                variant="warning"
                now={file?.size ? 100 : 0}
              ></ProgressBar>
            </ContainerBar>
          </ContainerCarga>
        </RowBox>
      </div>
    </>
  );
};

export default UploadFile;

export const PrevisualizacionComponent = ({ txtFormat, file }: any) => {
  return (
    <div>
      <Title>Previsualización</Title>
      <RowBox>
        <ImgSize src={filed} alt="file" />
        <ContainerCarga>
          <ContainerDetaiCarga>
            <TextCarga>{txtFormat}</TextCarga>
            <TextCarga>{file && file?.size / 1000}  kB</TextCarga>
            {/* <TextCarga>{file?.size.toString().slice(0, 2)} kB</TextCarga> */}
          </ContainerDetaiCarga>
          <ContainerBar>
            <ProgressBar
              variant="warning"
              now={file?.size ? 100 : 0}
            ></ProgressBar>
          </ContainerBar>
        </ContainerCarga>
      </RowBox>
    </div>
  );
};
