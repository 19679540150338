import { useState } from "react";
import { Button, Col, Form, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import colaboratorsIconTitle from "../../../assets/icons/title-Icons.svg";
import { Select } from "../../../components/common/InputsByType";
import {
  AdminContainer,
  BoxMobile,
  ButtonColBox,
  ColaboratorsReportsTitle,
  ColaboratorsReportsTitleBox,
  ContainPagination,
  ContainerTableFixed,
  FilterOptionsBox,
  FliterByTiemRow,
  PreviewColaboratorsText,
} from "../../../pages/Admin/Dashboard/stylesDashboard/AdminStyles";
import TableInfo from "../../../pages/Admin/TableInfo";
import {
  EvaluatedHeaderContainer,
  HeaderTitle,
} from "../../atoms/ColaborationHeaderEvaluation/ColaboratorHeaderEvaluationStyles";
import { StyleButon, StyleButonB } from "../../atoms/Modals/styleModals";
import PaginationTable from "../../atoms/PaginationTable";
const EmployeeReport = () => {
  const [inputValues, setInputValues] = useState({});
  const onChangeTextField = () => {};
  return (
    <AdminContainer>
      <EvaluatedHeaderContainer>
        <HeaderTitle>Evaluación de colaboradores</HeaderTitle>
      </EvaluatedHeaderContainer>
      <FliterByTiemRow>
        <Select
          id="AnioReporte"
          title="Año"
          options={[{ text: "Corporativo", value: "1" }]}
          name="AnioReporte"
          onChange={onChangeTextField}
          value={inputValues}
        />
        <Select
          id="SemetreReporte"
          title="Semestre"
          options={[{ text: "Corporativo", value: "1" }]}
          name="SemetreReporte"
          onChange={onChangeTextField}
          value={inputValues}
        />
      </FliterByTiemRow>
      <Row>
        <ColaboratorsReportsTitleBox>
          <img src={colaboratorsIconTitle} alt="colaboradores icono" />
          <div style={{ marginLeft: "8px" }}>
            <ColaboratorsReportsTitle>
              Reporte de colaboradores
            </ColaboratorsReportsTitle>
            <PreviewColaboratorsText>
              Vista previa de los colaboradores al cargo
            </PreviewColaboratorsText>
          </div>
        </ColaboratorsReportsTitleBox>
      </Row>
      <div style={{ background: "white", padding: "2%" }}>
        <Row>
          <Tabs defaultActiveKey="General" id="uncontrolled-tab-example">
            <Tab eventKey="General" title="General" style={{ padding: "0" }}>
              <FilterOptionsBox>
                <BoxMobile>
                  <Col>
                    <InputGroup style={{ marginBottom: "8px" }}>
                      <InputGroup.Text id="basic-addon3">
                        Clave trabajador
                      </InputGroup.Text>
                      <Form.Control placeholder="######" />
                    </InputGroup>
                    <InputGroup style={{ marginBottom: "8px" }}>
                      <InputGroup.Text id="basic-addon3">
                        Localidad
                      </InputGroup.Text>
                      <Form.Control placeholder="All" />
                    </InputGroup>
                    <InputGroup style={{ marginBottom: "8px" }}>
                      <InputGroup.Text id="basic-addon3">
                        Fecha de ingreso
                      </InputGroup.Text>
                      <Form.Control placeholder="All" />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup style={{ marginBottom: "8px" }}>
                      <InputGroup.Text id="basic-addon3">
                        Nombre
                      </InputGroup.Text>
                      <Form.Control placeholder="Test Nombre" />
                    </InputGroup>
                    <InputGroup style={{ marginBottom: "8px" }}>
                      <InputGroup.Text id="basic-addon3">Área</InputGroup.Text>
                      <Form.Control placeholder="All" />
                    </InputGroup>
                    <InputGroup style={{ marginBottom: "8px" }}>
                      <InputGroup.Text id="basic-addon3">
                        Estatus
                      </InputGroup.Text>
                      <Form.Select aria-label="Default select example">
                        <option>Filter members</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup style={{ marginBottom: "8px" }}>
                      <InputGroup.Text id="basic-addon3">
                        Puesto
                      </InputGroup.Text>
                      <Form.Control placeholder="Filter order number" />
                    </InputGroup>
                    <InputGroup style={{ marginBottom: "8px" }}>
                      <InputGroup.Text id="basic-addon3">
                        Departamento
                      </InputGroup.Text>
                      <Form.Control placeholder="All" />
                    </InputGroup>
                  </Col>
                  <Col>
                    <ButtonColBox>
                      <Button variant="primary" style={StyleButon}>
                        Buscar con filtros
                      </Button>
                      <Button variant="secondary" style={StyleButonB}>
                        Limpiar
                      </Button>
                    </ButtonColBox>
                  </Col>
                </BoxMobile>
              </FilterOptionsBox>
            </Tab>
          </Tabs>
        </Row>

        <ContainerTableFixed>
          <ContainPagination>
            <PaginationTable />
          </ContainPagination>
          <TableInfo titleButton="AgendarEntrevista" />
        </ContainerTableFixed>
      </div>
    </AdminContainer>
  );
};

export default EmployeeReport;
