/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { IQuestion } from "../../Interface/ApiInterface.js";
import {
  StyleButon,
  StyleButonB,
} from "../../components/atoms/Modals/styleModals";
import { Select, TextField } from "../../components/common/InputsByType";
import FormModalNotify from "../../components/organisms/ConfirmationModals/FormModalNotify";
import {
  BoxMobileModalQuestion,
  TitleModal,
} from "../../pages/Admin/Objectives/ObjetivesStyles.js";
import { useQuestions } from "../../redux/Questionnaires";

const onlyWords = ["QuestionModal", "categoriaModalQuestion"];

interface Options {
  value?: string;
  text?: string;
  estado?: boolean;
  cambiarEstado?: any;
  questionData?: IQuestion;
  typeEdit?: string;
  getQuestions?: any;
  inputValues?: any;
  setInputValues?: any;
  setModalSucces?: any;
  message?: string;
  setChangeQuestion?: any;
}

const ModalQuestion: FC<Options> = ({
  estado,
  cambiarEstado,
  questionData,
  typeEdit,
  getQuestions,
  setInputValues,
  inputValues,
  setModalSucces,
  setChangeQuestion,
}) => {
  const { handlePostQuestion, handlePutQuestion } = useQuestions();

  //! MODAL ERROR
  const [modalNotify, setModalNotify] = useState(false);
  const [inputLength, setInputLength] = useState(0);
  const [mensaje, setMensaje] = useState("");
  useEffect(() => {
    if (inputLength > 279) {
      setModalNotify(true);
      setMensaje("El objetivo no debe exceder los 280 caracteres");
    } else {
      setModalNotify(false);
    }
  }, [inputLength]);

  //! MODAL ERROR

  const optionsQuestion = [
    { text: "Selecciona...", value: "" },
    { text: "Desempeño laboral", value: "1" },
    { text: "Factor humano/actitud", value: "2" },
    { text: "Habilidades", value: "3" },
  ];

  const onChangeTextField = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputLength(e.target.value.length);
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onChangeSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name } = e.target;
    if (onlyWords.includes(name)) {
      setInputValues({
        ...inputValues,
        categoriaModalQuestion: e.target.options[e.target.selectedIndex].value,
      });
    }
  };

  const handleSubmit = async () => {
    if (inputValues.QuestionModal === "") return;

    if (inputValues.categoriaModalQuestion === "") return;

    if (
      typeEdit === "EDITAR" &&
      (questionData?.idQuestion === undefined ||
        questionData?.idQuestion === null)
    )
      return;

    switch (typeEdit) {
      case "CREAR":
        handlePostQuestion({
          textQuestion: inputValues.QuestionModal,
          status: 1,
          idCatQuestion: parseInt(inputValues.categoriaModalQuestion),
        } as IQuestion);

        setModalSucces(true);
        setChangeQuestion(false);
        break;
      case "EDITAR":
        handlePutQuestion({
          idQuestion: questionData?.idQuestion,
          textQuestion: inputValues.QuestionModal,
          status: 1,
          idCatQuestion: parseInt(inputValues.categoriaModalQuestion),
        } as IQuestion);
        setModalSucces(true);
        setChangeQuestion(true);
        break;
      default:
    }

    cambiarEstado(false);
  };

  useEffect(() => {
    //
    if (
      questionData?.textQuestion !== undefined ||
      questionData?.idCatQuestion !== undefined
    ) {
      setInputValues({
        ...inputValues,
        QuestionModal:
          questionData.textQuestion !== null
            ? questionData.textQuestion.toString()
            : "",
        categoriaModalQuestion:
          questionData.idCatQuestion !== null
            ? questionData.idCatQuestion.toString()
            : "",
      });
    } else {
      setInputValues({
        ...inputValues,
        QuestionModal: "",
        categoriaModalQuestion: "",
      });
    }
  }, [questionData]);
  return (
    <>
      {estado && (
        <Modal
          show={estado}
          backdrop="static"
          onHide={() => cambiarEstado(false)}
        >
          <Modal.Header closeButton>
            <TitleModal>
              {typeEdit === "VER"
                ? "Ver"
                : typeEdit === "EDITAR"
                ? "Editar"
                : "Agregar nueva"}{" "}
              pregunta
            </TitleModal>
          </Modal.Header>
          <Modal.Body>
            <BoxMobileModalQuestion>
              <TextField
                maxLength={280}
                title="Pregunta"
                id="QuestionModal"
                placeholder="Coorporativo"
                name="QuestionModal"
                onChange={onChangeTextField}
                value={inputValues}
                disabled={typeEdit != "EDITAR" && typeEdit != "CREAR" && true}
              />
              <Select
                id="Categoria"
                title="Categoría"
                options={optionsQuestion}
                name="categoriaModalQuestion"
                onChange={onChangeSelection}
                value={inputValues}
                disabled={typeEdit != "EDITAR" && typeEdit != "CREAR" && true}
              />{" "}
            </BoxMobileModalQuestion>
            {modalNotify ? <FormModalNotify mensaje={mensaje} /> : null}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                cambiarEstado(false);
                setInputValues({
                  ...inputValues,
                  QuestionModal: "",
                  categoriaModalQuestion: "",
                });
              }}
              style={StyleButonB}
            >
              Cancelar
            </Button>
            {(typeEdit === "EDITAR" || typeEdit === "CREAR") && (
              <Button
                variant="primary"
                disabled={
                  inputValues.categoriaModalQuestion != "" &&
                  inputValues.QuestionModal != ""
                    ? false
                    : true
                }
                style={StyleButon}
                onClick={() => {
                  handleSubmit().finally(() => getQuestions());
                }}
              >
                Guardar pregunta
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ModalQuestion;
