import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const urlApiHttp = "https://apiauthdev.collado.therocketcode.com/";
// const http = axios.create({baseURL: 'https://localhost:7297/api/'});
const http = axios.create({
  baseURL: "https://apistg.collado.therocketcode.com/api/",
});
// test
// const http = axios.create({baseURL: 'https://apistg.collado.therocketcode.com/api/'});

const token = sessionStorage.getItem("token");

http.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
http.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET, PATCH, PUT, POST, DELETE, OPTIONS";
http.defaults.headers.common["Content-Type"] = "application/json";

export const setAuthorizationHeader = (authToken: string) => {
  if (authToken) {
    http.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
  } else {
    // console.error('Token de autenticaci�n inv�lido.');
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};

const config = {
  headers: {
    "Content-Length": "10mb",
    "Accept-Encoding": ["gzip", "deflate", "br"],
    "Content-Type": "multipart/form-data",
  },
};

http.interceptors.request.use(
  (request: AxiosRequestConfig): any => {
    const reduxPersist = localStorage.getItem("LDBCOLLADO");
    const newRequest: AxiosRequestConfig = request;
    if (reduxPersist) {
      // const { token } = JSON.parse(reduxPersist).auth;
      // if (token !== undefined) newRequest.headers ? newRequest.headers.Authorization = `Bearer ${token}` : newRequest.headers = { Authorization: `Bearer ${token}` };
      return newRequest;
    }

    return request;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => Promise.reject(error)
);

export { config, http };
