import { FC } from "react";
import NotificationIcon from "../../../assets/icons/NotificationIcon";
import { FormModal } from "../../../pages/Admin/Objectives/ObjetivesStyles";

interface Labels {
  mensaje?: string;
}

export const FormModalNotify: FC<Labels> = ({ mensaje }) => {
  return (
    <FormModal>
      <div className="notiContainer">
        <NotificationIcon fill="#FF0000" width="24px" height="54px" />
        Notificación
      </div>
      {mensaje}
    </FormModal>
  );
};

export default FormModalNotify;
