import { useContext } from "react";
import iconOne from "../../../../assets/evaluaition-icons/icon-one.svg";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import { validateSemestre } from "../../../../context/validacionesFiltros";
import { useUrlPermisos } from "../../../../hooks/Evaluation";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import {
  EvaluationResults,
  HumanFactoAttitude,
  JobPerformanceForm,
  SkillsList,
} from "../../../atoms/EvaluationForms/EvaluationForms";
import { GeneralInfoEvaluationModal } from "../../../atoms/EvaluationForms/EvaluationModals/EvaluationModals";
import { EvaluationResultsContainer } from "../EvaluationFormStyles";
import {
  QuestionListContainerMetricasSection,
  TitleBoxMetricasSection,
  TitleRowMetricasSection,
  TitleTextBoxMetricasSection,
  WrapperSectionMetricas,
} from "./EvaluationMetricasStyles";

const EvaluationMetricas = ({
  optionsInstructions,
  setEvaluator,
  evaluator,
  evaluation,
  addJobPerformance,
  setAddJobPerformance,
  setEvaluation,
  addJobPerformanceEvaluado,
  setAddJobPerformanceEvaluado,
  addTotal,
  addTotalEvalaudo,
  addHumanFactor,
  setAddHumanFactor,
  addHumanFactorEvaluador,
  setAddHumanFactorEvaluador,
  addSkills,
  setAddSkills,
  addSkillsEvaluado,
  setAddSkillsEvaluador,
}: any) => {
  //**  Redux
  const { evaluationAttribute } = useCollaboratorRedux();

  const { changePeriodEvaluation, periodoDataInfo } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;
  const { urlPermisos } = useUrlPermisos();

  
  const periodLocal = localStorage.getItem("periodId");
  const periodParsed = JSON.parse(periodLocal ?? "{}");
  const { valuePeriod } = periodParsed;
  const principalFilters = localStorage.getItem("principalFilters");
  const filtersParsed = JSON.parse(principalFilters ?? "{}");
  const { year } = filtersParsed;
  const semestre = validateSemestre(valuePeriod);

  return (
    <>
      {evaluationAttribute.autoevaluation === 1 && (
        <WrapperSectionMetricas>
          <TitleRowMetricasSection>
            <>
              <img src={iconOne} alt="velocity" style={{ marginTop: "8px" }} />
            </>
            <TitleBoxMetricasSection>
              <TitleTextBoxMetricasSection>
                <div className="titleMetricas">
                  Evalúa del 1 al 5 las siguientes métricas
                </div>
                {evaluationAttribute.show_modal_help_tooltips === 1 ? (
                  <GeneralInfoEvaluationModal />
                ) : null}
              </TitleTextBoxMetricasSection>
              {/* <PrevTextBox>
                {optionsInstructions.map((opt: any) => (
                  <li key={opt.id}>
                    <PrevText>{opt.label}</PrevText>
                  </li>
                ))}
              </PrevTextBox> */}
            </TitleBoxMetricasSection>
            {urlPermisos && (
              <div className="containerDate">{periodoDataInfo}</div>
            )}
            {!urlPermisos && <div className="containerDate">{`Semestre ${semestre} / ${year} ` }</div>}
          </TitleRowMetricasSection>
          <QuestionListContainerMetricasSection>
            <div className="separateDiv">
              <JobPerformanceForm
                changePeriodEvaluation={changePeriodEvaluation}
                setEvaluator={setEvaluator}
                evaluator={evaluator}
                results={evaluation?.jobPerformancesAnswere}
                index={0}
                add={addJobPerformance}
                setAdd={setAddJobPerformance}
                setEvaluation={setEvaluation}
                evaluation={evaluation}
                addJobPerformanceEvaluado={addJobPerformanceEvaluado}
                setAddJobPerformanceEvaluado={setAddJobPerformanceEvaluado}
              />
              <div className="two">
                {/* <EvaluationResults
                  politicasDeEmpresa={evaluation?.employeeEvaluationPolitics}
                  total={addTotal}
                  addTotalEvalaudo={addTotalEvalaudo}
                /> */}
              </div>
            </div>
            <div>
              <HumanFactoAttitude
                setEvaluator={setEvaluator}
                evaluator={evaluator}
                results={evaluation?.humanFactorAnswere}
                index={evaluation?.jobPerformancesAnswere?.length}
                add={addHumanFactor}
                setAdd={setAddHumanFactor}
                setEvaluation={setEvaluation}
                evaluation={evaluation}
                addHumanFactorEvaluador={addHumanFactorEvaluador}
                setAddHumanFactorEvaluador={setAddHumanFactorEvaluador}
              />
              <div className="three">
                <SkillsList
                  setEvaluator={setEvaluator}
                  evaluator={evaluator}
                  results={evaluation?.skillsAnswere}
                  index={
                    evaluation?.jobPerformancesAnswere?.length +
                    evaluation?.humanFactorAnswere?.length
                  }
                  add={addSkills}
                  setAdd={setAddSkills}
                  setEvaluation={setEvaluation}
                  evaluation={evaluation}
                  addSkillsEvaluado={addSkillsEvaluado}
                  setAddSkillsEvaluador={setAddSkillsEvaluador}
                />
              </div>
            </div>
          </QuestionListContainerMetricasSection>
          <EvaluationResultsContainer>
            <EvaluationResults
              evaluation={evaluation}
              politicasDeEmpresa={evaluation?.employeeEvaluationPolitics}
              total={addTotal}
              addTotalEvalaudo={addTotalEvalaudo}
            />
          </EvaluationResultsContainer>
        </WrapperSectionMetricas>
      )}
    </>
  );
};

export default EvaluationMetricas;
