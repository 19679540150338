import { useEffect, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "../Evaluation/useSemesterYearNow";

export const useAbleQualificationGlobal = () => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();
  //** Hooks

  //** States
  const [ableQualificationGlobal, setAbleQualificationGlobal] = useState(true);

  //** Esta es una evaluación (NO ES MI EVALUACIÓN)
  const evaluation = !urlPermisos;

  useEffect(() => {
    if (User.role === "Auditor") {
      setAbleQualificationGlobal(true);
    }

    if (User.role === "Evaluado") {
      setAbleQualificationGlobal(true);
    }

    if (User.role === "Admin TI") {
      //**  +++++++++++++++++++++++++++  EVALUACIÓN +++++++++++++++++++++++
      if (evaluation) {
        if (Evaluation.catStatusEvaluationId === 7) {
          if (
            evaluationAttribute.edit_column_score_global === 1 &&
            evaluationAttribute.edit_evaluation_complete === 1 &&
            isActualDate
          ) {
            setAbleQualificationGlobal(false); // Habilita
          } else {
            setAbleQualificationGlobal(true); //Inhabilita
          }
        } else if (Evaluation.catStatusEvaluationId === 6) {
          setAbleQualificationGlobal(true); //Inhabilita
        } else {
          if (evaluationAttribute.edit_column_score_global === 1) {
            setAbleQualificationGlobal(false); // Habilita
          } else {
            setAbleQualificationGlobal(true); //Inhabilita
          }
        }
      }

      //**  +++++++++++++++++++++++++++   AUTOEVALUACIÓN +++++++++++++++++++++++
      if (!evaluation) {
        setAbleQualificationGlobal(true); // inhabilita
      }
    } else {
      setAbleQualificationGlobal(true); // inhabilita
    }
  }, [
    Evaluation.catStatusEvaluationId,
    urlPermisos,
    evaluationAttribute.edit_column_score_global,
    evaluationAttribute.edit_evaluation_complete,
    evaluation,
    User.role,
    isActualDate,
  ]);

  return {
    ableQualificationGlobal,
  };
};
