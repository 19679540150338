
import { useEffect, useMemo, useState } from 'react'
import { io } from 'socket.io-client'

interface Config {
    token:string
}


export const useSocket = ( apiURL:string , config:Config) => {
   
    const socket = useMemo ( () =>  io(apiURL, {
    reconnectionAttempts:3,
    reconnectionDelayMax:1000,
    reconnection:false,
    upgrade:false,
    closeOnBeforeunload:false,
    extraHeaders:{
        Authorization: `Bearer ${config.token}`
    },
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }) , [apiURL]) 

   const [online, setOnline] = useState(false)

   useEffect(() => {
    setOnline(socket.connected)
   }, [socket])

   useEffect(() => {
    socket.on("connect", () => {
        setOnline(true)
    })
   },[socket])

   useEffect(() => {
    socket.on('disconnect', () => {
        setOnline(false)
    })
   },[socket])

   return {
    socket,
    online
   }
}