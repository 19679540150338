import {
    Date,
    Select,
    TextField,
} from "../../../../components/common/InputsByType";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import {
    ButtonClean,
    ButtonSelect,
    ContainerFiltersObjetivos,
    OnlyFiltersContainer,
    StyleInputs
} from "../../Objectives/ObjetivesStyles";
import { ContainerButtonsObjetives } from "./FilterDashboardStyles";



const mathExpression = [
    { text: "Selecciona opción", value: "0" },
    { text: "igual", value: "igual" },
    { text: "menor que", value: "menor que" },
    { text: "mayor que", value: "mayor que" },
    { text: "menor igual que", value: "menor igual que" },
    { text: "mayor igual que", value: "mayor igual que" },
];

const FilterGeneralObjetive = ({
    onChangeTextField,
    inputValuesGeneral,
    handleSearchFilters,
    handleClearFilters,
}: any) => {

    //** Redux

    const { evaluationAttribute } = useCollaboratorRedux();

    return (
        <>
            {evaluationAttribute.general_objetive_search_filters === 1 && (
                <ContainerFiltersObjetivos>

                    <div className="tabsStyles">
                        Filtros
                    </div> 
                    <OnlyFiltersContainer>
                        <>
                            <StyleInputs>

                                <TextField
                                    title="Concepto KPI"
                                    id="conceptoKPIGral"
                                    placeholder="Base de datos de proyecto de regularización"
                                    name="KPIFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                            </StyleInputs>
                            <StyleInputs>


                                <TextField
                                    title="Verbo infinitivo"
                                    id="CorporativoGrall"
                                    placeholder="Entregar"
                                    name="verbFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                                <Select
                                    id="ExpresionGral"
                                    title="Expresión matemática"
                                    options={mathExpression}
                                    name="mathFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                            </StyleInputs>

                            <StyleInputs>


                                <TextField
                                    title="Preposición"
                                    id="PreposiciónGral"
                                    placeholder="Al"
                                    name="prepositionFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                                <TextField
                                    title="Indicador"
                                    id="IndicadorGral"
                                    placeholder="100"
                                    name="indicatorFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                            </StyleInputs>
                            <StyleInputs>
                                <TextField
                                    title="Adverbio"
                                    id="AdverbioGral"
                                    placeholder="El"
                                    name="adverbFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                                <Date
                                    id="dateGral"
                                    title="Fecha"
                                    name="dateFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                                {/* <TextField
                                    title="Año"
                                    id="AnioGral"
                                    placeholder="Año"
                                    name="yearFilterGeneral"
                                    onKeyDownCapture={validateInputNumber}
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                                <Select
                                    id="SemestreGral"
                                    title="Semestre"
                                    options={[
                                        { text: "Seleccionar Semestre", value: "" },
                                        { text: "1", value: "1" },
                                        { text: "2", value: "2" },
                                    ]}
                                    name="semesterFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                /> */}
                            </StyleInputs>
                            <StyleInputs>
                                <TextField
                                    title="Localidad"
                                    id="LocalidadGral"
                                    placeholder="Corporativo"
                                    name="locationFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                                <TextField
                                    title="Departamento"
                                    id="DepartamentoGral"
                                    placeholder="Departamento"
                                    name="departmentFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                                <TextField
                                    title="Área"
                                    id="areaGral"
                                    placeholder="Área"
                                    name="areaFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                                <TextField
                                    title="Compañía"
                                    id="CompaniaGral"
                                    placeholder="Compañía"
                                    name="companyFilterGeneral"
                                    onChange={onChangeTextField}
                                    value={inputValuesGeneral}
                                />
                            </StyleInputs>
                            <ContainerButtonsObjetives>
                                <ButtonClean
                                    onClick={(e) => {
                                        handleClearFilters(e, "General");
                                    }}
                                >
                                    Limpiar
                                </ButtonClean>
                                <ButtonSelect
                                    onClick={(e) => {
                                        handleSearchFilters(e, "General");
                                    }}
                                >
                                    Buscar
                                </ButtonSelect>
                            </ContainerButtonsObjetives>
                        </>
                    </OnlyFiltersContainer>
                </ContainerFiltersObjetivos>
            )
            }
        </>
    )
}

export default FilterGeneralObjetive