import styled from "styled-components";

type PropTypeBgTres = {
  marginBtm?: any;
  disabled?: any;
  colorBtn?: any;
};

export const mainContainerStyles = {
  display: "flex",
  width: "640px",
  padding: "32px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "32px",
};

export const titleStyles = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "22px",
  color: "#333335",
};

export const buttonCancelar = {
  display: "flex",
  width: "224px",
  height: "40px",
  padding: "8px 24px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "#C9F2FF",
  fontSize: "16px",
  fontWeight: "700",
  color: "#3B63A2",
};

export const buttonConfirmar = {
  display: "flex",
  width: "224px",
  height: "40px",
  padding: "8px 24px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  background: "#3B63A2",
  fontSize: "16px",
  fontWeight: "700",
  color: "#FFF",
};

export const ButtonStyled = styled.button.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-bottom: ${(props) => props.marginBtm || "0px"};
  border: none;
  /* opacity: 0.4; */
`;

export const FirmsBox = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  line-height: 12px;
  font-size: 10px;
  color: ${(props) => (props.colorBtn ? "#939396" : "#fff")};
  flex-direction: column;
  /* cursor: pointer; */
  width: 68px;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

export const ContainerEvaluators = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;

  .containertxt {
    color: white;
    font-size: 8px;
    line-height: 9.6px;
    gap: 8px;
    display: flex;
    margin: 0px auto;
  }
`;
