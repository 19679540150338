/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { IQuestion } from "../../Interface/ApiInterface";
import { HeaderTitleDos } from "../../components/atoms/EvaluationHeader/EvaluationHeaderStyles";
import PaginationTable from "../../components/atoms/PaginationTable";
import Caution from "../../components/organisms/ConfirmationModals/Caution";
import ModalStatusTargetError from "../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusTargetLoading from "../../components/organisms/ConfirmationModals/ModalStatusLoading";
import ModalStatusSuccessful from "../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import pencil from "../../pages/Admin/Objectives/images/pencil.svg";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useQuestions } from "../../redux/Questionnaires";
import {
  BackWhiteQ,
  ButtonTable,
  ContainerTable,
  ContainerTableFixed,
  PaginationBox,
  StyleButonsActions,
  WrapperCuestionario,
  WrapperCuestionarioBody,
  WrapperCuestionarioHeader
} from "../Admin/Dashboard/stylesDashboard/AdminStyles";
import {
  ButtonM,
  ContainPageQuestionaire,
  ContainerUpload,
  Paragraph,
  SubTitle,
  Title
} from "../Admin/Objectives/ObjetivesStyles";
import ModalQuestion from "./ModalQuestion";

const ColorHeadColumn = "#FFFFFF";
const OptionsActions = [
  { text: "Ver", value: "0" },
  { text: "Editar", value: "1" },
  { text: "Eliminar", value: "2" },
];

const Questionnaire = () => {
  const {
    questions,
    Meta,
    loadingPost,
    errorPost,
    setError,
    handleGetAllQuestions,
    handleDeleteQuestion,
  } = useQuestions();


  const [typeEdit, setTypeEdit] = useState("CREAR");
  const [isEditQuetion, setIsEditQuetion] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({} as IQuestion);

  const [inputValues, setInputValues] = useState({
    QuestionModal: "",
    categoriaModalQuestion: "",
  });
  const [modalSucces, setModalSucces] = useState(false);
  const [ChangeQuestion, setChangeQuestion] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [showSuccess, setShowSuccess] = useState(false);

  const deleteQuestion = async (idQuestion: number) => {
    return handleDeleteQuestion(idQuestion);
  };

  const onDelete = () => {
    if (
      selectedQuestion.idQuestion !== undefined &&
      selectedQuestion.idQuestion !== null
    ) {
      deleteQuestion(selectedQuestion.idQuestion).then(() => {
        setIsDelete(false);
        const indexQuestion = questions.findIndex(
          (question) => question.idQuestion == selectedQuestion.idQuestion
        );
        questions.splice(indexQuestion, 1);
        // handleGetAllQuestions("");
        setShowSuccess(!showSuccess)
      });
    }
  };
  const cleanInputValues = () => {
    setInputValues({
      QuestionModal: "",
      categoriaModalQuestion: "",
    });
  };

  const { evaluationAttribute } = useCollaboratorRedux();

  useEffect(() => {
    handleGetAllQuestions("");
  }, []);
  
  return (
    <ContainPageQuestionaire>
      <HeaderTitleDos>Preguntas dinámicas</HeaderTitleDos>
      <RowBox>
        <>
          <Title
            style={{
              fontSize: "20px",
              marginBottom: "1rem",
            }}>Creación de preguntas dinámicas</Title>
        </>
      </RowBox>

      <BackWhiteQ>
        <UploadBox>
          {evaluationAttribute.btn_add_questions === 1 && (
            <ContainerUpload>
              <RowBox>
              <img src={pencil} alt="addPeriodo" />
                <div>
                  <Paragraph>Agregar nueva pregunta</Paragraph>
                  <SubTitle>Crea una nueva pregunta</SubTitle>
                </div>
              </RowBox>

              <>
                <ButtonM
                  onClick={() => {
                    setIsEditQuetion(!isEditQuetion);
                    setTypeEdit("CREAR");
                    cleanInputValues();
                  }}
                >
                  Agregar Preguntas
                </ButtonM>
              </>

            </ContainerUpload>
          )}
        </UploadBox>
        <div style={{ margin: "2rem 0" }}>
          <ModalQuestion
            setModalSucces={setModalSucces}
            inputValues={inputValues}
            setInputValues={setInputValues}
            estado={isEditQuetion}
            cambiarEstado={setIsEditQuetion}
            questionData={selectedQuestion}
            typeEdit={typeEdit}
            setChangeQuestion={setChangeQuestion}
            getQuestions={() => {
              setTimeout(() => {
                handleGetAllQuestions(
                  `?PageSize=10&PageNumber=${Meta?.currentPage}`
                );
              }, 1000);
            }}
          />
        </div>
        {evaluationAttribute.table_questions === 1 ? (
          <ContainerTableFixed>

            <ContainerTable>
              <WrapperCuestionario>
                <WrapperCuestionarioHeader>
                  <div className="first">ID</div>
                  <div className="second">Pregunta</div>
                  <div className="third">Categoría</div>

                  {evaluationAttribute.btn_actions_table === 1 && (
                    <div className="fourth">Acción</div>
                  )}
                </WrapperCuestionarioHeader>
                <>
                  {questions.map((item: IQuestion) => {
                    return (
                      <WrapperCuestionarioBody>
                        <div className="first">{item.idQuestion}</div>
                        <div className="second">{item.textQuestion}</div>
                        <div className="third">{item.textCatQuestion}</div>
                        <div className="fourth">
                          {evaluationAttribute.btn_actions_table === 1 ? (
                            <td style={StyleButonsActions}>
                              {OptionsActions.map((text) => {
                                return text.text === "Eliminar" ? (
                                  <Button
                                    onClick={() => {
                                      setIsDelete(!isDelete);
                                      setSelectedQuestion({
                                        ...selectedQuestion,
                                        idQuestion: item.idQuestion,
                                      });
                                    }}
                                    style={ButtonTable}
                                  >
                                    {text.text}
                                  </Button>
                                ) : text.text === "Ver" ? (
                                  <Button
                                    onClick={() => {
                                      setTypeEdit("VER");
                                      setSelectedQuestion({
                                        ...selectedQuestion,
                                        ...item,
                                      });
                                      setIsEditQuetion(!isEditQuetion);
                                    }}
                                    style={ButtonTable}
                                  >
                                    {text.text}
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() => {
                                      setTypeEdit("EDITAR");
                                      setSelectedQuestion({
                                        ...selectedQuestion,
                                        ...item,
                                      });
                                      setIsEditQuetion(!isEditQuetion);
                                    }}
                                    style={ButtonTable}
                                  >
                                    {text.text}
                                  </Button>
                                );
                              })}
                            </td>
                          ) : null}
                        </div>
                      </WrapperCuestionarioBody>
                    );
                  })}
                </>
              </WrapperCuestionario>
            </ContainerTable>
            <PaginationBox
              style={{ marginTop: "40px" }}
            >
              <PaginationTable
                table="Cuestionario"
                setCurrentPage={setCurrentPage}
                Meta={Meta}
              />
            </PaginationBox>
          </ContainerTableFixed>
        ) : null}
        <Caution
          estado={isDelete}
          cambiarEstado={setIsDelete}
          textBody={"Al confirmar, eliminarás la pregunta seleccionada."}
          textBodyQuestion={'¿Estás seguro de proceder?'}
          textButtonAct={"Eliminar"}
          textButtonClose={"Cancelar"}
          handleClick={onDelete}
          title={"Eliminar pregunta"}
        />
      </BackWhiteQ>
      {showSuccess ? (
          <ModalStatusSuccessful
          handleClick={setShowSuccess}
          modalStatus={showSuccess}
          message={"La pregunta ha sido eliminada correctamente."}
          titleModal="Pregunta eliminada"
      />
      ) : null}
    
      {modalSucces ? (
        <ModalStatusSuccessful
          handleClick={setModalSucces}
          modalStatus={modalSucces}
          message={
            ChangeQuestion
              ? "La pregunta ha sido editada correctamente. "
              : "La pregunta se ha creado correctamente. "
          }
          messageShow={true}
          titleModal={ ChangeQuestion
            ? "Pregunta editada "
            : "Pregunta creada"
        }
        />
      ) : null}
      {loadingPost ? <ModalStatusTargetLoading /> : null}
      {errorPost ? (
        <ModalStatusTargetError
          modalStatus={errorPost}
          handleClick={setError}
        />
      ) : null}
    </ContainPageQuestionaire>
  );
};

export default Questionnaire;




const UploadBox = styled.div`
display: flex;
gap: 24px;
width: 100%;
/* border: 1px solid red; */

@media screen and (max-width: 1024px) {
  flex-direction: column;
}
`;


export const RowBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  /* border: 1px solid red; */
`;