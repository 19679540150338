import {
  ApiResponse,
  ApiResponseLiberar,
  DataChartsPie,
  DataChartsPiePolitics,
  PeriodYearsSemesters,
  PeriodsByYear,
} from "../Interface/ApiInterface";
import { http } from "../api";
import { validateIdPeriod } from "../context/validacionesFiltros";

const getPeriodYearsSemesters = async (ClaTrab?: string) => {
  return new Promise<ApiResponse<PeriodYearsSemesters>>((resolve, reject) => {
    http
      .get(`/Period/YearsSemesters${ClaTrab ? `?ClaTrab=${ClaTrab}` : ""}`)
      .then((response) => {
        const { data } = response;
        const res = {
          data,
          meta: data.meta,
        };
        resolve(res);
      })
      .catch((error) => {
        const dataError = error?.response?.data?.errors;
        reject(dataError);
      });
  });
};
const getPeriodsByYear = async (year: number | undefined, ClaTrab?: string) => {
  return new Promise<ApiResponse<PeriodsByYear[]>>((resolve, reject) => {
    http
      .get(
        `/Period/PeriodsByYear?year=${year}${
          ClaTrab ? `&ClaTrab=${ClaTrab}` : ""
        }`
      )
      .then((response) => {
        const { data } = response;
        // console.log("info", data);

        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        const dataError = error?.response?.data?.errors;
        reject(dataError);
      });
  });
};
const getStaffLocations = async () => {
  return new Promise<ApiResponse<string[]>>((resolve, reject) => {
    http
      .get(`/Staff/GetStaffLocations`)
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getStaffDirections = async () => {
  return new Promise<ApiResponse<string[]>>((resolve, reject) => {
    http
      .get(`/Staff/GetStaffDirectors`)
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getDataChartsPie = async (
  Evaluation_period_id: number | undefined,
  ClaTrab: number | undefined,
  Localidad?: string | undefined,
  Director?: string | undefined
) => {
  return new Promise<ApiResponse<DataChartsPie[]>>((resolve, reject) => {
    let url = `/Policies/DashboardKPIData?`;
    if (Evaluation_period_id) {
      if (Evaluation_period_id)
        url += `Evaluation_period_id=${Evaluation_period_id}`;
      if (ClaTrab) url += `&ClaTrab=${ClaTrab}`;
      if (Localidad) url += `&Localidad=${Localidad}`;
      if (Director) url += `&Director=${Director}`;
    }
    http
      .get(url)
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getDataChartsPiePolitics = async (
  Evaluation_period_id: number | undefined,
  Localidad: string | undefined,
  Director: string | undefined
) => {
  return new Promise<ApiResponse<DataChartsPiePolitics[]>>(
    (resolve, reject) => {
      let url = `/Policies?`;
      if (Evaluation_period_id) {
        if (Evaluation_period_id)
          url += `Evaluation_period_id=${Evaluation_period_id}`;
        if (Localidad) url += `&Localidad=${Localidad}`;
        if (Director) url += `&Director=${Director}`;
      }
      http
        .get(url)
        .then((response) => {
          const { data } = response;
          const res: any = {
            data,
          };
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    }
  );
};

const getSelectStatus = async () => {
  return new Promise<ApiResponse<string[]>>((resolve, reject) => {
    http
      .get(`/Evaluations/get-cat-estatus-evaluation`)
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getObjectivesPreviewList = async (
  Evaluation_period_id: number | undefined | any,
  ClaTrab: string | undefined | any,
  IdEvaluacion: string | undefined | any
) => {
  return new Promise<ApiResponse<string[]>>((resolve, reject) => {
    //   console.log("<<<<Esto llega!!!!!!!!!!!!! ", String(Evaluation_period_id));

    // let period: any = String(Evaluation_period_id);

    // if (period.includes(',')) {
    //   const parts = period.split(',');
    //   const beforeComma = parts[0];
    //   period = beforeComma
    // } else {
    //   period = String(Evaluation_period_id)
    // }
    const period = validateIdPeriod(Evaluation_period_id);
    if (period === "" || period === undefined || period === null) return;
    http
      .get(
        `/Evaluations/get-objectives-preview-list?IdEvaluacion=${IdEvaluacion}&ClaTrab=${ClaTrab}&IdPeriod=${period}`
      )
      .then((response) => {
        const { data } = response;
        const res: any = {
          data,
        };

        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getObjectivesPreviewListFuture = async (
  Evaluation_period_id: number | undefined | any,
  ClaTrab: string | undefined | any,
  IdEvaluacion: string | undefined | any
) => {
  if (
    Evaluation_period_id === "" ||
    Evaluation_period_id === undefined ||
    Evaluation_period_id === null
  )
    return;

  const period = validateIdPeriod(Evaluation_period_id);

  return new Promise<any>((resolve, reject) => {
    http
      .get(
        `/Evaluations/get-objectives-preview-list?IdEvaluacion=${IdEvaluacion}&ClaTrab=${ClaTrab}&IdPeriod=${period}&Type=futures`
      )
      .then((response) => {
        const { data } = response || {};
        // console.log('<Esto trae>', data);
        const res: any = {
          data,
        };

        resolve(res);
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      });
  });
};

const getReleaseEvaluations = async (query: any) => {
  return new Promise<ApiResponseLiberar>((resolve, reject) => {
    http
      .get(`/DashboardContoller/liberar-evaluaciones?IdPeriod=` + query)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  getDataChartsPie,
  getDataChartsPiePolitics,
  getObjectivesPreviewList,
  getObjectivesPreviewListFuture,
  getPeriodYearsSemesters,
  getPeriodsByYear,
  getReleaseEvaluations,
  getSelectStatus,
  getStaffDirections,
  getStaffLocations,
};
