export const validateIdPeriod = (idperiod: string | number | any) => {

  // console.log("Periodo a mandar -> ", idperiod);
  
    let period: any = String(idperiod);

    if (period.includes(',')) {

      const parts = period.split(',');
      const beforeComma = parts[0];
      // console.log("Periodo a que mando a back -> ", beforeComma );
      return period = beforeComma


    } else {
      // console.log("Periodo a que mando a back -> ", String(idperiod) );
      
      return period = String(idperiod)
    }
    
}
export const validateSemestre = (idperiod: string | number | any) => {

  // console.log("Periodo a mandar -> ", idperiod);
  
    let period: any = String(idperiod);

    if (period.includes(',')) {

      const parts = period.split(',');
      const beforeComma = parts[1];
      // console.log("Periodo a que mando a back -> ", period );
      return period = beforeComma


    } 
    
}


