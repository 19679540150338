import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./Routes/App";
import { setAuthorizationHeader } from "./api";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.css";
import { LoaderProvider } from "./context/LoaderContext";

// test

const rootElement = document.getElementById('collado');
const root = createRoot(rootElement as HTMLElement);
const token = sessionStorage.getItem("token");

setAuthorizationHeader(token ? token : "");

const queryClient = new QueryClient();
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <LoaderProvider>
        <App />
      </LoaderProvider>
    </QueryClientProvider>
  </Provider>
);

reportWebVitals();
