export const validationGeneral: any = (
  generalObjetivesFilter: any,
  evaluation_period_id: any
) => {
  let query = `?IdPeriod=${evaluation_period_id}&PageNumber=1&PageSize=10`;

  if (
    generalObjetivesFilter.verbFilterGeneral !== undefined &&
    generalObjetivesFilter.verbFilterGeneral !== ""
  ) {
    query += `&GeneralObjetivesInfinitiveVerb=${generalObjetivesFilter.verbFilterGeneral}`;
  }

  if (
    generalObjetivesFilter.KPIFilterGeneral !== undefined &&
    generalObjetivesFilter.KPIFilterGeneral !== ""
  ) {
    query += `&GeneralObjetivesKpiConcept=${generalObjetivesFilter.KPIFilterGeneral}`;
  }

  if (
    generalObjetivesFilter.mathFilterGeneral !== undefined &&
    generalObjetivesFilter.mathFilterGeneral !== "" &&
    generalObjetivesFilter.mathFilterGeneral !== "0"
  ) {
    query += `&GeneralObjetviesMathExpression=${generalObjetivesFilter.mathFilterGeneral}`;
  }
  if (
    generalObjetivesFilter.prepositionFilterGeneral !== undefined &&
    generalObjetivesFilter.prepositionFilterGeneral !== ""
  ) {
    query += `&GeneralObjetivesPreposition=${generalObjetivesFilter.prepositionFilterGeneral}`;
  }

  if (
    generalObjetivesFilter.indicatorFilterGeneral !== undefined &&
    generalObjetivesFilter.indicatorFilterGeneral !== ""
  ) {
    query += `&GeneralObjetivesQuantityIndicator=${generalObjetivesFilter.indicatorFilterGeneral}`;
  }
  if (
    generalObjetivesFilter.adverbFilterGeneral !== undefined &&
    generalObjetivesFilter.adverbFilterGeneral !== ""
  ) {
    query += `&GeneralObjetivesAdverb=${generalObjetivesFilter.adverbFilterGeneral}`;
  }

  if (
    generalObjetivesFilter.dateFilterGeneral !== undefined &&
    generalObjetivesFilter.dateFilterGeneral !== ""
  ) {
    query += `&GeneralObjetivesDate=${generalObjetivesFilter.dateFilterGeneral}`;
  }

  if (
    generalObjetivesFilter.yearFilterGeneral !== undefined &&
    generalObjetivesFilter.yearFilterGeneral !== ""
  ) {
    query += `&GeneralObjetivesYear=${generalObjetivesFilter.yearFilterGeneral}`;
  }

  if (
    generalObjetivesFilter.semesterFilterGeneral !== undefined &&
    generalObjetivesFilter.semesterFilterGeneral !== ""
  ) {
    query += `&GeneralObjetivesSemester=${generalObjetivesFilter.semesterFilterGeneral}`;
  }
  if (
    generalObjetivesFilter.locationFilterGeneral !== undefined &&
    generalObjetivesFilter.locationFilterGeneral !== ""
  ) {
    query += `&Locations=${generalObjetivesFilter.locationFilterGeneral}`;
  }
  if (
    generalObjetivesFilter.departmentFilterGeneral !== undefined &&
    generalObjetivesFilter.departmentFilterGeneral !== ""
  ) {
    query += `&Department=${generalObjetivesFilter.departmentFilterGeneral}`;
  }
  if (
    generalObjetivesFilter.areaFilterGeneral !== undefined &&
    generalObjetivesFilter.areaFilterGeneral !== ""
  ) {
    query += `&Area=${generalObjetivesFilter.areaFilterGeneral}`;
  }
  if (
    generalObjetivesFilter.companyFilterGeneral !== undefined &&
    generalObjetivesFilter.companyFilterGeneral !== ""
  ) {
    query += `&Company=${generalObjetivesFilter.companyFilterGeneral}`;
  }
  return query;
};

export const validationIndividual: any = (
  individualObjetivesFilter: any,
  evaluation_period_id: any
) => {
  let query = `?IdPeriod=${evaluation_period_id}&PageNumber=1&PageSize=10`;
  if (
    individualObjetivesFilter.verbFilterIndividual !== undefined &&
    individualObjetivesFilter.verbFilterIndividual !== ""
  ) {
    query += `&GeneralObjetivesInfinitiveVerb=${individualObjetivesFilter.verbFilterIndividual}`;
  }
  if (
    individualObjetivesFilter.KPIFilterIndividual !== undefined &&
    individualObjetivesFilter.KPIFilterIndividual !== ""
  ) {
    query += `&GeneralObjetivesKpiConcept=${individualObjetivesFilter.KPIFilterIndividual}`;
  }
  if (
    individualObjetivesFilter.mathFilterIndividual !== undefined &&
    individualObjetivesFilter.mathFilterIndividual !== "0"
  ) {
    query += `&GeneralObjetviesMathExpression=${individualObjetivesFilter.mathFilterIndividual}`;
  }
  if (
    individualObjetivesFilter.prepositionFilterIndividual !== undefined &&
    individualObjetivesFilter.prepositionFilterIndividual !== ""
  ) {
    query += `&GeneralObjetivesPreposition=${individualObjetivesFilter.prepositionFilterIndividual}`;
  }
  if (
    individualObjetivesFilter.indicatorFilterIndividual !== undefined &&
    individualObjetivesFilter.indicatorFilterIndividual !== ""
  ) {
    query += `&GeneralObjetivesQuantityIndicator=${individualObjetivesFilter.indicatorFilterIndividual}`;
  }
  if (
    individualObjetivesFilter.adverbFilterIndividual !== undefined &&
    individualObjetivesFilter.adverbFilterIndividual !== ""
  ) {
    query += `&GeneralObjetivesAdverb=${individualObjetivesFilter.adverbFilterIndividual}`;
  }
  if (
    individualObjetivesFilter.dateFilterIndividual !== undefined &&
    individualObjetivesFilter.dateFilterIndividual !== ""
  ) {
    query += `&GeneralObjetivesDate=${individualObjetivesFilter.dateFilterIndividual}`;
  }
  if (individualObjetivesFilter.yearFilterIndividual !== undefined &&
    individualObjetivesFilter.yearFilterIndividual !== "")  {
    query += `&GeneralObjetivesYear=${individualObjetivesFilter.yearFilterIndividual}`;
  }
  if (individualObjetivesFilter.semesterFilterIndividual !== undefined &&
    individualObjetivesFilter.semesterFilterIndividual !== "") {
    query += `&GeneralObjetivesSemester=${individualObjetivesFilter.semesterFilterIndividual}`;
  }
  return query;
};
