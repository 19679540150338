import styled from "styled-components";
import cancelIcon from '../../../../assets/cancelIcon.svg';
import Close from "../../../../assets/icons/CloseModal.svg";
import { ModalAlertWrapper } from "../../ModalActions/ModalEditStyles";

const PermisoNoActivo = ({
  isModalVisible,
  setIsModalVisible
}: any) => {



  return (
    <ModalAlertWrapper display={isModalVisible}>
      <div className="modalWrapper">
        <div className="close">
          <div className="title" style={{ color: '#F20505' }}>
            <img src={cancelIcon} className="image" alt="AlertModalIcon" />
            Permiso no activo
          </div>
          <div className="closeModal" onClick={() => setIsModalVisible(!isModalVisible)}>
            <img src={Close} alt="close" style={{ height: "16px" }} />
          </div>
        </div>
        <ContainerModalText>
          <div className="textContainer">
            No puedes realizar tu autoevaluación en este momento porque el permiso necesario no está activo.
            <br />
            <br />
            Por favor, contacta a tu evaluador para obtener el permiso correspondiente.
          </div>
        </ContainerModalText>
        <FooterModal>
          <ButtonModalSave
            onClick={() => setIsModalVisible(!isModalVisible)}
          >
            Continuar
          </ButtonModalSave>
          <ButtonModalCancel
            onClick={() => setIsModalVisible(!isModalVisible)}
          >
            Cancelar
          </ButtonModalCancel>
        </FooterModal>
      </div>
    </ModalAlertWrapper>
  );
};

export default PermisoNoActivo;


export const InputObj = styled.textarea`
  width: 80%;
  height: 80%;
  border: none;
  outline: none;
  resize: none;
`;

export const ContainerModalText = styled.div`
  width: 90%;
  padding: 14px 21px;
  margin-top: 20px;

  .textContainer {
    font-size: 20px;
    color: #666666;
    font-weight: 400;
    padding-bottom: 5px;
  }

  .containerModal {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: end;
    font-size: 14px;
    padding: 2rem;

    .txt {
      margin-top: 8px;
      padding: 8px;
    }

    .containerTxt {
      background-color: white;
      border-radius: 8px;
      font-weight: 700;
      color: black;
      font-size: 14px;
      padding: 8px;
      margin: 8px;
      margin-right: 24px;
      width: 83%;
    }
  }
`;

export const FooterModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  padding: 14px 21px;
`;

export const ButtonModalSave = styled.div`
  background-color: #3b63a2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: white;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  align-content: center;
`;

export const ButtonModalCancel = styled.div`
  background-color: #c1c1c3;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #f0f0f0;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
