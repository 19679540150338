import styled from "styled-components";

type PropTypeBgTres = {
  marginBtm?: any;
  disabled?: any;
  colorBtn?: any;
};

type PropTypeFutureGoalsCard = {
  borderColor?: boolean;
};
export const NewTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 16px;
  margin-bottom: 16px;

  /* gap: 10px; */
  /* border: 1px solid red; */

  .titleStyle {
    color: var(--primary-true-blue, #3b63a2);
    margin: 0px;
    /* H4 */
    font-family: Plus Jakarta Sans;
    font-size: 18px;
    font-style: normal;
    /* border: 1px solid; */
    font-weight: 700;
    line-height: 100%;
  }
  /* 33.6px */
  .iconContainer {
    margin-top: 6px;
    display: flex;
    justify-content: center;
  }
`;

export const FormContainer = styled.div`
  box-sizing: border-box;
  height: fit-content;
  /* width: 100%; */
  margin-left: 20px;
  /* max-width: 500px; */
  @media screen and (max-width: 1287px) {
    border: none !important;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 0;
`;

export const TitleModal = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Bangers&display=PlusJakartaSans");

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #3b63a2;
  width: 245px;
  height: 22px;
`;
export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  font-size: 18px;
  color: #3b63a2;
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const AutoEvaluacionContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 4.25%;
  gap: 12px;
  margin-top: 6px;
  .data {
    font-size: 14px;
    font-weight: 700;
  }
`;
export const FormTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 3.5px;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 24px;
  width: 96%;
`;

export const EvaluationInstructionsHeading = styled.div`
  margin-bottom: 29px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EvaluationInstructionsList = styled.ul`
  padding: 0 4rem;
  list-style: none;
  margin-bottom: 29px;

  @media screen and (min-width: 1024px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
`;

export const QuestionList = styled.ol`
  padding: 0;
`;

export const QuestionListItem = styled.li`
  margin: 1rem;
  height: 40px;
  display: flex;
  line-height: 22px;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #d9d9d9; */

  span {
    font-weight: 300;
    font-size: 14px;
  }
`;

export const TextItem = styled.span`
  max-width: 300px;
  margin-right: 1rem;
  font-weight: 500;
`;
export const SelectOption = styled.select.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 5px 2px; */
  gap: 8px;
  width: 71px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 8px;
  padding-left: 12px;

  background: url("data:image/svg+xml,<svg height='7px' width='12px' viewBox='0 0 12 7' fill='%2300000' xmlns='http://www.w3.org/2000/svg'><path d='M1.5471 0.697201L5.6719 4.82202L9.7967 0.697201C10.1221 0.371768 10.6498 0.371768 10.9752 0.697201C11.3006 1.02264 11.3006 1.55027 10.9752 1.87569L6.26117 6.58975C6.10483 6.74602 5.8929 6.83382 5.6719 6.83382C5.4509 6.83382 5.2389 6.74602 5.08263 6.58975L0.368591 1.87569C0.327911 1.83502 0.292318 1.79122 0.261805 1.74495C0.048238 1.42153 0.0838313 0.981961 0.368591 0.697201C0.409271 0.656521 0.453111 0.620928 0.499318 0.590421C0.822778 0.376848 1.26234 0.412448 1.5471 0.697201Z '/></svg>")
    no-repeat;

  background-position: calc(100% - 0.6rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
`;

export const ContainerSelect = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  opacity: ${(props) => (props.colorBtn ? ".45" : ".9")};
`;

export const PlusResult = styled.div`
  /* border: 1px solid red; */
  padding: 10px;
  background-color: #f4fcff;
  font-weight: 700;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-right: 2rem;
  }
  .sumaClass {
    color: #c1c1c3;
  }

  .totalRightSpacing {
    margin-right: 1.5rem;
  }

  .finalQualification {
    width: 61px;
    padding: 5px 16px;
    border-radius: 2px;
    background: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  column-gap: 1.75rem;
  height: fit-content;
  /* border: 1px solid #d9d9d9; */
`;

export const Results = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-right: 2rem;
  }

  .totalRightSpacing {
    margin-right: 1.5rem;
  }

  .finalQualification {
    width: 61px;
    padding: 5px 16px;
    border-radius: 2px;
    background: #d9d9d9;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  }
`;

export const EvaluatedCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: start; */
  /* justify-content: flex-start; */

  /* margin: 1rem 0; */
`;

export const CommentContainer = styled.form`
  display: flex;
  align-items: center;
  gap: 5px;
  padding-bottom: 12px;
`;

export const EvaluatedCommentsRow = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;

  @media screen and (min-width: 1024px) {
    width: 99%;
    gap: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const NumberItem = styled.span`
  font-weight: 700;
  font-size: 18px;
  color: #3b63a2;
`;

export const TitleBox = styled.div`
  margin: 40px 0 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const TitleText = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  color: #3b63a2;
  margin: 0;
`;

export const PrevText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #939396;
`;
export const Divisor = styled.div`
  /* border-right: 0.5px solid #939396; */
  /* width: 50%; */
  padding-right: 0%;
  width: 100%;

  @media (min-width: 320px) and (max-width: 1026px) {
    border-right: none;
    padding-right: 0%;
  }
`;
// export const AlignForm = {
//   width: "50%",
// };
export const AlignContent = {
  marginLeft: "-0.2rem",
};

//! Estilos del modal de los objetivos

export const ModalContainerBody = styled.div`
  width: 100%;
  /* border: 1px solid #d9d9d9; */
  font-family: "Plus Jakarta Sans";
`;
export const ModalContainerBodySelect = styled.div`
  width: 100%;
  padding: 14px;
  font-family: "Plus Jakarta Sans";
`;

export const ModalHeadTable = styled.div`
  display: flex;
  align-items: center;
  background-color: #3b63a2;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;

  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const BodyTableWrapper = styled.div`
  overflow-x: auto;
`;

export const BodyTableWrapperWrap = styled.div`
  overflow-x: auto;
  min-width: 1000px;
`;

export const ModalBodyTable = styled.div.attrs(
  (props: PropTypeFutureGoalsCard) => ({})
)<PropTypeFutureGoalsCard>`
  border: ${(props) => (props.borderColor ? "none" : "1px solid red")};
  display: flex;
  align-items: center;
  height: auto;
  color: #ffffff;
  width: 100%;
  padding: 8px;
  margin: 2px 0;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;

  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    .inputSecond {
      padding: 5px 10px;
      width: 65%;
      min-width: 130px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
  .third {
    width: 35%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 25%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    .inputFourth {
      padding: 5px 10px;
      width: 65%;
      min-width: 130px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;
export const ModalBodyTableDos = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: auto;
  color: #ffffff;
  width: 100%;
  padding: 8px;
  margin: 2px 0;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;

  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .second {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    .inputSecond {
      padding: 5px 10px;
      width: 40%;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
    }
  }
  .third {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .fourth {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ModalFooterTable = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  margin: 2px 0;
  border-radius: 2px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;
  letter-spacing: 1px;

  /* border: ${(props) => (props.colorBtn ? "1px solid red" : "none")}; */
  .second {
    width: 44%;
    display: flex;
    justify-content: end;
  }
  .first {
    width: 27%;
    display: flex;
    justify-content: end;
  }
`;

export const ModalFooterTableMySelect = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  margin: 3px 0;
  border-radius: 2px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;
  letter-spacing: 0.75px;
  border: ${(props) => (props.colorBtn ? "1px solid red" : "none")};

  .first {
    width: 30%;
    display: flex;
    justify-content: end;
  }

  .second {
    width: 30%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: end;
  }
  .third {
    width: 54%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
  }
`;

export const ValueAndReach = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
  /* border: 1px solid red; */
  margin-top: 40px;

  .firstContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
  .first {
    width: 100%;
  }

  .second {
    width: 30%;
    /* padding-top: 43px; */
  }

  @media screen and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    .firstContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .first {
      width: 100%;
    }

    .second {
      width: 100%;
    }
  }
`;

//! Estilos del  TableGoals de los objetivos

export const WrapperGoalsTable = styled.div`
  width: 100%;
  /* border: 1px solid #d9d9d9; */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;
    margin: 0 auto;
    outline: none;
    font-family: Plus Jakarta Sans;
  }
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;
    font-family: Plus Jakarta Sans;
    align-items: center;
    justify-content: center;
    outline: none;
    align-content: center;
  }

  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;
  }

  .MuiInputBase-root {
    outline: none;
    font-family: Plus Jakarta Sans;
  }
`;

export const GoalsHeadTable = styled.div`
  display: flex;
  align-items: center;
  background-color: #656569;
  height: 48px;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  font-family: "Plus Jakarta Sans";
  /* justify-content: space-between; */

  .first {
    width: 15%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

//!! ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const GoalsBodyTable = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  margin: 2px 0;
  /* text-align: center; */
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;

  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    .inputFirst {
      padding: 5px 2px;
      width: 40%;
      border-radius: 8px;
      border: none;
      display: flex;
      align-items: center;
    }
  }

  .second {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .inputSecond {
      padding: 6px 0px 6px 4px;

      border-radius: 8px;
      /* width: 60%; */
      min-width: 110px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
      /* border: 12px solid; */
    }
  }
  .third {
    width: 57%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 14%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    .inputFourth {
      padding: 6px 0px 6px 4px;
      min-width: 110px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;
//!! ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

export const FutureGoalsCard = styled.div.attrs(
  (props: PropTypeFutureGoalsCard) => ({})
)<PropTypeFutureGoalsCard>`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  margin: 2px 0;
  /* text-align: center; */
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;
  border: ${(props) => (props.borderColor ? "none" : "1px solid red")};

  .first {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    .inputFirst {
      padding: 5px 2px;
      width: 40%;
      border-radius: 8px;
      border: none;
      display: flex;
      align-items: center;
    }
  }

  .second {
    width: 14%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .inputSecond {
      padding: 6px 0px 6px 4px;

      border-radius: 8px;
      /* width: 60%; */
      min-width: 110px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
      /* border: 12px solid; */
    }
  }
  .third {
    width: 57%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 14%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    .inputFourth {
      padding: 6px 0px 6px 4px;
      min-width: 110px;
      border-radius: 8px;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      text-align: center;
    }
  }
`;

//** ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export const GoalFooterTable = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 48px;
  /* border-radius: 8px 8px 0px 0px; */
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  /* padding: 24px; */
  margin: 2px 0;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #f8f8f8;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: black;
  letter-spacing: 1px;
  /* border: 1px solid #d9d9d9; */
  gap: 15px;

  .first {
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .second {
    width: 62%;
  }
`;

//! Estilos de Politicas
export const GoalsHeadTablePoliticas = styled.div`
  display: flex;
  align-items: center;
  background-color: #656569;
  /* height: 48px; */
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
  font-weight: 700;
  width: 100%;
  padding: 16px 8px;
  font-family: "Plus Jakarta Sans";
  /* justify-content: space-between; */
  margin-bottom: 5px;
  .first {
    width: 10%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 20%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 15%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 12%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fifth {
    width: 23%;
    /* border: 1px solid; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sixth {
    width: 24%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const GoalsBodyTablePoliticas = styled.div`
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  height: 48px;
  font-weight: 700;
  width: 100%;
  padding: 24px;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 400;
  /* justify-content: space-between; */

  margin-bottom: 4px;

  .first {
    width: 10%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .second {
    width: 20%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .third {
    width: 15%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fourth {
    width: 12%;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fifth {
    width: 23%;
    /* border: 1px solid; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sixth {
    width: 24%;
    /* border: 1px solid; */
    padding: 0px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
`;

export const StlyeTextBodyModal = styled.p`
  font-weight: 700;
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  color: #939396;
`;
