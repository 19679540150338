import { useEffect, useState } from "react";
import { validateIdPeriod } from "../../context/validacionesFiltros";
import {
    ButtonFilterDashboard,
    FilterWrapper,
} from "../../pages/Admin/Dashboard/NewComponentsDashboardRefactor/FilterDashboardStyles";
import SelectCustomdDashboard from "../../pages/Admin/Dashboard/NewComponentsDashboardRefactor/components/SelectCustomDashboard";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import {
    getPeriodYearsSemesters,
    getPeriodsByYear,
} from "../../services/dataSelects.service";

const FilterObjetivos = ({
  // setSelectedOptionPeriod,
  handleAction,
  setEvaluation_period_id,
}: any) => {
  //* Redux
  const { User, evaluationAttribute } = useCollaboratorRedux();

  //** States
  const [years, setYears] = useState<any>([]);

  const [selectedOptionYear, setSelectedOptionYear] = useState(0);
  const [selectedOptionPeriod, setSelectedOptionPeriod] = useState("");
  const [semesterOptions, setSemestersOptions] = useState<any>([]);

  //**  Actions to get periods

  const getPeriods = async (year: any, action: any) => {
    const { data } = await getPeriodsByYear(year);

    const array = data.map((datos) => ({
      evaluationPeriodId: datos?.evaluationPeriodId,
      evaluationPeriodNumberOfYear: datos?.evaluationPeriodNumberOfYear,
    }));

    const firstPeriod = array[0];

    const selectedOption = action
      ? `${User.periodInfo.idPeriod},${User.periodInfo.semesterNumber}`
      : `${firstPeriod.evaluationPeriodId},${firstPeriod.evaluationPeriodNumberOfYear}`;

    setSelectedOptionPeriod(selectedOption);
    setSemestersOptions(array);
  };

  //! Se ejecuta al renderizar el componente
  //!Obtiene la info para ambos selects

  const getYearsOptions = async () => {
    const { data } = await getPeriodYearsSemesters();

    const yearList = data.evaluationPeriodYear.map((year, index) => ({
      year,
      id: data.evaluationPeriodNumberOfYear[index],
    }));

    yearList.sort((a, b) => a.year - b.year);

    //! Filtros generales
    setYears(yearList);
    getPeriods(User.periodInfo.year, true);
  };

  const onChangeOptionYear = async (e: any) => {
    const { value } = e.target;

    if (value !== "") {
      setSelectedOptionYear(Number(value));
      getPeriods(Number(value), false);
    }
  };

  const onChangeOptionSemestre = async (e: any) => {
    const { value } = e.target;

    if (value !== "") {
      setSelectedOptionPeriod(value);
    }
  };

  const handleSearchPeriod = () => {
    const periodo = validateIdPeriod(selectedOptionPeriod);
    // console.log("Se elige el periodo", selectedOptionPeriod);
    // setSelectedOptionPeriod(periodo);
    handleAction(periodo);
    setEvaluation_period_id(periodo);
  };

  const handleCleanPeriod = (e: any) => {
    e.preventDefault();

    //! Periodo

    setSelectedOptionYear(User?.periodInfo?.year);
    getPeriods(User?.periodInfo?.year, true);
    handleAction(User?.periodInfo?.idPeriod);
    setEvaluation_period_id(User?.periodInfo?.idPeriod);
  };

  useEffect(() => {
    getYearsOptions();
    setSelectedOptionYear(User?.periodInfo?.year);
  }, [User]);

  useEffect(() => {
    handleAction(User?.periodInfo?.idPeriod);
  }, []);

  return (
    <div style={{ paddingBottom: "24px", borderBottom: "2px solid #F0F0F0" }}>
      <FilterWrapper>
        <div className="labelBoxTab">Filtros</div>
        <div className="containerSelectsAndBtns">
          <div className="onlySelects">
            <SelectCustomdDashboard
              valueSelect={selectedOptionYear}
              nameSelect="year"
              nameLabel="Año"
              hadleChange={onChangeOptionYear}
            >
              {years.map(({ year }: any) => (
                <option key={year} value={year} style={{ color: "#000" }}>
                  {year}
                </option>
              ))}
            </SelectCustomdDashboard>
            <SelectCustomdDashboard
              valueSelect={selectedOptionPeriod}
              nameSelect="semester"
              nameLabel="Semestre"
              hadleChange={onChangeOptionSemestre}
            >
              {semesterOptions.map((periodName: any) => (
                <option
                  key={periodName?.evaluationPeriodId}
                  value={`${periodName?.evaluationPeriodId},${periodName?.evaluationPeriodNumberOfYear}`}
                  style={{ color: "#000" }}
                >
                  {periodName?.evaluationPeriodNumberOfYear}
                </option>
              ))}
            </SelectCustomdDashboard>
          </div>
          <div className="onlyBtns">
            <ButtonFilterDashboard
              onClick={handleCleanPeriod}
              colorBg="#C9F2FF"
              colorFont="#3B63A2"
            >
              Limpiar
            </ButtonFilterDashboard>
            <ButtonFilterDashboard
              onClick={handleSearchPeriod}
              colorFont="#FFF"
            >
              Buscar
            </ButtonFilterDashboard>
          </div>
        </div>
      </FilterWrapper>
    </div>
  );
};

export default FilterObjetivos;
