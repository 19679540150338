import { useContext } from "react";
import { Form } from "react-bootstrap";
import { ChangePeriodContext, ChangePeriodContextType } from "../../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import { useEvaluationRedux } from "../../../../redux/Evaluations";
import { CardCurrentObj } from "./../NewStyles";
import { getClassNameBasedOnPercentage, porcentajeAlcanceDefault, reglasKPIs, reglasKPIsDefault } from "./../utils";



const CardTableKpisChangePeriod = ({ item, }: any) => {

    // ** Redux
    const { Evaluation } = useEvaluationRedux();

    //** Context Cambio de periodo
    const {
        totalConocimientoEmpresa,
        changePeriodEvaluation,
        totalSumaMetricas
    } = useContext(
        ChangePeriodContext
    ) as ChangePeriodContextType;

    return (
        <CardCurrentObj activeBorder={false}>
            <div className="first">
                {
                    Evaluation?.evaluationPeriod
                        ?.evaluationPeriodNumberOfYear
                }
            </div>

            {/* COLUMNA DE VALOR */}
            <div className="second">
                {item?.employeeObjetivesType === "G" &&
                    `${item?.employeeEvaluationObjetivesWorth}%`}


                {item?.employeeObjetivesType === "I" && (
                    <>
                        {item?.employeeEvaluationObjetivesWorth}%
                    </>
                )}

                {item?.employeeObjetivesType === "D" && (
                    <>
                        {item?.employeeEvaluationObjetivesWorth}%
                    </>
                )}
            </div>

            {/* COLUMNA DE DESCRIPCIÓN DE OBJETIVOS */}
            <div className="third">
                {item?.employeeEvaluationObjetivesDescription}
            </div>
            {/* COLUMNA DE META */}
            <div className="fourth">
                {item?.employeeObjetivesType === "G" &&
                    `${item?.employeeEvaluationObjetivesGoal} %`}
                {item?.employeeObjetivesType === "I" && (
                    <>
                        {item?.employeeEvaluationObjetivesGoal}%
                    </>

                )}
                {item.employeeObjetivesType === "D" &&
                    `${item?.employeeEvaluationObjetivesGoal} %`}
            </div>


            {/* +++++++++++++++++ COLUMNA DE CALIFICACIÓN +++++++++++++++++ */}
            <div className="fifth" >
                {item.employeeObjetivesType !== "D" &&
                    item.employeeObjetivesType !== "G" && (
                        <>
                            {Number(item?.employeeEvaluationObjetivesReal).toLocaleString('en-US')}
                        </>


                    )}



                {item.employeeObjetivesType === "G" && !item.employeeEvaluationObjetivesDescription.includes("planta")
                    && (
                        <>
                            {Number(item?.employeeEvaluationObjetivesReal).toLocaleString('en-US')}
                        </>
                    )
                }

                {item.employeeObjetivesType === "G" && item.employeeEvaluationObjetivesDescription.includes("planta")
                    && (
                        <>
                            {/* {separarEnGruposDe100(Number(item?.employeeEvaluationObjetivesReal))} */}
                            {Number(item?.employeeEvaluationObjetivesReal).toLocaleString('en-US')}
                        </>
                    )
                }





                {/* {item.employeeObjetivesType === "D" &&
                    `${item?.employeeEvaluationObjetivesReal}`} */}
            </div>
            {/* +++++++++++++++++ COLUMNA DE CALIFICACIÓN +++++++++++++++++ */}
            <div className="sixth">
                {item?.employeeObjetivesType === "G" &&
                    <>
                        {/* {porcentajeDeAlcanceGenerales(
                            +item?.employeeEvaluationObjetivesGoal,
                            +item?.employeeEvaluationObjetivesReal
                        )}% */}
                        {porcentajeAlcanceDefault("Generales",
                            item?.employeeEvaluationObjetivesReal,
                            item?.employeeEvaluationObjetivesGoal)}%
                    </>
                }

                {item.employeeObjetivesType === "I" &&
                    <>
                        {/* {porcentajeDeAlcanceGenerales(
                            +item?.employeeEvaluationObjetivesGoal,
                            +item?.employeeEvaluationObjetivesReal
                        )}% */}
                        {porcentajeAlcanceDefault("Generales",
                            item?.employeeEvaluationObjetivesReal,
                            item?.employeeEvaluationObjetivesGoal)}%
                    </>
                }

                {item.employeeObjetivesType === "D" && item.employeeEvaluationObjetivesDescription.includes("Conocimiento") && (
                    <>
                        {porcentajeAlcanceDefault("Empresa", totalConocimientoEmpresa)}%
                    </>
                )}
                {item.employeeObjetivesType === "D" && item.employeeEvaluationObjetivesDescription.includes("laboral, humano,") && (
                    <>
                        {porcentajeAlcanceDefault("Desempenio", totalSumaMetricas)}%
                    </>
                )}
            </div>
            {/* +++++++++++++++++ COLUMNA DE CALIFICACIÓN +++++++++++++++++ */}


            {/* COLUMNA DE RESULTADO */}
            <div className="seventh" >
                {/* <InputGroup className="InputTableResult"> */}
                {item.employeeObjetivesType !== "D" &&
                    <Form.Control
                        name="employeeEvaluationObjetivesReal"
                        className={`text-align-center ${getClassNameBasedOnPercentage(porcentajeAlcanceDefault("Generales",
                        item?.employeeEvaluationObjetivesReal,
                        item?.employeeEvaluationObjetivesGoal))}`}
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        value={
                            reglasKPIs(
                                item?.employeeEvaluationObjetivesWorth,
                                porcentajeAlcanceDefault("Generales",
                                    item?.employeeEvaluationObjetivesReal,
                                    item?.employeeEvaluationObjetivesGoal)
                                ,
                                item?.employeeObjetivesType,
                                item?.employeeEvaluationObjetivesReal
                            )
                        }
                        disabled={true}
                    />
                }
                {item.employeeObjetivesType === "D" && item.employeeEvaluationObjetivesDescription.includes("Conocimiento") && (
                    <>
                        <Form.Control
                            name="employeeEvaluationObjetivesReal"
                            className={`text-align-center ${getClassNameBasedOnPercentage(porcentajeAlcanceDefault("Empresa", totalConocimientoEmpresa))}`}
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={
                                reglasKPIsDefault(
                                    item?.employeeEvaluationObjetivesWorth,
                                    porcentajeAlcanceDefault("Empresa", totalConocimientoEmpresa),
                                    item?.employeeObjetivesType,
                                    item?.employeeEvaluationObjetivesReal
                                )
                            }
                            disabled={true}
                        />
                    </>
                )}
                {item.employeeObjetivesType === "D" && item.employeeEvaluationObjetivesDescription.includes("laboral, humano,") && (
                    <>
                        <Form.Control
                            name="employeeEvaluationObjetivesReal"
                            className={`text-align-center ${getClassNameBasedOnPercentage(porcentajeAlcanceDefault("Desempenio", totalSumaMetricas))}`}
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={
                                reglasKPIsDefault(
                                    item?.employeeEvaluationObjetivesWorth,
                                    porcentajeAlcanceDefault("Desempenio", totalSumaMetricas),
                                    item?.employeeObjetivesType,
                                    item?.employeeEvaluationObjetivesReal
                                )
                            }
                            disabled={true}
                        />
                    </>
                )}
                {/* </InputGroup> */}
            </div>
            {/* COLUMNA DE RESULTADO */}
        </CardCurrentObj>);
};

export default CardTableKpisChangePeriod;
