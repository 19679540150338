/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { http } from "../../../api";
import colladoLoader from '../../../assets/colladoLoader.gif';
import { validateIdPeriod } from "../../../context/validacionesFiltros";
import { SearchSectionDownloadReportDos, SelectContainerDownloadReport, SelectDonwloadReport, SelectDonwloadReportDos, TextTypeReportDownloadReport } from "../../../pages/Admin/Dashboard/NewComponentsDashboardRefactor/ReporteColaboradores/DownloadReportCollaboratorsStyles";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { getPeriodsByYear } from "../../../services/dataSelects.service";
import { getPeriodYearsSemesters } from "../../../services/objetives.services";
import {
  CleanButton,
  DownLoadReportBox,
  DownloadButton,
  DownloadReportTitle,
  WrapperButtons
} from "./DownLoadReportStyles";

const DownLoadReport = ({ setSemestre }: any) => {
  //** Redux
  const {
    User
  } = useCollaboratorRedux();

  const {
    nombre: name,
    role,
    claTrab
  } = User;
    

  const [isLoading, setIsLoading] = useState(false);
  const [typeReport, setTypeReport] = useState("Completo");
  const [loadInfo, setLoadInfo] = useState(false)




  const [yearsDownloadReport, setYearsDownloadReport] = useState<any>([]);
  const [selectedOptionYearDownloadReport, setSelectedOptionYearDownloadReport] = useState(0);
  const [selectedPeriodDownloadReport, setSelectedPeriodDownloadReport] = useState("")

  const [optionsOfSemester, setOptionsOfSemester] = useState<any>([]);



  const getPeriodsDownloadReport = async (year: any, action: any) => {
    const { data } = await getPeriodsByYear(year);

    const array = data.map((datos) => ({
      evaluationPeriodId: datos?.evaluationPeriodId,
      evaluationPeriodNumberOfYear: datos?.evaluationPeriodNumberOfYear,
    }));

    const firstPeriod = array[0];

    const selectedOption = action
      ? `${User.periodInfo.idPeriod},${User.periodInfo.semesterNumber}`
      : `${firstPeriod.evaluationPeriodId},${firstPeriod.evaluationPeriodNumberOfYear}`;


    // console.log("Este es el periodo", selectedOption, year, action);

    setSelectedPeriodDownloadReport(selectedOption);
    setOptionsOfSemester(array);
  };


  const getYearsOptionsDownloadReport = async () => {

    const { data } = await getPeriodYearsSemesters();

    const yearList = data.evaluationPeriodYear.map((year, index) => ({
      year,
      id: data.evaluationPeriodNumberOfYear[index],
    }));

    yearList.sort((a, b) => a.year - b.year);

    //! DownloadReport
    setYearsDownloadReport(yearList);
    getPeriodsDownloadReport(User.periodInfo.year, true);
  };






  const onChangeOptionSemestreYearDownloadReport = async (e: any, type: string) => {
    const { value } = e.target;

    if (value !== "") {

      if (type === "Year") {
        setSelectedOptionYearDownloadReport(Number(value));
        getPeriodsDownloadReport(Number(value), false);
      }

      if (type === "Semester") {
        setSelectedPeriodDownloadReport(value);
      }

    }
  }

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const periodo = validateIdPeriod(selectedPeriodDownloadReport);
      const clave = claTrab.toString();

      const endpoint = typeReport === 'Resumido'
        ? `/DashboardContoller/generate-report?NombreColaborador=${name}&RolColaborador=${role === "Auditor" ? "Admin Ti" : role}&IdPeriod=${periodo}`
        : `/Evaluations/get-full-evaluations-report?IdPeriod=${periodo}&ClaTrab=${clave}`


      const response = await http.get(endpoint, {
        responseType: 'blob',
      });


      // Crea una URL para el archivo binario
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Crea un enlace para descargar el archivo
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report${year}${month}${day}.xlsx`);
      document.body.appendChild(link);

      // Simula un click en el enlace para iniciar la descarga
      link.click();

      // Elimina el enlace después de descargar
      link.parentNode?.removeChild(link);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      // console.error('Error al descargar el archivo:', error);
    }
  };



  const handleChangeTypeReport = (e: any) => {
    const { value } = e.target;
    if (value) {
      setTypeReport(e.target.value);
    }
  }

  useEffect(() => {
    getYearsOptionsDownloadReport();
    setSelectedOptionYearDownloadReport(User?.periodInfo?.year);
    // setTimeout(() => {
    //   setLoadInfo(true)
    // }, 2000)
  }, []);

  // console.log(selectedOptionYearDownloadReport, selectedPeriodDownloadReport);

  const handleCleanValues = (e: any) => {
    e.preventDefault();
    // setLoadInfo(false)
    getPeriodsDownloadReport(User.periodInfo.year, true);
    setSelectedOptionYearDownloadReport(User?.periodInfo?.year);
  }

  return (
    <>



      <DownLoadReportBox>
        <DownloadReportTitle>
          Descargar reporte
        </DownloadReportTitle>
        <SearchSectionDownloadReportDos>
          <div className="selectContainer">


            <SelectContainerDownloadReport>
              <TextTypeReportDownloadReport>Año</TextTypeReportDownloadReport>
              <SelectDonwloadReport
                value={selectedOptionYearDownloadReport}
                name="semester"
                onChange={(e) => onChangeOptionSemestreYearDownloadReport(e, "Year")}
              >
                {yearsDownloadReport.map(({ year }: any) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </SelectDonwloadReport>
            </SelectContainerDownloadReport>


            <SelectContainerDownloadReport>
              <TextTypeReportDownloadReport>Semestre</TextTypeReportDownloadReport>
              <SelectDonwloadReport
                value={selectedPeriodDownloadReport}
                name="semester"
                onChange={(e) => onChangeOptionSemestreYearDownloadReport(e, "Semester")}
              >
                {optionsOfSemester.map((periodName: any) => (
                  <option
                    key={periodName?.evaluationPeriodId}
                    value={`${periodName?.evaluationPeriodId},${periodName?.evaluationPeriodNumberOfYear}`}
                  >
                    {periodName?.evaluationPeriodNumberOfYear}
                  </option>
                ))}
              </SelectDonwloadReport>
            </SelectContainerDownloadReport>
            <SelecTypeOfReport
              valueSelect={typeReport}
              handleChange={handleChangeTypeReport}
            />
          </div>

        </SearchSectionDownloadReportDos>
        <WrapperButtons>
          {isLoading &&
            <img
              style={{ width: "42px" }}
              src={colladoLoader}
              alt=""
            />
          }
          <CleanButton onClick={handleCleanValues} >
            Limpiar
          </CleanButton>
          <DownloadButton onClick={handleDownload} disabled={isLoading}>
            {isLoading ? "Descargando" : "Descargar"}
          </DownloadButton>

        </WrapperButtons>
      </DownLoadReportBox>
    </>
  );
};

export { DownLoadReport };



const SelecTypeOfReport = ({
  valueSelect,
  handleChange,
}: any) => {

  return (
    <SelectContainerDownloadReport>
      <TextTypeReportDownloadReport>
        Tipo de reporte
      </TextTypeReportDownloadReport>

      <SelectDonwloadReportDos
        value={valueSelect}
        onChange={handleChange}
      >
        <option value="Completo">Completo</option>
        <option value="Resumido">Resumido</option>
      </SelectDonwloadReportDos>
    </SelectContainerDownloadReport>
  )
}