import styled from "styled-components";

export const DashboardsBox = styled.div`
  /* background-color: #ffffff; */
  /* display: flex; */
  /* gap: 3.5rem; */
  /* justify-content: space-between; */
  @media (min-width: 320px) and (max-width: 767px) {
  
  }
  @media (min-width: 768px) and (max-width: 1024px) {
  
  }
`;

export const RowChart = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;

  @media (min-width: 320px) and (max-width: 767px) {

  }
`;
