import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect } from "react";
import { IMeta } from "../../Interface/ApiInterface";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../context/EvaluationContext";
import { validateIdPeriod } from "../../context/validacionesFiltros";
import { ContainPagination } from "../../pages/Admin/Dashboard/stylesDashboard/AdminStyles";
import { useCollaboratorRedux } from "../../redux/Collaborators";
// import { styled } from '@mui/material/styles';

interface IPagination {
  table?: string;
  Meta?: IMeta;
  evaluation_period_id?: any;
  setCurrentPage?: any;
  setQueryPeticionWithFilters?: any;
}

const PaginationTableCollabs = ({
  table,
  Meta,
  evaluation_period_id,
  setCurrentPage,
  setQueryPeticionWithFilters,
}: IPagination) => {
  const { Filters, User, handleGetCollaborators } = useCollaboratorRedux();

  const { setLoadingPagination } = useContext(
    EvaluationContext
  ) as EvaluationContextType;

  const pageStorage = localStorage.getItem("pageLocalStorage");
  const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

  const { page: pageStore } = pageStorageParsed;
  // console.log("Se cambian los filtros -> ", Filters);
  useEffect(() => {
    if (!pageStore) {
      const pagePagination = { page: 1 };

      localStorage.setItem("pageLocalStorage", JSON.stringify(pagePagination));
    }
  }, []);

  const handleChangePaginate = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    event.preventDefault();
    let query = `?`;

    switch (table) {
      case "Collaboratos":
        if (Filters) {
          const pagePagination = { page: page };

          localStorage.setItem(
            "pageLocalStorage",
            JSON.stringify(pagePagination)
          );
          const pageStorage = localStorage.getItem("pageLocalStorage");

          const queryParts: string[] = [];

          for (const key in Filters) {
            if (Filters[key] !== undefined && Filters[key] !== "") {
              if (
                key === "Mas95Porcent" ||
                key === "Menos50Porcent" ||
                key === "TienePoliticPorcent" ||
                key === "SinEvaluacionPorcent"
              ) {
                queryParts.push(`GraphicFilter.${key}=${true}`);
              } else {
                queryParts.push(`${key}=${Filters[key]}`);
              }
            }
          }

          let queryFilters = queryParts.join("&");

          const periodo = validateIdPeriod(evaluation_period_id);

          // console.log("pagina guardada en local", pageStorage);

          if (pageStorage !== null) {
            const pageStorageParsed = JSON.parse(pageStorage);

            const { page: pageStore } = pageStorageParsed;

            // console.log("Tienes algo en local storage", pageStorage);
            query += `${queryFilters}&NombreColaborador=${
              User.nombre
            }&RolColaborador=${
              User.role === "Auditor" ? "Admin" : User.role
            }&IdPeriod=${periodo}&PageSize=10&PageNumber=${pageStore}`;

            setQueryPeticionWithFilters(
              `${queryFilters}&NombreColaborador=${
                User.nombre
              }&RolColaborador=${
                User.role === "Auditor" ? "Admin" : User.role
              }&IdPeriod=${periodo}&PageSize=10&PageNumber=1`
            );

            handleGetCollaborators(query);
            setLoadingPagination(true);
          } else {
            query += `${queryFilters}&NombreColaborador=${
              User.nombre
            }&RolColaborador=${
              User.role === "Auditor" ? "Admin" : User.role
            }&IdPeriod=${periodo}&PageSize=10&PageNumber=${page}`;

            setQueryPeticionWithFilters(
              `${queryFilters}&NombreColaborador=${
                User.nombre
              }&RolColaborador=${
                User.role === "Auditor" ? "Admin" : User.role
              }&IdPeriod=${periodo}&PageSize=10&PageNumber=1`
            );

            handleGetCollaborators(query);
            setLoadingPagination(true);
          }
        }

        break;

        // break;

      default:
        return;
    }
  };

  return (
    <ContainPagination>
      <Stack spacing={2}>
        <Pagination
          onChange={(e, page) => {
            handleChangePaginate(e, page);
            if (table === "Collaboratos") {
              setLoadingPagination(true);
            }
          }}
          count={Meta?.totalPages}
          color="primary"
          page={
            pageStore
              ? pageStore
              : Meta?.currentPage
              ? Meta?.currentPage
              : 1
          }
        />
      </Stack>
    </ContainPagination>
  );
};

export default PaginationTableCollabs;
