/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from "react";
import Close from "../../../assets/icons/CloseModal.svg";
import redWarning from "../../../assets/redWarning.svg";
// import { ContainerModal } from "../../../pages/Admin/Objectives/ObjetivesStyles";
import { useQuestions } from "../../../redux/Questionnaires";
import {
  CloseIcon,
  ContainerModal,
  Header,
  ImageIcon,
  ModalBox,
  TextModal,
} from "./ModalStatusStyles";

interface Labels {
  handleClick?: any;
  showData?: boolean | true;
  modalStatus?: boolean;
  message?: string;
  messageDos?: string;
  messageShow?: boolean;
  titleModal?:string;
}

export const ModalStatusTargetError: FC<Labels> = ({
  showData,
  handleClick,
  modalStatus,
  message,
  messageShow,
  messageDos,
  titleModal
}) => {
  
  const { errorPost, setError } = useQuestions();
// console.log("Titulo", titleModal)
  return (
    <ContainerModal showData={showData ? "flex" : "none"}>
      <ModalBox>
        <Header>
          <div>
            <ImageIcon src={redWarning} style={{ marginRight: "12px" }} />
           {titleModal}
          </div>
          <CloseIcon src={Close} onClick={() => handleClick(!modalStatus)} />
        </Header>
        <TextModal>
          {messageShow
            ? message
            : "No se pudo agregar el objetivo, por favor intenta nuevamente"}
        </TextModal>
        <TextModal fontSz="20px" lineH="auto">
          {messageDos !== "" && messageDos !== undefined ? messageDos : null}
        </TextModal>
      </ModalBox>
    </ContainerModal>
  );
};

export default ModalStatusTargetError;
