import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState} from "react";
import { useSocket } from "../hooks/Socket/useSocket";
import { Socket } from "socket.io-client";
import { useLocation } from "react-router-dom";

interface ISocketContext {
  socket?:Socket 
  online:boolean
  idRoom?:string 
}

interface ISocketContextProvider {
  socket?:Socket 
  online:boolean
  idRoom?:string 
  setIdRoom: Dispatch<SetStateAction<string | undefined>>;
}

interface MyComponentProps {
    children: ReactNode;
  }
  
const intialState: ISocketContext = {
  online: false,
  socket: undefined, 
  idRoom: undefined
}

export const SocketContext = React.createContext<ISocketContextProvider>(intialState as ISocketContextProvider);

const SocketProvider: React.FC<MyComponentProps> = ({ children }) => {
  const location = useLocation()     
  const [idRoom, setIdRoom] = useState<string>()
  const token = sessionStorage.getItem('token') as string
  const apiSocket = process.env.REACT_APP_API_URL_SOCKET

  const { online , socket} = useSocket(apiSocket as string, { token  })


  useEffect(() => {
     if(!location.pathname.includes('/evaluacion') && idRoom){
         socket.emit('leave-room', idRoom)
         setIdRoom(undefined)
     }
 // eslint-disable-next-line react-hooks/exhaustive-deps
 },[location.pathname])

 useEffect(() => {
  if(!location.pathname.includes('/Periodo')){
      socket.emit('leave-period')
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[location.pathname])


   return (
            <SocketContext.Provider value={{socket , online , idRoom, setIdRoom}}>
              {children}
            </SocketContext.Provider>
    )
}   

export default SocketProvider