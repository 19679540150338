import arrowDown from "../../../assets/arrowDown.svg";
import arrowUp from "../../../assets/arrowUp.svg";
import { UpArrow } from "../../../pages/Admin/Dashboard/components/ProgresoEvaluaciones/ProgresoEvaluaciones.styles";
import {
  EvaluatedHeaderContainer,
  HeaderTitle,
} from "./ColaboratorHeaderEvaluationStyles";

const ColaboratorHeaderEvaluation = ({ setHide, hide }) => {
  return (
    <>
      <EvaluatedHeaderContainer>
        {/* {ShowWindow ? (
          <HeaderTitle>
            {User?.role === "Evaluador"
              ? "Evaluación de colaboradores"
              : "KPI's Evaluación de colaboradores"}
          </HeaderTitle>
        ) : ( */}
        <HeaderTitle>
          Analítica de desempeño en evaluaciones de colaboradores
        </HeaderTitle>
        {/* )} */}
        <div className="containerToggle">
          <UpArrow
            alt=""
            src={hide ? arrowUp : arrowDown}
            onClick={() => setHide(!hide)}
          />
        </div>
      </EvaluatedHeaderContainer>
      {/* <EvaluatedHeaderContainer /> */}
      {/* <Button variant="outline-light">Enviar evaluaciones a RH</Button> */}
    </>
  );
};

export { ColaboratorHeaderEvaluation };

