/* eslint-disable no-restricted-globals */
import styled from "styled-components";
export const ContainPageQuestionaire = styled.div`
  background: #fff;
  width: 83vw;
  margin: 0 auto;
  padding: 24px 24px;
  @media (min-width: 320px) and (max-width: 768px) {
    background: #ffffff;
  }
`;

export const containerResult = styled.div`
  width: 74px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
`;

export const ContainTitleIcon = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  padding: 10px 0px;
`;
export const ContainIconInfo = styled.div`
  /* padding-left: 0.3%; */
`;
export const ButtonContainer = styled.button`
  border-radius: 8px;
  border-style: none;
  font-size: 18px;
`;

export const CenterPaginate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4%;
  @media (min-width: 320px) and (max-width: 1023px) {
    margin-bottom: 0%;
    margin-top: 16%;
  }
`;

export const flexBox = {
  marginBottom: "8px",
};
export const ColoBackground = {
  background: "#3B63A2",
};
export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyleInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
  }
`;

export const ContainModal = styled.div`
  padding: 16px;
  gap: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

export const RowBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  /* border: 1px solid #f0f0f0; */
`;

export const RowBoxDos = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 40px;
  margin-bottom: 16px;
  /* border: 1px solid red; */
  @media screen and (max-width: 1024px) {
    /* flex-direction: column; */
    /* justify-content: center; */
    align-content: center;
  }
`;

export const FliterByTiemRow = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media screen and (max-width: 770px) {
    flex-direction: row;
    width: 100%;
  }
`;

export const ImgSize = styled.img`
  width: 40px;
  /* padding-top: 2rem; */
  align-items: flex-start;
  /* margin-bottom: 3%; */
`;
export const ImgSizeDos = styled.img`
  width: 40px;
  /* padding-top: 2rem; */
  align-items: flex-start;
  /* margin-bottom: 3%; */
`;

export const ContainerInputsSelects = styled.div`
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  height: 100%;
`;

export const UploadBox = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  /* max-width: 550px; */
  /* border: 1px solid red; */

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const FormModal = styled.div`
  height: 52px;
  width: 100%;
  padding: 6px 10px;
  border-radius: 8px;
  background-color: #ffe6e6;
  font-size: 12px;
  .notiContainer {
    display: flex;
    height: 20px;
    align-self: center;
    align-content: center;
    gap: 4px;
    width: 90px;
    font-weight: 500;
  }
`;

export const ContainerUpload = styled.div`

  gap: 16px;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  @media screen and (max-width: 768px) {
    background: #f8f8f8;
  }
  @media screen and (min-width: 1200px) {
    height: 69px;
    width: 100%;
    max-width: 550px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BtnsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 40px;

  @media screen and (min-width: 768px) {
    width: 100px;
    flex-direction: row;
  }
`;

export const ButtonClean = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: #c9f2ff;
  color: #3b63a2;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonSelect = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: #3b63a2;
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;

  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
`;

export const Paragraph = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #3b63a2;
  margin-bottom: 4px;
`;

export const ButtonFileDos = styled.input`
  width: 157px;
  background: #3b63a2;
  border-radius: 8px;
  cursor: pointer;
  display: none;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const ButtonFile = styled.input`
  cursor: pointer;
  gap: 8px;
  width: 157px;
  height: 40px;
  background: #3b63a2;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  ::before {
    padding: 8px 32px;
    gap: 8px;
    width: 157px;
    height: 40px;
    background: #3b63a2;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border-radius: 8px;
    content: "Seleccionar";
  }

  input[type="file"] {
    opacity: 0;
    width: 200px;
    height: 32px;
    display: inline-block;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    padding-left: 49px;
  }
`;

export const ButtonFileManual = styled.button`
  display: flex;
  /* width: 86px; */
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: #3b63a2;
  color: #fff;
  line-height: 16px;
  /* height: 40px; */
  font-weight: 700;
  font-size: 14px;
  /* ::before {
    padding: 8px 32px;
    gap: 8px;
    width: 157px;
    height: 40px;
    background: #3b63a2;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
    border-radius: 8px;
    content: "Agregar";
  } */

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const InputFile = styled.input.attrs({ type: "file" })`
  padding: 8px 32px;
  gap: 8px;
  width: 157px;
  height: 40px;
  background: #3b63a2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  content: "Seleccionar"; /* testo por defecto */
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .file-select input[type="file"] {
    opacity: 0;
    width: 200px;
    height: 32px;
    display: inline-block;
  }

  #src-file1::before {
    content: "Seleccionar Archivo 1";
  }

  #src-file2::before {
    content: "Seleccionar Archivo 2";
  }
`;

export const InpuTextBtn = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

export const Title = styled.p`
  font-weight: 700;
  color: #3b63a2;
  margin-bottom: 0;
  font-size: 28px;
  line-height: 100%;
  @media screen and (min-width: 768px) {
    font-size: 20px;
  }
`;
export const TitleDos = styled.h4`
  //styleName: H4;
  font-family: "Plus Jakarta Sans";
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;
export const SubTitleDos = styled.p`
  font-family: "Plus Jakarta Sans";
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
export const SubTitleTres = styled.p`
  font-weight: 700;
  color: #3b63a2;
  margin-bottom: 0;
  font-size: 18px;
  /* border: 1px solid #3b63a2; */
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ContainTitleIconDos = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  /* justify-content: center; */
  gap: 6px;
  padding: 24px 0px 48px 0px;
  /* margin-bottom: 40px; */
  /* border: 1px solid #3b63a2; */
`;

export const SubTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #939396;

  @media screen and (min-width: 768px) {
    font-size: 14px;
  }
`;

export const TableMain = styled.table`
  margin-bottom: ${(props) => props.bottom || "0%"};
`;

export const Row = styled.tr`
  font-size: 14px;
  font-weight: 700;
  background: ${(props) => props.background || "#FFFFF"};
`;

export const Column = styled.td`
  color: ${(props) => props.color};
  padding: ${(props) => props.padding || "10px 50px"};
  font-weight: 400;
  font-size: 14px;
`;

export const CellHeadingText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  text-align: center;

  /* Neutrals/White */

  color: #ffffff;
`;

export const ButtonActions = styled.button`
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 8px;
  width: 178px;
  height: 33px;
  background-color: #c9f2ff !important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3b63a2;
  border-style: none;
`;

export const Circle = styled.div`
  width: 5px;
  height: 5px;
  background: black;
  border-radius: 50%;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  /* width:${(props) => props.width || "1150px"}; */
  /* height: 1000px; */
  background: #ffffff;
  border-radius: 16px;
`;
export const SubContainerDos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  /* width:${(props) => props.width || "1150px"}; */
  /* height: 1000px; */
  background: #ffffff;
  border-radius: 16px;
  @media screen and (max-width: 1024px) {
    margin-top: 40px;
    padding: 0px;
  }
`;

export const AdminContainerBlue = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  padding: 0 24px;
  width: 82vw;

  /* .gapEelements {
    margin-right: 8px;
  } */
  @media screen and (min-width: 320px) and (max-width: 768px) {
    background-color: white;
    padding: 0px;
  }
`;

export const ContainerPestaña = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 2px;
  isolation: isolate;
  /* width: 437px; */
  height: 35px;
`;

export const ContainerCarga = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 8px; */
  gap: 16px;
  height: 48px;
  background: #ffffff;
  border-radius: 8px;
`;

export const ContainerDetaiCarga = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 167px;

  width: 240px;
  height: 16px;
`;

export const TextCarga = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 160%;
  color: #3b63a2;
`;

export const ContainerBar = styled.div`
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 240px;
  height: 9px;
  background: #f0f0f0;
  border-radius: 1000px;
`;

export const TitleModal = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Bangers&display=PlusJakartaSans");

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #1f2a40;
  /* width: 245px; */
  height: 22px;
  display: flex;
  gap: 16px;
  /* border: 1px solid red; */
  padding-right: 10px;
`;

export const StyleSelect = styled.div`
  display: flex;
  width: 200px;
  height: 32px;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  padding: 8px 32px;
  gap: 8px;
  background: #3b63a2;
  border-radius: 8px;
  font-family: inherit;
  font-style: normal;
  font-weight: 700;
  font-size: inherit;
  line-height: inherit;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;
export const BoxMobileModal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  @media (min-width: 320px) and (max-width: 1023px) {
    display: block;
  }
`;
export const TextButton = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;
export const ButtonM = styled.div`
  padding: 8px 24px;

  background: #3b63a2;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //styleName: Small Body Bold;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;

  @media screen and (max-width: 768px) {
    width: -webkit-fill-available;
  }
`;

export const DivTable = styled.div`
  width: 100%;
`;
export const RowHead = styled.tr`
  padding: 0px 24px;
  background: #3b63a2;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  height: 40px;
  border-radius: 8px;
  /* border: 1px solid #3b63a2; */
  color: #ffffff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;
export const BoxMobileModalQuestion = styled.div`
  display: block;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
`;

export const ContainButtonMobile = styled.div`
  /* display: flex; */
`;
export const ContainButtonMobileDos = styled.div`
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;
export const ContainButtonMobileTres = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    width: 100%;
  }
`;

export const ContainerFiltersObjetivos = styled.div`
  display: flex;
  /* border: 1px solid red; */
  flex-direction: column;
  width: 100%;

  .tabsStyles {
    padding: 8px 16px 8px 16px;
    border-radius: 8px 8px 0px 0px;
    background-color: #c9f2ff;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    color: #3b63a2;
    width: 100px;
  }
`;

export const OnlyFiltersContainer = styled.div`
  display: flex;
  border: 1px solid #f0f0f0;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;
