import { ApiResponse, EvaluationNew, IGeneralObjetives, IIndividualObjetives, IResponse, IValidationFileResult, JobPerformanceInterface, PeriodYearsSemesters } from '../Interface/ApiInterface';
import { config, http } from '../api';

const setIndividualObjetivesServices = async (id: number, objetivos: any) => {
  // console.log("objetivos seteados : ",objetivos);
  
  return new Promise<ApiResponse<EvaluationNew>>((resolve, reject) => {
    http
      .post(`Evaluations/update-objetives-evaluation/${id}`, objetivos)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAllGeneralObjetivesServices = async (query: string) => {
    return new Promise<ApiResponse<IGeneralObjetives[]>>((resolve, reject) => {
      http
        .get(`Objetives/get-all-general-objetvies/G${query}`)
        .then((response) => {
          const { data, meta } = response.data;
          
          const res = {
            data,
            meta,
          };
          resolve(res);
        })
        .catch((error) => {
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  };

const getAllIndividualObjetivesServices = async (query: string) => {  
    return new Promise<ApiResponse<IIndividualObjetives[]>>((resolve, reject) => {
      http
        .get(`Objetives/get-all-general-objetvies/I${query}`)
        .then((response) => {
          const { data, meta } = response.data;
          const res = {
            data,
            meta,
          };
          resolve(res);
        })
        .catch((error) => {          
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  };

  const getPeriodYearsSemesters = async () => {  
    return new Promise<ApiResponse<PeriodYearsSemesters>>((resolve, reject) => {
      http
        .get(`/Period/YearsSemesters`)
        .then((response) => {
          const { data } = response;
          const res = {
            data,
            meta: data.meta
          };
          
          
          
          resolve(res);
        })
        .catch((error) => {          
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  };

const BulkGeneralObjetivesUploadServices = async (file: File) => {
    const data = new FormData();
    data.append('file', file);

    return new Promise<IResponse<IValidationFileResult>>((resolve, reject) => {
      http
        .post(`Objetives/masive-general-upload`,  data, config)
        .then((response) => {                   
          resolve(response.data);
        })
        .catch((error) => {  
          reject(error.response.data[0]);
        });
    });
  };

  const BulkIndividualObjetivesUploadServices = async (file: File) => {
    const data = new FormData();
    data.append('file', file);
    return new Promise<IResponse<IValidationFileResult>>((resolve, reject) => {
        http
          .post(`Objetives/masive-individual-upload`,  data, config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data[0]);
          });
      });
  };

  const postGeneralObjetivesUploadServices = async (body: IGeneralObjetives | IIndividualObjetives) => {
    return new Promise<IResponse<JobPerformanceInterface>>((resolve, reject) => {
      http
        .post(`Objetives/one-by-one-general-upload`, body)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  };

  const postIndividualObjetivesUploadServices = async (body: IIndividualObjetives) => {
    return new Promise<IResponse<JobPerformanceInterface>>((resolve, reject) => {
      http
        .post(`Objetives/one-by-one-individual-upload`, body)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  };

  const deleteGeneralObjetivesUploadServices = async (id: number) =>{
    return new Promise<IResponse<JobPerformanceInterface>>((resolve, reject) => {
      http
        .delete(`Objetives/Delete/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  }

  const putGeneralObjetivesUploadServices = async (id: number, data: IGeneralObjetives | IIndividualObjetives) => {
    return new Promise<IResponse<JobPerformanceInterface>>((resolve, reject) => {
      http
        .put(`Objetives/Update/${id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  }

export { BulkGeneralObjetivesUploadServices, BulkIndividualObjetivesUploadServices, deleteGeneralObjetivesUploadServices, getAllGeneralObjetivesServices, getAllIndividualObjetivesServices, getPeriodYearsSemesters, postGeneralObjetivesUploadServices, postIndividualObjetivesUploadServices, putGeneralObjetivesUploadServices, setIndividualObjetivesServices };

