import { FC, useEffect, useState } from "react";
import {
  BodyTableWrapper,
  BodyTableWrapperWrap,
  GoalsBodyTablePoliticas,
  GoalsHeadTablePoliticas,
} from "../../../../components/atoms/EvaluationForms/EvaluationFormsStyles";
import ModalStatusTargetError from "../../../../components/organisms/ConfirmationModals/ModalStatusError";
import ModalStatusSuccessful from "../../../../components/organisms/ConfirmationModals/ModalStatusSuccessful";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import { usePolitics } from "../../../../redux/Politics";
import { SaveBtnDos } from "../../ModalActions/ModalEditStyles";

import ModalPoliticas from "./ModalPoliticas";

interface ModalEdit {
  period?: any;
  queryFilter?: any;

}

export const PoliticsTable: FC<ModalEdit> = ({ period, queryFilter }) => {
  const [show, setShow] = useState(false);
  const [dataPolicies, setDataPolicies] = useState<any>({
    idEvaluacion: 0,
    calificacion: 0,
    totalPreguntas: 0,
    totalAciertos: 0,
  });

  const {
    handleUpdatePolicies,
    politic,
    handleGetAllPolitics,
    succesPost,
    errorPost,
    setSuccesPost,
    setErrorPost,
    FilterPolitic,
    handleGetAllPoliticsFilter,
  } = usePolitics();

  const [modalNotify, setModalNotify] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [mensaje, setMensaje] = useState("");

  const handleClose = () => {
    setShow(false);
    setDataPolicies({
      ...dataPolicies,
      idEvaluacion: 0,
      calificacion: 0,
      totalPreguntas: 0,
      totalAciertos: 0,
    });
  };
  const handleShow = (e: any, idEvaluacion: any) => {
    const politicData = politic.find(
      (item) => item.idEvaluacion === idEvaluacion
    );
    setDataPolicies({
      ...dataPolicies,
      idEvaluacion: idEvaluacion,
      calificacion: politicData?.calificacion,
      totalAciertos: politicData?.totalAciertos,
      totalPreguntas: politicData?.totalPreguntas,
    });
    setShow(true);
  };

  const validateBtn = () => {
    if (
      dataPolicies.calificacion >= 0 &&
      dataPolicies.calificacion !== null &&
      dataPolicies.totalPreguntas < 1000 &&
      dataPolicies.totalPreguntas >= 0 &&
      dataPolicies.totalAciertos < 1000 &&
      dataPolicies.totalAciertos >= 0
    ) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  useEffect(() => {
    validateBtn();
  }, [dataPolicies]);

  const onSubmit = () => {
    handleUpdatePolicies(dataPolicies);
    handleClose();
   
    setTimeout(() => {
      handleGetAllPoliticsFilter(queryFilter);
    }, 1500);
  };

  // console.log(queryFilter)

  const handleChange = (e: any) => {
    if (e.target.value.length < 4) {
      setDataPolicies({
        ...dataPolicies,
        [e.target.name]: parseInt(e.target.value),
      });
      setModalNotify(false);
    } else {
      setDataPolicies({
        ...dataPolicies,
        [e.target.name]: parseInt(e.target.value),
      });
      setModalNotify(true);
      setMensaje("No puede ser una cifra mayor a 999");
    }
  };
  const { evaluationAttribute } = useCollaboratorRedux();
  return (
    <BodyTableWrapper>
      <BodyTableWrapperWrap>
        <GoalsHeadTablePoliticas>
          <div className="first">Fortia</div>
          <div className="second">Nombre</div>
          <div className="third">Porcentaje</div>
          <div className="fourth">Calificación</div>
          <div className="fifth">Estatus</div>
          {evaluationAttribute.upload_rate_politics_by_form === 1 && (
            <div className="sixth">Acción</div>
          )}
        </GoalsHeadTablePoliticas>
        {politic.map((data, index) => {
          // console.log("dato ->", data, index);

          return (
            <GoalsBodyTablePoliticas key={data.claTrab}>
              <div className="first">{data.claTrab}</div>
              <div className="second">{data.nombre}</div>
              <div className="third">{data.porcentaje}</div>
              <div className="fourth">{data.calificacion}</div>
              <div className="fifth">
                {data.estaCalificado
                  ? "Resultados subidos"
                  : "Pendiente subir resultado"}
              </div>
              {evaluationAttribute.edit_delete_results_politics_by_collaborator ===
                1 && (
                <div className="sixth">
                  <SaveBtnDos
                    disabled={data.estaCalificado}
                    disabledP={data.estaCalificado}
                    onClick={(e) => handleShow(e, data.idEvaluacion)}
                  >
                    Calificar
                  </SaveBtnDos>
                  <SaveBtnDos
                    disabled={!data.estaCalificado}
                    disabledP={!data.estaCalificado}
                    onClick={(e) => handleShow(e, data.idEvaluacion)}
                  >
                    Ver / Editar
                  </SaveBtnDos>
                </div>
              )}
            </GoalsBodyTablePoliticas>
          );
        })}
      </BodyTableWrapperWrap>

      <ModalPoliticas
        show={show}
        handleClose={handleClose}
        dataPolicies={dataPolicies}
        handleChange={handleChange}
        modalNotify={modalNotify}
        mensaje={mensaje}
        onSubmit={onSubmit}
        disabledBtn={disabledBtn}
      />

      {succesPost ? (
        <ModalStatusSuccessful
          handleClick={setSuccesPost}
          modalStatus={succesPost}
          messageShow
          message="Los resultados de la política se han guardado correctamente. "
          titleModal="Resultados guardados"
        />
      ) : null}
      {errorPost ? (
        <ModalStatusTargetError
          handleClick={setErrorPost}
          modalStatus={errorPost}
          messageShow
          message="No se pudo editar, por favor intenta nuevamente"
          titleModal="Lo sentimos"
        />
      ) : null}
    </BodyTableWrapper>
  );
};
