import styled from "styled-components";

export const ModalOverlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`

export const MainCotainer = styled.div`
    display: flex;
    width: 640px;
    padding: 32px;
    flex-direction: column;
    justify-content: center;
   // align-items: center;
    gap: 32px;
    border-radius: 18px;
    background: #FFF;
`

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const TitleImage = styled.div`
    display: flex;
    align-items: center;
    gap:8px
`

export const TitleText = styled.h2`
    color: var(--primary-true-blue, #3B63A2);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 22px */;
    margin:0
`

export const TextBody = styled.p`
    color: var(--neutrals-jet, #333335);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
`