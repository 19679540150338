import styled from "styled-components";

export const BtnActions = styled.div`
  background: rgb(59, 99, 162); 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  width: 56px;
  height: 56px;
  color: white;
  cursor: pointer;
  box-shadow: -4px 4px 7px 0px #00000040;
`;
export const MoreActions = styled.div`
  background: rgb(59, 99, 162);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 6px;
  color: white;
  cursor: pointer;
  box-shadow: -4px 4px 7px 0px #00000040;
`;

export const ContainerButtonFloat = styled.div`
  display: flex;
  position: fixed;
  bottom: 20%;
  right: 25px;
  flex-direction: row-reverse;
  gap: 8px;
  height: 100px;
`;


export const valueDefault = {
  value: "null",
  label: "Selecciona una opción",
  semester: "2",
};

export const FirmsBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  line-height: 12px;
  font-size: 10px;
  color: #fff;
  flex-direction: column;
  cursor: pointer;
`;

export const ButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;