import { useEffect, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "../Evaluation/useSemesterYearNow";

export const useAbleQualificationGlobalPlanta = () => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();

  //** Hooks

  //** States
  const [ableQualificationGlobalPlanta, setAbleQualificationGlobalPlanta] =
    useState(true);

  //** Esta es una evaluación (NO ES MI EVALUACIÓN)
  const evaluation = !urlPermisos;
  const { isActualDate } = useSemesterYearNow();
  useEffect(() => {
    //**  +++++++++++++++++++++++++++  EVALUACIÓN +++++++++++++++++++++++
    if (User.role === "Auditor") {
      setAbleQualificationGlobalPlanta(true);
    }

    if (User.role === "Evaluado") {
      setAbleQualificationGlobalPlanta(true);
    }

    if (User.role === "Admin TI") {
      if (evaluation) {
        if (Evaluation.catStatusEvaluationId === 7) {
          if (
            evaluationAttribute?.edit_column_score_global_mixto === 1 &&
            evaluationAttribute?.edit_evaluation_complete === 1 &&
            isActualDate
          ) {
            setAbleQualificationGlobalPlanta(false); // Habilita
          } else {
            setAbleQualificationGlobalPlanta(true); //Inhabilita
          }
        } else if (Evaluation.catStatusEvaluationId === 6) {
          setAbleQualificationGlobalPlanta(true); //Inhabilita
        } else {
          if (evaluationAttribute?.edit_column_score_global_mixto === 1) {
            setAbleQualificationGlobalPlanta(false); // Habilita
          } else {
            setAbleQualificationGlobalPlanta(true); //Inhabilita
          }
        }
      }

      //**  +++++++++++++++++++++++++++   AUTOEVALUACIÓN +++++++++++++++++++++++

      if (!evaluation) {
        setAbleQualificationGlobalPlanta(true); // inhabilita
      }
    } else {
      setAbleQualificationGlobalPlanta(true); // Habilita
    }
  }, [
    Evaluation.catStatusEvaluationId,
    User.role,
    evaluation,
    evaluationAttribute.edit_column_score_global,
    evaluationAttribute?.edit_column_score_global_mixto,
    evaluationAttribute?.edit_evaluation_complete,
    isActualDate,
  ]);

  return {
    ableQualificationGlobalPlanta,
  };
};
