import React, { ReactNode, useEffect, useState } from "react";
import { useUrlPermisos } from "../hooks/Evaluation";
import { useCollaboratorRedux } from "../redux/Collaborators";
import { useEvaluationRedux } from "../redux/Evaluations";

export interface ITodo {
  id: number;
  title: string;
  description: string;
  status: boolean;
}
export type EvaluationContextType = {
  ableInput: any;
  futureObjetives: any;
  setFutureObjetives: any;
  evaluationInfo: any;
  setEvaluationInfo: any;
  periodId: any;
  setPeriodId: any;
  isReduxOrContext: any;
  setIsReduxOrContext: any;
  evaluatedOrEvaluation: any;
  setEvaluatedOrEvaluation: any;
  loadingPagination: any;
  setLoadingPagination: any;
  changePage: any;
  setChangePage: any;
  disableActionEvaluationBtn: any;
  setDisableActionEvaluationBtn: any;
  yearId: any;
  setYearId: any;
  periodPeticion: any;
  setPeriodPeticion: any;
  semester: any
  setSemester: any
  periodAutoEvaluation: any
  setPeriodAutoEvaluation: any
  setIsActiveEvaluation: any
  isActiveEvaluation: any
};

interface MyComponentProps {
  children: ReactNode;
}


export const EvaluationContext =
  React.createContext<EvaluationContextType | null>(null);

const EvaluationProvider: React.FC<MyComponentProps> = ({ children }) => {


  const { evaluationAttribute } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  //* Custom hooks
  const { urlPermisos } = useUrlPermisos();

  const [futureObjetives, setFutureObjetives] = useState([])
  const [ableInput, setAbleInput] = useState<boolean>(false);
  const [evaluationInfo, setEvaluationInfo] = useState<any>({})
  const [evaluatedOrEvaluation, setEvaluatedOrEvaluation] = useState(false)
  const [loadingPagination, setLoadingPagination] = useState(false)
  const [changePage, setChangePage] = useState(false)

  //** Para hacer peticiones guardados desde TableDashboard
  const [periodId, setPeriodId] = useState(null)
  const [periodPeticion, setPeriodPeticion] = useState(null)
  const [yearId, setYearId] = useState(null)
  // const [yearId, setYearId ]= useState(null)

  const [semester, setSemester] = useState()


  const [disableActionEvaluationBtn, setDisableActionEvaluationBtn] = useState(false)


  //!! Se activa redux o context en Evaluación de objetivos futuros

  const [isReduxOrContext, setIsReduxOrContext] = useState(false)

  const [isActiveEvaluation, setIsActiveEvaluation] = useState(false)



  //? Cambio de periodo de la evaluación 

  const [periodAutoEvaluation, setPeriodAutoEvaluation] = useState("")


  useEffect(() => {


    //** Evaluación
    if (!urlPermisos) {


      if (
        Evaluation.catStatusEvaluationId > 0 &&
        Evaluation.catStatusEvaluationId < 7
      ) {
        setAbleInput(false);
      }


      //** Caso para los admins
      if (
        Evaluation.catStatusEvaluationId === 7 &&
        evaluationAttribute.edit_evaluation_complete === 1
      ) {
        setAbleInput(false);
      }


      //** Caso para los que no tienen permisos
      if (
        Evaluation.catStatusEvaluationId === 7 &&
        evaluationAttribute.edit_evaluation_complete === 0
      ) {
        setAbleInput(true);
      }

    }

    //** Autoevaluación
    if (urlPermisos) {


      if (
        Evaluation.catStatusEvaluationId > 0 &&
        Evaluation.catStatusEvaluationId < 6
      ) {
        // console.log("Entra aquí!");
        setAbleInput(false);
      }


      //** Caso para los admins
      if (
        Evaluation.catStatusEvaluationId === 7 &&
        evaluationAttribute.edit_evaluation_complete === 1
      ) {
        // console.log("Entra aquí!");
        setAbleInput(false);
      }

      //** Caso para los que no tienen permisos
      if (
        Evaluation.catStatusEvaluationId === 7 &&
        evaluationAttribute.edit_evaluation_complete === 0
      ) {
        setAbleInput(true);
      }

    }

  }, [Evaluation.catStatusEvaluationId, evaluationAttribute.edit_evaluation_complete, urlPermisos]);






  return (
    <EvaluationContext.Provider
      value={{
        ableInput,
        futureObjetives,
        setFutureObjetives,
        evaluationInfo,
        setEvaluationInfo,
        periodId,
        setPeriodId,
        isReduxOrContext,
        setIsReduxOrContext,
        evaluatedOrEvaluation,
        setEvaluatedOrEvaluation,
        loadingPagination,
        setLoadingPagination,
        changePage,
        setChangePage,
        disableActionEvaluationBtn,
        setDisableActionEvaluationBtn,
        yearId,
        setYearId,
        periodPeticion,
        setPeriodPeticion,
        semester,
        setSemester,
        periodAutoEvaluation, 
        setPeriodAutoEvaluation,
        setIsActiveEvaluation,
        isActiveEvaluation
      }}

    >
      {children}
    </EvaluationContext.Provider>
  );
};

export default EvaluationProvider;
