import { http } from "../api";

const getPercentage = async (IdPeriod: number, name: string, role: string) => {
  // const periodo = validateIdPeriod(IdPeriod)
  try {
    if (IdPeriod && name && role ) {
      // console.log(
      //   `/Evaluations/get-evaluations-status-percentage/${IdPeriod}/${name}/${role}`
      // );

      const response = await http.get(
        `/Evaluations/get-evaluations-status-percentage/${IdPeriod}/${name}/${role}`
      );
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export { getPercentage };

