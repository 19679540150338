import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 40px; */
  align-self: stretch;
  background-color: #fff;
  margin-top: 24px;
`;
export const Title = styled.div`
  color: var(--primary-black, #000);
  font-feature-settings: "clig" off, "liga" off;
  /* H3 */
  font-family: Plus Jakarta Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 38.4px */
  margin: 0px;
  display: flex;
  /* gap: 12rem; */
  align-items: center;
  /* border: 1px solid red; */
  width: 100%;
  justify-content: space-between;
  .containerToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

export const Subtitle = styled.h3`
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;
  /* H4 */
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 33.6px */
  margin: 0px;
  margin-bottom: 16px;
`;
export const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  @media screen and (max-width: 1064px) {
    flex-direction: column;
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 0;
  align-items: center;
  width: 100%;
`;

export const TitleSelect = styled.div`
  width: 100%;
  padding: 16px 8px;
  background-color: #fff;
  text-align: flex-start;
  color: var(--neutrals-jet, #333335);
  font-feature-settings: "clig" off, "liga" off;
  /* Small Body */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  border: 1px solid var(--background-light-gray, #f8f8f8);
`;

export const InputFilter = styled.input`
  width: 100%;
  padding: 16px 8.25px;
  text-align: flex-start;
  color: var(--neutrals-jet, #333335);
  font-feature-settings: "clig" off, "liga" off;
  /* Small Body */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  border-width: 0;
  border-radius: 0px 8px 8px 0px;
  background: var(--background-light-gray, #f8f8f8);
`;

export const ButtonDescargar = styled.button`
  display: flex;

  justify-content: center;
  align-items: center;
  /* gap: 8px; */
  align-self: stretch;
  color: var(--neutrals-white, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  border-radius: 8px;
  background: var(--primary-true-blue, #3b63a2);
  border-style: solid;
  /* Small Body Bold */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  border: none;
  padding: 8px 24px;
`;

export const ButtonLimpiar = styled.button`
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: none;
  background: var(--secondary-blizzard-blue, #c9f2ff);
  color: var(--primary-granite-gray, #656569);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* Small Body Bold */
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #3b63a2;
  line-height: 16px; /* 114.286% */
`;

export const LineGray = styled.div`
  width: 100%;
  height: 2px;
  background: #f0f0f0;
`;
