//styles
import { HeaderContainer, MainContainer, ModalBackground, Subtitle, Title, TitleContainer } from './ModalStatusActualizado.styles';
//assets
import info from '../../../../../assets/checkGreen.svg';
import close from '../../../../../assets/closeButton.svg';

interface ModalCambioProps {
  setShowModalEstatusActualizado: (show: boolean) => void;
  showModalEstatusActualizado: boolean;
  getAllInfo: any;
  setListStringLocalidad: any;

}
export default function ModalStatusActualizado({
  setShowModalEstatusActualizado,
  showModalEstatusActualizado,
  getAllInfo,
  setListStringLocalidad

}: ModalCambioProps) {

  const handleCloseModal = () => {
    setShowModalEstatusActualizado(!showModalEstatusActualizado)
    getAllInfo(true)
    setListStringLocalidad("")
  }
  return (
    <ModalBackground>
      <MainContainer style={{gap: "0px"}}>
        <HeaderContainer style={{marginBottom: "32px"}}>
        <TitleContainer >
          <img src={info} alt='' />
          <Title>Estatus actualizado</Title>
        </TitleContainer>
        <img style={{cursor:'pointer'}} src={close} alt=''onClick={handleCloseModal}/>
        </HeaderContainer>
        <Subtitle style={{marginBottom: "10px"}}>La solicitud de cambio de estatus está en espera de aprobación por parte del equipo TI.</Subtitle>
        {/* <br/> */}
        <Subtitle>Te notificaremos tan pronto como se complete el proceso.</Subtitle>
        {/* <ButtonCerrar onClick={handleCloseModal}>Cerrar</ButtonCerrar> */}

      </MainContainer>
    </ModalBackground>
  )
}
