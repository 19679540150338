function NotificationIcon({ fill, width, height }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.45215V7.95215M13 7.45215C13 8.24008 12.8448 9.0203 12.5433 9.74825C12.2417 10.4762 11.7998 11.1376 11.2426 11.6948C10.6855 12.2519 10.0241 12.6939 9.2961 12.9954C8.56815 13.297 7.78793 13.4521 7 13.4521C6.21207 13.4521 5.43185 13.297 4.7039 12.9954C3.97595 12.6939 3.31451 12.2519 2.75736 11.6948C2.20021 11.1376 1.75825 10.4762 1.45672 9.74825C1.15519 9.0203 1 8.24008 1 7.45215C1 5.86085 1.63214 4.33473 2.75736 3.20951C3.88258 2.08429 5.4087 1.45215 7 1.45215C8.5913 1.45215 10.1174 2.08429 11.2426 3.20951C12.3679 4.33473 13 5.86085 13 7.45215ZM7 9.95215H7.00533V9.95748H7V9.95215Z"
        stroke={fill}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default NotificationIcon;
