import { useEffect, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useUrlPermisos } from "../Evaluation";
import { useSemesterYearNow } from "../Evaluation/useSemesterYearNow";

export const useAbleFortalezasDebilidades = () => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  //** Custom hooks
  const { urlPermisos } = useUrlPermisos();
  const { isActualDate } = useSemesterYearNow();

  //** States
  const [enablePermissionStrAndOp, setEnablePermissionStrAndOp] =
    useState(true);
  const evaluationDate = `${Evaluation?.evaluationPeriod?.evaluationPeriodNumberOfYear} ${Evaluation?.evaluationPeriod?.evaluationPeriodYear}`;
  const dateNow = getCurrentYearAndSemester();

  // console.log("Este usuario es!", User.role);

  useEffect(() => {
    //** Evaluación

    if (User.role === "Auditor") {
      setEnablePermissionStrAndOp(true);
    }

    if (User.role !== "Auditor") {
      if (!urlPermisos) {
        if (
          Evaluation.catStatusEvaluationId > 0 &&
          Evaluation.catStatusEvaluationId < 5
        ) {
          setEnablePermissionStrAndOp(false);
        }
        if (Evaluation.catStatusEvaluationId === 6) {
          setEnablePermissionStrAndOp(true);
        }
        if (Evaluation.catStatusEvaluationId === 7) {
          if (evaluationDate === dateNow) {
            if (evaluationAttribute.edit_evaluation_complete === 1) {
              setEnablePermissionStrAndOp(false);
            }
            //** Caso para los que no tienen permisos
            if (evaluationAttribute.edit_evaluation_complete === 0) {
              setEnablePermissionStrAndOp(true);
            }
          } else {
            setEnablePermissionStrAndOp(true);
          }
        }
      }

      //** Autoevaluación
      if (urlPermisos) {
        setEnablePermissionStrAndOp(true);
      }
    }
  }, [
    Evaluation.catStatusEvaluationId,
    User,
    evaluationAttribute.edit_evaluation_complete,
    isActualDate,
    urlPermisos,
    Evaluation,
    evaluationDate,
    dateNow,
  ]);

  return {
    enablePermissionStrAndOp,
  };
};

function getCurrentYearAndSemester() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11

  let semester;

  if (currentMonth >= 1 && currentMonth <= 6) {
    semester = 2; // Primer semestre
  } else {
    semester = 1; // Segundo semestre
  }

  return `${semester} ${currentYear - 1}`;
}
