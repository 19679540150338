import React from 'react'
//assets
import DownloadEval from '../../../../assets/DownloadEval.svg'
import onOff from '../../../../assets/onOff.svg'
//styles 
import { MainContainer, Imagen, Text, InnerContainer } from './ButtonEvalDownloads.styles'

interface IbutttonDonwload  {
  onClick : any
  showModalDesactivar: boolean
  setShowModalDesactivar: React.Dispatch<React.SetStateAction<boolean>>
  demanda:any
  demandaColumn:any
}

export default function ButtonEvalDonwload({onClick, showModalDesactivar, setShowModalDesactivar, demanda, demandaColumn} : IbutttonDonwload) {
  return (
    <MainContainer>
         <InnerContainer onClick={onClick}>
        <Imagen src={DownloadEval} alt=''/>
        <Text>Descargar</Text>
    </InnerContainer>
{demandaColumn === 1 && 
    <InnerContainer onClick={()=>setShowModalDesactivar(!showModalDesactivar)}>
        <Imagen src={onOff} alt=''/>
        <Text>{demanda ? 'Activar' : 'Desactivar'} evaluación</Text>
    </InnerContainer>}
    
    </MainContainer>
  
  )
}

