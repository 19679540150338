import styled from "styled-components";

export const EvaluatedHeaderContainer = styled.div`
margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:24px

`;

export const HeaderTitle = styled.h4`
   font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 38.4px */
    margin: 0;
`;

export const HeaderTitleDos = styled.div`
   font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 38.4px */
    margin: 0;
    padding: 0px 0px 48px 0px;
`;
