import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AlertIconEval from "../../../assets/AlertIconEval.svg";
import "./loaderModal.css";
interface Labels {
  showData?: boolean | true;
}

export const ModalEvaluationInactive: FC<Labels> = ({ showData }) => {
  return (
    <ContainerModal>
      <>
        <Modal>
          <div className="textModal">
            <img className="imgModal" src={AlertIconEval} alt="alert" />
            Evaluación desactivada
          </div>
          <TextModal>
            La evaluación está desactivada actualmente, por lo que no se pueden
            realizar evaluaciones.
          </TextModal>
          <Link
            to="/Dashboard"
            style={{
              textDecoration: "none",
              background: "rgba(59, 99, 162, 1)",
              fontSize: "14px",
              fontWeight: "700",
              color: "white",
              padding: "10px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              borderRadius: "8px",
              marginTop: "20px",
              maxWidth: "250px",
            }}
          >
            Regresar al dashboard
          </Link>
        </Modal>
      </>
    </ContainerModal>
  );
};

export default ModalEvaluationInactive;

const ContainerModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 999;
  display: flex;
`;

export const Modal = styled.div`
  max-width: 600px;
  /* height: 400px; */
  border-radius: 8px;
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 32px;

  .imgModal {
    height: 20px;
    width: 20px;
    /* border: 1px solid red; */
  }
  .textModal {
    //styleName: Title;

    width: 100%;
    gap: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 1px solid red; */
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #3b63a2;
    margin: 0px;
    margin-bottom: 32px;
  }
`;

export const TextModal = styled.div`
  //styleName: Body Bold;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;

  //styleName: Body;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;
