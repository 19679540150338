/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  EvaluationNew,
  OpportunitiesEvaluationInterface,
  StrenghsEvaluationInterface,
} from "../../../Interface/ApiInterface";
import iconTwo from "../../../assets/evaluaition-icons/icon-two.svg";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  NewEvaluationContext,
  NewEvaluationContextType,
} from "../../../context/EvaluacionContext/NewEvaluationContext";
import { validateSemestre } from "../../../context/validacionesFiltros";
import { useUrlPermisos } from "../../../hooks/Evaluation";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import {
  EvaluatedComents,
  EvaluatorComents,
} from "../../atoms/EvaluationForms/EvaluationForms";
import EvaluationMetricas from "./EvaluationFormSection/EvaluationMetricas";
import { EvaluationFormContainer } from "./EvaluationFormStyles";
import {
  ContainerOportunidades,
  DivisorOpFt,
  EvaluatedOpFt,
  TitleRowOpFt,
} from "./EvaluationFortalezasOportunidades/FortalezasOportunidadesStyles";

const optionsInstructions = [
  { id: 20121, label: "1=Oportunidad °2;" },
  { id: 25121, label: "2=Oportunidad °1;" },
  { id: 21191, label: "3=Cumple 100%;" },
  { id: 28121, label: "4=Fortaleza °1;" },
  { id: 21831, label: "y 5=Fortaleza °2" },
];

const EvaluationForm = ({
  setShow,
  setAddTotal,
  addTotal,
  evaluation,
  setEvaluation,
  oportunidad,
  setOportunidad,
  fortalezas,
  setFortalezas,
  addTotalEvalaudo,
  setAddTotalEvaluado,
}: any) => {
  //** Objects Redux
  const { Evaluation, dataChanged } = useEvaluationRedux();
  const { evaluationAttribute } = useCollaboratorRedux();

  //** Custom Hooks
  const { employeeNumber } = useParams();
  const { urlPermisos } = useUrlPermisos();

  //** Hooks useStates
  const [evaluator, setEvaluator] = useState<EvaluationNew>(Evaluation);
  const [addHumanFactor, setAddHumanFactor] = useState(0);
  const [addHumanFactorEvaluador, setAddHumanFactorEvaluador] = useState(0);

  const [addSkills, setAddSkills] = useState(0);
  const [addSkillsEvaluado, setAddSkillsEvaluador] = useState(0);

  const [fortalezaChangeVal, setFortalezaChangeVal] = useState(0);
  const [addJobPerformance, setAddJobPerformance] = useState(0);
  const [addJobPerformanceEvaluado, setAddJobPerformanceEvaluado] = useState(0);
  const [Strenghs, setStrenghs] = useState<StrenghsEvaluationInterface[]>([]);
  const [Oportunity, setOportunity] = useState<
    OpportunitiesEvaluationInterface[]
  >([]);

  const {
    setValidationFortalezasSection,

    validationFortalezasSection,
    validateFortalezasDebilidades,
  } = useContext(NewEvaluationContext) as NewEvaluationContextType;

  const { periodoDataInfo } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;

  useEffect(() => {
    if (Evaluation) {
      setFortalezas(Evaluation.employeeEvaluationWorkersStrengths);
      setOportunidad(Evaluation.employeeEvaluationWorkersOpportunitieAreas);
    }
  }, []);

  useEffect(() => {
    // console.log("Se setea la evaluación", evaluation);

    if (Evaluation) {
      if (
        Evaluation.employeeEvaluationEvaluatedUserId !== undefined &&
        employeeNumber !== ""
      ) {
        const JobPerformance = Evaluation.employeEvaluationQuestions.filter(
          (item) => item.question.idCatQuestionNavigation.idCat === 1
        );
        const HumanFactor = Evaluation.employeEvaluationQuestions.filter(
          (item) => item.question.idCatQuestionNavigation.idCat === 2
        );
        const Skills = Evaluation.employeEvaluationQuestions.filter(
          (item) => item.question.idCatQuestionNavigation.idCat === 3
        );
        let evaluation = Evaluation;
        evaluation.jobPerformancesAnswere = JobPerformance;
        evaluation.humanFactorAnswere = HumanFactor;
        evaluation.skillsAnswere = Skills;

        setEvaluation(evaluation);
      }
      setFortalezas(Evaluation.employeeEvaluationWorkersStrengths);
      setOportunidad(Evaluation.employeeEvaluationWorkersOpportunitieAreas);
    }
  }, [Evaluation]);

  useEffect(() => {
    setEvaluation({
      ...evaluation,
      additionJobPerformance: addJobPerformance,
      additionHumanFactor: addHumanFactor,
      additionSkill: addSkills,
    });

    setAddTotal(addHumanFactor + addSkills + addJobPerformance);
  }, [addHumanFactor, addSkills, addJobPerformance]);

  useEffect(() => {
    setAddTotalEvaluado(
      addSkillsEvaluado + addJobPerformanceEvaluado + addHumanFactorEvaluador
    );
  }, [addSkillsEvaluado, addJobPerformanceEvaluado, addHumanFactorEvaluador]);

  useEffect(() => {
    const JobPerformanceOportunity = evaluation?.jobPerformancesAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion < 3
    );
    const HumanFactorOportunity = evaluation?.humanFactorAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion < 3
    );
    const SkillsOportunity = evaluation?.skillsAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion < 3
    );
    const Jobs = JobPerformanceOportunity?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersOpportunitieId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersOpportunitieAreaText:
          item?.question.textQuestion,
      };
    });

    const HumanFactor = HumanFactorOportunity?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersOpportunitieId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersOpportunitieAreaText:
          item?.question.textQuestion,
      };
    });

    const Skill = SkillsOportunity?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersOpportunitieId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersOpportunitieAreaText:
          item?.question.textQuestion,
      };
    });
    const array = Jobs?.concat(HumanFactor);
    const aux = array?.concat(Skill);
    setOportunity(aux);

    const JobPerformanceStrenghs = evaluation?.jobPerformancesAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion > 3
    );
    const HumanFactorStrenghs = evaluation?.humanFactorAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion > 3
    );
    const SkillsStrenghs = evaluation?.skillsAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion > 3
    );

    const JobsStrenghs = JobPerformanceStrenghs?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersStrengthsId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersStrengthsQuestionText:
          item?.question?.textQuestion,
      };
    });

    const HumanFactorStre = HumanFactorStrenghs?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersStrengthsId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersStrengthsQuestionText:
          item?.question?.textQuestion,
      };
    });

    const SkillStre = SkillsStrenghs?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersStrengthsId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersStrengthsQuestionText:
          item?.question.textQuestion,
      };
    });

    const arrayStre = JobsStrenghs?.concat(HumanFactorStre);
    const auxStre = arrayStre?.concat(SkillStre);
    setStrenghs(auxStre);
  }, [Evaluation]);

  useEffect(() => {
    const JobPerformanceOportunity = evaluation?.jobPerformancesAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion < 3
    );
    const HumanFactorOportunity = evaluation?.humanFactorAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion < 3
    );
    const SkillsOportunity = evaluation?.skillsAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion < 3
    );
    const Jobs = JobPerformanceOportunity?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersOpportunitieId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersOpportunitieAreaText:
          item?.question.textQuestion,
      };
    });

    const HumanFactor = HumanFactorOportunity?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersOpportunitieId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersOpportunitieAreaText:
          item?.question.textQuestion,
      };
    });

    const Skill = SkillsOportunity?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersOpportunitieId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersOpportunitieAreaText:
          item?.question.textQuestion,
      };
    });
    const array = Jobs?.concat(HumanFactor);
    const aux = array?.concat(Skill);
    setOportunity(aux);

    const JobPerformanceStrenghs = evaluation?.jobPerformancesAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion > 3
    );
    const HumanFactorStrenghs = evaluation?.humanFactorAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion > 3
    );
    const SkillsStrenghs = evaluation.skillsAnswere?.filter(
      (item: any) => item?.evaluatorEvaluationResponseQuestion > 3
    );

    const JobsStrenghs = JobPerformanceStrenghs?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersStrengthsId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersStrengthsQuestionText:
          item?.question.textQuestion,
      };
    });

    const HumanFactorStre = HumanFactorStrenghs?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersStrengthsId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersStrengthsQuestionText:
          item?.question.textQuestion,
      };
    });

    const SkillStre = SkillsStrenghs?.map((item: any) => {
      return {
        employeeEvaluationId: evaluation?.employeeEvaluationId,
        employeeEvaluationWorkersStrengthsId:
          item?.employeeEvaluationQuestionsId,
        employeeEvaluationWorkersStrengthsQuestionText:
          item?.question.textQuestion,
      };
    });

    const arrayStre = JobsStrenghs?.concat(HumanFactorStre);
    const auxStre = arrayStre?.concat(SkillStre);
    setStrenghs(auxStre);
  }, [
    evaluation?.additionHumanFactor,
    evaluation?.additionSkill,
    evaluation?.additionJobPerformance,
  ]);

  //** handlers

  const handleChangeFortalezaAreas = (e: any, type: any, index: number) => {
    if (type === "fortaleza") {
      const fortalezaFind = Strenghs.find(
        (item) =>
          item?.employeeEvaluationWorkersStrengthsId === Number(e.target.value)
      );

      if (fortalezaFind !== undefined) {
        fortalezas[index] = fortalezaFind;

        //! NO CAMBIAR, SI SE CAMBIA CONSULTAR!!!!!!!!
        setFortalezaChangeVal(fortalezaChangeVal + 1);
        setFortalezas(fortalezas);
      } else {
        if (fortalezas[index]) {
          fortalezas[index].employeeEvaluationWorkersStrengthsQuestionText =
            e.target.value;
        } else {
          fortalezas[index] = {
            employeeEvaluationWorkersStrengthsQuestionText: e.target.value,
            employeeEvaluationId: Evaluation.employeeEvaluationId,
          };
        }

        //! NO CAMBIAR, SI SE CAMBIA CONSULTAR!!!!!!!!
        setFortalezaChangeVal(fortalezaChangeVal + 1);

        setFortalezas(fortalezas);
      }
    }

    if (type === "Oportunidad") {
      const oportunidadFind = Oportunity.find(
        (item) =>
          item?.employeeEvaluationWorkersOpportunitieId ===
          Number(e.target.value)
      );
      if (oportunidadFind !== undefined) {
        oportunidad[index] = oportunidadFind;

        //! NO CAMBIAR, SI SE CAMBIA CONSULTAR !!!!!!!!!
        setFortalezaChangeVal(fortalezaChangeVal + 1);
        setOportunidad(oportunidad);
      } else {
        if (oportunidad[index]) {
          oportunidad[index].employeeEvaluationWorkersOpportunitieAreaText =
            e.target.value;
        } else {
          oportunidad[index] = {
            employeeEvaluationWorkersOpportunitieAreaText: e.target.value,
            employeeEvaluationId: Evaluation.employeeEvaluationId,
          };
        }

        //! NO CAMBIAR, SI SE CAMBIA CONSULTAR !!!!!!!!!
        setFortalezaChangeVal(fortalezaChangeVal + 1);
        setOportunidad(oportunidad);
      }
    }
    ableStrengthsAndWeaknesses();
  };

  //* Con esta validación se verifica que el usuario haya rellenado sus tres campos de fortalezas y debilidades
  const ableStrengthsAndWeaknesses = () => {
    if (oportunidad && fortalezas) {
      const weaknessesValidation = oportunidad?.map(
        (oportunity: any) =>
          oportunity.employeeEvaluationWorkersOpportunitieAreaText
      );
      const strengthsValidation = fortalezas?.map(
        (oportunity: any) =>
          oportunity.employeeEvaluationWorkersStrengthsQuestionText
      );

      const allData = [...weaknessesValidation, ...strengthsValidation];

      if (
        allData.length === 0 ||
        allData.some(
          (elemento) =>
            elemento.trim("") === "" ||
            allData.some((elemento) => elemento === "Selecciona una opcion")
        )
      ) {
        // console.log("OpAndStr -> Todavía tiene datos!!", allData);
        setValidationFortalezasSection(true);
      } else {
        // console.log("OpAndStr -> Ya no tiene", allData);
        setValidationFortalezasSection(false);
      }
    }
  };
 
  useEffect(() => {
    ableStrengthsAndWeaknesses();
  }, [oportunidad, fortalezas]);


  const periodLocal = localStorage.getItem("periodId");
  const periodParsed = JSON.parse(periodLocal ?? "{}");
  const { valuePeriod } = periodParsed;
  const principalFilters = localStorage.getItem("principalFilters");
  const filtersParsed = JSON.parse(principalFilters ?? "{}");
  const { year } = filtersParsed;
  const semestre = validateSemestre(valuePeriod);

  return (
    <>
      <EvaluationFormContainer>
        <>
          <EvaluationMetricas
            optionsInstructions={optionsInstructions}
            setEvaluator={setEvaluator}
            evaluator={evaluator}
            evaluation={evaluation}
            addJobPerformance={addJobPerformance}
            setAddJobPerformance={setAddJobPerformance}
            setEvaluation={setEvaluation}
            addJobPerformanceEvaluado={addJobPerformanceEvaluado}
            setAddJobPerformanceEvaluado={setAddJobPerformanceEvaluado}
            addTotal={addTotal}
            addTotalEvalaudo={addTotalEvalaudo}
            addHumanFactor={addHumanFactor}
            setAddHumanFactor={setAddHumanFactor}
            addHumanFactorEvaluador={addHumanFactorEvaluador}
            setAddHumanFactorEvaluador={setAddHumanFactorEvaluador}
            addSkills={addSkills}
            setAddSkills={setAddSkills}
            addSkillsEvaluado={addSkillsEvaluado}
            setAddSkillsEvaluador={setAddSkillsEvaluador}
          />
        </>
        <ContainerOportunidades>
          <TitleRowOpFt>
            <picture>
              <img src={iconTwo} alt="velocity" />
            </picture>
            <div className="containerTxtOpFt">
              <div className="titleOpFt">Fortalezas y áreas de oportunidad</div>
              <div className="subtitleOpFt">
                Escribe 3 fortalezas a trabajar en este período
              </div>
            </div>
            {urlPermisos && (
              <div className="containerDate">{periodoDataInfo}</div>
            )}
            {!urlPermisos && (
              <div className="containerDate">{`Semestre ${semestre} / ${year} `}</div>
            )}
          </TitleRowOpFt>
          <EvaluatedOpFt>
            <DivisorOpFt>
              <EvaluatorComents
                title="Tres fortalezas del trabajador en este período"
                Strenghs={Strenghs}
                handleChange={handleChangeFortalezaAreas}
                strenghsSelect={fortalezas}
              />
            </DivisorOpFt>
            <DivisorOpFt>
              <EvaluatedComents
                title="Tres áreas de oportunidad del trabajador en este período"
                Opportunities={Oportunity}
                oportunitySelect={oportunidad}
                handleChange={handleChangeFortalezaAreas}
              />
            </DivisorOpFt>
          </EvaluatedOpFt>
        </ContainerOportunidades>
      </EvaluationFormContainer>
    </>
  );
};

export default EvaluationForm;
