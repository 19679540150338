import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CollaboratorInterface,
  CollaboratorUserInterface,
  IFilterCollaborators,
  IMeta,
  ListCollaborator,
} from "../Interface/ApiInterface";
import { actionEvent } from "../common/utils";
import {
  deleteCollaboratorServices,
  getCollaboratorServices,
  getCollaboratorUserServices,
  getCollaboratorsServices,
  getCredentials,
  postCollaboratorServices,
  putCollaboratorServices,
} from "../services/collaborator.service";
import { RootReducerInterface } from "./store";
import { useLoader } from "../context/LoaderContext";
/**
 * Action Types
 */
export enum CollaboratorActions {
  GET_COLLABORATORS_SUCCESS_USER = "GET_COLLABORATORS_SUCCESS_USER",
  GET_COLLABORATORS_ERROR_USER = "GET_COLLABORATORS_ERROR_USER",
  GET_COLLABORATORS_SUCCESS = "GET_COLLABORATORS_SUCCESS",
  GET_COLLABORATORS_ERROR = "GET_COLLABORATORS_ERROR",
  GET_COLLABORATORS_ERROR_LOADING = "GET_COLLABORATORS_ERROR_LOADING",
  POST_COLLABORATORS_SUCCESS = "POST_COLLABORATORS_SUCCESS",
  POST_COLLABORATORS_ERROR = "POST_COLLABORATORS_ERROR",
  PUT_COLLABORATORS_SUCCESS = "PUT_COLLABORATORS_SUCCESS",
  PUT_COLLABORATORS_ERROR = "PUT_COLLABORATORS_ERROR",
  DELETE_COLLABORATORS_SUCCESS = "DELETE_COLLABORATORS_SUCCESS",
  DELETE_COLLABORATORS_ERROR = "DELETE_COLLABORATORS_ERROR",
  SET_FILTERS_COLLABORATORS = "SET_FILTERS_COLLABORATORS",
  GET_COLLABORATOR_SUCCESS = "GET_COLLABORATOR_SUCCESS",
  GET_CREDENTIALS = "GET_CREDENTIALS",
  SET_PREV_USER = "SET_PREV_USER",
  GET_COLLABORATORS_TABLE_SUCCESS = "GET_COLLABORATORS_TABLE_SUCCESS",
}

/**
 * Interfaces
 */
interface ICredentials {
  Nombre: String;
  Apellido: String;
  Usuario: Number;
  Mail: String;
}
interface CollaboratorBaseInterface {
  User: CollaboratorUserInterface;
  UserPrev: CollaboratorUserInterface;
  otp: string;
  Antiquity: number;
  Collaborator: CollaboratorInterface;
  Collaborators: ListCollaborator[];
  loading: boolean;
  Meta: IMeta;
  Filters: IFilterCollaborators;
  Credentials: ICredentials;
  setSuccesPost: (succes: boolean) => void;
  setLoading: (succes: boolean) => void;
  succesPost: boolean;
  errorloading: any;
  setErrorLoading: (succes: any) => void;
  setOtpLoader: (succes: boolean) => void;
  otpLoader: boolean;
}

interface UseCollaboratorInterface extends CollaboratorBaseInterface {
  handleGetCollaboratorUser: (user: CollaboratorUserInterface) => void;
  handleGetCollaboratorUserPrev: (user: any, validation: boolean) => void;
  handleGetCollaborator: (id: number, period?: number | string) => void;
  handleGetCollaborators: (query: string | undefined) => void;
  handlePostCollaborators: (body: CollaboratorInterface) => void;
  handleUpdateCollaborators: (id: number, body: CollaboratorInterface) => void;
  handleDeleteCollaborators: (id: number) => void;
  handleSetFilterCollaborators: (filters: IFilterCollaborators) => void;
  handleGetCredentials: (url: string) => void;
  evaluationAttribute: Record<string, number>;
}

interface ReducerActionsInterface {
  type: CollaboratorActions;
  payload: CollaboratorBaseInterface;
}

/**
 * Hooks
 */
const useCollaboratorRedux = (): UseCollaboratorInterface => {
  const [loading, setLoading] = useState<boolean>(true);
  const [succesPost, setSuccesPost] = useState(false);
  const [evaluationAttribute, setEvaluationAttribute] = useState<
    Record<string, number>
  >({});
  const [errorloading, setErrorLoading] = useState<any>(null);
  const [otpLoader, setOtpLoader] = useState(false);
  const dispatch = useDispatch();
  const {
    Collaborators,
    Collaborator,
    Antiquity,
    User,
    UserPrev,
    Meta,
    otp,
    Filters,
    Credentials,
  } = useSelector((state: RootReducerInterface) => state.Collaborators);
  const { incrementRequests, decrementRequests } = useLoader();

  useEffect(() => {
    if (User && User.attributeSections) {
      const newObject: Record<string, number> = {};
      User.attributeSections.forEach((item) => {
        newObject[item.nameComponent] = item.value;
      });
      setEvaluationAttribute(newObject);
    }
  }, [User]);

  const handleGetCollaboratorUserPrev = async (
    user: ICredentials,
    validation: boolean
  ): Promise<void> => {
    try {
      // console.log("Info que manda la url: ", user);
      incrementRequests();
      setOtpLoader(true);
      const { data } = await getCollaboratorUserServices(user);
      if (validation) {
        // setLoading(true);
        setOtpLoader(false);
        setSuccesPost(true);
        // console.log("!!!!!Usa este codigo de comfirmacion :", data?.otp);
      }
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.SET_PREV_USER,
          payload: {
            Collaborators,
            User,
            Antiquity,
            Collaborator,
            loading,
            Meta,
            Filters,
            Credentials,
            UserPrev: data,
            otp: data?.otp,
            succesPost,
            setSuccesPost,
            setLoading,
            setOtpLoader,
            otpLoader: false,
            errorloading,
            setErrorLoading,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATORS_SUCCESS_USER,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleGetCollaboratorUser = async (
    user: CollaboratorUserInterface
  ): Promise<void> => {
    try {
      // setLoading(true);
      // localStorage.setItem('token', data.token);
      // setLoading(false);
      incrementRequests();
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATORS_SUCCESS_USER,
          payload: {
            Collaborators,
            User: user,
            Antiquity,
            Collaborator,
            loading,
            Meta,
            Filters,
            Credentials,
            UserPrev,
            otp,
            succesPost,
            setSuccesPost,
            setLoading,
            setOtpLoader,
            otpLoader,
            errorloading,
            setErrorLoading,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATORS_SUCCESS_USER,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleGetCredentials = async (url: string): Promise<void> => {
    try {
      // setLoading(true);
      incrementRequests();
      const { data } = await getCredentials(url);

      // setLoading(false);
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_CREDENTIALS,
          payload: {
            Collaborators,
            User,
            Antiquity,
            Collaborator,
            loading,
            Meta,
            Filters,
            Credentials: data,
            UserPrev,
            otp,
            succesPost,
            setSuccesPost,
            setLoading,
            setOtpLoader,
            otpLoader,
            errorloading,
            setErrorLoading,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATORS_SUCCESS_USER,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleGetCollaborator = async (
    id: number,
    period: number | string = ""
  ): Promise<void> => {
    try {
      // setLoading(true);
      incrementRequests();
      const { data, meta } = await getCollaboratorServices(id, period);
      // setLoading(false);
      // console.log("aquí cambia esto", data, meta);
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATOR_SUCCESS,
          payload: {
            Collaborators,
            User,
            Antiquity,
            Collaborator: data,
            loading,
            Meta,
            Filters,
            Credentials,
            UserPrev,
            otp,
            succesPost,
            setSuccesPost,
            setOtpLoader,
            otpLoader,
            setLoading,
            errorloading,
            setErrorLoading,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATORS_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleGetCollaborators = async (
    query: string | undefined
  ): Promise<void> => {
    try {
      // setLoading(true);
      // console.log("Periodo a mandar ->",query);
      console.log('Incrementing requests');
      incrementRequests();

      console.log('Fetching collaborators');
      const { data, meta } = await getCollaboratorsServices(query);
      console.log('Collaborators fetched:', data, meta);
      // console.log("<<< trae los datos", data, meta);

      // setLoading(false);

      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATORS_TABLE_SUCCESS,
          payload: {
            Collaborators: data,
            User,
            Antiquity,
            Collaborator,
            loading,
            Meta: meta,
            Filters,
            Credentials,
            UserPrev,
            otp,
            succesPost,
            setSuccesPost,
            setLoading,
            setOtpLoader,
            otpLoader,
            errorloading: 200,
            setErrorLoading,
          },
        })
      );
    } catch (e: any) {
      console.log('Error fetching collaborators:', e);
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATORS_ERROR_LOADING,
          payload: {
            Collaborators,
            User,
            Antiquity,
            Collaborator,
            loading,
            Meta,
            Filters,
            Credentials,
            UserPrev,
            otp,
            succesPost,
            setSuccesPost,
            setOtpLoader,
            otpLoader,
            setLoading,
            errorloading: e.response.status,
            setErrorLoading,
          },
        })
      );
      setErrorLoading(e.response.status);
      // console.log("e", e.response);
    } finally {
      console.log('Decrementing requests');
      decrementRequests();
    }
  };

  const handlePostCollaborators = async (
    body: CollaboratorInterface
  ): Promise<void> => {
    try {
      // setLoading(true);
      incrementRequests();
      await postCollaboratorServices(body);
      // setLoading(false);
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.POST_COLLABORATORS_SUCCESS,
          payload: {
            Collaborators: Collaborators,
            User: User,
            Antiquity,
            Collaborator,
            loading,
            Meta,
            Filters,
            Credentials,
            UserPrev,
            otp,
            succesPost,
            setSuccesPost,
            setLoading,
            setOtpLoader,
            otpLoader,
            errorloading,
            setErrorLoading,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.POST_COLLABORATORS_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleUpdateCollaborators = async (
    id: number,
    body: CollaboratorInterface
  ): Promise<void> => {
    try {
      // setLoading(true);
      incrementRequests();
      await putCollaboratorServices(id, body);
      // setLoading(false);

      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.PUT_COLLABORATORS_SUCCESS,
          payload: {
            Collaborators: Collaborators,
            User: User,
            Antiquity,
            Collaborator,
            loading,
            Meta,
            Filters,
            Credentials,
            UserPrev,
            otp,
            succesPost,
            setSuccesPost,
            setOtpLoader,
            otpLoader,
            setLoading,
            errorloading,
            setErrorLoading,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.PUT_COLLABORATORS_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleDeleteCollaborators = async (id: number): Promise<void> => {
    try {
      // setLoading(true);
      incrementRequests();
      await deleteCollaboratorServices(id);
      // setLoading(false);

      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.DELETE_COLLABORATORS_SUCCESS,
          payload: {
            Collaborators: Collaborators,
            User: User,
            Antiquity,
            Collaborator,
            loading,
            Meta,
            Filters,
            otp,
            Credentials,
            UserPrev,
            succesPost,
            setOtpLoader,
            otpLoader,
            setSuccesPost,
            setLoading,
            errorloading,
            setErrorLoading,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.DELETE_COLLABORATORS_ERROR,
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleSetFilterCollaborators = async (
    filters: IFilterCollaborators
  ) => {
    try {
      incrementRequests();
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.SET_FILTERS_COLLABORATORS,
          payload: {
            Collaborators,
            User,
            Antiquity,
            Collaborator,
            loading,
            Meta,
            Filters: filters,
            Credentials,
            UserPrev,
            otp,
            succesPost,
            setSuccesPost,
            setLoading,
            errorloading,
            setOtpLoader,
            otpLoader,
            setErrorLoading,
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<CollaboratorActions, CollaboratorBaseInterface>({
          type: CollaboratorActions.GET_COLLABORATORS_ERROR,
        })
      );
      // console.log("Error acá", e);
    } finally {
      decrementRequests();
    }
  };

  return {
    Meta,
    loading,
    Collaborator,
    Collaborators,
    User,
    UserPrev,
    otp,
    Antiquity,
    Filters,
    succesPost,
    setSuccesPost,
    Credentials,
    handleGetCredentials,
    handleSetFilterCollaborators,
    handleGetCollaborator,
    handlePostCollaborators,
    handleGetCollaborators,
    handleUpdateCollaborators,
    handleDeleteCollaborators,
    handleGetCollaboratorUser,
    handleGetCollaboratorUserPrev,
    evaluationAttribute,
    setLoading,
    errorloading,
    setOtpLoader,
    otpLoader,
    setErrorLoading,
  };
};

/**
 * Reducers
 */
const initialState: CollaboratorBaseInterface = {
  Antiquity: 0,
  Collaborator: {} as CollaboratorInterface,
  User: {} as CollaboratorUserInterface,
  loading: false,
  Collaborators: [] as ListCollaborator[],
  Meta: {} as IMeta,
  Filters: {} as IFilterCollaborators,
  Credentials: {} as ICredentials,
  UserPrev: {} as CollaboratorUserInterface,
  otp: "",
  errorloading: 0,
  succesPost: false,
  setErrorLoading: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
  setSuccesPost: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
  setLoading: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
  setOtpLoader: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
  otpLoader: false,
};

const CollaboratorReducer = (
  state = initialState,
  action: ReducerActionsInterface
): CollaboratorBaseInterface => {
  switch (action.type) {
    case CollaboratorActions.GET_COLLABORATORS_TABLE_SUCCESS:
      return {
        ...state,
        Collaborators: action.payload.Collaborators,
        loading: action.payload.loading,
        Meta: action.payload.Meta,
      };
    case CollaboratorActions.GET_COLLABORATORS_ERROR_LOADING:
      return {
        ...state,
        errorloading: action.payload.errorloading,
      };
    // case CollaboratorActions.GET_COLLABORATORS_ERROR:
    //   return {
    //     ...state,

    //   };
    case CollaboratorActions.GET_COLLABORATOR_SUCCESS:
      return {
        ...state,
        Collaborator: action.payload.Collaborator,
        loading: action.payload.loading,
        Meta: action.payload.Meta,
      };
    case CollaboratorActions.GET_COLLABORATORS_SUCCESS_USER:
      return {
        ...state,
        User: action.payload.User,
        loading: action.payload.loading,
      };
    case CollaboratorActions.SET_FILTERS_COLLABORATORS:
      return {
        ...state,
        Filters: action.payload.Filters,
      };
    case CollaboratorActions.GET_CREDENTIALS:
      return {
        ...state,
        Credentials: action.payload.Credentials,
      };
    case CollaboratorActions.SET_PREV_USER:
      return {
        ...state,
        UserPrev: action.payload.UserPrev,
        otp: action.payload.otp,
      };
    default:
      return state;
  }
};

/**
 * Exports
 */
export { CollaboratorReducer, useCollaboratorRedux };
export type { CollaboratorBaseInterface, UseCollaboratorInterface };

