// @ts-nocheck

export const getClassNameBasedOnPercentage = (percentage: any) => {
  if (percentage <= 79) {
    return "bajo";
  } else if (percentage <= 99) {
    return "regular";
  } else if (percentage <= 119) {
    return "bueno";
  } else {
    return "ecxelente";
  }
};

export const suma = (array: number[]) => {
  let suma = 0;
  for (let i = 0; i < array?.length; i++) {
    suma += array[i];
  }
  return suma;
};

export const reglasKPIs = (
  valor: number,
  porcentaje: any,
  objType: string,
  real: any
) => {
  let newNumChanged = porcentaje;

  if (typeof porcentaje === "string") {
    if (porcentaje.split("").includes("%")) {
      newNumChanged = porcentaje
        .split("")
        .filter((letra: string) => letra !== "%")
        .join("");
    } else {
      newNumChanged = porcentaje;
    }
  }

  //!! Ojo aquí
  if (porcentaje === null) {
    return 0;
  }

  const rules: { [key: string]: (v: number, p: number) => number } = {
    G: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    I: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    D: (v, p) => (v * p) / 100,
  };

  const ruleFunction = rules[objType];

  if (real === null) {
    return 0;
  }
  if (real === 0 && objType !== "D") {
    return 0;
  } else {
    const result = ruleFunction ? ruleFunction(valor, newNumChanged) : 0;

    return Math.round(result);
  }
};

export const reglasKPIsDefault = (
  valor: number,
  porcentaje: any,
  objType: string,
  real: any
) => {
  // console.log(" valor: -> ", valor, "porcentaje ->", porcentaje,"real ->", real, "tipo ->",objType)

  let newNumChanged = porcentaje;

  if (typeof porcentaje === "string") {
    if (porcentaje.split("").includes("%")) {
      newNumChanged = porcentaje
        .split("")
        .filter((letra: string) => letra !== "%")
        .join("");
    } else {
      newNumChanged = porcentaje;
    }
  }

  //!! Ojo aquí
  if (porcentaje === null) {
    return 0;
  }

  const rules: { [key: string]: (v: number, p: number) => number } = {
    G: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    I: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    D: (v, p) => (v * p) / 100,
  };

  const ruleFunction = rules[objType];

  if (real === null && real === undefined) {
    return 0;
  }
  if (real === 0 && objType !== "D") {
    return 0;
  } else {
    const result = ruleFunction ? ruleFunction(valor, newNumChanged) : 0;

    return Math.round(result);
  }
};

export const porcentajeDeAlcanceGenerales = (meta: any, real: any) => {
  // console.log("Entra Real!!!! - ", real, meta );

  if (real === "") {
    return null;
  }

  if (meta !== 0 || real !== 0) {
    let porcentaje = (real * 100) / meta;
    const res = porcentaje;

    if (res === Infinity) {
      return 0;
    }
    if (!Number.isNaN(res)) {
      return res.toFixed(0);
    }
  }
  return 0;
};

export const regexValidateNumber = /^-?[0-9]/;

export const reglasKPIsD = (
  valor: number,
  meta: any,
  objType: string,
  real: any
) => {
  let porcentaje = (real * 100) / meta;

  // console.log("Valor", porcentaje.toFixed(0), real, meta);

  let newNumChanged = porcentaje;

  const rules: { [key: string]: (v: number, p: number) => number } = {
    G: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    I: (v, p) => {
      if (p <= 79) return 0;
      if (p <= 99) return 0.8 * v;
      if (p <= 119) return 1 * v;
      return 1.2 * v;
    },
    D: (v, p) => (v * p) / 100,
  };

  const ruleFunction = rules[objType];

  if (real === null) {
    return 0;
  }
  if (real === 0 && objType !== "D") {
    return 0;
  } else {
    const result = ruleFunction ? ruleFunction(valor, newNumChanged) : 0;

    return Math.round(result);
  }
};

export const porcentajeAlcanceDefault = (
  type: string,
  value: any,
  meta?: any
) => {
  if (type === "Empresa") {
    return value * 0.24 * 100;
  }

  if (type === "Desempenio") {
    if (value <= 39) return 0;
    if (value <= 59) return 80;
    if (value <= 79) return 100;
    if (value > 79) return 120;
  }

  if (type === "Generales") {
    return (value * 100) / meta;
  }
};
