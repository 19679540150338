export const tableOneHeadings = [
  "Sem1.2022",
  "Valor",
  "Descripción de objetivos",
  "Meta",
  "Real",
  "% Alcance",
  "Resultado",
];

export const tableOneData = [
  {
    week: "1",
    value: "15%",
    objetiveDesc: "Alcance(EBITDA / Costo",
    meta: "100",
    real: "Online",
    scope: "0%",
    result: "0%",
  },
  {
    week: "Online",
    value: "Online",
    objetiveDesc: "Online",
    meta: "100",
    real: "Online",
    scope: "0%",
    result: "0%",
  },
  {
    week: "TBD",
    value: "TBD",
    objetiveDesc: "TBD",
    meta: "TBD",
    real: "TBD",
    scope: "TBD",
    result: "TBD",
  },
  {
    week: "TBD",
    value: "TBD",
    objetiveDesc: "TBD",
    meta: "TBD",
    real: "TBD",
    scope: "TBD",
    result: "TBD",
  },
  {
    week: "TBD",
    value: "TBD",
    objetiveDesc: "TBD",
    meta: "TBD",
    real: "TBD",
    scope: "TBD",
    result: "TBD",
  },
];

export const tableTwoHeadings = [
  "Sem1.2022",
  "Valor",
  "Descripción de objetivos",
  "Meta",
];

export const tableTwoData = [
  {
    week: "1",
    value: "15%",
    objetiveDesc: "Alcance(EBITDA / Costo",
    meta: "100",
  },
  {
    week: "Online",
    value: "Online",
    objetiveDesc: "Online",
    meta: "100",
  },
  {
    week: "TBD",
    value: "TBD",
    objetiveDesc: "TBD",
    meta: "TBD",
  },
  {
    week: "TBD",
    value: "TBD",
    objetiveDesc: "TBD",
    meta: "TBD",
  },
  {
    week: "TBD",
    value: "TBD",
    objetiveDesc: "TBD",
    meta: "TBD",
  },
];

export const scopeTableHeading = ["Alcance", ""];

export const scopeTableData = [
  {
    id: 123,
    scope: "< 80%",
    scopeDesc:
      "Objetivo no alcanzado, el cumplimiento quedó por debajo del 80%.",
    value: "#F20505",
  },
  { 
    id: 234,
    scope: "80% - 99%",
    scopeDesc:
      "Refleja un cumplimiento básico en su área de trabajo. La persona demuestra capacidad para lograr la mayor parte de las tareas, pero necesita más empuje para la conclusión de su objetivo.",
    value: "#F27405",
  },
  {
    id: 345,
    scope: "100% - 119%",
    scopeDesc:
      "El desempeño cumple con las exigencias principales del puesto. Refleja un desempeño riguroso, tiene conocimientos, formación y experiencia apropiada para el puesto. Lleva a cabo su tarea de forma eficaz y profesional.",
    value: "#F27405",
  },
  {
    id: 456,
    scope: ">= 120%",
    scopeDesc:
      "Los resultados superan lo esperado. Demuestra logros extraordinarios en todas las manifestaciones de su trabajo, su desempeño es raramente igualado por otras personas que ocupan puestos similares.",
    value: "#10B102",
  },
];
