import styled from "styled-components";

export const ComentariosTitleBox = styled.div`
  margin: 0 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* gap: 16px; */
  font-family: Plus Jakarta Sans;
  position: relative;
  .containerDate {
    position: absolute;
    right: 0px;
    font-size: 12px;
    font-family: "Plus Jakarta Sans";
    background-color: #F0F0F0;
    border-radius: 16px;
    padding:  7px 10px; 
  }
  .descriptionComentarios {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #656569;
  }

  .titleComentarios {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 38.4px */
    margin: none;
  }

  @media screen and (max-width: 768px) {
    .titleComentarios {
      font-size: 20px;
    }
  }
`;

export const CommentCardComentariosSection = styled.div`
  padding: 24px;
  gap: 16px;
  width: 100;
  background: #f8f8f8;
  border-radius: 8px;

  @media screen and (min-width: 800px) {
    width: 50%;
  }
`;

export const CommentsRowComentariosSection = styled.div`
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* gap: 2.5rem; */
  /* border: 1px solid red; */
`;

export const KpisTextContainerComentariosSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;

  @media screen and (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
  }
`;
export const WrapperComentariosSection = styled.div`
  background-color: white;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 32px 24px;
`;

export const WrapperTitleComentarios = styled.div`
  display: flex;
  align-items: start;
  padding: 0;
  margin-bottom: 24px;
 position: relative;
  .containerDate {
    position: absolute;
    right: 0px;
    font-size: 12px;
    font-weight: 400;
    font-family: "Plus Jakarta Sans";
    background-color: #F0F0F0;
    border-radius: 16px;
    padding:  7px 10px; 
  }
  @media screen and (max-width: 768px) {
    padding: 0 28px;
  }
`;
export const CommentCardTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #3B63A2;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f1f1f1;
`;
