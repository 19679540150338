import styled from "styled-components";

export const ContainerOportunidades = styled.section`
  display: flex;
  width: 100%;
  background-color: white;
  flex-direction: column;
  padding: 32px 24px;
`;

export const TitleRowOpFt = styled.div`
  display: flex;
  /* padding: 0 28px; */
  /* border: 1px solid red; */
  gap: 8px;

  position: relative;
  .containerDate {
    position: absolute;
    right: 20px;
    font-size: 12px;
    font-family: "Plus Jakarta Sans";
    background-color: #F0F0F0;
    border-radius: 16px;
    padding:  7px 10px; 
  }
  .containerTxtOpFt {
    display: flex;
    flex-direction: column;
    /* gap: 12px; */
  }
  .titleOpFt {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 38.4px */
    margin: none;
    margin-right: 4px;
  }

  .subtitleOpFt {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #656569;
    line-height: 120%;
  }

  @media screen and (min-width: 768px) {
    padding: 0;
  }
`;
export const EvaluatedOpFt = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (min-width: 1024px) {
    width: 99%;
    gap: 2rem;
    display: flex;

    justify-content: space-around;
  }
`;
export const DivisorOpFt = styled.div`
  padding: 0px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  @media (min-width: 320px) and (max-width: 1026px) {
    border-right: none;
    padding: 0px;
  }
`;

// !
