import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { CollaboratorReducer } from "./Collaborators";
import { EvaluationReducer } from "./Evaluations";
import { HumanFactorReducer } from "./HumanFactors";
import { JobPerformanceReducer } from "./JobPerformances";
import { ObjetivesReducer } from "./Objetives";
import { PeriodsReducer } from "./Periods";
import { PoliticsReducer } from "./Politics";
import { QuestionReducer } from "./Questionnaires";
import { SkillReducer } from "./Skills";

const saveToLocalStorage = (state: RootReducerInterface): void => {
  try {
    // console.log(
    //   "LDBCOLLADO",
    //   Object.keys(state.Collaborators.Collaborator).length,
    //   state.Collaborators.Collaborator
    // );
    
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("LDBCOLLADO", serializedState);
  } catch (e) {
    console.warn(e);
  }
};

const loadLocalStorageData = (): RootReducerInterface | undefined => {
  try {
    const serializedState = sessionStorage.getItem("LDBCOLLADO");

    if (serializedState === null) return undefined;
    // console.log("LDBCOLLADO", JSON.parse(serializedState));
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

const rootReducer = combineReducers({
  // reducers
  Collaborators: CollaboratorReducer,
  HumanFactors: HumanFactorReducer,
  Skills: SkillReducer,
  Evaluations: EvaluationReducer,
  JobPerformances: JobPerformanceReducer,
  Objetives: ObjetivesReducer,
  Periods: PeriodsReducer,
  Questions: QuestionReducer,
  Politics: PoliticsReducer,
});

type RootReducerInterface = ReturnType<typeof rootReducer>;

const middleware = [thunk];
const composeEnhancers = composeWithDevTools({
  trace: true,
});

const store = createStore(
  rootReducer,
  loadLocalStorageData(),
  composeEnhancers(applyMiddleware(...middleware))
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export { rootReducer, store };
export type { RootReducerInterface };

