import styled from "styled-components";

export const SideBarStyles = styled.aside`
  /* display: block; */
  /* width: 16rem; */
  height: 100%;
  padding: 24px;
  /* border-top-right-radius: 21px;
  border-bottom-right-radius: 21px; */
  position: fixed;
  margin-top: 70px;
  padding-top: 50px;
  /* border: 1px solid red; */
  background-color: #f0f0f0;

  @media screen and (max-width: 800px) {
    display: block;

    padding: 4rem 26px;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    height: auto;
    padding: 0.5rem;
    display: none;
  }
`;

export const DesktopSideBar = styled.aside`
  display: none;
  width: 227px !important;
  height: 697px;
  padding: 4rem 26px;
  border-top-right-radius: 21px;
  border-bottom-right-radius: 21px;
  /* background-color: #f0f0f0; */

  @media screen and (min-width: 768px) {
    display: block;
  }
`;

export const EvaluationTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  gap: 12px;
  margin-bottom: 10px;
  align-content: center;
  /* height: 44px; */
  text-align: center;
  border-radius: 8px;
  font-family: "Plus Jakarta Sans";
  @media screen and (min-width: 320px) and (max-width: 768px) {
    width: 19vh;
    margin-bottom: 8px;
  }
`;

export const EvaluationTitle = styled.h2`
  font-weight: 400;
  font-size: 14px;
  color: #333335;
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Plus Jakarta Sans;
  font-size: 15.788px;
  font-style: normal;

  line-height: 140%; /* 22.104px */
`;

export const BorderDivision = styled.div`
  border: 2px solid #939396;
`;

export const EvaluationList = styled.ul`
  padding: 0;
  list-style: none;
  margin-left: 5px;
`;

export const EvaluationListItem = styled.li`
  display: flex;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 10px;
  }
`;
