/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from "react";
import { IPoliticsFilter } from "../../../Interface/ApiInterface";
import { validateIdPeriod } from "../../../context/validacionesFiltros";
import { usePolitics } from "../../../redux/Politics";
import {
  Date,
  SelectDos,
  TextFieldDos,
} from "../ModalActions/InputsByTypeObjective";
import {
  ButtonClean,
  ButtonSelect,
  ContainInputs,
  ContainInputsTab,
  ContainerButtonsFiltros,
  RowNew,
  StyleInputs,
} from "./PoliticsStyles";

interface FiltesPoliciesProps {
  file?: File;
  period?: any;
  queryFilter?: any; 
  setQueryFilter?: any;

}
const optionsStatus = [
  { text: "Selecciona", value: "" },
  { text: "Calificado", value: "1" },
  { text: "Pendiente", value: "0" },
];
const optionsClasificacion = [
  { text: "Selecciona", value: "" },
  { text: "División", value: "Division" },
  { text: "Global", value: "Global" },
];
export const FiltersPolicies: FC<FiltesPoliciesProps> = ({ period, queryFilter, setQueryFilter }) => {
  //? Validamos que el periodo vaya de manera correcta
  const periodo = validateIdPeriod(period);

  //** Hooks
  const [politicFilter, setPoliticFilter] = useState<any>(
    {} as IPoliticsFilter
  );

  //** Redux
  const {
    handleGetAllPoliticsFilter,
    handleSetPoliticlFilter,
    handleGetAllPolitics,
  } = usePolitics();

  const handleChange = (e: any) => {
    setPoliticFilter({
      ...politicFilter,
      [e.target.name]: e.target.value,
    });
  };

  const cleanState = () => {
    setPoliticFilter({
      ...politicFilter,
      nombre: "",
      claTrab: "",
      puesto: "",
      ubicacion: "",
      area: "",
      departamento: "",
      fechaNac: "",
      compania: "",
      clasificacion: "",
      estatusPolicies: "",
    });
    handleGetAllPolitics(periodo);
  };

  const handleSearchFilters = () => {
    const queryParams: any = [];
    const addQueryParam = (key: any, value: any) => {
      queryParams.push(`${key}=${encodeURIComponent(value.trim())}`);
    };

    const filterMappings = {
      nombre: "Nombre",
      claTrab: "ClaTrab",
      puesto: "Puesto",
      ubicacion: "Ubicacion",
      area: "Area",
      departamento: "Departamento",
      fechaNac: "FechaNac",
      compania: "Compania",
      clasificacion: "Clasificacion",
      estatusPolicies: "EstatusPolicies",
    };

    // Itera sobre el objeto de mapeo para construir la cadena de consulta basada en los filtros activos.
    Object.entries(filterMappings).forEach(([filterKey, queryKey]) => {
      const value = politicFilter[filterKey];
      if (value !== undefined && value !== "") {
        addQueryParam(queryKey, value);
      }
    });

    // Agrega siempre el IdPeriod al final
    addQueryParam("IdPeriod", periodo);

    // Une todos los parámetros con '&' y los precede con '?', luego actualiza el filtro de consulta
    let query = queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    setQueryFilter(query);

    // Verifica si se debe proceder con la actualización y obtención de los filtros políticos
    if (period !== undefined && period !== "") {
      handleSetPoliticlFilter(politicFilter);
    }
  };

  useEffect(() => {
    cleanState();
  }, []);

  useEffect(() => {
    handleSearchFilters();
  }, [politicFilter]);

  return (
    <RowNew>
      <div className="title">Filtros</div>
      <ContainInputsTab>
        <ContainInputs>
          <StyleInputs>
            <TextFieldDos
              title="Clave trabajador"
              id="claTrab"
              placeholder="123456"
              name="claTrab"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
            />
            <TextFieldDos
              title="Nombre"
              id="nombre"
              placeholder="Jhon doe"
              name="nombre"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
            />
            <TextFieldDos
              title="Puesto"
              id="puesto"
              placeholder="Jefe de desarrollo"
              name="puesto"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
            />
          </StyleInputs>
          <StyleInputs>
            <TextFieldDos
              title="Localidad"
              id="ubicacion"
              placeholder="Matamoros"
              name="ubicacion"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
            />
            <TextFieldDos
              title="Área"
              id="area"
              placeholder="Sistemas"
              name="area"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
            />
            <TextFieldDos
              title="Departamento"
              id="departamento"
              placeholder="Sistemas de informacion"
              name="departamento"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
            />
          </StyleInputs>
          <StyleInputs>
            <Date
              title="Fecha ingreso"
              id="fechaNac"
              name="fechaNac"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
            />
            <TextFieldDos
              id="compania"
              title="Compañía"
              placeholder="Compañía"
              name="compania"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
            />
            <SelectDos
              title="Clasificación"
              id="clasificacion"
              placeholder="Global"
              name="clasificacion"
              value={politicFilter}
              onChange={(e) => handleChange(e)}
              options={optionsClasificacion}
            />
          </StyleInputs>
          <StyleInputs>
            <div className="containerUnDiv">
              <SelectDos
                title="Estatus"
                id="estatusPolicies"
                placeholder="Estatus"
                name="estatusPolicies"
                value={politicFilter}
                onChange={(e) => handleChange(e)}
                options={optionsStatus}
              />
            </div>
          </StyleInputs>
        </ContainInputs>
        <ContainerButtonsFiltros>
          <ButtonClean onClick={() => cleanState()}>Limpiar</ButtonClean>
          <ButtonSelect onClick={() => handleGetAllPoliticsFilter(queryFilter)}>
            Buscar
          </ButtonSelect>
        </ContainerButtonsFiltros>
      </ContainInputsTab>
    </RowNew>
  );
};

// const handleSearchFilters = (e?: any, name?: string) => {
//   let query = ``;
//   if (politicFilter.nombre !== undefined && politicFilter.nombre !== "") {
//     query += `?Nombre=${politicFilter.nombre}`;
//     setQueryFilter(query)
//   }
//   if (politicFilter.claTrab !== undefined && politicFilter.claTrab) {
//     query += `?ClaTrab=${politicFilter.claTrab}`;
//     setQueryFilter(query)
//   }
//   if (politicFilter.puesto !== undefined && politicFilter.puesto) {
//     if (query === "") {
//       query += `?Puesto=${politicFilter.puesto}`;
//       setQueryFilter(query)
//     } else {
//       query += `&Puesto=${politicFilter.puesto}`;
//       setQueryFilter(query)
//     }
//   }
//   if (politicFilter.ubicacion !== undefined && politicFilter.ubicacion) {
//     if (query === "") {
//       query += `?Ubicacion=${politicFilter.ubicacion}`;
//       setQueryFilter(query)
//     } else {
//       query += `&Ubicacion=${politicFilter.ubicacion}`;
//       setQueryFilter(query)
//     }
//   }
//   if (politicFilter.area !== undefined && politicFilter.area) {
//     if (query === "") {
//       query += `?Area=${politicFilter.area}`;
//       setQueryFilter(query)
//     } else {
//       query += `&Area=${politicFilter.area}`;
//       setQueryFilter(query)
//     }
//   }
//   if (
//     politicFilter.departamento !== undefined &&
//     politicFilter.departamento
//   ) {
//     if (query === "") {
//       query += `?Departamento=${politicFilter.departamento.trim()}`;
//       setQueryFilter(query)
//     } else {
//       query += `&Departamento=${politicFilter.departamento.trim()}`;
//       setQueryFilter(query)
//     }
//   }
//   if (politicFilter.fechaNac !== undefined && politicFilter.fechaNac) {
//     if (query === "") {
//       query += `?FechaNac=${politicFilter.fechaNac}`;
//       setQueryFilter(query)
//     } else {
//       query += `&FechaNac=${politicFilter.fechaNac}`;
//       setQueryFilter(query)
//     }
//   }
//   if (politicFilter.compania !== undefined && politicFilter.compania) {
//     if (query === "") {
//       query += `?Compania=${politicFilter.compania}`;
//       setQueryFilter(query)
//     } else {
//       query += `&Compania=${politicFilter.compania}`;
//       setQueryFilter(query)
//     }
//   }
//   if (
//     politicFilter.clasificacion !== undefined &&
//     politicFilter.clasificacion
//   ) {
//     if (query === "") {
//       query += `?Clasificacion=${politicFilter.clasificacion}`;
//       setQueryFilter(query)
//     } else {
//       query += `&Clasificacion=${politicFilter.clasificacion}`;
//       setQueryFilter(query)
//     }
//   }
//   if (
//     politicFilter.estatusPolicies !== undefined &&
//     politicFilter.estatusPolicies
//   ) {
//     if (query === "") {
//       query += `?EstatusPolicies=${politicFilter.estatusPolicies}`;
//       setQueryFilter(query)
//     } else {
//       query += `&EstatusPolicies=${politicFilter.estatusPolicies}`;
//       setQueryFilter(query)
//     }
//   }

//   if (query !== "") {
//     query += `&IdPeriod=${periodo}`;
//     setQueryFilter(query)
//   } else {
//     query += `?IdPeriod=${periodo}`;
//     setQueryFilter(query)
//   }

//   if (period !== undefined && period !== "") {
//     handleSetPoliticlFilter(politicFilter);
//     handleGetAllPoliticsFilter(query);
//   }
// };
