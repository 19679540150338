import Modal from 'react-bootstrap/Modal';
import './loaderStyles.css'

function MyVerticallyCenteredModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body id="body-modal">
        <div id="loader">
            <div id="shadow"></div>
            <div id="box"></div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;