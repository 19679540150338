import axios from "axios";
import {
  ApiResponse,
  CollaboratorInterface,
  CollaboratorUserInterface,
  ICredentials,
  IMeta,
  IResponse,
  ListCollaborator,
} from "../Interface/ApiInterface";
import { http, urlApiHttp } from "../api";

const getCollaboratorsServices = async (query: string | undefined) => {
  return new Promise<ApiResponse<ListCollaborator[]>>((resolve, reject) => {
    let url = `Staff/collaborators/filters${query}`;
    http
      .get(url)
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getCollaboratorServices = async (
  id: number,
  period?: number | string
) => {
  // console.log("Periodo", period);
  return new Promise<ApiResponse<CollaboratorInterface>>((resolve, reject) => {
    http
      .get(
        `Staff/${id}${
          period === undefined || period === "" ? "" : `?PeriodId=${period}`
        }`
      )
      .then((response) => {
        const { data, meta } = response.data;
        const res = {
          data,
          meta,
        };
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getCredentials = async (url: string) => {
  return new Promise<ApiResponse<ICredentials>>((resolve, reject) => {
    axios(`${urlApiHttp}?${url}`)
      .then((response: any) => {
        const { data } = response;
        const res = {
          data: data as ICredentials,
          meta: {} as IMeta,
        };
        resolve(res);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

const getCollaboratorUserServices = async (user: ICredentials) => {
  return new Promise<ApiResponse<CollaboratorUserInterface>>(
    (resolve, reject) => {
      http
        .post(`Auth`, user)
        .then((response) => {
          const { data } = response.data;
          // console.log('colaborador',data);

          const res = {
            data,
            meta: {} as IMeta,
          };
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    }
  );
};

const postCollaboratorServices = async (body: CollaboratorInterface) => {
  return new Promise<IResponse<CollaboratorInterface>>((resolve, reject) => {
    http
      .post(`https://pokeapi.co/api/v2/pokemon?limit=100000&offset=0`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const putCollaboratorServices = async (
  id: number,
  body: CollaboratorInterface
) => {
  return new Promise<IResponse<CollaboratorInterface>>((resolve, reject) => {
    http
      .put(`https://pokeapi.co/api/v2/pokemon?limit=100000&offset=0`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteCollaboratorServices = async (id: number) => {
  return new Promise<IResponse<CollaboratorInterface>>((resolve, reject) => {
    http
      .delete(`https://pokeapi.co/api/v2/pokemon?limit=100000&offset=0`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  deleteCollaboratorServices,
  getCollaboratorServices, getCollaboratorsServices, getCollaboratorUserServices, getCredentials,
  postCollaboratorServices,
  putCollaboratorServices
};

