/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Image, Offcanvas } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { IViews } from "../../Interface/ApiInterface";
import logout from "../../assets/icons/cerrarsession.svg";
import CuestionarioIcon from "../../assets/icons/cuestionario.svg";
import DashboardIcon from "../../assets/icons/dashboard.svg";
import EvaluationIcon from "../../assets/icons/evaluation.svg";
import PeriodoIcon from "../../assets/icons/iconPeriodo.svg";
import mainIcon from "../../assets/icons/logo-collado.svg";
import burguerMenuIcon from "../../assets/icons/mobile-menu-icon.svg";
import notifications from "../../assets/icons/notifications.svg";
import ObjectiveIcon from "../../assets/icons/objetive.svg";
import PoliticasIcon from "../../assets/icons/politicas.svg";
import SideBar from "../../components/atoms/SideBar/SideBar";
import {
  EvaluationTitle,
  EvaluationTitleContainer,
} from "../../components/atoms/SideBar/SideBarStyles";
import NotificationsTable from "../../hooks/Hu93/NotificationsTable";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { AppContainer, Divider } from "../../styles/AppStyles";
import {
  ChildrenLayout,
  GlobalHeader,
  MainLayoutContainer,
} from "./MainLayoutStyles";
import { SocketContext } from "../../context/SocketContext";

const MainLayout = () => {
  const { User, handleGetCollaborator } = useCollaboratorRedux();
  const { handleGetEvaluation } = useEvaluationRedux();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [view, setView] = useState("");
  const [firstCharge, setFirstCharge] = useState(false);
  const [notificationsList, setNotificationsList] = useState(0);

  const [openNotifications, setOpenNotifications] = useState(false);
  const { socket, idRoom, setIdRoom } = useContext(SocketContext);

  const handleLogout = (e: any) => {
    e.preventDefault();
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("LDBCOLLADO");
    if (idRoom) {
      socket?.emit("leave-room", idRoom);
      setIdRoom(undefined);
    }
    setTimeout(() => {
      window.location.href = "https://collado.com.mx/";
    }, 1000);
  };

  useEffect(() => {
    if (User) {
      switch (User.role) {
        case "Evaluado":
          setView("Evaluacion");
          break;
        case "Evaluador":
          setView("Dashboard");
          break;
        case "Director":
          setView("Dashboard");
          break;
        case "Admin":
          setView("Dashboard");
          break;
        default:
          setView("Dashboard");
          break;
      }
    }
  }, []);

  function scrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  }

  const handelView = (name: string) => {
    setView(name);
    switch (name) {
      case "Dashboard":
        window.scrollTo(0, 0);
        break;
      case "Evaluaciones":
        window.scrollTo(0, 0);
        break;
      case "Objetivos":
        window.scrollTo(0, 0);
        break;
      case "Periodo":
        window.scrollTo(0, 0);
        break;
      case "Politicas":
        window.scrollTo(0, 0);
        break;
      case "Cuestionario":
        window.scrollTo(0, 0);
        break;
      case "Mi evaluación":
        window.scrollTo(0, 100);
        break;
      case "Mis objetivos":
        scrollToBottom();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setFirstCharge(!firstCharge);
  }, []);

  return (
    <AppContainer>
      <GlobalHeader>
        <img src={mainIcon} alt="main" />

        <div style={{ position: "relative", height:"20px"}}>
          {notificationsList !== 0 && (
            <div
              style={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                backgroundColor: "#F20505",
                position: "absolute",
                right: 0
              }}
            />
          )}
          <img
            className="settingsIcon"
            src={notifications}
            alt="notificaciones"
            onClick={() => setOpenNotifications(!openNotifications)}
          />
        </div>
        <NotificationsTable
          setNotificationsList={setNotificationsList}
          firstCharge={firstCharge}
          setOpen={setOpenNotifications}
          open={openNotifications}
        />
        <img
          className="burguerIcon"
          src={burguerMenuIcon}
          alt="main"
          onClick={handleShow}
        />
      </GlobalHeader>
      <MainLayoutContainer>
        <SideBar />
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          style={{ width: "266px" }}
        >
          <Offcanvas.Header closeButton>
            <img src={mainIcon} alt="main" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            {User?.views?.map((item: IViews, index) => {
              return (
                <EvaluationTitleContainer key={item.name}>
                  <Link
                    // to={User.role === 'Admin' ? 'Dashboard' : User.role === 'Gerente' ? 'Dasboard' : `/${item.name}`}
                    to={`/${
                      item.name === "Mi evaluación"
                        ? "Evaluacion"
                        : item.name === "Mis objetivos"
                        ? "Evaluacion"
                        : item.name
                    }`}
                    style={{ textDecoration: "none" }}
                    onClick={() => handelView(item.name)}
                    key={`${item.name}${index}`}
                  >
                    <EvaluationTitleContainer
                      style={{
                        background: view === item.name ? "#F4E4D6" : "#FFFFFF",
                      }}
                    >
                      <Image
                        src={
                          item.name === "Dashboard"
                            ? DashboardIcon
                            : item.name === "Evaluaciones"
                            ? EvaluationIcon
                            : item.name === "Objetivos"
                            ? ObjectiveIcon
                            : item.name === "Periodo"
                            ? PeriodoIcon
                            : item.name === "Politicas"
                            ? PoliticasIcon
                            : item.name === "Cuestionario"
                            ? CuestionarioIcon
                            : undefined
                        }
                        alt={item.name}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          marginTop: "12%",
                        }}
                      >
                        <EvaluationTitle
                          style={{
                            color: view === item.name ? "#3B63A2" : "#333335",
                          }}
                        >
                          {User.role === "Evaluado" &&
                          item.name === "Evaluaciones"
                            ? "Mi evaluación"
                            : null}
                          {User.role === "Evaluado" && item.name === "Objetivos"
                            ? "Mis objetivos"
                            : null}
                          {User.role === "Evaluado"}
                          {User.role !== "Evaluado" ? item.name : null}
                        </EvaluationTitle>
                      </div>
                    </EvaluationTitleContainer>
                  </Link>
                </EvaluationTitleContainer>
              );
            })}
            <Divider />
            <Link
              to="/Default.aspx"
              style={{ textDecoration: "none" }}
              onClick={(e) => handleLogout(e)}
              key={`logout`}
            >
              <EvaluationTitleContainer style={{ background: "#FFFFFF" }}>
                <Image src={logout} />
                <EvaluationTitle style={{ color: "#333335" }}>
                  {`Cerrar Sesión`}
                </EvaluationTitle>
              </EvaluationTitleContainer>
            </Link>
          </Offcanvas.Body>
        </Offcanvas>
        <ChildrenLayout>
          <Outlet />
        </ChildrenLayout>
      </MainLayoutContainer>
    </AppContainer>
  );
};

export default MainLayout;
