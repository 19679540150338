/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { FC } from "react";
import styled from "styled-components";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import {
  ContainSubTitle,
} from "../../atoms/ColaborationHeaderEvaluation/ColaboratorHeaderEvaluationStyles";
import { DashboardCard } from "../../atoms/DashboardCard/DashboardCard";
import { RowChart } from "./DashboardCardBoxStyles";
interface DataDonuts {
  title: string;
  value: any;
}
interface PropsDataDonut {
  dataDonutComplete?: DataDonuts[];
  dataDonutRestante?: DataDonuts[] | any[];
  inPolitics?: boolean;
  evaluation_period_id?: any;
  firstValidation?: any;
  secondValidation?: any;
}
const DashboardCardBox: FC<PropsDataDonut> = ({
  dataDonutComplete,
  dataDonutRestante,
  inPolitics,
  evaluation_period_id,
  firstValidation,
  secondValidation,
}) => {
  const { Meta, User, } = useCollaboratorRedux();
  let showTotalDonust = 0;
  if (inPolitics) {
    showTotalDonust = 4
  } else {
    showTotalDonust = 2
  }

  // console.log("Este es el evaluationPEriod", evaluation_period_id);
  
  return (
    <>
      {User?.attributeSections[firstValidation]?.value === 1 && (
        <>
          <RowChart style={{ marginTop: '48px'}}>
            {dataDonutComplete?.map((data, index) => {

              if (index <= showTotalDonust) {
                return (
                  <DashboardCard
                    key={index + index}
                    evaluation_period_id={evaluation_period_id}
                    completadas={data}
                    faltantes={dataDonutRestante?.[index]}
                    chartName={data.title}
                    index={index}
                  />
                );
              }
            })}
          </RowChart>
        </>
      )}
      <ContainSubTitle>
        {inPolitics ? null : (
          <HeaderTitle>KPI's Evaluación de políticas</HeaderTitle>
        )}
      </ContainSubTitle>

      {User?.attributeSections[secondValidation]?.value === 1 && (
        <>
          <RowChart>
            {dataDonutComplete?.map((data, index) => {
              if (index > 2) {
                return (
                  <DashboardCard
                    key={index ** index}
                    evaluation_period_id={evaluation_period_id}
                    completadas={data}
                    faltantes={dataDonutRestante?.[index]}
                    chartName={data.title}
                  
                  />
                );
              }
            })}
          </RowChart>
        </>
      )}
    </>
  );
};

export { DashboardCardBox };

const HeaderTitle = styled.h4`
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 120%;
color: #3b63a2;
`;