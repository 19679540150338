import React, { ReactNode, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import {
  getPeriodYearsSemesters,
  getPeriodsByYear,
  getStaffDirections,
  getStaffLocations,
} from "../../services/dataSelects.service";

export type FiltersDashboardType = {
  setYears?: any;
  years?: any;

  setSelectedOptionYear?: any;
  selectedOptionYear?: any;
  selectedOptionPeriod?: any;
  setSelectedOptionPeriod?: any;

  semesterOptions?: any;
  setSemestersOptions?: any;

  onChangeOptionYear?: any;
  onChangeOptionSemestre?: any;
  getYearsOptions?: any;
  handleSearchPeriod?: any;
  periodAutoEvaluation?: any;
  changePeriodEvaluation?: any;
  setPeriodAutoEvaluation?: any;
  getPeriods?: any;
  setChangePeriodEvaluation?: any;
  locations?: any;
  directions?: any;
  getOptionsDirections?: any;
  getOptionisLocations?: any;
  onChangeOptionSemestreLocal?: any;
  getOnlyYears?: any;
  getOnlyPeriods?: any;
};

interface MyComponentProps {
  children: ReactNode;
}

export const FiltersDashboardContext =
  React.createContext<FiltersDashboardType | null>(null);

const ManageFiltersDashboardProvider: React.FC<MyComponentProps> = ({
  children,
}) => {
  //* Redux
  const { User } = useCollaboratorRedux();

  //** States
  const [years, setYears] = useState<any>([]);

  const [selectedOptionYear, setSelectedOptionYear] = useState(
    User?.periodInfo?.year
  );
  const [selectedOptionPeriod, setSelectedOptionPeriod] = useState("");
  const [semesterOptions, setSemestersOptions] = useState<any>([]);

  //** Locations and directions states
  const [locations, setLocations] = useState<Array<string>>([]);
  const [directions, setDirections] = useState<Array<string>>([]);

  //**  Actions to get locations and directions
  const getOptionisLocations = async () => {
    const { data } = await getStaffLocations();
    const locationsList: Array<string> = [];
    data.map((data) => locationsList.push(data));
    setLocations(locationsList);
  };

  const getOptionsDirections = async () => {
    const { data } = await getStaffDirections();
    const directionsList: Array<string> = [];
    data.map((data) => directionsList.push(data));
    setDirections(directionsList);
  };

  //**  Actions to get periods

  const getPeriods = async (year: any, action: any) => {
    const { data } = await getPeriodsByYear(year);

    const array = data.map((datos) => ({
      evaluationPeriodId: datos?.evaluationPeriodId,
      evaluationPeriodNumberOfYear: datos?.evaluationPeriodNumberOfYear,
    }));

    const firstPeriod = array[0];

    const selectedOption = action
      ? `${User.periodInfo.idPeriod},${User.periodInfo.semesterNumber}`
      : `${firstPeriod.evaluationPeriodId},${firstPeriod.evaluationPeriodNumberOfYear}`;
    // console.log("Aquí lo estoy seteando");
    setSelectedOptionPeriod(selectedOption);
    setSemestersOptions(array);
  };

  //! Se ejecuta al renderizar el componente
  //!Obtiene la info para ambos selects

  const getYearsOptions = async () => {
    const { data } = await getPeriodYearsSemesters();

    const yearList = data.evaluationPeriodYear.map((year, index) => ({
      year,
      id: data.evaluationPeriodNumberOfYear[index],
    }));

    yearList.sort((a, b) => a.year - b.year);

    //! Filtros generales
    setYears(yearList);
    getPeriods(User.periodInfo.year, true);
  };

  const onChangeOptionYear = async (e: any) => {
    const { value } = e.target;

    if (value !== "") {
      setSelectedOptionYear(Number(value));
      getPeriods(Number(value), false);
    }
  };

  const onChangeOptionSemestre = async (e: any) => {
    const { value } = e.target;

    if (value !== "") {
      // console.log("value", value);
      setSelectedOptionPeriod(value);
      // console.log("Seteo el valor del periodo", value)
      const periodId = { valuePeriod: value };
      localStorage.setItem("periodId", JSON.stringify(periodId));
    }
  };

  //! Local Storage

  const onChangeOptionSemestreLocal = (value: any) => {
    if (value !== "") {
      // console.log("Seteamos el valor!", value);
      setSelectedOptionPeriod(value);
    }
  };

  const getOnlyYears = async () => {
    const { data } = await getPeriodYearsSemesters();

    const yearList = data.evaluationPeriodYear.map((year, index) => ({
      year,
      id: data.evaluationPeriodNumberOfYear[index],
    }));

    yearList.sort((a, b) => a.year - b.year);

    //! Filtros generales
    setYears(yearList);
  };

  const getOnlyPeriods = async (year: any) => {
    const { data } = await getPeriodsByYear(year);

    const array = data.map((datos) => ({
      evaluationPeriodId: datos?.evaluationPeriodId,
      evaluationPeriodNumberOfYear: datos?.evaluationPeriodNumberOfYear,
    }));
    setSemestersOptions(array);
  };

  return (
    <FiltersDashboardContext.Provider
      value={{
        selectedOptionYear,
        years,
        selectedOptionPeriod,
        semesterOptions,
        setSemestersOptions,
        setSelectedOptionPeriod,
        setYears,

        onChangeOptionYear,
        onChangeOptionSemestre,

        setSelectedOptionYear,
        getYearsOptions,
        getPeriods,
        locations,
        directions,
        getOptionsDirections,
        getOptionisLocations,

        onChangeOptionSemestreLocal,
        getOnlyYears,
        getOnlyPeriods,
      }}
    >
      {children}
    </FiltersDashboardContext.Provider>
  );
};

export default ManageFiltersDashboardProvider;
