import closeButton from '../../../../assets/closeButton.svg';
import openDots from '../../../../assets/openDots.svg';
import ButtonEvalDonwload from '../../Firms/ButtonEvalDonwload/ButtonEvalDonwload';
import ModalAdvertirDesactivar from '../components/TableDashboard/ModalAdvertirDesactivar';

const OtherOptions = ({
    visibleDownloadIndex,
    index,
    toggleButtonVisibility,
    handleDownload,
    item,
    showModalDesactivar,
    setShowModalDesactivar,
    evaluationAttribute,
    handleDeactivate
}: any) => {
    return <>
        <img
            style={{ cursor: "pointer" }}
            onClick={() => toggleButtonVisibility(index)}
            src={visibleDownloadIndex === index ? closeButton : openDots}
            alt=""
        />
        {visibleDownloadIndex === index && (
            <>
                <ButtonEvalDonwload
                    onClick={() =>
                        handleDownload(
                            0,
                            item.claTrab,
                            item.employeeEvaluation.evaluationPeriod.evaluationPeriodId,
                            item.employeeEvaluation.evaluationPeriod.evaluationPeriodYear,
                            item.employeeEvaluation.evaluationPeriod.evaluationPeriodNumberOfYear
                        )
                    }
                    showModalDesactivar={showModalDesactivar}
                    setShowModalDesactivar={setShowModalDesactivar}
                    demanda={item.demanda}
                    demandaColumn={evaluationAttribute.edit_column_demand}
                />
                {showModalDesactivar &&
                    <ModalAdvertirDesactivar
                        onClick={() => handleDeactivate({ ClaTrab: item.claTrab, Demanda: !item.demanda })}
                        showModalDesactivar={showModalDesactivar}
                        setShowModalDesactivar={setShowModalDesactivar}
                        demanda={item.demanda}
                    />}
                {/* {modalOpen && <ModalConfirmacionDesactivar modalOpen={modalOpen} setModalOpen={setModalOpen}/>} */}
            </>
        )}
    </>;
};

export default OtherOptions;
