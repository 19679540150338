import React, { FC, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  ContentTitleModal,
  ImageModal,
  TitleListo,
  TitleError,
  DeleteHr,
  TitleErrorLenghtMax
} from "../../../components/atoms/Modals/styleModals";
import Ok from "../../../assets/icons/Ok.svg";
import Error from "../../../assets/icons/Error.svg";
interface Labels {
  textBody?: string;
  estado?: boolean;
  cambiarEstado?: any;
  title?: string;
  imageError?: boolean;
}
export const Listo: FC<Labels> = ({
  textBody,
  estado,
  cambiarEstado,
  title,
  imageError
}) => {
/*   setTimeout(() => {
    cambiarEstado(false)
  }, 5000); */


  return (
    <>
      {estado && (
        <Modal
          show={estado}
          backdrop="static"
          onHide={() => cambiarEstado(false)}
        >
          <Modal.Header style={DeleteHr} closeButton>
            <ContentTitleModal>
              <ImageModal src={imageError ? Error : Ok} alt="IconModalOk" />
              {title == 'Lo sentimos' || title == 'Listo' ?
                <Modal.Title style={imageError ? TitleError : TitleListo}>{title}</Modal.Title>
                :
                <Modal.Title style={imageError ? TitleErrorLenghtMax : TitleListo}>{title}</Modal.Title>
              }

            </ContentTitleModal>
          </Modal.Header>
          <div style={{ marginBottom: "2rem" }}>
            <Modal.Body>{textBody}</Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Listo;
