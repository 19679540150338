/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useContext, useState } from "react";
import { Card } from "react-bootstrap";
import { Cell, Pie, PieChart } from "recharts";
import { IFilterCollaborators } from "../../../Interface/ApiInterface";
import salaryDot from "../../../assets/icons/orange-dot.svg";
import { EvaluationContext, EvaluationContextType } from "../../../context/EvaluationContext";
import { validateIdPeriod } from "../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import {
  Completed,
  DashboarCardItem,
  DataBox,
  GoalTitleCard,
  ItemBox,
  cardBody,
  underlineTitle
} from "./DashboardCardStyles";

const COLORS = ["#F27405"];
interface DataDonuts {
  title: string;
  value: any;
}
interface propsChart {
  chartName?: string;
  completadas: DataDonuts;
  faltantes?: DataDonuts;
  data?: Array<DataDonuts>;
  evaluation_period_id?: any;
  index?:number
}
interface DonutData {
  name: string;
  value: string | number;
}


const DonutChart: FC<any> = ({ data }) => {
  const auxData: Array<DonutData> = [...data].map(({ title, value }) => ({
    name: title,
    value,
  }));

  return (
    <PieChart width={160} height={170}>
      <Pie
        data={auxData}
        innerRadius={50}
        outerRadius={70}
        fill="#F27405"
        paddingAngle={6}
        dataKey="value"
      >
        {auxData.map((entry, index) => {
          if (auxData.length - 1 === index) {
            return <Cell key={`cell-${entry.name}`} fill="#F0F0F0" />;
          }
          return (
            <Cell
              key={`cell-${entry.name}`}
              fill={COLORS[index % COLORS.length]}
            />
          );
        })}
      </Pie>
    </PieChart>
  );
};

const DataPorcentageBox: FC<propsChart> = ({ completadas, index }) => {
  // console.log("indice", index)
  return (
    <DataBox>
    <ItemBox>
        <img src={salaryDot} alt="dot" />
        {index === 0 ? (
          <Completed>{completadas?.value} <span style={{color:'#656569'}}>de</span> 100</Completed>
        ) : (
          <Completed>{completadas?.value} <span style={{color:'#656569'}}>% de colaboradores</span></Completed>
        )}
      </ItemBox>
    </DataBox>
  );
};

const DashboardCard: FC<propsChart> = ({
  chartName,
  completadas,
  faltantes,
  evaluation_period_id,
  index
}) => {

  //** Context
  const { setLoadingPagination } = useContext(EvaluationContext) as EvaluationContextType;

  //** Redux
  const {
    User,
    handleGetCollaborators,
    handleSetFilterCollaborators
  } = useCollaboratorRedux();

  //* States
  const [filters, setFilters] = useState({} as IFilterCollaborators);



  const handleName = (e: any) => {
    const { innerHTML } = e.target;

    const idPeriod = validateIdPeriod(evaluation_period_id)
    // console.log('aquí', innerHTML);
    let query = `?`;

    if (idPeriod !== undefined) {
      if (
        innerHTML === "% de trabajadores con más del 95% de calificación" &&
        idPeriod !== undefined
      ) {
        query += `&GraphicFilter.Mas95Porcent=${(filters.Mas95Porcent = true)}`;
      }
      if (
        innerHTML === "% de trabajadores con menos del 50% de calificación" &&
        idPeriod !== undefined
      ) {
        query += `&GraphicFilter.Menos50Porcent=${(filters.Menos50Porcent =
          true)}`;
      }
      if (
        innerHTML === "% de colaboradores que presentaron su políticas" &&
        idPeriod !== undefined
      ) {
        query += `&GraphicFilter.TienePoliticPorcent=${(filters.TienePoliticPorcent =
          true)}`;
      }
      if (
        innerHTML === "% de colaboradores que NO completaron su evaluación" &&
        idPeriod !== undefined
      ) {
        query += `&GraphicFilter.SinEvaluacionPorcent=${(filters.SinEvaluacionPorcent =
          true)}`;
      }

      const periodo = validateIdPeriod(evaluation_period_id)
      query += `&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role}&IdPeriod=${periodo}`;

      setLoadingPagination(true)
      handleSetFilterCollaborators(filters);
      handleGetCollaborators(query);

    }


  };

  return (
    <>
      {completadas.value === 0 && completadas.title === "" ? null : (
        <DashboarCardItem>
          <Card.Title style={underlineTitle}>
            <GoalTitleCard onClick={handleName} style={{ cursor: "pointer" }}>
              {chartName}
            </GoalTitleCard>
          </Card.Title>
          <Card.Body style={cardBody}>
            <DonutChart data={[completadas, faltantes]} />
            <DataPorcentageBox
              completadas={completadas}
              faltantes={faltantes}
              index={index}
            />
          </Card.Body>
        </DashboarCardItem>
      )}
    </>
  );
};

export { DashboardCard };

