import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IGetIsCreatingEvaluationsResponse, IMeta, IPeriod, errorType } from "../Interface/ApiInterface";
import { actionEvent } from "../common/utils";
import {
  createEvaluationPeriodsServices,
  deletePeriodsServices,
  getAllPeriodsServices,
  postPeriodsServices,
  putPeriodsServices,
} from "../services/periods.service";
import { RootReducerInterface } from "./store";
import { useLoader } from "../context/LoaderContext";

enum PeriodsActions {
  GET_ALL_PERIODS = "GET_ALL_PERIODS",
  POST_PERIOD = "POST_PERIOD",
  PUT_PERIOD = "PUT_PERIOD",
  DELETE_PERIOD = "DELETE_PERIOD",
  SET_ERROR_MSG = "SET_ERROR_MSG",
  CREATE_EVALUATION_PERIODS = "CREATE_EVALUATION_PERIODS",
}

interface IPeriodsBaseInterface {
  periods: IPeriod[];
  period: IPeriod;
  errorPeriod: errorType;
  Meta: IMeta;
  message: any;
  loader: boolean;
  setLoader: (loader: boolean) => void;
  setMessage: (message: any) => void;
  setSuccesPost: (succes: boolean) => void;
  succesPost: boolean;
  errorPost: boolean;
  setErrorPost: (error: boolean) => void;
  setTitleModal:(message: any) => void;
  titleModal:any
}

interface UsePeriodsInterface extends IPeriodsBaseInterface {
  handleGetAllPeriods: (query: string) => void;
  handlePostPeriod: (period: IPeriod, cltrabEvaluator: number) => void;
  handlePutPeriod: (id: number, period: IPeriod) => void;
  handleDeletePeriod: (id: number) => void;
  handleCreateEvaluationPeriods(idPeriod: number, claTrab: number): void;
}

interface ReducerActionsInterface {
  type: PeriodsActions;
  payload: IPeriodsBaseInterface;
}

const usePeriods = (): UsePeriodsInterface => {
  const [errorPost, setErrorPost] = useState(false);
  const [succesPost, setSuccesPost] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [titleModal, setTitleModal] = useState("")
  const dispatch = useDispatch();
  const { incrementRequests, decrementRequests } = useLoader();

  const { periods, period, errorPeriod, Meta } = useSelector(
    (state: RootReducerInterface) => state.Periods
  );

  const handleCreateEvaluationPeriods = async (
    idPeriod: number,
    claTrab: number
  ) => {
    try {
      // setLoader(true);
      incrementRequests();
      const data: any = await createEvaluationPeriodsServices(
        idPeriod,
        claTrab
      );
      setSuccesPost(true);
      setMessage(
        data?.message || "Se han creado evaluaciones exitosamente. No se encontraron errores u omisiones. "
      );
      setTitleModal("Evaluacion creada")
      handleGetAllPeriods("");
      // setLoader(false);

      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.CREATE_EVALUATION_PERIODS,
          payload: {
            periods,
            period,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            setTitleModal,
            titleModal
          },
        })
      );
    } catch (error: any) {
      // console.log("error extraido de la api: ", error?.response?.data[0]);
      setErrorPost(true);
      // setLoader(false);
      setMessage(
        error?.response?.data[0].detail ||
          "Lo sentimos, el período no pudo ser creado."
      );
      setTitleModal("Losentimos, no fue posible crear la evaluación")
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.CREATE_EVALUATION_PERIODS,
          payload: {
            periods,
            period,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            setTitleModal,
            titleModal
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleGetAllPeriods = async (query: string) => {
    try {
      // setLoader(true);
      incrementRequests();
      const { data, meta } = await getAllPeriodsServices(query);
      // setLoader(false);
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.GET_ALL_PERIODS,
          payload: {
            periods: data,
            period,
            errorPeriod,
            Meta: meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            titleModal,
            setTitleModal
          },
        })
      );
    } catch (error) {
      // setLoader(false);
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.GET_ALL_PERIODS,
          payload: {
            periods,
            period,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            setTitleModal,
            titleModal
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handlePostPeriod = async (period: IPeriod, cltrabEvaluator: number) => {
    try {
      // setLoader(true);
      incrementRequests();

      const { data } = await postPeriodsServices(period, cltrabEvaluator);

      // setLoader(false);
      setSuccesPost(true);
      setMessage("El periodo ha sido creado correctamente.");
      setTitleModal("Periodo creado")
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.POST_PERIOD,
          payload: {
            periods,
            period: data,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            titleModal,
            setTitleModal
          },
        })
      );
    } catch (error) {
      // setLoader(false);
      setErrorPost(true);
      setMessage("Lamentamos informarte que no fue posible completar la creación del período en este momento. Por favor, verifica la información ingresada e intenta nuevamente. \n Si persisten los problemas, no dudes en contactarnos para recibir asistencia.");
      setTitleModal("Error en la creación de período")
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.POST_PERIOD,
          payload: {
            periods,
            period,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            titleModal,
            setTitleModal
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handlePutPeriod = async (id: number, period: IPeriod) => {
    try {
      // setLoader(true);
      incrementRequests();
      await putPeriodsServices(id, period);
      // setLoader(false);
      setSuccesPost(true);
      setMessage("El período fue actualizado correctamente.");
      setTitleModal("Periodo actualizado")
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.POST_PERIOD,
          payload: {
            periods,
            period,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            titleModal,
            setTitleModal
          },
        })
      );
    } catch (error) {
      setErrorPost(true);
      setMessage("Lo sentimos, el período no pudo ser actualizado.");
      setTitleModal("Error en la actualización de período")
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.POST_PERIOD,
          payload: {
            periods,
            period,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            titleModal,
            setTitleModal
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleDeletePeriod = async (id: number) => {
    try {
      // setLoader(true);
      incrementRequests();
      await deletePeriodsServices(id);
      // setLoader(false);
      setSuccesPost(true);
      setMessage("El período fue eliminado correctamente.");
      setTitleModal("Periodo eliminado")
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.DELETE_PERIOD,
          payload: {
            periods,
            period,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            titleModal,
            setTitleModal
          },
        })
      );
    } catch (error) {
      // setLoader(false);
      setErrorPost(true);
      setMessage("Lo sentimos, el período no pudo ser eliminado.");
      setTitleModal("Error en la eliminación de período")
      dispatch(
        actionEvent<PeriodsActions, IPeriodsBaseInterface>({
          type: PeriodsActions.DELETE_PERIOD,
          payload: {
            periods,
            period,
            errorPeriod,
            Meta,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
            message,
            setMessage,
            setLoader,
            loader,
            titleModal,
            setTitleModal
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };


  return {
    periods,
    period,
    Meta,
    errorPeriod,
    succesPost,
    errorPost,
    handleGetAllPeriods,
    handlePostPeriod,
    handlePutPeriod,
    handleDeletePeriod,
    setSuccesPost,
    setErrorPost,
    message,
    setMessage,
    setLoader,
    loader,
    handleCreateEvaluationPeriods,
    titleModal,
    setTitleModal,
  };
};

const intailState: IPeriodsBaseInterface = {
  periods: [] as IPeriod[],
  period: {} as IPeriod,
  errorPeriod: {} as errorType,
  Meta: {} as IMeta,
  errorPost: false,
  succesPost: false,
  message: "",
  titleModal:"",
  loader: false,
  setLoader: function (loader: boolean): void {
    throw new Error("Function not implemented.");
  },

  setMessage: function (message: string): void {
    throw new Error("Function not implemented.");
  },
  setTitleModal: function (message: string): void {
    throw new Error("Function not implemented.");
  },
  setSuccesPost: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
  setErrorPost: function (error: boolean): void {
    throw new Error("Function not implemented.");
  },
};

const PeriodsReducer = (
  state = intailState,
  action: ReducerActionsInterface
) => {
  switch (action.type) {
    case PeriodsActions.GET_ALL_PERIODS:
      return {
        ...state,
        periods: action.payload.periods,
        Meta: action.payload.Meta,
      };
    default:
      return state;
  }
};

export { PeriodsReducer, usePeriods };

