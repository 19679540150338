import { saveAs } from "file-saver";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EvaluationNew,
  ListCollaborator,
} from "../../../Interface/ApiInterface";
import BookingInterview from "../../../components/atoms/Modals/BookingInterview/BookingInterview";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import ModalTable from "../MySelect";

import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { validateIdPeriod } from "../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import {
  getObjectivesPreviewList,
  getObjectivesPreviewListFuture,
} from "../../../services/dataSelects.service";
import {
  postDeactivateEval,
  postSetAdminStaff,
} from "../../../services/dataTable.service";
import { getFile } from "../../../services/downLoadFileEvaluation";
import {
  getEvaluationStatusSemaforo,
  updateEvaluationStatusSemaforo,
} from "../../../services/evaluation.service";
import ModalDesactivarAutoEval from "../components/ModalEvaluaciones/ModalDesactivarAutoEval";
import ModalHabilitarAutoEval from "../components/ModalEvaluaciones/ModalHabilitarAutoEval";
import HeaderTableUserDashboard from "./SwitchsSelectsTableDashboard/HeaderTableUserDashboard";
import OtherOptions from "./SwitchsSelectsTableDashboard/OtherOptions";
import PermisosTableUserDashboard from "./SwitchsSelectsTableDashboard/PermisosTableUserDashboard";
import StatusTableDashboardUsersSelect from "./SwitchsSelectsTableDashboard/StatusTableDashboardUsersSelect";
import SwitchClasificationUser from "./SwitchsSelectsTableDashboard/SwitchClasificationUser";
import semaforoGreen from "./assets/SemaforoGreen.svg";
import semaforoYellow from "./assets/SemaforoYellow.svg";
import {
  BodyTableWrapperWrap,
  ContainerTable,
  TableRowDos,
} from "./stylesDashboard/AdminStyles";
import {
  ButtonDashboardTable,
  StatusDashboard,
} from "./stylesDashboard/NewStylesDashboard";

interface PropsTable {
  evaluation_period_id: any;
  nameEmployee?: string;
  SetNameEmployee?: string;
  setError?: any;
  setSuccess?: any;
  pageGet?: any;
  setMessage?: any;
  setFilterSearch?: any;
  filterSearch?: any;
  semestre?: any;
  isModalVisible?: boolean;
  setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  selectedOption?: any;
  setSelectedOption?: any;
  selectedIdEvaluation?: any;
  setSelectedIdEvaluation?: any;
  updateTable?: boolean;
  setUpdateTable?: React.Dispatch<React.SetStateAction<boolean>>;
  getAllInfo?: any;
  setuserShow?: React.Dispatch<React.SetStateAction<string>>;
  setuserClaTrab?: React.Dispatch<React.SetStateAction<string>>;
}
interface ButtonInt {
  name?: any;
  item?: any;
}

const TableDashboard: FC<PropsTable> = ({
  evaluation_period_id,
  filterSearch,
  setError,
  setSuccess,
  setMessage,
  pageGet,
  semestre,
  updateTable,
  setUpdateTable,
}) => {
  //* CONTEXT
  const {
    setFutureObjetives,
    setEvaluationInfo,
    setPeriodId,
    setIsReduxOrContext,
    setChangePage,
    changePage,
    setYearId,
    setSemester,
    setPeriodPeticion,
  } = useContext(EvaluationContext) as EvaluationContextType;

  //* REDUX
  const { User, Collaborators, handleGetCollaborator } = useCollaboratorRedux();
  const { evaluationAttribute } = useCollaboratorRedux();

  //* STATES
  const navigate = useNavigate();
  const { handleGetEvaluation } = useEvaluationRedux();
  const [showBookInterview, setshowBookInterview] = useState(false);
  const [show, setShow] = useState(false);
  const [updateChecks, setUpdateChecks] = useState(false);
  const [evaluation, setEvaluation] = useState<EvaluationNew>(
    {} as EvaluationNew
  );
  const [objetivos, setObjetivos] = useState<string[]>([]);
  const [visibleDownloadIndex, setVisibleDownloadIndex] = useState<
    number | null
  >(null);
  const [evaluadoName, setEvaluadoName] = useState("");
  const [showModalDesactivar, setShowModalDesactivar] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  //? arreglo de semaforos
  const [objStatusSemaforo, setobjStatusSemaforo] = useState<any>();

  //? active autoEvaluación modal
  const [modalSemaforo, setModalSemaforo] = useState<boolean>(false);

  //? desactive autoEvaluación modal
  const [modalSemaforoCancel, setModalSemaforoCancel] =
    useState<boolean>(false);

  const [infoSemaforo, setInfoSemaforo] = useState({});

  const handleShow = () => setShow(true);

  const handleInterviewModal = () => setshowBookInterview(true);

  const parsePeriod = (periodId: string) => {
    const cache = JSON.parse(periodId)?.valuePeriod;
    const result = validateIdPeriod(cache);
    return result;
  };

  const getFutureObjectives = async (collaborator: ListCollaborator) => {
    let periodCache = parsePeriod(localStorage.getItem("periodId")!);

    handleGetCollaborator(collaborator.claTrab, periodCache);
    handleGetEvaluation(
      User.claTrab,
      collaborator.claTrab,
      collaborator.employeeEvaluation.evaluationPeriod.evaluationPeriodId
    );

    const { data: futureObjectives } = await getObjectivesPreviewListFuture(
      evaluation_period_id,
      collaborator?.employeeEvaluation.employeeEvaluationEvaluatedUserId,
      collaborator?.employeeEvaluation.employeeEvaluationId
    );

    setEvaluationInfo(collaborator);
    setFutureObjetives(futureObjectives);

    //values con los que para hacer la bused en las evaluation
    const searchEvaluation = {
      clabTrab: collaborator.claTrab,
      evaluationPeriodYear:
        collaborator.employeeEvaluation.evaluationPeriod.evaluationPeriodYear,
      evaluationPeriodNumberOfYear:
        collaborator.employeeEvaluation.evaluationPeriod
          .evaluationPeriodNumberOfYear,
      collaboratorId:
        collaborator?.employeeEvaluation.employeeEvaluationEvaluatedUserId,
      evaluationPeriodId:
        collaborator.employeeEvaluation.evaluationPeriod.evaluationPeriodId,
    };

    localStorage.setItem("searchEvaluation", JSON.stringify(searchEvaluation));
    navigate(
      `/evaluacion/${collaborator.claTrab}/${collaborator.employeeEvaluation.evaluationPeriod.evaluationPeriodYear}/${collaborator.employeeEvaluation.evaluationPeriod.evaluationPeriodNumberOfYear}`
    );
  };

  const handleStatusSemaforo = async (
    IdEvaluation: number
  ): Promise<boolean> => {
    const res = await getEvaluationStatusSemaforo(IdEvaluation);
    if (res.data) return res.data.status;
    else return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      const statusPromises = Collaborators.map(async (element) => {
        const st = await handleStatusSemaforo(
          element.employeeEvaluation.employeeEvaluationId
        );
        return {
          status: st,
        };
      });

      const statuses = await Promise.all(statusPromises);
      setobjStatusSemaforo(statuses);
    };

    fetchData();
  }, [Collaborators]);

  const handleSubmitGetEvaluation = async (
    e: any,
    collaborator: ListCollaborator
  ) => {
    e.preventDefault();
    setIsReduxOrContext(false);
    setPeriodId(evaluation_period_id);
    setEvaluationInfo(collaborator);
    setYearId(
      collaborator.employeeEvaluation.evaluationPeriod.evaluationPeriodYear
    );
    setPeriodPeticion(
      collaborator.employeeEvaluation.evaluationPeriod
        .evaluationPeriodNumberOfYear
    );
    setSemester(
      collaborator.employeeEvaluation.evaluationPeriod
        .evaluationPeriodNumberOfYear
    );
    switch (collaborator.employeeEvaluation.catStatusEvaluationId) {
      case 1:
        setEvaluation(collaborator.employeeEvaluation);
        const { data: currentObjectives } = await getObjectivesPreviewList(
          evaluation_period_id,
          collaborator?.employeeEvaluation.employeeEvaluationEvaluatedUserId,
          collaborator?.employeeEvaluation.employeeEvaluationId
        );
        setPeriodId(evaluation_period_id);
        setChangePage(!changePage);
        setObjetivos(currentObjectives);
        handleShow();
        break;

      case 2:
      case 3:
      case 4:
        setChangePage(!changePage);
        getFutureObjectives(collaborator);
        break;

      case 5:
        setChangePage(!changePage);
        setEvaluation(collaborator.employeeEvaluation);
        handleInterviewModal();
        break;

      case 6:
      case 7:
        setChangePage(!changePage);
        getFutureObjectives(collaborator);
        break;
    }
  };
  const handleSubmitGetEvaluationAuditor = async (
    e: any,
    collaborator: ListCollaborator
  ) => {
    e.preventDefault();
    setIsReduxOrContext(false);
    setPeriodId(evaluation_period_id);
    setEvaluationInfo(collaborator);
    setYearId(
      collaborator.employeeEvaluation.evaluationPeriod.evaluationPeriodYear
    );
    setPeriodPeticion(
      collaborator.employeeEvaluation.evaluationPeriod
        .evaluationPeriodNumberOfYear
    );
    setSemester(
      collaborator.employeeEvaluation.evaluationPeriod
        .evaluationPeriodNumberOfYear
    );
    setChangePage(!changePage);
    getFutureObjectives(collaborator);
  };

  const ActionButton: FC<ButtonInt> = ({ name, item }: any) => {
    return (
      <ButtonDashboardTable
        onClick={(e: any) => {
          handleSubmitGetEvaluation(e, item);
        }}
      >
        <span>{name}</span>
      </ButtonDashboardTable>
    );
  };

  const ActionButtonAuditor: FC<ButtonInt> = ({ name, item }: any) => {
    return (
      <ButtonDashboardTable
        onClick={(e: any) => {
          handleSubmitGetEvaluationAuditor(e, item);
        }}
      >
        <span>{name}</span>
      </ButtonDashboardTable>
    );
  };

  const actionMap: Record<number, (item: any) => JSX.Element | null> = {
    1: (item) =>
      evaluationAttribute.action_modal_define_objetives === 1 ? (
        <ActionButton name="Definir objetivos" item={item} />
      ) : null,
    2: (item) => <ActionButton name="Evaluar" item={item} />,
    3: (item) => <ActionButton name="Evaluar" item={item} />,
    4: (item) => <ActionButton name="Ver evaluación" item={item} />,
    5: (item) =>
      evaluationAttribute.action_booking_interview === 1 ? (
        <ActionButton name="Agendar entrevista" item={item} />
      ) : null,
    6: (item) => <ActionButton name="Firmar" item={item} />,
    7: (item) => <ActionButton name="Ver evaluación" item={item} />,
    8: (item) => <ActionButtonAuditor name="Ver evaluación" item={item} />,
  };

  const handleActions = (idStatus: number, item: any, role: string) => {
    if (role === "Auditor") {
      const actionFunction = actionMap[8];
      if (actionFunction) {
        return actionFunction(item);
      }
      return null;
    } else {
      const actionFunction = actionMap[idStatus];
      if (actionFunction) {
        return actionFunction(item);
      }
      return null;
    }
  };

  const updateClasification = async (
    // e: any,
    collaborator: ListCollaborator
  ) => {
    setUpdateChecks(!updateChecks);

    const { message } = await postSetAdminStaff(
      collaborator.claTrab,
      undefined,
      collaborator.clasificacion,
      evaluation_period_id
    );
  };

  async function fetchData(
    claTrabEvaluator: number,
    claTrabEvaluated: number,
    iDPeriod: number,
    evaluationPeriodYear: number,
    evaluationPeriodNumberOfYear: number
  ) {
    try {
      const response = await getFile(
        claTrabEvaluator,
        claTrabEvaluated,
        iDPeriod
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(
        blob,
        `Evaluacion_${claTrabEvaluated}-${evaluationPeriodYear}-${evaluationPeriodNumberOfYear} (${iDPeriod})`
      );
    } catch (error) {
      // console.error("Error:", error);
      // alert("El trabajador no tiene evaluacion aun")
    }
  }

  const handleDownload = (
    claTrabEvaluator: number,
    claTrabEvaluated: number,
    iDPeriod: number,
    evaluationPeriodYear: number,
    evaluationPeriodNumberOfYear: number
  ) => {
    fetchData(
      claTrabEvaluator,
      claTrabEvaluated,
      iDPeriod,
      evaluationPeriodYear,
      evaluationPeriodNumberOfYear
    );
  };

  const handleDeactivate = ({
    ClaTrab,
    Demanda,
  }: {
    ClaTrab: number;
    Demanda: boolean;
  }) => {
    postDeactivateEval(ClaTrab, Demanda)
      .then((response) => {
        setModalOpen(true);
        if (setUpdateTable) {
          setUpdateTable(!updateTable);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleBlockUnblock = (idEvaluation: number, status: boolean) => {
    const body = {
      IdEvaluation: idEvaluation,
      Status: status,
    };
    updateEvaluationStatusSemaforo(body);
  };

  const toggleButtonVisibility = (rowIndex: number) => {
    if (visibleDownloadIndex === rowIndex) {
      setVisibleDownloadIndex(null); // Hide the download button if it's already visible
    } else {
      setVisibleDownloadIndex(rowIndex); // Show the download button for the clicked row
    }
  };

  if (Collaborators.length === 0) return null;

  return (
    <ContainerTable>
      <BodyTableWrapperWrap>
        <HeaderTableUserDashboard />

        {Collaborators &&
          Collaborators?.map((item: ListCollaborator, index: number) => {
            return (
              <TableRowDos key={item.claTrab}>
                <div className="first">{item.infoCollaborator}</div>

                <div className="second">
                  {evaluationAttribute.change_status_evaluation === 0 && (
                    <StatusDashboard>
                      {item.demanda ? "Inactivo" : item.evaluationStatus}
                    </StatusDashboard>
                  )}

                  {evaluationAttribute.change_status_evaluation === 1 && (
                    <StatusTableDashboardUsersSelect
                      item={item}
                      // setSelectedOption={setSelectedOption}
                      // setSelectedIdEvaluation={setSelectedIdEvaluation}
                      // setIsModalVisible={setIsModalVisible}
                      // setuserShow={setuserShow}
                      // setuserClaTrab={setuserClaTrab}
                    />
                  )}
                </div>

                {evaluationAttribute.status_managed === 1 ? (
                  <div className="third">
                    {handleActions(
                      item.employeeEvaluation?.catStatusEvaluation
                        ?.catStatusEvaluationId,
                      item,
                      User.role
                    )}
                  </div>
                ) : null}

                {evaluationAttribute.action_global_calification === 1 && (
                  <div className="fourth">
                    {/* <FormControlLabel
                      sx={{
                        fontFamily: "Plus Jakarta Sans",
                        fontSize: "14px",
                      }}
                      control={<IOSSwitch sx={{ m: 1 }} />}
                      label={item.clasificacion ? "Si" : "No"}
                      checked={item.clasificacion}
                      onChange={(e) => {
                        item.clasificacion = !item.clasificacion;
                        updateClasification(e, item);
                      }}
                    /> */}
                    <SwitchClasificationUser
                      item={item}
                      // updateClasification={updateClasification}
                    />
                  </div>
                )}

                {/* --------------- SEMAFORO --------------- */}

                {User.role !== "Auditor" && (
                  <div className="fifth">
                    {item.estatusAutoevaluation ? (
                      <img src={semaforoGreen} alt="Green" />
                    ) : (
                      <img src={semaforoYellow} alt="Yellow" />
                    )}
                  </div>
                )}

                {/* --------------- SEMAFORO --------------- */}

                {/* --------------- PERMISOS --------------- */}
                {evaluationAttribute.action_define_administrator === 1 && (
                  <div className="sixth">
                    <PermisosTableUserDashboard item={item} />
                  </div>
                )}
                {/* --------------- PERMISOS --------------- */}

                {/* --------------- OTRAS OPCIONES --------------- */}
                {User.role !== "Auditor" && (
                  <div className="seventh">
                    <OtherOptions
                      visibleDownloadIndex={visibleDownloadIndex}
                      index={index}
                      toggleButtonVisibility={toggleButtonVisibility}
                      handleDownload={handleDownload}
                      item={item}
                      showModalDesactivar={showModalDesactivar}
                      setShowModalDesactivar={setShowModalDesactivar}
                      evaluationAttribute={evaluationAttribute}
                      handleDeactivate={handleDeactivate}
                    />
                  </div>
                )}
                {/* --------------- OTRAS OPCIONES --------------- */}
              </TableRowDos>
            );
          })}
        <>
          <ModalTable
            evaluation={evaluation}
            show={show}
            onHide={setShow}
            semestre={semestre}
            objetivos={objetivos}
          />

          <BookingInterview
            showBookInterview={showBookInterview}
            onHide={setshowBookInterview}
            evaluation={evaluation}
            evaluation_period_id={evaluation_period_id}
            setError={setError}
            setSuccess={setSuccess}
            setMessage={setMessage}
            pageGet={pageGet}
            filterSearch={filterSearch}
          />

          <ModalHabilitarAutoEval
            setIsModalVisible={setModalSemaforo}
            isModalVisible={modalSemaforo}
            infoSemaforo={infoSemaforo}
            statusSemaforos={objStatusSemaforo}
            setStatusSemaforos={setobjStatusSemaforo}
            evaluation_period_id={evaluation_period_id}
            evaluadoName={evaluadoName}
          />

          <ModalDesactivarAutoEval
            setIsModalVisible={setModalSemaforoCancel}
            isModalVisible={modalSemaforoCancel}
            infoSemaforo={infoSemaforo}
            statusSemaforos={objStatusSemaforo}
            setStatusSemaforos={setobjStatusSemaforo}
            evaluation_period_id={evaluation_period_id}
            evaluadoName={evaluadoName}
          />
        </>
      </BodyTableWrapperWrap>
    </ContainerTable>
  );
};

export default TableDashboard;
