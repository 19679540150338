import styled from "styled-components";
// import Close from "../../../assets/icons/CloseModal.svg";
import { useContext, useEffect, useState } from "react";
import AlertModalIcon from "../../../assets/infoYellow.svg";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { putSend } from "../../../services/sendEvaluation.service";
import { ModalAlertWrapper } from "../ModalActions/ModalEditStyles";

const ids: Record<number, string> = {
  1: "Definición de objetivos",
  2: "Nuevo ingreso",
  3: "Pendiente evaluar",
  4: "Pendiente de liberar",
  6: "Pendiente de firmar",
  7: "Completada",
};

const ModalChangeEstatus = ({
  item,
  isModalVisible,
  setIsModalVisible,
  selectedOption,
  setRoleUser,
}: any) => {
  //** Redux
  const { User, handleGetCollaborators } = useCollaboratorRedux();
  const { setLoadingPagination } = useContext(
    EvaluationContext
  ) as EvaluationContextType;

  const [filters, setFilters] = useState<any | null>({
    claTrab: "",
    nombre: "",
    puesto: "",
    Localidad: "",
    area: "",
    departamento: "",
    fechaNac: "",
    IdEstatusEvaluacion: "",
    Clasificacion: "",
    gerente: "",
    director: "",
    compania: "",
    JerarquiaColaborador: "",
    EstatusAutoEvaluacion: "",
  });

  useEffect(() => {
    const filtrosAlmacenados = localStorage.getItem("tableFilters");

    if (filtrosAlmacenados) {
      const filtros = JSON.parse(filtrosAlmacenados);
      // console.log("Tienes filtros", filtros);
      setFilters(filtros);
    }
    // getDataSelectStatus();
    // handleClear();
  }, []);

  function obtenerClavePorValor(valor: string): number | null {
    for (const clave in ids) {
      if (ids.hasOwnProperty(clave)) {
        if (ids[clave] === valor) {
          return Number(clave);
        }
      }
    }
    return null;
  }

  const handleSent = () => {
    const filtrosAlmacenados = localStorage.getItem("principalFilters");
    const pageStorage = localStorage.getItem("pageLocalStorage");
    const pageStorageParsed = JSON.parse(pageStorage ?? "{}");

    const { page: pageStore } = pageStorageParsed;

    if (selectedOption !== undefined) {
      setRoleUser(selectedOption);

      const claveEncontrada: number | null =
        obtenerClavePorValor(selectedOption);

      // console.log("claveEncontrada", claveEncontrada, selectedOption);

      if (claveEncontrada) {
        putSend(
          Number(item.employeeEvaluation.employeeEvaluationId),
          claveEncontrada
        )
          .then((response) => {
            setIsModalVisible(!isModalVisible);
            // console.log(response);
          })
          .catch((error) => {
            // console.error("Error:", error);
          });
      }
      if (filtrosAlmacenados) {
        const filtros = JSON.parse(filtrosAlmacenados);
        const { year, periodo, propLocation, propDirector } = filtros;

        let query = `?`;
        Object.keys(filters).forEach((key) => {
          if (
            filters[key] !== undefined &&
            filters[key] !== "" &&
            filters[key] !== null &&
            filters[key] !== "Elige una opción"
          ) {
            query += `&${key}=${filters[key]}`;
          }
        });

        handleGetCollaborators(
          `${query}&NombreColaborador=${User.nombre}&RolColaborador=${
            User.role === "Auditor" ? "Admin" : User.role
          }&IdPeriod=${periodo}&PageNumber=${pageStore}&PageSize=10`
        );
        setLoadingPagination(true);
      }
    } else {
      // alert("Error");
    }
  };

  // console.log(item);

  return (
    <ModalAlertWrapper display={isModalVisible}>
      <div className="modalWrapper">
        <TitleContainer>
          <img src={AlertModalIcon} className="image" alt="AlertModalIcon" />
          <TitleText className="titleText">Cambiar estatus </TitleText>
        </TitleContainer>

        <ContainerModalText>
          <div className="textContainer">
            Al confirmar, cambiarás el estatus actual del colaborador [
            {item.claTrab} - {item.nombre}]
          </div>
          <div className="textContainer">¿Deseas continuar?</div>
        </ContainerModalText>
        <FooterModal>
          <ButtonModalCancel
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Cancelar
          </ButtonModalCancel>
          <ButtonModalSave onClick={handleSent}>Continuar</ButtonModalSave>
        </FooterModal>
      </div>
    </ModalAlertWrapper>
  );
};

export default ModalChangeEstatus;

export const InputObj = styled.textarea`
  width: 80%;
  height: 80%;
  border: none;
  outline: none;
  resize: none;
`;

export const TitleContainer = styled.div`
  display: flex;
  width:100%;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const TitleText = styled.p`
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;
  margin: 0;
  /* Title */
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 22px */
`;

export const ContainerModalText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  //padding: 14px 21px;

  .textContainer {
    font-size: 16px;
    color: #333335;
    font-weight: 400;
    //padding-bottom: 5px;
  }

  .containerModal {
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: end;
    font-size: 14px;

    .txt {
      margin-top: 8px;
      padding: 8px;
    }

    .containerTxt {
      background-color: white;
      border-radius: 8px;
      font-weight: 700;
      color: black;
      font-size: 14px;
      //padding: 8px;
      //margin: 8px;
      //margin-right: 24px;
      width: 83%;
    }
  }
`;

export const FooterModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 32px;
  //padding: 14px 21px;
`;

export const ButtonModalSave = styled.div`
  background-color: #c9f2ff;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #3b63a2;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  align-content: center;
`;

export const ButtonModalCancel = styled.div`
  background-color: #3b63a2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #FFF;
  padding: 8px 24px;
  cursor: pointer;
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  align-content: center;
  justify-content: center;
`;
