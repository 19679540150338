import { FC } from 'react';
import styled from 'styled-components';
import check from '../../../assets/checkGreen.svg';
import info from '../../../assets/infoYellow.svg';
import {
  ButtonContainer,
  ButtonContinuar,
  Header,
  ImageIcon,
  TextModal,
} from './ModalStatusStyles';

interface Labels {
  handleClick?: any;
  showData?: boolean | true;
  modalStatus?: boolean;
  message?: string;
  messageShow?: boolean;
  setFooter?: any;
  onClick?: any;
  excelFile?: any;
  rowsNotInsertedResponse?: number;
  titleModal?: any;
}

export const ModalStatusLoader: FC<Labels> = ({
  showData,
  handleClick,
  modalStatus,
  message,
  messageShow,
  setFooter,
  onClick,
  excelFile,
  rowsNotInsertedResponse,
  titleModal,
}) => {
  return (
    <ContainerModal showData={showData ? 'flex' : 'none'} data-testid='loader'>
      <ModalBox>
        <Header>
          <div>
            <ImageIcon
              src={!rowsNotInsertedResponse ? info : check}
              style={{ marginRight: '12px' }}
            />
            {titleModal}
          </div>
          {/* <CloseIcon src={Close} onClick={() =>{ 
            if(setFooter){
              setFooter(false)
            }
            handleClick(!modalStatus)}} /> */}
        </Header>
        <TextModal>
          {messageShow ? message : 'El objetivo se ha agregado correctamente.'}
        </TextModal>

        {excelFile ? (
          <ButtonContainer>
            <ButtonContinuar onClick={onClick}>
              Descargar archivo
            </ButtonContinuar>
          </ButtonContainer>
        ) : null}
      </ModalBox>
    </ContainerModal>
  );
};

export default ModalStatusLoader;

export const ContainerModal = styled.div.attrs(
  (props: PropTypeBg) => ({})
)<PropTypeBg>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.showData ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000000;
`;

type PropTypeBg = {
  showData?: string;
  fontSz?: string;
  lineH?: string;
};

export const ModalBox = styled.div`
  background-color: #fff;
  /* height: 233px; */
  max-width: 699px;
  width: 100%;
  z-index: 2;
  border-radius: 19px;
  display: flex;
  flex-direction: column;
  //gap:32px;
  padding: 32px;
  margin-bottom: 400px;

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    /* height: 200px; */
  }
`;
