import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionEvent } from "../common/utils";
import { RootReducerInterface } from "./store";
import { HumanFactorInterface } from '../Interface/ApiInterface';
import { getHumanFactorServices, postHumanFactorServices, putHumanFactorServices, deleteHumanFactorServices } from "../services/humanFactor.service";
/**
 * Action Types
 */
export enum HumanFactorActions {
  GET_HUMAN_FACTOR_SUCCESS = "GET_HUMAN_FACTOR_SUCCESS",
  GET_HUMAN_FACTOR_ERROR = "GET_HUMAN_FACTOR_ERROR",
  POST_HUMAN_FACTOR_SUCCESS = "POST_HUMAN_FACTOR_SUCCESS",
  POST_HUMAN_FACTOR_ERROR = "POST_HUMAN_FACTOR_ERROR",
  PUT_HUMAN_FACTOR_SUCCESS = "PUT_HUMAN_FACTOR_SUCCESS",
  PUT_HUMAN_FACTOR_ERROR = "PUT_HUMAN_FACTOR_ERROR",
  DELETE_HUMAN_FACTOR_SUCCESS = "DELETE_HUMAN_FACTOR_SUCCESS",
  DELETE_HUMAN_FACTOR_ERROR = "DELETE_HUMAN_FACTOR_ERROR",
}

/**
 * Interfaces
 */
interface HumanFactorBaseInterface {
  HumanFactors: HumanFactorInterface[];
  loading: boolean;
}

interface UseHumanFactorInterface extends HumanFactorBaseInterface {
  handleGetHumanFactor: () => void;
  handlePostHumanFactor: (body: HumanFactorInterface) => void;
  handleUpdateHumanFactor: (id: number, body: HumanFactorInterface) => void;
  handleDeleteHumanFactor: (id: number) => void;
}

interface ReducerActionsInterface {
  type: HumanFactorActions;
  payload: HumanFactorBaseInterface;
}

/**
 * Hooks
 */
const useHumanFactorRedux = (): UseHumanFactorInterface => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const {
    HumanFactors,
  } = useSelector((state: RootReducerInterface) => state.HumanFactors);

  const handleGetHumanFactor = async (): Promise<void> => {
    try {
      setLoading(true);
      const { results } = await getHumanFactorServices();
      setLoading(false);
      
      dispatch(
        actionEvent<HumanFactorActions, HumanFactorBaseInterface>({
          type: HumanFactorActions.GET_HUMAN_FACTOR_SUCCESS,
          payload: {
            HumanFactors: results,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<HumanFactorActions, HumanFactorBaseInterface>({
          type: HumanFactorActions.GET_HUMAN_FACTOR_ERROR,
        })
      );
    }
  };

  const handlePostHumanFactor = async (body: HumanFactorInterface): Promise<void> => {
    try {
      setLoading(true);
      await postHumanFactorServices(body);
      setLoading(false);

      dispatch(
        actionEvent<HumanFactorActions, HumanFactorBaseInterface>({
          type: HumanFactorActions.POST_HUMAN_FACTOR_SUCCESS,
          payload: {
            HumanFactors,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<HumanFactorActions, HumanFactorBaseInterface>({
          type: HumanFactorActions.POST_HUMAN_FACTOR_ERROR,
        })
      );
    }
  };

  const handleUpdateHumanFactor = async (id: number, body: HumanFactorInterface): Promise<void> => {
    try {
      setLoading(true);
      await putHumanFactorServices(id, body);
      setLoading(false);

      dispatch(
        actionEvent<HumanFactorActions, HumanFactorBaseInterface>({
          type: HumanFactorActions.PUT_HUMAN_FACTOR_SUCCESS,
          payload: {
            HumanFactors,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<HumanFactorActions, HumanFactorBaseInterface>({
          type: HumanFactorActions.PUT_HUMAN_FACTOR_ERROR,
        })
      );
    }
  };

  const handleDeleteHumanFactor = async (id: number): Promise<void> => {
    try {
      setLoading(true);
      await deleteHumanFactorServices(id);
      setLoading(false);

      dispatch(
        actionEvent<HumanFactorActions, HumanFactorBaseInterface>({
          type: HumanFactorActions.DELETE_HUMAN_FACTOR_SUCCESS,
          payload: {
            HumanFactors,
            loading
          },
        })
      );
    } catch (e) {
      dispatch(
        actionEvent<HumanFactorActions, HumanFactorBaseInterface>({
          type: HumanFactorActions.DELETE_HUMAN_FACTOR_ERROR,
        })
      );
    }
  };

  return {
    loading,
    HumanFactors,
    handleGetHumanFactor,
    handlePostHumanFactor,
    handleUpdateHumanFactor,
    handleDeleteHumanFactor
  };
};

/**
 * Reducers
 */
const initialState: HumanFactorBaseInterface = {
  loading: false,
  HumanFactors: [],
};

const HumanFactorReducer = (
  state = initialState,
  action: ReducerActionsInterface
): HumanFactorBaseInterface => {
  switch (action.type) {
    case HumanFactorActions.GET_HUMAN_FACTOR_SUCCESS:
    return {
        ...state,
        HumanFactors: action.payload.HumanFactors,
        loading: action.payload.loading
      };
    default:
      return state;
  }
};

/**
 * Exports
 */
export { HumanFactorReducer, useHumanFactorRedux };
export type { UseHumanFactorInterface, HumanFactorBaseInterface };
