import { http } from "../api";

const putSend = async (IdEvaluation: number, IdCatEstatus: number) => {
  try {
    const response = await http.put(
      `/Evaluations/change-evaluation-status?IdEvaluation=${IdEvaluation}&IdCatEstatus=${IdCatEstatus}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export { putSend };

