import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import styled from "styled-components";
import SuccesIconVector from "../../../assets/SuccesIconVector.svg";
import infoIconVector from "../../../assets/infoIconVector.svg";
import { aproveSolicitud } from "../../../services/massiveChange.service";
import ChangeStatusCard from "./ChangeStatusCard";

// Define el tipo PropTypeBgTres aquí
type PropTypeBgTres = {
  underline?: boolean;
  deny?: boolean;
};

const NotificationCard = ({
  idSolicitante,
  nombreSolicitante,
  index,
  lengthData,
  idSolicitud,
  closeNotifications,
  setUpdateData,
  updateData,
}: any) => {
  const queryClient = useQueryClient();
  //* States
  const [idChangeStatus, setIdChangeStatus] = useState<number | null>(null);
  const [idSolicitanteStatus, setIdSolicitanteStatus] = useState<number | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [denyOrAprove, setDenyOrAprove] = useState("Aprobado");
  const [succesMutation, setSuccessMutation] = useState(false);

  //* Functions
  const handleChangeId = () => {
    setIdChangeStatus(idSolicitud);
    setOpen(!open);
    setIdSolicitanteStatus(idSolicitante);
  };

  const {
    mutateAsync: aproveSolicitudMutation,
    isIdle,
    isPending,
    isSuccess,
  } = useMutation({
    mutationFn: aproveSolicitud,
    onSuccess: () => {
      setSuccessMutation(true);
      queryClient.invalidateQueries({ queryKey: ["issuesById"] });
      setTimeout(() => {
        setSuccessMutation(false);
      }, 2000);
    },
  });

  // console.log(isPending, isIdle, isSuccess);

  return (
    <>
      <Container underline={index + 1 === lengthData}>
        Tienes una solicitud de cambio de estatus masivo de
        <span className="nombreSolicitante"> {nombreSolicitante} {idSolicitante} </span>
        pendiente de aprobar. Revísala{" "}
        <span className="actionClick" onClick={handleChangeId}>
          {" "}
          aquí.{" "}
        </span>
      </Container>
      <ChangeStatusCard
        closeNotifications={closeNotifications}
        key={idChangeStatus}
        id={idChangeStatus}
        open={open}
        close={setOpen}
        idSolicitanteStatus={idSolicitanteStatus}
        aproveSolicitudMutation={aproveSolicitudMutation}
        setDenyOrAprove={setDenyOrAprove}
        setUpdateData={setUpdateData}
        updateData={updateData}

      />
      {succesMutation && (
        <ContainerAviso deny={denyOrAprove === "Aprobar"}>
          <div className="containerDiv">
            {denyOrAprove === "Aprobar" ? (
              <>
                <img src={SuccesIconVector} alt="" />
                <div>
                  La solicitud ha sido aprobada, se le enviará un correo de
                  notificación a{" "}
                  <span className="spantxt">{idSolicitante}</span>.
                </div>
              </>
            ) : (
              <>
                <img src={infoIconVector} alt="" />
                <div>
                  La solicitud ha sido denegada, se le enviará un correo de
                  notificación a{" "}
                  <span className="spantxt">{idSolicitante}</span>.
                </div>
              </>
            )}
          </div>
        </ContainerAviso>
      )}
      {/* {(isIdle || isPending) && <>cargando</>} */}
    </>
  );
};

export default NotificationCard;

const Container = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  width: 90%;
  border-bottom: ${(props) => (props.underline ? "none" : "1px solid #f0f0f0")};
  margin: 5px auto;
  padding: 16px 0px;
  .nombreSolicitante {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }

  .actionClick {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(59, 99, 162, 1);
    cursor: pointer;
  }

  //styleName: Small Body;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
`;

export const ContainerAviso = styled.div.attrs(
  (props: PropTypeBgTres) => ({})
)<PropTypeBgTres>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  /* background-color: ${(props) => (props.deny ? "#FFF8E8" : "#D8FFD4")}; */
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .containerDiv {
    margin-top: 80px;
    max-width: 400px;
    min-height: 80px;
    border-radius: 8px;
    background-color: ${(props) => (props.deny ? "#D8FFD4" : "#FFF8E8")};
    box-shadow: 0px 4px 8px 0px rgba(6, 17, 46, 0.18);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    flex-direction: row;
    gap: 10px;
  }

  .spantxt {
    font-weight: 700;
  }
  /* z-index: 1; */
  /* display: ${(props) => (props.deny ? "flex" : "none")}; */
`;
