/* eslint-disable @typescript-eslint/no-unused-vars */
import { config, http } from '../api';
import { ApiResponse, IPolitic, IResponse, IValidationFilePolitics, PolitcEvaluation } from '../Interface/ApiInterface';



const BulkPoliticsUploadServices = async (file: File) => {
    const data = new FormData();
    data.append('file', file);
    
    
    return new Promise<IResponse<IValidationFilePolitics>>((resolve, reject) => {
      http
        .post(`Policies/MassiveUploadPolicies`,  data, config)
        .then((response) => {          
          resolve(response.data);
        })
        .catch((error) => {  
          reject(error.response.data[0]);
        });
    });
  };

  const MasivePatchUploadServices = async (file: File) => {
    const data = new FormData();
    data.append('file', file);
    
    
    return new Promise<IResponse<IValidationFilePolitics>>((resolve, reject) => {
      http
        .put(`Objetives/masive-patch-objectives`,  data, config)
        .then((response) => {          
          resolve(response.data);
        })
        .catch((error) => {  
          reject(error.response.data[0]);
        });
    });
  };

  const getAllPolitics = async (query: string) => {
    return new Promise<ApiResponse<IPolitic[]>>((resolve, reject) => {
      http
        .get(`Policies/GetPoliciesScore?IdPeriod=${query}`)
        .then((response) => {
          const { data, meta } = response.data;

          const res = {
            data,
            meta,
          };
          resolve(response.data);
        })
        .catch((error) => {
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  };
  const getAllPoliticsFilter = async (query: string) => {
    return new Promise<ApiResponse<IPolitic[]>>((resolve, reject) => {
      http
        .get(`Policies/GetPoliciesScore${query}`)
        .then((response) => {
          const { data, meta } = response.data;
          // 

          const res = {
            data,
            meta,
          };
          resolve(response.data);
        })
        .catch((error) => {
            
            
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  };



  const updatePolitic = async ( body: PolitcEvaluation) => {

    return new Promise<ApiResponse<PolitcEvaluation[]>>((resolve, reject) => {
      http
        .post(`Policies/UpdateScore`, body)
        .then((response) => {
          
          const { data, meta } = response.data;
          const res = {
            data,
            meta,
          };
          
          resolve(response.data);
        })
        .catch((error) => {
          const dataError = error?.response?.data?.errors;
          reject(dataError);
        });
    });
  };
  export { BulkPoliticsUploadServices, getAllPolitics, getAllPoliticsFilter, updatePolitic, MasivePatchUploadServices };

