import {
    Date,
    Select,
    TextField,
} from "../../../../components/common/InputsByType";
import { useCollaboratorRedux } from "../../../../redux/Collaborators";
import {
    ButtonClean,
    ButtonSelect,
    ContainerFiltersObjetivos,
    OnlyFiltersContainer,
    StyleInputs
} from "../../Objectives/ObjetivesStyles";
import { ContainerButtonsObjetives } from "./FilterDashboardStyles";




const mathExpression = [
    { text: "Selecciona opción", value: "0" },
    { text: "igual", value: "igual" },
    { text: "menor que", value: "menor que" },
    { text: "mayor que", value: "mayor que" },
    { text: "menor igual que", value: "menor igual que" },
    { text: "mayor igual que", value: "mayor igual que" },
];

const FilterIndividualObjetives = ({
    onChangeTextField,
    inputValuesIndividual,
    handleSearchFilters,
    handleClearFilters,
}: any) => {

    //** Redux

    const { evaluationAttribute } = useCollaboratorRedux();

    return (
        <>
            {evaluationAttribute.filter_search_objetives_individuals_in_list === 1 && (
                <ContainerFiltersObjetivos>

                    <div className="tabsStyles">
                        Filtros
                    </div>
                    <OnlyFiltersContainer>
                        <StyleInputs>

                            <TextField
                                title="Concepto KPI"
                                id="conceptoKPI"
                                placeholder="Base de datos de proyecto de regularización"
                                name="KPIFilterIndividual"
                                onChange={onChangeTextField}
                                value={inputValuesIndividual}
                            />
                        </StyleInputs>
                        <StyleInputs>
                            <TextField
                                title="Verbo infinitivo"
                                id="Corporativo"
                                name="verbFilterIndividual"
                                placeholder="Entregar"
                                onChange={onChangeTextField}
                                value={inputValuesIndividual}
                            />
                            <Select
                                id="Expresion"
                                title="Expresión matemática"
                                options={mathExpression}
                                name="mathFilterIndividual"
                                onChange={onChangeTextField}
                                value={inputValuesIndividual}
                            />
                        </StyleInputs>
                        <StyleInputs>

                            <TextField
                                title="Preposición"
                                id="Preposición"
                                placeholder="Al"
                                name="prepositionFilterIndividual"
                                onChange={onChangeTextField}
                                value={inputValuesIndividual}
                            />
                            <TextField
                                title="Indicador"
                                id="Indicador"
                                name="indicatorFilterIndividual"
                                placeholder="100"
                                onChange={onChangeTextField}
                                value={inputValuesIndividual}
                            />
                        </StyleInputs>
                        <StyleInputs>
                            <TextField
                                title="Adverbio"
                                id="Adverbio"
                                name="adverbFilterIndividual"
                                placeholder="El"
                                onChange={onChangeTextField}
                                value={inputValuesIndividual}
                            />
                            <Date
                                id="date"
                                title="Fecha"
                                name="dateFilterIndividual"
                                onChange={onChangeTextField}
                                value={inputValuesIndividual}
                            />
                          
                        </StyleInputs>

                        <ContainerButtonsObjetives>
                            <ButtonClean
                                onClick={(e) => {
                                    handleClearFilters(e, "Individual");
                                }}
                            >
                                Limpiar
                            </ButtonClean>
                            <ButtonSelect
                                onClick={(e) => {
                                    handleSearchFilters(e, "Individual");
                                }}
                            >
                                Buscar
                            </ButtonSelect>
                        </ContainerButtonsObjetives>
                    </OnlyFiltersContainer>
                </ContainerFiltersObjetivos>
            )
            }
        </>
    )
}

export default FilterIndividualObjetives;