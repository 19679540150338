import React, { ReactNode, useState } from "react";
import { getCurrentYearAndSemesterObj } from "../../hooks/Evaluation/useSemesterYearNow";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import {
  getPeriodYearsSemesters,
  getPeriodsByYear,
} from "../../services/dataSelects.service";
import { validateIdPeriod } from "../validacionesFiltros";

export type ChangePeriodContextType = {
  setYears?: any;
  years?: any;
  setSelectedOptionYear?: any;
  selectedOptionYear?: any;
  selectedOptionPeriod?: any;
  setSelectedOptionPeriod?: any;
  evaluationPeriodName?: any;
  setEvaluationPeriodName?: any;
  onChangeOptionYear?: any;
  onChangeOptionSemestre?: any;
  getYearsOptions?: any;
  handleSearchPeriod?: any;
  periodAutoEvaluation?: any;
  changePeriodEvaluation?: any;
  handleValidatePeriod?: any;
  setPeriodAutoEvaluation?: any;
  getPeriods?: any;
  setChangePeriodEvaluation?: any;
  realizedSearch?: any;
  setRealizedSearch?: any;
  totalConocimientoEmpresa?: any;
  setTotalConocimientoEmpresa?: any;
  totalSumaMetricas?: any;
  setTotalSumaMetricas?: any;
  periodoDataInfo?: any;
  setPeriodoDataInfo?: any;
  idActualPeriod?: any;
};

interface MyComponentProps {
  children: ReactNode;
}
const { semestre, year } = getCurrentYearAndSemesterObj();
export const ChangePeriodContext =
  React.createContext<ChangePeriodContextType | null>(null);

const ChangePeriodProvider: React.FC<MyComponentProps> = ({ children }) => {
  //* Redux
  const { User } = useCollaboratorRedux();
  //** States
  const [years, setYears] = useState<any>([]);
  const [selectedOptionYear, setSelectedOptionYear] = useState(0);
  const [selectedOptionPeriod, setSelectedOptionPeriod] = useState("");
  const [evaluationPeriodName, setEvaluationPeriodName] = useState<any>([]);
  const [changePeriodEvaluation, setChangePeriodEvaluation] = useState(false);
  const [periodAutoEvaluation, setPeriodAutoEvaluation] = useState("");


  const [realizedSearch, setRealizedSearch] = useState(1);

  const [totalSumaMetricas, setTotalSumaMetricas] = useState();
  const [totalConocimientoEmpresa, setTotalConocimientoEmpresa] = useState();

  const [periodoDataInfo, setPeriodoDataInfo] = useState("");

  const [idActualPeriod, setIdActualPeriod] = useState(0)

  //! Con esta función se obtienen  los años
  const getYearsOptions = async () => {
    const { data } = await getPeriodYearsSemesters(String(User?.claTrab));

    const yearList = data.evaluationPeriodYear.map((year, index) => ({
      year,
      id: data.evaluationPeriodNumberOfYear[index],
    }));

    yearList.sort((a, b) => a.year - b.year);

    setYears(yearList);
    // console.log(
    //   "<AutoEvaluacion>Aquí obtenemos el año actual -> ",
    //   getCurrentYearAndSemesterObj(),
    // );

    // Creamos una función la cual trae un objeto con anio y semestre
    getPeriods(year, true);
  };

  const getPeriods = async (year: any, action: any) => {
    const { data } = await getPeriodsByYear(year, String(User?.claTrab));

    const array = data.map((datos) => ({
      evaluationPeriodId: datos?.evaluationPeriodId,
      evaluationPeriodNumberOfYear: datos?.evaluationPeriodNumberOfYear,
    }));

    console.log("array", array);

    const firstPeriod = array[0];
    const actualPeriod = array.filter(
      (item: any) => item.evaluationPeriodNumberOfYear === semestre
    );
    setIdActualPeriod(actualPeriod[0]?.evaluationPeriodId)

    const selectedOption = action
      ? `${actualPeriod[0]?.evaluationPeriodId},${actualPeriod[0]?.evaluationPeriodNumberOfYear}`
      : `${firstPeriod?.evaluationPeriodId},${firstPeriod?.evaluationPeriodNumberOfYear}`;

    // console.log("<AutoEvaluacion>", selectedOption, array, actualPeriod);
    setSelectedOptionPeriod(selectedOption);

    //todo Validar que estemos en el periodo actual
    const periodo = validateIdPeriod(selectedOption);

    setPeriodAutoEvaluation(periodo);
    setEvaluationPeriodName(array);
  };

  const onChangeOptionYear = async (e: any) => {
    const { value } = e.target;
    if (value !== "") {
      // console.log("valor del año", value);

      setSelectedOptionYear(Number(value));
      getPeriods(Number(value), false);
    }
  };

  const onChangeOptionSemestre = async (e: any) => {
    const { value } = e.target;
    // console.log("valor que entra al onChange", value);

    if (value !== "") {
      setSelectedOptionPeriod(value);

      //todo Validar que estemos en el periodo actual
      const periodo = validateIdPeriod(value);
      setPeriodAutoEvaluation(periodo);
    }
  };

  const handleValidatePeriod = (selectedPeriod: string) => {
    const periodo = validateIdPeriod(selectedPeriod);
    // setPeriodAutoEvaluation(periodo)
    setRealizedSearch(realizedSearch + 1);

    if (idActualPeriod !== Number(periodo)) {
      setChangePeriodEvaluation(false);
    } else {
      setChangePeriodEvaluation(true);
    }
  };

  console.log("Periodo actual", User.claTrab);

  return (
    <ChangePeriodContext.Provider
      value={{
        selectedOptionYear,
        years,
        selectedOptionPeriod,
        evaluationPeriodName,
        periodAutoEvaluation,
        changePeriodEvaluation,
        setChangePeriodEvaluation,
        setEvaluationPeriodName,
        setSelectedOptionPeriod,
        setYears,
        setPeriodAutoEvaluation,
        idActualPeriod,
        realizedSearch,
        setRealizedSearch,
        periodoDataInfo,
        setPeriodoDataInfo,

        onChangeOptionYear,
        onChangeOptionSemestre,
        handleValidatePeriod,
        // handleSearchPeriod,

        setSelectedOptionYear,
        getYearsOptions,
        getPeriods,

        totalConocimientoEmpresa,
        setTotalConocimientoEmpresa,

        setTotalSumaMetricas,
        totalSumaMetricas,
      }}
    >
      {children}
    </ChangePeriodContext.Provider>
  );
};

export default ChangePeriodProvider;
