import { FC, lazy, LazyExoticComponent } from 'react';

interface ActionEvent<T, P> {
  type: T;
  payload?: P;
}

const actionEvent = <T, P>({ type, payload }: ActionEvent<T, P>): ActionEvent<T, P> => ({
  type,
  payload,
});

const lazyComponent = (
  name: string,
  importer: Promise<Record<string, FC>>
): LazyExoticComponent<FC> =>
  lazy(async () => {
    const component = await importer;
    return { default: component[name] };
  });

const encrypt = (text: string): string => window.btoa(unescape(encodeURIComponent(text)));

const decrypt = (text: string): string => decodeURIComponent(escape(window.atob(text)));

const copyText = (text: string): void => {
  const aux: HTMLInputElement = document.createElement('input');
  aux.setAttribute('value', text);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
};

const validateInputText = (event: any) => {
  if (event.keyCode !== 8 && event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40 && event.keyCode !== 46) {
    const regex = new RegExp('^[a-zA-ZñÑ]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
};

const validateInputNumber = (event: any) => {
  if (event.keyCode !== 8 && event.keyCode !== 37 && event.keyCode !== 38 && event.keyCode !== 39 && event.keyCode !== 40 && event.keyCode !== 46) {
    const regex = new RegExp('[0-9]');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }

};

// eslint-disable-next-line arrow-body-style
const validarEmail = (email: string) => {
  return email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/);
};

const years = () => {
  const currentYear = new Date().getFullYear();
  const yearsArray = [];
  yearsArray.push({ text: 'selecciona un año', value: '0' });
  yearsArray.push({
    text: `${currentYear - 1}`,
    value: `${currentYear - 1}`
  });
  for (let i = 0; i < 6; i++) {
    yearsArray.push({
      text: `${currentYear + i}`,
      value: `${currentYear + i}`
    });
  }
  return yearsArray;
};

const isTest: boolean = process.env.NODE_ENV === 'test';
const isProd: boolean = process.env.NODE_ENV === 'production';


const evaluationNullPolitics = null



export {
  actionEvent, copyText, decrypt, encrypt, evaluationNullPolitics, isProd, isTest, lazyComponent, validarEmail,
  validateInputNumber, validateInputText, years
};

