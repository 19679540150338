import { useEffect, useState } from "react";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";

export const useSaveButton = () => {
  //** Redux
  const { User, evaluationAttribute } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();
  //**  Redux
  //  const { Collaborator, evaluationAttribute, User } = useCollaboratorRedux();

  //** States
  const [activeSave, setActiveSave] = useState(false);

  const ActiveSaveButton = () => {
    // console.log(
    //   "Hola, se activa activeSave",
    //   Evaluation?.catStatusEvaluationId,
    //   User.role.toLocaleLowerCase(),
    //   evaluationAttribute,
    //   Evaluation
    // );
    //**  Si el estatus de la evaluación es === 7 habilitaremos el botón de guardar, sólo si es igual a uno de estos roles

    // if (
    //   Evaluation?.catStatusEvaluationId === 7 &&
    //   (User.role.toLocaleLowerCase() === "admin ti" ||
    //     User.role.toLocaleLowerCase() === "admin" ||
    //     User.role.toLocaleLowerCase() === "admin rh")
    // ) {
    //   setActiveSave(false);
    // }

    // if (
    //   Evaluation?.catStatusEvaluationId >= 2 &&
    //   Evaluation?.catStatusEvaluationId <= 5
    // ) {
    //   setActiveSave(false);
    // } else {
    //   setActiveSave(true);
    // }

    // if (
    //   Evaluation?.catStatusEvaluationId === 7 &&
    //   evaluationAttribute?.edit_evaluation_complete === 1
    // ) {
    //   setActiveSave(false);
    // } else {
    //   setActiveSave(true);
    // }

    const isAdmin =
      User.role.toLocaleLowerCase() === "admin ti" ||
      User.role.toLocaleLowerCase() === "admin" ||
      User.role.toLocaleLowerCase() === "admin rh";

    if (Evaluation?.catStatusEvaluationId === 7 && isAdmin) {
      setActiveSave(false);
    } else if (
      Evaluation?.catStatusEvaluationId >= 2 &&
      Evaluation?.catStatusEvaluationId <= 5
    ) {
      setActiveSave(false);
    } else if (
      Evaluation?.catStatusEvaluationId === 7 &&
      evaluationAttribute?.edit_evaluation_complete === 1
    ) {
      setActiveSave(false);
    } else {
      setActiveSave(true);
    }
  };

  useEffect(() => {
    // console.log("Hola, se activa activeSave", activeSave);
    ActiveSaveButton();
  }, [User, activeSave, evaluationAttribute, Evaluation]);

  return {
    ActiveSaveButton,
    setActiveSave,
    activeSave,
  };
};
