/* eslint-disable no-unused-vars */
import styled from "styled-components";
export const carrusel = {
  padding: "32px 10px",
};
export const ButtonsCarrusel = {
  justifyContent: "center",
};
export const ButtonsCarruselMobile = {
  justifyContent: "center",
  display: "contents",
  marginBottom: "17px",

};
const size = {
  mobile: "320px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "2560px",
}
export const StyleButonM = {
  background: "#3B63A2",
  borderRadius: "8px",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "120%",
  color: "#FFFFF",
  cursor: "pointer",
  width: '-webkit-fill-available',
  margin: '0.5rem',
};
export const StyleButon = {
  padding: "8px 24px",
  background: "#C9F2FF",
  borderRadius: "8px",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "120%",
  color: "#3B63A2",
  borderStyle: "none",
  cursor: "pointer",
};
export const StyleButonB = {
  padding: "7.1px 24px",
  background: "#3B63A2",
  borderRadius: "8px",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "120%",
  color: "#FFF",
  cursor: "pointer",

};
export const StyleButonBM = {
  padding: "8px 24px",
  gap: "8px",
  background: "#C1C1C3",
  borderRadius: "8px",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "120%",
  color: "#F0F0F0",
  borderStyle: "none",
  cursor: "pointer",
  width: '-webkit-fill-available',
  margin: '0.5rem',
};
export const StyleButonCerrar = {
  background: "#C9F2FF",
  borderRadius: "8px",
  color: "#3B63A2",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "120%",
  borderStyle: "none",
  cursor: "pointer",
};

export const GlobalFont = styled.h1`
  font-family: 'Plus Jakarta Sans', sans-serif;
`;
export const GlobalFontDos = styled.div`

  /* height: 20px; */
  /* width: 20px;/ */
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* margin-top: 6px; */
  font-family: 'Plus Jakarta Sans', sans-serif;
`;

export const TitleModal = styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #1f2a40;
`;
export const HeaderSlice = styled.h2`
  font-weight: 900;
  font-size: 23.1523px;
  line-height: 110%;
  text-align: center;
  color: #3b63a2;
  margin-bottom: 3%;
`;
export const HeaderSlice2 = styled.h2`
  font-weight: 900;
  font-size: 23.1523px;
  line-height: 110%;
  text-align: center;
  color: #3b63a2;
  margin-bottom: 2%;
`;
export const SubtitleSlice = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #3b63a2;
`;
export const Text = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  color: #3b63a2;
`;
export const ContentSlice = styled.div`
  display: block;
  width: 100%;
  align-content: center;
  padding: 32px 24px;
  gap: 24px;
  @media (min-width: 320px) and (max-width: 1023px) {
    padding: 2px 4px;
  }
`;
export const ContentTextSlice2 = styled.div`
  left: 0px;
  top: 0px;
  box-sizing: border-box;
  width: 100%;
  /* height: auto; */
  padding: 32px 24px 32px;
  gap: 24px;
  background: #107e6f;
  border: 3.22456px solid #3b63a2;
  border-radius: 12.8982px;
  position: relative;
  @media (min-width: 320px) and (max-width: 1023px) {
    padding: 32px 32px 22px;
    margin-bottom: 8%;
    margin-top: 6%;
  }
`;
export const TextSlice2 = styled.p`
  /* font-weight: 400;
  font-size: 14px; */
  /* line-height: 110%; */
  text-align: center;
  color: #ffffff;
  padding: 0 4rem;
  position: relative;
  @media (min-width: 320px) and (max-width: 1023px) {
    padding: 0 0rem;

  }
`;
export const Image = styled.img`
  left: -20px;
  height: auto;
  top: 60px;
  width: 10%;
  position: absolute;
  padding: 2px 4px;
  background: #3b63a2;
  border-radius: 9.85795px;
  /* @media (width: 320px) {
    width: 17%;
    margin-top: 5%;
    margin-right: 5%;
  } */

  @media (min-width: 320px) and (max-width: 768px) {
    width: 15%;
    margin-top: 6%;
    margin-left: 5%;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 10%;
    margin-top: 1%;
    margin-right: 6%;
  }
`;
export const ContentSmart = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0px;
  gap: 8px;
  @media (min-width: 320px) and (max-width: 1023px) {
    width: 100%;
  }
`;
export const SubtitleSlice3 = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  text-align: center;
  color: #3b63a2;
  z-index: 1000;
  @media (min-width: 320px) and (max-width: 376px) {
    font-size: 5.4533px;
  }
  @media (min-width: 377px) and (max-width: 769px) {
    font-size: 7.3533px;
  }
  @media (min-width: 770px) and (max-width: 1023px) {
    font-size: 15.3533px;
  }
`;
export const ContentGrid = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  @media (min-width: 320px) and (max-width: 376px) {
    width: 10%;
  }
  @media (min-width: 377px) and (max-width: 769px) {
    width: 15%;
  }
`;

export const Smart = styled.p`
  font-weight: 700;
  font-size: 107.682px;
  line-height: 110%;
  text-align: center;
  color: #3b63a2;
  @media (min-width: 320px) and (max-width: 376px) {
    font-size: 20.682px;
  }
  @media (min-width: 377px) and (max-width: 424px) {
    font-size: 30.682px;
  }
  @media (min-width: 425px) and (max-width: 1023px) {
    font-size: 50.682px;
  }
`;
export const ContentTextSlice3 = styled.p`
  display: grid;
  grid-template-rows: 1fr 4fr 1fr;
  left: 0px;
  top: 0px;
  background: #3b63a2;
  padding: 10px;
  height: 55%;
  @media (min-width: 320px) and (max-width: 376px) {
    height: 78%;
    padding: 5px;
  }
  @media (min-width: 377px) and (max-width: 769px) {
    height: 77%;
  }
  @media (min-width: 770px) and (max-width: 1023px) {
    height: 60%;
  }
`;
export const TextSlice3 = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 110%;
  color: #ffffff;
  @media (min-width: 320px) and (max-width: 376px) {
    font-size: 7.5px;
  }
  @media (min-width: 377px) and (max-width: 769px) {
    font-size: 6px;
  }
`;
export const TitleTextSlice3 = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  @media (min-width: 320px) and (max-width: 376px) {
    font-size: 6px;
  }
`;
export const Question = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 110%;
  text-align: center;
  color: #ffffff;
`;
export const ContenTitle = styled.p`
  display: flex;
  gap: 14px;
`;
export const ContentTitleModal = styled.div`
  display: flex;
  gap: 14px;
`;
export const ImageModal = styled.img`
left: 4.33%;
right: 88.67%;
top: 17%;
bottom: 64%;
`;
export const TitleCaution = {
  width: '158px',
  height: '38px',
  left: '100.19px',
  top: '39.61px',
  fontStyle: ' normal',
  fontWeight: '700',
  fontSize: '32px',
  lineHeight: '120%',
  color: '#F27405',
};
export const TitleListo = {
  width: '158px',
  height: '38px',
  left: '100.19px',
  top: '39.61px',
  fontStyle: ' normal',
  fontWeight: '700',
  fontSize: '32px',
  lineHeight: '120%',
  color: '#22BB55',
};
export const TitleError = {
  width: '158px',
  height: '38px',
  left: '100.19px',
  top: '39.61px',
  fontStyle: ' normal',
  fontWeight: '700',
  fontSize: '28px',
  lineHeight: '120%',
  color: '#EE4444',
};
export const TitleErrorLenghtMax = {
  width: 'auto',
  height: '38px',
  left: '100.19px',
  top: '39.61px',
  fontStyle: ' normal',
  fontWeight: '700',
  fontSize: '28px',
  lineHeight: '120%',
  color: '#EE4444',
};
export const DeleteHr = {
  borderBottom: 'none',
};
export const DeleteHrTop = {
  borderTop: 'none',
};
export const ContainMobileInfoButtons = styled.div`
  display: flex;
  @media (min-width: 320px) and (max-width: 758px) {
    flex-direction: column;
  }
`;
export const ContainMobileButon = styled.div`
margin-left:1%;
  @media (min-width: 320px) and (max-width: 758px) {
    margin-top:1%;

  }
`;