import { useContext, useEffect, useState } from "react";
import { useUrlPermisos } from ".";
import {
  ChangePeriodContext,
  ChangePeriodContextType,
} from "../../context/ChangePeriodAutoEvaluationContext/ChangePeriodAutoEvaluation";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../context/EvaluationContext";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";

export const useAbleFirmsBtn = () => {
  //** Context
  const { changePeriodEvaluation } = useContext(
    ChangePeriodContext
  ) as ChangePeriodContextType;
  //* Context
  const { disableActionEvaluationBtn } = useContext(
    EvaluationContext
  ) as EvaluationContextType;
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();
  const { Evaluation } = useEvaluationRedux();

  const { urlPermisos } = useUrlPermisos();
  //** Hooks

  //** States
  const [ableFirms, setAbleFirms] = useState(true);

  //** Esta es una evaluación (NO ES MI EVALUACIÓN)

  // console.log(
  //   "Estás en tu periodo actual!",
  //   !disableActionEvaluationBtn &&
  //     Evaluation.catStatusEvaluationId !== 7 &&
  //     evaluationAttribute.edit_evaluation_complete !== 1
  // );

  // console.log("Atributos", User.role);

  useEffect(() => {
    if (User.role === "Auditor") {
      setAbleFirms(false); //Inhabilita
    }

    if (User.role !== "Auditor" && User.role !== "Evaluado") {
      //**  +++++++++++++++++++++++++++   EVALUACIÓN +++++++++++++++++++++++
      if (!urlPermisos) {
        if (!disableActionEvaluationBtn) {
          if (
            Evaluation?.catStatusEvaluationId === 7 &&
            evaluationAttribute?.edit_evaluation_complete === 1
          ) {
            setAbleFirms(true);
          }

          if (
            Evaluation?.catStatusEvaluationId === 7 &&
            evaluationAttribute?.edit_evaluation_complete !== 1
          ) {
            setAbleFirms(false);
          }

          if (Evaluation?.catStatusEvaluationId !== 7) {
            setAbleFirms(true); // Habilita
          } else {
            setAbleFirms(false); //Inhabilita
          }
        }
      }

      if (urlPermisos) {
        if (changePeriodEvaluation) {
          if (!disableActionEvaluationBtn) {
            if (
              Evaluation?.catStatusEvaluationId !== 7 &&
              evaluationAttribute?.edit_evaluation_complete !== 1
            ) {
              setAbleFirms(true); // Habilita
            }
          } else {
            setAbleFirms(false); //Inhabilita
          }
        } else {
          setAbleFirms(false); //Inhabilita
        }
      }
    }

    if (User.role === "Evaluado") {
      if (urlPermisos) {
        if (changePeriodEvaluation) {
          if (!disableActionEvaluationBtn) {
            if (
              Evaluation?.catStatusEvaluationId !== 7 &&
              evaluationAttribute?.edit_evaluation_complete !== 1
            ) {
              setAbleFirms(true); // Habilita
            }

            if (Evaluation?.catStatusEvaluationId === 7) {
              setAbleFirms(false); //Inhabilita
            }
          } else {
            setAbleFirms(false); //Inhabilita
          }
        } else {
          setAbleFirms(false); //Inhabilita
        }
      }
    }

    // function shouldAbleFirms() {
    //   if (User.role === "Auditor") return false;

    //   // Cuando urlPermisos es verdadero
    //   if (urlPermisos) {
    //     // Si se cambia el período de evaluación pero el botón está deshabilitado, deshabilitar firmas
    //     if (changePeriodEvaluation) {
    //       return !disableActionEvaluationBtn &&
    //              Evaluation?.catStatusEvaluationId !== 7 &&
    //              evaluationAttribute?.edit_evaluation_complete !== 1;
    //     }
    //     // Si no se cambia el período de evaluación, deshabilitar firmas
    //     return false;
    //   }

    //   // Cuando urlPermisos es falso o indefinido
    //   if (!disableActionEvaluationBtn) {
    //     if (Evaluation?.catStatusEvaluationId === 7) {
    //       return evaluationAttribute?.edit_evaluation_complete === 1;
    //     }
    //     // Si el estado de la evaluación no es 7, habilita firmas
    //     return true;
    //   }

    //   // Si el botón de acción está deshabilitado, deshabilita firmas
    //   return false;
    // }

    // setAbleFirms(shouldAbleFirms());
  }, [
    Evaluation?.catStatusEvaluationId,
    urlPermisos,
    evaluationAttribute.edit_column_score,
    evaluationAttribute?.edit_evaluation_complete,
    changePeriodEvaluation,
    disableActionEvaluationBtn,
    User.role,
  ]);

  return {
    ableFirms,
  };
};
