import styled from "styled-components";

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const MainContainer = styled.div`
  display: flex;
  padding: 32px;
  width: 640px;
  flex-direction: column;
  /* gap: 32px; */
  border-radius: 18px;
  background: #fff;
  /* border: 1px solid red; */
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;

  .containerTitleImg {
    display: flex;
    gap: 8px;
  }
`;

export const Title = styled.h2`
  color: var(--primary-true-blue, #3b63a2);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
  margin: 0;
`;

export const Subtitle = styled.div`
  color: var(--neutrals-jet, #333335);
  font-feature-settings: "clig" off, "liga" off;

  /* Body */
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  margin: 0;
  margin-bottom: 16px;
`;

export const StepModal = styled.p`
  color: var(--primary-true-blue, #3b63a2);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
  margin: 0px;
  margin-top: 4px;
`;

export const SelectsMainContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

export const EstatusText = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  height: 34px;
  border: 1px solid var(--background-light-gray, #f8f8f8);
  background: var(--neutrals-white, #fff);
`;

export const SelectModal = styled.select`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 0px 8px 8px 0px;
  background: var(--background-light-gray, #f8f8f8);
  font-size: 12px;
  border: 1px solid #f8f8f8;
  outline: none;
`;

export const InputModal = styled.input`
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 0px 8px 8px 0px;
  background: var(--background-light-gray, #f8f8f8);
  font-size: 12px;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonModal = styled.button`
  display: flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border-width: 1px;
  border-color: #3b63a2;
  border-style: solid;
  background: var(--primary-true-blue, #3b63a2);
  color: var(--neutrals-white, #fff);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;

export const TagContainer = styled.div`
  display: flex;
  //styleName: Data;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  padding: 3px 10px;
  border-radius: 16px;
  /* font: ; */
  background-color: #c9f2ff;
  color: #3b63a2;
  gap: 4px;

  .close {
    cursor: pointer;
  }
`;

export const TagsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  justify-content: center;
  gap: 8px;
  justify-items: center;

  
`;
