import { Button, Modal } from "react-bootstrap";
import { TitleModal, TitleContainer } from "../../../components/atoms/EvaluationForms/EvaluationFormsStyles";
import { ButtonStyled, FirmsBox, titleStyles, buttonCancelar, buttonConfirmar , ContainerButtons, mainContainerStyles} from "./FirmsStyles";
//assets
import info from '../../../assets/infoYellow.svg'

export const ButtonActions = ({
  activeSave,
  imageButton,
  txtButton,
  marginBtm,
  handleAction,
}: any) => {
  return (
    <ButtonStyled

      marginBtm={marginBtm}
      disabled={activeSave}
      onClick={handleAction}
    >
      <FirmsBox colorBtn={activeSave}>
        <img style={{ height: '40px', width: '40px' }} src={imageButton} alt={txtButton} />
        {txtButton}
      </FirmsBox>
    </ButtonStyled>
  );
};

export const ModalConfirmEvaluation = ({
  show,
  handleClose,
  handleFinishFirm,
}: any) => {



  return (
    <Modal  show={show} onHide={handleClose} backdrop="static">
      <div style={{padding:'32px'}} >

        <TitleContainer>
        <img src={info} alt=""/>
        <TitleModal>Confirmación de firma</TitleModal>
        </TitleContainer>    

      <Modal.Body>
        <p style={titleStyles}> Al firmar esta evaluación, estás confirmando que has revisado y aceptado los resultados presentados. Esta acción es irreversible.  </p>
        <p style={titleStyles}> ¿Estás seguro de proceder? </p>
      </Modal.Body>
      <ContainerButtons>
        <Button style={buttonCancelar} onClick={handleClose}>
          Cancelar
        </Button>
        <Button style={buttonConfirmar} onClick={handleFinishFirm}>
        Confirmar firma
        </Button>
      </ContainerButtons>
      </div>
    </Modal>
  );
};

