import { useEffect, useState } from "react";
import ModalChangeEstatus from "../../components/ModalChangeEstatus";
import { StatusDashboardSelect } from "../stylesDashboard/NewStylesDashboard";

const StatusTableDashboardUsersSelect = ({ item }: any) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [roleUser, setRoleUser] = useState<any>();

  const validateRole = (role: any) => {
    setRoleUser(role);
  };

  useEffect(() => {
    validateRole(item.employeeEvaluation.catStatusEvaluation.name);
    // console.log(
    //   "item.employeeEvaluation.catStatusEvaluation.name",
    //   item.employeeEvaluation.catStatusEvaluation.name,
    //   item
    // );
  }, [item]);

  return (
    <>
      <StatusDashboardSelect
        value={roleUser}
        onChange={(event) => {
          setSelectedOption(event.target.value);
          // console.log("event.target.value", event.target.value);
          setShowModal(true);
        }}
      >
        {item.demanda ? (
          <option hidden style={{ color: "gray" }}>
            Inactivo
          </option>
        ) : (
          <>
            {item.statusDisponibles.map((option: any, optionIndex: any) => {
              // console.log("option", option);
              if (option.descripcion === "Pendiente agendar") {
                return null;
              } else {
                return (
                  <option
                    key={optionIndex}
                    value={option.descripcion}
                    selected={option.descripcion}
                  >
                    {option.descripcion}
                  </option>
                );
              }
            })}
          </>
        )}
      </StatusDashboardSelect>
      {showModal && (
        <ModalChangeEstatus
          item={item}
          isModalVisible={showModal}
          setIsModalVisible={setShowModal}
          selectedOption={selectedOption}
          setRoleUser={setRoleUser}
        />
      )}
    </>
  );
};

export default StatusTableDashboardUsersSelect;
