import { useContext } from "react";
import editObj from "../../../assets/icons/EditObj.svg";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { useEditFutureObjetive } from "../../../hooks/Evaluation";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { useEvaluationRedux } from "../../../redux/Evaluations";
import {
  EditContainer,
  EditObjectiveContainer,
} from "../Dashboard/stylesDashboard/AdminStyles";

const EditObjetiveFutures = ({
  setEditObjective,
  index,
  data,
  setIndexEdit,
}: any) => {
  //** Redux
  const { evaluationAttribute, User } = useCollaboratorRedux();

  // ** Context
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;
  const { editeFutureObjetive } = useEditFutureObjetive();
  const { Evaluation } = useEvaluationRedux();
  const handleClick = () => {
    setEditObjective(true);
    setIndexEdit(index);
  };

  return (
    <EditContainer>
      <EditObjectiveContainer>
        {data.length > 0 && data[index]?.descripcion?.label}
      </EditObjectiveContainer>

      {!editeFutureObjetive && (
        <div style={{ cursor: "pointer" }} onClick={() => handleClick()}>
          <img
            src={editObj}
            alt="editObjetive"
            style={{ height: "12px", width: "12px" }}
          />
        </div>
      )}
    </EditContainer>
  );
};

export default EditObjetiveFutures;
