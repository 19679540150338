/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IGeneralObjetives,
    IGeneralObjetivesFilter,
    IIndividualObjetives,
    IIndividualObjetivesFilter,
    IMeta,
    PeriodYearsSemesters,
} from "../Interface/ApiInterface";
import { actionEvent } from "../common/utils";
import {
    deleteGeneralObjetivesUploadServices,
    getAllGeneralObjetivesServices,
    getAllIndividualObjetivesServices,
    getPeriodYearsSemesters,
    postGeneralObjetivesUploadServices,
    postIndividualObjetivesUploadServices,
    putGeneralObjetivesUploadServices,
    setIndividualObjetivesServices,
} from "../services/objetives.services";
import { RootReducerInterface } from "./store";
import { useLoader } from "../context/LoaderContext";

enum ObjetivesActions {
  GET_ALL_GENERAL_OBJETIVES = "GET_ALL_GENERAL_OBJETIVES",
  GET_ALL_GENERAL_OBJETIVES_ERROR = "GET_ALL_GENERAL_OBJETIVES_ERROR",
  GET_ALL_INDIVIDUAL_OBJETIVES = "GET_ALL_INDIVIDUAL_OBJETIVES",
  GET_ALL_INDIVIDUAL_OBJETIVES_ERROR = "GET_ALL_INDIVIDUAL_OBJETIVES_ERROR",
  GET_PERIOD_YEARS_SEMESTERS = "GET_PERIOD_YEARS_SEMESTERS",
  GET_PERIOD_YEARS_SEMESTERS_ERROR = "GET_PERIOD_YEARS_SEMESTERS_ERROR",
  CREATE_NEW_GENERAL_OBJETIVES = "CREATE_NEW_GENERAL_OBJETIVES",
  CREATE_NEW_GENERAL_OBJETIVES_ERROR = "CREATE_NEW_GENERAL_OBJETIVES_ERROR",
  CREATE_NEW_INDIVIDUAL_OBJETIVE = "CREATE_NEW_INDIVIDUAL_OBJETIVE",
  CREATE_NEW_INDIVIDUAL_OBJETIVE_ERROR = "CREATE_NEW_INDIVIDUAL_OBJETIVE_ERROR",
  UPDATE_GENERAL_OBJETIVES_SUCCESS = "UPDATE_GENERAL_OBJETIVES",
  UPDATE_GENERAL_OBJETIVES_ERROR = "UPDATE_GENERAL_OBJETIVES_ERROR",
  DELETE_GENERAL_OBJETIVES_SUCCESS = "DELETE_GENERAL_OBJETIVES_SUCCESS",
  DELETE_GENERAL_OBJETIVES_ERROR = "DELETE_GENERAL_OBJETIVES_ERROR",
  SET_ERROR_MSG = "SET_ERROR_MSG",
  SET_ERROR_MSG_GCGO = "SET_ERROR_MSG_GCGO",
  SET_ERROR_MSG_GCIO = "SET_ERROR_MSG_GCIO",
  SET_FILTER_GENERAL = "SET_FILTER_GENERAL",
  SET_FILTER_INDIVIDUAL = "SET_FILTER_INDIVIDUAL",
  SET_EDIT_OBJETIVE = "SET_EDIT_OBJETIVE",
}

interface errorType {
  error: any;
  type: string;
}

interface IObjetivesBaseInterface {
  generalObjetives: IGeneralObjetives[];
  individualObjetives: IIndividualObjetives[];
  yearsSemesters: PeriodYearsSemesters;
  loading: Boolean;
  errorMsg: errorType | undefined;
  MetaIndividual: IMeta;
  MetaGeneral: IMeta;
  FilterIndividual: IIndividualObjetivesFilter;
  FilterGeneral: IGeneralObjetivesFilter;
  EditObjetive:
    | IIndividualObjetives
    | IGeneralObjetivesFilter
    | IGeneralObjetives
    | any;
  setSuccesPost: (succes: boolean) => void;
  succesPost: boolean;
  errorPost: boolean;
  setErrorPost: (error: boolean) => void;
}

interface UseObjetivesInterface extends IObjetivesBaseInterface {
  handleGetAllIndividualObjetives: (query: string) => void;
  handleGetPeriodYearsSemesters: () => void;
  handleGetAllGeneralObjetives: (query: string) => void;
  handleCreateGeneralObjetives: (body: IGeneralObjetives) => void;
  handleCreateIndividualObjetives: (body: IIndividualObjetives) => void;
  handleUpdateGeneralObjetives: (
    id: number,
    body: IGeneralObjetives | IIndividualObjetives,
    period: number
  ) => void;
  handleDeleteGeneralObjetives: (id: number) => void;
  handleDelteError: () => void;
  handleSetIndividualFilter: (filters: IIndividualObjetivesFilter) => void;
  handleSetGeneralFilter: (filters: IGeneralObjetivesFilter) => void;
  handleSetEditObjetive: (
    objetive: IIndividualObjetives | IGeneralObjetivesFilter | string
  ) => void;
  handleSetIndividualObjetives: (id: number, objetivos: any) => void;
}

interface ReducerActionsInterface {
  type: ObjetivesActions;
  payload: IObjetivesBaseInterface;
}

const useObjetives = (): UseObjetivesInterface => {
  const [loading, setLoading] = useState(false);
  const [errorPost, setErrorPost] = useState(false);
  const [succesPost, setSuccesPost] = useState(false);
  const { incrementRequests, decrementRequests } = useLoader();

  const dispatch = useDispatch();

  const {
    EditObjetive,
    generalObjetives,
    individualObjetives,
    yearsSemesters,
    errorMsg,
    MetaGeneral,
    MetaIndividual,
    FilterGeneral,
    FilterIndividual,
  } = useSelector((state: RootReducerInterface) => state.Objetives);

  const handleDelteError = () => {
    dispatch(
      actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
        type: ObjetivesActions.SET_ERROR_MSG,
        payload: {
          loading,
          generalObjetives,
          individualObjetives,
          yearsSemesters,
          errorMsg: undefined,
          MetaIndividual,
          MetaGeneral,
          FilterGeneral,
          FilterIndividual,
          EditObjetive,
          setSuccesPost,
          succesPost,
          errorPost,
          setErrorPost,
        },
      })
    );
  };

  const handleSetIndividualObjetives = async (id: number, objetivos: any) => {
    try {
      setLoading(true);
      incrementRequests();
      const { data, meta } = await setIndividualObjetivesServices(
        id,
        objetivos
      );

      // console.log("<SE SETEA DESDE ACÁ>", data);

      setLoading(false);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.GET_ALL_INDIVIDUAL_OBJETIVES,
          payload: {
            loading,
            generalObjetives,
            individualObjetives,
            yearsSemesters,
            errorMsg,
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } catch (error) {
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_ERROR_MSG,
          payload: {
            loading,
            individualObjetives,
            generalObjetives,
            yearsSemesters,
            errorMsg: {
              error,
              type: "GOIO",
            },
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleSetEditObjetive = async (
    objetive: IIndividualObjetives | IGeneralObjetivesFilter | string
  ) => {
    if (objetive !== "undefined") {
      await dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_EDIT_OBJETIVE,
          payload: {
            loading,
            generalObjetives,
            individualObjetives,
            yearsSemesters,
            errorMsg: undefined,
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive: objetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } else {
      await dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_EDIT_OBJETIVE,
          payload: {
            loading,
            generalObjetives,
            individualObjetives,
            yearsSemesters,
            errorMsg: undefined,
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive: "undefined",
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    }
  };

  const handleSetGeneralFilter = async (filter: IGeneralObjetivesFilter) => {
    dispatch(
      actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
        type: ObjetivesActions.SET_FILTER_GENERAL,
        payload: {
          loading,
          generalObjetives,
          individualObjetives,
          yearsSemesters,
          errorMsg: undefined,
          MetaIndividual,
          MetaGeneral,
          FilterGeneral: filter,
          FilterIndividual,
          EditObjetive,
          setSuccesPost,
          succesPost,
          errorPost,
          setErrorPost,
        },
      })
    );
  };

  const handleSetIndividualFilter = async (
    filter: IIndividualObjetivesFilter
  ) => {
    dispatch(
      actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
        type: ObjetivesActions.SET_FILTER_INDIVIDUAL,
        payload: {
          loading,
          generalObjetives,
          individualObjetives,
          yearsSemesters,
          errorMsg: undefined,
          MetaIndividual,
          MetaGeneral,
          FilterGeneral,
          FilterIndividual: filter,
          EditObjetive,
          setSuccesPost,
          succesPost,
          errorPost,
          setErrorPost,
        },
      })
    );
  };

  const handleGetAllIndividualObjetives = async (query: string) => {
    try {
      setLoading(true);
      incrementRequests();

      const { data, meta } = await getAllIndividualObjetivesServices(query);
      setLoading(false);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.GET_ALL_INDIVIDUAL_OBJETIVES,
          payload: {
            loading,
            generalObjetives,
            individualObjetives: data,
            yearsSemesters,
            errorMsg: undefined,
            MetaIndividual: meta,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } catch (error) {
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_ERROR_MSG,
          payload: {
            loading,
            individualObjetives,
            generalObjetives,
            yearsSemesters,
            errorMsg: {
              error,
              type: "GOIO",
            },
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleGetPeriodYearsSemesters = async () => {
    try {
      setLoading(true);
      incrementRequests();
      const { data, meta } = await getPeriodYearsSemesters();
      //

      setLoading(false);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.GET_PERIOD_YEARS_SEMESTERS,
          payload: {
            loading,
            generalObjetives,
            individualObjetives,
            yearsSemesters: data,
            errorMsg: undefined,
            MetaIndividual: meta,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } catch (error) {
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_ERROR_MSG,
          payload: {
            loading,
            individualObjetives,
            generalObjetives,
            yearsSemesters,
            errorMsg: {
              error,
              type: "GOIO",
            },
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleGetAllGeneralObjetives = async (query: string) => {
    try {
      setLoading(true);
      incrementRequests();

      const { data, meta } = await getAllGeneralObjetivesServices(query);
      // console.log("Esto se manda: ",query);

      setLoading(false);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.GET_ALL_GENERAL_OBJETIVES,
          payload: {
            loading,
            individualObjetives,
            generalObjetives: data,
            yearsSemesters,
            errorMsg: undefined,
            MetaIndividual,
            MetaGeneral: meta,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } catch (error) {
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_ERROR_MSG,
          payload: {
            loading,
            individualObjetives,
            generalObjetives,
            yearsSemesters,
            errorMsg: {
              error,
              type: "GOGO",
            },
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };
  const handleCreateGeneralObjetives = async (body: IGeneralObjetives) => {
    try {
      setLoading(true);
      incrementRequests();
      body.generalObjetivesDate = body.fGeneralObjetivesDate || "";
      body.fGeneralObjetivesDate = "";

      await postGeneralObjetivesUploadServices(body);
      setSuccesPost(true);

      setLoading(false);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.CREATE_NEW_GENERAL_OBJETIVES,
        })
      );
    } catch (error) {
      //
      setErrorPost(true);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_ERROR_MSG_GCGO,
          payload: {
            loading,
            individualObjetives,
            generalObjetives,
            yearsSemesters,
            errorMsg: {
              error,
              type: "GCGO",
            },
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleCreateIndividualObjetives = async (
    body: IIndividualObjetives
  ) => {
    try {
      setLoading(true);
      incrementRequests();
      body.generalObjetivesDate = body.fGeneralObjetivesDate;
      body.fGeneralObjetivesDate = "";
      //

      await postIndividualObjetivesUploadServices(body);
      setSuccesPost(true);
      setLoading(false);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.CREATE_NEW_GENERAL_OBJETIVES,
        })
      );
    } catch (error) {
      setErrorPost(true);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_ERROR_MSG_GCIO,
          payload: {
            loading,
            individualObjetives,
            generalObjetives,
            yearsSemesters,
            errorMsg: {
              error,
              type: "GCIO",
            },
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleUpdateGeneralObjetives = async (
    id: number,
    body: IGeneralObjetives | IIndividualObjetives,
    period: any
  ) => {
    try {
      setLoading(true);
      incrementRequests();

      body.generalObjetivesDate = body.fGeneralObjetivesDate;
      body.fGeneralObjetivesDate = "";
      await putGeneralObjetivesUploadServices(id, body);
      await handleGetAllGeneralObjetives(period);
      await handleGetAllIndividualObjetives(period);
      setSuccesPost(true);
      setLoading(false);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.UPDATE_GENERAL_OBJETIVES_SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_ERROR_MSG,
          payload: {
            loading,
            individualObjetives,
            generalObjetives,
            yearsSemesters,
            errorMsg: {
              error,
              type: "GUO",
            },
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  const handleDeleteGeneralObjetives = async (id: number) => {
    try {
      setLoading(true);
      incrementRequests();
      await deleteGeneralObjetivesUploadServices(id);
      await handleGetAllGeneralObjetives("");
      setLoading(false);

      setSuccesPost(true);
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.DELETE_GENERAL_OBJETIVES_SUCCESS,
        })
      );
    } catch (error) {
      dispatch(
        actionEvent<ObjetivesActions, IObjetivesBaseInterface>({
          type: ObjetivesActions.SET_ERROR_MSG,
          payload: {
            loading,
            individualObjetives,
            generalObjetives,
            yearsSemesters,
            errorMsg: {
              error,
              type: "GDO",
            },
            MetaIndividual,
            MetaGeneral,
            FilterGeneral,
            FilterIndividual,
            EditObjetive,
            setSuccesPost,
            succesPost,
            errorPost,
            setErrorPost,
          },
        })
      );
    } finally {
      decrementRequests();
    }
  };

  return {
    FilterGeneral,
    FilterIndividual,
    MetaIndividual,
    MetaGeneral,
    loading,
    errorMsg,
    generalObjetives,
    individualObjetives,
    yearsSemesters,
    EditObjetive,
    succesPost,
    errorPost,
    setErrorPost,
    setSuccesPost,
    handleGetAllIndividualObjetives,
    handleGetPeriodYearsSemesters,
    handleGetAllGeneralObjetives,
    handleCreateGeneralObjetives,
    handleCreateIndividualObjetives,
    handleUpdateGeneralObjetives,
    handleDeleteGeneralObjetives,
    handleDelteError,
    handleSetIndividualFilter,
    handleSetGeneralFilter,
    handleSetEditObjetive,
    handleSetIndividualObjetives,
  };
};

const intailState: IObjetivesBaseInterface = {
  generalObjetives: [],
  individualObjetives: [],
  yearsSemesters: {} as PeriodYearsSemesters,
  loading: false,
  errorMsg: {} as errorType,
  MetaIndividual: {} as IMeta,
  MetaGeneral: {} as IMeta,
  FilterGeneral: {} as IGeneralObjetivesFilter,
  FilterIndividual: {} as IIndividualObjetivesFilter,
  EditObjetive: "",
  errorPost: false,
  succesPost: false,
  setSuccesPost: function (succes: boolean): void {
    throw new Error("Function not implemented.");
  },
  setErrorPost: function (error: boolean): void {
    throw new Error("Function not implemented.");
  },
};

const ObjetivesReducer = (
  state = intailState,
  action: ReducerActionsInterface
) => {
  switch (action.type) {
    case ObjetivesActions.GET_ALL_GENERAL_OBJETIVES:
      return {
        ...state,
        generalObjetives: action.payload.generalObjetives,
        MetaGeneral: action.payload.MetaGeneral,
      };
    case ObjetivesActions.GET_ALL_INDIVIDUAL_OBJETIVES:
      return {
        ...state,
        individualObjetives: action.payload.individualObjetives,
        MetaIndividual: action.payload.MetaIndividual,
      };
    case ObjetivesActions.GET_PERIOD_YEARS_SEMESTERS:
      return {
        ...state,
        yearsSemesters: action.payload.yearsSemesters,
      };
    case ObjetivesActions.UPDATE_GENERAL_OBJETIVES_SUCCESS:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case ObjetivesActions.DELETE_GENERAL_OBJETIVES_SUCCESS:
      return {
        ...state,
        loading: action.payload.loading,
      };
    case ObjetivesActions.SET_FILTER_GENERAL:
      return {
        ...state,
        FilterGeneral: action.payload.FilterGeneral,
      };
    case ObjetivesActions.SET_FILTER_INDIVIDUAL:
      return {
        ...state,
        FilterIndividual: action.payload.FilterIndividual,
      };
    case ObjetivesActions.SET_EDIT_OBJETIVE:
      return {
        ...state,
        EditObjetive: action.payload.EditObjetive,
      };
    /* ERRORES */
    case ObjetivesActions.SET_ERROR_MSG_GCGO:
      return {
        ...state,
        errorMsg: action.payload.errorMsg,
      };
    case ObjetivesActions.SET_ERROR_MSG_GCIO:
      return {
        ...state,
        errorMsg: action.payload.errorMsg,
      };
    default:
      return state;
  }
};

export { ObjetivesReducer, useObjetives };

