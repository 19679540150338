/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { years } from "../../common/utils";
import {
  BoxMobileModal,
  ButtonM,
  ContainerUpload,
  Paragraph,
  RowBox,
  SubTitle,
  TitleModal,
  UploadBox
} from "../../pages/Admin/Objectives/ObjetivesStyles.js";
import pencil from "../../pages/Admin/Objectives/images/pencil.svg";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../pages/Admin/Politics/PoliticsStyles.js";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { usePeriods } from "../../redux/Periods";
import { Select } from "../common/InputsByType";
import ModalStatusTargetError from "../organisms/ConfirmationModals/ModalStatusError";

interface Options {
  value: string;
  text: string;
}
const options = [
  { text: "1", value: "1" },
  { text: "2", value: "2" },
];
interface ModalPeriod {
  period?: any;
  setPeriod?: any;
  setSubmitModal?: any;
}

const ModalPerioEvaluacion: FC<ModalPeriod> = ({
  period,
  setPeriod,
  setSubmitModal,
}) => {
  const [show, setShow] = useState(false);
  const [yearsArray, setYearsArray] = useState([{}]);
  const [showWindow, setShowWindow] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const {
    periods,
    message,
    setMessage,
    setErrorPost,
    errorPost,
    handleGetAllPeriods,
    setTitleModal,
    titleModal
  } = usePeriods();
  const { User } = useCollaboratorRedux();

  const onChangeTextField = (e: any) => {
    e.preventDefault();
    setPeriod({
      ...period,
      [e.target.name]: +e.target.value,
    });
  };

  const validatePeriod = () => {
    const exist = periods.find(
      (item) =>
        item?.evaluationPeriodNumberOfYear ===
        period?.evaluationPeriodNumberOfYear &&
        item?.evaluationPeriodYear === period?.evaluationPeriodYear
    );

    return exist;
  };

  const handleSubmit = async (e: any) => {
    if (
      period?.evaluationPeriodNumberOfYear > 0 &&
      period?.evaluationPeriodYear > 0
    ) {
      e.preventDefault();
      const exist = validatePeriod();

      if (exist) {
        setMessage("El período ya existe");
        setErrorPost(true);
        handleClose();
        setTitleModal("Periodo existente")

      } else {
        period.evaluationPeriodName =
          period?.evaluationPeriodNumberOfYear === 1 ? "01 ENERO" : "01 JULIO";

        setPeriod(period);

        setSubmitModal(true);
        handleClose();
        handleGetAllPeriods(``);
      }
    }
  };

  useEffect(() => {
    let list = years();
    setYearsArray(list);
    const sizeWindow = window.screen.width;
    if (sizeWindow >= 768) {
      setShowWindow(true);
    }
  }, []);

  useEffect(() => {
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    if (currentMonth <= 6) {
      setPeriod({ ...period, evaluationPeriodNumberOfYear: 1 });
    } else {
      setPeriod({ ...period, evaluationPeriodNumberOfYear: 2 });
    }
  }, []);
  return (
    <>
      <UploadBox>
        <ContainerUpload>
          <RowBox>
            <img src={pencil} alt="addPeriodo" />
            <div>
              <Paragraph>Crear nuevo período</Paragraph>
              <SubTitle>Crea un nuevo período</SubTitle>
            </div>
          </RowBox>
          {/* {showWindow && ( */}
            <ButtonM onClick={handleShow}>
              Crear período
            </ButtonM>
        </ContainerUpload>
        {/* {!showWindow && ( */}
          {/* <ContainButtonMobile>
            <ButtonM onClick={handleShow}>
              <TextButton>Crear período</TextButton>
            </ButtonM>
          </ContainButtonMobile>
        )} */}
      </UploadBox>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <TitleModal>Agregar nuevo período</TitleModal>
        </Modal.Header>
        <Modal.Body>
          <BoxMobileModal>
            <Select
              id="anioPeriodo"
              title="Año"
              options={yearsArray as Options[]}
              name="evaluationPeriodYear"
              onChange={onChangeTextField}
              value={inputValues}
            />
            <Select
              id="Periodo"
              title="Período"
              disabled={false}
              options={options}
              name="evaluationPeriodNumberOfYear"
              onChange={onChangeTextField}
              value={
                period?.evaluationPeriodNumberOfYear === 1
                  ? { evaluationPeriodNumberOfYear: 1 }
                  : { evaluationPeriodNumberOfYear: 2 }
              }
            />
          </BoxMobileModal>
        </Modal.Body>
        <Modal.Footer>
          <SecondaryButton onClick={handleClose}>Cancelar</SecondaryButton>
          <PrimaryButton
            disabled={
              period?.evaluationPeriodNumberOfYear > 0 &&
                period?.evaluationPeriodYear > 0
                ? false
                : true
            }
            onClick={handleSubmit}
          >
            Crear período
          </PrimaryButton>
        </Modal.Footer>
      </Modal>
      {errorPost ? (
        <ModalStatusTargetError
          handleClick={setErrorPost}
          modalStatus={errorPost}
          messageShow
          message={message}
          titleModal={titleModal}
        />
      ) : null}
    </>
  );
};

export default ModalPerioEvaluacion;
