/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { AiOutlineInfoCircle } from "react-icons/ai";
import infoIcon from "../../../../assets/evaluaition-icons/info.svg";
import AlertModalIcon from "../../../../assets/icons/AlertModalIcon.svg";
import infoIconModal from "../../../../assets/icons/infoIconModal.svg";
import { ModalIntructionsBox } from "./EvaluationModalsStyles";
import tableImg from "./img/table.svg";

const GeneralInfoEvaluationModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };

  return (
    <>
      <img
        src={infoIcon}
        alt="info"
        onClick={handleShow}
        style={{ marginTop: "6px" }}
      />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Instructivo para llenar evaluación de desempeño
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>
              Consideraciones para la evaluación del desempeño laboral, factor
              humano, actitud y habilidades:
            </strong>
          </p>
          <div style={{ textAlign: "center" }}>
            <img src={tableImg} alt="" />
          </div>
          <ModalIntructionsBox>
            <div style={{}}>
              <p>
                <strong>Donde:</strong>
              </p>
              <ol>
                <ul>
                  1= Por debajo de lo esperado y es percibido por los demás
                  colaboradores.
                </ul>
                <ul>2= Por debajo de lo esperado</ul>
                <ul>3 = Es lo esperado y que iniciamos</ul>
                <ul>4= Supera las expectativas</ul>
                <ul>
                  5= Supera las expectativas y es percibido por los demás
                  colaboradores.{" "}
                </ul>
              </ol>
            </div>
          </ModalIntructionsBox>
          <ModalIntructionsBox>
            <p style={{ color: "#939396" }}>
              NOTA: Una calificación promedio de 3 corresponde a un colaborador
              que cumple las expectativas, su comportamiento es bueno, tiene
              excelente desempeño, actitud, etc. Todos los trabajadores o
              empleados al ingresar a la empresa tenemos 3 de calificación en
              todos los indicadores.
            </p>
          </ModalIntructionsBox>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{ background: "#3B63A2", border: "none", fontWeight: "500" }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const InfoLaboralDevelopmentModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };

  return (
    <>
      <AiOutlineInfoCircle onClick={handleShow} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Evaluación del Desempeño Laboral
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ color: "#000", fontSize: "14px" }}>
              En la sección de <strong>Desempeño Laboral</strong>, utilizamos
              calificaciones del 1 al 5. Estas reflejan aspectos notorios que
              tanto compañeros de área como el centro de trabajo reconocen en
              ti. El nivel 5 es un ejemplo a seguir, representando un desempeño
              excelente, mientras que el nivel 1 señala áreas a corregir
              inmediatamente para beneficio tuyo y de la organización. Queremos
              destacar tus fortalezas y también identificar áreas de
              oportunidad, ya que todos tenemos puntos fuertes y podemos mejorar
              diariamente en nuestras tareas dentro de la organización.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{ background: "#3B63A2", border: "none", fontWeight: "500" }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const InfoLaboralDevelopmentModalFactorHumano = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };

  return (
    <>
      <AiOutlineInfoCircle onClick={handleShow} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Evaluación de Factor Humano/ Actitud
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ color: "#000", fontSize: "14px" }}>
              En la sección de <strong>Factor Humano</strong>, utilizamos
              calificaciones del 1 al 5. Estas reflejan aspectos notorios que
              tanto compañeros de área como el centro de trabajo reconocen en
              ti. El nivel 5 es un ejemplo a seguir, representando un desempeño
              excelente, mientras que el nivel 1 señala áreas a corregir
              inmediatamente para beneficio tuyo y de la organización. Queremos
              destacar tus fortalezas y también identificar áreas de
              oportunidad, ya que todos tenemos puntos fuertes y podemos mejorar
              diariamente en nuestras tareas dentro de la organización.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{ background: "#3B63A2", border: "none", fontWeight: "500" }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const InfoLaboralDevelopmentModalHabilidades = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };

  return (
    <>
      <AiOutlineInfoCircle onClick={handleShow} />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Evaluación del Habilidades
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ color: "#000", fontSize: "14px" }}>
              En la sección de <strong>Habilidades</strong>, utilizamos
              calificaciones del 1 al 5. Estas reflejan aspectos notorios que
              tanto compañeros de área como el centro de trabajo reconocen en
              ti. El nivel 5 es un ejemplo a seguir, representando un desempeño
              excelente, mientras que el nivel 1 señala áreas a corregir
              inmediatamente para beneficio tuyo y de la organización. Queremos
              destacar tus fortalezas y también identificar áreas de
              oportunidad, ya que todos tenemos puntos fuertes y podemos mejorar
              diariamente en nuestras tareas dentro de la organización.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{ background: "#3B63A2", border: "none", fontWeight: "500" }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const StrongestModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    // lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };

  return (
    <>
      <AiOutlineInfoCircle
        onClick={handleShow}
        style={{ width: "20px", height: "20px" }}
      />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Información de Fortalezas
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ color: "#000", fontSize: "14px" }}>
              <strong>Fortalezas:</strong> En los siguientes tres espacios en
              blanco, proporciona una explicación por escrito resaltando las
              fortalezas identificadas en el evaluado. Estas fortalezas deben
              basarse en los reactivos anteriores que obtuvieron una
              calificación de 5 o 4. Es importante reconocer y destacar las
              habilidades excepcionales demostradas por el colaborador en estas
              áreas específicas para un mejor entendimiento de su desempeño.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{ background: "#3B63A2", border: "none", fontWeight: "500" }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const SaveEditModal = ({
  show,
  onHide,
  onHide2,
  isSaveDatas,
  setIsSaveDatas,
  handleChange,
  isErased,
  setTextModal,
  isStrengh,
  setValue,
}: any) => {
  const handleClose = () => onHide(false);
  const handleShow = () => onHide(true);

  const handleSave = () => {
    // setTextModal('');
    // console.log(isSaveDatas)
    if (isErased) {
      isSaveDatas.e.target.value = "";
      setIsSaveDatas(isSaveDatas);
      handleChange(isSaveDatas.e, isSaveDatas.type, Number(isSaveDatas.index));
      onHide(false);
      onHide2(false);
    } else {
      handleChange(isSaveDatas.e, isSaveDatas.type, Number(isSaveDatas.index));
      onHide(false);
      onHide2(false);
    }
    setValue("");
  };

  const titleStyles = {
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "22px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ff8b02",
    // color: "#1F2A40",
  };

  const buttonStyles = {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  };

  return (
    <>
      <Modal centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={AlertModalIcon}
              style={{ marginRight: "20px" }}
              alt="AlertModalIcon"
            />
            ¡Importante!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ marginTop: "20px", marginLeft: "20px" }}>
            Estás a punto de {isErased ? "borrar" : "guardar"} la{" "}
            {isStrengh ? "fortaleza" : "oportunidad"} para tu colaborador
          </p>
          <p style={{ marginTop: "20px", marginLeft: "20px" }}>
            ¿Deseas continuar con esta acción?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div style={buttonStyles}>
            <Button
              variant="primary"
              onClick={handleSave}
              style={{
                background: "#C9F2FF",
                border: "1px solid #C9F2FF",
                width: "45%",
                color: "#3B63A2",
                fontWeight: "600",
              }}
            >
              Continuar
            </Button>
            <Button
              variant="secondary"
              onClick={handleClose}
              style={{
                background: "#3B63A2",
                border: "1px solid #3B63A2",
                width: "45%",
                fontWeight: "600",
              }}
            >
              Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const EditStrongestModal = ({
  show,
  onHide,
  handleChangeDos,
  indexEdit,
  editText,
  StrenghsOrOpportunities,
  isDisabled,
  formValues,
  formData,
  idSelect,
  isStrengh,
  setShowAlert,
  isErased,
  setIsErased,
  textModal,
  setTextModal,
  value,
  setValue,
}: any) => {
  const handleClose = () => {
    setValue("");
    setModified(false);
    onHide(false);
  };
  const handleShow = () => onHide(true);
  // const [inputModal, setValue] = useState<any>(formData[indexEdit] && formData[indexEdit]?.employeeEvaluationWorkersStrengthsQuestionText || formData[indexEdit]?.employeeEvaluationWorkersOpportunitieAreaText);
  const [inputModalValues, setInputModalValues] = useState<any>("");
  const [modified, setModified] = useState(false);

  useEffect(() => {
    if (value === "") {
      setValue(textModal);
    }
  }, [textModal]);

  const onChangeTextField = (e: any) => {
    setModified(true);
    setValue(e.target.value);
    setInputModalValues(e);
  };

  const handleErase = () => {
    setModified(false);
    const auxInput = {
      target: {
        value: "",
      },
    };
    handleChangeDos(
      inputModalValues ? inputModalValues : auxInput,
      isStrengh ? "fortaleza" : "Oportunidad",
      idSelect
    );
    setIsErased(true);
    setShowAlert(true);
  };

  const handleSave = () => {
    setModified(false);
    handleChangeDos(
      inputModalValues,
      isStrengh ? "fortaleza" : "Oportunidad",
      idSelect
    );
    setIsErased(false);
    setShowAlert(true);
  };

  const handleSelector = (e: any) => {
    setModified(true);
    setValue(e.target.value);
    setInputModalValues(e);
  };

  const titleStyles = {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px",
  };

  // console.log("value", value)

  return (
    <>
      <Modal
        centered
        backdrop="static"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>Edición de {editText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Select
            aria-label="example"
            value={value}
            onChange={(e) => handleSelector(e)}
          >
            <option value="">Selecciona una opción</option>
            {StrenghsOrOpportunities
              ? StrenghsOrOpportunities?.map((item: any, index: any) => {
                return (
                  <>
                    {item.employeeEvaluationWorkersStrengthsQuestionText ? (
                      <option
                        value={
                          item?.employeeEvaluationWorkersStrengthsQuestionText
                        }
                        key={index}
                      >
                        {item?.employeeEvaluationWorkersStrengthsQuestionText}
                      </option>
                    ) : (
                      <option
                        value={
                          item?.employeeEvaluationWorkersOpportunitieAreaText
                        }
                        key={index}
                      >
                        {item?.employeeEvaluationWorkersOpportunitieAreaText}
                      </option>
                    )}
                  </>
                );
              })
              : null}
            ;
          </Form.Select>

          <p style={{ marginTop: "20px", marginLeft: "10px" }}>
            Escribe o modifica una {isStrengh ? "fortaleza" : "oportunidad"}
          </p>
          <Form.Control
            name={idSelect}
            type="text"
            placeholder="Escribe aquí"
            onChange={onChangeTextField}
            value={value === "Selecciona una opcion" ? "" : value}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={value === "Selecciona una opcion" || value === ""}
            style={{ background: "#3B63A2", border: "none", fontWeight: "500" }}
          >
            Guardar
          </Button>
          <Button
            variant="info"
            onClick={handleErase}
            style={{
              background: "#C9F2FF",
              border: "none",
              fontWeight: "500",
              color: "#3B63A2",
            }}
          >
            Borrar
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ background: "#3B63A2", border: "none", fontWeight: "500" }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const OportunitiesModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const titleStyles = {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#1F2A40",
    opacity: "0.6",
  };

  return (
    <>
      <AiOutlineInfoCircle
        onClick={handleShow}
        style={{ width: "20px", height: "20px" }}
      />

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={titleStyles}>
            <img
              src={infoIconModal}
              alt="info"
              style={{ marginRight: "8px" }}
            />
            Información Oportunidades
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p style={{ color: "#000", fontSize: "14px" }}>
              <strong>Oportunidades:</strong> En los siguientes tres espacios en
              blanco, proporciona una explicación por escrito sobre las
              oportunidades de mejora identificadas en el evaluado. Estas
              oportunidades deben basarse en los reactivos anteriores que
              obtuvieron una calificación de 1 o 2. Identificar y detallar estas
              áreas de mejora nos permitirá brindar un apoyo enfocado y
              orientado al crecimiento del colaborador para su desarrollo
              personal y profesional.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            style={{ background: "#3B63A2", border: "none", fontWeight: "500" }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export {
  EditStrongestModal,
  GeneralInfoEvaluationModal,
  InfoLaboralDevelopmentModal,
  InfoLaboralDevelopmentModalFactorHumano,
  InfoLaboralDevelopmentModalHabilidades,
  OportunitiesModal,
  SaveEditModal,
  StrongestModal
};

