import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { TableData } from "../../../Interface/ApiInterface";
import {
  FutureGoalsCard,
  GoalsBodyTable,
} from "../../../components/atoms/EvaluationForms/EvaluationFormsStyles";
import FormattedInputsFuture from "../../../components/atoms/KpisTables/inputMask/InputCardFuture";
import FormattedInputsFutureDefault from "../../../components/atoms/KpisTables/inputMask/InputCardFutureDefault";
import FormattedInputsFutureDesempenio from "../../../components/atoms/KpisTables/inputMask/InputCardFutureDesempenio";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import {
  useAbleFutureObjetives,
  useAbleFutureObjetivesDefaults,
  useAbleFutureObjetivesSelects,
} from "../../../hooks/ObjetivosFuturos/index";
import { useAbleFutureObjetivesDesempenio } from "../../../hooks/ObjetivosFuturos/useAbleFutureObjetivesDesempenio";
import {
  ContainerDescription,
  SelectViewDisabledData,
} from "../Dashboard/stylesDashboard/AdminStyles";
import EditObjetiveFutures from "../components/EditObjetiveFutures";
import ModalObjetiveFuture from "./ModalObjetiveFuture";

const regexValidateNumber = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;

export const ColumnsDataFutureSelect = ({
  data,
  handleChange,
  optionsData,
  boolData,
  loadInput,
  setLoadInput,
}: any) => {
  const [showEditObjetive, setShowEditObjetive] = useState(false);
  const [indexEdit, setIndexEdit] = useState(0);
  // const [loadInput, setLoadInput] = useState(false);

  // ** Context
  const { ableInput } = useContext(EvaluationContext) as EvaluationContextType;

  const { ableSelectObjetive } = useAbleFutureObjetives();
  const { ableSelectSelect } = useAbleFutureObjetivesSelects();

  useEffect(() => {
    setLoadInput(false);
    setTimeout(() => {
      setLoadInput(true);
    }, 1000);
  }, []);

  return (
    <>
      {data.map((item: any, index: any) => {
        // console.log("Cambia la data->", item);
        return (
          <FutureGoalsCard
            borderColor={boolData[index]}
            style={{ backgroundColor: "white" }}
            key={item.description}
          >
            <div className="first">{item.semestre}</div>
            <div className="second">
              {loadInput && (
                <FormattedInputsFuture
                  handleChange={handleChange}
                  value={item?.valor}
                  disable={ableSelectObjetive}
                  index={index}
                  name="valor"
                />
              )}
            </div>
            <div className="third">
              <ContainerDescription>
                {data[index]?.descripcion?.label === "Selecciona una opcion" ? (
                  <Select
                    isDisabled={ableSelectObjetive}
                    options={optionsData}
                    value={item?.descripcion}
                    onChange={(value) =>
                      handleChange(
                        index,
                        "descripcion",
                        value as TableData["descripcion"]
                      )
                    }
                  />
                ) : (
                  <EditObjetiveFutures
                    setEditObjective={setShowEditObjetive}
                    data={data}
                    index={index}
                    setIndexEdit={setIndexEdit}
                  />
                )}
              </ContainerDescription>
            </div>
            <div className="fourth">
              {loadInput && (
                <FormattedInputsFuture
                  handleChange={handleChange}
                  value={item?.meta}
                  disable={ableSelectObjetive}
                  index={index}
                  name="meta"
                />
              )}
            </div>
          </FutureGoalsCard>
        );
      })}
      <ModalObjetiveFuture
        show={showEditObjetive}
        onHide={setShowEditObjetive}
        handleChangeDos={handleChange}
        data={data}
        indexEdit={indexEdit}
      />
    </>
  );
};

export const ColumnsDataFutureG = ({
  dataExtra,
  valuesFuturesG,
  setValuesFuturesG,
}: any) => {
  const { ableSelectDefaultObj } = useAbleFutureObjetivesDefaults();

  const handleChangeValue = (value: number | string, index: number) => {
    const changedValue = valuesFuturesG.map((item: any, i: number) => {
      if (i === index) {
        return {
          ...item,
          valor: value,
        };
      } else {
        return item;
      }
    });

    setValuesFuturesG(changedValue);
  };

  return (
    <>
      {dataExtra.map((item: any, index: number) => {
        return (
          item.type === "G" && (
            <GoalsBodyTable key={item.id}>
              <div className="first">{item?.semestre}</div>
              <div className="second">
                {/* {valuesFuturesG[index]?.valor} */}
                <FormattedInputsFutureDefault
                  handleChange={handleChangeValue}
                  value={valuesFuturesG[index]?.valor}
                  disable={ableSelectDefaultObj}
                  index={index}
                  name="valor"
                />
                {/* <input
                  disabled={ableSelectDefaultObj}
                  style={{ outline: "none" }}
                  className="inputSecond"
                  type="number"
                  value={valuesFuturesG[index]?.valor}
                  onChange={(e) => handleChangeValue(e.target.value, index)}
                /> */}
              </div>
              <div className="third">
                <ContainerDescription>
                  <SelectViewDisabledData>
                    {item?.description}
                  </SelectViewDisabledData>
                </ContainerDescription>
              </div>
              <div className="fourth">{item?.meta}% </div>
            </GoalsBodyTable>
          )
        );
      })}
    </>
  );
};

export const ColumnsDataFutureD = ({
  dataExtra,
  setDesempenioValue,
  desempenioValue,
}: any) => {
  const { ableSelectObjetiveDesempenio } = useAbleFutureObjetivesDesempenio();

  return (
    <>
      {dataExtra.map((item: any, index: number) => {
        if (
          item.type === "D" &&
          item.description ===
          "Desempeño laboral, humano, actitud y habilidades"
        ) {
          return (
            <GoalsBodyTable key={item.id}>
              <div className="first">{item.semestre}</div>
              <div className="second">
                <FormattedInputsFutureDesempenio
                  handleChange={setDesempenioValue}
                  value={desempenioValue}
                  disable={ableSelectObjetiveDesempenio}
                  index={index}
                  name="valor"
                />
              </div>
              <div className="third">
                <ContainerDescription>
                  <SelectViewDisabledData>
                    {item?.description}
                  </SelectViewDisabledData>
                </ContainerDescription>
              </div>
              <div className="fourth">{item?.meta}% </div>
            </GoalsBodyTable>
          );
        }
        return null;
        // );
      })}
    </>
  );
};

export const ColumnsDataFutureDTwo = ({ dataExtra, semestre }: any) => {
  return (
    <>
      {dataExtra.map((item: any) => {
        if (
          item.type === "D" &&
          item.description !==
          "Desempeño laboral, humano, actitud y habilidades"
        ) {
          return (
            <GoalsBodyTable key={item.id}>
              <div className="first">{item.semestre}</div>
              <div className="second">{item?.valor}%</div>
              <div className="third">
                <ContainerDescription>
                  <SelectViewDisabledData>
                    {item?.description}
                  </SelectViewDisabledData>
                </ContainerDescription>
              </div>
              <div className="fourth">{item?.meta}% </div>
            </GoalsBodyTable>
          );
        }

        return null;
        // );
      })}
    </>
  );
};

export const ColumnsDataFutureChangedPeriod = ({ dataExtra }: any) => {
  return (
    <>
      {dataExtra.map((item: any, index: number) => {
        return (
          <GoalsBodyTable key={item.id}>
            <div className="first">{item?.semestre}</div>
            <div className="second">{item?.valor}%</div>
            <div className="third">
              <ContainerDescription>
                <SelectViewDisabledData>
                  {item?.description}
                </SelectViewDisabledData>
              </ContainerDescription>
            </div>
            <div className="fourth">{item?.meta}% </div>
          </GoalsBodyTable>
        );
      })}
    </>
  );
};
