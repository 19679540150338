/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { IFilterCollaborators } from "../../../Interface/ApiInterface";
import { validateInputText } from "../../../common/utils";
import { ButtonBox } from "../../../components/atoms/KpisTables/KpisTablesStyles";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../../context/EvaluationContext";
import { validateIdPeriod } from "../../../context/validacionesFiltros";
import { useCollaboratorRedux } from "../../../redux/Collaborators";
import { getSelectStatus } from "../../../services/dataSelects.service";
import { BlueButton, GrayButton } from "../Politics/PoliticsStyles";
import { BoxMobile, FilterOptionsBox } from "./stylesDashboard/AdminStyles";
import {
  InputDashboard,
  InputWrapperDashboard,
  LabelDashboard,
  SelectDashboard,
} from "./stylesDashboard/NewStylesDashboard";

const dataClasificacion = [
  { text: "Selecciona", value: "", id: 23231 },
  { text: "Global", value: "GLOBAL", id: 24231 },
  { text: "División", value: "DIVISION", id: 25231 },
];

// const dataAutoEvaluacion = [
//   { text: "Selecciona", value: "", id: 2312131 },
//   { text: "Si", value: true, id: 2442131 },
//   { text: "No", value: false, id: 2551231 },
// ];

const FiltersDashboard = ({
  evaluation_period_id,
  nameEmployee,
  setFilterSearch,
  setErrorLoader,
  setQueryPeticionWithFilters,
}: any) => {
  const {
    User,
    handleGetCollaborators,
    handleSetFilterCollaborators,
    setErrorLoading,
    errorloading,
  } = useCollaboratorRedux();
  const { setLoadingPagination } = useContext(
    EvaluationContext
  ) as EvaluationContextType;
  const [filters, setFilters] = useState<any | null>({
    claTrab: "",
    nombre: "",
    puesto: "",
    Localidad: "",
    area: "",
    departamento: "",
    fechaNac: "",
    IdEstatusEvaluacion: "",
    Clasificacion: "",
    gerente: "",
    director: "",
    compania: "",
    JerarquiaColaborador: "",
    EstatusAutoEvaluacion: "",
  });

  const [dataSelectStatus, setDataSelectStatus] = useState<Array<any>>([]);

  const getDataSelectStatus = async () => {
    const { data } = await getSelectStatus();
    setDataSelectStatus(data);
  };

  const handleChange = (e: any) => {
    // console.log("<NOMBRE> ", e.target.name, "<VALOR>", e.target.value);

    const filtersTable = {
      ...filters,
      [e.target.name]: e.target.value,
    };
    // console.log(filtersTable);

    localStorage.setItem("tableFilters", JSON.stringify(filtersTable));

    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleClear = () => {
    let query = `?`;

    const cleanFilters = {
      claTrab: "",
      nombre: "",
      puesto: "",
      Ubicacion: "",
      area: "",
      departamento: "",
      fechaNac: "",
      IdEstatusEvaluacion: "",
      Clasificacion: "",
      gerente: "",
      director: "",
      compania: "",
      JerarquiaColaborador: "",
      EstatusAutoEvaluacion: "",
    };
    setFilters(cleanFilters);

    localStorage.setItem("tableFilters", JSON.stringify(cleanFilters));

    handleSetFilterCollaborators({} as IFilterCollaborators);
    if (evaluation_period_id) {
      const periodo = validateIdPeriod(evaluation_period_id);

      if (
        nameEmployee !== undefined &&
        nameEmployee !== "" &&
        nameEmployee !== null &&
        nameEmployee !== "Elige una opción"
      ) {
        filters.JerarquiaColaborador = nameEmployee;
        query += `&JerarquiaColaborador=${filters.JerarquiaColaborador}`;
      }
      // console.log("query", query);

      query += `&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role
        }&IdPeriod=${periodo}&PageSize=10&PageNumber=1`;
      setLoadingPagination(true);
      handleGetCollaborators(query);
    }
  };

  const handleSubmit = (e?: any) => {
    let query = `?`;
    Object.keys(filters).forEach((key) => {
      if (
        filters[key] !== undefined &&
        filters[key] !== "" &&
        filters[key] !== null &&
        filters[key] !== "Elige una opción"
      ) {
        query += `&${key}=${filters[key]}`;
      }
    });
    // console.log("Esta es la petición que realizamos: ", query);

    if (evaluation_period_id) {
      const periodo = validateIdPeriod(evaluation_period_id);
      query += `&NombreColaborador=${User.nombre}&RolColaborador=${User.role === "Auditor" ? "Admin" : User.role
        }&IdPeriod=${periodo}&PageSize=10&PageNumber=1`;
      setQueryPeticionWithFilters(query);

      // console.log("query petición", query);

      handleSetFilterCollaborators(filters);
      handleGetCollaborators(query);
      setLoadingPagination(true);

      if (setFilterSearch) {
        setFilterSearch(query);
      }
    }
  };

  useEffect(() => {
    const filtrosAlmacenados = localStorage.getItem("tableFilters");

    if (filtrosAlmacenados) {
      const filtros = JSON.parse(filtrosAlmacenados);
      // console.log("Tienes filtros", filtros);
      setFilters(filtros);
    }
    getDataSelectStatus();
    // handleClear();
  }, []);

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    // console.log("Aquí -> ", errorloading);
    if (errorloading === 403 || errorloading) {
      setLoadingPagination(false);
      setErrorLoader(true);
      setErrorLoading(null);
    }
  }, [errorloading]);

  // console.log("Filtros cargados", filters);
  return (
    <FilterOptionsBox>
      <BoxMobile>
        <Col>
          <InputWrapperDashboard>
            <LabelDashboard>Clasificación</LabelDashboard>
            <SelectDashboard
              name="Clasificacion"
              value={filters.Clasificacion}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              {dataClasificacion.map((data) => (
                <option key={data.id} value={data.value}>
                  {data.text}
                </option>
              ))}
            </SelectDashboard>
          </InputWrapperDashboard>
          <InputWrapperDashboard>
            <LabelDashboard>Puesto</LabelDashboard>
            <InputDashboard
              placeholder="Puesto"
              name="puesto"
              // onKeyDownCapture={validateInputText}
              value={filters.puesto}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>

          <InputWrapperDashboard>
            <LabelDashboard>Compañía</LabelDashboard>
            <InputDashboard
              placeholder="Compañía"
              aria-label="Default select example"
              name="compania"
              value={filters.compania}
              // onKeyDownCapture={validateInputText}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>
          {/* <InputGroup style={{ marginBottom: "8px" }}>
            <InputGroup.Text id="basic-addon3" style={FixSearchLabel}>
              Autoevaluación
            </InputGroup.Text>
            <Form.Select
              aria-label="Default select example"
              name="EstatusAutoEvaluacion"
              value={filters.EstatusAutoEvaluacion}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              {dataAutoEvaluacion.map((data: any) =>
                (<option key={data.id} value={data.value}>{data.text}</option>)
              )}
            </Form.Select>
          </InputGroup> */}
        </Col>
        <Col>
          <InputWrapperDashboard>
            <LabelDashboard>Estatus</LabelDashboard>
            <SelectDashboard
              name="IdEstatusEvaluacion"
              onKeyDownCapture={validateInputText}
              value={filters.IdEstatusEvaluacion}
              onChange={(e) => {
                handleChange(e);
              }}
            >
              <option value="">Selecciona</option>
              {dataSelectStatus
                .filter((datos) => datos.name !== "Pendiente agendar")
                .map((datos) => (
                  <option
                    key={datos.catStatusEvaluationId}
                    value={datos.catStatusEvaluationId}
                  >
                    {datos.name}
                  </option>
                ))}
            </SelectDashboard>
          </InputWrapperDashboard>

          {/* <InputGroup style={{ marginBottom: "8px" }}>
            <InputGroup.Text id="basic-addon3" style={FixSearchLabel}>
              Departamento
            </InputGroup.Text>
            <Form.Control
              placeholder="Departamento"
              name="departamento"
              onKeyDownCapture={validateInputText}
              value={filters.departamento}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputGroup> */}
          <InputWrapperDashboard>
            <LabelDashboard>Localidad</LabelDashboard>
            <InputDashboard
              placeholder="Localidad"
              name="Ubicacion"
              value={filters.Ubicacion}
              // onKeyDownCapture={validateInputText}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>
          <InputWrapperDashboard>
            <LabelDashboard>Director</LabelDashboard>
            <InputDashboard
              placeholder="All"
              name="director"
              // onKeyDownCapture={validateInputText}
              value={filters.director}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>
        </Col>
        <Col>
          <InputWrapperDashboard>
            <LabelDashboard>Clave</LabelDashboard>
            <InputDashboard
              placeholder="######"
              name="claTrab"
              value={filters.claTrab}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>

          {/* <InputWrapperDashboard >
            <LabelDashboard>
              Fecha de ingreso
            </LabelDashboard>
            <InputDashboard
              placeholder="Fecha ingreso"
              name="fechaNac"
              value={filters.fechaNac}
              onChange={(e) => {
                handleChange(e);
              }}
              type="date"
            />
          </InputWrapperDashboard> */}

          <InputWrapperDashboard>
            <LabelDashboard>Área</LabelDashboard>
            <InputDashboard
              placeholder="Área"
              name="area"
              // onKeyDownCapture={validateInputText}
              value={filters.area}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>
          <InputWrapperDashboard>
            <LabelDashboard>Gerente</LabelDashboard>
            <InputDashboard
              placeholder="All"
              name="gerente"
              value={filters.gerente}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>
        </Col>

        <Col>
          <InputWrapperDashboard>
            <LabelDashboard>Nombre</LabelDashboard>
            <InputDashboard
              placeholder="Nombre"
              name="nombre"
              // onKeyDownCapture={validateInputText}
              value={filters.nombre}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>
          <InputWrapperDashboard>
            <LabelDashboard>Departamento</LabelDashboard>
            <InputDashboard
              placeholder="Departamento"
              name="departamento"
              // onKeyDownCapture={validateInputText}
              value={filters.departamento}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </InputWrapperDashboard>
        </Col>
      </BoxMobile>
      <>
        <ButtonBox style={{ gap: "0.5rem" }}>
          <GrayButton onClick={handleClear}>Limpiar</GrayButton>
          <BlueButton onClick={handleSubmit}>Buscar</BlueButton>
        </ButtonBox>
      </>
    </FilterOptionsBox>
  );
};

export default FiltersDashboard;
